import { Link as ChakraLink } from "@chakra-ui/react";
import { Link as RRLink, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

export const Link = ({ type, ...rest }: any) => (
  <ChakraLink
    {...rest}
    _activeLink={{ textDecoration: "underline" }}
    as={type === "hash" ? HashLink : type === "navlink" ? NavLink : RRLink}
  />
);
