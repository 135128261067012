import { ColorModeScript } from "@chakra-ui/react";
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  // redirect,
} from "react-router-dom";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import { Root } from "./components/Root";
import { Home } from "./pages/Home";
import { Traverse } from "./pages/Traverse";
import { Event } from "./pages/Event";
import { NotFound } from "./pages/NotFound";
import { Ordinals } from "./pages/Ordinals";

// const { REACT_APP_HALLOWEEN_CURRENT_DAY } = process.env;

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "traverse",
        element: <Traverse />,
      },
      {
        path: "ordinals",
        element: <Ordinals />,
      },
      {
        path: "event/:eventName",
        element: <Event />,
        // loader: async ({ params }: any) => {
        //   if (!params.day) {
        //     return redirect(
        //       `/event/${params.eventName}/${REACT_APP_HALLOWEEN_CURRENT_DAY}`
        //     );
        //   }
        // },
        children: [
          {
            path: ":day",
            element: <Event />,
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  <React.StrictMode>
    <ColorModeScript />
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
