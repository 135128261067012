import {
  Container,
  Heading,
  SimpleGrid,
  Box,
  Img,
  Text,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { inscriptions } from "../metadata/inscriptions";

export function Ordinals() {
  return (
    <Container maxWidth="4xl" centerContent>
      <Heading
        mt={12}
        mb={3}
        bgGradient="linear(to-r, purple.500, orange.600)"
        bgClip="text"
      >
        Ordinals
      </Heading>
      <Heading as="h3" fontWeight="normal" fontSize="lg" mb={12}>
        50 Tiny dino ordinals inscribed on the bitcoin blockchain.{" "}
        <Link isExternal href="https://discord.gg/JjWnUZfXcc">
          Join the discord <ExternalLinkIcon />
        </Link>
        .
      </Heading>
      <SimpleGrid columns={{ base: 2, sm: 4, md: 5, lg: 5 }} spacing={3}>
        {inscriptions
          .sort((a: any, b: any) => a.index - b.index)
          .map((insc: any) => {
            return (
              <Link href={insc.explorer} isExternal>
                <Box key={insc.name}>
                  <Img
                    src={
                      insc.name.includes("dino")
                        ? `/${insc.index}.jpeg`
                        : require(`../metadata/ordinals/${insc.name.replace(
                            /\s+/g,
                            "-"
                          )}.svg`)
                    }
                  />
                  <Text align="center">Inscription #{insc.index}</Text>
                  <Text align="center" fontSize="sm">
                    ({insc.name})
                  </Text>
                </Box>
              </Link>
            );
          })}
      </SimpleGrid>
    </Container>
  );
}
