import { ChakraProvider, Container, chakra, theme } from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";
import { Outlet, ScrollRestoration } from "react-router-dom";
import { Mainnet, DAppProvider, Config } from "@usedapp/core";

import { readOnlyUrls, networks } from "../constants";
import { Header } from "./Header";
import { Footer } from "./Footer";
import { LocalTools } from "./LocalTools";

const { REACT_APP_LOCAL_TOOLS } = process.env;

const config: Config = {
  readOnlyChainId: Mainnet.chainId,
  readOnlyUrls,
  multicallVersion: 1,
  fastMulticallEncoding: true,
  noMetamaskDeactivate: true,
  networks,
};

const customTheme = {
  ...theme,
  styles: {
    ...theme.styles,
    global: (props: any) => ({
      body: {
        color: mode("gray.800", "whiteAlpha.900")(props),
        bg: mode("white", "gray.800")(props),
        lineHeight: "base",
      },
      "*": {
        textTransform: "lowercase !important",
      },
      "*::placeholder": {
        color: mode("gray.400", "whiteAlpha.400")(props),
      },
      "*, *::before, &::after": {
        borderColor: mode("gray.200", "whiteAlpha.300")(props),
        wordWrap: "break-word",
      },
    }),
  },
};

export const Root = () => (
  <ChakraProvider theme={customTheme}>
    <DAppProvider config={config}>
      <Container maxW="5xl" p={6}>
        {REACT_APP_LOCAL_TOOLS && <LocalTools />}
        <Header />
        <chakra.main>
          <Outlet />
        </chakra.main>
        <Footer />
      </Container>
    </DAppProvider>
    <ScrollRestoration />
  </ChakraProvider>
);
