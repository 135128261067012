import { useEthers, ChainId } from "@usedapp/core";
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  useDisclosure,
  Collapse,
  VStack,
  Icon,
  Flex,
  Text,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { supportedChains } from "../constants";

export const SwitchChains = ({
  desktop = false,
  title,
}: {
  desktop?: boolean;
  title?: string;
}) => {
  const { chainId, switchNetwork, account } = useEthers();
  const { isOpen, onToggle } = useDisclosure();

  if (desktop) {
    return (
      <Box display={{ base: "none", md: "block" }}>
        <Menu>
          <MenuButton
            disabled={!account}
            as={Button}
            rightIcon={<ChevronDownIcon />}
          >
            <Text maxWidth="110px" noOfLines={1}>
              {title ?? (chainId && supportedChains[chainId].displayName)}
            </Text>
          </MenuButton>
          <MenuList>
            {Object.keys(supportedChains).map((key) => {
              const id = parseInt(key, 10);
              const chain = supportedChains[id as ChainId];

              return (
                <MenuItem
                  fontWeight={chain.chainId === chainId ? "bold" : "light"}
                  onClick={() => switchNetwork(chain.chainId)}
                  aria-label={`Switch to ${chain.displayName}`}
                  key={chain.chainId}
                >
                  {chain.displayName}
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </Box>
    );
  }

  return (
    <>
      <Flex w="full" onClick={onToggle} alignItems="center">
        <Button
          display={{ base: "block", md: "none" }}
          variant="unstyled"
          width="full"
          textAlign="left"
        >
          Switch chains
        </Button>
        <Icon
          as={ChevronDownIcon}
          transition={"all .25s ease-in-out"}
          transform={isOpen ? "rotate(180deg)" : ""}
          w={6}
          h={6}
        />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack align="flex-start" pl={3}>
          {Object.keys(supportedChains).map((key) => {
            const id = parseInt(key, 10);
            const chain = supportedChains[id as ChainId];

            return (
              <Button
                display={{ base: "block", md: "none" }}
                variant="unstyled"
                width="full"
                textAlign="left"
                onClick={() => switchNetwork(chain.chainId)}
                fontWeight={chain.chainId === chainId ? "bold" : "light"}
                key={chain.chainId}
              >
                {chain.displayName}
              </Button>
            );
          })}
        </VStack>
      </Collapse>
    </>
  );
};
