export const metadata: any = {
  "1": {
    name: "tiny dinos winter 2022 #1",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1.png",
  },
  "2": {
    name: "tiny dinos winter 2022 #2",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2.png",
  },
  "3": {
    name: "tiny dinos winter 2022 #3",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3.png",
  },
  "4": {
    name: "tiny dinos winter 2022 #4",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4.png",
  },
  "5": {
    name: "tiny dinos winter 2022 #5",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5.png",
  },
  "6": {
    name: "tiny dinos winter 2022 #6",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6.png",
  },
  "7": {
    name: "tiny dinos winter 2022 #7",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7.png",
  },
  "8": {
    name: "tiny dinos winter 2022 #8",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8.png",
  },
  "9": {
    name: "tiny dinos winter 2022 #9",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9.png",
  },
  "10": {
    name: "tiny dinos winter 2022 #10",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 10,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/10.png",
  },
  "11": {
    name: "tiny dinos winter 2022 #11",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 11,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/11.png",
  },
  "12": {
    name: "tiny dinos winter 2022 #12",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 12,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/12.png",
  },
  "13": {
    name: "tiny dinos winter 2022 #13",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 13,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/13.png",
  },
  "14": {
    name: "tiny dinos winter 2022 #14",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 14,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/14.png",
  },
  "15": {
    name: "tiny dinos winter 2022 #15",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 15,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/15.png",
  },
  "16": {
    name: "tiny dinos winter 2022 #16",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 16,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/16.png",
  },
  "17": {
    name: "tiny dinos winter 2022 #17",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 17,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/17.png",
  },
  "18": {
    name: "tiny dinos winter 2022 #18",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 18,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/18.png",
  },
  "19": {
    name: "tiny dinos winter 2022 #19",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 19,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/19.png",
  },
  "20": {
    name: "tiny dinos winter 2022 #20",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 20,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/20.png",
  },
  "21": {
    name: "tiny dinos winter 2022 #21",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 21,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/21.png",
  },
  "22": {
    name: "tiny dinos winter 2022 #22",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 22,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/22.png",
  },
  "23": {
    name: "tiny dinos winter 2022 #23",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 23,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/23.png",
  },
  "24": {
    name: "tiny dinos winter 2022 #24",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 24,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/24.png",
  },
  "25": {
    name: "tiny dinos winter 2022 #25",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 25,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/25.png",
  },
  "26": {
    name: "tiny dinos winter 2022 #26",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 26,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/26.png",
  },
  "27": {
    name: "tiny dinos winter 2022 #27",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 27,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/27.png",
  },
  "28": {
    name: "tiny dinos winter 2022 #28",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 28,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/28.png",
  },
  "29": {
    name: "tiny dinos winter 2022 #29",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 29,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/29.png",
  },
  "30": {
    name: "tiny dinos winter 2022 #30",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 30,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/30.png",
  },
  "31": {
    name: "tiny dinos winter 2022 #31",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 31,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/31.png",
  },
  "32": {
    name: "tiny dinos winter 2022 #32",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 32,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/32.png",
  },
  "33": {
    name: "tiny dinos winter 2022 #33",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 33,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/33.png",
  },
  "34": {
    name: "tiny dinos winter 2022 #34",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 34,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/34.png",
  },
  "35": {
    name: "tiny dinos winter 2022 #35",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 35,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/35.png",
  },
  "36": {
    name: "tiny dinos winter 2022 #36",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 36,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/36.png",
  },
  "37": {
    name: "tiny dinos winter 2022 #37",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 37,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/37.png",
  },
  "38": {
    name: "tiny dinos winter 2022 #38",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 38,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/38.png",
  },
  "39": {
    name: "tiny dinos winter 2022 #39",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 39,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/39.png",
  },
  "40": {
    name: "tiny dinos winter 2022 #40",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 40,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/40.png",
  },
  "41": {
    name: "tiny dinos winter 2022 #41",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 41,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/41.png",
  },
  "42": {
    name: "tiny dinos winter 2022 #42",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 42,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/42.png",
  },
  "43": {
    name: "tiny dinos winter 2022 #43",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 43,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/43.png",
  },
  "44": {
    name: "tiny dinos winter 2022 #44",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 44,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/44.png",
  },
  "45": {
    name: "tiny dinos winter 2022 #45",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 45,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/45.png",
  },
  "46": {
    name: "tiny dinos winter 2022 #46",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 46,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/46.png",
  },
  "47": {
    name: "tiny dinos winter 2022 #47",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 47,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/47.png",
  },
  "48": {
    name: "tiny dinos winter 2022 #48",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 48,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/48.png",
  },
  "49": {
    name: "tiny dinos winter 2022 #49",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 49,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/49.png",
  },
  "50": {
    name: "tiny dinos winter 2022 #50",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 50,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/50.png",
  },
  "51": {
    name: "tiny dinos winter 2022 #51",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 51,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/51.png",
  },
  "52": {
    name: "tiny dinos winter 2022 #52",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 52,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/52.png",
  },
  "53": {
    name: "tiny dinos winter 2022 #53",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 53,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/53.png",
  },
  "54": {
    name: "tiny dinos winter 2022 #54",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 54,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/54.png",
  },
  "55": {
    name: "tiny dinos winter 2022 #55",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 55,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/55.png",
  },
  "56": {
    name: "tiny dinos winter 2022 #56",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 56,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/56.png",
  },
  "57": {
    name: "tiny dinos winter 2022 #57",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 57,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/57.png",
  },
  "58": {
    name: "tiny dinos winter 2022 #58",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 58,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/58.png",
  },
  "59": {
    name: "tiny dinos winter 2022 #59",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 59,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/59.png",
  },
  "60": {
    name: "tiny dinos winter 2022 #60",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 60,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/60.png",
  },
  "61": {
    name: "tiny dinos winter 2022 #61",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 61,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/61.png",
  },
  "62": {
    name: "tiny dinos winter 2022 #62",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 62,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/62.png",
  },
  "63": {
    name: "tiny dinos winter 2022 #63",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 63,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/63.png",
  },
  "64": {
    name: "tiny dinos winter 2022 #64",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 64,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/64.png",
  },
  "65": {
    name: "tiny dinos winter 2022 #65",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 65,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/65.png",
  },
  "66": {
    name: "tiny dinos winter 2022 #66",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 66,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/66.png",
  },
  "67": {
    name: "tiny dinos winter 2022 #67",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 67,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/67.png",
  },
  "68": {
    name: "tiny dinos winter 2022 #68",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 68,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/68.png",
  },
  "69": {
    name: "tiny dinos winter 2022 #69",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 69,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/69.png",
  },
  "70": {
    name: "tiny dinos winter 2022 #70",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 70,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/70.png",
  },
  "71": {
    name: "tiny dinos winter 2022 #71",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 71,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/71.png",
  },
  "72": {
    name: "tiny dinos winter 2022 #72",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 72,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/72.png",
  },
  "73": {
    name: "tiny dinos winter 2022 #73",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 73,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/73.png",
  },
  "74": {
    name: "tiny dinos winter 2022 #74",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 74,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/74.png",
  },
  "75": {
    name: "tiny dinos winter 2022 #75",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 75,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/75.png",
  },
  "76": {
    name: "tiny dinos winter 2022 #76",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 76,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/76.png",
  },
  "77": {
    name: "tiny dinos winter 2022 #77",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 77,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/77.png",
  },
  "78": {
    name: "tiny dinos winter 2022 #78",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 78,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/78.png",
  },
  "79": {
    name: "tiny dinos winter 2022 #79",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 79,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/79.png",
  },
  "80": {
    name: "tiny dinos winter 2022 #80",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 80,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/80.png",
  },
  "81": {
    name: "tiny dinos winter 2022 #81",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 81,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/81.png",
  },
  "82": {
    name: "tiny dinos winter 2022 #82",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 82,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/82.png",
  },
  "83": {
    name: "tiny dinos winter 2022 #83",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 83,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/83.png",
  },
  "84": {
    name: "tiny dinos winter 2022 #84",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 84,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/84.png",
  },
  "85": {
    name: "tiny dinos winter 2022 #85",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 85,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/85.png",
  },
  "86": {
    name: "tiny dinos winter 2022 #86",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 86,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/86.png",
  },
  "87": {
    name: "tiny dinos winter 2022 #87",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 87,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/87.png",
  },
  "88": {
    name: "tiny dinos winter 2022 #88",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 88,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/88.png",
  },
  "89": {
    name: "tiny dinos winter 2022 #89",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 89,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/89.png",
  },
  "90": {
    name: "tiny dinos winter 2022 #90",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 90,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/90.png",
  },
  "91": {
    name: "tiny dinos winter 2022 #91",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 91,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/91.png",
  },
  "92": {
    name: "tiny dinos winter 2022 #92",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 92,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/92.png",
  },
  "93": {
    name: "tiny dinos winter 2022 #93",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 93,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/93.png",
  },
  "94": {
    name: "tiny dinos winter 2022 #94",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 94,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/94.png",
  },
  "95": {
    name: "tiny dinos winter 2022 #95",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 95,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/95.png",
  },
  "96": {
    name: "tiny dinos winter 2022 #96",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 96,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/96.png",
  },
  "97": {
    name: "tiny dinos winter 2022 #97",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 97,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/97.png",
  },
  "98": {
    name: "tiny dinos winter 2022 #98",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 98,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/98.png",
  },
  "99": {
    name: "tiny dinos winter 2022 #99",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 99,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/99.png",
  },
  "100": {
    name: "tiny dinos winter 2022 #100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 100,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/100.png",
  },
  "101": {
    name: "tiny dinos winter 2022 #101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 101,
    attributes: [
      {
        trait_type: "1/1",
        value: "opt",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/101.png",
  },
  "102": {
    name: "tiny dinos winter 2022 #102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 102,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/102.png",
  },
  "103": {
    name: "tiny dinos winter 2022 #103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 103,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/103.png",
  },
  "104": {
    name: "tiny dinos winter 2022 #104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 104,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/104.png",
  },
  "105": {
    name: "tiny dinos winter 2022 #105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 105,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/105.png",
  },
  "106": {
    name: "tiny dinos winter 2022 #106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 106,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/106.png",
  },
  "107": {
    name: "tiny dinos winter 2022 #107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 107,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/107.png",
  },
  "108": {
    name: "tiny dinos winter 2022 #108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 108,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/108.png",
  },
  "109": {
    name: "tiny dinos winter 2022 #109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 109,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/109.png",
  },
  "110": {
    name: "tiny dinos winter 2022 #110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 110,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/110.png",
  },
  "111": {
    name: "tiny dinos winter 2022 #111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 111,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/111.png",
  },
  "112": {
    name: "tiny dinos winter 2022 #112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 112,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/112.png",
  },
  "113": {
    name: "tiny dinos winter 2022 #113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 113,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/113.png",
  },
  "114": {
    name: "tiny dinos winter 2022 #114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 114,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/114.png",
  },
  "115": {
    name: "tiny dinos winter 2022 #115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 115,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/115.png",
  },
  "116": {
    name: "tiny dinos winter 2022 #116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 116,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/116.png",
  },
  "117": {
    name: "tiny dinos winter 2022 #117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 117,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/117.png",
  },
  "118": {
    name: "tiny dinos winter 2022 #118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 118,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/118.png",
  },
  "119": {
    name: "tiny dinos winter 2022 #119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 119,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/119.png",
  },
  "120": {
    name: "tiny dinos winter 2022 #120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 120,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/120.png",
  },
  "121": {
    name: "tiny dinos winter 2022 #121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 121,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/121.png",
  },
  "122": {
    name: "tiny dinos winter 2022 #122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 122,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/122.png",
  },
  "123": {
    name: "tiny dinos winter 2022 #123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 123,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/123.png",
  },
  "124": {
    name: "tiny dinos winter 2022 #124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 124,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/124.png",
  },
  "125": {
    name: "tiny dinos winter 2022 #125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 125,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/125.png",
  },
  "126": {
    name: "tiny dinos winter 2022 #126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 126,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/126.png",
  },
  "127": {
    name: "tiny dinos winter 2022 #127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 127,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/127.png",
  },
  "128": {
    name: "tiny dinos winter 2022 #128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 128,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/128.png",
  },
  "129": {
    name: "tiny dinos winter 2022 #129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 129,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/129.png",
  },
  "130": {
    name: "tiny dinos winter 2022 #130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 130,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/130.png",
  },
  "131": {
    name: "tiny dinos winter 2022 #131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 131,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/131.png",
  },
  "132": {
    name: "tiny dinos winter 2022 #132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 132,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/132.png",
  },
  "133": {
    name: "tiny dinos winter 2022 #133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 133,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/133.png",
  },
  "134": {
    name: "tiny dinos winter 2022 #134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 134,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/134.png",
  },
  "135": {
    name: "tiny dinos winter 2022 #135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 135,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/135.png",
  },
  "136": {
    name: "tiny dinos winter 2022 #136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 136,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/136.png",
  },
  "137": {
    name: "tiny dinos winter 2022 #137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 137,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/137.png",
  },
  "138": {
    name: "tiny dinos winter 2022 #138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 138,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/138.png",
  },
  "139": {
    name: "tiny dinos winter 2022 #139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 139,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/139.png",
  },
  "140": {
    name: "tiny dinos winter 2022 #140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 140,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/140.png",
  },
  "141": {
    name: "tiny dinos winter 2022 #141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 141,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/141.png",
  },
  "142": {
    name: "tiny dinos winter 2022 #142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 142,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/142.png",
  },
  "143": {
    name: "tiny dinos winter 2022 #143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 143,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/143.png",
  },
  "144": {
    name: "tiny dinos winter 2022 #144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 144,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/144.png",
  },
  "145": {
    name: "tiny dinos winter 2022 #145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 145,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/145.png",
  },
  "146": {
    name: "tiny dinos winter 2022 #146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 146,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/146.png",
  },
  "147": {
    name: "tiny dinos winter 2022 #147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 147,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/147.png",
  },
  "148": {
    name: "tiny dinos winter 2022 #148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 148,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/148.png",
  },
  "149": {
    name: "tiny dinos winter 2022 #149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 149,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/149.png",
  },
  "150": {
    name: "tiny dinos winter 2022 #150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 150,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/150.png",
  },
  "151": {
    name: "tiny dinos winter 2022 #151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 151,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/151.png",
  },
  "152": {
    name: "tiny dinos winter 2022 #152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 152,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/152.png",
  },
  "153": {
    name: "tiny dinos winter 2022 #153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 153,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/153.png",
  },
  "154": {
    name: "tiny dinos winter 2022 #154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 154,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/154.png",
  },
  "155": {
    name: "tiny dinos winter 2022 #155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 155,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/155.png",
  },
  "156": {
    name: "tiny dinos winter 2022 #156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 156,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/156.png",
  },
  "157": {
    name: "tiny dinos winter 2022 #157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 157,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/157.png",
  },
  "158": {
    name: "tiny dinos winter 2022 #158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 158,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/158.png",
  },
  "159": {
    name: "tiny dinos winter 2022 #159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 159,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/159.png",
  },
  "160": {
    name: "tiny dinos winter 2022 #160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 160,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/160.png",
  },
  "161": {
    name: "tiny dinos winter 2022 #161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 161,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/161.png",
  },
  "162": {
    name: "tiny dinos winter 2022 #162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 162,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/162.png",
  },
  "163": {
    name: "tiny dinos winter 2022 #163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 163,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/163.png",
  },
  "164": {
    name: "tiny dinos winter 2022 #164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 164,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/164.png",
  },
  "165": {
    name: "tiny dinos winter 2022 #165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 165,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/165.png",
  },
  "166": {
    name: "tiny dinos winter 2022 #166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 166,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/166.png",
  },
  "167": {
    name: "tiny dinos winter 2022 #167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 167,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/167.png",
  },
  "168": {
    name: "tiny dinos winter 2022 #168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 168,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/168.png",
  },
  "169": {
    name: "tiny dinos winter 2022 #169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 169,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/169.png",
  },
  "170": {
    name: "tiny dinos winter 2022 #170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 170,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/170.png",
  },
  "171": {
    name: "tiny dinos winter 2022 #171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 171,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/171.png",
  },
  "172": {
    name: "tiny dinos winter 2022 #172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 172,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/172.png",
  },
  "173": {
    name: "tiny dinos winter 2022 #173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 173,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/173.png",
  },
  "174": {
    name: "tiny dinos winter 2022 #174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 174,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/174.png",
  },
  "175": {
    name: "tiny dinos winter 2022 #175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 175,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/175.png",
  },
  "176": {
    name: "tiny dinos winter 2022 #176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 176,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/176.png",
  },
  "177": {
    name: "tiny dinos winter 2022 #177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 177,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/177.png",
  },
  "178": {
    name: "tiny dinos winter 2022 #178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 178,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/178.png",
  },
  "179": {
    name: "tiny dinos winter 2022 #179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 179,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/179.png",
  },
  "180": {
    name: "tiny dinos winter 2022 #180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 180,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/180.png",
  },
  "181": {
    name: "tiny dinos winter 2022 #181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 181,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/181.png",
  },
  "182": {
    name: "tiny dinos winter 2022 #182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 182,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/182.png",
  },
  "183": {
    name: "tiny dinos winter 2022 #183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 183,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/183.png",
  },
  "184": {
    name: "tiny dinos winter 2022 #184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 184,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/184.png",
  },
  "185": {
    name: "tiny dinos winter 2022 #185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 185,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/185.png",
  },
  "186": {
    name: "tiny dinos winter 2022 #186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 186,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/186.png",
  },
  "187": {
    name: "tiny dinos winter 2022 #187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 187,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/187.png",
  },
  "188": {
    name: "tiny dinos winter 2022 #188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 188,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/188.png",
  },
  "189": {
    name: "tiny dinos winter 2022 #189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 189,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/189.png",
  },
  "190": {
    name: "tiny dinos winter 2022 #190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 190,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/190.png",
  },
  "191": {
    name: "tiny dinos winter 2022 #191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 191,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/191.png",
  },
  "192": {
    name: "tiny dinos winter 2022 #192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 192,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/192.png",
  },
  "193": {
    name: "tiny dinos winter 2022 #193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 193,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/193.png",
  },
  "194": {
    name: "tiny dinos winter 2022 #194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 194,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/194.png",
  },
  "195": {
    name: "tiny dinos winter 2022 #195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 195,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/195.png",
  },
  "196": {
    name: "tiny dinos winter 2022 #196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 196,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/196.png",
  },
  "197": {
    name: "tiny dinos winter 2022 #197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 197,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/197.png",
  },
  "198": {
    name: "tiny dinos winter 2022 #198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 198,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/198.png",
  },
  "199": {
    name: "tiny dinos winter 2022 #199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 199,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/199.png",
  },
  "200": {
    name: "tiny dinos winter 2022 #200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 200,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/200.png",
  },
  "201": {
    name: "tiny dinos winter 2022 #201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 201,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/201.png",
  },
  "202": {
    name: "tiny dinos winter 2022 #202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 202,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/202.png",
  },
  "203": {
    name: "tiny dinos winter 2022 #203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 203,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/203.png",
  },
  "204": {
    name: "tiny dinos winter 2022 #204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 204,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/204.png",
  },
  "205": {
    name: "tiny dinos winter 2022 #205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 205,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/205.png",
  },
  "206": {
    name: "tiny dinos winter 2022 #206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 206,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/206.png",
  },
  "207": {
    name: "tiny dinos winter 2022 #207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 207,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/207.png",
  },
  "208": {
    name: "tiny dinos winter 2022 #208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 208,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/208.png",
  },
  "209": {
    name: "tiny dinos winter 2022 #209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 209,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/209.png",
  },
  "210": {
    name: "tiny dinos winter 2022 #210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 210,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/210.png",
  },
  "211": {
    name: "tiny dinos winter 2022 #211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 211,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/211.png",
  },
  "212": {
    name: "tiny dinos winter 2022 #212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 212,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/212.png",
  },
  "213": {
    name: "tiny dinos winter 2022 #213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 213,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/213.png",
  },
  "214": {
    name: "tiny dinos winter 2022 #214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 214,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/214.png",
  },
  "215": {
    name: "tiny dinos winter 2022 #215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 215,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/215.png",
  },
  "216": {
    name: "tiny dinos winter 2022 #216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 216,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/216.png",
  },
  "217": {
    name: "tiny dinos winter 2022 #217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 217,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/217.png",
  },
  "218": {
    name: "tiny dinos winter 2022 #218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 218,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/218.png",
  },
  "219": {
    name: "tiny dinos winter 2022 #219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 219,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/219.png",
  },
  "220": {
    name: "tiny dinos winter 2022 #220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 220,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/220.png",
  },
  "221": {
    name: "tiny dinos winter 2022 #221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 221,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/221.png",
  },
  "222": {
    name: "tiny dinos winter 2022 #222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 222,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/222.png",
  },
  "223": {
    name: "tiny dinos winter 2022 #223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 223,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/223.png",
  },
  "224": {
    name: "tiny dinos winter 2022 #224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 224,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/224.png",
  },
  "225": {
    name: "tiny dinos winter 2022 #225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 225,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/225.png",
  },
  "226": {
    name: "tiny dinos winter 2022 #226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 226,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/226.png",
  },
  "227": {
    name: "tiny dinos winter 2022 #227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 227,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/227.png",
  },
  "228": {
    name: "tiny dinos winter 2022 #228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 228,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/228.png",
  },
  "229": {
    name: "tiny dinos winter 2022 #229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 229,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/229.png",
  },
  "230": {
    name: "tiny dinos winter 2022 #230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 230,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/230.png",
  },
  "231": {
    name: "tiny dinos winter 2022 #231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 231,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/231.png",
  },
  "232": {
    name: "tiny dinos winter 2022 #232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 232,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/232.png",
  },
  "233": {
    name: "tiny dinos winter 2022 #233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 233,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/233.png",
  },
  "234": {
    name: "tiny dinos winter 2022 #234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 234,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/234.png",
  },
  "235": {
    name: "tiny dinos winter 2022 #235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 235,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/235.png",
  },
  "236": {
    name: "tiny dinos winter 2022 #236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 236,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/236.png",
  },
  "237": {
    name: "tiny dinos winter 2022 #237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 237,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/237.png",
  },
  "238": {
    name: "tiny dinos winter 2022 #238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 238,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/238.png",
  },
  "239": {
    name: "tiny dinos winter 2022 #239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 239,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/239.png",
  },
  "240": {
    name: "tiny dinos winter 2022 #240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 240,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/240.png",
  },
  "241": {
    name: "tiny dinos winter 2022 #241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 241,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/241.png",
  },
  "242": {
    name: "tiny dinos winter 2022 #242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 242,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/242.png",
  },
  "243": {
    name: "tiny dinos winter 2022 #243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 243,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/243.png",
  },
  "244": {
    name: "tiny dinos winter 2022 #244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 244,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/244.png",
  },
  "245": {
    name: "tiny dinos winter 2022 #245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 245,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/245.png",
  },
  "246": {
    name: "tiny dinos winter 2022 #246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 246,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/246.png",
  },
  "247": {
    name: "tiny dinos winter 2022 #247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 247,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/247.png",
  },
  "248": {
    name: "tiny dinos winter 2022 #248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 248,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/248.png",
  },
  "249": {
    name: "tiny dinos winter 2022 #249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 249,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/249.png",
  },
  "250": {
    name: "tiny dinos winter 2022 #250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 250,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/250.png",
  },
  "251": {
    name: "tiny dinos winter 2022 #251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 251,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/251.png",
  },
  "252": {
    name: "tiny dinos winter 2022 #252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 252,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/252.png",
  },
  "253": {
    name: "tiny dinos winter 2022 #253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 253,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/253.png",
  },
  "254": {
    name: "tiny dinos winter 2022 #254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 254,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/254.png",
  },
  "255": {
    name: "tiny dinos winter 2022 #255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 255,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/255.png",
  },
  "256": {
    name: "tiny dinos winter 2022 #256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 256,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/256.png",
  },
  "257": {
    name: "tiny dinos winter 2022 #257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 257,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/257.png",
  },
  "258": {
    name: "tiny dinos winter 2022 #258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 258,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/258.png",
  },
  "259": {
    name: "tiny dinos winter 2022 #259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 259,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/259.png",
  },
  "260": {
    name: "tiny dinos winter 2022 #260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 260,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/260.png",
  },
  "261": {
    name: "tiny dinos winter 2022 #261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 261,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/261.png",
  },
  "262": {
    name: "tiny dinos winter 2022 #262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 262,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/262.png",
  },
  "263": {
    name: "tiny dinos winter 2022 #263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 263,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/263.png",
  },
  "264": {
    name: "tiny dinos winter 2022 #264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 264,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/264.png",
  },
  "265": {
    name: "tiny dinos winter 2022 #265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 265,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/265.png",
  },
  "266": {
    name: "tiny dinos winter 2022 #266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 266,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/266.png",
  },
  "267": {
    name: "tiny dinos winter 2022 #267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 267,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/267.png",
  },
  "268": {
    name: "tiny dinos winter 2022 #268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 268,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/268.png",
  },
  "269": {
    name: "tiny dinos winter 2022 #269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 269,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/269.png",
  },
  "270": {
    name: "tiny dinos winter 2022 #270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 270,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/270.png",
  },
  "271": {
    name: "tiny dinos winter 2022 #271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 271,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/271.png",
  },
  "272": {
    name: "tiny dinos winter 2022 #272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 272,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/272.png",
  },
  "273": {
    name: "tiny dinos winter 2022 #273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 273,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/273.png",
  },
  "274": {
    name: "tiny dinos winter 2022 #274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 274,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/274.png",
  },
  "275": {
    name: "tiny dinos winter 2022 #275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 275,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/275.png",
  },
  "276": {
    name: "tiny dinos winter 2022 #276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 276,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/276.png",
  },
  "277": {
    name: "tiny dinos winter 2022 #277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 277,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/277.png",
  },
  "278": {
    name: "tiny dinos winter 2022 #278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 278,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/278.png",
  },
  "279": {
    name: "tiny dinos winter 2022 #279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 279,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/279.png",
  },
  "280": {
    name: "tiny dinos winter 2022 #280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 280,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/280.png",
  },
  "281": {
    name: "tiny dinos winter 2022 #281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 281,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/281.png",
  },
  "282": {
    name: "tiny dinos winter 2022 #282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 282,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/282.png",
  },
  "283": {
    name: "tiny dinos winter 2022 #283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 283,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/283.png",
  },
  "284": {
    name: "tiny dinos winter 2022 #284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 284,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/284.png",
  },
  "285": {
    name: "tiny dinos winter 2022 #285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 285,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/285.png",
  },
  "286": {
    name: "tiny dinos winter 2022 #286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 286,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/286.png",
  },
  "287": {
    name: "tiny dinos winter 2022 #287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 287,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/287.png",
  },
  "288": {
    name: "tiny dinos winter 2022 #288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 288,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/288.png",
  },
  "289": {
    name: "tiny dinos winter 2022 #289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 289,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/289.png",
  },
  "290": {
    name: "tiny dinos winter 2022 #290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 290,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/290.png",
  },
  "291": {
    name: "tiny dinos winter 2022 #291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 291,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/291.png",
  },
  "292": {
    name: "tiny dinos winter 2022 #292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 292,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/292.png",
  },
  "293": {
    name: "tiny dinos winter 2022 #293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 293,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/293.png",
  },
  "294": {
    name: "tiny dinos winter 2022 #294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 294,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/294.png",
  },
  "295": {
    name: "tiny dinos winter 2022 #295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 295,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/295.png",
  },
  "296": {
    name: "tiny dinos winter 2022 #296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 296,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/296.png",
  },
  "297": {
    name: "tiny dinos winter 2022 #297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 297,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/297.png",
  },
  "298": {
    name: "tiny dinos winter 2022 #298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 298,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/298.png",
  },
  "299": {
    name: "tiny dinos winter 2022 #299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 299,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/299.png",
  },
  "300": {
    name: "tiny dinos winter 2022 #300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 300,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/300.png",
  },
  "301": {
    name: "tiny dinos winter 2022 #301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 301,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/301.png",
  },
  "302": {
    name: "tiny dinos winter 2022 #302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 302,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/302.png",
  },
  "303": {
    name: "tiny dinos winter 2022 #303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 303,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/303.png",
  },
  "304": {
    name: "tiny dinos winter 2022 #304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 304,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/304.png",
  },
  "305": {
    name: "tiny dinos winter 2022 #305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 305,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/305.png",
  },
  "306": {
    name: "tiny dinos winter 2022 #306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 306,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/306.png",
  },
  "307": {
    name: "tiny dinos winter 2022 #307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 307,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/307.png",
  },
  "308": {
    name: "tiny dinos winter 2022 #308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 308,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/308.png",
  },
  "309": {
    name: "tiny dinos winter 2022 #309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 309,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/309.png",
  },
  "310": {
    name: "tiny dinos winter 2022 #310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 310,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/310.png",
  },
  "311": {
    name: "tiny dinos winter 2022 #311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 311,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/311.png",
  },
  "312": {
    name: "tiny dinos winter 2022 #312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 312,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/312.png",
  },
  "313": {
    name: "tiny dinos winter 2022 #313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 313,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/313.png",
  },
  "314": {
    name: "tiny dinos winter 2022 #314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 314,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/314.png",
  },
  "315": {
    name: "tiny dinos winter 2022 #315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 315,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/315.png",
  },
  "316": {
    name: "tiny dinos winter 2022 #316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 316,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/316.png",
  },
  "317": {
    name: "tiny dinos winter 2022 #317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 317,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/317.png",
  },
  "318": {
    name: "tiny dinos winter 2022 #318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 318,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/318.png",
  },
  "319": {
    name: "tiny dinos winter 2022 #319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 319,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/319.png",
  },
  "320": {
    name: "tiny dinos winter 2022 #320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 320,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/320.png",
  },
  "321": {
    name: "tiny dinos winter 2022 #321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 321,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/321.png",
  },
  "322": {
    name: "tiny dinos winter 2022 #322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 322,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/322.png",
  },
  "323": {
    name: "tiny dinos winter 2022 #323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 323,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/323.png",
  },
  "324": {
    name: "tiny dinos winter 2022 #324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 324,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/324.png",
  },
  "325": {
    name: "tiny dinos winter 2022 #325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 325,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/325.png",
  },
  "326": {
    name: "tiny dinos winter 2022 #326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 326,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/326.png",
  },
  "327": {
    name: "tiny dinos winter 2022 #327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 327,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/327.png",
  },
  "328": {
    name: "tiny dinos winter 2022 #328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 328,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/328.png",
  },
  "329": {
    name: "tiny dinos winter 2022 #329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 329,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/329.png",
  },
  "330": {
    name: "tiny dinos winter 2022 #330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 330,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/330.png",
  },
  "331": {
    name: "tiny dinos winter 2022 #331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 331,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/331.png",
  },
  "332": {
    name: "tiny dinos winter 2022 #332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 332,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/332.png",
  },
  "333": {
    name: "tiny dinos winter 2022 #333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 333,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/333.png",
  },
  "334": {
    name: "tiny dinos winter 2022 #334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 334,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/334.png",
  },
  "335": {
    name: "tiny dinos winter 2022 #335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 335,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/335.png",
  },
  "336": {
    name: "tiny dinos winter 2022 #336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 336,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/336.png",
  },
  "337": {
    name: "tiny dinos winter 2022 #337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 337,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/337.png",
  },
  "338": {
    name: "tiny dinos winter 2022 #338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 338,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/338.png",
  },
  "339": {
    name: "tiny dinos winter 2022 #339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 339,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/339.png",
  },
  "340": {
    name: "tiny dinos winter 2022 #340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 340,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/340.png",
  },
  "341": {
    name: "tiny dinos winter 2022 #341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 341,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/341.png",
  },
  "342": {
    name: "tiny dinos winter 2022 #342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 342,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/342.png",
  },
  "343": {
    name: "tiny dinos winter 2022 #343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 343,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/343.png",
  },
  "344": {
    name: "tiny dinos winter 2022 #344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 344,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/344.png",
  },
  "345": {
    name: "tiny dinos winter 2022 #345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 345,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/345.png",
  },
  "346": {
    name: "tiny dinos winter 2022 #346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 346,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/346.png",
  },
  "347": {
    name: "tiny dinos winter 2022 #347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 347,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/347.png",
  },
  "348": {
    name: "tiny dinos winter 2022 #348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 348,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/348.png",
  },
  "349": {
    name: "tiny dinos winter 2022 #349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 349,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/349.png",
  },
  "350": {
    name: "tiny dinos winter 2022 #350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 350,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/350.png",
  },
  "351": {
    name: "tiny dinos winter 2022 #351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 351,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/351.png",
  },
  "352": {
    name: "tiny dinos winter 2022 #352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 352,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/352.png",
  },
  "353": {
    name: "tiny dinos winter 2022 #353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 353,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/353.png",
  },
  "354": {
    name: "tiny dinos winter 2022 #354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 354,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/354.png",
  },
  "355": {
    name: "tiny dinos winter 2022 #355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 355,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/355.png",
  },
  "356": {
    name: "tiny dinos winter 2022 #356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 356,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/356.png",
  },
  "357": {
    name: "tiny dinos winter 2022 #357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 357,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/357.png",
  },
  "358": {
    name: "tiny dinos winter 2022 #358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 358,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/358.png",
  },
  "359": {
    name: "tiny dinos winter 2022 #359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 359,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/359.png",
  },
  "360": {
    name: "tiny dinos winter 2022 #360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 360,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/360.png",
  },
  "361": {
    name: "tiny dinos winter 2022 #361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 361,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/361.png",
  },
  "362": {
    name: "tiny dinos winter 2022 #362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 362,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/362.png",
  },
  "363": {
    name: "tiny dinos winter 2022 #363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 363,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/363.png",
  },
  "364": {
    name: "tiny dinos winter 2022 #364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 364,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/364.png",
  },
  "365": {
    name: "tiny dinos winter 2022 #365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 365,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/365.png",
  },
  "366": {
    name: "tiny dinos winter 2022 #366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 366,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/366.png",
  },
  "367": {
    name: "tiny dinos winter 2022 #367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 367,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/367.png",
  },
  "368": {
    name: "tiny dinos winter 2022 #368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 368,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/368.png",
  },
  "369": {
    name: "tiny dinos winter 2022 #369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 369,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/369.png",
  },
  "370": {
    name: "tiny dinos winter 2022 #370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 370,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/370.png",
  },
  "371": {
    name: "tiny dinos winter 2022 #371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 371,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/371.png",
  },
  "372": {
    name: "tiny dinos winter 2022 #372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 372,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/372.png",
  },
  "373": {
    name: "tiny dinos winter 2022 #373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 373,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/373.png",
  },
  "374": {
    name: "tiny dinos winter 2022 #374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 374,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/374.png",
  },
  "375": {
    name: "tiny dinos winter 2022 #375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 375,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/375.png",
  },
  "376": {
    name: "tiny dinos winter 2022 #376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 376,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/376.png",
  },
  "377": {
    name: "tiny dinos winter 2022 #377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 377,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/377.png",
  },
  "378": {
    name: "tiny dinos winter 2022 #378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 378,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/378.png",
  },
  "379": {
    name: "tiny dinos winter 2022 #379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 379,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/379.png",
  },
  "380": {
    name: "tiny dinos winter 2022 #380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 380,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/380.png",
  },
  "381": {
    name: "tiny dinos winter 2022 #381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 381,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/381.png",
  },
  "382": {
    name: "tiny dinos winter 2022 #382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 382,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/382.png",
  },
  "383": {
    name: "tiny dinos winter 2022 #383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 383,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/383.png",
  },
  "384": {
    name: "tiny dinos winter 2022 #384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 384,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/384.png",
  },
  "385": {
    name: "tiny dinos winter 2022 #385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 385,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/385.png",
  },
  "386": {
    name: "tiny dinos winter 2022 #386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 386,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/386.png",
  },
  "387": {
    name: "tiny dinos winter 2022 #387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 387,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/387.png",
  },
  "388": {
    name: "tiny dinos winter 2022 #388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 388,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/388.png",
  },
  "389": {
    name: "tiny dinos winter 2022 #389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 389,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/389.png",
  },
  "390": {
    name: "tiny dinos winter 2022 #390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 390,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/390.png",
  },
  "391": {
    name: "tiny dinos winter 2022 #391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 391,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/391.png",
  },
  "392": {
    name: "tiny dinos winter 2022 #392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 392,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/392.png",
  },
  "393": {
    name: "tiny dinos winter 2022 #393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 393,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/393.png",
  },
  "394": {
    name: "tiny dinos winter 2022 #394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 394,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/394.png",
  },
  "395": {
    name: "tiny dinos winter 2022 #395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 395,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/395.png",
  },
  "396": {
    name: "tiny dinos winter 2022 #396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 396,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/396.png",
  },
  "397": {
    name: "tiny dinos winter 2022 #397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 397,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/397.png",
  },
  "398": {
    name: "tiny dinos winter 2022 #398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 398,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/398.png",
  },
  "399": {
    name: "tiny dinos winter 2022 #399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 399,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/399.png",
  },
  "400": {
    name: "tiny dinos winter 2022 #400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 400,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/400.png",
  },
  "401": {
    name: "tiny dinos winter 2022 #401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 401,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/401.png",
  },
  "402": {
    name: "tiny dinos winter 2022 #402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 402,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/402.png",
  },
  "403": {
    name: "tiny dinos winter 2022 #403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 403,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/403.png",
  },
  "404": {
    name: "tiny dinos winter 2022 #404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 404,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/404.png",
  },
  "405": {
    name: "tiny dinos winter 2022 #405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 405,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/405.png",
  },
  "406": {
    name: "tiny dinos winter 2022 #406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 406,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/406.png",
  },
  "407": {
    name: "tiny dinos winter 2022 #407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 407,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/407.png",
  },
  "408": {
    name: "tiny dinos winter 2022 #408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 408,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/408.png",
  },
  "409": {
    name: "tiny dinos winter 2022 #409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 409,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/409.png",
  },
  "410": {
    name: "tiny dinos winter 2022 #410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 410,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/410.png",
  },
  "411": {
    name: "tiny dinos winter 2022 #411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 411,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/411.png",
  },
  "412": {
    name: "tiny dinos winter 2022 #412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 412,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/412.png",
  },
  "413": {
    name: "tiny dinos winter 2022 #413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 413,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/413.png",
  },
  "414": {
    name: "tiny dinos winter 2022 #414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 414,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/414.png",
  },
  "415": {
    name: "tiny dinos winter 2022 #415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 415,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/415.png",
  },
  "416": {
    name: "tiny dinos winter 2022 #416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 416,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/416.png",
  },
  "417": {
    name: "tiny dinos winter 2022 #417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 417,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/417.png",
  },
  "418": {
    name: "tiny dinos winter 2022 #418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 418,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/418.png",
  },
  "419": {
    name: "tiny dinos winter 2022 #419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 419,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/419.png",
  },
  "420": {
    name: "tiny dinos winter 2022 #420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 420,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/420.png",
  },
  "421": {
    name: "tiny dinos winter 2022 #421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 421,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/421.png",
  },
  "422": {
    name: "tiny dinos winter 2022 #422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 422,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/422.png",
  },
  "423": {
    name: "tiny dinos winter 2022 #423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 423,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/423.png",
  },
  "424": {
    name: "tiny dinos winter 2022 #424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 424,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/424.png",
  },
  "425": {
    name: "tiny dinos winter 2022 #425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 425,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/425.png",
  },
  "426": {
    name: "tiny dinos winter 2022 #426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 426,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/426.png",
  },
  "427": {
    name: "tiny dinos winter 2022 #427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 427,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/427.png",
  },
  "428": {
    name: "tiny dinos winter 2022 #428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 428,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/428.png",
  },
  "429": {
    name: "tiny dinos winter 2022 #429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 429,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/429.png",
  },
  "430": {
    name: "tiny dinos winter 2022 #430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 430,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/430.png",
  },
  "431": {
    name: "tiny dinos winter 2022 #431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 431,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/431.png",
  },
  "432": {
    name: "tiny dinos winter 2022 #432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 432,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/432.png",
  },
  "433": {
    name: "tiny dinos winter 2022 #433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 433,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/433.png",
  },
  "434": {
    name: "tiny dinos winter 2022 #434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 434,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/434.png",
  },
  "435": {
    name: "tiny dinos winter 2022 #435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 435,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/435.png",
  },
  "436": {
    name: "tiny dinos winter 2022 #436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 436,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/436.png",
  },
  "437": {
    name: "tiny dinos winter 2022 #437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 437,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/437.png",
  },
  "438": {
    name: "tiny dinos winter 2022 #438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 438,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/438.png",
  },
  "439": {
    name: "tiny dinos winter 2022 #439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 439,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/439.png",
  },
  "440": {
    name: "tiny dinos winter 2022 #440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 440,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/440.png",
  },
  "441": {
    name: "tiny dinos winter 2022 #441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 441,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/441.png",
  },
  "442": {
    name: "tiny dinos winter 2022 #442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 442,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/442.png",
  },
  "443": {
    name: "tiny dinos winter 2022 #443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 443,
    attributes: [
      {
        trait_type: "1/1",
        value: "ftm",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/443.png",
  },
  "444": {
    name: "tiny dinos winter 2022 #444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 444,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/444.png",
  },
  "445": {
    name: "tiny dinos winter 2022 #445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 445,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/445.png",
  },
  "446": {
    name: "tiny dinos winter 2022 #446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 446,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/446.png",
  },
  "447": {
    name: "tiny dinos winter 2022 #447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 447,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/447.png",
  },
  "448": {
    name: "tiny dinos winter 2022 #448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 448,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/448.png",
  },
  "449": {
    name: "tiny dinos winter 2022 #449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 449,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/449.png",
  },
  "450": {
    name: "tiny dinos winter 2022 #450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 450,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/450.png",
  },
  "451": {
    name: "tiny dinos winter 2022 #451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 451,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/451.png",
  },
  "452": {
    name: "tiny dinos winter 2022 #452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 452,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/452.png",
  },
  "453": {
    name: "tiny dinos winter 2022 #453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 453,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/453.png",
  },
  "454": {
    name: "tiny dinos winter 2022 #454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 454,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/454.png",
  },
  "455": {
    name: "tiny dinos winter 2022 #455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 455,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/455.png",
  },
  "456": {
    name: "tiny dinos winter 2022 #456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 456,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/456.png",
  },
  "457": {
    name: "tiny dinos winter 2022 #457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 457,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/457.png",
  },
  "458": {
    name: "tiny dinos winter 2022 #458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 458,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/458.png",
  },
  "459": {
    name: "tiny dinos winter 2022 #459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 459,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/459.png",
  },
  "460": {
    name: "tiny dinos winter 2022 #460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 460,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/460.png",
  },
  "461": {
    name: "tiny dinos winter 2022 #461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 461,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/461.png",
  },
  "462": {
    name: "tiny dinos winter 2022 #462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 462,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/462.png",
  },
  "463": {
    name: "tiny dinos winter 2022 #463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 463,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/463.png",
  },
  "464": {
    name: "tiny dinos winter 2022 #464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 464,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/464.png",
  },
  "465": {
    name: "tiny dinos winter 2022 #465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 465,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/465.png",
  },
  "466": {
    name: "tiny dinos winter 2022 #466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 466,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/466.png",
  },
  "467": {
    name: "tiny dinos winter 2022 #467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 467,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/467.png",
  },
  "468": {
    name: "tiny dinos winter 2022 #468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 468,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/468.png",
  },
  "469": {
    name: "tiny dinos winter 2022 #469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 469,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/469.png",
  },
  "470": {
    name: "tiny dinos winter 2022 #470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 470,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/470.png",
  },
  "471": {
    name: "tiny dinos winter 2022 #471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 471,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/471.png",
  },
  "472": {
    name: "tiny dinos winter 2022 #472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 472,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/472.png",
  },
  "473": {
    name: "tiny dinos winter 2022 #473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 473,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/473.png",
  },
  "474": {
    name: "tiny dinos winter 2022 #474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 474,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/474.png",
  },
  "475": {
    name: "tiny dinos winter 2022 #475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 475,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/475.png",
  },
  "476": {
    name: "tiny dinos winter 2022 #476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 476,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/476.png",
  },
  "477": {
    name: "tiny dinos winter 2022 #477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 477,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/477.png",
  },
  "478": {
    name: "tiny dinos winter 2022 #478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 478,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/478.png",
  },
  "479": {
    name: "tiny dinos winter 2022 #479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 479,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/479.png",
  },
  "480": {
    name: "tiny dinos winter 2022 #480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 480,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/480.png",
  },
  "481": {
    name: "tiny dinos winter 2022 #481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 481,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/481.png",
  },
  "482": {
    name: "tiny dinos winter 2022 #482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 482,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/482.png",
  },
  "483": {
    name: "tiny dinos winter 2022 #483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 483,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/483.png",
  },
  "484": {
    name: "tiny dinos winter 2022 #484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 484,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/484.png",
  },
  "485": {
    name: "tiny dinos winter 2022 #485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 485,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/485.png",
  },
  "486": {
    name: "tiny dinos winter 2022 #486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 486,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/486.png",
  },
  "487": {
    name: "tiny dinos winter 2022 #487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 487,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/487.png",
  },
  "488": {
    name: "tiny dinos winter 2022 #488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 488,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/488.png",
  },
  "489": {
    name: "tiny dinos winter 2022 #489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 489,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/489.png",
  },
  "490": {
    name: "tiny dinos winter 2022 #490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 490,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/490.png",
  },
  "491": {
    name: "tiny dinos winter 2022 #491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 491,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/491.png",
  },
  "492": {
    name: "tiny dinos winter 2022 #492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 492,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/492.png",
  },
  "493": {
    name: "tiny dinos winter 2022 #493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 493,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/493.png",
  },
  "494": {
    name: "tiny dinos winter 2022 #494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 494,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/494.png",
  },
  "495": {
    name: "tiny dinos winter 2022 #495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 495,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/495.png",
  },
  "496": {
    name: "tiny dinos winter 2022 #496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 496,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/496.png",
  },
  "497": {
    name: "tiny dinos winter 2022 #497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 497,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/497.png",
  },
  "498": {
    name: "tiny dinos winter 2022 #498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 498,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/498.png",
  },
  "499": {
    name: "tiny dinos winter 2022 #499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 499,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/499.png",
  },
  "500": {
    name: "tiny dinos winter 2022 #500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 500,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/500.png",
  },
  "501": {
    name: "tiny dinos winter 2022 #501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 501,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/501.png",
  },
  "502": {
    name: "tiny dinos winter 2022 #502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 502,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/502.png",
  },
  "503": {
    name: "tiny dinos winter 2022 #503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 503,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/503.png",
  },
  "504": {
    name: "tiny dinos winter 2022 #504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 504,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/504.png",
  },
  "505": {
    name: "tiny dinos winter 2022 #505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 505,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/505.png",
  },
  "506": {
    name: "tiny dinos winter 2022 #506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 506,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/506.png",
  },
  "507": {
    name: "tiny dinos winter 2022 #507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 507,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/507.png",
  },
  "508": {
    name: "tiny dinos winter 2022 #508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 508,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/508.png",
  },
  "509": {
    name: "tiny dinos winter 2022 #509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 509,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/509.png",
  },
  "510": {
    name: "tiny dinos winter 2022 #510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 510,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/510.png",
  },
  "511": {
    name: "tiny dinos winter 2022 #511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 511,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/511.png",
  },
  "512": {
    name: "tiny dinos winter 2022 #512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 512,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/512.png",
  },
  "513": {
    name: "tiny dinos winter 2022 #513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 513,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/513.png",
  },
  "514": {
    name: "tiny dinos winter 2022 #514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 514,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/514.png",
  },
  "515": {
    name: "tiny dinos winter 2022 #515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 515,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/515.png",
  },
  "516": {
    name: "tiny dinos winter 2022 #516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 516,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/516.png",
  },
  "517": {
    name: "tiny dinos winter 2022 #517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 517,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/517.png",
  },
  "518": {
    name: "tiny dinos winter 2022 #518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 518,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/518.png",
  },
  "519": {
    name: "tiny dinos winter 2022 #519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 519,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/519.png",
  },
  "520": {
    name: "tiny dinos winter 2022 #520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 520,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/520.png",
  },
  "521": {
    name: "tiny dinos winter 2022 #521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 521,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/521.png",
  },
  "522": {
    name: "tiny dinos winter 2022 #522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 522,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/522.png",
  },
  "523": {
    name: "tiny dinos winter 2022 #523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 523,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/523.png",
  },
  "524": {
    name: "tiny dinos winter 2022 #524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 524,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/524.png",
  },
  "525": {
    name: "tiny dinos winter 2022 #525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 525,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/525.png",
  },
  "526": {
    name: "tiny dinos winter 2022 #526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 526,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/526.png",
  },
  "527": {
    name: "tiny dinos winter 2022 #527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 527,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/527.png",
  },
  "528": {
    name: "tiny dinos winter 2022 #528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 528,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/528.png",
  },
  "529": {
    name: "tiny dinos winter 2022 #529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 529,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/529.png",
  },
  "530": {
    name: "tiny dinos winter 2022 #530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 530,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/530.png",
  },
  "531": {
    name: "tiny dinos winter 2022 #531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 531,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/531.png",
  },
  "532": {
    name: "tiny dinos winter 2022 #532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 532,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/532.png",
  },
  "533": {
    name: "tiny dinos winter 2022 #533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 533,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/533.png",
  },
  "534": {
    name: "tiny dinos winter 2022 #534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 534,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/534.png",
  },
  "535": {
    name: "tiny dinos winter 2022 #535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 535,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/535.png",
  },
  "536": {
    name: "tiny dinos winter 2022 #536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 536,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/536.png",
  },
  "537": {
    name: "tiny dinos winter 2022 #537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 537,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/537.png",
  },
  "538": {
    name: "tiny dinos winter 2022 #538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 538,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/538.png",
  },
  "539": {
    name: "tiny dinos winter 2022 #539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 539,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/539.png",
  },
  "540": {
    name: "tiny dinos winter 2022 #540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 540,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/540.png",
  },
  "541": {
    name: "tiny dinos winter 2022 #541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 541,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/541.png",
  },
  "542": {
    name: "tiny dinos winter 2022 #542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 542,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/542.png",
  },
  "543": {
    name: "tiny dinos winter 2022 #543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 543,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/543.png",
  },
  "544": {
    name: "tiny dinos winter 2022 #544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 544,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/544.png",
  },
  "545": {
    name: "tiny dinos winter 2022 #545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 545,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/545.png",
  },
  "546": {
    name: "tiny dinos winter 2022 #546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 546,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/546.png",
  },
  "547": {
    name: "tiny dinos winter 2022 #547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 547,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/547.png",
  },
  "548": {
    name: "tiny dinos winter 2022 #548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 548,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/548.png",
  },
  "549": {
    name: "tiny dinos winter 2022 #549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 549,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/549.png",
  },
  "550": {
    name: "tiny dinos winter 2022 #550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 550,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/550.png",
  },
  "551": {
    name: "tiny dinos winter 2022 #551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 551,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/551.png",
  },
  "552": {
    name: "tiny dinos winter 2022 #552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 552,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/552.png",
  },
  "553": {
    name: "tiny dinos winter 2022 #553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 553,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/553.png",
  },
  "554": {
    name: "tiny dinos winter 2022 #554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 554,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/554.png",
  },
  "555": {
    name: "tiny dinos winter 2022 #555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 555,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/555.png",
  },
  "556": {
    name: "tiny dinos winter 2022 #556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 556,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/556.png",
  },
  "557": {
    name: "tiny dinos winter 2022 #557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 557,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/557.png",
  },
  "558": {
    name: "tiny dinos winter 2022 #558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 558,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/558.png",
  },
  "559": {
    name: "tiny dinos winter 2022 #559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 559,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/559.png",
  },
  "560": {
    name: "tiny dinos winter 2022 #560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 560,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/560.png",
  },
  "561": {
    name: "tiny dinos winter 2022 #561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 561,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/561.png",
  },
  "562": {
    name: "tiny dinos winter 2022 #562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 562,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/562.png",
  },
  "563": {
    name: "tiny dinos winter 2022 #563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 563,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/563.png",
  },
  "564": {
    name: "tiny dinos winter 2022 #564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 564,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/564.png",
  },
  "565": {
    name: "tiny dinos winter 2022 #565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 565,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/565.png",
  },
  "566": {
    name: "tiny dinos winter 2022 #566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 566,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/566.png",
  },
  "567": {
    name: "tiny dinos winter 2022 #567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 567,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/567.png",
  },
  "568": {
    name: "tiny dinos winter 2022 #568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 568,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/568.png",
  },
  "569": {
    name: "tiny dinos winter 2022 #569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 569,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/569.png",
  },
  "570": {
    name: "tiny dinos winter 2022 #570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 570,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/570.png",
  },
  "571": {
    name: "tiny dinos winter 2022 #571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 571,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/571.png",
  },
  "572": {
    name: "tiny dinos winter 2022 #572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 572,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/572.png",
  },
  "573": {
    name: "tiny dinos winter 2022 #573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 573,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/573.png",
  },
  "574": {
    name: "tiny dinos winter 2022 #574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 574,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/574.png",
  },
  "575": {
    name: "tiny dinos winter 2022 #575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 575,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/575.png",
  },
  "576": {
    name: "tiny dinos winter 2022 #576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 576,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/576.png",
  },
  "577": {
    name: "tiny dinos winter 2022 #577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 577,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/577.png",
  },
  "578": {
    name: "tiny dinos winter 2022 #578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 578,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/578.png",
  },
  "579": {
    name: "tiny dinos winter 2022 #579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 579,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/579.png",
  },
  "580": {
    name: "tiny dinos winter 2022 #580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 580,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/580.png",
  },
  "581": {
    name: "tiny dinos winter 2022 #581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 581,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/581.png",
  },
  "582": {
    name: "tiny dinos winter 2022 #582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 582,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/582.png",
  },
  "583": {
    name: "tiny dinos winter 2022 #583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 583,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/583.png",
  },
  "584": {
    name: "tiny dinos winter 2022 #584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 584,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/584.png",
  },
  "585": {
    name: "tiny dinos winter 2022 #585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 585,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/585.png",
  },
  "586": {
    name: "tiny dinos winter 2022 #586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 586,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/586.png",
  },
  "587": {
    name: "tiny dinos winter 2022 #587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 587,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/587.png",
  },
  "588": {
    name: "tiny dinos winter 2022 #588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 588,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/588.png",
  },
  "589": {
    name: "tiny dinos winter 2022 #589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 589,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/589.png",
  },
  "590": {
    name: "tiny dinos winter 2022 #590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 590,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/590.png",
  },
  "591": {
    name: "tiny dinos winter 2022 #591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 591,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/591.png",
  },
  "592": {
    name: "tiny dinos winter 2022 #592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 592,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/592.png",
  },
  "593": {
    name: "tiny dinos winter 2022 #593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 593,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/593.png",
  },
  "594": {
    name: "tiny dinos winter 2022 #594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 594,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/594.png",
  },
  "595": {
    name: "tiny dinos winter 2022 #595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 595,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/595.png",
  },
  "596": {
    name: "tiny dinos winter 2022 #596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 596,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/596.png",
  },
  "597": {
    name: "tiny dinos winter 2022 #597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 597,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/597.png",
  },
  "598": {
    name: "tiny dinos winter 2022 #598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 598,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/598.png",
  },
  "599": {
    name: "tiny dinos winter 2022 #599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 599,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/599.png",
  },
  "600": {
    name: "tiny dinos winter 2022 #600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 600,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/600.png",
  },
  "601": {
    name: "tiny dinos winter 2022 #601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 601,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/601.png",
  },
  "602": {
    name: "tiny dinos winter 2022 #602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 602,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/602.png",
  },
  "603": {
    name: "tiny dinos winter 2022 #603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 603,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/603.png",
  },
  "604": {
    name: "tiny dinos winter 2022 #604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 604,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/604.png",
  },
  "605": {
    name: "tiny dinos winter 2022 #605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 605,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/605.png",
  },
  "606": {
    name: "tiny dinos winter 2022 #606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 606,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/606.png",
  },
  "607": {
    name: "tiny dinos winter 2022 #607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 607,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/607.png",
  },
  "608": {
    name: "tiny dinos winter 2022 #608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 608,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/608.png",
  },
  "609": {
    name: "tiny dinos winter 2022 #609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 609,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/609.png",
  },
  "610": {
    name: "tiny dinos winter 2022 #610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 610,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/610.png",
  },
  "611": {
    name: "tiny dinos winter 2022 #611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 611,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/611.png",
  },
  "612": {
    name: "tiny dinos winter 2022 #612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 612,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/612.png",
  },
  "613": {
    name: "tiny dinos winter 2022 #613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 613,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/613.png",
  },
  "614": {
    name: "tiny dinos winter 2022 #614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 614,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/614.png",
  },
  "615": {
    name: "tiny dinos winter 2022 #615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 615,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/615.png",
  },
  "616": {
    name: "tiny dinos winter 2022 #616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 616,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/616.png",
  },
  "617": {
    name: "tiny dinos winter 2022 #617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 617,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/617.png",
  },
  "618": {
    name: "tiny dinos winter 2022 #618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 618,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/618.png",
  },
  "619": {
    name: "tiny dinos winter 2022 #619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 619,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/619.png",
  },
  "620": {
    name: "tiny dinos winter 2022 #620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 620,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/620.png",
  },
  "621": {
    name: "tiny dinos winter 2022 #621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 621,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/621.png",
  },
  "622": {
    name: "tiny dinos winter 2022 #622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 622,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/622.png",
  },
  "623": {
    name: "tiny dinos winter 2022 #623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 623,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/623.png",
  },
  "624": {
    name: "tiny dinos winter 2022 #624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 624,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/624.png",
  },
  "625": {
    name: "tiny dinos winter 2022 #625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 625,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/625.png",
  },
  "626": {
    name: "tiny dinos winter 2022 #626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 626,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/626.png",
  },
  "627": {
    name: "tiny dinos winter 2022 #627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 627,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/627.png",
  },
  "628": {
    name: "tiny dinos winter 2022 #628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 628,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/628.png",
  },
  "629": {
    name: "tiny dinos winter 2022 #629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 629,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/629.png",
  },
  "630": {
    name: "tiny dinos winter 2022 #630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 630,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/630.png",
  },
  "631": {
    name: "tiny dinos winter 2022 #631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 631,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/631.png",
  },
  "632": {
    name: "tiny dinos winter 2022 #632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 632,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/632.png",
  },
  "633": {
    name: "tiny dinos winter 2022 #633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 633,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/633.png",
  },
  "634": {
    name: "tiny dinos winter 2022 #634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 634,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/634.png",
  },
  "635": {
    name: "tiny dinos winter 2022 #635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 635,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/635.png",
  },
  "636": {
    name: "tiny dinos winter 2022 #636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 636,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/636.png",
  },
  "637": {
    name: "tiny dinos winter 2022 #637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 637,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/637.png",
  },
  "638": {
    name: "tiny dinos winter 2022 #638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 638,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/638.png",
  },
  "639": {
    name: "tiny dinos winter 2022 #639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 639,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/639.png",
  },
  "640": {
    name: "tiny dinos winter 2022 #640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 640,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/640.png",
  },
  "641": {
    name: "tiny dinos winter 2022 #641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 641,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/641.png",
  },
  "642": {
    name: "tiny dinos winter 2022 #642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 642,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/642.png",
  },
  "643": {
    name: "tiny dinos winter 2022 #643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 643,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/643.png",
  },
  "644": {
    name: "tiny dinos winter 2022 #644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 644,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/644.png",
  },
  "645": {
    name: "tiny dinos winter 2022 #645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 645,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/645.png",
  },
  "646": {
    name: "tiny dinos winter 2022 #646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 646,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/646.png",
  },
  "647": {
    name: "tiny dinos winter 2022 #647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 647,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/647.png",
  },
  "648": {
    name: "tiny dinos winter 2022 #648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 648,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/648.png",
  },
  "649": {
    name: "tiny dinos winter 2022 #649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 649,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/649.png",
  },
  "650": {
    name: "tiny dinos winter 2022 #650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 650,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/650.png",
  },
  "651": {
    name: "tiny dinos winter 2022 #651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 651,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/651.png",
  },
  "652": {
    name: "tiny dinos winter 2022 #652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 652,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/652.png",
  },
  "653": {
    name: "tiny dinos winter 2022 #653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 653,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/653.png",
  },
  "654": {
    name: "tiny dinos winter 2022 #654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 654,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/654.png",
  },
  "655": {
    name: "tiny dinos winter 2022 #655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 655,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/655.png",
  },
  "656": {
    name: "tiny dinos winter 2022 #656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 656,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/656.png",
  },
  "657": {
    name: "tiny dinos winter 2022 #657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 657,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/657.png",
  },
  "658": {
    name: "tiny dinos winter 2022 #658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 658,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/658.png",
  },
  "659": {
    name: "tiny dinos winter 2022 #659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 659,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/659.png",
  },
  "660": {
    name: "tiny dinos winter 2022 #660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 660,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/660.png",
  },
  "661": {
    name: "tiny dinos winter 2022 #661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 661,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/661.png",
  },
  "662": {
    name: "tiny dinos winter 2022 #662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 662,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/662.png",
  },
  "663": {
    name: "tiny dinos winter 2022 #663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 663,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/663.png",
  },
  "664": {
    name: "tiny dinos winter 2022 #664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 664,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/664.png",
  },
  "665": {
    name: "tiny dinos winter 2022 #665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 665,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/665.png",
  },
  "666": {
    name: "tiny dinos winter 2022 #666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 666,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/666.png",
  },
  "667": {
    name: "tiny dinos winter 2022 #667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 667,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/667.png",
  },
  "668": {
    name: "tiny dinos winter 2022 #668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 668,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/668.png",
  },
  "669": {
    name: "tiny dinos winter 2022 #669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 669,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/669.png",
  },
  "670": {
    name: "tiny dinos winter 2022 #670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 670,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/670.png",
  },
  "671": {
    name: "tiny dinos winter 2022 #671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 671,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/671.png",
  },
  "672": {
    name: "tiny dinos winter 2022 #672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 672,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/672.png",
  },
  "673": {
    name: "tiny dinos winter 2022 #673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 673,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/673.png",
  },
  "674": {
    name: "tiny dinos winter 2022 #674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 674,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/674.png",
  },
  "675": {
    name: "tiny dinos winter 2022 #675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 675,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/675.png",
  },
  "676": {
    name: "tiny dinos winter 2022 #676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 676,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/676.png",
  },
  "677": {
    name: "tiny dinos winter 2022 #677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 677,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/677.png",
  },
  "678": {
    name: "tiny dinos winter 2022 #678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 678,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/678.png",
  },
  "679": {
    name: "tiny dinos winter 2022 #679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 679,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/679.png",
  },
  "680": {
    name: "tiny dinos winter 2022 #680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 680,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/680.png",
  },
  "681": {
    name: "tiny dinos winter 2022 #681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 681,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/681.png",
  },
  "682": {
    name: "tiny dinos winter 2022 #682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 682,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/682.png",
  },
  "683": {
    name: "tiny dinos winter 2022 #683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 683,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/683.png",
  },
  "684": {
    name: "tiny dinos winter 2022 #684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 684,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/684.png",
  },
  "685": {
    name: "tiny dinos winter 2022 #685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 685,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/685.png",
  },
  "686": {
    name: "tiny dinos winter 2022 #686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 686,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/686.png",
  },
  "687": {
    name: "tiny dinos winter 2022 #687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 687,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/687.png",
  },
  "688": {
    name: "tiny dinos winter 2022 #688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 688,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/688.png",
  },
  "689": {
    name: "tiny dinos winter 2022 #689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 689,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/689.png",
  },
  "690": {
    name: "tiny dinos winter 2022 #690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 690,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/690.png",
  },
  "691": {
    name: "tiny dinos winter 2022 #691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 691,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/691.png",
  },
  "692": {
    name: "tiny dinos winter 2022 #692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 692,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/692.png",
  },
  "693": {
    name: "tiny dinos winter 2022 #693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 693,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/693.png",
  },
  "694": {
    name: "tiny dinos winter 2022 #694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 694,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/694.png",
  },
  "695": {
    name: "tiny dinos winter 2022 #695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 695,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/695.png",
  },
  "696": {
    name: "tiny dinos winter 2022 #696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 696,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/696.png",
  },
  "697": {
    name: "tiny dinos winter 2022 #697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 697,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/697.png",
  },
  "698": {
    name: "tiny dinos winter 2022 #698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 698,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/698.png",
  },
  "699": {
    name: "tiny dinos winter 2022 #699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 699,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/699.png",
  },
  "700": {
    name: "tiny dinos winter 2022 #700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 700,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/700.png",
  },
  "701": {
    name: "tiny dinos winter 2022 #701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 701,
    attributes: [
      {
        trait_type: "1/1",
        value: "rexx",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/701.png",
  },
  "702": {
    name: "tiny dinos winter 2022 #702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 702,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/702.png",
  },
  "703": {
    name: "tiny dinos winter 2022 #703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 703,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/703.png",
  },
  "704": {
    name: "tiny dinos winter 2022 #704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 704,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/704.png",
  },
  "705": {
    name: "tiny dinos winter 2022 #705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 705,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/705.png",
  },
  "706": {
    name: "tiny dinos winter 2022 #706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 706,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/706.png",
  },
  "707": {
    name: "tiny dinos winter 2022 #707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 707,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/707.png",
  },
  "708": {
    name: "tiny dinos winter 2022 #708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 708,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/708.png",
  },
  "709": {
    name: "tiny dinos winter 2022 #709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 709,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/709.png",
  },
  "710": {
    name: "tiny dinos winter 2022 #710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 710,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/710.png",
  },
  "711": {
    name: "tiny dinos winter 2022 #711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 711,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/711.png",
  },
  "712": {
    name: "tiny dinos winter 2022 #712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 712,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/712.png",
  },
  "713": {
    name: "tiny dinos winter 2022 #713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 713,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/713.png",
  },
  "714": {
    name: "tiny dinos winter 2022 #714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 714,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/714.png",
  },
  "715": {
    name: "tiny dinos winter 2022 #715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 715,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/715.png",
  },
  "716": {
    name: "tiny dinos winter 2022 #716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 716,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/716.png",
  },
  "717": {
    name: "tiny dinos winter 2022 #717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 717,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/717.png",
  },
  "718": {
    name: "tiny dinos winter 2022 #718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 718,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/718.png",
  },
  "719": {
    name: "tiny dinos winter 2022 #719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 719,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/719.png",
  },
  "720": {
    name: "tiny dinos winter 2022 #720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 720,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/720.png",
  },
  "721": {
    name: "tiny dinos winter 2022 #721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 721,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/721.png",
  },
  "722": {
    name: "tiny dinos winter 2022 #722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 722,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/722.png",
  },
  "723": {
    name: "tiny dinos winter 2022 #723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 723,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/723.png",
  },
  "724": {
    name: "tiny dinos winter 2022 #724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 724,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/724.png",
  },
  "725": {
    name: "tiny dinos winter 2022 #725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 725,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/725.png",
  },
  "726": {
    name: "tiny dinos winter 2022 #726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 726,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/726.png",
  },
  "727": {
    name: "tiny dinos winter 2022 #727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 727,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/727.png",
  },
  "728": {
    name: "tiny dinos winter 2022 #728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 728,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/728.png",
  },
  "729": {
    name: "tiny dinos winter 2022 #729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 729,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/729.png",
  },
  "730": {
    name: "tiny dinos winter 2022 #730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 730,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/730.png",
  },
  "731": {
    name: "tiny dinos winter 2022 #731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 731,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/731.png",
  },
  "732": {
    name: "tiny dinos winter 2022 #732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 732,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/732.png",
  },
  "733": {
    name: "tiny dinos winter 2022 #733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 733,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/733.png",
  },
  "734": {
    name: "tiny dinos winter 2022 #734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 734,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/734.png",
  },
  "735": {
    name: "tiny dinos winter 2022 #735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 735,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/735.png",
  },
  "736": {
    name: "tiny dinos winter 2022 #736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 736,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/736.png",
  },
  "737": {
    name: "tiny dinos winter 2022 #737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 737,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/737.png",
  },
  "738": {
    name: "tiny dinos winter 2022 #738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 738,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/738.png",
  },
  "739": {
    name: "tiny dinos winter 2022 #739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 739,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/739.png",
  },
  "740": {
    name: "tiny dinos winter 2022 #740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 740,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/740.png",
  },
  "741": {
    name: "tiny dinos winter 2022 #741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 741,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/741.png",
  },
  "742": {
    name: "tiny dinos winter 2022 #742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 742,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/742.png",
  },
  "743": {
    name: "tiny dinos winter 2022 #743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 743,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/743.png",
  },
  "744": {
    name: "tiny dinos winter 2022 #744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 744,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/744.png",
  },
  "745": {
    name: "tiny dinos winter 2022 #745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 745,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/745.png",
  },
  "746": {
    name: "tiny dinos winter 2022 #746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 746,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/746.png",
  },
  "747": {
    name: "tiny dinos winter 2022 #747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 747,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/747.png",
  },
  "748": {
    name: "tiny dinos winter 2022 #748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 748,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/748.png",
  },
  "749": {
    name: "tiny dinos winter 2022 #749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 749,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/749.png",
  },
  "750": {
    name: "tiny dinos winter 2022 #750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 750,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/750.png",
  },
  "751": {
    name: "tiny dinos winter 2022 #751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 751,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/751.png",
  },
  "752": {
    name: "tiny dinos winter 2022 #752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 752,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/752.png",
  },
  "753": {
    name: "tiny dinos winter 2022 #753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 753,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/753.png",
  },
  "754": {
    name: "tiny dinos winter 2022 #754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 754,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/754.png",
  },
  "755": {
    name: "tiny dinos winter 2022 #755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 755,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/755.png",
  },
  "756": {
    name: "tiny dinos winter 2022 #756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 756,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/756.png",
  },
  "757": {
    name: "tiny dinos winter 2022 #757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 757,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/757.png",
  },
  "758": {
    name: "tiny dinos winter 2022 #758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 758,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/758.png",
  },
  "759": {
    name: "tiny dinos winter 2022 #759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 759,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/759.png",
  },
  "760": {
    name: "tiny dinos winter 2022 #760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 760,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/760.png",
  },
  "761": {
    name: "tiny dinos winter 2022 #761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 761,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/761.png",
  },
  "762": {
    name: "tiny dinos winter 2022 #762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 762,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/762.png",
  },
  "763": {
    name: "tiny dinos winter 2022 #763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 763,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/763.png",
  },
  "764": {
    name: "tiny dinos winter 2022 #764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 764,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/764.png",
  },
  "765": {
    name: "tiny dinos winter 2022 #765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 765,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/765.png",
  },
  "766": {
    name: "tiny dinos winter 2022 #766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 766,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/766.png",
  },
  "767": {
    name: "tiny dinos winter 2022 #767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 767,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/767.png",
  },
  "768": {
    name: "tiny dinos winter 2022 #768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 768,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/768.png",
  },
  "769": {
    name: "tiny dinos winter 2022 #769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 769,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/769.png",
  },
  "770": {
    name: "tiny dinos winter 2022 #770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 770,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/770.png",
  },
  "771": {
    name: "tiny dinos winter 2022 #771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 771,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/771.png",
  },
  "772": {
    name: "tiny dinos winter 2022 #772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 772,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/772.png",
  },
  "773": {
    name: "tiny dinos winter 2022 #773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 773,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/773.png",
  },
  "774": {
    name: "tiny dinos winter 2022 #774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 774,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/774.png",
  },
  "775": {
    name: "tiny dinos winter 2022 #775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 775,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/775.png",
  },
  "776": {
    name: "tiny dinos winter 2022 #776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 776,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/776.png",
  },
  "777": {
    name: "tiny dinos winter 2022 #777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 777,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/777.png",
  },
  "778": {
    name: "tiny dinos winter 2022 #778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 778,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/778.png",
  },
  "779": {
    name: "tiny dinos winter 2022 #779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 779,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/779.png",
  },
  "780": {
    name: "tiny dinos winter 2022 #780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 780,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/780.png",
  },
  "781": {
    name: "tiny dinos winter 2022 #781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 781,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/781.png",
  },
  "782": {
    name: "tiny dinos winter 2022 #782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 782,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/782.png",
  },
  "783": {
    name: "tiny dinos winter 2022 #783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 783,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/783.png",
  },
  "784": {
    name: "tiny dinos winter 2022 #784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 784,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/784.png",
  },
  "785": {
    name: "tiny dinos winter 2022 #785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 785,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/785.png",
  },
  "786": {
    name: "tiny dinos winter 2022 #786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 786,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/786.png",
  },
  "787": {
    name: "tiny dinos winter 2022 #787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 787,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/787.png",
  },
  "788": {
    name: "tiny dinos winter 2022 #788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 788,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/788.png",
  },
  "789": {
    name: "tiny dinos winter 2022 #789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 789,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/789.png",
  },
  "790": {
    name: "tiny dinos winter 2022 #790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 790,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/790.png",
  },
  "791": {
    name: "tiny dinos winter 2022 #791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 791,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/791.png",
  },
  "792": {
    name: "tiny dinos winter 2022 #792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 792,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/792.png",
  },
  "793": {
    name: "tiny dinos winter 2022 #793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 793,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/793.png",
  },
  "794": {
    name: "tiny dinos winter 2022 #794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 794,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/794.png",
  },
  "795": {
    name: "tiny dinos winter 2022 #795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 795,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/795.png",
  },
  "796": {
    name: "tiny dinos winter 2022 #796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 796,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/796.png",
  },
  "797": {
    name: "tiny dinos winter 2022 #797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 797,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/797.png",
  },
  "798": {
    name: "tiny dinos winter 2022 #798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 798,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/798.png",
  },
  "799": {
    name: "tiny dinos winter 2022 #799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 799,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/799.png",
  },
  "800": {
    name: "tiny dinos winter 2022 #800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 800,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/800.png",
  },
  "801": {
    name: "tiny dinos winter 2022 #801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 801,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/801.png",
  },
  "802": {
    name: "tiny dinos winter 2022 #802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 802,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/802.png",
  },
  "803": {
    name: "tiny dinos winter 2022 #803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 803,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/803.png",
  },
  "804": {
    name: "tiny dinos winter 2022 #804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 804,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/804.png",
  },
  "805": {
    name: "tiny dinos winter 2022 #805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 805,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/805.png",
  },
  "806": {
    name: "tiny dinos winter 2022 #806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 806,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/806.png",
  },
  "807": {
    name: "tiny dinos winter 2022 #807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 807,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/807.png",
  },
  "808": {
    name: "tiny dinos winter 2022 #808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 808,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/808.png",
  },
  "809": {
    name: "tiny dinos winter 2022 #809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 809,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/809.png",
  },
  "810": {
    name: "tiny dinos winter 2022 #810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 810,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/810.png",
  },
  "811": {
    name: "tiny dinos winter 2022 #811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 811,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/811.png",
  },
  "812": {
    name: "tiny dinos winter 2022 #812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 812,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/812.png",
  },
  "813": {
    name: "tiny dinos winter 2022 #813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 813,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/813.png",
  },
  "814": {
    name: "tiny dinos winter 2022 #814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 814,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/814.png",
  },
  "815": {
    name: "tiny dinos winter 2022 #815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 815,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/815.png",
  },
  "816": {
    name: "tiny dinos winter 2022 #816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 816,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/816.png",
  },
  "817": {
    name: "tiny dinos winter 2022 #817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 817,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/817.png",
  },
  "818": {
    name: "tiny dinos winter 2022 #818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 818,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/818.png",
  },
  "819": {
    name: "tiny dinos winter 2022 #819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 819,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/819.png",
  },
  "820": {
    name: "tiny dinos winter 2022 #820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 820,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/820.png",
  },
  "821": {
    name: "tiny dinos winter 2022 #821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 821,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/821.png",
  },
  "822": {
    name: "tiny dinos winter 2022 #822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 822,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/822.png",
  },
  "823": {
    name: "tiny dinos winter 2022 #823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 823,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/823.png",
  },
  "824": {
    name: "tiny dinos winter 2022 #824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 824,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/824.png",
  },
  "825": {
    name: "tiny dinos winter 2022 #825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 825,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/825.png",
  },
  "826": {
    name: "tiny dinos winter 2022 #826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 826,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/826.png",
  },
  "827": {
    name: "tiny dinos winter 2022 #827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 827,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/827.png",
  },
  "828": {
    name: "tiny dinos winter 2022 #828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 828,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/828.png",
  },
  "829": {
    name: "tiny dinos winter 2022 #829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 829,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/829.png",
  },
  "830": {
    name: "tiny dinos winter 2022 #830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 830,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/830.png",
  },
  "831": {
    name: "tiny dinos winter 2022 #831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 831,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/831.png",
  },
  "832": {
    name: "tiny dinos winter 2022 #832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 832,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/832.png",
  },
  "833": {
    name: "tiny dinos winter 2022 #833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 833,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/833.png",
  },
  "834": {
    name: "tiny dinos winter 2022 #834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 834,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/834.png",
  },
  "835": {
    name: "tiny dinos winter 2022 #835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 835,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/835.png",
  },
  "836": {
    name: "tiny dinos winter 2022 #836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 836,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/836.png",
  },
  "837": {
    name: "tiny dinos winter 2022 #837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 837,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/837.png",
  },
  "838": {
    name: "tiny dinos winter 2022 #838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 838,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/838.png",
  },
  "839": {
    name: "tiny dinos winter 2022 #839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 839,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/839.png",
  },
  "840": {
    name: "tiny dinos winter 2022 #840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 840,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/840.png",
  },
  "841": {
    name: "tiny dinos winter 2022 #841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 841,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/841.png",
  },
  "842": {
    name: "tiny dinos winter 2022 #842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 842,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/842.png",
  },
  "843": {
    name: "tiny dinos winter 2022 #843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 843,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/843.png",
  },
  "844": {
    name: "tiny dinos winter 2022 #844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 844,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/844.png",
  },
  "845": {
    name: "tiny dinos winter 2022 #845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 845,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/845.png",
  },
  "846": {
    name: "tiny dinos winter 2022 #846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 846,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/846.png",
  },
  "847": {
    name: "tiny dinos winter 2022 #847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 847,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/847.png",
  },
  "848": {
    name: "tiny dinos winter 2022 #848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 848,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/848.png",
  },
  "849": {
    name: "tiny dinos winter 2022 #849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 849,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/849.png",
  },
  "850": {
    name: "tiny dinos winter 2022 #850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 850,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/850.png",
  },
  "851": {
    name: "tiny dinos winter 2022 #851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 851,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/851.png",
  },
  "852": {
    name: "tiny dinos winter 2022 #852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 852,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/852.png",
  },
  "853": {
    name: "tiny dinos winter 2022 #853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 853,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/853.png",
  },
  "854": {
    name: "tiny dinos winter 2022 #854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 854,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/854.png",
  },
  "855": {
    name: "tiny dinos winter 2022 #855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 855,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/855.png",
  },
  "856": {
    name: "tiny dinos winter 2022 #856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 856,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/856.png",
  },
  "857": {
    name: "tiny dinos winter 2022 #857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 857,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/857.png",
  },
  "858": {
    name: "tiny dinos winter 2022 #858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 858,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/858.png",
  },
  "859": {
    name: "tiny dinos winter 2022 #859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 859,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/859.png",
  },
  "860": {
    name: "tiny dinos winter 2022 #860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 860,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/860.png",
  },
  "861": {
    name: "tiny dinos winter 2022 #861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 861,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/861.png",
  },
  "862": {
    name: "tiny dinos winter 2022 #862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 862,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/862.png",
  },
  "863": {
    name: "tiny dinos winter 2022 #863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 863,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/863.png",
  },
  "864": {
    name: "tiny dinos winter 2022 #864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 864,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/864.png",
  },
  "865": {
    name: "tiny dinos winter 2022 #865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 865,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/865.png",
  },
  "866": {
    name: "tiny dinos winter 2022 #866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 866,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/866.png",
  },
  "867": {
    name: "tiny dinos winter 2022 #867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 867,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/867.png",
  },
  "868": {
    name: "tiny dinos winter 2022 #868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 868,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/868.png",
  },
  "869": {
    name: "tiny dinos winter 2022 #869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 869,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/869.png",
  },
  "870": {
    name: "tiny dinos winter 2022 #870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 870,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/870.png",
  },
  "871": {
    name: "tiny dinos winter 2022 #871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 871,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/871.png",
  },
  "872": {
    name: "tiny dinos winter 2022 #872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 872,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/872.png",
  },
  "873": {
    name: "tiny dinos winter 2022 #873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 873,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/873.png",
  },
  "874": {
    name: "tiny dinos winter 2022 #874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 874,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/874.png",
  },
  "875": {
    name: "tiny dinos winter 2022 #875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 875,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/875.png",
  },
  "876": {
    name: "tiny dinos winter 2022 #876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 876,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/876.png",
  },
  "877": {
    name: "tiny dinos winter 2022 #877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 877,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/877.png",
  },
  "878": {
    name: "tiny dinos winter 2022 #878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 878,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/878.png",
  },
  "879": {
    name: "tiny dinos winter 2022 #879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 879,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/879.png",
  },
  "880": {
    name: "tiny dinos winter 2022 #880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 880,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/880.png",
  },
  "881": {
    name: "tiny dinos winter 2022 #881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 881,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/881.png",
  },
  "882": {
    name: "tiny dinos winter 2022 #882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 882,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/882.png",
  },
  "883": {
    name: "tiny dinos winter 2022 #883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 883,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/883.png",
  },
  "884": {
    name: "tiny dinos winter 2022 #884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 884,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/884.png",
  },
  "885": {
    name: "tiny dinos winter 2022 #885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 885,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/885.png",
  },
  "886": {
    name: "tiny dinos winter 2022 #886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 886,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/886.png",
  },
  "887": {
    name: "tiny dinos winter 2022 #887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 887,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/887.png",
  },
  "888": {
    name: "tiny dinos winter 2022 #888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 888,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/888.png",
  },
  "889": {
    name: "tiny dinos winter 2022 #889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 889,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/889.png",
  },
  "890": {
    name: "tiny dinos winter 2022 #890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 890,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/890.png",
  },
  "891": {
    name: "tiny dinos winter 2022 #891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 891,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/891.png",
  },
  "892": {
    name: "tiny dinos winter 2022 #892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 892,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/892.png",
  },
  "893": {
    name: "tiny dinos winter 2022 #893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 893,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/893.png",
  },
  "894": {
    name: "tiny dinos winter 2022 #894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 894,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/894.png",
  },
  "895": {
    name: "tiny dinos winter 2022 #895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 895,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/895.png",
  },
  "896": {
    name: "tiny dinos winter 2022 #896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 896,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/896.png",
  },
  "897": {
    name: "tiny dinos winter 2022 #897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 897,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/897.png",
  },
  "898": {
    name: "tiny dinos winter 2022 #898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 898,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/898.png",
  },
  "899": {
    name: "tiny dinos winter 2022 #899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 899,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/899.png",
  },
  "900": {
    name: "tiny dinos winter 2022 #900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 900,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/900.png",
  },
  "901": {
    name: "tiny dinos winter 2022 #901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 901,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/901.png",
  },
  "902": {
    name: "tiny dinos winter 2022 #902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 902,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/902.png",
  },
  "903": {
    name: "tiny dinos winter 2022 #903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 903,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/903.png",
  },
  "904": {
    name: "tiny dinos winter 2022 #904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 904,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/904.png",
  },
  "905": {
    name: "tiny dinos winter 2022 #905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 905,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/905.png",
  },
  "906": {
    name: "tiny dinos winter 2022 #906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 906,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/906.png",
  },
  "907": {
    name: "tiny dinos winter 2022 #907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 907,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/907.png",
  },
  "908": {
    name: "tiny dinos winter 2022 #908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 908,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/908.png",
  },
  "909": {
    name: "tiny dinos winter 2022 #909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 909,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/909.png",
  },
  "910": {
    name: "tiny dinos winter 2022 #910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 910,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/910.png",
  },
  "911": {
    name: "tiny dinos winter 2022 #911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 911,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/911.png",
  },
  "912": {
    name: "tiny dinos winter 2022 #912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 912,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/912.png",
  },
  "913": {
    name: "tiny dinos winter 2022 #913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 913,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/913.png",
  },
  "914": {
    name: "tiny dinos winter 2022 #914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 914,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/914.png",
  },
  "915": {
    name: "tiny dinos winter 2022 #915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 915,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/915.png",
  },
  "916": {
    name: "tiny dinos winter 2022 #916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 916,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/916.png",
  },
  "917": {
    name: "tiny dinos winter 2022 #917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 917,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/917.png",
  },
  "918": {
    name: "tiny dinos winter 2022 #918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 918,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/918.png",
  },
  "919": {
    name: "tiny dinos winter 2022 #919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 919,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/919.png",
  },
  "920": {
    name: "tiny dinos winter 2022 #920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 920,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/920.png",
  },
  "921": {
    name: "tiny dinos winter 2022 #921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 921,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/921.png",
  },
  "922": {
    name: "tiny dinos winter 2022 #922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 922,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/922.png",
  },
  "923": {
    name: "tiny dinos winter 2022 #923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 923,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/923.png",
  },
  "924": {
    name: "tiny dinos winter 2022 #924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 924,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/924.png",
  },
  "925": {
    name: "tiny dinos winter 2022 #925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 925,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/925.png",
  },
  "926": {
    name: "tiny dinos winter 2022 #926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 926,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/926.png",
  },
  "927": {
    name: "tiny dinos winter 2022 #927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 927,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/927.png",
  },
  "928": {
    name: "tiny dinos winter 2022 #928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 928,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/928.png",
  },
  "929": {
    name: "tiny dinos winter 2022 #929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 929,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/929.png",
  },
  "930": {
    name: "tiny dinos winter 2022 #930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 930,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/930.png",
  },
  "931": {
    name: "tiny dinos winter 2022 #931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 931,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/931.png",
  },
  "932": {
    name: "tiny dinos winter 2022 #932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 932,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/932.png",
  },
  "933": {
    name: "tiny dinos winter 2022 #933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 933,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/933.png",
  },
  "934": {
    name: "tiny dinos winter 2022 #934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 934,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/934.png",
  },
  "935": {
    name: "tiny dinos winter 2022 #935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 935,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/935.png",
  },
  "936": {
    name: "tiny dinos winter 2022 #936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 936,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/936.png",
  },
  "937": {
    name: "tiny dinos winter 2022 #937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 937,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/937.png",
  },
  "938": {
    name: "tiny dinos winter 2022 #938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 938,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/938.png",
  },
  "939": {
    name: "tiny dinos winter 2022 #939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 939,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/939.png",
  },
  "940": {
    name: "tiny dinos winter 2022 #940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 940,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/940.png",
  },
  "941": {
    name: "tiny dinos winter 2022 #941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 941,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/941.png",
  },
  "942": {
    name: "tiny dinos winter 2022 #942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 942,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/942.png",
  },
  "943": {
    name: "tiny dinos winter 2022 #943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 943,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/943.png",
  },
  "944": {
    name: "tiny dinos winter 2022 #944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 944,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/944.png",
  },
  "945": {
    name: "tiny dinos winter 2022 #945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 945,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/945.png",
  },
  "946": {
    name: "tiny dinos winter 2022 #946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 946,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/946.png",
  },
  "947": {
    name: "tiny dinos winter 2022 #947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 947,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/947.png",
  },
  "948": {
    name: "tiny dinos winter 2022 #948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 948,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/948.png",
  },
  "949": {
    name: "tiny dinos winter 2022 #949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 949,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/949.png",
  },
  "950": {
    name: "tiny dinos winter 2022 #950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 950,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/950.png",
  },
  "951": {
    name: "tiny dinos winter 2022 #951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 951,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/951.png",
  },
  "952": {
    name: "tiny dinos winter 2022 #952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 952,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/952.png",
  },
  "953": {
    name: "tiny dinos winter 2022 #953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 953,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/953.png",
  },
  "954": {
    name: "tiny dinos winter 2022 #954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 954,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/954.png",
  },
  "955": {
    name: "tiny dinos winter 2022 #955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 955,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/955.png",
  },
  "956": {
    name: "tiny dinos winter 2022 #956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 956,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/956.png",
  },
  "957": {
    name: "tiny dinos winter 2022 #957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 957,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/957.png",
  },
  "958": {
    name: "tiny dinos winter 2022 #958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 958,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/958.png",
  },
  "959": {
    name: "tiny dinos winter 2022 #959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 959,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/959.png",
  },
  "960": {
    name: "tiny dinos winter 2022 #960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 960,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/960.png",
  },
  "961": {
    name: "tiny dinos winter 2022 #961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 961,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/961.png",
  },
  "962": {
    name: "tiny dinos winter 2022 #962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 962,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/962.png",
  },
  "963": {
    name: "tiny dinos winter 2022 #963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 963,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/963.png",
  },
  "964": {
    name: "tiny dinos winter 2022 #964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 964,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/964.png",
  },
  "965": {
    name: "tiny dinos winter 2022 #965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 965,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/965.png",
  },
  "966": {
    name: "tiny dinos winter 2022 #966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 966,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/966.png",
  },
  "967": {
    name: "tiny dinos winter 2022 #967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 967,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/967.png",
  },
  "968": {
    name: "tiny dinos winter 2022 #968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 968,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/968.png",
  },
  "969": {
    name: "tiny dinos winter 2022 #969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 969,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/969.png",
  },
  "970": {
    name: "tiny dinos winter 2022 #970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 970,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/970.png",
  },
  "971": {
    name: "tiny dinos winter 2022 #971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 971,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/971.png",
  },
  "972": {
    name: "tiny dinos winter 2022 #972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 972,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/972.png",
  },
  "973": {
    name: "tiny dinos winter 2022 #973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 973,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/973.png",
  },
  "974": {
    name: "tiny dinos winter 2022 #974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 974,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/974.png",
  },
  "975": {
    name: "tiny dinos winter 2022 #975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 975,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/975.png",
  },
  "976": {
    name: "tiny dinos winter 2022 #976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 976,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/976.png",
  },
  "977": {
    name: "tiny dinos winter 2022 #977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 977,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/977.png",
  },
  "978": {
    name: "tiny dinos winter 2022 #978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 978,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/978.png",
  },
  "979": {
    name: "tiny dinos winter 2022 #979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 979,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/979.png",
  },
  "980": {
    name: "tiny dinos winter 2022 #980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 980,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/980.png",
  },
  "981": {
    name: "tiny dinos winter 2022 #981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 981,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/981.png",
  },
  "982": {
    name: "tiny dinos winter 2022 #982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 982,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/982.png",
  },
  "983": {
    name: "tiny dinos winter 2022 #983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 983,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/983.png",
  },
  "984": {
    name: "tiny dinos winter 2022 #984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 984,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/984.png",
  },
  "985": {
    name: "tiny dinos winter 2022 #985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 985,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/985.png",
  },
  "986": {
    name: "tiny dinos winter 2022 #986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 986,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/986.png",
  },
  "987": {
    name: "tiny dinos winter 2022 #987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 987,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/987.png",
  },
  "988": {
    name: "tiny dinos winter 2022 #988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 988,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/988.png",
  },
  "989": {
    name: "tiny dinos winter 2022 #989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 989,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/989.png",
  },
  "990": {
    name: "tiny dinos winter 2022 #990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 990,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/990.png",
  },
  "991": {
    name: "tiny dinos winter 2022 #991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 991,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/991.png",
  },
  "992": {
    name: "tiny dinos winter 2022 #992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 992,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/992.png",
  },
  "993": {
    name: "tiny dinos winter 2022 #993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 993,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/993.png",
  },
  "994": {
    name: "tiny dinos winter 2022 #994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 994,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/994.png",
  },
  "995": {
    name: "tiny dinos winter 2022 #995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 995,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/995.png",
  },
  "996": {
    name: "tiny dinos winter 2022 #996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 996,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/996.png",
  },
  "997": {
    name: "tiny dinos winter 2022 #997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 997,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/997.png",
  },
  "998": {
    name: "tiny dinos winter 2022 #998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 998,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/998.png",
  },
  "999": {
    name: "tiny dinos winter 2022 #999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 999,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/999.png",
  },
  "1000": {
    name: "tiny dinos winter 2022 #1000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1000,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1000.png",
  },
  "1001": {
    name: "tiny dinos winter 2022 #1001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1001,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1001.png",
  },
  "1002": {
    name: "tiny dinos winter 2022 #1002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1002,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1002.png",
  },
  "1003": {
    name: "tiny dinos winter 2022 #1003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1003,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1003.png",
  },
  "1004": {
    name: "tiny dinos winter 2022 #1004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1004,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1004.png",
  },
  "1005": {
    name: "tiny dinos winter 2022 #1005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1005,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1005.png",
  },
  "1006": {
    name: "tiny dinos winter 2022 #1006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1006,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1006.png",
  },
  "1007": {
    name: "tiny dinos winter 2022 #1007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1007,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1007.png",
  },
  "1008": {
    name: "tiny dinos winter 2022 #1008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1008,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1008.png",
  },
  "1009": {
    name: "tiny dinos winter 2022 #1009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1009,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1009.png",
  },
  "1010": {
    name: "tiny dinos winter 2022 #1010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1010,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1010.png",
  },
  "1011": {
    name: "tiny dinos winter 2022 #1011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1011,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1011.png",
  },
  "1012": {
    name: "tiny dinos winter 2022 #1012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1012,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1012.png",
  },
  "1013": {
    name: "tiny dinos winter 2022 #1013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1013,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1013.png",
  },
  "1014": {
    name: "tiny dinos winter 2022 #1014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1014,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1014.png",
  },
  "1015": {
    name: "tiny dinos winter 2022 #1015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1015,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1015.png",
  },
  "1016": {
    name: "tiny dinos winter 2022 #1016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1016,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1016.png",
  },
  "1017": {
    name: "tiny dinos winter 2022 #1017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1017,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1017.png",
  },
  "1018": {
    name: "tiny dinos winter 2022 #1018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1018,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1018.png",
  },
  "1019": {
    name: "tiny dinos winter 2022 #1019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1019,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1019.png",
  },
  "1020": {
    name: "tiny dinos winter 2022 #1020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1020,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1020.png",
  },
  "1021": {
    name: "tiny dinos winter 2022 #1021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1021,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1021.png",
  },
  "1022": {
    name: "tiny dinos winter 2022 #1022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1022,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1022.png",
  },
  "1023": {
    name: "tiny dinos winter 2022 #1023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1023,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1023.png",
  },
  "1024": {
    name: "tiny dinos winter 2022 #1024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1024,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1024.png",
  },
  "1025": {
    name: "tiny dinos winter 2022 #1025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1025,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1025.png",
  },
  "1026": {
    name: "tiny dinos winter 2022 #1026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1026,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1026.png",
  },
  "1027": {
    name: "tiny dinos winter 2022 #1027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1027,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1027.png",
  },
  "1028": {
    name: "tiny dinos winter 2022 #1028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1028,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1028.png",
  },
  "1029": {
    name: "tiny dinos winter 2022 #1029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1029,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1029.png",
  },
  "1030": {
    name: "tiny dinos winter 2022 #1030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1030,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1030.png",
  },
  "1031": {
    name: "tiny dinos winter 2022 #1031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1031,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1031.png",
  },
  "1032": {
    name: "tiny dinos winter 2022 #1032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1032,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1032.png",
  },
  "1033": {
    name: "tiny dinos winter 2022 #1033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1033,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1033.png",
  },
  "1034": {
    name: "tiny dinos winter 2022 #1034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1034,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1034.png",
  },
  "1035": {
    name: "tiny dinos winter 2022 #1035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1035,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1035.png",
  },
  "1036": {
    name: "tiny dinos winter 2022 #1036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1036,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1036.png",
  },
  "1037": {
    name: "tiny dinos winter 2022 #1037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1037,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1037.png",
  },
  "1038": {
    name: "tiny dinos winter 2022 #1038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1038,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1038.png",
  },
  "1039": {
    name: "tiny dinos winter 2022 #1039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1039,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1039.png",
  },
  "1040": {
    name: "tiny dinos winter 2022 #1040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1040,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1040.png",
  },
  "1041": {
    name: "tiny dinos winter 2022 #1041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1041,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1041.png",
  },
  "1042": {
    name: "tiny dinos winter 2022 #1042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1042,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1042.png",
  },
  "1043": {
    name: "tiny dinos winter 2022 #1043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1043,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1043.png",
  },
  "1044": {
    name: "tiny dinos winter 2022 #1044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1044,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1044.png",
  },
  "1045": {
    name: "tiny dinos winter 2022 #1045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1045,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1045.png",
  },
  "1046": {
    name: "tiny dinos winter 2022 #1046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1046,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1046.png",
  },
  "1047": {
    name: "tiny dinos winter 2022 #1047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1047,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1047.png",
  },
  "1048": {
    name: "tiny dinos winter 2022 #1048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1048,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1048.png",
  },
  "1049": {
    name: "tiny dinos winter 2022 #1049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1049,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1049.png",
  },
  "1050": {
    name: "tiny dinos winter 2022 #1050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1050,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1050.png",
  },
  "1051": {
    name: "tiny dinos winter 2022 #1051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1051,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1051.png",
  },
  "1052": {
    name: "tiny dinos winter 2022 #1052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1052,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1052.png",
  },
  "1053": {
    name: "tiny dinos winter 2022 #1053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1053,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1053.png",
  },
  "1054": {
    name: "tiny dinos winter 2022 #1054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1054,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1054.png",
  },
  "1055": {
    name: "tiny dinos winter 2022 #1055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1055,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1055.png",
  },
  "1056": {
    name: "tiny dinos winter 2022 #1056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1056,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1056.png",
  },
  "1057": {
    name: "tiny dinos winter 2022 #1057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1057,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1057.png",
  },
  "1058": {
    name: "tiny dinos winter 2022 #1058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1058,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1058.png",
  },
  "1059": {
    name: "tiny dinos winter 2022 #1059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1059,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1059.png",
  },
  "1060": {
    name: "tiny dinos winter 2022 #1060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1060,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1060.png",
  },
  "1061": {
    name: "tiny dinos winter 2022 #1061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1061,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1061.png",
  },
  "1062": {
    name: "tiny dinos winter 2022 #1062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1062,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1062.png",
  },
  "1063": {
    name: "tiny dinos winter 2022 #1063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1063,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1063.png",
  },
  "1064": {
    name: "tiny dinos winter 2022 #1064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1064,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1064.png",
  },
  "1065": {
    name: "tiny dinos winter 2022 #1065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1065,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1065.png",
  },
  "1066": {
    name: "tiny dinos winter 2022 #1066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1066,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1066.png",
  },
  "1067": {
    name: "tiny dinos winter 2022 #1067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1067,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1067.png",
  },
  "1068": {
    name: "tiny dinos winter 2022 #1068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1068,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1068.png",
  },
  "1069": {
    name: "tiny dinos winter 2022 #1069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1069,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1069.png",
  },
  "1070": {
    name: "tiny dinos winter 2022 #1070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1070,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1070.png",
  },
  "1071": {
    name: "tiny dinos winter 2022 #1071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1071,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1071.png",
  },
  "1072": {
    name: "tiny dinos winter 2022 #1072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1072,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1072.png",
  },
  "1073": {
    name: "tiny dinos winter 2022 #1073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1073,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1073.png",
  },
  "1074": {
    name: "tiny dinos winter 2022 #1074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1074,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1074.png",
  },
  "1075": {
    name: "tiny dinos winter 2022 #1075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1075,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1075.png",
  },
  "1076": {
    name: "tiny dinos winter 2022 #1076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1076,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1076.png",
  },
  "1077": {
    name: "tiny dinos winter 2022 #1077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1077,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1077.png",
  },
  "1078": {
    name: "tiny dinos winter 2022 #1078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1078,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1078.png",
  },
  "1079": {
    name: "tiny dinos winter 2022 #1079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1079,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1079.png",
  },
  "1080": {
    name: "tiny dinos winter 2022 #1080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1080,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1080.png",
  },
  "1081": {
    name: "tiny dinos winter 2022 #1081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1081,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1081.png",
  },
  "1082": {
    name: "tiny dinos winter 2022 #1082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1082,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1082.png",
  },
  "1083": {
    name: "tiny dinos winter 2022 #1083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1083,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1083.png",
  },
  "1084": {
    name: "tiny dinos winter 2022 #1084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1084,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1084.png",
  },
  "1085": {
    name: "tiny dinos winter 2022 #1085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1085,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1085.png",
  },
  "1086": {
    name: "tiny dinos winter 2022 #1086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1086,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1086.png",
  },
  "1087": {
    name: "tiny dinos winter 2022 #1087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1087,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1087.png",
  },
  "1088": {
    name: "tiny dinos winter 2022 #1088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1088,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1088.png",
  },
  "1089": {
    name: "tiny dinos winter 2022 #1089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1089,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1089.png",
  },
  "1090": {
    name: "tiny dinos winter 2022 #1090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1090,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1090.png",
  },
  "1091": {
    name: "tiny dinos winter 2022 #1091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1091,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1091.png",
  },
  "1092": {
    name: "tiny dinos winter 2022 #1092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1092,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1092.png",
  },
  "1093": {
    name: "tiny dinos winter 2022 #1093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1093,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1093.png",
  },
  "1094": {
    name: "tiny dinos winter 2022 #1094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1094,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1094.png",
  },
  "1095": {
    name: "tiny dinos winter 2022 #1095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1095,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1095.png",
  },
  "1096": {
    name: "tiny dinos winter 2022 #1096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1096,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1096.png",
  },
  "1097": {
    name: "tiny dinos winter 2022 #1097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1097,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1097.png",
  },
  "1098": {
    name: "tiny dinos winter 2022 #1098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1098,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1098.png",
  },
  "1099": {
    name: "tiny dinos winter 2022 #1099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1099,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1099.png",
  },
  "1100": {
    name: "tiny dinos winter 2022 #1100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1100,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1100.png",
  },
  "1101": {
    name: "tiny dinos winter 2022 #1101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1101,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1101.png",
  },
  "1102": {
    name: "tiny dinos winter 2022 #1102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1102,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1102.png",
  },
  "1103": {
    name: "tiny dinos winter 2022 #1103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1103,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1103.png",
  },
  "1104": {
    name: "tiny dinos winter 2022 #1104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1104,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1104.png",
  },
  "1105": {
    name: "tiny dinos winter 2022 #1105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1105,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1105.png",
  },
  "1106": {
    name: "tiny dinos winter 2022 #1106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1106,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1106.png",
  },
  "1107": {
    name: "tiny dinos winter 2022 #1107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1107,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1107.png",
  },
  "1108": {
    name: "tiny dinos winter 2022 #1108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1108,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1108.png",
  },
  "1109": {
    name: "tiny dinos winter 2022 #1109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1109,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1109.png",
  },
  "1110": {
    name: "tiny dinos winter 2022 #1110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1110,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1110.png",
  },
  "1111": {
    name: "tiny dinos winter 2022 #1111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1111,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1111.png",
  },
  "1112": {
    name: "tiny dinos winter 2022 #1112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1112,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1112.png",
  },
  "1113": {
    name: "tiny dinos winter 2022 #1113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1113,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1113.png",
  },
  "1114": {
    name: "tiny dinos winter 2022 #1114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1114,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1114.png",
  },
  "1115": {
    name: "tiny dinos winter 2022 #1115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1115,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1115.png",
  },
  "1116": {
    name: "tiny dinos winter 2022 #1116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1116,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1116.png",
  },
  "1117": {
    name: "tiny dinos winter 2022 #1117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1117,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1117.png",
  },
  "1118": {
    name: "tiny dinos winter 2022 #1118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1118,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1118.png",
  },
  "1119": {
    name: "tiny dinos winter 2022 #1119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1119,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1119.png",
  },
  "1120": {
    name: "tiny dinos winter 2022 #1120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1120,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1120.png",
  },
  "1121": {
    name: "tiny dinos winter 2022 #1121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1121,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1121.png",
  },
  "1122": {
    name: "tiny dinos winter 2022 #1122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1122,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1122.png",
  },
  "1123": {
    name: "tiny dinos winter 2022 #1123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1123,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1123.png",
  },
  "1124": {
    name: "tiny dinos winter 2022 #1124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1124,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1124.png",
  },
  "1125": {
    name: "tiny dinos winter 2022 #1125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1125,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1125.png",
  },
  "1126": {
    name: "tiny dinos winter 2022 #1126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1126,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1126.png",
  },
  "1127": {
    name: "tiny dinos winter 2022 #1127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1127,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1127.png",
  },
  "1128": {
    name: "tiny dinos winter 2022 #1128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1128,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1128.png",
  },
  "1129": {
    name: "tiny dinos winter 2022 #1129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1129,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1129.png",
  },
  "1130": {
    name: "tiny dinos winter 2022 #1130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1130,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1130.png",
  },
  "1131": {
    name: "tiny dinos winter 2022 #1131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1131,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1131.png",
  },
  "1132": {
    name: "tiny dinos winter 2022 #1132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1132,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1132.png",
  },
  "1133": {
    name: "tiny dinos winter 2022 #1133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1133,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1133.png",
  },
  "1134": {
    name: "tiny dinos winter 2022 #1134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1134,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1134.png",
  },
  "1135": {
    name: "tiny dinos winter 2022 #1135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1135,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1135.png",
  },
  "1136": {
    name: "tiny dinos winter 2022 #1136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1136,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1136.png",
  },
  "1137": {
    name: "tiny dinos winter 2022 #1137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1137,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1137.png",
  },
  "1138": {
    name: "tiny dinos winter 2022 #1138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1138,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1138.png",
  },
  "1139": {
    name: "tiny dinos winter 2022 #1139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1139,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1139.png",
  },
  "1140": {
    name: "tiny dinos winter 2022 #1140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1140,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1140.png",
  },
  "1141": {
    name: "tiny dinos winter 2022 #1141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1141,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1141.png",
  },
  "1142": {
    name: "tiny dinos winter 2022 #1142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1142,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1142.png",
  },
  "1143": {
    name: "tiny dinos winter 2022 #1143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1143,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1143.png",
  },
  "1144": {
    name: "tiny dinos winter 2022 #1144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1144,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1144.png",
  },
  "1145": {
    name: "tiny dinos winter 2022 #1145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1145,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1145.png",
  },
  "1146": {
    name: "tiny dinos winter 2022 #1146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1146,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1146.png",
  },
  "1147": {
    name: "tiny dinos winter 2022 #1147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1147,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1147.png",
  },
  "1148": {
    name: "tiny dinos winter 2022 #1148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1148,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1148.png",
  },
  "1149": {
    name: "tiny dinos winter 2022 #1149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1149,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1149.png",
  },
  "1150": {
    name: "tiny dinos winter 2022 #1150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1150,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1150.png",
  },
  "1151": {
    name: "tiny dinos winter 2022 #1151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1151,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1151.png",
  },
  "1152": {
    name: "tiny dinos winter 2022 #1152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1152,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1152.png",
  },
  "1153": {
    name: "tiny dinos winter 2022 #1153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1153,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1153.png",
  },
  "1154": {
    name: "tiny dinos winter 2022 #1154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1154,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1154.png",
  },
  "1155": {
    name: "tiny dinos winter 2022 #1155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1155,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1155.png",
  },
  "1156": {
    name: "tiny dinos winter 2022 #1156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1156,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1156.png",
  },
  "1157": {
    name: "tiny dinos winter 2022 #1157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1157,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1157.png",
  },
  "1158": {
    name: "tiny dinos winter 2022 #1158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1158,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1158.png",
  },
  "1159": {
    name: "tiny dinos winter 2022 #1159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1159,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1159.png",
  },
  "1160": {
    name: "tiny dinos winter 2022 #1160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1160,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1160.png",
  },
  "1161": {
    name: "tiny dinos winter 2022 #1161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1161,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1161.png",
  },
  "1162": {
    name: "tiny dinos winter 2022 #1162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1162,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1162.png",
  },
  "1163": {
    name: "tiny dinos winter 2022 #1163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1163,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1163.png",
  },
  "1164": {
    name: "tiny dinos winter 2022 #1164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1164,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1164.png",
  },
  "1165": {
    name: "tiny dinos winter 2022 #1165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1165,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1165.png",
  },
  "1166": {
    name: "tiny dinos winter 2022 #1166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1166,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1166.png",
  },
  "1167": {
    name: "tiny dinos winter 2022 #1167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1167,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1167.png",
  },
  "1168": {
    name: "tiny dinos winter 2022 #1168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1168,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1168.png",
  },
  "1169": {
    name: "tiny dinos winter 2022 #1169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1169,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1169.png",
  },
  "1170": {
    name: "tiny dinos winter 2022 #1170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1170,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1170.png",
  },
  "1171": {
    name: "tiny dinos winter 2022 #1171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1171,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1171.png",
  },
  "1172": {
    name: "tiny dinos winter 2022 #1172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1172,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1172.png",
  },
  "1173": {
    name: "tiny dinos winter 2022 #1173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1173,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1173.png",
  },
  "1174": {
    name: "tiny dinos winter 2022 #1174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1174,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1174.png",
  },
  "1175": {
    name: "tiny dinos winter 2022 #1175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1175,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1175.png",
  },
  "1176": {
    name: "tiny dinos winter 2022 #1176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1176,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1176.png",
  },
  "1177": {
    name: "tiny dinos winter 2022 #1177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1177,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1177.png",
  },
  "1178": {
    name: "tiny dinos winter 2022 #1178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1178,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1178.png",
  },
  "1179": {
    name: "tiny dinos winter 2022 #1179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1179,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1179.png",
  },
  "1180": {
    name: "tiny dinos winter 2022 #1180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1180,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1180.png",
  },
  "1181": {
    name: "tiny dinos winter 2022 #1181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1181,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1181.png",
  },
  "1182": {
    name: "tiny dinos winter 2022 #1182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1182,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1182.png",
  },
  "1183": {
    name: "tiny dinos winter 2022 #1183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1183,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1183.png",
  },
  "1184": {
    name: "tiny dinos winter 2022 #1184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1184,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1184.png",
  },
  "1185": {
    name: "tiny dinos winter 2022 #1185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1185,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1185.png",
  },
  "1186": {
    name: "tiny dinos winter 2022 #1186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1186,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1186.png",
  },
  "1187": {
    name: "tiny dinos winter 2022 #1187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1187,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1187.png",
  },
  "1188": {
    name: "tiny dinos winter 2022 #1188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1188,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1188.png",
  },
  "1189": {
    name: "tiny dinos winter 2022 #1189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1189,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1189.png",
  },
  "1190": {
    name: "tiny dinos winter 2022 #1190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1190,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1190.png",
  },
  "1191": {
    name: "tiny dinos winter 2022 #1191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1191,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1191.png",
  },
  "1192": {
    name: "tiny dinos winter 2022 #1192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1192,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1192.png",
  },
  "1193": {
    name: "tiny dinos winter 2022 #1193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1193,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1193.png",
  },
  "1194": {
    name: "tiny dinos winter 2022 #1194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1194,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1194.png",
  },
  "1195": {
    name: "tiny dinos winter 2022 #1195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1195,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1195.png",
  },
  "1196": {
    name: "tiny dinos winter 2022 #1196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1196,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1196.png",
  },
  "1197": {
    name: "tiny dinos winter 2022 #1197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1197,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1197.png",
  },
  "1198": {
    name: "tiny dinos winter 2022 #1198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1198,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1198.png",
  },
  "1199": {
    name: "tiny dinos winter 2022 #1199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1199,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1199.png",
  },
  "1200": {
    name: "tiny dinos winter 2022 #1200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1200,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1200.png",
  },
  "1201": {
    name: "tiny dinos winter 2022 #1201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1201,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1201.png",
  },
  "1202": {
    name: "tiny dinos winter 2022 #1202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1202,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1202.png",
  },
  "1203": {
    name: "tiny dinos winter 2022 #1203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1203,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1203.png",
  },
  "1204": {
    name: "tiny dinos winter 2022 #1204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1204,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1204.png",
  },
  "1205": {
    name: "tiny dinos winter 2022 #1205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1205,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1205.png",
  },
  "1206": {
    name: "tiny dinos winter 2022 #1206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1206,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1206.png",
  },
  "1207": {
    name: "tiny dinos winter 2022 #1207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1207,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1207.png",
  },
  "1208": {
    name: "tiny dinos winter 2022 #1208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1208,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1208.png",
  },
  "1209": {
    name: "tiny dinos winter 2022 #1209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1209,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1209.png",
  },
  "1210": {
    name: "tiny dinos winter 2022 #1210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1210,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1210.png",
  },
  "1211": {
    name: "tiny dinos winter 2022 #1211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1211,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1211.png",
  },
  "1212": {
    name: "tiny dinos winter 2022 #1212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1212,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1212.png",
  },
  "1213": {
    name: "tiny dinos winter 2022 #1213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1213,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1213.png",
  },
  "1214": {
    name: "tiny dinos winter 2022 #1214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1214,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1214.png",
  },
  "1215": {
    name: "tiny dinos winter 2022 #1215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1215,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1215.png",
  },
  "1216": {
    name: "tiny dinos winter 2022 #1216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1216,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1216.png",
  },
  "1217": {
    name: "tiny dinos winter 2022 #1217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1217,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1217.png",
  },
  "1218": {
    name: "tiny dinos winter 2022 #1218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1218,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1218.png",
  },
  "1219": {
    name: "tiny dinos winter 2022 #1219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1219,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1219.png",
  },
  "1220": {
    name: "tiny dinos winter 2022 #1220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1220,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1220.png",
  },
  "1221": {
    name: "tiny dinos winter 2022 #1221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1221,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1221.png",
  },
  "1222": {
    name: "tiny dinos winter 2022 #1222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1222,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1222.png",
  },
  "1223": {
    name: "tiny dinos winter 2022 #1223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1223,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1223.png",
  },
  "1224": {
    name: "tiny dinos winter 2022 #1224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1224,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1224.png",
  },
  "1225": {
    name: "tiny dinos winter 2022 #1225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1225,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1225.png",
  },
  "1226": {
    name: "tiny dinos winter 2022 #1226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1226,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1226.png",
  },
  "1227": {
    name: "tiny dinos winter 2022 #1227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1227,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1227.png",
  },
  "1228": {
    name: "tiny dinos winter 2022 #1228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1228,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1228.png",
  },
  "1229": {
    name: "tiny dinos winter 2022 #1229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1229,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1229.png",
  },
  "1230": {
    name: "tiny dinos winter 2022 #1230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1230,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1230.png",
  },
  "1231": {
    name: "tiny dinos winter 2022 #1231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1231,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1231.png",
  },
  "1232": {
    name: "tiny dinos winter 2022 #1232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1232,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1232.png",
  },
  "1233": {
    name: "tiny dinos winter 2022 #1233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1233,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1233.png",
  },
  "1234": {
    name: "tiny dinos winter 2022 #1234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1234,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1234.png",
  },
  "1235": {
    name: "tiny dinos winter 2022 #1235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1235,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1235.png",
  },
  "1236": {
    name: "tiny dinos winter 2022 #1236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1236,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1236.png",
  },
  "1237": {
    name: "tiny dinos winter 2022 #1237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1237,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1237.png",
  },
  "1238": {
    name: "tiny dinos winter 2022 #1238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1238,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1238.png",
  },
  "1239": {
    name: "tiny dinos winter 2022 #1239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1239,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1239.png",
  },
  "1240": {
    name: "tiny dinos winter 2022 #1240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1240,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1240.png",
  },
  "1241": {
    name: "tiny dinos winter 2022 #1241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1241,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1241.png",
  },
  "1242": {
    name: "tiny dinos winter 2022 #1242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1242,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1242.png",
  },
  "1243": {
    name: "tiny dinos winter 2022 #1243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1243,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1243.png",
  },
  "1244": {
    name: "tiny dinos winter 2022 #1244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1244,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1244.png",
  },
  "1245": {
    name: "tiny dinos winter 2022 #1245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1245,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1245.png",
  },
  "1246": {
    name: "tiny dinos winter 2022 #1246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1246,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1246.png",
  },
  "1247": {
    name: "tiny dinos winter 2022 #1247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1247,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1247.png",
  },
  "1248": {
    name: "tiny dinos winter 2022 #1248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1248,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1248.png",
  },
  "1249": {
    name: "tiny dinos winter 2022 #1249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1249,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1249.png",
  },
  "1250": {
    name: "tiny dinos winter 2022 #1250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1250,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1250.png",
  },
  "1251": {
    name: "tiny dinos winter 2022 #1251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1251,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1251.png",
  },
  "1252": {
    name: "tiny dinos winter 2022 #1252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1252,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1252.png",
  },
  "1253": {
    name: "tiny dinos winter 2022 #1253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1253,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1253.png",
  },
  "1254": {
    name: "tiny dinos winter 2022 #1254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1254,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1254.png",
  },
  "1255": {
    name: "tiny dinos winter 2022 #1255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1255,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1255.png",
  },
  "1256": {
    name: "tiny dinos winter 2022 #1256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1256,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1256.png",
  },
  "1257": {
    name: "tiny dinos winter 2022 #1257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1257,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1257.png",
  },
  "1258": {
    name: "tiny dinos winter 2022 #1258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1258,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1258.png",
  },
  "1259": {
    name: "tiny dinos winter 2022 #1259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1259,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1259.png",
  },
  "1260": {
    name: "tiny dinos winter 2022 #1260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1260,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1260.png",
  },
  "1261": {
    name: "tiny dinos winter 2022 #1261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1261,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1261.png",
  },
  "1262": {
    name: "tiny dinos winter 2022 #1262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1262,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1262.png",
  },
  "1263": {
    name: "tiny dinos winter 2022 #1263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1263,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1263.png",
  },
  "1264": {
    name: "tiny dinos winter 2022 #1264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1264,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1264.png",
  },
  "1265": {
    name: "tiny dinos winter 2022 #1265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1265,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1265.png",
  },
  "1266": {
    name: "tiny dinos winter 2022 #1266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1266,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1266.png",
  },
  "1267": {
    name: "tiny dinos winter 2022 #1267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1267,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1267.png",
  },
  "1268": {
    name: "tiny dinos winter 2022 #1268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1268,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1268.png",
  },
  "1269": {
    name: "tiny dinos winter 2022 #1269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1269,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1269.png",
  },
  "1270": {
    name: "tiny dinos winter 2022 #1270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1270,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1270.png",
  },
  "1271": {
    name: "tiny dinos winter 2022 #1271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1271,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1271.png",
  },
  "1272": {
    name: "tiny dinos winter 2022 #1272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1272,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1272.png",
  },
  "1273": {
    name: "tiny dinos winter 2022 #1273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1273,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1273.png",
  },
  "1274": {
    name: "tiny dinos winter 2022 #1274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1274,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1274.png",
  },
  "1275": {
    name: "tiny dinos winter 2022 #1275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1275,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1275.png",
  },
  "1276": {
    name: "tiny dinos winter 2022 #1276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1276,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1276.png",
  },
  "1277": {
    name: "tiny dinos winter 2022 #1277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1277,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1277.png",
  },
  "1278": {
    name: "tiny dinos winter 2022 #1278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1278,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1278.png",
  },
  "1279": {
    name: "tiny dinos winter 2022 #1279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1279,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1279.png",
  },
  "1280": {
    name: "tiny dinos winter 2022 #1280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1280,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1280.png",
  },
  "1281": {
    name: "tiny dinos winter 2022 #1281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1281,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1281.png",
  },
  "1282": {
    name: "tiny dinos winter 2022 #1282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1282,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1282.png",
  },
  "1283": {
    name: "tiny dinos winter 2022 #1283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1283,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1283.png",
  },
  "1284": {
    name: "tiny dinos winter 2022 #1284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1284,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1284.png",
  },
  "1285": {
    name: "tiny dinos winter 2022 #1285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1285,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1285.png",
  },
  "1286": {
    name: "tiny dinos winter 2022 #1286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1286,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1286.png",
  },
  "1287": {
    name: "tiny dinos winter 2022 #1287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1287,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1287.png",
  },
  "1288": {
    name: "tiny dinos winter 2022 #1288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1288,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1288.png",
  },
  "1289": {
    name: "tiny dinos winter 2022 #1289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1289,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1289.png",
  },
  "1290": {
    name: "tiny dinos winter 2022 #1290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1290,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1290.png",
  },
  "1291": {
    name: "tiny dinos winter 2022 #1291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1291,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1291.png",
  },
  "1292": {
    name: "tiny dinos winter 2022 #1292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1292,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1292.png",
  },
  "1293": {
    name: "tiny dinos winter 2022 #1293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1293,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1293.png",
  },
  "1294": {
    name: "tiny dinos winter 2022 #1294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1294,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1294.png",
  },
  "1295": {
    name: "tiny dinos winter 2022 #1295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1295,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1295.png",
  },
  "1296": {
    name: "tiny dinos winter 2022 #1296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1296,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1296.png",
  },
  "1297": {
    name: "tiny dinos winter 2022 #1297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1297,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1297.png",
  },
  "1298": {
    name: "tiny dinos winter 2022 #1298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1298,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1298.png",
  },
  "1299": {
    name: "tiny dinos winter 2022 #1299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1299,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1299.png",
  },
  "1300": {
    name: "tiny dinos winter 2022 #1300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1300,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1300.png",
  },
  "1301": {
    name: "tiny dinos winter 2022 #1301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1301,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1301.png",
  },
  "1302": {
    name: "tiny dinos winter 2022 #1302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1302,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1302.png",
  },
  "1303": {
    name: "tiny dinos winter 2022 #1303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1303,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1303.png",
  },
  "1304": {
    name: "tiny dinos winter 2022 #1304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1304,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1304.png",
  },
  "1305": {
    name: "tiny dinos winter 2022 #1305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1305,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1305.png",
  },
  "1306": {
    name: "tiny dinos winter 2022 #1306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1306,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1306.png",
  },
  "1307": {
    name: "tiny dinos winter 2022 #1307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1307,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1307.png",
  },
  "1308": {
    name: "tiny dinos winter 2022 #1308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1308,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1308.png",
  },
  "1309": {
    name: "tiny dinos winter 2022 #1309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1309,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1309.png",
  },
  "1310": {
    name: "tiny dinos winter 2022 #1310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1310,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1310.png",
  },
  "1311": {
    name: "tiny dinos winter 2022 #1311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1311,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1311.png",
  },
  "1312": {
    name: "tiny dinos winter 2022 #1312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1312,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1312.png",
  },
  "1313": {
    name: "tiny dinos winter 2022 #1313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1313,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1313.png",
  },
  "1314": {
    name: "tiny dinos winter 2022 #1314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1314,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1314.png",
  },
  "1315": {
    name: "tiny dinos winter 2022 #1315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1315,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1315.png",
  },
  "1316": {
    name: "tiny dinos winter 2022 #1316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1316,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1316.png",
  },
  "1317": {
    name: "tiny dinos winter 2022 #1317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1317,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1317.png",
  },
  "1318": {
    name: "tiny dinos winter 2022 #1318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1318,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1318.png",
  },
  "1319": {
    name: "tiny dinos winter 2022 #1319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1319,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1319.png",
  },
  "1320": {
    name: "tiny dinos winter 2022 #1320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1320,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1320.png",
  },
  "1321": {
    name: "tiny dinos winter 2022 #1321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1321,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1321.png",
  },
  "1322": {
    name: "tiny dinos winter 2022 #1322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1322,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1322.png",
  },
  "1323": {
    name: "tiny dinos winter 2022 #1323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1323,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1323.png",
  },
  "1324": {
    name: "tiny dinos winter 2022 #1324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1324,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1324.png",
  },
  "1325": {
    name: "tiny dinos winter 2022 #1325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1325,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1325.png",
  },
  "1326": {
    name: "tiny dinos winter 2022 #1326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1326,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1326.png",
  },
  "1327": {
    name: "tiny dinos winter 2022 #1327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1327,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1327.png",
  },
  "1328": {
    name: "tiny dinos winter 2022 #1328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1328,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1328.png",
  },
  "1329": {
    name: "tiny dinos winter 2022 #1329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1329,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1329.png",
  },
  "1330": {
    name: "tiny dinos winter 2022 #1330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1330,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1330.png",
  },
  "1331": {
    name: "tiny dinos winter 2022 #1331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1331,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1331.png",
  },
  "1332": {
    name: "tiny dinos winter 2022 #1332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1332,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1332.png",
  },
  "1333": {
    name: "tiny dinos winter 2022 #1333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1333,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1333.png",
  },
  "1334": {
    name: "tiny dinos winter 2022 #1334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1334,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1334.png",
  },
  "1335": {
    name: "tiny dinos winter 2022 #1335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1335,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1335.png",
  },
  "1336": {
    name: "tiny dinos winter 2022 #1336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1336,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1336.png",
  },
  "1337": {
    name: "tiny dinos winter 2022 #1337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1337,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1337.png",
  },
  "1338": {
    name: "tiny dinos winter 2022 #1338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1338,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1338.png",
  },
  "1339": {
    name: "tiny dinos winter 2022 #1339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1339,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1339.png",
  },
  "1340": {
    name: "tiny dinos winter 2022 #1340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1340,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1340.png",
  },
  "1341": {
    name: "tiny dinos winter 2022 #1341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1341,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1341.png",
  },
  "1342": {
    name: "tiny dinos winter 2022 #1342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1342,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1342.png",
  },
  "1343": {
    name: "tiny dinos winter 2022 #1343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1343,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1343.png",
  },
  "1344": {
    name: "tiny dinos winter 2022 #1344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1344,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1344.png",
  },
  "1345": {
    name: "tiny dinos winter 2022 #1345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1345,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1345.png",
  },
  "1346": {
    name: "tiny dinos winter 2022 #1346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1346,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1346.png",
  },
  "1347": {
    name: "tiny dinos winter 2022 #1347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1347,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1347.png",
  },
  "1348": {
    name: "tiny dinos winter 2022 #1348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1348,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1348.png",
  },
  "1349": {
    name: "tiny dinos winter 2022 #1349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1349,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1349.png",
  },
  "1350": {
    name: "tiny dinos winter 2022 #1350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1350,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1350.png",
  },
  "1351": {
    name: "tiny dinos winter 2022 #1351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1351,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1351.png",
  },
  "1352": {
    name: "tiny dinos winter 2022 #1352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1352,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1352.png",
  },
  "1353": {
    name: "tiny dinos winter 2022 #1353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1353,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1353.png",
  },
  "1354": {
    name: "tiny dinos winter 2022 #1354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1354,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1354.png",
  },
  "1355": {
    name: "tiny dinos winter 2022 #1355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1355,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1355.png",
  },
  "1356": {
    name: "tiny dinos winter 2022 #1356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1356,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1356.png",
  },
  "1357": {
    name: "tiny dinos winter 2022 #1357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1357,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1357.png",
  },
  "1358": {
    name: "tiny dinos winter 2022 #1358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1358,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1358.png",
  },
  "1359": {
    name: "tiny dinos winter 2022 #1359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1359,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1359.png",
  },
  "1360": {
    name: "tiny dinos winter 2022 #1360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1360,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1360.png",
  },
  "1361": {
    name: "tiny dinos winter 2022 #1361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1361,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1361.png",
  },
  "1362": {
    name: "tiny dinos winter 2022 #1362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1362,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1362.png",
  },
  "1363": {
    name: "tiny dinos winter 2022 #1363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1363,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1363.png",
  },
  "1364": {
    name: "tiny dinos winter 2022 #1364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1364,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1364.png",
  },
  "1365": {
    name: "tiny dinos winter 2022 #1365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1365,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1365.png",
  },
  "1366": {
    name: "tiny dinos winter 2022 #1366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1366,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1366.png",
  },
  "1367": {
    name: "tiny dinos winter 2022 #1367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1367,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1367.png",
  },
  "1368": {
    name: "tiny dinos winter 2022 #1368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1368,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1368.png",
  },
  "1369": {
    name: "tiny dinos winter 2022 #1369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1369,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1369.png",
  },
  "1370": {
    name: "tiny dinos winter 2022 #1370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1370,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1370.png",
  },
  "1371": {
    name: "tiny dinos winter 2022 #1371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1371,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1371.png",
  },
  "1372": {
    name: "tiny dinos winter 2022 #1372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1372,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1372.png",
  },
  "1373": {
    name: "tiny dinos winter 2022 #1373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1373,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1373.png",
  },
  "1374": {
    name: "tiny dinos winter 2022 #1374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1374,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1374.png",
  },
  "1375": {
    name: "tiny dinos winter 2022 #1375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1375,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1375.png",
  },
  "1376": {
    name: "tiny dinos winter 2022 #1376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1376,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1376.png",
  },
  "1377": {
    name: "tiny dinos winter 2022 #1377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1377,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1377.png",
  },
  "1378": {
    name: "tiny dinos winter 2022 #1378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1378,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1378.png",
  },
  "1379": {
    name: "tiny dinos winter 2022 #1379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1379,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1379.png",
  },
  "1380": {
    name: "tiny dinos winter 2022 #1380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1380,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1380.png",
  },
  "1381": {
    name: "tiny dinos winter 2022 #1381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1381,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1381.png",
  },
  "1382": {
    name: "tiny dinos winter 2022 #1382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1382,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1382.png",
  },
  "1383": {
    name: "tiny dinos winter 2022 #1383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1383,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1383.png",
  },
  "1384": {
    name: "tiny dinos winter 2022 #1384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1384,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1384.png",
  },
  "1385": {
    name: "tiny dinos winter 2022 #1385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1385,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1385.png",
  },
  "1386": {
    name: "tiny dinos winter 2022 #1386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1386,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1386.png",
  },
  "1387": {
    name: "tiny dinos winter 2022 #1387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1387,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1387.png",
  },
  "1388": {
    name: "tiny dinos winter 2022 #1388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1388,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1388.png",
  },
  "1389": {
    name: "tiny dinos winter 2022 #1389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1389,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1389.png",
  },
  "1390": {
    name: "tiny dinos winter 2022 #1390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1390,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1390.png",
  },
  "1391": {
    name: "tiny dinos winter 2022 #1391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1391,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1391.png",
  },
  "1392": {
    name: "tiny dinos winter 2022 #1392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1392,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1392.png",
  },
  "1393": {
    name: "tiny dinos winter 2022 #1393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1393,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1393.png",
  },
  "1394": {
    name: "tiny dinos winter 2022 #1394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1394,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1394.png",
  },
  "1395": {
    name: "tiny dinos winter 2022 #1395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1395,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1395.png",
  },
  "1396": {
    name: "tiny dinos winter 2022 #1396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1396,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1396.png",
  },
  "1397": {
    name: "tiny dinos winter 2022 #1397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1397,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1397.png",
  },
  "1398": {
    name: "tiny dinos winter 2022 #1398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1398,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1398.png",
  },
  "1399": {
    name: "tiny dinos winter 2022 #1399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1399,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1399.png",
  },
  "1400": {
    name: "tiny dinos winter 2022 #1400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1400,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1400.png",
  },
  "1401": {
    name: "tiny dinos winter 2022 #1401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1401,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1401.png",
  },
  "1402": {
    name: "tiny dinos winter 2022 #1402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1402,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1402.png",
  },
  "1403": {
    name: "tiny dinos winter 2022 #1403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1403,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1403.png",
  },
  "1404": {
    name: "tiny dinos winter 2022 #1404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1404,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1404.png",
  },
  "1405": {
    name: "tiny dinos winter 2022 #1405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1405,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1405.png",
  },
  "1406": {
    name: "tiny dinos winter 2022 #1406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1406,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1406.png",
  },
  "1407": {
    name: "tiny dinos winter 2022 #1407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1407,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1407.png",
  },
  "1408": {
    name: "tiny dinos winter 2022 #1408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1408,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1408.png",
  },
  "1409": {
    name: "tiny dinos winter 2022 #1409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1409,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1409.png",
  },
  "1410": {
    name: "tiny dinos winter 2022 #1410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1410,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1410.png",
  },
  "1411": {
    name: "tiny dinos winter 2022 #1411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1411,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1411.png",
  },
  "1412": {
    name: "tiny dinos winter 2022 #1412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1412,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1412.png",
  },
  "1413": {
    name: "tiny dinos winter 2022 #1413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1413,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1413.png",
  },
  "1414": {
    name: "tiny dinos winter 2022 #1414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1414,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1414.png",
  },
  "1415": {
    name: "tiny dinos winter 2022 #1415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1415,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1415.png",
  },
  "1416": {
    name: "tiny dinos winter 2022 #1416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1416,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1416.png",
  },
  "1417": {
    name: "tiny dinos winter 2022 #1417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1417,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1417.png",
  },
  "1418": {
    name: "tiny dinos winter 2022 #1418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1418,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1418.png",
  },
  "1419": {
    name: "tiny dinos winter 2022 #1419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1419,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1419.png",
  },
  "1420": {
    name: "tiny dinos winter 2022 #1420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1420,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1420.png",
  },
  "1421": {
    name: "tiny dinos winter 2022 #1421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1421,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1421.png",
  },
  "1422": {
    name: "tiny dinos winter 2022 #1422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1422,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1422.png",
  },
  "1423": {
    name: "tiny dinos winter 2022 #1423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1423,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1423.png",
  },
  "1424": {
    name: "tiny dinos winter 2022 #1424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1424,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1424.png",
  },
  "1425": {
    name: "tiny dinos winter 2022 #1425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1425,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1425.png",
  },
  "1426": {
    name: "tiny dinos winter 2022 #1426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1426,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1426.png",
  },
  "1427": {
    name: "tiny dinos winter 2022 #1427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1427,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1427.png",
  },
  "1428": {
    name: "tiny dinos winter 2022 #1428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1428,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1428.png",
  },
  "1429": {
    name: "tiny dinos winter 2022 #1429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1429,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1429.png",
  },
  "1430": {
    name: "tiny dinos winter 2022 #1430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1430,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1430.png",
  },
  "1431": {
    name: "tiny dinos winter 2022 #1431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1431,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1431.png",
  },
  "1432": {
    name: "tiny dinos winter 2022 #1432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1432,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1432.png",
  },
  "1433": {
    name: "tiny dinos winter 2022 #1433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1433,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1433.png",
  },
  "1434": {
    name: "tiny dinos winter 2022 #1434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1434,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1434.png",
  },
  "1435": {
    name: "tiny dinos winter 2022 #1435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1435,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1435.png",
  },
  "1436": {
    name: "tiny dinos winter 2022 #1436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1436,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1436.png",
  },
  "1437": {
    name: "tiny dinos winter 2022 #1437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1437,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1437.png",
  },
  "1438": {
    name: "tiny dinos winter 2022 #1438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1438,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1438.png",
  },
  "1439": {
    name: "tiny dinos winter 2022 #1439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1439,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1439.png",
  },
  "1440": {
    name: "tiny dinos winter 2022 #1440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1440,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1440.png",
  },
  "1441": {
    name: "tiny dinos winter 2022 #1441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1441,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1441.png",
  },
  "1442": {
    name: "tiny dinos winter 2022 #1442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1442,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1442.png",
  },
  "1443": {
    name: "tiny dinos winter 2022 #1443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1443,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1443.png",
  },
  "1444": {
    name: "tiny dinos winter 2022 #1444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1444,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1444.png",
  },
  "1445": {
    name: "tiny dinos winter 2022 #1445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1445,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1445.png",
  },
  "1446": {
    name: "tiny dinos winter 2022 #1446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1446,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1446.png",
  },
  "1447": {
    name: "tiny dinos winter 2022 #1447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1447,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1447.png",
  },
  "1448": {
    name: "tiny dinos winter 2022 #1448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1448,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1448.png",
  },
  "1449": {
    name: "tiny dinos winter 2022 #1449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1449,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1449.png",
  },
  "1450": {
    name: "tiny dinos winter 2022 #1450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1450,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1450.png",
  },
  "1451": {
    name: "tiny dinos winter 2022 #1451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1451,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1451.png",
  },
  "1452": {
    name: "tiny dinos winter 2022 #1452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1452,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1452.png",
  },
  "1453": {
    name: "tiny dinos winter 2022 #1453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1453,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1453.png",
  },
  "1454": {
    name: "tiny dinos winter 2022 #1454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1454,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1454.png",
  },
  "1455": {
    name: "tiny dinos winter 2022 #1455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1455,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1455.png",
  },
  "1456": {
    name: "tiny dinos winter 2022 #1456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1456,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1456.png",
  },
  "1457": {
    name: "tiny dinos winter 2022 #1457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1457,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1457.png",
  },
  "1458": {
    name: "tiny dinos winter 2022 #1458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1458,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1458.png",
  },
  "1459": {
    name: "tiny dinos winter 2022 #1459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1459,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1459.png",
  },
  "1460": {
    name: "tiny dinos winter 2022 #1460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1460,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1460.png",
  },
  "1461": {
    name: "tiny dinos winter 2022 #1461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1461,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1461.png",
  },
  "1462": {
    name: "tiny dinos winter 2022 #1462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1462,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1462.png",
  },
  "1463": {
    name: "tiny dinos winter 2022 #1463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1463,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1463.png",
  },
  "1464": {
    name: "tiny dinos winter 2022 #1464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1464,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1464.png",
  },
  "1465": {
    name: "tiny dinos winter 2022 #1465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1465,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1465.png",
  },
  "1466": {
    name: "tiny dinos winter 2022 #1466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1466,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1466.png",
  },
  "1467": {
    name: "tiny dinos winter 2022 #1467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1467,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1467.png",
  },
  "1468": {
    name: "tiny dinos winter 2022 #1468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1468,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1468.png",
  },
  "1469": {
    name: "tiny dinos winter 2022 #1469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1469,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1469.png",
  },
  "1470": {
    name: "tiny dinos winter 2022 #1470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1470,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1470.png",
  },
  "1471": {
    name: "tiny dinos winter 2022 #1471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1471,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1471.png",
  },
  "1472": {
    name: "tiny dinos winter 2022 #1472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1472,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1472.png",
  },
  "1473": {
    name: "tiny dinos winter 2022 #1473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1473,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1473.png",
  },
  "1474": {
    name: "tiny dinos winter 2022 #1474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1474,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1474.png",
  },
  "1475": {
    name: "tiny dinos winter 2022 #1475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1475,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1475.png",
  },
  "1476": {
    name: "tiny dinos winter 2022 #1476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1476,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1476.png",
  },
  "1477": {
    name: "tiny dinos winter 2022 #1477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1477,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1477.png",
  },
  "1478": {
    name: "tiny dinos winter 2022 #1478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1478,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1478.png",
  },
  "1479": {
    name: "tiny dinos winter 2022 #1479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1479,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1479.png",
  },
  "1480": {
    name: "tiny dinos winter 2022 #1480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1480,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1480.png",
  },
  "1481": {
    name: "tiny dinos winter 2022 #1481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1481,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1481.png",
  },
  "1482": {
    name: "tiny dinos winter 2022 #1482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1482,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1482.png",
  },
  "1483": {
    name: "tiny dinos winter 2022 #1483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1483,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1483.png",
  },
  "1484": {
    name: "tiny dinos winter 2022 #1484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1484,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1484.png",
  },
  "1485": {
    name: "tiny dinos winter 2022 #1485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1485,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1485.png",
  },
  "1486": {
    name: "tiny dinos winter 2022 #1486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1486,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1486.png",
  },
  "1487": {
    name: "tiny dinos winter 2022 #1487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1487,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1487.png",
  },
  "1488": {
    name: "tiny dinos winter 2022 #1488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1488,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1488.png",
  },
  "1489": {
    name: "tiny dinos winter 2022 #1489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1489,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1489.png",
  },
  "1490": {
    name: "tiny dinos winter 2022 #1490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1490,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1490.png",
  },
  "1491": {
    name: "tiny dinos winter 2022 #1491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1491,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1491.png",
  },
  "1492": {
    name: "tiny dinos winter 2022 #1492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1492,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1492.png",
  },
  "1493": {
    name: "tiny dinos winter 2022 #1493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1493,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1493.png",
  },
  "1494": {
    name: "tiny dinos winter 2022 #1494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1494,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1494.png",
  },
  "1495": {
    name: "tiny dinos winter 2022 #1495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1495,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1495.png",
  },
  "1496": {
    name: "tiny dinos winter 2022 #1496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1496,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1496.png",
  },
  "1497": {
    name: "tiny dinos winter 2022 #1497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1497,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1497.png",
  },
  "1498": {
    name: "tiny dinos winter 2022 #1498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1498,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1498.png",
  },
  "1499": {
    name: "tiny dinos winter 2022 #1499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1499,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1499.png",
  },
  "1500": {
    name: "tiny dinos winter 2022 #1500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1500,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1500.png",
  },
  "1501": {
    name: "tiny dinos winter 2022 #1501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1501,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1501.png",
  },
  "1502": {
    name: "tiny dinos winter 2022 #1502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1502,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1502.png",
  },
  "1503": {
    name: "tiny dinos winter 2022 #1503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1503,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1503.png",
  },
  "1504": {
    name: "tiny dinos winter 2022 #1504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1504,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1504.png",
  },
  "1505": {
    name: "tiny dinos winter 2022 #1505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1505,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1505.png",
  },
  "1506": {
    name: "tiny dinos winter 2022 #1506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1506,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1506.png",
  },
  "1507": {
    name: "tiny dinos winter 2022 #1507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1507,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1507.png",
  },
  "1508": {
    name: "tiny dinos winter 2022 #1508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1508,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1508.png",
  },
  "1509": {
    name: "tiny dinos winter 2022 #1509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1509,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1509.png",
  },
  "1510": {
    name: "tiny dinos winter 2022 #1510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1510,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1510.png",
  },
  "1511": {
    name: "tiny dinos winter 2022 #1511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1511,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1511.png",
  },
  "1512": {
    name: "tiny dinos winter 2022 #1512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1512,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1512.png",
  },
  "1513": {
    name: "tiny dinos winter 2022 #1513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1513,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1513.png",
  },
  "1514": {
    name: "tiny dinos winter 2022 #1514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1514,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1514.png",
  },
  "1515": {
    name: "tiny dinos winter 2022 #1515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1515,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1515.png",
  },
  "1516": {
    name: "tiny dinos winter 2022 #1516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1516,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1516.png",
  },
  "1517": {
    name: "tiny dinos winter 2022 #1517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1517,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1517.png",
  },
  "1518": {
    name: "tiny dinos winter 2022 #1518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1518,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1518.png",
  },
  "1519": {
    name: "tiny dinos winter 2022 #1519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1519,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1519.png",
  },
  "1520": {
    name: "tiny dinos winter 2022 #1520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1520,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1520.png",
  },
  "1521": {
    name: "tiny dinos winter 2022 #1521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1521,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1521.png",
  },
  "1522": {
    name: "tiny dinos winter 2022 #1522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1522,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1522.png",
  },
  "1523": {
    name: "tiny dinos winter 2022 #1523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1523,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1523.png",
  },
  "1524": {
    name: "tiny dinos winter 2022 #1524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1524,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1524.png",
  },
  "1525": {
    name: "tiny dinos winter 2022 #1525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1525,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1525.png",
  },
  "1526": {
    name: "tiny dinos winter 2022 #1526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1526,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1526.png",
  },
  "1527": {
    name: "tiny dinos winter 2022 #1527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1527,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1527.png",
  },
  "1528": {
    name: "tiny dinos winter 2022 #1528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1528,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1528.png",
  },
  "1529": {
    name: "tiny dinos winter 2022 #1529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1529,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1529.png",
  },
  "1530": {
    name: "tiny dinos winter 2022 #1530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1530,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1530.png",
  },
  "1531": {
    name: "tiny dinos winter 2022 #1531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1531,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1531.png",
  },
  "1532": {
    name: "tiny dinos winter 2022 #1532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1532,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1532.png",
  },
  "1533": {
    name: "tiny dinos winter 2022 #1533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1533,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1533.png",
  },
  "1534": {
    name: "tiny dinos winter 2022 #1534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1534,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1534.png",
  },
  "1535": {
    name: "tiny dinos winter 2022 #1535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1535,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1535.png",
  },
  "1536": {
    name: "tiny dinos winter 2022 #1536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1536,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1536.png",
  },
  "1537": {
    name: "tiny dinos winter 2022 #1537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1537,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1537.png",
  },
  "1538": {
    name: "tiny dinos winter 2022 #1538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1538,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1538.png",
  },
  "1539": {
    name: "tiny dinos winter 2022 #1539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1539,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1539.png",
  },
  "1540": {
    name: "tiny dinos winter 2022 #1540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1540,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1540.png",
  },
  "1541": {
    name: "tiny dinos winter 2022 #1541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1541,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1541.png",
  },
  "1542": {
    name: "tiny dinos winter 2022 #1542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1542,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1542.png",
  },
  "1543": {
    name: "tiny dinos winter 2022 #1543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1543,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1543.png",
  },
  "1544": {
    name: "tiny dinos winter 2022 #1544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1544,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1544.png",
  },
  "1545": {
    name: "tiny dinos winter 2022 #1545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1545,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1545.png",
  },
  "1546": {
    name: "tiny dinos winter 2022 #1546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1546,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1546.png",
  },
  "1547": {
    name: "tiny dinos winter 2022 #1547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1547,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1547.png",
  },
  "1548": {
    name: "tiny dinos winter 2022 #1548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1548,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1548.png",
  },
  "1549": {
    name: "tiny dinos winter 2022 #1549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1549,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1549.png",
  },
  "1550": {
    name: "tiny dinos winter 2022 #1550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1550,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1550.png",
  },
  "1551": {
    name: "tiny dinos winter 2022 #1551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1551,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1551.png",
  },
  "1552": {
    name: "tiny dinos winter 2022 #1552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1552,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1552.png",
  },
  "1553": {
    name: "tiny dinos winter 2022 #1553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1553,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1553.png",
  },
  "1554": {
    name: "tiny dinos winter 2022 #1554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1554,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1554.png",
  },
  "1555": {
    name: "tiny dinos winter 2022 #1555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1555,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1555.png",
  },
  "1556": {
    name: "tiny dinos winter 2022 #1556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1556,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1556.png",
  },
  "1557": {
    name: "tiny dinos winter 2022 #1557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1557,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1557.png",
  },
  "1558": {
    name: "tiny dinos winter 2022 #1558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1558,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1558.png",
  },
  "1559": {
    name: "tiny dinos winter 2022 #1559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1559,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1559.png",
  },
  "1560": {
    name: "tiny dinos winter 2022 #1560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1560,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1560.png",
  },
  "1561": {
    name: "tiny dinos winter 2022 #1561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1561,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1561.png",
  },
  "1562": {
    name: "tiny dinos winter 2022 #1562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1562,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1562.png",
  },
  "1563": {
    name: "tiny dinos winter 2022 #1563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1563,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1563.png",
  },
  "1564": {
    name: "tiny dinos winter 2022 #1564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1564,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1564.png",
  },
  "1565": {
    name: "tiny dinos winter 2022 #1565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1565,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1565.png",
  },
  "1566": {
    name: "tiny dinos winter 2022 #1566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1566,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1566.png",
  },
  "1567": {
    name: "tiny dinos winter 2022 #1567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1567,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1567.png",
  },
  "1568": {
    name: "tiny dinos winter 2022 #1568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1568,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1568.png",
  },
  "1569": {
    name: "tiny dinos winter 2022 #1569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1569,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1569.png",
  },
  "1570": {
    name: "tiny dinos winter 2022 #1570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1570,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1570.png",
  },
  "1571": {
    name: "tiny dinos winter 2022 #1571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1571,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1571.png",
  },
  "1572": {
    name: "tiny dinos winter 2022 #1572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1572,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1572.png",
  },
  "1573": {
    name: "tiny dinos winter 2022 #1573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1573,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1573.png",
  },
  "1574": {
    name: "tiny dinos winter 2022 #1574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1574,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1574.png",
  },
  "1575": {
    name: "tiny dinos winter 2022 #1575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1575,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1575.png",
  },
  "1576": {
    name: "tiny dinos winter 2022 #1576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1576,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1576.png",
  },
  "1577": {
    name: "tiny dinos winter 2022 #1577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1577,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1577.png",
  },
  "1578": {
    name: "tiny dinos winter 2022 #1578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1578,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1578.png",
  },
  "1579": {
    name: "tiny dinos winter 2022 #1579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1579,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1579.png",
  },
  "1580": {
    name: "tiny dinos winter 2022 #1580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1580,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1580.png",
  },
  "1581": {
    name: "tiny dinos winter 2022 #1581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1581,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1581.png",
  },
  "1582": {
    name: "tiny dinos winter 2022 #1582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1582,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1582.png",
  },
  "1583": {
    name: "tiny dinos winter 2022 #1583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1583,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1583.png",
  },
  "1584": {
    name: "tiny dinos winter 2022 #1584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1584,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1584.png",
  },
  "1585": {
    name: "tiny dinos winter 2022 #1585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1585,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1585.png",
  },
  "1586": {
    name: "tiny dinos winter 2022 #1586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1586,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1586.png",
  },
  "1587": {
    name: "tiny dinos winter 2022 #1587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1587,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1587.png",
  },
  "1588": {
    name: "tiny dinos winter 2022 #1588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1588,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1588.png",
  },
  "1589": {
    name: "tiny dinos winter 2022 #1589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1589,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1589.png",
  },
  "1590": {
    name: "tiny dinos winter 2022 #1590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1590,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1590.png",
  },
  "1591": {
    name: "tiny dinos winter 2022 #1591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1591,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1591.png",
  },
  "1592": {
    name: "tiny dinos winter 2022 #1592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1592,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1592.png",
  },
  "1593": {
    name: "tiny dinos winter 2022 #1593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1593,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1593.png",
  },
  "1594": {
    name: "tiny dinos winter 2022 #1594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1594,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1594.png",
  },
  "1595": {
    name: "tiny dinos winter 2022 #1595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1595,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1595.png",
  },
  "1596": {
    name: "tiny dinos winter 2022 #1596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1596,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1596.png",
  },
  "1597": {
    name: "tiny dinos winter 2022 #1597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1597,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1597.png",
  },
  "1598": {
    name: "tiny dinos winter 2022 #1598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1598,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1598.png",
  },
  "1599": {
    name: "tiny dinos winter 2022 #1599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1599,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1599.png",
  },
  "1600": {
    name: "tiny dinos winter 2022 #1600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1600,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1600.png",
  },
  "1601": {
    name: "tiny dinos winter 2022 #1601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1601,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1601.png",
  },
  "1602": {
    name: "tiny dinos winter 2022 #1602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1602,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1602.png",
  },
  "1603": {
    name: "tiny dinos winter 2022 #1603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1603,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1603.png",
  },
  "1604": {
    name: "tiny dinos winter 2022 #1604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1604,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1604.png",
  },
  "1605": {
    name: "tiny dinos winter 2022 #1605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1605,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1605.png",
  },
  "1606": {
    name: "tiny dinos winter 2022 #1606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1606,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1606.png",
  },
  "1607": {
    name: "tiny dinos winter 2022 #1607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1607,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1607.png",
  },
  "1608": {
    name: "tiny dinos winter 2022 #1608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1608,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1608.png",
  },
  "1609": {
    name: "tiny dinos winter 2022 #1609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1609,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1609.png",
  },
  "1610": {
    name: "tiny dinos winter 2022 #1610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1610,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1610.png",
  },
  "1611": {
    name: "tiny dinos winter 2022 #1611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1611,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1611.png",
  },
  "1612": {
    name: "tiny dinos winter 2022 #1612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1612,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1612.png",
  },
  "1613": {
    name: "tiny dinos winter 2022 #1613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1613,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1613.png",
  },
  "1614": {
    name: "tiny dinos winter 2022 #1614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1614,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1614.png",
  },
  "1615": {
    name: "tiny dinos winter 2022 #1615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1615,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1615.png",
  },
  "1616": {
    name: "tiny dinos winter 2022 #1616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1616,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1616.png",
  },
  "1617": {
    name: "tiny dinos winter 2022 #1617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1617,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1617.png",
  },
  "1618": {
    name: "tiny dinos winter 2022 #1618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1618,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1618.png",
  },
  "1619": {
    name: "tiny dinos winter 2022 #1619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1619,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1619.png",
  },
  "1620": {
    name: "tiny dinos winter 2022 #1620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1620,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1620.png",
  },
  "1621": {
    name: "tiny dinos winter 2022 #1621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1621,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1621.png",
  },
  "1622": {
    name: "tiny dinos winter 2022 #1622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1622,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1622.png",
  },
  "1623": {
    name: "tiny dinos winter 2022 #1623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1623,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1623.png",
  },
  "1624": {
    name: "tiny dinos winter 2022 #1624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1624,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1624.png",
  },
  "1625": {
    name: "tiny dinos winter 2022 #1625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1625,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1625.png",
  },
  "1626": {
    name: "tiny dinos winter 2022 #1626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1626,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1626.png",
  },
  "1627": {
    name: "tiny dinos winter 2022 #1627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1627,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1627.png",
  },
  "1628": {
    name: "tiny dinos winter 2022 #1628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1628,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1628.png",
  },
  "1629": {
    name: "tiny dinos winter 2022 #1629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1629,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1629.png",
  },
  "1630": {
    name: "tiny dinos winter 2022 #1630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1630,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1630.png",
  },
  "1631": {
    name: "tiny dinos winter 2022 #1631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1631,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1631.png",
  },
  "1632": {
    name: "tiny dinos winter 2022 #1632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1632,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1632.png",
  },
  "1633": {
    name: "tiny dinos winter 2022 #1633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1633,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1633.png",
  },
  "1634": {
    name: "tiny dinos winter 2022 #1634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1634,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1634.png",
  },
  "1635": {
    name: "tiny dinos winter 2022 #1635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1635,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1635.png",
  },
  "1636": {
    name: "tiny dinos winter 2022 #1636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1636,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1636.png",
  },
  "1637": {
    name: "tiny dinos winter 2022 #1637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1637,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1637.png",
  },
  "1638": {
    name: "tiny dinos winter 2022 #1638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1638,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1638.png",
  },
  "1639": {
    name: "tiny dinos winter 2022 #1639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1639,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1639.png",
  },
  "1640": {
    name: "tiny dinos winter 2022 #1640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1640,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1640.png",
  },
  "1641": {
    name: "tiny dinos winter 2022 #1641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1641,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1641.png",
  },
  "1642": {
    name: "tiny dinos winter 2022 #1642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1642,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1642.png",
  },
  "1643": {
    name: "tiny dinos winter 2022 #1643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1643,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1643.png",
  },
  "1644": {
    name: "tiny dinos winter 2022 #1644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1644,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1644.png",
  },
  "1645": {
    name: "tiny dinos winter 2022 #1645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1645,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1645.png",
  },
  "1646": {
    name: "tiny dinos winter 2022 #1646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1646,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1646.png",
  },
  "1647": {
    name: "tiny dinos winter 2022 #1647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1647,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1647.png",
  },
  "1648": {
    name: "tiny dinos winter 2022 #1648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1648,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1648.png",
  },
  "1649": {
    name: "tiny dinos winter 2022 #1649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1649,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1649.png",
  },
  "1650": {
    name: "tiny dinos winter 2022 #1650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1650,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1650.png",
  },
  "1651": {
    name: "tiny dinos winter 2022 #1651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1651,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1651.png",
  },
  "1652": {
    name: "tiny dinos winter 2022 #1652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1652,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1652.png",
  },
  "1653": {
    name: "tiny dinos winter 2022 #1653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1653,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1653.png",
  },
  "1654": {
    name: "tiny dinos winter 2022 #1654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1654,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1654.png",
  },
  "1655": {
    name: "tiny dinos winter 2022 #1655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1655,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1655.png",
  },
  "1656": {
    name: "tiny dinos winter 2022 #1656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1656,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1656.png",
  },
  "1657": {
    name: "tiny dinos winter 2022 #1657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1657,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1657.png",
  },
  "1658": {
    name: "tiny dinos winter 2022 #1658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1658,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1658.png",
  },
  "1659": {
    name: "tiny dinos winter 2022 #1659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1659,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1659.png",
  },
  "1660": {
    name: "tiny dinos winter 2022 #1660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1660,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1660.png",
  },
  "1661": {
    name: "tiny dinos winter 2022 #1661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1661,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1661.png",
  },
  "1662": {
    name: "tiny dinos winter 2022 #1662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1662,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1662.png",
  },
  "1663": {
    name: "tiny dinos winter 2022 #1663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1663,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1663.png",
  },
  "1664": {
    name: "tiny dinos winter 2022 #1664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1664,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1664.png",
  },
  "1665": {
    name: "tiny dinos winter 2022 #1665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1665,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1665.png",
  },
  "1666": {
    name: "tiny dinos winter 2022 #1666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1666,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1666.png",
  },
  "1667": {
    name: "tiny dinos winter 2022 #1667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1667,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1667.png",
  },
  "1668": {
    name: "tiny dinos winter 2022 #1668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1668,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1668.png",
  },
  "1669": {
    name: "tiny dinos winter 2022 #1669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1669,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1669.png",
  },
  "1670": {
    name: "tiny dinos winter 2022 #1670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1670,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1670.png",
  },
  "1671": {
    name: "tiny dinos winter 2022 #1671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1671,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1671.png",
  },
  "1672": {
    name: "tiny dinos winter 2022 #1672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1672,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1672.png",
  },
  "1673": {
    name: "tiny dinos winter 2022 #1673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1673,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1673.png",
  },
  "1674": {
    name: "tiny dinos winter 2022 #1674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1674,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1674.png",
  },
  "1675": {
    name: "tiny dinos winter 2022 #1675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1675,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1675.png",
  },
  "1676": {
    name: "tiny dinos winter 2022 #1676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1676,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1676.png",
  },
  "1677": {
    name: "tiny dinos winter 2022 #1677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1677,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1677.png",
  },
  "1678": {
    name: "tiny dinos winter 2022 #1678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1678,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1678.png",
  },
  "1679": {
    name: "tiny dinos winter 2022 #1679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1679,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1679.png",
  },
  "1680": {
    name: "tiny dinos winter 2022 #1680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1680,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1680.png",
  },
  "1681": {
    name: "tiny dinos winter 2022 #1681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1681,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1681.png",
  },
  "1682": {
    name: "tiny dinos winter 2022 #1682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1682,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1682.png",
  },
  "1683": {
    name: "tiny dinos winter 2022 #1683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1683,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1683.png",
  },
  "1684": {
    name: "tiny dinos winter 2022 #1684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1684,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1684.png",
  },
  "1685": {
    name: "tiny dinos winter 2022 #1685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1685,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1685.png",
  },
  "1686": {
    name: "tiny dinos winter 2022 #1686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1686,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1686.png",
  },
  "1687": {
    name: "tiny dinos winter 2022 #1687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1687,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1687.png",
  },
  "1688": {
    name: "tiny dinos winter 2022 #1688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1688,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1688.png",
  },
  "1689": {
    name: "tiny dinos winter 2022 #1689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1689,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1689.png",
  },
  "1690": {
    name: "tiny dinos winter 2022 #1690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1690,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1690.png",
  },
  "1691": {
    name: "tiny dinos winter 2022 #1691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1691,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1691.png",
  },
  "1692": {
    name: "tiny dinos winter 2022 #1692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1692,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1692.png",
  },
  "1693": {
    name: "tiny dinos winter 2022 #1693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1693,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1693.png",
  },
  "1694": {
    name: "tiny dinos winter 2022 #1694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1694,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1694.png",
  },
  "1695": {
    name: "tiny dinos winter 2022 #1695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1695,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1695.png",
  },
  "1696": {
    name: "tiny dinos winter 2022 #1696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1696,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1696.png",
  },
  "1697": {
    name: "tiny dinos winter 2022 #1697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1697,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1697.png",
  },
  "1698": {
    name: "tiny dinos winter 2022 #1698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1698,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1698.png",
  },
  "1699": {
    name: "tiny dinos winter 2022 #1699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1699,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1699.png",
  },
  "1700": {
    name: "tiny dinos winter 2022 #1700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1700,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1700.png",
  },
  "1701": {
    name: "tiny dinos winter 2022 #1701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1701,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1701.png",
  },
  "1702": {
    name: "tiny dinos winter 2022 #1702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1702,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1702.png",
  },
  "1703": {
    name: "tiny dinos winter 2022 #1703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1703,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1703.png",
  },
  "1704": {
    name: "tiny dinos winter 2022 #1704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1704,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1704.png",
  },
  "1705": {
    name: "tiny dinos winter 2022 #1705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1705,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1705.png",
  },
  "1706": {
    name: "tiny dinos winter 2022 #1706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1706,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1706.png",
  },
  "1707": {
    name: "tiny dinos winter 2022 #1707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1707,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1707.png",
  },
  "1708": {
    name: "tiny dinos winter 2022 #1708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1708,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1708.png",
  },
  "1709": {
    name: "tiny dinos winter 2022 #1709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1709,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1709.png",
  },
  "1710": {
    name: "tiny dinos winter 2022 #1710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1710,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1710.png",
  },
  "1711": {
    name: "tiny dinos winter 2022 #1711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1711,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1711.png",
  },
  "1712": {
    name: "tiny dinos winter 2022 #1712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1712,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1712.png",
  },
  "1713": {
    name: "tiny dinos winter 2022 #1713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1713,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1713.png",
  },
  "1714": {
    name: "tiny dinos winter 2022 #1714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1714,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1714.png",
  },
  "1715": {
    name: "tiny dinos winter 2022 #1715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1715,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1715.png",
  },
  "1716": {
    name: "tiny dinos winter 2022 #1716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1716,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1716.png",
  },
  "1717": {
    name: "tiny dinos winter 2022 #1717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1717,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1717.png",
  },
  "1718": {
    name: "tiny dinos winter 2022 #1718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1718,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1718.png",
  },
  "1719": {
    name: "tiny dinos winter 2022 #1719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1719,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1719.png",
  },
  "1720": {
    name: "tiny dinos winter 2022 #1720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1720,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1720.png",
  },
  "1721": {
    name: "tiny dinos winter 2022 #1721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1721,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1721.png",
  },
  "1722": {
    name: "tiny dinos winter 2022 #1722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1722,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1722.png",
  },
  "1723": {
    name: "tiny dinos winter 2022 #1723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1723,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1723.png",
  },
  "1724": {
    name: "tiny dinos winter 2022 #1724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1724,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1724.png",
  },
  "1725": {
    name: "tiny dinos winter 2022 #1725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1725,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1725.png",
  },
  "1726": {
    name: "tiny dinos winter 2022 #1726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1726,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1726.png",
  },
  "1727": {
    name: "tiny dinos winter 2022 #1727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1727,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1727.png",
  },
  "1728": {
    name: "tiny dinos winter 2022 #1728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1728,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1728.png",
  },
  "1729": {
    name: "tiny dinos winter 2022 #1729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1729,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1729.png",
  },
  "1730": {
    name: "tiny dinos winter 2022 #1730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1730,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1730.png",
  },
  "1731": {
    name: "tiny dinos winter 2022 #1731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1731,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1731.png",
  },
  "1732": {
    name: "tiny dinos winter 2022 #1732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1732,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1732.png",
  },
  "1733": {
    name: "tiny dinos winter 2022 #1733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1733,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1733.png",
  },
  "1734": {
    name: "tiny dinos winter 2022 #1734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1734,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1734.png",
  },
  "1735": {
    name: "tiny dinos winter 2022 #1735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1735,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1735.png",
  },
  "1736": {
    name: "tiny dinos winter 2022 #1736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1736,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1736.png",
  },
  "1737": {
    name: "tiny dinos winter 2022 #1737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1737,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1737.png",
  },
  "1738": {
    name: "tiny dinos winter 2022 #1738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1738,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1738.png",
  },
  "1739": {
    name: "tiny dinos winter 2022 #1739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1739,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1739.png",
  },
  "1740": {
    name: "tiny dinos winter 2022 #1740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1740,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1740.png",
  },
  "1741": {
    name: "tiny dinos winter 2022 #1741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1741,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1741.png",
  },
  "1742": {
    name: "tiny dinos winter 2022 #1742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1742,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1742.png",
  },
  "1743": {
    name: "tiny dinos winter 2022 #1743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1743,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1743.png",
  },
  "1744": {
    name: "tiny dinos winter 2022 #1744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1744,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1744.png",
  },
  "1745": {
    name: "tiny dinos winter 2022 #1745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1745,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1745.png",
  },
  "1746": {
    name: "tiny dinos winter 2022 #1746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1746,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1746.png",
  },
  "1747": {
    name: "tiny dinos winter 2022 #1747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1747,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1747.png",
  },
  "1748": {
    name: "tiny dinos winter 2022 #1748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1748,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1748.png",
  },
  "1749": {
    name: "tiny dinos winter 2022 #1749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1749,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1749.png",
  },
  "1750": {
    name: "tiny dinos winter 2022 #1750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1750,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1750.png",
  },
  "1751": {
    name: "tiny dinos winter 2022 #1751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1751,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1751.png",
  },
  "1752": {
    name: "tiny dinos winter 2022 #1752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1752,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1752.png",
  },
  "1753": {
    name: "tiny dinos winter 2022 #1753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1753,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1753.png",
  },
  "1754": {
    name: "tiny dinos winter 2022 #1754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1754,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1754.png",
  },
  "1755": {
    name: "tiny dinos winter 2022 #1755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1755,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1755.png",
  },
  "1756": {
    name: "tiny dinos winter 2022 #1756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1756,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1756.png",
  },
  "1757": {
    name: "tiny dinos winter 2022 #1757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1757,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1757.png",
  },
  "1758": {
    name: "tiny dinos winter 2022 #1758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1758,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1758.png",
  },
  "1759": {
    name: "tiny dinos winter 2022 #1759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1759,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1759.png",
  },
  "1760": {
    name: "tiny dinos winter 2022 #1760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1760,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1760.png",
  },
  "1761": {
    name: "tiny dinos winter 2022 #1761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1761,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1761.png",
  },
  "1762": {
    name: "tiny dinos winter 2022 #1762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1762,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1762.png",
  },
  "1763": {
    name: "tiny dinos winter 2022 #1763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1763,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1763.png",
  },
  "1764": {
    name: "tiny dinos winter 2022 #1764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1764,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1764.png",
  },
  "1765": {
    name: "tiny dinos winter 2022 #1765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1765,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1765.png",
  },
  "1766": {
    name: "tiny dinos winter 2022 #1766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1766,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1766.png",
  },
  "1767": {
    name: "tiny dinos winter 2022 #1767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1767,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1767.png",
  },
  "1768": {
    name: "tiny dinos winter 2022 #1768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1768,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1768.png",
  },
  "1769": {
    name: "tiny dinos winter 2022 #1769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1769,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1769.png",
  },
  "1770": {
    name: "tiny dinos winter 2022 #1770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1770,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1770.png",
  },
  "1771": {
    name: "tiny dinos winter 2022 #1771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1771,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1771.png",
  },
  "1772": {
    name: "tiny dinos winter 2022 #1772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1772,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1772.png",
  },
  "1773": {
    name: "tiny dinos winter 2022 #1773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1773,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1773.png",
  },
  "1774": {
    name: "tiny dinos winter 2022 #1774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1774,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1774.png",
  },
  "1775": {
    name: "tiny dinos winter 2022 #1775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1775,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1775.png",
  },
  "1776": {
    name: "tiny dinos winter 2022 #1776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1776,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1776.png",
  },
  "1777": {
    name: "tiny dinos winter 2022 #1777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1777,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1777.png",
  },
  "1778": {
    name: "tiny dinos winter 2022 #1778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1778,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1778.png",
  },
  "1779": {
    name: "tiny dinos winter 2022 #1779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1779,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1779.png",
  },
  "1780": {
    name: "tiny dinos winter 2022 #1780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1780,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1780.png",
  },
  "1781": {
    name: "tiny dinos winter 2022 #1781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1781,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1781.png",
  },
  "1782": {
    name: "tiny dinos winter 2022 #1782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1782,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1782.png",
  },
  "1783": {
    name: "tiny dinos winter 2022 #1783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1783,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1783.png",
  },
  "1784": {
    name: "tiny dinos winter 2022 #1784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1784,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1784.png",
  },
  "1785": {
    name: "tiny dinos winter 2022 #1785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1785,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1785.png",
  },
  "1786": {
    name: "tiny dinos winter 2022 #1786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1786,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1786.png",
  },
  "1787": {
    name: "tiny dinos winter 2022 #1787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1787,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1787.png",
  },
  "1788": {
    name: "tiny dinos winter 2022 #1788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1788,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1788.png",
  },
  "1789": {
    name: "tiny dinos winter 2022 #1789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1789,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1789.png",
  },
  "1790": {
    name: "tiny dinos winter 2022 #1790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1790,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1790.png",
  },
  "1791": {
    name: "tiny dinos winter 2022 #1791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1791,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1791.png",
  },
  "1792": {
    name: "tiny dinos winter 2022 #1792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1792,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1792.png",
  },
  "1793": {
    name: "tiny dinos winter 2022 #1793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1793,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1793.png",
  },
  "1794": {
    name: "tiny dinos winter 2022 #1794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1794,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1794.png",
  },
  "1795": {
    name: "tiny dinos winter 2022 #1795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1795,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1795.png",
  },
  "1796": {
    name: "tiny dinos winter 2022 #1796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1796,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1796.png",
  },
  "1797": {
    name: "tiny dinos winter 2022 #1797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1797,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1797.png",
  },
  "1798": {
    name: "tiny dinos winter 2022 #1798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1798,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1798.png",
  },
  "1799": {
    name: "tiny dinos winter 2022 #1799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1799,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1799.png",
  },
  "1800": {
    name: "tiny dinos winter 2022 #1800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1800,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1800.png",
  },
  "1801": {
    name: "tiny dinos winter 2022 #1801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1801,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1801.png",
  },
  "1802": {
    name: "tiny dinos winter 2022 #1802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1802,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1802.png",
  },
  "1803": {
    name: "tiny dinos winter 2022 #1803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1803,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1803.png",
  },
  "1804": {
    name: "tiny dinos winter 2022 #1804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1804,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1804.png",
  },
  "1805": {
    name: "tiny dinos winter 2022 #1805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1805,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1805.png",
  },
  "1806": {
    name: "tiny dinos winter 2022 #1806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1806,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1806.png",
  },
  "1807": {
    name: "tiny dinos winter 2022 #1807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1807,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1807.png",
  },
  "1808": {
    name: "tiny dinos winter 2022 #1808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1808,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1808.png",
  },
  "1809": {
    name: "tiny dinos winter 2022 #1809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1809,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1809.png",
  },
  "1810": {
    name: "tiny dinos winter 2022 #1810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1810,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1810.png",
  },
  "1811": {
    name: "tiny dinos winter 2022 #1811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1811,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1811.png",
  },
  "1812": {
    name: "tiny dinos winter 2022 #1812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1812,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1812.png",
  },
  "1813": {
    name: "tiny dinos winter 2022 #1813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1813,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1813.png",
  },
  "1814": {
    name: "tiny dinos winter 2022 #1814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1814,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1814.png",
  },
  "1815": {
    name: "tiny dinos winter 2022 #1815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1815,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1815.png",
  },
  "1816": {
    name: "tiny dinos winter 2022 #1816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1816,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1816.png",
  },
  "1817": {
    name: "tiny dinos winter 2022 #1817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1817,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1817.png",
  },
  "1818": {
    name: "tiny dinos winter 2022 #1818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1818,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1818.png",
  },
  "1819": {
    name: "tiny dinos winter 2022 #1819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1819,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1819.png",
  },
  "1820": {
    name: "tiny dinos winter 2022 #1820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1820,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1820.png",
  },
  "1821": {
    name: "tiny dinos winter 2022 #1821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1821,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1821.png",
  },
  "1822": {
    name: "tiny dinos winter 2022 #1822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1822,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1822.png",
  },
  "1823": {
    name: "tiny dinos winter 2022 #1823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1823,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1823.png",
  },
  "1824": {
    name: "tiny dinos winter 2022 #1824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1824,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1824.png",
  },
  "1825": {
    name: "tiny dinos winter 2022 #1825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1825,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1825.png",
  },
  "1826": {
    name: "tiny dinos winter 2022 #1826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1826,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1826.png",
  },
  "1827": {
    name: "tiny dinos winter 2022 #1827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1827,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1827.png",
  },
  "1828": {
    name: "tiny dinos winter 2022 #1828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1828,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1828.png",
  },
  "1829": {
    name: "tiny dinos winter 2022 #1829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1829,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1829.png",
  },
  "1830": {
    name: "tiny dinos winter 2022 #1830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1830,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1830.png",
  },
  "1831": {
    name: "tiny dinos winter 2022 #1831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1831,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1831.png",
  },
  "1832": {
    name: "tiny dinos winter 2022 #1832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1832,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1832.png",
  },
  "1833": {
    name: "tiny dinos winter 2022 #1833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1833,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1833.png",
  },
  "1834": {
    name: "tiny dinos winter 2022 #1834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1834,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1834.png",
  },
  "1835": {
    name: "tiny dinos winter 2022 #1835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1835,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1835.png",
  },
  "1836": {
    name: "tiny dinos winter 2022 #1836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1836,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1836.png",
  },
  "1837": {
    name: "tiny dinos winter 2022 #1837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1837,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1837.png",
  },
  "1838": {
    name: "tiny dinos winter 2022 #1838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1838,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1838.png",
  },
  "1839": {
    name: "tiny dinos winter 2022 #1839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1839,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1839.png",
  },
  "1840": {
    name: "tiny dinos winter 2022 #1840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1840,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1840.png",
  },
  "1841": {
    name: "tiny dinos winter 2022 #1841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1841,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1841.png",
  },
  "1842": {
    name: "tiny dinos winter 2022 #1842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1842,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1842.png",
  },
  "1843": {
    name: "tiny dinos winter 2022 #1843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1843,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1843.png",
  },
  "1844": {
    name: "tiny dinos winter 2022 #1844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1844,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1844.png",
  },
  "1845": {
    name: "tiny dinos winter 2022 #1845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1845,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1845.png",
  },
  "1846": {
    name: "tiny dinos winter 2022 #1846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1846,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1846.png",
  },
  "1847": {
    name: "tiny dinos winter 2022 #1847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1847,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1847.png",
  },
  "1848": {
    name: "tiny dinos winter 2022 #1848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1848,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1848.png",
  },
  "1849": {
    name: "tiny dinos winter 2022 #1849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1849,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1849.png",
  },
  "1850": {
    name: "tiny dinos winter 2022 #1850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1850,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1850.png",
  },
  "1851": {
    name: "tiny dinos winter 2022 #1851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1851,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1851.png",
  },
  "1852": {
    name: "tiny dinos winter 2022 #1852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1852,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1852.png",
  },
  "1853": {
    name: "tiny dinos winter 2022 #1853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1853,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1853.png",
  },
  "1854": {
    name: "tiny dinos winter 2022 #1854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1854,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1854.png",
  },
  "1855": {
    name: "tiny dinos winter 2022 #1855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1855,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1855.png",
  },
  "1856": {
    name: "tiny dinos winter 2022 #1856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1856,
    attributes: [
      {
        trait_type: "1/1",
        value: "poly",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1856.png",
  },
  "1857": {
    name: "tiny dinos winter 2022 #1857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1857,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1857.png",
  },
  "1858": {
    name: "tiny dinos winter 2022 #1858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1858,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1858.png",
  },
  "1859": {
    name: "tiny dinos winter 2022 #1859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1859,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1859.png",
  },
  "1860": {
    name: "tiny dinos winter 2022 #1860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1860,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1860.png",
  },
  "1861": {
    name: "tiny dinos winter 2022 #1861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1861,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1861.png",
  },
  "1862": {
    name: "tiny dinos winter 2022 #1862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1862,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1862.png",
  },
  "1863": {
    name: "tiny dinos winter 2022 #1863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1863,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1863.png",
  },
  "1864": {
    name: "tiny dinos winter 2022 #1864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1864,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1864.png",
  },
  "1865": {
    name: "tiny dinos winter 2022 #1865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1865,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1865.png",
  },
  "1866": {
    name: "tiny dinos winter 2022 #1866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1866,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1866.png",
  },
  "1867": {
    name: "tiny dinos winter 2022 #1867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1867,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1867.png",
  },
  "1868": {
    name: "tiny dinos winter 2022 #1868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1868,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1868.png",
  },
  "1869": {
    name: "tiny dinos winter 2022 #1869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1869,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1869.png",
  },
  "1870": {
    name: "tiny dinos winter 2022 #1870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1870,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1870.png",
  },
  "1871": {
    name: "tiny dinos winter 2022 #1871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1871,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1871.png",
  },
  "1872": {
    name: "tiny dinos winter 2022 #1872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1872,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1872.png",
  },
  "1873": {
    name: "tiny dinos winter 2022 #1873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1873,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1873.png",
  },
  "1874": {
    name: "tiny dinos winter 2022 #1874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1874,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1874.png",
  },
  "1875": {
    name: "tiny dinos winter 2022 #1875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1875,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1875.png",
  },
  "1876": {
    name: "tiny dinos winter 2022 #1876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1876,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1876.png",
  },
  "1877": {
    name: "tiny dinos winter 2022 #1877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1877,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1877.png",
  },
  "1878": {
    name: "tiny dinos winter 2022 #1878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1878,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1878.png",
  },
  "1879": {
    name: "tiny dinos winter 2022 #1879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1879,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1879.png",
  },
  "1880": {
    name: "tiny dinos winter 2022 #1880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1880,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1880.png",
  },
  "1881": {
    name: "tiny dinos winter 2022 #1881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1881,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1881.png",
  },
  "1882": {
    name: "tiny dinos winter 2022 #1882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1882,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1882.png",
  },
  "1883": {
    name: "tiny dinos winter 2022 #1883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1883,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1883.png",
  },
  "1884": {
    name: "tiny dinos winter 2022 #1884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1884,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1884.png",
  },
  "1885": {
    name: "tiny dinos winter 2022 #1885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1885,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1885.png",
  },
  "1886": {
    name: "tiny dinos winter 2022 #1886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1886,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1886.png",
  },
  "1887": {
    name: "tiny dinos winter 2022 #1887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1887,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1887.png",
  },
  "1888": {
    name: "tiny dinos winter 2022 #1888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1888,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1888.png",
  },
  "1889": {
    name: "tiny dinos winter 2022 #1889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1889,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1889.png",
  },
  "1890": {
    name: "tiny dinos winter 2022 #1890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1890,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1890.png",
  },
  "1891": {
    name: "tiny dinos winter 2022 #1891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1891,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1891.png",
  },
  "1892": {
    name: "tiny dinos winter 2022 #1892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1892,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1892.png",
  },
  "1893": {
    name: "tiny dinos winter 2022 #1893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1893,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1893.png",
  },
  "1894": {
    name: "tiny dinos winter 2022 #1894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1894,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1894.png",
  },
  "1895": {
    name: "tiny dinos winter 2022 #1895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1895,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1895.png",
  },
  "1896": {
    name: "tiny dinos winter 2022 #1896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1896,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1896.png",
  },
  "1897": {
    name: "tiny dinos winter 2022 #1897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1897,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1897.png",
  },
  "1898": {
    name: "tiny dinos winter 2022 #1898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1898,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1898.png",
  },
  "1899": {
    name: "tiny dinos winter 2022 #1899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1899,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1899.png",
  },
  "1900": {
    name: "tiny dinos winter 2022 #1900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1900,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1900.png",
  },
  "1901": {
    name: "tiny dinos winter 2022 #1901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1901,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1901.png",
  },
  "1902": {
    name: "tiny dinos winter 2022 #1902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1902,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1902.png",
  },
  "1903": {
    name: "tiny dinos winter 2022 #1903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1903,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1903.png",
  },
  "1904": {
    name: "tiny dinos winter 2022 #1904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1904,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1904.png",
  },
  "1905": {
    name: "tiny dinos winter 2022 #1905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1905,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1905.png",
  },
  "1906": {
    name: "tiny dinos winter 2022 #1906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1906,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1906.png",
  },
  "1907": {
    name: "tiny dinos winter 2022 #1907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1907,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1907.png",
  },
  "1908": {
    name: "tiny dinos winter 2022 #1908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1908,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1908.png",
  },
  "1909": {
    name: "tiny dinos winter 2022 #1909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1909,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1909.png",
  },
  "1910": {
    name: "tiny dinos winter 2022 #1910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1910,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1910.png",
  },
  "1911": {
    name: "tiny dinos winter 2022 #1911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1911,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1911.png",
  },
  "1912": {
    name: "tiny dinos winter 2022 #1912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1912,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1912.png",
  },
  "1913": {
    name: "tiny dinos winter 2022 #1913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1913,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1913.png",
  },
  "1914": {
    name: "tiny dinos winter 2022 #1914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1914,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1914.png",
  },
  "1915": {
    name: "tiny dinos winter 2022 #1915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1915,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1915.png",
  },
  "1916": {
    name: "tiny dinos winter 2022 #1916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1916,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1916.png",
  },
  "1917": {
    name: "tiny dinos winter 2022 #1917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1917,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1917.png",
  },
  "1918": {
    name: "tiny dinos winter 2022 #1918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1918,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1918.png",
  },
  "1919": {
    name: "tiny dinos winter 2022 #1919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1919,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1919.png",
  },
  "1920": {
    name: "tiny dinos winter 2022 #1920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1920,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1920.png",
  },
  "1921": {
    name: "tiny dinos winter 2022 #1921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1921,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1921.png",
  },
  "1922": {
    name: "tiny dinos winter 2022 #1922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1922,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1922.png",
  },
  "1923": {
    name: "tiny dinos winter 2022 #1923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1923,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1923.png",
  },
  "1924": {
    name: "tiny dinos winter 2022 #1924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1924,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1924.png",
  },
  "1925": {
    name: "tiny dinos winter 2022 #1925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1925,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1925.png",
  },
  "1926": {
    name: "tiny dinos winter 2022 #1926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1926,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1926.png",
  },
  "1927": {
    name: "tiny dinos winter 2022 #1927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1927,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1927.png",
  },
  "1928": {
    name: "tiny dinos winter 2022 #1928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1928,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1928.png",
  },
  "1929": {
    name: "tiny dinos winter 2022 #1929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1929,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1929.png",
  },
  "1930": {
    name: "tiny dinos winter 2022 #1930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1930,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1930.png",
  },
  "1931": {
    name: "tiny dinos winter 2022 #1931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1931,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1931.png",
  },
  "1932": {
    name: "tiny dinos winter 2022 #1932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1932,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1932.png",
  },
  "1933": {
    name: "tiny dinos winter 2022 #1933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1933,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1933.png",
  },
  "1934": {
    name: "tiny dinos winter 2022 #1934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1934,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1934.png",
  },
  "1935": {
    name: "tiny dinos winter 2022 #1935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1935,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1935.png",
  },
  "1936": {
    name: "tiny dinos winter 2022 #1936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1936,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1936.png",
  },
  "1937": {
    name: "tiny dinos winter 2022 #1937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1937,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1937.png",
  },
  "1938": {
    name: "tiny dinos winter 2022 #1938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1938,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1938.png",
  },
  "1939": {
    name: "tiny dinos winter 2022 #1939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1939,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1939.png",
  },
  "1940": {
    name: "tiny dinos winter 2022 #1940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1940,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1940.png",
  },
  "1941": {
    name: "tiny dinos winter 2022 #1941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1941,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1941.png",
  },
  "1942": {
    name: "tiny dinos winter 2022 #1942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1942,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1942.png",
  },
  "1943": {
    name: "tiny dinos winter 2022 #1943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1943,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1943.png",
  },
  "1944": {
    name: "tiny dinos winter 2022 #1944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1944,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1944.png",
  },
  "1945": {
    name: "tiny dinos winter 2022 #1945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1945,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1945.png",
  },
  "1946": {
    name: "tiny dinos winter 2022 #1946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1946,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1946.png",
  },
  "1947": {
    name: "tiny dinos winter 2022 #1947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1947,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1947.png",
  },
  "1948": {
    name: "tiny dinos winter 2022 #1948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1948,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1948.png",
  },
  "1949": {
    name: "tiny dinos winter 2022 #1949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1949,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1949.png",
  },
  "1950": {
    name: "tiny dinos winter 2022 #1950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1950,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1950.png",
  },
  "1951": {
    name: "tiny dinos winter 2022 #1951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1951,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1951.png",
  },
  "1952": {
    name: "tiny dinos winter 2022 #1952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1952,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1952.png",
  },
  "1953": {
    name: "tiny dinos winter 2022 #1953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1953,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1953.png",
  },
  "1954": {
    name: "tiny dinos winter 2022 #1954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1954,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1954.png",
  },
  "1955": {
    name: "tiny dinos winter 2022 #1955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1955,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1955.png",
  },
  "1956": {
    name: "tiny dinos winter 2022 #1956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1956,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1956.png",
  },
  "1957": {
    name: "tiny dinos winter 2022 #1957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1957,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1957.png",
  },
  "1958": {
    name: "tiny dinos winter 2022 #1958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1958,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1958.png",
  },
  "1959": {
    name: "tiny dinos winter 2022 #1959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1959,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1959.png",
  },
  "1960": {
    name: "tiny dinos winter 2022 #1960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1960,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1960.png",
  },
  "1961": {
    name: "tiny dinos winter 2022 #1961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1961,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1961.png",
  },
  "1962": {
    name: "tiny dinos winter 2022 #1962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1962,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1962.png",
  },
  "1963": {
    name: "tiny dinos winter 2022 #1963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1963,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1963.png",
  },
  "1964": {
    name: "tiny dinos winter 2022 #1964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1964,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1964.png",
  },
  "1965": {
    name: "tiny dinos winter 2022 #1965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1965,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1965.png",
  },
  "1966": {
    name: "tiny dinos winter 2022 #1966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1966,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1966.png",
  },
  "1967": {
    name: "tiny dinos winter 2022 #1967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1967,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1967.png",
  },
  "1968": {
    name: "tiny dinos winter 2022 #1968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1968,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1968.png",
  },
  "1969": {
    name: "tiny dinos winter 2022 #1969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1969,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1969.png",
  },
  "1970": {
    name: "tiny dinos winter 2022 #1970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1970,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1970.png",
  },
  "1971": {
    name: "tiny dinos winter 2022 #1971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1971,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1971.png",
  },
  "1972": {
    name: "tiny dinos winter 2022 #1972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1972,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1972.png",
  },
  "1973": {
    name: "tiny dinos winter 2022 #1973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1973,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1973.png",
  },
  "1974": {
    name: "tiny dinos winter 2022 #1974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1974,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1974.png",
  },
  "1975": {
    name: "tiny dinos winter 2022 #1975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1975,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1975.png",
  },
  "1976": {
    name: "tiny dinos winter 2022 #1976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1976,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1976.png",
  },
  "1977": {
    name: "tiny dinos winter 2022 #1977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1977,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1977.png",
  },
  "1978": {
    name: "tiny dinos winter 2022 #1978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1978,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1978.png",
  },
  "1979": {
    name: "tiny dinos winter 2022 #1979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1979,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1979.png",
  },
  "1980": {
    name: "tiny dinos winter 2022 #1980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1980,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1980.png",
  },
  "1981": {
    name: "tiny dinos winter 2022 #1981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1981,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1981.png",
  },
  "1982": {
    name: "tiny dinos winter 2022 #1982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1982,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1982.png",
  },
  "1983": {
    name: "tiny dinos winter 2022 #1983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1983,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1983.png",
  },
  "1984": {
    name: "tiny dinos winter 2022 #1984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1984,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1984.png",
  },
  "1985": {
    name: "tiny dinos winter 2022 #1985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1985,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1985.png",
  },
  "1986": {
    name: "tiny dinos winter 2022 #1986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1986,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1986.png",
  },
  "1987": {
    name: "tiny dinos winter 2022 #1987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1987,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1987.png",
  },
  "1988": {
    name: "tiny dinos winter 2022 #1988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1988,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1988.png",
  },
  "1989": {
    name: "tiny dinos winter 2022 #1989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1989,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1989.png",
  },
  "1990": {
    name: "tiny dinos winter 2022 #1990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1990,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1990.png",
  },
  "1991": {
    name: "tiny dinos winter 2022 #1991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1991,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1991.png",
  },
  "1992": {
    name: "tiny dinos winter 2022 #1992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1992,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1992.png",
  },
  "1993": {
    name: "tiny dinos winter 2022 #1993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1993,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1993.png",
  },
  "1994": {
    name: "tiny dinos winter 2022 #1994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1994,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1994.png",
  },
  "1995": {
    name: "tiny dinos winter 2022 #1995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1995,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1995.png",
  },
  "1996": {
    name: "tiny dinos winter 2022 #1996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1996,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1996.png",
  },
  "1997": {
    name: "tiny dinos winter 2022 #1997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1997,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1997.png",
  },
  "1998": {
    name: "tiny dinos winter 2022 #1998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1998,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1998.png",
  },
  "1999": {
    name: "tiny dinos winter 2022 #1999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 1999,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/1999.png",
  },
  "2000": {
    name: "tiny dinos winter 2022 #2000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2000,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2000.png",
  },
  "2001": {
    name: "tiny dinos winter 2022 #2001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2001,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2001.png",
  },
  "2002": {
    name: "tiny dinos winter 2022 #2002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2002,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2002.png",
  },
  "2003": {
    name: "tiny dinos winter 2022 #2003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2003,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2003.png",
  },
  "2004": {
    name: "tiny dinos winter 2022 #2004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2004,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2004.png",
  },
  "2005": {
    name: "tiny dinos winter 2022 #2005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2005,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2005.png",
  },
  "2006": {
    name: "tiny dinos winter 2022 #2006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2006,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2006.png",
  },
  "2007": {
    name: "tiny dinos winter 2022 #2007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2007,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2007.png",
  },
  "2008": {
    name: "tiny dinos winter 2022 #2008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2008,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2008.png",
  },
  "2009": {
    name: "tiny dinos winter 2022 #2009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2009,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2009.png",
  },
  "2010": {
    name: "tiny dinos winter 2022 #2010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2010,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2010.png",
  },
  "2011": {
    name: "tiny dinos winter 2022 #2011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2011,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2011.png",
  },
  "2012": {
    name: "tiny dinos winter 2022 #2012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2012,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2012.png",
  },
  "2013": {
    name: "tiny dinos winter 2022 #2013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2013,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2013.png",
  },
  "2014": {
    name: "tiny dinos winter 2022 #2014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2014,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2014.png",
  },
  "2015": {
    name: "tiny dinos winter 2022 #2015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2015,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2015.png",
  },
  "2016": {
    name: "tiny dinos winter 2022 #2016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2016,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2016.png",
  },
  "2017": {
    name: "tiny dinos winter 2022 #2017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2017,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2017.png",
  },
  "2018": {
    name: "tiny dinos winter 2022 #2018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2018,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2018.png",
  },
  "2019": {
    name: "tiny dinos winter 2022 #2019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2019,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2019.png",
  },
  "2020": {
    name: "tiny dinos winter 2022 #2020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2020,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2020.png",
  },
  "2021": {
    name: "tiny dinos winter 2022 #2021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2021,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2021.png",
  },
  "2022": {
    name: "tiny dinos winter 2022 #2022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2022,
    attributes: [
      {
        trait_type: "1/1",
        value: "friendly dino",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2022.png",
  },
  "2023": {
    name: "tiny dinos winter 2022 #2023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2023,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2023.png",
  },
  "2024": {
    name: "tiny dinos winter 2022 #2024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2024,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2024.png",
  },
  "2025": {
    name: "tiny dinos winter 2022 #2025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2025,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2025.png",
  },
  "2026": {
    name: "tiny dinos winter 2022 #2026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2026,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2026.png",
  },
  "2027": {
    name: "tiny dinos winter 2022 #2027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2027,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2027.png",
  },
  "2028": {
    name: "tiny dinos winter 2022 #2028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2028,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2028.png",
  },
  "2029": {
    name: "tiny dinos winter 2022 #2029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2029,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2029.png",
  },
  "2030": {
    name: "tiny dinos winter 2022 #2030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2030,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2030.png",
  },
  "2031": {
    name: "tiny dinos winter 2022 #2031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2031,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2031.png",
  },
  "2032": {
    name: "tiny dinos winter 2022 #2032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2032,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2032.png",
  },
  "2033": {
    name: "tiny dinos winter 2022 #2033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2033,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2033.png",
  },
  "2034": {
    name: "tiny dinos winter 2022 #2034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2034,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2034.png",
  },
  "2035": {
    name: "tiny dinos winter 2022 #2035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2035,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2035.png",
  },
  "2036": {
    name: "tiny dinos winter 2022 #2036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2036,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2036.png",
  },
  "2037": {
    name: "tiny dinos winter 2022 #2037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2037,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2037.png",
  },
  "2038": {
    name: "tiny dinos winter 2022 #2038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2038,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2038.png",
  },
  "2039": {
    name: "tiny dinos winter 2022 #2039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2039,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2039.png",
  },
  "2040": {
    name: "tiny dinos winter 2022 #2040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2040,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2040.png",
  },
  "2041": {
    name: "tiny dinos winter 2022 #2041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2041,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2041.png",
  },
  "2042": {
    name: "tiny dinos winter 2022 #2042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2042,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2042.png",
  },
  "2043": {
    name: "tiny dinos winter 2022 #2043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2043,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2043.png",
  },
  "2044": {
    name: "tiny dinos winter 2022 #2044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2044,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2044.png",
  },
  "2045": {
    name: "tiny dinos winter 2022 #2045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2045,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2045.png",
  },
  "2046": {
    name: "tiny dinos winter 2022 #2046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2046,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2046.png",
  },
  "2047": {
    name: "tiny dinos winter 2022 #2047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2047,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2047.png",
  },
  "2048": {
    name: "tiny dinos winter 2022 #2048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2048,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2048.png",
  },
  "2049": {
    name: "tiny dinos winter 2022 #2049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2049,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2049.png",
  },
  "2050": {
    name: "tiny dinos winter 2022 #2050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2050,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2050.png",
  },
  "2051": {
    name: "tiny dinos winter 2022 #2051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2051,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2051.png",
  },
  "2052": {
    name: "tiny dinos winter 2022 #2052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2052,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2052.png",
  },
  "2053": {
    name: "tiny dinos winter 2022 #2053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2053,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2053.png",
  },
  "2054": {
    name: "tiny dinos winter 2022 #2054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2054,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2054.png",
  },
  "2055": {
    name: "tiny dinos winter 2022 #2055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2055,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2055.png",
  },
  "2056": {
    name: "tiny dinos winter 2022 #2056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2056,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2056.png",
  },
  "2057": {
    name: "tiny dinos winter 2022 #2057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2057,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2057.png",
  },
  "2058": {
    name: "tiny dinos winter 2022 #2058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2058,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2058.png",
  },
  "2059": {
    name: "tiny dinos winter 2022 #2059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2059,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2059.png",
  },
  "2060": {
    name: "tiny dinos winter 2022 #2060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2060,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2060.png",
  },
  "2061": {
    name: "tiny dinos winter 2022 #2061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2061,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2061.png",
  },
  "2062": {
    name: "tiny dinos winter 2022 #2062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2062,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2062.png",
  },
  "2063": {
    name: "tiny dinos winter 2022 #2063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2063,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2063.png",
  },
  "2064": {
    name: "tiny dinos winter 2022 #2064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2064,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2064.png",
  },
  "2065": {
    name: "tiny dinos winter 2022 #2065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2065,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2065.png",
  },
  "2066": {
    name: "tiny dinos winter 2022 #2066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2066,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2066.png",
  },
  "2067": {
    name: "tiny dinos winter 2022 #2067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2067,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2067.png",
  },
  "2068": {
    name: "tiny dinos winter 2022 #2068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2068,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2068.png",
  },
  "2069": {
    name: "tiny dinos winter 2022 #2069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2069,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2069.png",
  },
  "2070": {
    name: "tiny dinos winter 2022 #2070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2070,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2070.png",
  },
  "2071": {
    name: "tiny dinos winter 2022 #2071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2071,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2071.png",
  },
  "2072": {
    name: "tiny dinos winter 2022 #2072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2072,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2072.png",
  },
  "2073": {
    name: "tiny dinos winter 2022 #2073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2073,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2073.png",
  },
  "2074": {
    name: "tiny dinos winter 2022 #2074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2074,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2074.png",
  },
  "2075": {
    name: "tiny dinos winter 2022 #2075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2075,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2075.png",
  },
  "2076": {
    name: "tiny dinos winter 2022 #2076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2076,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2076.png",
  },
  "2077": {
    name: "tiny dinos winter 2022 #2077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2077,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2077.png",
  },
  "2078": {
    name: "tiny dinos winter 2022 #2078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2078,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2078.png",
  },
  "2079": {
    name: "tiny dinos winter 2022 #2079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2079,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2079.png",
  },
  "2080": {
    name: "tiny dinos winter 2022 #2080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2080,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2080.png",
  },
  "2081": {
    name: "tiny dinos winter 2022 #2081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2081,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2081.png",
  },
  "2082": {
    name: "tiny dinos winter 2022 #2082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2082,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2082.png",
  },
  "2083": {
    name: "tiny dinos winter 2022 #2083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2083,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2083.png",
  },
  "2084": {
    name: "tiny dinos winter 2022 #2084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2084,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2084.png",
  },
  "2085": {
    name: "tiny dinos winter 2022 #2085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2085,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2085.png",
  },
  "2086": {
    name: "tiny dinos winter 2022 #2086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2086,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2086.png",
  },
  "2087": {
    name: "tiny dinos winter 2022 #2087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2087,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2087.png",
  },
  "2088": {
    name: "tiny dinos winter 2022 #2088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2088,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2088.png",
  },
  "2089": {
    name: "tiny dinos winter 2022 #2089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2089,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2089.png",
  },
  "2090": {
    name: "tiny dinos winter 2022 #2090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2090,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2090.png",
  },
  "2091": {
    name: "tiny dinos winter 2022 #2091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2091,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2091.png",
  },
  "2092": {
    name: "tiny dinos winter 2022 #2092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2092,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2092.png",
  },
  "2093": {
    name: "tiny dinos winter 2022 #2093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2093,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2093.png",
  },
  "2094": {
    name: "tiny dinos winter 2022 #2094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2094,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2094.png",
  },
  "2095": {
    name: "tiny dinos winter 2022 #2095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2095,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2095.png",
  },
  "2096": {
    name: "tiny dinos winter 2022 #2096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2096,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2096.png",
  },
  "2097": {
    name: "tiny dinos winter 2022 #2097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2097,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2097.png",
  },
  "2098": {
    name: "tiny dinos winter 2022 #2098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2098,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2098.png",
  },
  "2099": {
    name: "tiny dinos winter 2022 #2099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2099,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2099.png",
  },
  "2100": {
    name: "tiny dinos winter 2022 #2100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2100,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2100.png",
  },
  "2101": {
    name: "tiny dinos winter 2022 #2101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2101,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2101.png",
  },
  "2102": {
    name: "tiny dinos winter 2022 #2102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2102,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2102.png",
  },
  "2103": {
    name: "tiny dinos winter 2022 #2103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2103,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2103.png",
  },
  "2104": {
    name: "tiny dinos winter 2022 #2104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2104,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2104.png",
  },
  "2105": {
    name: "tiny dinos winter 2022 #2105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2105,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2105.png",
  },
  "2106": {
    name: "tiny dinos winter 2022 #2106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2106,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2106.png",
  },
  "2107": {
    name: "tiny dinos winter 2022 #2107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2107,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2107.png",
  },
  "2108": {
    name: "tiny dinos winter 2022 #2108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2108,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2108.png",
  },
  "2109": {
    name: "tiny dinos winter 2022 #2109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2109,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2109.png",
  },
  "2110": {
    name: "tiny dinos winter 2022 #2110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2110,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2110.png",
  },
  "2111": {
    name: "tiny dinos winter 2022 #2111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2111,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2111.png",
  },
  "2112": {
    name: "tiny dinos winter 2022 #2112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2112,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2112.png",
  },
  "2113": {
    name: "tiny dinos winter 2022 #2113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2113,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2113.png",
  },
  "2114": {
    name: "tiny dinos winter 2022 #2114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2114,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2114.png",
  },
  "2115": {
    name: "tiny dinos winter 2022 #2115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2115,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2115.png",
  },
  "2116": {
    name: "tiny dinos winter 2022 #2116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2116,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2116.png",
  },
  "2117": {
    name: "tiny dinos winter 2022 #2117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2117,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2117.png",
  },
  "2118": {
    name: "tiny dinos winter 2022 #2118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2118,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2118.png",
  },
  "2119": {
    name: "tiny dinos winter 2022 #2119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2119,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2119.png",
  },
  "2120": {
    name: "tiny dinos winter 2022 #2120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2120,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2120.png",
  },
  "2121": {
    name: "tiny dinos winter 2022 #2121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2121,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2121.png",
  },
  "2122": {
    name: "tiny dinos winter 2022 #2122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2122,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2122.png",
  },
  "2123": {
    name: "tiny dinos winter 2022 #2123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2123,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2123.png",
  },
  "2124": {
    name: "tiny dinos winter 2022 #2124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2124,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2124.png",
  },
  "2125": {
    name: "tiny dinos winter 2022 #2125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2125,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2125.png",
  },
  "2126": {
    name: "tiny dinos winter 2022 #2126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2126,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2126.png",
  },
  "2127": {
    name: "tiny dinos winter 2022 #2127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2127,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2127.png",
  },
  "2128": {
    name: "tiny dinos winter 2022 #2128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2128,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2128.png",
  },
  "2129": {
    name: "tiny dinos winter 2022 #2129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2129,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2129.png",
  },
  "2130": {
    name: "tiny dinos winter 2022 #2130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2130,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2130.png",
  },
  "2131": {
    name: "tiny dinos winter 2022 #2131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2131,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2131.png",
  },
  "2132": {
    name: "tiny dinos winter 2022 #2132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2132,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2132.png",
  },
  "2133": {
    name: "tiny dinos winter 2022 #2133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2133,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2133.png",
  },
  "2134": {
    name: "tiny dinos winter 2022 #2134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2134,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2134.png",
  },
  "2135": {
    name: "tiny dinos winter 2022 #2135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2135,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2135.png",
  },
  "2136": {
    name: "tiny dinos winter 2022 #2136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2136,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2136.png",
  },
  "2137": {
    name: "tiny dinos winter 2022 #2137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2137,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2137.png",
  },
  "2138": {
    name: "tiny dinos winter 2022 #2138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2138,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2138.png",
  },
  "2139": {
    name: "tiny dinos winter 2022 #2139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2139,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2139.png",
  },
  "2140": {
    name: "tiny dinos winter 2022 #2140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2140,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2140.png",
  },
  "2141": {
    name: "tiny dinos winter 2022 #2141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2141,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2141.png",
  },
  "2142": {
    name: "tiny dinos winter 2022 #2142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2142,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2142.png",
  },
  "2143": {
    name: "tiny dinos winter 2022 #2143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2143,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2143.png",
  },
  "2144": {
    name: "tiny dinos winter 2022 #2144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2144,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2144.png",
  },
  "2145": {
    name: "tiny dinos winter 2022 #2145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2145,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2145.png",
  },
  "2146": {
    name: "tiny dinos winter 2022 #2146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2146,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2146.png",
  },
  "2147": {
    name: "tiny dinos winter 2022 #2147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2147,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2147.png",
  },
  "2148": {
    name: "tiny dinos winter 2022 #2148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2148,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2148.png",
  },
  "2149": {
    name: "tiny dinos winter 2022 #2149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2149,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2149.png",
  },
  "2150": {
    name: "tiny dinos winter 2022 #2150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2150,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2150.png",
  },
  "2151": {
    name: "tiny dinos winter 2022 #2151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2151,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2151.png",
  },
  "2152": {
    name: "tiny dinos winter 2022 #2152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2152,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2152.png",
  },
  "2153": {
    name: "tiny dinos winter 2022 #2153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2153,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2153.png",
  },
  "2154": {
    name: "tiny dinos winter 2022 #2154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2154,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2154.png",
  },
  "2155": {
    name: "tiny dinos winter 2022 #2155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2155,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2155.png",
  },
  "2156": {
    name: "tiny dinos winter 2022 #2156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2156,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2156.png",
  },
  "2157": {
    name: "tiny dinos winter 2022 #2157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2157,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2157.png",
  },
  "2158": {
    name: "tiny dinos winter 2022 #2158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2158,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2158.png",
  },
  "2159": {
    name: "tiny dinos winter 2022 #2159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2159,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2159.png",
  },
  "2160": {
    name: "tiny dinos winter 2022 #2160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2160,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2160.png",
  },
  "2161": {
    name: "tiny dinos winter 2022 #2161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2161,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2161.png",
  },
  "2162": {
    name: "tiny dinos winter 2022 #2162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2162,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2162.png",
  },
  "2163": {
    name: "tiny dinos winter 2022 #2163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2163,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2163.png",
  },
  "2164": {
    name: "tiny dinos winter 2022 #2164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2164,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2164.png",
  },
  "2165": {
    name: "tiny dinos winter 2022 #2165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2165,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2165.png",
  },
  "2166": {
    name: "tiny dinos winter 2022 #2166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2166,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2166.png",
  },
  "2167": {
    name: "tiny dinos winter 2022 #2167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2167,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2167.png",
  },
  "2168": {
    name: "tiny dinos winter 2022 #2168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2168,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2168.png",
  },
  "2169": {
    name: "tiny dinos winter 2022 #2169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2169,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2169.png",
  },
  "2170": {
    name: "tiny dinos winter 2022 #2170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2170,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2170.png",
  },
  "2171": {
    name: "tiny dinos winter 2022 #2171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2171,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2171.png",
  },
  "2172": {
    name: "tiny dinos winter 2022 #2172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2172,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2172.png",
  },
  "2173": {
    name: "tiny dinos winter 2022 #2173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2173,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2173.png",
  },
  "2174": {
    name: "tiny dinos winter 2022 #2174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2174,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2174.png",
  },
  "2175": {
    name: "tiny dinos winter 2022 #2175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2175,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2175.png",
  },
  "2176": {
    name: "tiny dinos winter 2022 #2176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2176,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2176.png",
  },
  "2177": {
    name: "tiny dinos winter 2022 #2177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2177,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2177.png",
  },
  "2178": {
    name: "tiny dinos winter 2022 #2178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2178,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2178.png",
  },
  "2179": {
    name: "tiny dinos winter 2022 #2179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2179,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2179.png",
  },
  "2180": {
    name: "tiny dinos winter 2022 #2180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2180,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2180.png",
  },
  "2181": {
    name: "tiny dinos winter 2022 #2181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2181,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2181.png",
  },
  "2182": {
    name: "tiny dinos winter 2022 #2182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2182,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2182.png",
  },
  "2183": {
    name: "tiny dinos winter 2022 #2183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2183,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2183.png",
  },
  "2184": {
    name: "tiny dinos winter 2022 #2184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2184,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2184.png",
  },
  "2185": {
    name: "tiny dinos winter 2022 #2185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2185,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2185.png",
  },
  "2186": {
    name: "tiny dinos winter 2022 #2186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2186,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2186.png",
  },
  "2187": {
    name: "tiny dinos winter 2022 #2187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2187,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2187.png",
  },
  "2188": {
    name: "tiny dinos winter 2022 #2188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2188,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2188.png",
  },
  "2189": {
    name: "tiny dinos winter 2022 #2189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2189,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2189.png",
  },
  "2190": {
    name: "tiny dinos winter 2022 #2190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2190,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2190.png",
  },
  "2191": {
    name: "tiny dinos winter 2022 #2191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2191,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2191.png",
  },
  "2192": {
    name: "tiny dinos winter 2022 #2192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2192,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2192.png",
  },
  "2193": {
    name: "tiny dinos winter 2022 #2193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2193,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2193.png",
  },
  "2194": {
    name: "tiny dinos winter 2022 #2194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2194,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2194.png",
  },
  "2195": {
    name: "tiny dinos winter 2022 #2195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2195,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2195.png",
  },
  "2196": {
    name: "tiny dinos winter 2022 #2196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2196,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2196.png",
  },
  "2197": {
    name: "tiny dinos winter 2022 #2197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2197,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2197.png",
  },
  "2198": {
    name: "tiny dinos winter 2022 #2198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2198,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2198.png",
  },
  "2199": {
    name: "tiny dinos winter 2022 #2199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2199,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2199.png",
  },
  "2200": {
    name: "tiny dinos winter 2022 #2200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2200,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2200.png",
  },
  "2201": {
    name: "tiny dinos winter 2022 #2201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2201,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2201.png",
  },
  "2202": {
    name: "tiny dinos winter 2022 #2202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2202,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2202.png",
  },
  "2203": {
    name: "tiny dinos winter 2022 #2203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2203,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2203.png",
  },
  "2204": {
    name: "tiny dinos winter 2022 #2204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2204,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2204.png",
  },
  "2205": {
    name: "tiny dinos winter 2022 #2205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2205,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2205.png",
  },
  "2206": {
    name: "tiny dinos winter 2022 #2206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2206,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2206.png",
  },
  "2207": {
    name: "tiny dinos winter 2022 #2207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2207,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2207.png",
  },
  "2208": {
    name: "tiny dinos winter 2022 #2208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2208,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2208.png",
  },
  "2209": {
    name: "tiny dinos winter 2022 #2209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2209,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2209.png",
  },
  "2210": {
    name: "tiny dinos winter 2022 #2210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2210,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2210.png",
  },
  "2211": {
    name: "tiny dinos winter 2022 #2211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2211,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2211.png",
  },
  "2212": {
    name: "tiny dinos winter 2022 #2212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2212,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2212.png",
  },
  "2213": {
    name: "tiny dinos winter 2022 #2213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2213,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2213.png",
  },
  "2214": {
    name: "tiny dinos winter 2022 #2214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2214,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2214.png",
  },
  "2215": {
    name: "tiny dinos winter 2022 #2215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2215,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2215.png",
  },
  "2216": {
    name: "tiny dinos winter 2022 #2216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2216,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2216.png",
  },
  "2217": {
    name: "tiny dinos winter 2022 #2217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2217,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2217.png",
  },
  "2218": {
    name: "tiny dinos winter 2022 #2218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2218,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2218.png",
  },
  "2219": {
    name: "tiny dinos winter 2022 #2219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2219,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2219.png",
  },
  "2220": {
    name: "tiny dinos winter 2022 #2220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2220,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2220.png",
  },
  "2221": {
    name: "tiny dinos winter 2022 #2221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2221,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2221.png",
  },
  "2222": {
    name: "tiny dinos winter 2022 #2222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2222,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2222.png",
  },
  "2223": {
    name: "tiny dinos winter 2022 #2223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2223,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2223.png",
  },
  "2224": {
    name: "tiny dinos winter 2022 #2224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2224,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2224.png",
  },
  "2225": {
    name: "tiny dinos winter 2022 #2225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2225,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2225.png",
  },
  "2226": {
    name: "tiny dinos winter 2022 #2226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2226,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2226.png",
  },
  "2227": {
    name: "tiny dinos winter 2022 #2227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2227,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2227.png",
  },
  "2228": {
    name: "tiny dinos winter 2022 #2228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2228,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2228.png",
  },
  "2229": {
    name: "tiny dinos winter 2022 #2229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2229,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2229.png",
  },
  "2230": {
    name: "tiny dinos winter 2022 #2230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2230,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2230.png",
  },
  "2231": {
    name: "tiny dinos winter 2022 #2231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2231,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2231.png",
  },
  "2232": {
    name: "tiny dinos winter 2022 #2232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2232,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2232.png",
  },
  "2233": {
    name: "tiny dinos winter 2022 #2233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2233,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2233.png",
  },
  "2234": {
    name: "tiny dinos winter 2022 #2234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2234,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2234.png",
  },
  "2235": {
    name: "tiny dinos winter 2022 #2235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2235,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2235.png",
  },
  "2236": {
    name: "tiny dinos winter 2022 #2236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2236,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2236.png",
  },
  "2237": {
    name: "tiny dinos winter 2022 #2237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2237,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2237.png",
  },
  "2238": {
    name: "tiny dinos winter 2022 #2238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2238,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2238.png",
  },
  "2239": {
    name: "tiny dinos winter 2022 #2239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2239,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2239.png",
  },
  "2240": {
    name: "tiny dinos winter 2022 #2240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2240,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2240.png",
  },
  "2241": {
    name: "tiny dinos winter 2022 #2241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2241,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2241.png",
  },
  "2242": {
    name: "tiny dinos winter 2022 #2242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2242,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2242.png",
  },
  "2243": {
    name: "tiny dinos winter 2022 #2243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2243,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2243.png",
  },
  "2244": {
    name: "tiny dinos winter 2022 #2244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2244,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2244.png",
  },
  "2245": {
    name: "tiny dinos winter 2022 #2245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2245,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2245.png",
  },
  "2246": {
    name: "tiny dinos winter 2022 #2246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2246,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2246.png",
  },
  "2247": {
    name: "tiny dinos winter 2022 #2247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2247,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2247.png",
  },
  "2248": {
    name: "tiny dinos winter 2022 #2248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2248,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2248.png",
  },
  "2249": {
    name: "tiny dinos winter 2022 #2249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2249,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2249.png",
  },
  "2250": {
    name: "tiny dinos winter 2022 #2250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2250,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2250.png",
  },
  "2251": {
    name: "tiny dinos winter 2022 #2251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2251,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2251.png",
  },
  "2252": {
    name: "tiny dinos winter 2022 #2252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2252,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2252.png",
  },
  "2253": {
    name: "tiny dinos winter 2022 #2253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2253,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2253.png",
  },
  "2254": {
    name: "tiny dinos winter 2022 #2254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2254,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2254.png",
  },
  "2255": {
    name: "tiny dinos winter 2022 #2255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2255,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2255.png",
  },
  "2256": {
    name: "tiny dinos winter 2022 #2256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2256,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2256.png",
  },
  "2257": {
    name: "tiny dinos winter 2022 #2257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2257,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2257.png",
  },
  "2258": {
    name: "tiny dinos winter 2022 #2258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2258,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2258.png",
  },
  "2259": {
    name: "tiny dinos winter 2022 #2259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2259,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2259.png",
  },
  "2260": {
    name: "tiny dinos winter 2022 #2260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2260,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2260.png",
  },
  "2261": {
    name: "tiny dinos winter 2022 #2261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2261,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2261.png",
  },
  "2262": {
    name: "tiny dinos winter 2022 #2262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2262,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2262.png",
  },
  "2263": {
    name: "tiny dinos winter 2022 #2263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2263,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2263.png",
  },
  "2264": {
    name: "tiny dinos winter 2022 #2264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2264,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2264.png",
  },
  "2265": {
    name: "tiny dinos winter 2022 #2265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2265,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2265.png",
  },
  "2266": {
    name: "tiny dinos winter 2022 #2266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2266,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2266.png",
  },
  "2267": {
    name: "tiny dinos winter 2022 #2267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2267,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2267.png",
  },
  "2268": {
    name: "tiny dinos winter 2022 #2268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2268,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2268.png",
  },
  "2269": {
    name: "tiny dinos winter 2022 #2269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2269,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2269.png",
  },
  "2270": {
    name: "tiny dinos winter 2022 #2270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2270,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2270.png",
  },
  "2271": {
    name: "tiny dinos winter 2022 #2271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2271,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2271.png",
  },
  "2272": {
    name: "tiny dinos winter 2022 #2272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2272,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2272.png",
  },
  "2273": {
    name: "tiny dinos winter 2022 #2273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2273,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2273.png",
  },
  "2274": {
    name: "tiny dinos winter 2022 #2274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2274,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2274.png",
  },
  "2275": {
    name: "tiny dinos winter 2022 #2275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2275,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2275.png",
  },
  "2276": {
    name: "tiny dinos winter 2022 #2276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2276,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2276.png",
  },
  "2277": {
    name: "tiny dinos winter 2022 #2277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2277,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2277.png",
  },
  "2278": {
    name: "tiny dinos winter 2022 #2278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2278,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2278.png",
  },
  "2279": {
    name: "tiny dinos winter 2022 #2279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2279,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2279.png",
  },
  "2280": {
    name: "tiny dinos winter 2022 #2280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2280,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2280.png",
  },
  "2281": {
    name: "tiny dinos winter 2022 #2281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2281,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2281.png",
  },
  "2282": {
    name: "tiny dinos winter 2022 #2282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2282,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2282.png",
  },
  "2283": {
    name: "tiny dinos winter 2022 #2283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2283,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2283.png",
  },
  "2284": {
    name: "tiny dinos winter 2022 #2284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2284,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2284.png",
  },
  "2285": {
    name: "tiny dinos winter 2022 #2285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2285,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2285.png",
  },
  "2286": {
    name: "tiny dinos winter 2022 #2286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2286,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2286.png",
  },
  "2287": {
    name: "tiny dinos winter 2022 #2287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2287,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2287.png",
  },
  "2288": {
    name: "tiny dinos winter 2022 #2288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2288,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2288.png",
  },
  "2289": {
    name: "tiny dinos winter 2022 #2289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2289,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2289.png",
  },
  "2290": {
    name: "tiny dinos winter 2022 #2290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2290,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2290.png",
  },
  "2291": {
    name: "tiny dinos winter 2022 #2291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2291,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2291.png",
  },
  "2292": {
    name: "tiny dinos winter 2022 #2292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2292,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2292.png",
  },
  "2293": {
    name: "tiny dinos winter 2022 #2293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2293,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2293.png",
  },
  "2294": {
    name: "tiny dinos winter 2022 #2294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2294,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2294.png",
  },
  "2295": {
    name: "tiny dinos winter 2022 #2295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2295,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2295.png",
  },
  "2296": {
    name: "tiny dinos winter 2022 #2296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2296,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2296.png",
  },
  "2297": {
    name: "tiny dinos winter 2022 #2297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2297,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2297.png",
  },
  "2298": {
    name: "tiny dinos winter 2022 #2298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2298,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2298.png",
  },
  "2299": {
    name: "tiny dinos winter 2022 #2299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2299,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2299.png",
  },
  "2300": {
    name: "tiny dinos winter 2022 #2300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2300,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2300.png",
  },
  "2301": {
    name: "tiny dinos winter 2022 #2301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2301,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2301.png",
  },
  "2302": {
    name: "tiny dinos winter 2022 #2302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2302,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2302.png",
  },
  "2303": {
    name: "tiny dinos winter 2022 #2303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2303,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2303.png",
  },
  "2304": {
    name: "tiny dinos winter 2022 #2304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2304,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2304.png",
  },
  "2305": {
    name: "tiny dinos winter 2022 #2305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2305,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2305.png",
  },
  "2306": {
    name: "tiny dinos winter 2022 #2306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2306,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2306.png",
  },
  "2307": {
    name: "tiny dinos winter 2022 #2307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2307,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2307.png",
  },
  "2308": {
    name: "tiny dinos winter 2022 #2308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2308,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2308.png",
  },
  "2309": {
    name: "tiny dinos winter 2022 #2309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2309,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2309.png",
  },
  "2310": {
    name: "tiny dinos winter 2022 #2310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2310,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2310.png",
  },
  "2311": {
    name: "tiny dinos winter 2022 #2311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2311,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2311.png",
  },
  "2312": {
    name: "tiny dinos winter 2022 #2312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2312,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2312.png",
  },
  "2313": {
    name: "tiny dinos winter 2022 #2313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2313,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2313.png",
  },
  "2314": {
    name: "tiny dinos winter 2022 #2314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2314,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2314.png",
  },
  "2315": {
    name: "tiny dinos winter 2022 #2315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2315,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2315.png",
  },
  "2316": {
    name: "tiny dinos winter 2022 #2316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2316,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2316.png",
  },
  "2317": {
    name: "tiny dinos winter 2022 #2317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2317,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2317.png",
  },
  "2318": {
    name: "tiny dinos winter 2022 #2318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2318,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2318.png",
  },
  "2319": {
    name: "tiny dinos winter 2022 #2319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2319,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2319.png",
  },
  "2320": {
    name: "tiny dinos winter 2022 #2320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2320,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2320.png",
  },
  "2321": {
    name: "tiny dinos winter 2022 #2321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2321,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2321.png",
  },
  "2322": {
    name: "tiny dinos winter 2022 #2322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2322,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2322.png",
  },
  "2323": {
    name: "tiny dinos winter 2022 #2323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2323,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2323.png",
  },
  "2324": {
    name: "tiny dinos winter 2022 #2324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2324,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2324.png",
  },
  "2325": {
    name: "tiny dinos winter 2022 #2325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2325,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2325.png",
  },
  "2326": {
    name: "tiny dinos winter 2022 #2326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2326,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2326.png",
  },
  "2327": {
    name: "tiny dinos winter 2022 #2327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2327,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2327.png",
  },
  "2328": {
    name: "tiny dinos winter 2022 #2328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2328,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2328.png",
  },
  "2329": {
    name: "tiny dinos winter 2022 #2329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2329,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2329.png",
  },
  "2330": {
    name: "tiny dinos winter 2022 #2330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2330,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2330.png",
  },
  "2331": {
    name: "tiny dinos winter 2022 #2331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2331,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2331.png",
  },
  "2332": {
    name: "tiny dinos winter 2022 #2332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2332,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2332.png",
  },
  "2333": {
    name: "tiny dinos winter 2022 #2333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2333,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2333.png",
  },
  "2334": {
    name: "tiny dinos winter 2022 #2334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2334,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2334.png",
  },
  "2335": {
    name: "tiny dinos winter 2022 #2335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2335,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2335.png",
  },
  "2336": {
    name: "tiny dinos winter 2022 #2336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2336,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2336.png",
  },
  "2337": {
    name: "tiny dinos winter 2022 #2337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2337,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2337.png",
  },
  "2338": {
    name: "tiny dinos winter 2022 #2338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2338,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2338.png",
  },
  "2339": {
    name: "tiny dinos winter 2022 #2339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2339,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2339.png",
  },
  "2340": {
    name: "tiny dinos winter 2022 #2340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2340,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2340.png",
  },
  "2341": {
    name: "tiny dinos winter 2022 #2341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2341,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2341.png",
  },
  "2342": {
    name: "tiny dinos winter 2022 #2342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2342,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2342.png",
  },
  "2343": {
    name: "tiny dinos winter 2022 #2343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2343,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2343.png",
  },
  "2344": {
    name: "tiny dinos winter 2022 #2344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2344,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2344.png",
  },
  "2345": {
    name: "tiny dinos winter 2022 #2345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2345,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2345.png",
  },
  "2346": {
    name: "tiny dinos winter 2022 #2346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2346,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2346.png",
  },
  "2347": {
    name: "tiny dinos winter 2022 #2347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2347,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2347.png",
  },
  "2348": {
    name: "tiny dinos winter 2022 #2348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2348,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2348.png",
  },
  "2349": {
    name: "tiny dinos winter 2022 #2349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2349,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2349.png",
  },
  "2350": {
    name: "tiny dinos winter 2022 #2350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2350,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2350.png",
  },
  "2351": {
    name: "tiny dinos winter 2022 #2351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2351,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2351.png",
  },
  "2352": {
    name: "tiny dinos winter 2022 #2352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2352,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2352.png",
  },
  "2353": {
    name: "tiny dinos winter 2022 #2353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2353,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2353.png",
  },
  "2354": {
    name: "tiny dinos winter 2022 #2354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2354,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2354.png",
  },
  "2355": {
    name: "tiny dinos winter 2022 #2355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2355,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2355.png",
  },
  "2356": {
    name: "tiny dinos winter 2022 #2356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2356,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2356.png",
  },
  "2357": {
    name: "tiny dinos winter 2022 #2357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2357,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2357.png",
  },
  "2358": {
    name: "tiny dinos winter 2022 #2358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2358,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2358.png",
  },
  "2359": {
    name: "tiny dinos winter 2022 #2359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2359,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2359.png",
  },
  "2360": {
    name: "tiny dinos winter 2022 #2360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2360,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2360.png",
  },
  "2361": {
    name: "tiny dinos winter 2022 #2361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2361,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2361.png",
  },
  "2362": {
    name: "tiny dinos winter 2022 #2362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2362,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2362.png",
  },
  "2363": {
    name: "tiny dinos winter 2022 #2363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2363,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2363.png",
  },
  "2364": {
    name: "tiny dinos winter 2022 #2364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2364,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2364.png",
  },
  "2365": {
    name: "tiny dinos winter 2022 #2365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2365,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2365.png",
  },
  "2366": {
    name: "tiny dinos winter 2022 #2366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2366,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2366.png",
  },
  "2367": {
    name: "tiny dinos winter 2022 #2367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2367,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2367.png",
  },
  "2368": {
    name: "tiny dinos winter 2022 #2368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2368,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2368.png",
  },
  "2369": {
    name: "tiny dinos winter 2022 #2369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2369,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2369.png",
  },
  "2370": {
    name: "tiny dinos winter 2022 #2370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2370,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2370.png",
  },
  "2371": {
    name: "tiny dinos winter 2022 #2371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2371,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2371.png",
  },
  "2372": {
    name: "tiny dinos winter 2022 #2372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2372,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2372.png",
  },
  "2373": {
    name: "tiny dinos winter 2022 #2373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2373,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2373.png",
  },
  "2374": {
    name: "tiny dinos winter 2022 #2374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2374,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2374.png",
  },
  "2375": {
    name: "tiny dinos winter 2022 #2375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2375,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2375.png",
  },
  "2376": {
    name: "tiny dinos winter 2022 #2376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2376,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2376.png",
  },
  "2377": {
    name: "tiny dinos winter 2022 #2377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2377,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2377.png",
  },
  "2378": {
    name: "tiny dinos winter 2022 #2378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2378,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2378.png",
  },
  "2379": {
    name: "tiny dinos winter 2022 #2379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2379,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2379.png",
  },
  "2380": {
    name: "tiny dinos winter 2022 #2380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2380,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2380.png",
  },
  "2381": {
    name: "tiny dinos winter 2022 #2381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2381,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2381.png",
  },
  "2382": {
    name: "tiny dinos winter 2022 #2382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2382,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2382.png",
  },
  "2383": {
    name: "tiny dinos winter 2022 #2383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2383,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2383.png",
  },
  "2384": {
    name: "tiny dinos winter 2022 #2384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2384,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2384.png",
  },
  "2385": {
    name: "tiny dinos winter 2022 #2385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2385,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2385.png",
  },
  "2386": {
    name: "tiny dinos winter 2022 #2386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2386,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2386.png",
  },
  "2387": {
    name: "tiny dinos winter 2022 #2387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2387,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2387.png",
  },
  "2388": {
    name: "tiny dinos winter 2022 #2388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2388,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2388.png",
  },
  "2389": {
    name: "tiny dinos winter 2022 #2389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2389,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2389.png",
  },
  "2390": {
    name: "tiny dinos winter 2022 #2390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2390,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2390.png",
  },
  "2391": {
    name: "tiny dinos winter 2022 #2391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2391,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2391.png",
  },
  "2392": {
    name: "tiny dinos winter 2022 #2392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2392,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2392.png",
  },
  "2393": {
    name: "tiny dinos winter 2022 #2393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2393,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2393.png",
  },
  "2394": {
    name: "tiny dinos winter 2022 #2394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2394,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2394.png",
  },
  "2395": {
    name: "tiny dinos winter 2022 #2395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2395,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2395.png",
  },
  "2396": {
    name: "tiny dinos winter 2022 #2396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2396,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2396.png",
  },
  "2397": {
    name: "tiny dinos winter 2022 #2397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2397,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2397.png",
  },
  "2398": {
    name: "tiny dinos winter 2022 #2398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2398,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2398.png",
  },
  "2399": {
    name: "tiny dinos winter 2022 #2399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2399,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2399.png",
  },
  "2400": {
    name: "tiny dinos winter 2022 #2400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2400,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2400.png",
  },
  "2401": {
    name: "tiny dinos winter 2022 #2401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2401,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2401.png",
  },
  "2402": {
    name: "tiny dinos winter 2022 #2402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2402,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2402.png",
  },
  "2403": {
    name: "tiny dinos winter 2022 #2403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2403,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2403.png",
  },
  "2404": {
    name: "tiny dinos winter 2022 #2404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2404,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2404.png",
  },
  "2405": {
    name: "tiny dinos winter 2022 #2405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2405,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2405.png",
  },
  "2406": {
    name: "tiny dinos winter 2022 #2406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2406,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2406.png",
  },
  "2407": {
    name: "tiny dinos winter 2022 #2407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2407,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2407.png",
  },
  "2408": {
    name: "tiny dinos winter 2022 #2408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2408,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2408.png",
  },
  "2409": {
    name: "tiny dinos winter 2022 #2409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2409,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2409.png",
  },
  "2410": {
    name: "tiny dinos winter 2022 #2410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2410,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2410.png",
  },
  "2411": {
    name: "tiny dinos winter 2022 #2411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2411,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2411.png",
  },
  "2412": {
    name: "tiny dinos winter 2022 #2412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2412,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2412.png",
  },
  "2413": {
    name: "tiny dinos winter 2022 #2413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2413,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2413.png",
  },
  "2414": {
    name: "tiny dinos winter 2022 #2414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2414,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2414.png",
  },
  "2415": {
    name: "tiny dinos winter 2022 #2415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2415,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2415.png",
  },
  "2416": {
    name: "tiny dinos winter 2022 #2416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2416,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2416.png",
  },
  "2417": {
    name: "tiny dinos winter 2022 #2417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2417,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2417.png",
  },
  "2418": {
    name: "tiny dinos winter 2022 #2418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2418,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2418.png",
  },
  "2419": {
    name: "tiny dinos winter 2022 #2419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2419,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2419.png",
  },
  "2420": {
    name: "tiny dinos winter 2022 #2420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2420,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2420.png",
  },
  "2421": {
    name: "tiny dinos winter 2022 #2421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2421,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2421.png",
  },
  "2422": {
    name: "tiny dinos winter 2022 #2422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2422,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2422.png",
  },
  "2423": {
    name: "tiny dinos winter 2022 #2423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2423,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2423.png",
  },
  "2424": {
    name: "tiny dinos winter 2022 #2424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2424,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2424.png",
  },
  "2425": {
    name: "tiny dinos winter 2022 #2425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2425,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2425.png",
  },
  "2426": {
    name: "tiny dinos winter 2022 #2426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2426,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2426.png",
  },
  "2427": {
    name: "tiny dinos winter 2022 #2427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2427,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2427.png",
  },
  "2428": {
    name: "tiny dinos winter 2022 #2428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2428,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2428.png",
  },
  "2429": {
    name: "tiny dinos winter 2022 #2429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2429,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2429.png",
  },
  "2430": {
    name: "tiny dinos winter 2022 #2430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2430,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2430.png",
  },
  "2431": {
    name: "tiny dinos winter 2022 #2431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2431,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2431.png",
  },
  "2432": {
    name: "tiny dinos winter 2022 #2432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2432,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2432.png",
  },
  "2433": {
    name: "tiny dinos winter 2022 #2433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2433,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2433.png",
  },
  "2434": {
    name: "tiny dinos winter 2022 #2434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2434,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2434.png",
  },
  "2435": {
    name: "tiny dinos winter 2022 #2435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2435,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2435.png",
  },
  "2436": {
    name: "tiny dinos winter 2022 #2436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2436,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2436.png",
  },
  "2437": {
    name: "tiny dinos winter 2022 #2437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2437,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2437.png",
  },
  "2438": {
    name: "tiny dinos winter 2022 #2438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2438,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2438.png",
  },
  "2439": {
    name: "tiny dinos winter 2022 #2439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2439,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2439.png",
  },
  "2440": {
    name: "tiny dinos winter 2022 #2440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2440,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2440.png",
  },
  "2441": {
    name: "tiny dinos winter 2022 #2441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2441,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2441.png",
  },
  "2442": {
    name: "tiny dinos winter 2022 #2442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2442,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2442.png",
  },
  "2443": {
    name: "tiny dinos winter 2022 #2443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2443,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2443.png",
  },
  "2444": {
    name: "tiny dinos winter 2022 #2444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2444,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2444.png",
  },
  "2445": {
    name: "tiny dinos winter 2022 #2445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2445,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2445.png",
  },
  "2446": {
    name: "tiny dinos winter 2022 #2446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2446,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2446.png",
  },
  "2447": {
    name: "tiny dinos winter 2022 #2447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2447,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2447.png",
  },
  "2448": {
    name: "tiny dinos winter 2022 #2448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2448,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2448.png",
  },
  "2449": {
    name: "tiny dinos winter 2022 #2449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2449,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2449.png",
  },
  "2450": {
    name: "tiny dinos winter 2022 #2450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2450,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2450.png",
  },
  "2451": {
    name: "tiny dinos winter 2022 #2451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2451,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2451.png",
  },
  "2452": {
    name: "tiny dinos winter 2022 #2452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2452,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2452.png",
  },
  "2453": {
    name: "tiny dinos winter 2022 #2453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2453,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2453.png",
  },
  "2454": {
    name: "tiny dinos winter 2022 #2454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2454,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2454.png",
  },
  "2455": {
    name: "tiny dinos winter 2022 #2455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2455,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2455.png",
  },
  "2456": {
    name: "tiny dinos winter 2022 #2456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2456,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2456.png",
  },
  "2457": {
    name: "tiny dinos winter 2022 #2457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2457,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2457.png",
  },
  "2458": {
    name: "tiny dinos winter 2022 #2458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2458,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2458.png",
  },
  "2459": {
    name: "tiny dinos winter 2022 #2459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2459,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2459.png",
  },
  "2460": {
    name: "tiny dinos winter 2022 #2460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2460,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2460.png",
  },
  "2461": {
    name: "tiny dinos winter 2022 #2461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2461,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2461.png",
  },
  "2462": {
    name: "tiny dinos winter 2022 #2462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2462,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2462.png",
  },
  "2463": {
    name: "tiny dinos winter 2022 #2463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2463,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2463.png",
  },
  "2464": {
    name: "tiny dinos winter 2022 #2464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2464,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2464.png",
  },
  "2465": {
    name: "tiny dinos winter 2022 #2465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2465,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2465.png",
  },
  "2466": {
    name: "tiny dinos winter 2022 #2466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2466,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2466.png",
  },
  "2467": {
    name: "tiny dinos winter 2022 #2467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2467,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2467.png",
  },
  "2468": {
    name: "tiny dinos winter 2022 #2468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2468,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2468.png",
  },
  "2469": {
    name: "tiny dinos winter 2022 #2469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2469,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2469.png",
  },
  "2470": {
    name: "tiny dinos winter 2022 #2470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2470,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2470.png",
  },
  "2471": {
    name: "tiny dinos winter 2022 #2471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2471,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2471.png",
  },
  "2472": {
    name: "tiny dinos winter 2022 #2472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2472,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2472.png",
  },
  "2473": {
    name: "tiny dinos winter 2022 #2473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2473,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2473.png",
  },
  "2474": {
    name: "tiny dinos winter 2022 #2474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2474,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2474.png",
  },
  "2475": {
    name: "tiny dinos winter 2022 #2475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2475,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2475.png",
  },
  "2476": {
    name: "tiny dinos winter 2022 #2476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2476,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2476.png",
  },
  "2477": {
    name: "tiny dinos winter 2022 #2477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2477,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2477.png",
  },
  "2478": {
    name: "tiny dinos winter 2022 #2478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2478,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2478.png",
  },
  "2479": {
    name: "tiny dinos winter 2022 #2479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2479,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2479.png",
  },
  "2480": {
    name: "tiny dinos winter 2022 #2480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2480,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2480.png",
  },
  "2481": {
    name: "tiny dinos winter 2022 #2481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2481,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2481.png",
  },
  "2482": {
    name: "tiny dinos winter 2022 #2482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2482,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2482.png",
  },
  "2483": {
    name: "tiny dinos winter 2022 #2483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2483,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2483.png",
  },
  "2484": {
    name: "tiny dinos winter 2022 #2484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2484,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2484.png",
  },
  "2485": {
    name: "tiny dinos winter 2022 #2485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2485,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2485.png",
  },
  "2486": {
    name: "tiny dinos winter 2022 #2486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2486,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2486.png",
  },
  "2487": {
    name: "tiny dinos winter 2022 #2487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2487,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2487.png",
  },
  "2488": {
    name: "tiny dinos winter 2022 #2488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2488,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2488.png",
  },
  "2489": {
    name: "tiny dinos winter 2022 #2489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2489,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2489.png",
  },
  "2490": {
    name: "tiny dinos winter 2022 #2490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2490,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2490.png",
  },
  "2491": {
    name: "tiny dinos winter 2022 #2491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2491,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2491.png",
  },
  "2492": {
    name: "tiny dinos winter 2022 #2492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2492,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2492.png",
  },
  "2493": {
    name: "tiny dinos winter 2022 #2493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2493,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2493.png",
  },
  "2494": {
    name: "tiny dinos winter 2022 #2494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2494,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2494.png",
  },
  "2495": {
    name: "tiny dinos winter 2022 #2495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2495,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2495.png",
  },
  "2496": {
    name: "tiny dinos winter 2022 #2496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2496,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2496.png",
  },
  "2497": {
    name: "tiny dinos winter 2022 #2497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2497,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2497.png",
  },
  "2498": {
    name: "tiny dinos winter 2022 #2498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2498,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2498.png",
  },
  "2499": {
    name: "tiny dinos winter 2022 #2499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2499,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2499.png",
  },
  "2500": {
    name: "tiny dinos winter 2022 #2500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2500,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2500.png",
  },
  "2501": {
    name: "tiny dinos winter 2022 #2501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2501,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2501.png",
  },
  "2502": {
    name: "tiny dinos winter 2022 #2502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2502,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2502.png",
  },
  "2503": {
    name: "tiny dinos winter 2022 #2503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2503,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2503.png",
  },
  "2504": {
    name: "tiny dinos winter 2022 #2504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2504,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2504.png",
  },
  "2505": {
    name: "tiny dinos winter 2022 #2505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2505,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2505.png",
  },
  "2506": {
    name: "tiny dinos winter 2022 #2506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2506,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2506.png",
  },
  "2507": {
    name: "tiny dinos winter 2022 #2507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2507,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2507.png",
  },
  "2508": {
    name: "tiny dinos winter 2022 #2508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2508,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2508.png",
  },
  "2509": {
    name: "tiny dinos winter 2022 #2509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2509,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2509.png",
  },
  "2510": {
    name: "tiny dinos winter 2022 #2510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2510,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2510.png",
  },
  "2511": {
    name: "tiny dinos winter 2022 #2511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2511,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2511.png",
  },
  "2512": {
    name: "tiny dinos winter 2022 #2512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2512,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2512.png",
  },
  "2513": {
    name: "tiny dinos winter 2022 #2513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2513,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2513.png",
  },
  "2514": {
    name: "tiny dinos winter 2022 #2514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2514,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2514.png",
  },
  "2515": {
    name: "tiny dinos winter 2022 #2515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2515,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2515.png",
  },
  "2516": {
    name: "tiny dinos winter 2022 #2516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2516,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2516.png",
  },
  "2517": {
    name: "tiny dinos winter 2022 #2517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2517,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2517.png",
  },
  "2518": {
    name: "tiny dinos winter 2022 #2518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2518,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2518.png",
  },
  "2519": {
    name: "tiny dinos winter 2022 #2519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2519,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2519.png",
  },
  "2520": {
    name: "tiny dinos winter 2022 #2520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2520,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2520.png",
  },
  "2521": {
    name: "tiny dinos winter 2022 #2521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2521,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2521.png",
  },
  "2522": {
    name: "tiny dinos winter 2022 #2522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2522,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2522.png",
  },
  "2523": {
    name: "tiny dinos winter 2022 #2523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2523,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2523.png",
  },
  "2524": {
    name: "tiny dinos winter 2022 #2524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2524,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2524.png",
  },
  "2525": {
    name: "tiny dinos winter 2022 #2525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2525,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2525.png",
  },
  "2526": {
    name: "tiny dinos winter 2022 #2526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2526,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2526.png",
  },
  "2527": {
    name: "tiny dinos winter 2022 #2527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2527,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2527.png",
  },
  "2528": {
    name: "tiny dinos winter 2022 #2528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2528,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2528.png",
  },
  "2529": {
    name: "tiny dinos winter 2022 #2529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2529,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2529.png",
  },
  "2530": {
    name: "tiny dinos winter 2022 #2530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2530,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2530.png",
  },
  "2531": {
    name: "tiny dinos winter 2022 #2531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2531,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2531.png",
  },
  "2532": {
    name: "tiny dinos winter 2022 #2532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2532,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2532.png",
  },
  "2533": {
    name: "tiny dinos winter 2022 #2533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2533,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2533.png",
  },
  "2534": {
    name: "tiny dinos winter 2022 #2534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2534,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2534.png",
  },
  "2535": {
    name: "tiny dinos winter 2022 #2535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2535,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2535.png",
  },
  "2536": {
    name: "tiny dinos winter 2022 #2536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2536,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2536.png",
  },
  "2537": {
    name: "tiny dinos winter 2022 #2537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2537,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2537.png",
  },
  "2538": {
    name: "tiny dinos winter 2022 #2538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2538,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2538.png",
  },
  "2539": {
    name: "tiny dinos winter 2022 #2539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2539,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2539.png",
  },
  "2540": {
    name: "tiny dinos winter 2022 #2540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2540,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2540.png",
  },
  "2541": {
    name: "tiny dinos winter 2022 #2541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2541,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2541.png",
  },
  "2542": {
    name: "tiny dinos winter 2022 #2542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2542,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2542.png",
  },
  "2543": {
    name: "tiny dinos winter 2022 #2543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2543,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2543.png",
  },
  "2544": {
    name: "tiny dinos winter 2022 #2544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2544,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2544.png",
  },
  "2545": {
    name: "tiny dinos winter 2022 #2545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2545,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2545.png",
  },
  "2546": {
    name: "tiny dinos winter 2022 #2546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2546,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2546.png",
  },
  "2547": {
    name: "tiny dinos winter 2022 #2547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2547,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2547.png",
  },
  "2548": {
    name: "tiny dinos winter 2022 #2548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2548,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2548.png",
  },
  "2549": {
    name: "tiny dinos winter 2022 #2549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2549,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2549.png",
  },
  "2550": {
    name: "tiny dinos winter 2022 #2550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2550,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2550.png",
  },
  "2551": {
    name: "tiny dinos winter 2022 #2551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2551,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2551.png",
  },
  "2552": {
    name: "tiny dinos winter 2022 #2552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2552,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2552.png",
  },
  "2553": {
    name: "tiny dinos winter 2022 #2553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2553,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2553.png",
  },
  "2554": {
    name: "tiny dinos winter 2022 #2554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2554,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2554.png",
  },
  "2555": {
    name: "tiny dinos winter 2022 #2555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2555,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2555.png",
  },
  "2556": {
    name: "tiny dinos winter 2022 #2556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2556,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2556.png",
  },
  "2557": {
    name: "tiny dinos winter 2022 #2557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2557,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2557.png",
  },
  "2558": {
    name: "tiny dinos winter 2022 #2558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2558,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2558.png",
  },
  "2559": {
    name: "tiny dinos winter 2022 #2559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2559,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2559.png",
  },
  "2560": {
    name: "tiny dinos winter 2022 #2560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2560,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2560.png",
  },
  "2561": {
    name: "tiny dinos winter 2022 #2561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2561,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2561.png",
  },
  "2562": {
    name: "tiny dinos winter 2022 #2562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2562,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2562.png",
  },
  "2563": {
    name: "tiny dinos winter 2022 #2563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2563,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2563.png",
  },
  "2564": {
    name: "tiny dinos winter 2022 #2564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2564,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2564.png",
  },
  "2565": {
    name: "tiny dinos winter 2022 #2565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2565,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2565.png",
  },
  "2566": {
    name: "tiny dinos winter 2022 #2566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2566,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2566.png",
  },
  "2567": {
    name: "tiny dinos winter 2022 #2567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2567,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2567.png",
  },
  "2568": {
    name: "tiny dinos winter 2022 #2568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2568,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2568.png",
  },
  "2569": {
    name: "tiny dinos winter 2022 #2569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2569,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2569.png",
  },
  "2570": {
    name: "tiny dinos winter 2022 #2570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2570,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2570.png",
  },
  "2571": {
    name: "tiny dinos winter 2022 #2571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2571,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2571.png",
  },
  "2572": {
    name: "tiny dinos winter 2022 #2572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2572,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2572.png",
  },
  "2573": {
    name: "tiny dinos winter 2022 #2573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2573,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2573.png",
  },
  "2574": {
    name: "tiny dinos winter 2022 #2574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2574,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2574.png",
  },
  "2575": {
    name: "tiny dinos winter 2022 #2575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2575,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2575.png",
  },
  "2576": {
    name: "tiny dinos winter 2022 #2576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2576,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2576.png",
  },
  "2577": {
    name: "tiny dinos winter 2022 #2577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2577,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2577.png",
  },
  "2578": {
    name: "tiny dinos winter 2022 #2578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2578,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2578.png",
  },
  "2579": {
    name: "tiny dinos winter 2022 #2579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2579,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2579.png",
  },
  "2580": {
    name: "tiny dinos winter 2022 #2580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2580,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2580.png",
  },
  "2581": {
    name: "tiny dinos winter 2022 #2581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2581,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2581.png",
  },
  "2582": {
    name: "tiny dinos winter 2022 #2582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2582,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2582.png",
  },
  "2583": {
    name: "tiny dinos winter 2022 #2583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2583,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2583.png",
  },
  "2584": {
    name: "tiny dinos winter 2022 #2584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2584,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2584.png",
  },
  "2585": {
    name: "tiny dinos winter 2022 #2585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2585,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2585.png",
  },
  "2586": {
    name: "tiny dinos winter 2022 #2586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2586,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2586.png",
  },
  "2587": {
    name: "tiny dinos winter 2022 #2587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2587,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2587.png",
  },
  "2588": {
    name: "tiny dinos winter 2022 #2588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2588,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2588.png",
  },
  "2589": {
    name: "tiny dinos winter 2022 #2589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2589,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2589.png",
  },
  "2590": {
    name: "tiny dinos winter 2022 #2590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2590,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2590.png",
  },
  "2591": {
    name: "tiny dinos winter 2022 #2591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2591,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2591.png",
  },
  "2592": {
    name: "tiny dinos winter 2022 #2592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2592,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2592.png",
  },
  "2593": {
    name: "tiny dinos winter 2022 #2593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2593,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2593.png",
  },
  "2594": {
    name: "tiny dinos winter 2022 #2594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2594,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2594.png",
  },
  "2595": {
    name: "tiny dinos winter 2022 #2595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2595,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2595.png",
  },
  "2596": {
    name: "tiny dinos winter 2022 #2596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2596,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2596.png",
  },
  "2597": {
    name: "tiny dinos winter 2022 #2597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2597,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2597.png",
  },
  "2598": {
    name: "tiny dinos winter 2022 #2598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2598,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2598.png",
  },
  "2599": {
    name: "tiny dinos winter 2022 #2599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2599,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2599.png",
  },
  "2600": {
    name: "tiny dinos winter 2022 #2600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2600,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2600.png",
  },
  "2601": {
    name: "tiny dinos winter 2022 #2601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2601,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2601.png",
  },
  "2602": {
    name: "tiny dinos winter 2022 #2602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2602,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2602.png",
  },
  "2603": {
    name: "tiny dinos winter 2022 #2603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2603,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2603.png",
  },
  "2604": {
    name: "tiny dinos winter 2022 #2604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2604,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2604.png",
  },
  "2605": {
    name: "tiny dinos winter 2022 #2605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2605,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2605.png",
  },
  "2606": {
    name: "tiny dinos winter 2022 #2606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2606,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2606.png",
  },
  "2607": {
    name: "tiny dinos winter 2022 #2607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2607,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2607.png",
  },
  "2608": {
    name: "tiny dinos winter 2022 #2608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2608,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2608.png",
  },
  "2609": {
    name: "tiny dinos winter 2022 #2609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2609,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2609.png",
  },
  "2610": {
    name: "tiny dinos winter 2022 #2610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2610,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2610.png",
  },
  "2611": {
    name: "tiny dinos winter 2022 #2611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2611,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2611.png",
  },
  "2612": {
    name: "tiny dinos winter 2022 #2612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2612,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2612.png",
  },
  "2613": {
    name: "tiny dinos winter 2022 #2613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2613,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2613.png",
  },
  "2614": {
    name: "tiny dinos winter 2022 #2614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2614,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2614.png",
  },
  "2615": {
    name: "tiny dinos winter 2022 #2615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2615,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2615.png",
  },
  "2616": {
    name: "tiny dinos winter 2022 #2616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2616,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2616.png",
  },
  "2617": {
    name: "tiny dinos winter 2022 #2617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2617,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2617.png",
  },
  "2618": {
    name: "tiny dinos winter 2022 #2618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2618,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2618.png",
  },
  "2619": {
    name: "tiny dinos winter 2022 #2619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2619,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2619.png",
  },
  "2620": {
    name: "tiny dinos winter 2022 #2620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2620,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2620.png",
  },
  "2621": {
    name: "tiny dinos winter 2022 #2621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2621,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2621.png",
  },
  "2622": {
    name: "tiny dinos winter 2022 #2622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2622,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2622.png",
  },
  "2623": {
    name: "tiny dinos winter 2022 #2623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2623,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2623.png",
  },
  "2624": {
    name: "tiny dinos winter 2022 #2624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2624,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2624.png",
  },
  "2625": {
    name: "tiny dinos winter 2022 #2625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2625,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2625.png",
  },
  "2626": {
    name: "tiny dinos winter 2022 #2626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2626,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2626.png",
  },
  "2627": {
    name: "tiny dinos winter 2022 #2627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2627,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2627.png",
  },
  "2628": {
    name: "tiny dinos winter 2022 #2628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2628,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2628.png",
  },
  "2629": {
    name: "tiny dinos winter 2022 #2629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2629,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2629.png",
  },
  "2630": {
    name: "tiny dinos winter 2022 #2630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2630,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2630.png",
  },
  "2631": {
    name: "tiny dinos winter 2022 #2631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2631,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2631.png",
  },
  "2632": {
    name: "tiny dinos winter 2022 #2632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2632,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2632.png",
  },
  "2633": {
    name: "tiny dinos winter 2022 #2633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2633,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2633.png",
  },
  "2634": {
    name: "tiny dinos winter 2022 #2634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2634,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2634.png",
  },
  "2635": {
    name: "tiny dinos winter 2022 #2635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2635,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2635.png",
  },
  "2636": {
    name: "tiny dinos winter 2022 #2636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2636,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2636.png",
  },
  "2637": {
    name: "tiny dinos winter 2022 #2637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2637,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2637.png",
  },
  "2638": {
    name: "tiny dinos winter 2022 #2638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2638,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2638.png",
  },
  "2639": {
    name: "tiny dinos winter 2022 #2639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2639,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2639.png",
  },
  "2640": {
    name: "tiny dinos winter 2022 #2640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2640,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2640.png",
  },
  "2641": {
    name: "tiny dinos winter 2022 #2641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2641,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2641.png",
  },
  "2642": {
    name: "tiny dinos winter 2022 #2642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2642,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2642.png",
  },
  "2643": {
    name: "tiny dinos winter 2022 #2643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2643,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2643.png",
  },
  "2644": {
    name: "tiny dinos winter 2022 #2644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2644,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2644.png",
  },
  "2645": {
    name: "tiny dinos winter 2022 #2645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2645,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2645.png",
  },
  "2646": {
    name: "tiny dinos winter 2022 #2646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2646,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2646.png",
  },
  "2647": {
    name: "tiny dinos winter 2022 #2647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2647,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2647.png",
  },
  "2648": {
    name: "tiny dinos winter 2022 #2648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2648,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2648.png",
  },
  "2649": {
    name: "tiny dinos winter 2022 #2649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2649,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2649.png",
  },
  "2650": {
    name: "tiny dinos winter 2022 #2650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2650,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2650.png",
  },
  "2651": {
    name: "tiny dinos winter 2022 #2651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2651,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2651.png",
  },
  "2652": {
    name: "tiny dinos winter 2022 #2652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2652,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2652.png",
  },
  "2653": {
    name: "tiny dinos winter 2022 #2653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2653,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2653.png",
  },
  "2654": {
    name: "tiny dinos winter 2022 #2654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2654,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2654.png",
  },
  "2655": {
    name: "tiny dinos winter 2022 #2655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2655,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2655.png",
  },
  "2656": {
    name: "tiny dinos winter 2022 #2656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2656,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2656.png",
  },
  "2657": {
    name: "tiny dinos winter 2022 #2657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2657,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2657.png",
  },
  "2658": {
    name: "tiny dinos winter 2022 #2658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2658,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2658.png",
  },
  "2659": {
    name: "tiny dinos winter 2022 #2659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2659,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2659.png",
  },
  "2660": {
    name: "tiny dinos winter 2022 #2660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2660,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2660.png",
  },
  "2661": {
    name: "tiny dinos winter 2022 #2661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2661,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2661.png",
  },
  "2662": {
    name: "tiny dinos winter 2022 #2662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2662,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2662.png",
  },
  "2663": {
    name: "tiny dinos winter 2022 #2663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2663,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2663.png",
  },
  "2664": {
    name: "tiny dinos winter 2022 #2664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2664,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2664.png",
  },
  "2665": {
    name: "tiny dinos winter 2022 #2665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2665,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2665.png",
  },
  "2666": {
    name: "tiny dinos winter 2022 #2666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2666,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2666.png",
  },
  "2667": {
    name: "tiny dinos winter 2022 #2667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2667,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2667.png",
  },
  "2668": {
    name: "tiny dinos winter 2022 #2668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2668,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2668.png",
  },
  "2669": {
    name: "tiny dinos winter 2022 #2669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2669,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2669.png",
  },
  "2670": {
    name: "tiny dinos winter 2022 #2670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2670,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2670.png",
  },
  "2671": {
    name: "tiny dinos winter 2022 #2671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2671,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2671.png",
  },
  "2672": {
    name: "tiny dinos winter 2022 #2672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2672,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2672.png",
  },
  "2673": {
    name: "tiny dinos winter 2022 #2673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2673,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2673.png",
  },
  "2674": {
    name: "tiny dinos winter 2022 #2674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2674,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2674.png",
  },
  "2675": {
    name: "tiny dinos winter 2022 #2675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2675,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2675.png",
  },
  "2676": {
    name: "tiny dinos winter 2022 #2676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2676,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2676.png",
  },
  "2677": {
    name: "tiny dinos winter 2022 #2677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2677,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2677.png",
  },
  "2678": {
    name: "tiny dinos winter 2022 #2678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2678,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2678.png",
  },
  "2679": {
    name: "tiny dinos winter 2022 #2679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2679,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2679.png",
  },
  "2680": {
    name: "tiny dinos winter 2022 #2680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2680,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2680.png",
  },
  "2681": {
    name: "tiny dinos winter 2022 #2681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2681,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2681.png",
  },
  "2682": {
    name: "tiny dinos winter 2022 #2682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2682,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2682.png",
  },
  "2683": {
    name: "tiny dinos winter 2022 #2683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2683,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2683.png",
  },
  "2684": {
    name: "tiny dinos winter 2022 #2684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2684,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2684.png",
  },
  "2685": {
    name: "tiny dinos winter 2022 #2685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2685,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2685.png",
  },
  "2686": {
    name: "tiny dinos winter 2022 #2686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2686,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2686.png",
  },
  "2687": {
    name: "tiny dinos winter 2022 #2687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2687,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2687.png",
  },
  "2688": {
    name: "tiny dinos winter 2022 #2688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2688,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2688.png",
  },
  "2689": {
    name: "tiny dinos winter 2022 #2689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2689,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2689.png",
  },
  "2690": {
    name: "tiny dinos winter 2022 #2690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2690,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2690.png",
  },
  "2691": {
    name: "tiny dinos winter 2022 #2691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2691,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2691.png",
  },
  "2692": {
    name: "tiny dinos winter 2022 #2692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2692,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2692.png",
  },
  "2693": {
    name: "tiny dinos winter 2022 #2693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2693,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2693.png",
  },
  "2694": {
    name: "tiny dinos winter 2022 #2694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2694,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2694.png",
  },
  "2695": {
    name: "tiny dinos winter 2022 #2695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2695,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2695.png",
  },
  "2696": {
    name: "tiny dinos winter 2022 #2696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2696,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2696.png",
  },
  "2697": {
    name: "tiny dinos winter 2022 #2697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2697,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2697.png",
  },
  "2698": {
    name: "tiny dinos winter 2022 #2698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2698,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2698.png",
  },
  "2699": {
    name: "tiny dinos winter 2022 #2699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2699,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2699.png",
  },
  "2700": {
    name: "tiny dinos winter 2022 #2700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2700,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2700.png",
  },
  "2701": {
    name: "tiny dinos winter 2022 #2701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2701,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2701.png",
  },
  "2702": {
    name: "tiny dinos winter 2022 #2702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2702,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2702.png",
  },
  "2703": {
    name: "tiny dinos winter 2022 #2703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2703,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2703.png",
  },
  "2704": {
    name: "tiny dinos winter 2022 #2704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2704,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2704.png",
  },
  "2705": {
    name: "tiny dinos winter 2022 #2705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2705,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2705.png",
  },
  "2706": {
    name: "tiny dinos winter 2022 #2706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2706,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2706.png",
  },
  "2707": {
    name: "tiny dinos winter 2022 #2707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2707,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2707.png",
  },
  "2708": {
    name: "tiny dinos winter 2022 #2708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2708,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2708.png",
  },
  "2709": {
    name: "tiny dinos winter 2022 #2709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2709,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2709.png",
  },
  "2710": {
    name: "tiny dinos winter 2022 #2710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2710,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2710.png",
  },
  "2711": {
    name: "tiny dinos winter 2022 #2711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2711,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2711.png",
  },
  "2712": {
    name: "tiny dinos winter 2022 #2712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2712,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2712.png",
  },
  "2713": {
    name: "tiny dinos winter 2022 #2713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2713,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2713.png",
  },
  "2714": {
    name: "tiny dinos winter 2022 #2714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2714,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2714.png",
  },
  "2715": {
    name: "tiny dinos winter 2022 #2715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2715,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2715.png",
  },
  "2716": {
    name: "tiny dinos winter 2022 #2716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2716,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2716.png",
  },
  "2717": {
    name: "tiny dinos winter 2022 #2717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2717,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2717.png",
  },
  "2718": {
    name: "tiny dinos winter 2022 #2718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2718,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2718.png",
  },
  "2719": {
    name: "tiny dinos winter 2022 #2719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2719,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2719.png",
  },
  "2720": {
    name: "tiny dinos winter 2022 #2720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2720,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2720.png",
  },
  "2721": {
    name: "tiny dinos winter 2022 #2721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2721,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2721.png",
  },
  "2722": {
    name: "tiny dinos winter 2022 #2722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2722,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2722.png",
  },
  "2723": {
    name: "tiny dinos winter 2022 #2723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2723,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2723.png",
  },
  "2724": {
    name: "tiny dinos winter 2022 #2724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2724,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2724.png",
  },
  "2725": {
    name: "tiny dinos winter 2022 #2725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2725,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2725.png",
  },
  "2726": {
    name: "tiny dinos winter 2022 #2726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2726,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2726.png",
  },
  "2727": {
    name: "tiny dinos winter 2022 #2727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2727,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2727.png",
  },
  "2728": {
    name: "tiny dinos winter 2022 #2728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2728,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2728.png",
  },
  "2729": {
    name: "tiny dinos winter 2022 #2729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2729,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2729.png",
  },
  "2730": {
    name: "tiny dinos winter 2022 #2730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2730,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2730.png",
  },
  "2731": {
    name: "tiny dinos winter 2022 #2731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2731,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2731.png",
  },
  "2732": {
    name: "tiny dinos winter 2022 #2732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2732,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2732.png",
  },
  "2733": {
    name: "tiny dinos winter 2022 #2733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2733,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2733.png",
  },
  "2734": {
    name: "tiny dinos winter 2022 #2734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2734,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2734.png",
  },
  "2735": {
    name: "tiny dinos winter 2022 #2735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2735,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2735.png",
  },
  "2736": {
    name: "tiny dinos winter 2022 #2736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2736,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2736.png",
  },
  "2737": {
    name: "tiny dinos winter 2022 #2737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2737,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2737.png",
  },
  "2738": {
    name: "tiny dinos winter 2022 #2738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2738,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2738.png",
  },
  "2739": {
    name: "tiny dinos winter 2022 #2739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2739,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2739.png",
  },
  "2740": {
    name: "tiny dinos winter 2022 #2740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2740,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2740.png",
  },
  "2741": {
    name: "tiny dinos winter 2022 #2741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2741,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2741.png",
  },
  "2742": {
    name: "tiny dinos winter 2022 #2742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2742,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2742.png",
  },
  "2743": {
    name: "tiny dinos winter 2022 #2743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2743,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2743.png",
  },
  "2744": {
    name: "tiny dinos winter 2022 #2744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2744,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2744.png",
  },
  "2745": {
    name: "tiny dinos winter 2022 #2745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2745,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2745.png",
  },
  "2746": {
    name: "tiny dinos winter 2022 #2746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2746,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2746.png",
  },
  "2747": {
    name: "tiny dinos winter 2022 #2747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2747,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2747.png",
  },
  "2748": {
    name: "tiny dinos winter 2022 #2748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2748,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2748.png",
  },
  "2749": {
    name: "tiny dinos winter 2022 #2749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2749,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2749.png",
  },
  "2750": {
    name: "tiny dinos winter 2022 #2750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2750,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2750.png",
  },
  "2751": {
    name: "tiny dinos winter 2022 #2751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2751,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2751.png",
  },
  "2752": {
    name: "tiny dinos winter 2022 #2752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2752,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2752.png",
  },
  "2753": {
    name: "tiny dinos winter 2022 #2753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2753,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2753.png",
  },
  "2754": {
    name: "tiny dinos winter 2022 #2754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2754,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2754.png",
  },
  "2755": {
    name: "tiny dinos winter 2022 #2755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2755,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2755.png",
  },
  "2756": {
    name: "tiny dinos winter 2022 #2756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2756,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2756.png",
  },
  "2757": {
    name: "tiny dinos winter 2022 #2757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2757,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2757.png",
  },
  "2758": {
    name: "tiny dinos winter 2022 #2758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2758,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2758.png",
  },
  "2759": {
    name: "tiny dinos winter 2022 #2759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2759,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2759.png",
  },
  "2760": {
    name: "tiny dinos winter 2022 #2760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2760,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2760.png",
  },
  "2761": {
    name: "tiny dinos winter 2022 #2761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2761,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2761.png",
  },
  "2762": {
    name: "tiny dinos winter 2022 #2762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2762,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2762.png",
  },
  "2763": {
    name: "tiny dinos winter 2022 #2763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2763,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2763.png",
  },
  "2764": {
    name: "tiny dinos winter 2022 #2764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2764,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2764.png",
  },
  "2765": {
    name: "tiny dinos winter 2022 #2765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2765,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2765.png",
  },
  "2766": {
    name: "tiny dinos winter 2022 #2766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2766,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2766.png",
  },
  "2767": {
    name: "tiny dinos winter 2022 #2767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2767,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2767.png",
  },
  "2768": {
    name: "tiny dinos winter 2022 #2768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2768,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2768.png",
  },
  "2769": {
    name: "tiny dinos winter 2022 #2769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2769,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2769.png",
  },
  "2770": {
    name: "tiny dinos winter 2022 #2770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2770,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2770.png",
  },
  "2771": {
    name: "tiny dinos winter 2022 #2771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2771,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2771.png",
  },
  "2772": {
    name: "tiny dinos winter 2022 #2772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2772,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2772.png",
  },
  "2773": {
    name: "tiny dinos winter 2022 #2773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2773,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2773.png",
  },
  "2774": {
    name: "tiny dinos winter 2022 #2774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2774,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2774.png",
  },
  "2775": {
    name: "tiny dinos winter 2022 #2775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2775,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2775.png",
  },
  "2776": {
    name: "tiny dinos winter 2022 #2776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2776,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2776.png",
  },
  "2777": {
    name: "tiny dinos winter 2022 #2777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2777,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2777.png",
  },
  "2778": {
    name: "tiny dinos winter 2022 #2778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2778,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2778.png",
  },
  "2779": {
    name: "tiny dinos winter 2022 #2779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2779,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2779.png",
  },
  "2780": {
    name: "tiny dinos winter 2022 #2780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2780,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2780.png",
  },
  "2781": {
    name: "tiny dinos winter 2022 #2781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2781,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2781.png",
  },
  "2782": {
    name: "tiny dinos winter 2022 #2782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2782,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2782.png",
  },
  "2783": {
    name: "tiny dinos winter 2022 #2783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2783,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2783.png",
  },
  "2784": {
    name: "tiny dinos winter 2022 #2784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2784,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2784.png",
  },
  "2785": {
    name: "tiny dinos winter 2022 #2785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2785,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2785.png",
  },
  "2786": {
    name: "tiny dinos winter 2022 #2786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2786,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2786.png",
  },
  "2787": {
    name: "tiny dinos winter 2022 #2787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2787,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2787.png",
  },
  "2788": {
    name: "tiny dinos winter 2022 #2788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2788,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2788.png",
  },
  "2789": {
    name: "tiny dinos winter 2022 #2789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2789,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2789.png",
  },
  "2790": {
    name: "tiny dinos winter 2022 #2790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2790,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2790.png",
  },
  "2791": {
    name: "tiny dinos winter 2022 #2791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2791,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2791.png",
  },
  "2792": {
    name: "tiny dinos winter 2022 #2792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2792,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2792.png",
  },
  "2793": {
    name: "tiny dinos winter 2022 #2793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2793,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2793.png",
  },
  "2794": {
    name: "tiny dinos winter 2022 #2794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2794,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2794.png",
  },
  "2795": {
    name: "tiny dinos winter 2022 #2795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2795,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2795.png",
  },
  "2796": {
    name: "tiny dinos winter 2022 #2796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2796,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2796.png",
  },
  "2797": {
    name: "tiny dinos winter 2022 #2797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2797,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2797.png",
  },
  "2798": {
    name: "tiny dinos winter 2022 #2798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2798,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2798.png",
  },
  "2799": {
    name: "tiny dinos winter 2022 #2799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2799,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2799.png",
  },
  "2800": {
    name: "tiny dinos winter 2022 #2800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2800,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2800.png",
  },
  "2801": {
    name: "tiny dinos winter 2022 #2801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2801,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2801.png",
  },
  "2802": {
    name: "tiny dinos winter 2022 #2802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2802,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2802.png",
  },
  "2803": {
    name: "tiny dinos winter 2022 #2803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2803,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2803.png",
  },
  "2804": {
    name: "tiny dinos winter 2022 #2804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2804,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2804.png",
  },
  "2805": {
    name: "tiny dinos winter 2022 #2805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2805,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2805.png",
  },
  "2806": {
    name: "tiny dinos winter 2022 #2806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2806,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2806.png",
  },
  "2807": {
    name: "tiny dinos winter 2022 #2807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2807,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2807.png",
  },
  "2808": {
    name: "tiny dinos winter 2022 #2808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2808,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2808.png",
  },
  "2809": {
    name: "tiny dinos winter 2022 #2809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2809,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2809.png",
  },
  "2810": {
    name: "tiny dinos winter 2022 #2810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2810,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2810.png",
  },
  "2811": {
    name: "tiny dinos winter 2022 #2811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2811,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2811.png",
  },
  "2812": {
    name: "tiny dinos winter 2022 #2812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2812,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2812.png",
  },
  "2813": {
    name: "tiny dinos winter 2022 #2813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2813,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2813.png",
  },
  "2814": {
    name: "tiny dinos winter 2022 #2814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2814,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2814.png",
  },
  "2815": {
    name: "tiny dinos winter 2022 #2815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2815,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2815.png",
  },
  "2816": {
    name: "tiny dinos winter 2022 #2816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2816,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2816.png",
  },
  "2817": {
    name: "tiny dinos winter 2022 #2817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2817,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2817.png",
  },
  "2818": {
    name: "tiny dinos winter 2022 #2818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2818,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2818.png",
  },
  "2819": {
    name: "tiny dinos winter 2022 #2819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2819,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2819.png",
  },
  "2820": {
    name: "tiny dinos winter 2022 #2820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2820,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2820.png",
  },
  "2821": {
    name: "tiny dinos winter 2022 #2821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2821,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2821.png",
  },
  "2822": {
    name: "tiny dinos winter 2022 #2822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2822,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2822.png",
  },
  "2823": {
    name: "tiny dinos winter 2022 #2823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2823,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2823.png",
  },
  "2824": {
    name: "tiny dinos winter 2022 #2824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2824,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2824.png",
  },
  "2825": {
    name: "tiny dinos winter 2022 #2825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2825,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2825.png",
  },
  "2826": {
    name: "tiny dinos winter 2022 #2826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2826,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2826.png",
  },
  "2827": {
    name: "tiny dinos winter 2022 #2827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2827,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2827.png",
  },
  "2828": {
    name: "tiny dinos winter 2022 #2828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2828,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2828.png",
  },
  "2829": {
    name: "tiny dinos winter 2022 #2829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2829,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2829.png",
  },
  "2830": {
    name: "tiny dinos winter 2022 #2830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2830,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2830.png",
  },
  "2831": {
    name: "tiny dinos winter 2022 #2831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2831,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2831.png",
  },
  "2832": {
    name: "tiny dinos winter 2022 #2832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2832,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2832.png",
  },
  "2833": {
    name: "tiny dinos winter 2022 #2833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2833,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2833.png",
  },
  "2834": {
    name: "tiny dinos winter 2022 #2834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2834,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2834.png",
  },
  "2835": {
    name: "tiny dinos winter 2022 #2835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2835,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2835.png",
  },
  "2836": {
    name: "tiny dinos winter 2022 #2836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2836,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2836.png",
  },
  "2837": {
    name: "tiny dinos winter 2022 #2837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2837,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2837.png",
  },
  "2838": {
    name: "tiny dinos winter 2022 #2838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2838,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2838.png",
  },
  "2839": {
    name: "tiny dinos winter 2022 #2839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2839,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2839.png",
  },
  "2840": {
    name: "tiny dinos winter 2022 #2840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2840,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2840.png",
  },
  "2841": {
    name: "tiny dinos winter 2022 #2841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2841,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2841.png",
  },
  "2842": {
    name: "tiny dinos winter 2022 #2842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2842,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2842.png",
  },
  "2843": {
    name: "tiny dinos winter 2022 #2843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2843,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2843.png",
  },
  "2844": {
    name: "tiny dinos winter 2022 #2844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2844,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2844.png",
  },
  "2845": {
    name: "tiny dinos winter 2022 #2845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2845,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2845.png",
  },
  "2846": {
    name: "tiny dinos winter 2022 #2846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2846,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2846.png",
  },
  "2847": {
    name: "tiny dinos winter 2022 #2847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2847,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2847.png",
  },
  "2848": {
    name: "tiny dinos winter 2022 #2848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2848,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2848.png",
  },
  "2849": {
    name: "tiny dinos winter 2022 #2849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2849,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2849.png",
  },
  "2850": {
    name: "tiny dinos winter 2022 #2850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2850,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2850.png",
  },
  "2851": {
    name: "tiny dinos winter 2022 #2851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2851,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2851.png",
  },
  "2852": {
    name: "tiny dinos winter 2022 #2852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2852,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2852.png",
  },
  "2853": {
    name: "tiny dinos winter 2022 #2853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2853,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2853.png",
  },
  "2854": {
    name: "tiny dinos winter 2022 #2854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2854,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2854.png",
  },
  "2855": {
    name: "tiny dinos winter 2022 #2855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2855,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2855.png",
  },
  "2856": {
    name: "tiny dinos winter 2022 #2856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2856,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2856.png",
  },
  "2857": {
    name: "tiny dinos winter 2022 #2857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2857,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2857.png",
  },
  "2858": {
    name: "tiny dinos winter 2022 #2858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2858,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2858.png",
  },
  "2859": {
    name: "tiny dinos winter 2022 #2859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2859,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2859.png",
  },
  "2860": {
    name: "tiny dinos winter 2022 #2860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2860,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2860.png",
  },
  "2861": {
    name: "tiny dinos winter 2022 #2861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2861,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2861.png",
  },
  "2862": {
    name: "tiny dinos winter 2022 #2862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2862,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2862.png",
  },
  "2863": {
    name: "tiny dinos winter 2022 #2863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2863,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2863.png",
  },
  "2864": {
    name: "tiny dinos winter 2022 #2864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2864,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2864.png",
  },
  "2865": {
    name: "tiny dinos winter 2022 #2865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2865,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2865.png",
  },
  "2866": {
    name: "tiny dinos winter 2022 #2866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2866,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2866.png",
  },
  "2867": {
    name: "tiny dinos winter 2022 #2867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2867,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2867.png",
  },
  "2868": {
    name: "tiny dinos winter 2022 #2868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2868,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2868.png",
  },
  "2869": {
    name: "tiny dinos winter 2022 #2869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2869,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2869.png",
  },
  "2870": {
    name: "tiny dinos winter 2022 #2870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2870,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2870.png",
  },
  "2871": {
    name: "tiny dinos winter 2022 #2871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2871,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2871.png",
  },
  "2872": {
    name: "tiny dinos winter 2022 #2872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2872,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2872.png",
  },
  "2873": {
    name: "tiny dinos winter 2022 #2873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2873,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2873.png",
  },
  "2874": {
    name: "tiny dinos winter 2022 #2874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2874,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2874.png",
  },
  "2875": {
    name: "tiny dinos winter 2022 #2875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2875,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2875.png",
  },
  "2876": {
    name: "tiny dinos winter 2022 #2876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2876,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2876.png",
  },
  "2877": {
    name: "tiny dinos winter 2022 #2877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2877,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2877.png",
  },
  "2878": {
    name: "tiny dinos winter 2022 #2878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2878,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2878.png",
  },
  "2879": {
    name: "tiny dinos winter 2022 #2879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2879,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2879.png",
  },
  "2880": {
    name: "tiny dinos winter 2022 #2880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2880,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2880.png",
  },
  "2881": {
    name: "tiny dinos winter 2022 #2881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2881,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2881.png",
  },
  "2882": {
    name: "tiny dinos winter 2022 #2882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2882,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2882.png",
  },
  "2883": {
    name: "tiny dinos winter 2022 #2883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2883,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2883.png",
  },
  "2884": {
    name: "tiny dinos winter 2022 #2884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2884,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2884.png",
  },
  "2885": {
    name: "tiny dinos winter 2022 #2885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2885,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2885.png",
  },
  "2886": {
    name: "tiny dinos winter 2022 #2886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2886,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2886.png",
  },
  "2887": {
    name: "tiny dinos winter 2022 #2887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2887,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2887.png",
  },
  "2888": {
    name: "tiny dinos winter 2022 #2888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2888,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2888.png",
  },
  "2889": {
    name: "tiny dinos winter 2022 #2889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2889,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2889.png",
  },
  "2890": {
    name: "tiny dinos winter 2022 #2890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2890,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2890.png",
  },
  "2891": {
    name: "tiny dinos winter 2022 #2891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2891,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2891.png",
  },
  "2892": {
    name: "tiny dinos winter 2022 #2892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2892,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2892.png",
  },
  "2893": {
    name: "tiny dinos winter 2022 #2893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2893,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2893.png",
  },
  "2894": {
    name: "tiny dinos winter 2022 #2894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2894,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2894.png",
  },
  "2895": {
    name: "tiny dinos winter 2022 #2895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2895,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2895.png",
  },
  "2896": {
    name: "tiny dinos winter 2022 #2896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2896,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2896.png",
  },
  "2897": {
    name: "tiny dinos winter 2022 #2897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2897,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2897.png",
  },
  "2898": {
    name: "tiny dinos winter 2022 #2898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2898,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2898.png",
  },
  "2899": {
    name: "tiny dinos winter 2022 #2899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2899,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2899.png",
  },
  "2900": {
    name: "tiny dinos winter 2022 #2900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2900,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2900.png",
  },
  "2901": {
    name: "tiny dinos winter 2022 #2901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2901,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2901.png",
  },
  "2902": {
    name: "tiny dinos winter 2022 #2902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2902,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2902.png",
  },
  "2903": {
    name: "tiny dinos winter 2022 #2903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2903,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2903.png",
  },
  "2904": {
    name: "tiny dinos winter 2022 #2904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2904,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2904.png",
  },
  "2905": {
    name: "tiny dinos winter 2022 #2905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2905,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2905.png",
  },
  "2906": {
    name: "tiny dinos winter 2022 #2906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2906,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2906.png",
  },
  "2907": {
    name: "tiny dinos winter 2022 #2907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2907,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2907.png",
  },
  "2908": {
    name: "tiny dinos winter 2022 #2908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2908,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2908.png",
  },
  "2909": {
    name: "tiny dinos winter 2022 #2909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2909,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2909.png",
  },
  "2910": {
    name: "tiny dinos winter 2022 #2910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2910,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2910.png",
  },
  "2911": {
    name: "tiny dinos winter 2022 #2911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2911,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2911.png",
  },
  "2912": {
    name: "tiny dinos winter 2022 #2912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2912,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2912.png",
  },
  "2913": {
    name: "tiny dinos winter 2022 #2913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2913,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2913.png",
  },
  "2914": {
    name: "tiny dinos winter 2022 #2914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2914,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2914.png",
  },
  "2915": {
    name: "tiny dinos winter 2022 #2915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2915,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2915.png",
  },
  "2916": {
    name: "tiny dinos winter 2022 #2916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2916,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2916.png",
  },
  "2917": {
    name: "tiny dinos winter 2022 #2917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2917,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2917.png",
  },
  "2918": {
    name: "tiny dinos winter 2022 #2918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2918,
    attributes: [
      {
        trait_type: "1/1",
        value: "ghost",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2918.png",
  },
  "2919": {
    name: "tiny dinos winter 2022 #2919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2919,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2919.png",
  },
  "2920": {
    name: "tiny dinos winter 2022 #2920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2920,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2920.png",
  },
  "2921": {
    name: "tiny dinos winter 2022 #2921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2921,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2921.png",
  },
  "2922": {
    name: "tiny dinos winter 2022 #2922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2922,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2922.png",
  },
  "2923": {
    name: "tiny dinos winter 2022 #2923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2923,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2923.png",
  },
  "2924": {
    name: "tiny dinos winter 2022 #2924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2924,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2924.png",
  },
  "2925": {
    name: "tiny dinos winter 2022 #2925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2925,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2925.png",
  },
  "2926": {
    name: "tiny dinos winter 2022 #2926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2926,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2926.png",
  },
  "2927": {
    name: "tiny dinos winter 2022 #2927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2927,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2927.png",
  },
  "2928": {
    name: "tiny dinos winter 2022 #2928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2928,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2928.png",
  },
  "2929": {
    name: "tiny dinos winter 2022 #2929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2929,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2929.png",
  },
  "2930": {
    name: "tiny dinos winter 2022 #2930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2930,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2930.png",
  },
  "2931": {
    name: "tiny dinos winter 2022 #2931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2931,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2931.png",
  },
  "2932": {
    name: "tiny dinos winter 2022 #2932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2932,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2932.png",
  },
  "2933": {
    name: "tiny dinos winter 2022 #2933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2933,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2933.png",
  },
  "2934": {
    name: "tiny dinos winter 2022 #2934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2934,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2934.png",
  },
  "2935": {
    name: "tiny dinos winter 2022 #2935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2935,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2935.png",
  },
  "2936": {
    name: "tiny dinos winter 2022 #2936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2936,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2936.png",
  },
  "2937": {
    name: "tiny dinos winter 2022 #2937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2937,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2937.png",
  },
  "2938": {
    name: "tiny dinos winter 2022 #2938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2938,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2938.png",
  },
  "2939": {
    name: "tiny dinos winter 2022 #2939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2939,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2939.png",
  },
  "2940": {
    name: "tiny dinos winter 2022 #2940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2940,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2940.png",
  },
  "2941": {
    name: "tiny dinos winter 2022 #2941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2941,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2941.png",
  },
  "2942": {
    name: "tiny dinos winter 2022 #2942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2942,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2942.png",
  },
  "2943": {
    name: "tiny dinos winter 2022 #2943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2943,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2943.png",
  },
  "2944": {
    name: "tiny dinos winter 2022 #2944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2944,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2944.png",
  },
  "2945": {
    name: "tiny dinos winter 2022 #2945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2945,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2945.png",
  },
  "2946": {
    name: "tiny dinos winter 2022 #2946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2946,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2946.png",
  },
  "2947": {
    name: "tiny dinos winter 2022 #2947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2947,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2947.png",
  },
  "2948": {
    name: "tiny dinos winter 2022 #2948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2948,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2948.png",
  },
  "2949": {
    name: "tiny dinos winter 2022 #2949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2949,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2949.png",
  },
  "2950": {
    name: "tiny dinos winter 2022 #2950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2950,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2950.png",
  },
  "2951": {
    name: "tiny dinos winter 2022 #2951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2951,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2951.png",
  },
  "2952": {
    name: "tiny dinos winter 2022 #2952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2952,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2952.png",
  },
  "2953": {
    name: "tiny dinos winter 2022 #2953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2953,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2953.png",
  },
  "2954": {
    name: "tiny dinos winter 2022 #2954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2954,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2954.png",
  },
  "2955": {
    name: "tiny dinos winter 2022 #2955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2955,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2955.png",
  },
  "2956": {
    name: "tiny dinos winter 2022 #2956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2956,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2956.png",
  },
  "2957": {
    name: "tiny dinos winter 2022 #2957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2957,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2957.png",
  },
  "2958": {
    name: "tiny dinos winter 2022 #2958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2958,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2958.png",
  },
  "2959": {
    name: "tiny dinos winter 2022 #2959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2959,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2959.png",
  },
  "2960": {
    name: "tiny dinos winter 2022 #2960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2960,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2960.png",
  },
  "2961": {
    name: "tiny dinos winter 2022 #2961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2961,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2961.png",
  },
  "2962": {
    name: "tiny dinos winter 2022 #2962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2962,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2962.png",
  },
  "2963": {
    name: "tiny dinos winter 2022 #2963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2963,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2963.png",
  },
  "2964": {
    name: "tiny dinos winter 2022 #2964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2964,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2964.png",
  },
  "2965": {
    name: "tiny dinos winter 2022 #2965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2965,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2965.png",
  },
  "2966": {
    name: "tiny dinos winter 2022 #2966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2966,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2966.png",
  },
  "2967": {
    name: "tiny dinos winter 2022 #2967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2967,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2967.png",
  },
  "2968": {
    name: "tiny dinos winter 2022 #2968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2968,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2968.png",
  },
  "2969": {
    name: "tiny dinos winter 2022 #2969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2969,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2969.png",
  },
  "2970": {
    name: "tiny dinos winter 2022 #2970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2970,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2970.png",
  },
  "2971": {
    name: "tiny dinos winter 2022 #2971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2971,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2971.png",
  },
  "2972": {
    name: "tiny dinos winter 2022 #2972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2972,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2972.png",
  },
  "2973": {
    name: "tiny dinos winter 2022 #2973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2973,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2973.png",
  },
  "2974": {
    name: "tiny dinos winter 2022 #2974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2974,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2974.png",
  },
  "2975": {
    name: "tiny dinos winter 2022 #2975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2975,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2975.png",
  },
  "2976": {
    name: "tiny dinos winter 2022 #2976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2976,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2976.png",
  },
  "2977": {
    name: "tiny dinos winter 2022 #2977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2977,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2977.png",
  },
  "2978": {
    name: "tiny dinos winter 2022 #2978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2978,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2978.png",
  },
  "2979": {
    name: "tiny dinos winter 2022 #2979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2979,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2979.png",
  },
  "2980": {
    name: "tiny dinos winter 2022 #2980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2980,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2980.png",
  },
  "2981": {
    name: "tiny dinos winter 2022 #2981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2981,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2981.png",
  },
  "2982": {
    name: "tiny dinos winter 2022 #2982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2982,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2982.png",
  },
  "2983": {
    name: "tiny dinos winter 2022 #2983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2983,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2983.png",
  },
  "2984": {
    name: "tiny dinos winter 2022 #2984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2984,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2984.png",
  },
  "2985": {
    name: "tiny dinos winter 2022 #2985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2985,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2985.png",
  },
  "2986": {
    name: "tiny dinos winter 2022 #2986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2986,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2986.png",
  },
  "2987": {
    name: "tiny dinos winter 2022 #2987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2987,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2987.png",
  },
  "2988": {
    name: "tiny dinos winter 2022 #2988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2988,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2988.png",
  },
  "2989": {
    name: "tiny dinos winter 2022 #2989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2989,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2989.png",
  },
  "2990": {
    name: "tiny dinos winter 2022 #2990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2990,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2990.png",
  },
  "2991": {
    name: "tiny dinos winter 2022 #2991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2991,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2991.png",
  },
  "2992": {
    name: "tiny dinos winter 2022 #2992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2992,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2992.png",
  },
  "2993": {
    name: "tiny dinos winter 2022 #2993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2993,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2993.png",
  },
  "2994": {
    name: "tiny dinos winter 2022 #2994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2994,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2994.png",
  },
  "2995": {
    name: "tiny dinos winter 2022 #2995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2995,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2995.png",
  },
  "2996": {
    name: "tiny dinos winter 2022 #2996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2996,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2996.png",
  },
  "2997": {
    name: "tiny dinos winter 2022 #2997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2997,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2997.png",
  },
  "2998": {
    name: "tiny dinos winter 2022 #2998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2998,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2998.png",
  },
  "2999": {
    name: "tiny dinos winter 2022 #2999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 2999,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/2999.png",
  },
  "3000": {
    name: "tiny dinos winter 2022 #3000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3000,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3000.png",
  },
  "3001": {
    name: "tiny dinos winter 2022 #3001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3001,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3001.png",
  },
  "3002": {
    name: "tiny dinos winter 2022 #3002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3002,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3002.png",
  },
  "3003": {
    name: "tiny dinos winter 2022 #3003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3003,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3003.png",
  },
  "3004": {
    name: "tiny dinos winter 2022 #3004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3004,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3004.png",
  },
  "3005": {
    name: "tiny dinos winter 2022 #3005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3005,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3005.png",
  },
  "3006": {
    name: "tiny dinos winter 2022 #3006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3006,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3006.png",
  },
  "3007": {
    name: "tiny dinos winter 2022 #3007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3007,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3007.png",
  },
  "3008": {
    name: "tiny dinos winter 2022 #3008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3008,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3008.png",
  },
  "3009": {
    name: "tiny dinos winter 2022 #3009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3009,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3009.png",
  },
  "3010": {
    name: "tiny dinos winter 2022 #3010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3010,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3010.png",
  },
  "3011": {
    name: "tiny dinos winter 2022 #3011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3011,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3011.png",
  },
  "3012": {
    name: "tiny dinos winter 2022 #3012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3012,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3012.png",
  },
  "3013": {
    name: "tiny dinos winter 2022 #3013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3013,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3013.png",
  },
  "3014": {
    name: "tiny dinos winter 2022 #3014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3014,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3014.png",
  },
  "3015": {
    name: "tiny dinos winter 2022 #3015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3015,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3015.png",
  },
  "3016": {
    name: "tiny dinos winter 2022 #3016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3016,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3016.png",
  },
  "3017": {
    name: "tiny dinos winter 2022 #3017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3017,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3017.png",
  },
  "3018": {
    name: "tiny dinos winter 2022 #3018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3018,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3018.png",
  },
  "3019": {
    name: "tiny dinos winter 2022 #3019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3019,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3019.png",
  },
  "3020": {
    name: "tiny dinos winter 2022 #3020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3020,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3020.png",
  },
  "3021": {
    name: "tiny dinos winter 2022 #3021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3021,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3021.png",
  },
  "3022": {
    name: "tiny dinos winter 2022 #3022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3022,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3022.png",
  },
  "3023": {
    name: "tiny dinos winter 2022 #3023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3023,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3023.png",
  },
  "3024": {
    name: "tiny dinos winter 2022 #3024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3024,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3024.png",
  },
  "3025": {
    name: "tiny dinos winter 2022 #3025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3025,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3025.png",
  },
  "3026": {
    name: "tiny dinos winter 2022 #3026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3026,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3026.png",
  },
  "3027": {
    name: "tiny dinos winter 2022 #3027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3027,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3027.png",
  },
  "3028": {
    name: "tiny dinos winter 2022 #3028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3028,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3028.png",
  },
  "3029": {
    name: "tiny dinos winter 2022 #3029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3029,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3029.png",
  },
  "3030": {
    name: "tiny dinos winter 2022 #3030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3030,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3030.png",
  },
  "3031": {
    name: "tiny dinos winter 2022 #3031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3031,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3031.png",
  },
  "3032": {
    name: "tiny dinos winter 2022 #3032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3032,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3032.png",
  },
  "3033": {
    name: "tiny dinos winter 2022 #3033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3033,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3033.png",
  },
  "3034": {
    name: "tiny dinos winter 2022 #3034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3034,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3034.png",
  },
  "3035": {
    name: "tiny dinos winter 2022 #3035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3035,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3035.png",
  },
  "3036": {
    name: "tiny dinos winter 2022 #3036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3036,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3036.png",
  },
  "3037": {
    name: "tiny dinos winter 2022 #3037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3037,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3037.png",
  },
  "3038": {
    name: "tiny dinos winter 2022 #3038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3038,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3038.png",
  },
  "3039": {
    name: "tiny dinos winter 2022 #3039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3039,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3039.png",
  },
  "3040": {
    name: "tiny dinos winter 2022 #3040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3040,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3040.png",
  },
  "3041": {
    name: "tiny dinos winter 2022 #3041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3041,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3041.png",
  },
  "3042": {
    name: "tiny dinos winter 2022 #3042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3042,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3042.png",
  },
  "3043": {
    name: "tiny dinos winter 2022 #3043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3043,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3043.png",
  },
  "3044": {
    name: "tiny dinos winter 2022 #3044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3044,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3044.png",
  },
  "3045": {
    name: "tiny dinos winter 2022 #3045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3045,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3045.png",
  },
  "3046": {
    name: "tiny dinos winter 2022 #3046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3046,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3046.png",
  },
  "3047": {
    name: "tiny dinos winter 2022 #3047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3047,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3047.png",
  },
  "3048": {
    name: "tiny dinos winter 2022 #3048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3048,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3048.png",
  },
  "3049": {
    name: "tiny dinos winter 2022 #3049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3049,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3049.png",
  },
  "3050": {
    name: "tiny dinos winter 2022 #3050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3050,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3050.png",
  },
  "3051": {
    name: "tiny dinos winter 2022 #3051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3051,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3051.png",
  },
  "3052": {
    name: "tiny dinos winter 2022 #3052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3052,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3052.png",
  },
  "3053": {
    name: "tiny dinos winter 2022 #3053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3053,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3053.png",
  },
  "3054": {
    name: "tiny dinos winter 2022 #3054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3054,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3054.png",
  },
  "3055": {
    name: "tiny dinos winter 2022 #3055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3055,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3055.png",
  },
  "3056": {
    name: "tiny dinos winter 2022 #3056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3056,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3056.png",
  },
  "3057": {
    name: "tiny dinos winter 2022 #3057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3057,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3057.png",
  },
  "3058": {
    name: "tiny dinos winter 2022 #3058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3058,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3058.png",
  },
  "3059": {
    name: "tiny dinos winter 2022 #3059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3059,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3059.png",
  },
  "3060": {
    name: "tiny dinos winter 2022 #3060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3060,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3060.png",
  },
  "3061": {
    name: "tiny dinos winter 2022 #3061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3061,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3061.png",
  },
  "3062": {
    name: "tiny dinos winter 2022 #3062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3062,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3062.png",
  },
  "3063": {
    name: "tiny dinos winter 2022 #3063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3063,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3063.png",
  },
  "3064": {
    name: "tiny dinos winter 2022 #3064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3064,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3064.png",
  },
  "3065": {
    name: "tiny dinos winter 2022 #3065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3065,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3065.png",
  },
  "3066": {
    name: "tiny dinos winter 2022 #3066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3066,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3066.png",
  },
  "3067": {
    name: "tiny dinos winter 2022 #3067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3067,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3067.png",
  },
  "3068": {
    name: "tiny dinos winter 2022 #3068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3068,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3068.png",
  },
  "3069": {
    name: "tiny dinos winter 2022 #3069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3069,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3069.png",
  },
  "3070": {
    name: "tiny dinos winter 2022 #3070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3070,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3070.png",
  },
  "3071": {
    name: "tiny dinos winter 2022 #3071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3071,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3071.png",
  },
  "3072": {
    name: "tiny dinos winter 2022 #3072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3072,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3072.png",
  },
  "3073": {
    name: "tiny dinos winter 2022 #3073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3073,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3073.png",
  },
  "3074": {
    name: "tiny dinos winter 2022 #3074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3074,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3074.png",
  },
  "3075": {
    name: "tiny dinos winter 2022 #3075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3075,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3075.png",
  },
  "3076": {
    name: "tiny dinos winter 2022 #3076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3076,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3076.png",
  },
  "3077": {
    name: "tiny dinos winter 2022 #3077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3077,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3077.png",
  },
  "3078": {
    name: "tiny dinos winter 2022 #3078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3078,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3078.png",
  },
  "3079": {
    name: "tiny dinos winter 2022 #3079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3079,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3079.png",
  },
  "3080": {
    name: "tiny dinos winter 2022 #3080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3080,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3080.png",
  },
  "3081": {
    name: "tiny dinos winter 2022 #3081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3081,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3081.png",
  },
  "3082": {
    name: "tiny dinos winter 2022 #3082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3082,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3082.png",
  },
  "3083": {
    name: "tiny dinos winter 2022 #3083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3083,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3083.png",
  },
  "3084": {
    name: "tiny dinos winter 2022 #3084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3084,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3084.png",
  },
  "3085": {
    name: "tiny dinos winter 2022 #3085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3085,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3085.png",
  },
  "3086": {
    name: "tiny dinos winter 2022 #3086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3086,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3086.png",
  },
  "3087": {
    name: "tiny dinos winter 2022 #3087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3087,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3087.png",
  },
  "3088": {
    name: "tiny dinos winter 2022 #3088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3088,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3088.png",
  },
  "3089": {
    name: "tiny dinos winter 2022 #3089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3089,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3089.png",
  },
  "3090": {
    name: "tiny dinos winter 2022 #3090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3090,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3090.png",
  },
  "3091": {
    name: "tiny dinos winter 2022 #3091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3091,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3091.png",
  },
  "3092": {
    name: "tiny dinos winter 2022 #3092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3092,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3092.png",
  },
  "3093": {
    name: "tiny dinos winter 2022 #3093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3093,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3093.png",
  },
  "3094": {
    name: "tiny dinos winter 2022 #3094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3094,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3094.png",
  },
  "3095": {
    name: "tiny dinos winter 2022 #3095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3095,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3095.png",
  },
  "3096": {
    name: "tiny dinos winter 2022 #3096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3096,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3096.png",
  },
  "3097": {
    name: "tiny dinos winter 2022 #3097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3097,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3097.png",
  },
  "3098": {
    name: "tiny dinos winter 2022 #3098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3098,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3098.png",
  },
  "3099": {
    name: "tiny dinos winter 2022 #3099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3099,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3099.png",
  },
  "3100": {
    name: "tiny dinos winter 2022 #3100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3100,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3100.png",
  },
  "3101": {
    name: "tiny dinos winter 2022 #3101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3101,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3101.png",
  },
  "3102": {
    name: "tiny dinos winter 2022 #3102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3102,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3102.png",
  },
  "3103": {
    name: "tiny dinos winter 2022 #3103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3103,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3103.png",
  },
  "3104": {
    name: "tiny dinos winter 2022 #3104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3104,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3104.png",
  },
  "3105": {
    name: "tiny dinos winter 2022 #3105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3105,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3105.png",
  },
  "3106": {
    name: "tiny dinos winter 2022 #3106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3106,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3106.png",
  },
  "3107": {
    name: "tiny dinos winter 2022 #3107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3107,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3107.png",
  },
  "3108": {
    name: "tiny dinos winter 2022 #3108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3108,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3108.png",
  },
  "3109": {
    name: "tiny dinos winter 2022 #3109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3109,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3109.png",
  },
  "3110": {
    name: "tiny dinos winter 2022 #3110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3110,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3110.png",
  },
  "3111": {
    name: "tiny dinos winter 2022 #3111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3111,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3111.png",
  },
  "3112": {
    name: "tiny dinos winter 2022 #3112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3112,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3112.png",
  },
  "3113": {
    name: "tiny dinos winter 2022 #3113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3113,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3113.png",
  },
  "3114": {
    name: "tiny dinos winter 2022 #3114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3114,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3114.png",
  },
  "3115": {
    name: "tiny dinos winter 2022 #3115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3115,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3115.png",
  },
  "3116": {
    name: "tiny dinos winter 2022 #3116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3116,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3116.png",
  },
  "3117": {
    name: "tiny dinos winter 2022 #3117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3117,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3117.png",
  },
  "3118": {
    name: "tiny dinos winter 2022 #3118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3118,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3118.png",
  },
  "3119": {
    name: "tiny dinos winter 2022 #3119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3119,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3119.png",
  },
  "3120": {
    name: "tiny dinos winter 2022 #3120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3120,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3120.png",
  },
  "3121": {
    name: "tiny dinos winter 2022 #3121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3121,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3121.png",
  },
  "3122": {
    name: "tiny dinos winter 2022 #3122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3122,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3122.png",
  },
  "3123": {
    name: "tiny dinos winter 2022 #3123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3123,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3123.png",
  },
  "3124": {
    name: "tiny dinos winter 2022 #3124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3124,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3124.png",
  },
  "3125": {
    name: "tiny dinos winter 2022 #3125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3125,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3125.png",
  },
  "3126": {
    name: "tiny dinos winter 2022 #3126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3126,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3126.png",
  },
  "3127": {
    name: "tiny dinos winter 2022 #3127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3127,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3127.png",
  },
  "3128": {
    name: "tiny dinos winter 2022 #3128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3128,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3128.png",
  },
  "3129": {
    name: "tiny dinos winter 2022 #3129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3129,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3129.png",
  },
  "3130": {
    name: "tiny dinos winter 2022 #3130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3130,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3130.png",
  },
  "3131": {
    name: "tiny dinos winter 2022 #3131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3131,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3131.png",
  },
  "3132": {
    name: "tiny dinos winter 2022 #3132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3132,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3132.png",
  },
  "3133": {
    name: "tiny dinos winter 2022 #3133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3133,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3133.png",
  },
  "3134": {
    name: "tiny dinos winter 2022 #3134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3134,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3134.png",
  },
  "3135": {
    name: "tiny dinos winter 2022 #3135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3135,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3135.png",
  },
  "3136": {
    name: "tiny dinos winter 2022 #3136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3136,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3136.png",
  },
  "3137": {
    name: "tiny dinos winter 2022 #3137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3137,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3137.png",
  },
  "3138": {
    name: "tiny dinos winter 2022 #3138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3138,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3138.png",
  },
  "3139": {
    name: "tiny dinos winter 2022 #3139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3139,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3139.png",
  },
  "3140": {
    name: "tiny dinos winter 2022 #3140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3140,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3140.png",
  },
  "3141": {
    name: "tiny dinos winter 2022 #3141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3141,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3141.png",
  },
  "3142": {
    name: "tiny dinos winter 2022 #3142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3142,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3142.png",
  },
  "3143": {
    name: "tiny dinos winter 2022 #3143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3143,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3143.png",
  },
  "3144": {
    name: "tiny dinos winter 2022 #3144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3144,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3144.png",
  },
  "3145": {
    name: "tiny dinos winter 2022 #3145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3145,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3145.png",
  },
  "3146": {
    name: "tiny dinos winter 2022 #3146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3146,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3146.png",
  },
  "3147": {
    name: "tiny dinos winter 2022 #3147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3147,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3147.png",
  },
  "3148": {
    name: "tiny dinos winter 2022 #3148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3148,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3148.png",
  },
  "3149": {
    name: "tiny dinos winter 2022 #3149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3149,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3149.png",
  },
  "3150": {
    name: "tiny dinos winter 2022 #3150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3150,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3150.png",
  },
  "3151": {
    name: "tiny dinos winter 2022 #3151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3151,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3151.png",
  },
  "3152": {
    name: "tiny dinos winter 2022 #3152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3152,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3152.png",
  },
  "3153": {
    name: "tiny dinos winter 2022 #3153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3153,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3153.png",
  },
  "3154": {
    name: "tiny dinos winter 2022 #3154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3154,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3154.png",
  },
  "3155": {
    name: "tiny dinos winter 2022 #3155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3155,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3155.png",
  },
  "3156": {
    name: "tiny dinos winter 2022 #3156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3156,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3156.png",
  },
  "3157": {
    name: "tiny dinos winter 2022 #3157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3157,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3157.png",
  },
  "3158": {
    name: "tiny dinos winter 2022 #3158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3158,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3158.png",
  },
  "3159": {
    name: "tiny dinos winter 2022 #3159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3159,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3159.png",
  },
  "3160": {
    name: "tiny dinos winter 2022 #3160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3160,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3160.png",
  },
  "3161": {
    name: "tiny dinos winter 2022 #3161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3161,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3161.png",
  },
  "3162": {
    name: "tiny dinos winter 2022 #3162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3162,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3162.png",
  },
  "3163": {
    name: "tiny dinos winter 2022 #3163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3163,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3163.png",
  },
  "3164": {
    name: "tiny dinos winter 2022 #3164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3164,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3164.png",
  },
  "3165": {
    name: "tiny dinos winter 2022 #3165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3165,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3165.png",
  },
  "3166": {
    name: "tiny dinos winter 2022 #3166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3166,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3166.png",
  },
  "3167": {
    name: "tiny dinos winter 2022 #3167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3167,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3167.png",
  },
  "3168": {
    name: "tiny dinos winter 2022 #3168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3168,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3168.png",
  },
  "3169": {
    name: "tiny dinos winter 2022 #3169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3169,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3169.png",
  },
  "3170": {
    name: "tiny dinos winter 2022 #3170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3170,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3170.png",
  },
  "3171": {
    name: "tiny dinos winter 2022 #3171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3171,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3171.png",
  },
  "3172": {
    name: "tiny dinos winter 2022 #3172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3172,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3172.png",
  },
  "3173": {
    name: "tiny dinos winter 2022 #3173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3173,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3173.png",
  },
  "3174": {
    name: "tiny dinos winter 2022 #3174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3174,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3174.png",
  },
  "3175": {
    name: "tiny dinos winter 2022 #3175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3175,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3175.png",
  },
  "3176": {
    name: "tiny dinos winter 2022 #3176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3176,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3176.png",
  },
  "3177": {
    name: "tiny dinos winter 2022 #3177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3177,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3177.png",
  },
  "3178": {
    name: "tiny dinos winter 2022 #3178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3178,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3178.png",
  },
  "3179": {
    name: "tiny dinos winter 2022 #3179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3179,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3179.png",
  },
  "3180": {
    name: "tiny dinos winter 2022 #3180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3180,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3180.png",
  },
  "3181": {
    name: "tiny dinos winter 2022 #3181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3181,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3181.png",
  },
  "3182": {
    name: "tiny dinos winter 2022 #3182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3182,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3182.png",
  },
  "3183": {
    name: "tiny dinos winter 2022 #3183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3183,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3183.png",
  },
  "3184": {
    name: "tiny dinos winter 2022 #3184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3184,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3184.png",
  },
  "3185": {
    name: "tiny dinos winter 2022 #3185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3185,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3185.png",
  },
  "3186": {
    name: "tiny dinos winter 2022 #3186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3186,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3186.png",
  },
  "3187": {
    name: "tiny dinos winter 2022 #3187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3187,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3187.png",
  },
  "3188": {
    name: "tiny dinos winter 2022 #3188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3188,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3188.png",
  },
  "3189": {
    name: "tiny dinos winter 2022 #3189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3189,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3189.png",
  },
  "3190": {
    name: "tiny dinos winter 2022 #3190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3190,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3190.png",
  },
  "3191": {
    name: "tiny dinos winter 2022 #3191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3191,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3191.png",
  },
  "3192": {
    name: "tiny dinos winter 2022 #3192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3192,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3192.png",
  },
  "3193": {
    name: "tiny dinos winter 2022 #3193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3193,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3193.png",
  },
  "3194": {
    name: "tiny dinos winter 2022 #3194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3194,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3194.png",
  },
  "3195": {
    name: "tiny dinos winter 2022 #3195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3195,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3195.png",
  },
  "3196": {
    name: "tiny dinos winter 2022 #3196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3196,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3196.png",
  },
  "3197": {
    name: "tiny dinos winter 2022 #3197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3197,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3197.png",
  },
  "3198": {
    name: "tiny dinos winter 2022 #3198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3198,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3198.png",
  },
  "3199": {
    name: "tiny dinos winter 2022 #3199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3199,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3199.png",
  },
  "3200": {
    name: "tiny dinos winter 2022 #3200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3200,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3200.png",
  },
  "3201": {
    name: "tiny dinos winter 2022 #3201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3201,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3201.png",
  },
  "3202": {
    name: "tiny dinos winter 2022 #3202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3202,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3202.png",
  },
  "3203": {
    name: "tiny dinos winter 2022 #3203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3203,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3203.png",
  },
  "3204": {
    name: "tiny dinos winter 2022 #3204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3204,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3204.png",
  },
  "3205": {
    name: "tiny dinos winter 2022 #3205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3205,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3205.png",
  },
  "3206": {
    name: "tiny dinos winter 2022 #3206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3206,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3206.png",
  },
  "3207": {
    name: "tiny dinos winter 2022 #3207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3207,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3207.png",
  },
  "3208": {
    name: "tiny dinos winter 2022 #3208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3208,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3208.png",
  },
  "3209": {
    name: "tiny dinos winter 2022 #3209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3209,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3209.png",
  },
  "3210": {
    name: "tiny dinos winter 2022 #3210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3210,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3210.png",
  },
  "3211": {
    name: "tiny dinos winter 2022 #3211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3211,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3211.png",
  },
  "3212": {
    name: "tiny dinos winter 2022 #3212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3212,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3212.png",
  },
  "3213": {
    name: "tiny dinos winter 2022 #3213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3213,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3213.png",
  },
  "3214": {
    name: "tiny dinos winter 2022 #3214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3214,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3214.png",
  },
  "3215": {
    name: "tiny dinos winter 2022 #3215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3215,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3215.png",
  },
  "3216": {
    name: "tiny dinos winter 2022 #3216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3216,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3216.png",
  },
  "3217": {
    name: "tiny dinos winter 2022 #3217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3217,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3217.png",
  },
  "3218": {
    name: "tiny dinos winter 2022 #3218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3218,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3218.png",
  },
  "3219": {
    name: "tiny dinos winter 2022 #3219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3219,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3219.png",
  },
  "3220": {
    name: "tiny dinos winter 2022 #3220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3220,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3220.png",
  },
  "3221": {
    name: "tiny dinos winter 2022 #3221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3221,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3221.png",
  },
  "3222": {
    name: "tiny dinos winter 2022 #3222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3222,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3222.png",
  },
  "3223": {
    name: "tiny dinos winter 2022 #3223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3223,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3223.png",
  },
  "3224": {
    name: "tiny dinos winter 2022 #3224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3224,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3224.png",
  },
  "3225": {
    name: "tiny dinos winter 2022 #3225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3225,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3225.png",
  },
  "3226": {
    name: "tiny dinos winter 2022 #3226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3226,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3226.png",
  },
  "3227": {
    name: "tiny dinos winter 2022 #3227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3227,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3227.png",
  },
  "3228": {
    name: "tiny dinos winter 2022 #3228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3228,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3228.png",
  },
  "3229": {
    name: "tiny dinos winter 2022 #3229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3229,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3229.png",
  },
  "3230": {
    name: "tiny dinos winter 2022 #3230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3230,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3230.png",
  },
  "3231": {
    name: "tiny dinos winter 2022 #3231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3231,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3231.png",
  },
  "3232": {
    name: "tiny dinos winter 2022 #3232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3232,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3232.png",
  },
  "3233": {
    name: "tiny dinos winter 2022 #3233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3233,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3233.png",
  },
  "3234": {
    name: "tiny dinos winter 2022 #3234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3234,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3234.png",
  },
  "3235": {
    name: "tiny dinos winter 2022 #3235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3235,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3235.png",
  },
  "3236": {
    name: "tiny dinos winter 2022 #3236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3236,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3236.png",
  },
  "3237": {
    name: "tiny dinos winter 2022 #3237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3237,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3237.png",
  },
  "3238": {
    name: "tiny dinos winter 2022 #3238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3238,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3238.png",
  },
  "3239": {
    name: "tiny dinos winter 2022 #3239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3239,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3239.png",
  },
  "3240": {
    name: "tiny dinos winter 2022 #3240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3240,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3240.png",
  },
  "3241": {
    name: "tiny dinos winter 2022 #3241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3241,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3241.png",
  },
  "3242": {
    name: "tiny dinos winter 2022 #3242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3242,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3242.png",
  },
  "3243": {
    name: "tiny dinos winter 2022 #3243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3243,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3243.png",
  },
  "3244": {
    name: "tiny dinos winter 2022 #3244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3244,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3244.png",
  },
  "3245": {
    name: "tiny dinos winter 2022 #3245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3245,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3245.png",
  },
  "3246": {
    name: "tiny dinos winter 2022 #3246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3246,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3246.png",
  },
  "3247": {
    name: "tiny dinos winter 2022 #3247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3247,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3247.png",
  },
  "3248": {
    name: "tiny dinos winter 2022 #3248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3248,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3248.png",
  },
  "3249": {
    name: "tiny dinos winter 2022 #3249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3249,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3249.png",
  },
  "3250": {
    name: "tiny dinos winter 2022 #3250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3250,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3250.png",
  },
  "3251": {
    name: "tiny dinos winter 2022 #3251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3251,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3251.png",
  },
  "3252": {
    name: "tiny dinos winter 2022 #3252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3252,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3252.png",
  },
  "3253": {
    name: "tiny dinos winter 2022 #3253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3253,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3253.png",
  },
  "3254": {
    name: "tiny dinos winter 2022 #3254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3254,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3254.png",
  },
  "3255": {
    name: "tiny dinos winter 2022 #3255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3255,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3255.png",
  },
  "3256": {
    name: "tiny dinos winter 2022 #3256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3256,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3256.png",
  },
  "3257": {
    name: "tiny dinos winter 2022 #3257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3257,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3257.png",
  },
  "3258": {
    name: "tiny dinos winter 2022 #3258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3258,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3258.png",
  },
  "3259": {
    name: "tiny dinos winter 2022 #3259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3259,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3259.png",
  },
  "3260": {
    name: "tiny dinos winter 2022 #3260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3260,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3260.png",
  },
  "3261": {
    name: "tiny dinos winter 2022 #3261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3261,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3261.png",
  },
  "3262": {
    name: "tiny dinos winter 2022 #3262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3262,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3262.png",
  },
  "3263": {
    name: "tiny dinos winter 2022 #3263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3263,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3263.png",
  },
  "3264": {
    name: "tiny dinos winter 2022 #3264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3264,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3264.png",
  },
  "3265": {
    name: "tiny dinos winter 2022 #3265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3265,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3265.png",
  },
  "3266": {
    name: "tiny dinos winter 2022 #3266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3266,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3266.png",
  },
  "3267": {
    name: "tiny dinos winter 2022 #3267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3267,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3267.png",
  },
  "3268": {
    name: "tiny dinos winter 2022 #3268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3268,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3268.png",
  },
  "3269": {
    name: "tiny dinos winter 2022 #3269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3269,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3269.png",
  },
  "3270": {
    name: "tiny dinos winter 2022 #3270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3270,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3270.png",
  },
  "3271": {
    name: "tiny dinos winter 2022 #3271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3271,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3271.png",
  },
  "3272": {
    name: "tiny dinos winter 2022 #3272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3272,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3272.png",
  },
  "3273": {
    name: "tiny dinos winter 2022 #3273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3273,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3273.png",
  },
  "3274": {
    name: "tiny dinos winter 2022 #3274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3274,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3274.png",
  },
  "3275": {
    name: "tiny dinos winter 2022 #3275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3275,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3275.png",
  },
  "3276": {
    name: "tiny dinos winter 2022 #3276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3276,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3276.png",
  },
  "3277": {
    name: "tiny dinos winter 2022 #3277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3277,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3277.png",
  },
  "3278": {
    name: "tiny dinos winter 2022 #3278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3278,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3278.png",
  },
  "3279": {
    name: "tiny dinos winter 2022 #3279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3279,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3279.png",
  },
  "3280": {
    name: "tiny dinos winter 2022 #3280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3280,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3280.png",
  },
  "3281": {
    name: "tiny dinos winter 2022 #3281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3281,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3281.png",
  },
  "3282": {
    name: "tiny dinos winter 2022 #3282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3282,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3282.png",
  },
  "3283": {
    name: "tiny dinos winter 2022 #3283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3283,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3283.png",
  },
  "3284": {
    name: "tiny dinos winter 2022 #3284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3284,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3284.png",
  },
  "3285": {
    name: "tiny dinos winter 2022 #3285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3285,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3285.png",
  },
  "3286": {
    name: "tiny dinos winter 2022 #3286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3286,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3286.png",
  },
  "3287": {
    name: "tiny dinos winter 2022 #3287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3287,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3287.png",
  },
  "3288": {
    name: "tiny dinos winter 2022 #3288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3288,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3288.png",
  },
  "3289": {
    name: "tiny dinos winter 2022 #3289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3289,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3289.png",
  },
  "3290": {
    name: "tiny dinos winter 2022 #3290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3290,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3290.png",
  },
  "3291": {
    name: "tiny dinos winter 2022 #3291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3291,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3291.png",
  },
  "3292": {
    name: "tiny dinos winter 2022 #3292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3292,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3292.png",
  },
  "3293": {
    name: "tiny dinos winter 2022 #3293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3293,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3293.png",
  },
  "3294": {
    name: "tiny dinos winter 2022 #3294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3294,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3294.png",
  },
  "3295": {
    name: "tiny dinos winter 2022 #3295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3295,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3295.png",
  },
  "3296": {
    name: "tiny dinos winter 2022 #3296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3296,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3296.png",
  },
  "3297": {
    name: "tiny dinos winter 2022 #3297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3297,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3297.png",
  },
  "3298": {
    name: "tiny dinos winter 2022 #3298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3298,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3298.png",
  },
  "3299": {
    name: "tiny dinos winter 2022 #3299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3299,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3299.png",
  },
  "3300": {
    name: "tiny dinos winter 2022 #3300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3300,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3300.png",
  },
  "3301": {
    name: "tiny dinos winter 2022 #3301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3301,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3301.png",
  },
  "3302": {
    name: "tiny dinos winter 2022 #3302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3302,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3302.png",
  },
  "3303": {
    name: "tiny dinos winter 2022 #3303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3303,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3303.png",
  },
  "3304": {
    name: "tiny dinos winter 2022 #3304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3304,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3304.png",
  },
  "3305": {
    name: "tiny dinos winter 2022 #3305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3305,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3305.png",
  },
  "3306": {
    name: "tiny dinos winter 2022 #3306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3306,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3306.png",
  },
  "3307": {
    name: "tiny dinos winter 2022 #3307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3307,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3307.png",
  },
  "3308": {
    name: "tiny dinos winter 2022 #3308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3308,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3308.png",
  },
  "3309": {
    name: "tiny dinos winter 2022 #3309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3309,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3309.png",
  },
  "3310": {
    name: "tiny dinos winter 2022 #3310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3310,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3310.png",
  },
  "3311": {
    name: "tiny dinos winter 2022 #3311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3311,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3311.png",
  },
  "3312": {
    name: "tiny dinos winter 2022 #3312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3312,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3312.png",
  },
  "3313": {
    name: "tiny dinos winter 2022 #3313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3313,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3313.png",
  },
  "3314": {
    name: "tiny dinos winter 2022 #3314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3314,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3314.png",
  },
  "3315": {
    name: "tiny dinos winter 2022 #3315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3315,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3315.png",
  },
  "3316": {
    name: "tiny dinos winter 2022 #3316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3316,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3316.png",
  },
  "3317": {
    name: "tiny dinos winter 2022 #3317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3317,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3317.png",
  },
  "3318": {
    name: "tiny dinos winter 2022 #3318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3318,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3318.png",
  },
  "3319": {
    name: "tiny dinos winter 2022 #3319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3319,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3319.png",
  },
  "3320": {
    name: "tiny dinos winter 2022 #3320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3320,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3320.png",
  },
  "3321": {
    name: "tiny dinos winter 2022 #3321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3321,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3321.png",
  },
  "3322": {
    name: "tiny dinos winter 2022 #3322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3322,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3322.png",
  },
  "3323": {
    name: "tiny dinos winter 2022 #3323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3323,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3323.png",
  },
  "3324": {
    name: "tiny dinos winter 2022 #3324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3324,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3324.png",
  },
  "3325": {
    name: "tiny dinos winter 2022 #3325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3325,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3325.png",
  },
  "3326": {
    name: "tiny dinos winter 2022 #3326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3326,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3326.png",
  },
  "3327": {
    name: "tiny dinos winter 2022 #3327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3327,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3327.png",
  },
  "3328": {
    name: "tiny dinos winter 2022 #3328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3328,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3328.png",
  },
  "3329": {
    name: "tiny dinos winter 2022 #3329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3329,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3329.png",
  },
  "3330": {
    name: "tiny dinos winter 2022 #3330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3330,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3330.png",
  },
  "3331": {
    name: "tiny dinos winter 2022 #3331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3331,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3331.png",
  },
  "3332": {
    name: "tiny dinos winter 2022 #3332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3332,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3332.png",
  },
  "3333": {
    name: "tiny dinos winter 2022 #3333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3333,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3333.png",
  },
  "3334": {
    name: "tiny dinos winter 2022 #3334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3334,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3334.png",
  },
  "3335": {
    name: "tiny dinos winter 2022 #3335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3335,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3335.png",
  },
  "3336": {
    name: "tiny dinos winter 2022 #3336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3336,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3336.png",
  },
  "3337": {
    name: "tiny dinos winter 2022 #3337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3337,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3337.png",
  },
  "3338": {
    name: "tiny dinos winter 2022 #3338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3338,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3338.png",
  },
  "3339": {
    name: "tiny dinos winter 2022 #3339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3339,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3339.png",
  },
  "3340": {
    name: "tiny dinos winter 2022 #3340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3340,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3340.png",
  },
  "3341": {
    name: "tiny dinos winter 2022 #3341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3341,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3341.png",
  },
  "3342": {
    name: "tiny dinos winter 2022 #3342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3342,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3342.png",
  },
  "3343": {
    name: "tiny dinos winter 2022 #3343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3343,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3343.png",
  },
  "3344": {
    name: "tiny dinos winter 2022 #3344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3344,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3344.png",
  },
  "3345": {
    name: "tiny dinos winter 2022 #3345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3345,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3345.png",
  },
  "3346": {
    name: "tiny dinos winter 2022 #3346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3346,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3346.png",
  },
  "3347": {
    name: "tiny dinos winter 2022 #3347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3347,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3347.png",
  },
  "3348": {
    name: "tiny dinos winter 2022 #3348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3348,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3348.png",
  },
  "3349": {
    name: "tiny dinos winter 2022 #3349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3349,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3349.png",
  },
  "3350": {
    name: "tiny dinos winter 2022 #3350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3350,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3350.png",
  },
  "3351": {
    name: "tiny dinos winter 2022 #3351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3351,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3351.png",
  },
  "3352": {
    name: "tiny dinos winter 2022 #3352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3352,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3352.png",
  },
  "3353": {
    name: "tiny dinos winter 2022 #3353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3353,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3353.png",
  },
  "3354": {
    name: "tiny dinos winter 2022 #3354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3354,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3354.png",
  },
  "3355": {
    name: "tiny dinos winter 2022 #3355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3355,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3355.png",
  },
  "3356": {
    name: "tiny dinos winter 2022 #3356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3356,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3356.png",
  },
  "3357": {
    name: "tiny dinos winter 2022 #3357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3357,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3357.png",
  },
  "3358": {
    name: "tiny dinos winter 2022 #3358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3358,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3358.png",
  },
  "3359": {
    name: "tiny dinos winter 2022 #3359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3359,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3359.png",
  },
  "3360": {
    name: "tiny dinos winter 2022 #3360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3360,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3360.png",
  },
  "3361": {
    name: "tiny dinos winter 2022 #3361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3361,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3361.png",
  },
  "3362": {
    name: "tiny dinos winter 2022 #3362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3362,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3362.png",
  },
  "3363": {
    name: "tiny dinos winter 2022 #3363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3363,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3363.png",
  },
  "3364": {
    name: "tiny dinos winter 2022 #3364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3364,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3364.png",
  },
  "3365": {
    name: "tiny dinos winter 2022 #3365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3365,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3365.png",
  },
  "3366": {
    name: "tiny dinos winter 2022 #3366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3366,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3366.png",
  },
  "3367": {
    name: "tiny dinos winter 2022 #3367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3367,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3367.png",
  },
  "3368": {
    name: "tiny dinos winter 2022 #3368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3368,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3368.png",
  },
  "3369": {
    name: "tiny dinos winter 2022 #3369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3369,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3369.png",
  },
  "3370": {
    name: "tiny dinos winter 2022 #3370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3370,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3370.png",
  },
  "3371": {
    name: "tiny dinos winter 2022 #3371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3371,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3371.png",
  },
  "3372": {
    name: "tiny dinos winter 2022 #3372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3372,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3372.png",
  },
  "3373": {
    name: "tiny dinos winter 2022 #3373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3373,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3373.png",
  },
  "3374": {
    name: "tiny dinos winter 2022 #3374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3374,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3374.png",
  },
  "3375": {
    name: "tiny dinos winter 2022 #3375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3375,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3375.png",
  },
  "3376": {
    name: "tiny dinos winter 2022 #3376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3376,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3376.png",
  },
  "3377": {
    name: "tiny dinos winter 2022 #3377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3377,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3377.png",
  },
  "3378": {
    name: "tiny dinos winter 2022 #3378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3378,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3378.png",
  },
  "3379": {
    name: "tiny dinos winter 2022 #3379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3379,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3379.png",
  },
  "3380": {
    name: "tiny dinos winter 2022 #3380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3380,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3380.png",
  },
  "3381": {
    name: "tiny dinos winter 2022 #3381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3381,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3381.png",
  },
  "3382": {
    name: "tiny dinos winter 2022 #3382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3382,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3382.png",
  },
  "3383": {
    name: "tiny dinos winter 2022 #3383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3383,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3383.png",
  },
  "3384": {
    name: "tiny dinos winter 2022 #3384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3384,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3384.png",
  },
  "3385": {
    name: "tiny dinos winter 2022 #3385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3385,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3385.png",
  },
  "3386": {
    name: "tiny dinos winter 2022 #3386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3386,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3386.png",
  },
  "3387": {
    name: "tiny dinos winter 2022 #3387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3387,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3387.png",
  },
  "3388": {
    name: "tiny dinos winter 2022 #3388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3388,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3388.png",
  },
  "3389": {
    name: "tiny dinos winter 2022 #3389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3389,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3389.png",
  },
  "3390": {
    name: "tiny dinos winter 2022 #3390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3390,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3390.png",
  },
  "3391": {
    name: "tiny dinos winter 2022 #3391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3391,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3391.png",
  },
  "3392": {
    name: "tiny dinos winter 2022 #3392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3392,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3392.png",
  },
  "3393": {
    name: "tiny dinos winter 2022 #3393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3393,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3393.png",
  },
  "3394": {
    name: "tiny dinos winter 2022 #3394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3394,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3394.png",
  },
  "3395": {
    name: "tiny dinos winter 2022 #3395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3395,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3395.png",
  },
  "3396": {
    name: "tiny dinos winter 2022 #3396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3396,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3396.png",
  },
  "3397": {
    name: "tiny dinos winter 2022 #3397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3397,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3397.png",
  },
  "3398": {
    name: "tiny dinos winter 2022 #3398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3398,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3398.png",
  },
  "3399": {
    name: "tiny dinos winter 2022 #3399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3399,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3399.png",
  },
  "3400": {
    name: "tiny dinos winter 2022 #3400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3400,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3400.png",
  },
  "3401": {
    name: "tiny dinos winter 2022 #3401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3401,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3401.png",
  },
  "3402": {
    name: "tiny dinos winter 2022 #3402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3402,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3402.png",
  },
  "3403": {
    name: "tiny dinos winter 2022 #3403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3403,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3403.png",
  },
  "3404": {
    name: "tiny dinos winter 2022 #3404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3404,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3404.png",
  },
  "3405": {
    name: "tiny dinos winter 2022 #3405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3405,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3405.png",
  },
  "3406": {
    name: "tiny dinos winter 2022 #3406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3406,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3406.png",
  },
  "3407": {
    name: "tiny dinos winter 2022 #3407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3407,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3407.png",
  },
  "3408": {
    name: "tiny dinos winter 2022 #3408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3408,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3408.png",
  },
  "3409": {
    name: "tiny dinos winter 2022 #3409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3409,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3409.png",
  },
  "3410": {
    name: "tiny dinos winter 2022 #3410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3410,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3410.png",
  },
  "3411": {
    name: "tiny dinos winter 2022 #3411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3411,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3411.png",
  },
  "3412": {
    name: "tiny dinos winter 2022 #3412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3412,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3412.png",
  },
  "3413": {
    name: "tiny dinos winter 2022 #3413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3413,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3413.png",
  },
  "3414": {
    name: "tiny dinos winter 2022 #3414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3414,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3414.png",
  },
  "3415": {
    name: "tiny dinos winter 2022 #3415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3415,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3415.png",
  },
  "3416": {
    name: "tiny dinos winter 2022 #3416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3416,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3416.png",
  },
  "3417": {
    name: "tiny dinos winter 2022 #3417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3417,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3417.png",
  },
  "3418": {
    name: "tiny dinos winter 2022 #3418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3418,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3418.png",
  },
  "3419": {
    name: "tiny dinos winter 2022 #3419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3419,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3419.png",
  },
  "3420": {
    name: "tiny dinos winter 2022 #3420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3420,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3420.png",
  },
  "3421": {
    name: "tiny dinos winter 2022 #3421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3421,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3421.png",
  },
  "3422": {
    name: "tiny dinos winter 2022 #3422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3422,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3422.png",
  },
  "3423": {
    name: "tiny dinos winter 2022 #3423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3423,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3423.png",
  },
  "3424": {
    name: "tiny dinos winter 2022 #3424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3424,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3424.png",
  },
  "3425": {
    name: "tiny dinos winter 2022 #3425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3425,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3425.png",
  },
  "3426": {
    name: "tiny dinos winter 2022 #3426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3426,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3426.png",
  },
  "3427": {
    name: "tiny dinos winter 2022 #3427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3427,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3427.png",
  },
  "3428": {
    name: "tiny dinos winter 2022 #3428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3428,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3428.png",
  },
  "3429": {
    name: "tiny dinos winter 2022 #3429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3429,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3429.png",
  },
  "3430": {
    name: "tiny dinos winter 2022 #3430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3430,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3430.png",
  },
  "3431": {
    name: "tiny dinos winter 2022 #3431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3431,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3431.png",
  },
  "3432": {
    name: "tiny dinos winter 2022 #3432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3432,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3432.png",
  },
  "3433": {
    name: "tiny dinos winter 2022 #3433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3433,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3433.png",
  },
  "3434": {
    name: "tiny dinos winter 2022 #3434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3434,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3434.png",
  },
  "3435": {
    name: "tiny dinos winter 2022 #3435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3435,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3435.png",
  },
  "3436": {
    name: "tiny dinos winter 2022 #3436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3436,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3436.png",
  },
  "3437": {
    name: "tiny dinos winter 2022 #3437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3437,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3437.png",
  },
  "3438": {
    name: "tiny dinos winter 2022 #3438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3438,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3438.png",
  },
  "3439": {
    name: "tiny dinos winter 2022 #3439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3439,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3439.png",
  },
  "3440": {
    name: "tiny dinos winter 2022 #3440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3440,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3440.png",
  },
  "3441": {
    name: "tiny dinos winter 2022 #3441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3441,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3441.png",
  },
  "3442": {
    name: "tiny dinos winter 2022 #3442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3442,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3442.png",
  },
  "3443": {
    name: "tiny dinos winter 2022 #3443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3443,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3443.png",
  },
  "3444": {
    name: "tiny dinos winter 2022 #3444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3444,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3444.png",
  },
  "3445": {
    name: "tiny dinos winter 2022 #3445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3445,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3445.png",
  },
  "3446": {
    name: "tiny dinos winter 2022 #3446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3446,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3446.png",
  },
  "3447": {
    name: "tiny dinos winter 2022 #3447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3447,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3447.png",
  },
  "3448": {
    name: "tiny dinos winter 2022 #3448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3448,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3448.png",
  },
  "3449": {
    name: "tiny dinos winter 2022 #3449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3449,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3449.png",
  },
  "3450": {
    name: "tiny dinos winter 2022 #3450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3450,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3450.png",
  },
  "3451": {
    name: "tiny dinos winter 2022 #3451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3451,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3451.png",
  },
  "3452": {
    name: "tiny dinos winter 2022 #3452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3452,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3452.png",
  },
  "3453": {
    name: "tiny dinos winter 2022 #3453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3453,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3453.png",
  },
  "3454": {
    name: "tiny dinos winter 2022 #3454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3454,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3454.png",
  },
  "3455": {
    name: "tiny dinos winter 2022 #3455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3455,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3455.png",
  },
  "3456": {
    name: "tiny dinos winter 2022 #3456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3456,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3456.png",
  },
  "3457": {
    name: "tiny dinos winter 2022 #3457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3457,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3457.png",
  },
  "3458": {
    name: "tiny dinos winter 2022 #3458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3458,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3458.png",
  },
  "3459": {
    name: "tiny dinos winter 2022 #3459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3459,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3459.png",
  },
  "3460": {
    name: "tiny dinos winter 2022 #3460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3460,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3460.png",
  },
  "3461": {
    name: "tiny dinos winter 2022 #3461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3461,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3461.png",
  },
  "3462": {
    name: "tiny dinos winter 2022 #3462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3462,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3462.png",
  },
  "3463": {
    name: "tiny dinos winter 2022 #3463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3463,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3463.png",
  },
  "3464": {
    name: "tiny dinos winter 2022 #3464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3464,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3464.png",
  },
  "3465": {
    name: "tiny dinos winter 2022 #3465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3465,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3465.png",
  },
  "3466": {
    name: "tiny dinos winter 2022 #3466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3466,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3466.png",
  },
  "3467": {
    name: "tiny dinos winter 2022 #3467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3467,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3467.png",
  },
  "3468": {
    name: "tiny dinos winter 2022 #3468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3468,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3468.png",
  },
  "3469": {
    name: "tiny dinos winter 2022 #3469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3469,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3469.png",
  },
  "3470": {
    name: "tiny dinos winter 2022 #3470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3470,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3470.png",
  },
  "3471": {
    name: "tiny dinos winter 2022 #3471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3471,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3471.png",
  },
  "3472": {
    name: "tiny dinos winter 2022 #3472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3472,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3472.png",
  },
  "3473": {
    name: "tiny dinos winter 2022 #3473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3473,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3473.png",
  },
  "3474": {
    name: "tiny dinos winter 2022 #3474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3474,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3474.png",
  },
  "3475": {
    name: "tiny dinos winter 2022 #3475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3475,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3475.png",
  },
  "3476": {
    name: "tiny dinos winter 2022 #3476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3476,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3476.png",
  },
  "3477": {
    name: "tiny dinos winter 2022 #3477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3477,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3477.png",
  },
  "3478": {
    name: "tiny dinos winter 2022 #3478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3478,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3478.png",
  },
  "3479": {
    name: "tiny dinos winter 2022 #3479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3479,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3479.png",
  },
  "3480": {
    name: "tiny dinos winter 2022 #3480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3480,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3480.png",
  },
  "3481": {
    name: "tiny dinos winter 2022 #3481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3481,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3481.png",
  },
  "3482": {
    name: "tiny dinos winter 2022 #3482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3482,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3482.png",
  },
  "3483": {
    name: "tiny dinos winter 2022 #3483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3483,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3483.png",
  },
  "3484": {
    name: "tiny dinos winter 2022 #3484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3484,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3484.png",
  },
  "3485": {
    name: "tiny dinos winter 2022 #3485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3485,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3485.png",
  },
  "3486": {
    name: "tiny dinos winter 2022 #3486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3486,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3486.png",
  },
  "3487": {
    name: "tiny dinos winter 2022 #3487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3487,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3487.png",
  },
  "3488": {
    name: "tiny dinos winter 2022 #3488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3488,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3488.png",
  },
  "3489": {
    name: "tiny dinos winter 2022 #3489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3489,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3489.png",
  },
  "3490": {
    name: "tiny dinos winter 2022 #3490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3490,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3490.png",
  },
  "3491": {
    name: "tiny dinos winter 2022 #3491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3491,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3491.png",
  },
  "3492": {
    name: "tiny dinos winter 2022 #3492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3492,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3492.png",
  },
  "3493": {
    name: "tiny dinos winter 2022 #3493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3493,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3493.png",
  },
  "3494": {
    name: "tiny dinos winter 2022 #3494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3494,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3494.png",
  },
  "3495": {
    name: "tiny dinos winter 2022 #3495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3495,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3495.png",
  },
  "3496": {
    name: "tiny dinos winter 2022 #3496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3496,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3496.png",
  },
  "3497": {
    name: "tiny dinos winter 2022 #3497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3497,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3497.png",
  },
  "3498": {
    name: "tiny dinos winter 2022 #3498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3498,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3498.png",
  },
  "3499": {
    name: "tiny dinos winter 2022 #3499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3499,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3499.png",
  },
  "3500": {
    name: "tiny dinos winter 2022 #3500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3500,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3500.png",
  },
  "3501": {
    name: "tiny dinos winter 2022 #3501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3501,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3501.png",
  },
  "3502": {
    name: "tiny dinos winter 2022 #3502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3502,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3502.png",
  },
  "3503": {
    name: "tiny dinos winter 2022 #3503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3503,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3503.png",
  },
  "3504": {
    name: "tiny dinos winter 2022 #3504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3504,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3504.png",
  },
  "3505": {
    name: "tiny dinos winter 2022 #3505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3505,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3505.png",
  },
  "3506": {
    name: "tiny dinos winter 2022 #3506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3506,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3506.png",
  },
  "3507": {
    name: "tiny dinos winter 2022 #3507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3507,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3507.png",
  },
  "3508": {
    name: "tiny dinos winter 2022 #3508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3508,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3508.png",
  },
  "3509": {
    name: "tiny dinos winter 2022 #3509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3509,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3509.png",
  },
  "3510": {
    name: "tiny dinos winter 2022 #3510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3510,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3510.png",
  },
  "3511": {
    name: "tiny dinos winter 2022 #3511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3511,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3511.png",
  },
  "3512": {
    name: "tiny dinos winter 2022 #3512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3512,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3512.png",
  },
  "3513": {
    name: "tiny dinos winter 2022 #3513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3513,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3513.png",
  },
  "3514": {
    name: "tiny dinos winter 2022 #3514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3514,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3514.png",
  },
  "3515": {
    name: "tiny dinos winter 2022 #3515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3515,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3515.png",
  },
  "3516": {
    name: "tiny dinos winter 2022 #3516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3516,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3516.png",
  },
  "3517": {
    name: "tiny dinos winter 2022 #3517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3517,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3517.png",
  },
  "3518": {
    name: "tiny dinos winter 2022 #3518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3518,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3518.png",
  },
  "3519": {
    name: "tiny dinos winter 2022 #3519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3519,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3519.png",
  },
  "3520": {
    name: "tiny dinos winter 2022 #3520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3520,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3520.png",
  },
  "3521": {
    name: "tiny dinos winter 2022 #3521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3521,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3521.png",
  },
  "3522": {
    name: "tiny dinos winter 2022 #3522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3522,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3522.png",
  },
  "3523": {
    name: "tiny dinos winter 2022 #3523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3523,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3523.png",
  },
  "3524": {
    name: "tiny dinos winter 2022 #3524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3524,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3524.png",
  },
  "3525": {
    name: "tiny dinos winter 2022 #3525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3525,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3525.png",
  },
  "3526": {
    name: "tiny dinos winter 2022 #3526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3526,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3526.png",
  },
  "3527": {
    name: "tiny dinos winter 2022 #3527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3527,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3527.png",
  },
  "3528": {
    name: "tiny dinos winter 2022 #3528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3528,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3528.png",
  },
  "3529": {
    name: "tiny dinos winter 2022 #3529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3529,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3529.png",
  },
  "3530": {
    name: "tiny dinos winter 2022 #3530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3530,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3530.png",
  },
  "3531": {
    name: "tiny dinos winter 2022 #3531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3531,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3531.png",
  },
  "3532": {
    name: "tiny dinos winter 2022 #3532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3532,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3532.png",
  },
  "3533": {
    name: "tiny dinos winter 2022 #3533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3533,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3533.png",
  },
  "3534": {
    name: "tiny dinos winter 2022 #3534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3534,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3534.png",
  },
  "3535": {
    name: "tiny dinos winter 2022 #3535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3535,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3535.png",
  },
  "3536": {
    name: "tiny dinos winter 2022 #3536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3536,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3536.png",
  },
  "3537": {
    name: "tiny dinos winter 2022 #3537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3537,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3537.png",
  },
  "3538": {
    name: "tiny dinos winter 2022 #3538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3538,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3538.png",
  },
  "3539": {
    name: "tiny dinos winter 2022 #3539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3539,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3539.png",
  },
  "3540": {
    name: "tiny dinos winter 2022 #3540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3540,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3540.png",
  },
  "3541": {
    name: "tiny dinos winter 2022 #3541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3541,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3541.png",
  },
  "3542": {
    name: "tiny dinos winter 2022 #3542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3542,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3542.png",
  },
  "3543": {
    name: "tiny dinos winter 2022 #3543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3543,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3543.png",
  },
  "3544": {
    name: "tiny dinos winter 2022 #3544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3544,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3544.png",
  },
  "3545": {
    name: "tiny dinos winter 2022 #3545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3545,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3545.png",
  },
  "3546": {
    name: "tiny dinos winter 2022 #3546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3546,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3546.png",
  },
  "3547": {
    name: "tiny dinos winter 2022 #3547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3547,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3547.png",
  },
  "3548": {
    name: "tiny dinos winter 2022 #3548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3548,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3548.png",
  },
  "3549": {
    name: "tiny dinos winter 2022 #3549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3549,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3549.png",
  },
  "3550": {
    name: "tiny dinos winter 2022 #3550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3550,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3550.png",
  },
  "3551": {
    name: "tiny dinos winter 2022 #3551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3551,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3551.png",
  },
  "3552": {
    name: "tiny dinos winter 2022 #3552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3552,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3552.png",
  },
  "3553": {
    name: "tiny dinos winter 2022 #3553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3553,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3553.png",
  },
  "3554": {
    name: "tiny dinos winter 2022 #3554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3554,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3554.png",
  },
  "3555": {
    name: "tiny dinos winter 2022 #3555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3555,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3555.png",
  },
  "3556": {
    name: "tiny dinos winter 2022 #3556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3556,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3556.png",
  },
  "3557": {
    name: "tiny dinos winter 2022 #3557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3557,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3557.png",
  },
  "3558": {
    name: "tiny dinos winter 2022 #3558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3558,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3558.png",
  },
  "3559": {
    name: "tiny dinos winter 2022 #3559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3559,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3559.png",
  },
  "3560": {
    name: "tiny dinos winter 2022 #3560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3560,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3560.png",
  },
  "3561": {
    name: "tiny dinos winter 2022 #3561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3561,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3561.png",
  },
  "3562": {
    name: "tiny dinos winter 2022 #3562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3562,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3562.png",
  },
  "3563": {
    name: "tiny dinos winter 2022 #3563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3563,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3563.png",
  },
  "3564": {
    name: "tiny dinos winter 2022 #3564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3564,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3564.png",
  },
  "3565": {
    name: "tiny dinos winter 2022 #3565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3565,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3565.png",
  },
  "3566": {
    name: "tiny dinos winter 2022 #3566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3566,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3566.png",
  },
  "3567": {
    name: "tiny dinos winter 2022 #3567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3567,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3567.png",
  },
  "3568": {
    name: "tiny dinos winter 2022 #3568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3568,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3568.png",
  },
  "3569": {
    name: "tiny dinos winter 2022 #3569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3569,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3569.png",
  },
  "3570": {
    name: "tiny dinos winter 2022 #3570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3570,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3570.png",
  },
  "3571": {
    name: "tiny dinos winter 2022 #3571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3571,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3571.png",
  },
  "3572": {
    name: "tiny dinos winter 2022 #3572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3572,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3572.png",
  },
  "3573": {
    name: "tiny dinos winter 2022 #3573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3573,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3573.png",
  },
  "3574": {
    name: "tiny dinos winter 2022 #3574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3574,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3574.png",
  },
  "3575": {
    name: "tiny dinos winter 2022 #3575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3575,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3575.png",
  },
  "3576": {
    name: "tiny dinos winter 2022 #3576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3576,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3576.png",
  },
  "3577": {
    name: "tiny dinos winter 2022 #3577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3577,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3577.png",
  },
  "3578": {
    name: "tiny dinos winter 2022 #3578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3578,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3578.png",
  },
  "3579": {
    name: "tiny dinos winter 2022 #3579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3579,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3579.png",
  },
  "3580": {
    name: "tiny dinos winter 2022 #3580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3580,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3580.png",
  },
  "3581": {
    name: "tiny dinos winter 2022 #3581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3581,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3581.png",
  },
  "3582": {
    name: "tiny dinos winter 2022 #3582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3582,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3582.png",
  },
  "3583": {
    name: "tiny dinos winter 2022 #3583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3583,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3583.png",
  },
  "3584": {
    name: "tiny dinos winter 2022 #3584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3584,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3584.png",
  },
  "3585": {
    name: "tiny dinos winter 2022 #3585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3585,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3585.png",
  },
  "3586": {
    name: "tiny dinos winter 2022 #3586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3586,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3586.png",
  },
  "3587": {
    name: "tiny dinos winter 2022 #3587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3587,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3587.png",
  },
  "3588": {
    name: "tiny dinos winter 2022 #3588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3588,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3588.png",
  },
  "3589": {
    name: "tiny dinos winter 2022 #3589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3589,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3589.png",
  },
  "3590": {
    name: "tiny dinos winter 2022 #3590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3590,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3590.png",
  },
  "3591": {
    name: "tiny dinos winter 2022 #3591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3591,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3591.png",
  },
  "3592": {
    name: "tiny dinos winter 2022 #3592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3592,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3592.png",
  },
  "3593": {
    name: "tiny dinos winter 2022 #3593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3593,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3593.png",
  },
  "3594": {
    name: "tiny dinos winter 2022 #3594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3594,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3594.png",
  },
  "3595": {
    name: "tiny dinos winter 2022 #3595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3595,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3595.png",
  },
  "3596": {
    name: "tiny dinos winter 2022 #3596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3596,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3596.png",
  },
  "3597": {
    name: "tiny dinos winter 2022 #3597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3597,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3597.png",
  },
  "3598": {
    name: "tiny dinos winter 2022 #3598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3598,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3598.png",
  },
  "3599": {
    name: "tiny dinos winter 2022 #3599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3599,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3599.png",
  },
  "3600": {
    name: "tiny dinos winter 2022 #3600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3600,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3600.png",
  },
  "3601": {
    name: "tiny dinos winter 2022 #3601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3601,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3601.png",
  },
  "3602": {
    name: "tiny dinos winter 2022 #3602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3602,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3602.png",
  },
  "3603": {
    name: "tiny dinos winter 2022 #3603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3603,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3603.png",
  },
  "3604": {
    name: "tiny dinos winter 2022 #3604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3604,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3604.png",
  },
  "3605": {
    name: "tiny dinos winter 2022 #3605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3605,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3605.png",
  },
  "3606": {
    name: "tiny dinos winter 2022 #3606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3606,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3606.png",
  },
  "3607": {
    name: "tiny dinos winter 2022 #3607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3607,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3607.png",
  },
  "3608": {
    name: "tiny dinos winter 2022 #3608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3608,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3608.png",
  },
  "3609": {
    name: "tiny dinos winter 2022 #3609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3609,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3609.png",
  },
  "3610": {
    name: "tiny dinos winter 2022 #3610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3610,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3610.png",
  },
  "3611": {
    name: "tiny dinos winter 2022 #3611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3611,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3611.png",
  },
  "3612": {
    name: "tiny dinos winter 2022 #3612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3612,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3612.png",
  },
  "3613": {
    name: "tiny dinos winter 2022 #3613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3613,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3613.png",
  },
  "3614": {
    name: "tiny dinos winter 2022 #3614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3614,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3614.png",
  },
  "3615": {
    name: "tiny dinos winter 2022 #3615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3615,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3615.png",
  },
  "3616": {
    name: "tiny dinos winter 2022 #3616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3616,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3616.png",
  },
  "3617": {
    name: "tiny dinos winter 2022 #3617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3617,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3617.png",
  },
  "3618": {
    name: "tiny dinos winter 2022 #3618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3618,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3618.png",
  },
  "3619": {
    name: "tiny dinos winter 2022 #3619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3619,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3619.png",
  },
  "3620": {
    name: "tiny dinos winter 2022 #3620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3620,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3620.png",
  },
  "3621": {
    name: "tiny dinos winter 2022 #3621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3621,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3621.png",
  },
  "3622": {
    name: "tiny dinos winter 2022 #3622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3622,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3622.png",
  },
  "3623": {
    name: "tiny dinos winter 2022 #3623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3623,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3623.png",
  },
  "3624": {
    name: "tiny dinos winter 2022 #3624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3624,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3624.png",
  },
  "3625": {
    name: "tiny dinos winter 2022 #3625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3625,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3625.png",
  },
  "3626": {
    name: "tiny dinos winter 2022 #3626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3626,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3626.png",
  },
  "3627": {
    name: "tiny dinos winter 2022 #3627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3627,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3627.png",
  },
  "3628": {
    name: "tiny dinos winter 2022 #3628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3628,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3628.png",
  },
  "3629": {
    name: "tiny dinos winter 2022 #3629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3629,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3629.png",
  },
  "3630": {
    name: "tiny dinos winter 2022 #3630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3630,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3630.png",
  },
  "3631": {
    name: "tiny dinos winter 2022 #3631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3631,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3631.png",
  },
  "3632": {
    name: "tiny dinos winter 2022 #3632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3632,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3632.png",
  },
  "3633": {
    name: "tiny dinos winter 2022 #3633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3633,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3633.png",
  },
  "3634": {
    name: "tiny dinos winter 2022 #3634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3634,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3634.png",
  },
  "3635": {
    name: "tiny dinos winter 2022 #3635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3635,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3635.png",
  },
  "3636": {
    name: "tiny dinos winter 2022 #3636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3636,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3636.png",
  },
  "3637": {
    name: "tiny dinos winter 2022 #3637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3637,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3637.png",
  },
  "3638": {
    name: "tiny dinos winter 2022 #3638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3638,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3638.png",
  },
  "3639": {
    name: "tiny dinos winter 2022 #3639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3639,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3639.png",
  },
  "3640": {
    name: "tiny dinos winter 2022 #3640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3640,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3640.png",
  },
  "3641": {
    name: "tiny dinos winter 2022 #3641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3641,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3641.png",
  },
  "3642": {
    name: "tiny dinos winter 2022 #3642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3642,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3642.png",
  },
  "3643": {
    name: "tiny dinos winter 2022 #3643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3643,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3643.png",
  },
  "3644": {
    name: "tiny dinos winter 2022 #3644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3644,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3644.png",
  },
  "3645": {
    name: "tiny dinos winter 2022 #3645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3645,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3645.png",
  },
  "3646": {
    name: "tiny dinos winter 2022 #3646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3646,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3646.png",
  },
  "3647": {
    name: "tiny dinos winter 2022 #3647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3647,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3647.png",
  },
  "3648": {
    name: "tiny dinos winter 2022 #3648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3648,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3648.png",
  },
  "3649": {
    name: "tiny dinos winter 2022 #3649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3649,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3649.png",
  },
  "3650": {
    name: "tiny dinos winter 2022 #3650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3650,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3650.png",
  },
  "3651": {
    name: "tiny dinos winter 2022 #3651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3651,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3651.png",
  },
  "3652": {
    name: "tiny dinos winter 2022 #3652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3652,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3652.png",
  },
  "3653": {
    name: "tiny dinos winter 2022 #3653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3653,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3653.png",
  },
  "3654": {
    name: "tiny dinos winter 2022 #3654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3654,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3654.png",
  },
  "3655": {
    name: "tiny dinos winter 2022 #3655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3655,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3655.png",
  },
  "3656": {
    name: "tiny dinos winter 2022 #3656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3656,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3656.png",
  },
  "3657": {
    name: "tiny dinos winter 2022 #3657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3657,
    attributes: [
      {
        trait_type: "1/1",
        value: "arb",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3657.png",
  },
  "3658": {
    name: "tiny dinos winter 2022 #3658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3658,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3658.png",
  },
  "3659": {
    name: "tiny dinos winter 2022 #3659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3659,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3659.png",
  },
  "3660": {
    name: "tiny dinos winter 2022 #3660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3660,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3660.png",
  },
  "3661": {
    name: "tiny dinos winter 2022 #3661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3661,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3661.png",
  },
  "3662": {
    name: "tiny dinos winter 2022 #3662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3662,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3662.png",
  },
  "3663": {
    name: "tiny dinos winter 2022 #3663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3663,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3663.png",
  },
  "3664": {
    name: "tiny dinos winter 2022 #3664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3664,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3664.png",
  },
  "3665": {
    name: "tiny dinos winter 2022 #3665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3665,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3665.png",
  },
  "3666": {
    name: "tiny dinos winter 2022 #3666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3666,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3666.png",
  },
  "3667": {
    name: "tiny dinos winter 2022 #3667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3667,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3667.png",
  },
  "3668": {
    name: "tiny dinos winter 2022 #3668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3668,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3668.png",
  },
  "3669": {
    name: "tiny dinos winter 2022 #3669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3669,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3669.png",
  },
  "3670": {
    name: "tiny dinos winter 2022 #3670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3670,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3670.png",
  },
  "3671": {
    name: "tiny dinos winter 2022 #3671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3671,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3671.png",
  },
  "3672": {
    name: "tiny dinos winter 2022 #3672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3672,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3672.png",
  },
  "3673": {
    name: "tiny dinos winter 2022 #3673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3673,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3673.png",
  },
  "3674": {
    name: "tiny dinos winter 2022 #3674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3674,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3674.png",
  },
  "3675": {
    name: "tiny dinos winter 2022 #3675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3675,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3675.png",
  },
  "3676": {
    name: "tiny dinos winter 2022 #3676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3676,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3676.png",
  },
  "3677": {
    name: "tiny dinos winter 2022 #3677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3677,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3677.png",
  },
  "3678": {
    name: "tiny dinos winter 2022 #3678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3678,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3678.png",
  },
  "3679": {
    name: "tiny dinos winter 2022 #3679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3679,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3679.png",
  },
  "3680": {
    name: "tiny dinos winter 2022 #3680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3680,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3680.png",
  },
  "3681": {
    name: "tiny dinos winter 2022 #3681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3681,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3681.png",
  },
  "3682": {
    name: "tiny dinos winter 2022 #3682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3682,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3682.png",
  },
  "3683": {
    name: "tiny dinos winter 2022 #3683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3683,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3683.png",
  },
  "3684": {
    name: "tiny dinos winter 2022 #3684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3684,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3684.png",
  },
  "3685": {
    name: "tiny dinos winter 2022 #3685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3685,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3685.png",
  },
  "3686": {
    name: "tiny dinos winter 2022 #3686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3686,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3686.png",
  },
  "3687": {
    name: "tiny dinos winter 2022 #3687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3687,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3687.png",
  },
  "3688": {
    name: "tiny dinos winter 2022 #3688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3688,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3688.png",
  },
  "3689": {
    name: "tiny dinos winter 2022 #3689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3689,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3689.png",
  },
  "3690": {
    name: "tiny dinos winter 2022 #3690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3690,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3690.png",
  },
  "3691": {
    name: "tiny dinos winter 2022 #3691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3691,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3691.png",
  },
  "3692": {
    name: "tiny dinos winter 2022 #3692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3692,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3692.png",
  },
  "3693": {
    name: "tiny dinos winter 2022 #3693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3693,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3693.png",
  },
  "3694": {
    name: "tiny dinos winter 2022 #3694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3694,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3694.png",
  },
  "3695": {
    name: "tiny dinos winter 2022 #3695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3695,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3695.png",
  },
  "3696": {
    name: "tiny dinos winter 2022 #3696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3696,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3696.png",
  },
  "3697": {
    name: "tiny dinos winter 2022 #3697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3697,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3697.png",
  },
  "3698": {
    name: "tiny dinos winter 2022 #3698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3698,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3698.png",
  },
  "3699": {
    name: "tiny dinos winter 2022 #3699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3699,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3699.png",
  },
  "3700": {
    name: "tiny dinos winter 2022 #3700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3700,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3700.png",
  },
  "3701": {
    name: "tiny dinos winter 2022 #3701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3701,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3701.png",
  },
  "3702": {
    name: "tiny dinos winter 2022 #3702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3702,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3702.png",
  },
  "3703": {
    name: "tiny dinos winter 2022 #3703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3703,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3703.png",
  },
  "3704": {
    name: "tiny dinos winter 2022 #3704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3704,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3704.png",
  },
  "3705": {
    name: "tiny dinos winter 2022 #3705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3705,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3705.png",
  },
  "3706": {
    name: "tiny dinos winter 2022 #3706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3706,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3706.png",
  },
  "3707": {
    name: "tiny dinos winter 2022 #3707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3707,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3707.png",
  },
  "3708": {
    name: "tiny dinos winter 2022 #3708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3708,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3708.png",
  },
  "3709": {
    name: "tiny dinos winter 2022 #3709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3709,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3709.png",
  },
  "3710": {
    name: "tiny dinos winter 2022 #3710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3710,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3710.png",
  },
  "3711": {
    name: "tiny dinos winter 2022 #3711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3711,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3711.png",
  },
  "3712": {
    name: "tiny dinos winter 2022 #3712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3712,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3712.png",
  },
  "3713": {
    name: "tiny dinos winter 2022 #3713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3713,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3713.png",
  },
  "3714": {
    name: "tiny dinos winter 2022 #3714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3714,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3714.png",
  },
  "3715": {
    name: "tiny dinos winter 2022 #3715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3715,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3715.png",
  },
  "3716": {
    name: "tiny dinos winter 2022 #3716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3716,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3716.png",
  },
  "3717": {
    name: "tiny dinos winter 2022 #3717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3717,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3717.png",
  },
  "3718": {
    name: "tiny dinos winter 2022 #3718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3718,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3718.png",
  },
  "3719": {
    name: "tiny dinos winter 2022 #3719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3719,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3719.png",
  },
  "3720": {
    name: "tiny dinos winter 2022 #3720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3720,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3720.png",
  },
  "3721": {
    name: "tiny dinos winter 2022 #3721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3721,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3721.png",
  },
  "3722": {
    name: "tiny dinos winter 2022 #3722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3722,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3722.png",
  },
  "3723": {
    name: "tiny dinos winter 2022 #3723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3723,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3723.png",
  },
  "3724": {
    name: "tiny dinos winter 2022 #3724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3724,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3724.png",
  },
  "3725": {
    name: "tiny dinos winter 2022 #3725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3725,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3725.png",
  },
  "3726": {
    name: "tiny dinos winter 2022 #3726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3726,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3726.png",
  },
  "3727": {
    name: "tiny dinos winter 2022 #3727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3727,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3727.png",
  },
  "3728": {
    name: "tiny dinos winter 2022 #3728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3728,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3728.png",
  },
  "3729": {
    name: "tiny dinos winter 2022 #3729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3729,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3729.png",
  },
  "3730": {
    name: "tiny dinos winter 2022 #3730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3730,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3730.png",
  },
  "3731": {
    name: "tiny dinos winter 2022 #3731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3731,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3731.png",
  },
  "3732": {
    name: "tiny dinos winter 2022 #3732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3732,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3732.png",
  },
  "3733": {
    name: "tiny dinos winter 2022 #3733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3733,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3733.png",
  },
  "3734": {
    name: "tiny dinos winter 2022 #3734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3734,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3734.png",
  },
  "3735": {
    name: "tiny dinos winter 2022 #3735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3735,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3735.png",
  },
  "3736": {
    name: "tiny dinos winter 2022 #3736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3736,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3736.png",
  },
  "3737": {
    name: "tiny dinos winter 2022 #3737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3737,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3737.png",
  },
  "3738": {
    name: "tiny dinos winter 2022 #3738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3738,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3738.png",
  },
  "3739": {
    name: "tiny dinos winter 2022 #3739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3739,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3739.png",
  },
  "3740": {
    name: "tiny dinos winter 2022 #3740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3740,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3740.png",
  },
  "3741": {
    name: "tiny dinos winter 2022 #3741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3741,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3741.png",
  },
  "3742": {
    name: "tiny dinos winter 2022 #3742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3742,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3742.png",
  },
  "3743": {
    name: "tiny dinos winter 2022 #3743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3743,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3743.png",
  },
  "3744": {
    name: "tiny dinos winter 2022 #3744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3744,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3744.png",
  },
  "3745": {
    name: "tiny dinos winter 2022 #3745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3745,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3745.png",
  },
  "3746": {
    name: "tiny dinos winter 2022 #3746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3746,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3746.png",
  },
  "3747": {
    name: "tiny dinos winter 2022 #3747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3747,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3747.png",
  },
  "3748": {
    name: "tiny dinos winter 2022 #3748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3748,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3748.png",
  },
  "3749": {
    name: "tiny dinos winter 2022 #3749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3749,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3749.png",
  },
  "3750": {
    name: "tiny dinos winter 2022 #3750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3750,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3750.png",
  },
  "3751": {
    name: "tiny dinos winter 2022 #3751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3751,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3751.png",
  },
  "3752": {
    name: "tiny dinos winter 2022 #3752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3752,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3752.png",
  },
  "3753": {
    name: "tiny dinos winter 2022 #3753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3753,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3753.png",
  },
  "3754": {
    name: "tiny dinos winter 2022 #3754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3754,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3754.png",
  },
  "3755": {
    name: "tiny dinos winter 2022 #3755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3755,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3755.png",
  },
  "3756": {
    name: "tiny dinos winter 2022 #3756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3756,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3756.png",
  },
  "3757": {
    name: "tiny dinos winter 2022 #3757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3757,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3757.png",
  },
  "3758": {
    name: "tiny dinos winter 2022 #3758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3758,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3758.png",
  },
  "3759": {
    name: "tiny dinos winter 2022 #3759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3759,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3759.png",
  },
  "3760": {
    name: "tiny dinos winter 2022 #3760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3760,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3760.png",
  },
  "3761": {
    name: "tiny dinos winter 2022 #3761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3761,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3761.png",
  },
  "3762": {
    name: "tiny dinos winter 2022 #3762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3762,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3762.png",
  },
  "3763": {
    name: "tiny dinos winter 2022 #3763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3763,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3763.png",
  },
  "3764": {
    name: "tiny dinos winter 2022 #3764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3764,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3764.png",
  },
  "3765": {
    name: "tiny dinos winter 2022 #3765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3765,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3765.png",
  },
  "3766": {
    name: "tiny dinos winter 2022 #3766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3766,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3766.png",
  },
  "3767": {
    name: "tiny dinos winter 2022 #3767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3767,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3767.png",
  },
  "3768": {
    name: "tiny dinos winter 2022 #3768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3768,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3768.png",
  },
  "3769": {
    name: "tiny dinos winter 2022 #3769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3769,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3769.png",
  },
  "3770": {
    name: "tiny dinos winter 2022 #3770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3770,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3770.png",
  },
  "3771": {
    name: "tiny dinos winter 2022 #3771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3771,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3771.png",
  },
  "3772": {
    name: "tiny dinos winter 2022 #3772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3772,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3772.png",
  },
  "3773": {
    name: "tiny dinos winter 2022 #3773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3773,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3773.png",
  },
  "3774": {
    name: "tiny dinos winter 2022 #3774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3774,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3774.png",
  },
  "3775": {
    name: "tiny dinos winter 2022 #3775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3775,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3775.png",
  },
  "3776": {
    name: "tiny dinos winter 2022 #3776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3776,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3776.png",
  },
  "3777": {
    name: "tiny dinos winter 2022 #3777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3777,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3777.png",
  },
  "3778": {
    name: "tiny dinos winter 2022 #3778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3778,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3778.png",
  },
  "3779": {
    name: "tiny dinos winter 2022 #3779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3779,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3779.png",
  },
  "3780": {
    name: "tiny dinos winter 2022 #3780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3780,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3780.png",
  },
  "3781": {
    name: "tiny dinos winter 2022 #3781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3781,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3781.png",
  },
  "3782": {
    name: "tiny dinos winter 2022 #3782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3782,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3782.png",
  },
  "3783": {
    name: "tiny dinos winter 2022 #3783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3783,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3783.png",
  },
  "3784": {
    name: "tiny dinos winter 2022 #3784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3784,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3784.png",
  },
  "3785": {
    name: "tiny dinos winter 2022 #3785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3785,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3785.png",
  },
  "3786": {
    name: "tiny dinos winter 2022 #3786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3786,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3786.png",
  },
  "3787": {
    name: "tiny dinos winter 2022 #3787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3787,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3787.png",
  },
  "3788": {
    name: "tiny dinos winter 2022 #3788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3788,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3788.png",
  },
  "3789": {
    name: "tiny dinos winter 2022 #3789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3789,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3789.png",
  },
  "3790": {
    name: "tiny dinos winter 2022 #3790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3790,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3790.png",
  },
  "3791": {
    name: "tiny dinos winter 2022 #3791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3791,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3791.png",
  },
  "3792": {
    name: "tiny dinos winter 2022 #3792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3792,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3792.png",
  },
  "3793": {
    name: "tiny dinos winter 2022 #3793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3793,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3793.png",
  },
  "3794": {
    name: "tiny dinos winter 2022 #3794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3794,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3794.png",
  },
  "3795": {
    name: "tiny dinos winter 2022 #3795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3795,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3795.png",
  },
  "3796": {
    name: "tiny dinos winter 2022 #3796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3796,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3796.png",
  },
  "3797": {
    name: "tiny dinos winter 2022 #3797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3797,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3797.png",
  },
  "3798": {
    name: "tiny dinos winter 2022 #3798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3798,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3798.png",
  },
  "3799": {
    name: "tiny dinos winter 2022 #3799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3799,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3799.png",
  },
  "3800": {
    name: "tiny dinos winter 2022 #3800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3800,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3800.png",
  },
  "3801": {
    name: "tiny dinos winter 2022 #3801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3801,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3801.png",
  },
  "3802": {
    name: "tiny dinos winter 2022 #3802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3802,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3802.png",
  },
  "3803": {
    name: "tiny dinos winter 2022 #3803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3803,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3803.png",
  },
  "3804": {
    name: "tiny dinos winter 2022 #3804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3804,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3804.png",
  },
  "3805": {
    name: "tiny dinos winter 2022 #3805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3805,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3805.png",
  },
  "3806": {
    name: "tiny dinos winter 2022 #3806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3806,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3806.png",
  },
  "3807": {
    name: "tiny dinos winter 2022 #3807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3807,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3807.png",
  },
  "3808": {
    name: "tiny dinos winter 2022 #3808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3808,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3808.png",
  },
  "3809": {
    name: "tiny dinos winter 2022 #3809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3809,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3809.png",
  },
  "3810": {
    name: "tiny dinos winter 2022 #3810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3810,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3810.png",
  },
  "3811": {
    name: "tiny dinos winter 2022 #3811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3811,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3811.png",
  },
  "3812": {
    name: "tiny dinos winter 2022 #3812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3812,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3812.png",
  },
  "3813": {
    name: "tiny dinos winter 2022 #3813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3813,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3813.png",
  },
  "3814": {
    name: "tiny dinos winter 2022 #3814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3814,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3814.png",
  },
  "3815": {
    name: "tiny dinos winter 2022 #3815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3815,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3815.png",
  },
  "3816": {
    name: "tiny dinos winter 2022 #3816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3816,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3816.png",
  },
  "3817": {
    name: "tiny dinos winter 2022 #3817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3817,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3817.png",
  },
  "3818": {
    name: "tiny dinos winter 2022 #3818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3818,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3818.png",
  },
  "3819": {
    name: "tiny dinos winter 2022 #3819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3819,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3819.png",
  },
  "3820": {
    name: "tiny dinos winter 2022 #3820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3820,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3820.png",
  },
  "3821": {
    name: "tiny dinos winter 2022 #3821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3821,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3821.png",
  },
  "3822": {
    name: "tiny dinos winter 2022 #3822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3822,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3822.png",
  },
  "3823": {
    name: "tiny dinos winter 2022 #3823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3823,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3823.png",
  },
  "3824": {
    name: "tiny dinos winter 2022 #3824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3824,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3824.png",
  },
  "3825": {
    name: "tiny dinos winter 2022 #3825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3825,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3825.png",
  },
  "3826": {
    name: "tiny dinos winter 2022 #3826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3826,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3826.png",
  },
  "3827": {
    name: "tiny dinos winter 2022 #3827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3827,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3827.png",
  },
  "3828": {
    name: "tiny dinos winter 2022 #3828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3828,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3828.png",
  },
  "3829": {
    name: "tiny dinos winter 2022 #3829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3829,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3829.png",
  },
  "3830": {
    name: "tiny dinos winter 2022 #3830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3830,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3830.png",
  },
  "3831": {
    name: "tiny dinos winter 2022 #3831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3831,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3831.png",
  },
  "3832": {
    name: "tiny dinos winter 2022 #3832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3832,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3832.png",
  },
  "3833": {
    name: "tiny dinos winter 2022 #3833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3833,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3833.png",
  },
  "3834": {
    name: "tiny dinos winter 2022 #3834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3834,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3834.png",
  },
  "3835": {
    name: "tiny dinos winter 2022 #3835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3835,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3835.png",
  },
  "3836": {
    name: "tiny dinos winter 2022 #3836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3836,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3836.png",
  },
  "3837": {
    name: "tiny dinos winter 2022 #3837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3837,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3837.png",
  },
  "3838": {
    name: "tiny dinos winter 2022 #3838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3838,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3838.png",
  },
  "3839": {
    name: "tiny dinos winter 2022 #3839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3839,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3839.png",
  },
  "3840": {
    name: "tiny dinos winter 2022 #3840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3840,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3840.png",
  },
  "3841": {
    name: "tiny dinos winter 2022 #3841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3841,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3841.png",
  },
  "3842": {
    name: "tiny dinos winter 2022 #3842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3842,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3842.png",
  },
  "3843": {
    name: "tiny dinos winter 2022 #3843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3843,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3843.png",
  },
  "3844": {
    name: "tiny dinos winter 2022 #3844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3844,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3844.png",
  },
  "3845": {
    name: "tiny dinos winter 2022 #3845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3845,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3845.png",
  },
  "3846": {
    name: "tiny dinos winter 2022 #3846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3846,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3846.png",
  },
  "3847": {
    name: "tiny dinos winter 2022 #3847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3847,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3847.png",
  },
  "3848": {
    name: "tiny dinos winter 2022 #3848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3848,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3848.png",
  },
  "3849": {
    name: "tiny dinos winter 2022 #3849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3849,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3849.png",
  },
  "3850": {
    name: "tiny dinos winter 2022 #3850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3850,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3850.png",
  },
  "3851": {
    name: "tiny dinos winter 2022 #3851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3851,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3851.png",
  },
  "3852": {
    name: "tiny dinos winter 2022 #3852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3852,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3852.png",
  },
  "3853": {
    name: "tiny dinos winter 2022 #3853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3853,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3853.png",
  },
  "3854": {
    name: "tiny dinos winter 2022 #3854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3854,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3854.png",
  },
  "3855": {
    name: "tiny dinos winter 2022 #3855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3855,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3855.png",
  },
  "3856": {
    name: "tiny dinos winter 2022 #3856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3856,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3856.png",
  },
  "3857": {
    name: "tiny dinos winter 2022 #3857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3857,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3857.png",
  },
  "3858": {
    name: "tiny dinos winter 2022 #3858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3858,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3858.png",
  },
  "3859": {
    name: "tiny dinos winter 2022 #3859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3859,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3859.png",
  },
  "3860": {
    name: "tiny dinos winter 2022 #3860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3860,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3860.png",
  },
  "3861": {
    name: "tiny dinos winter 2022 #3861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3861,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3861.png",
  },
  "3862": {
    name: "tiny dinos winter 2022 #3862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3862,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3862.png",
  },
  "3863": {
    name: "tiny dinos winter 2022 #3863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3863,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3863.png",
  },
  "3864": {
    name: "tiny dinos winter 2022 #3864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3864,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3864.png",
  },
  "3865": {
    name: "tiny dinos winter 2022 #3865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3865,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3865.png",
  },
  "3866": {
    name: "tiny dinos winter 2022 #3866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3866,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3866.png",
  },
  "3867": {
    name: "tiny dinos winter 2022 #3867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3867,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3867.png",
  },
  "3868": {
    name: "tiny dinos winter 2022 #3868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3868,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3868.png",
  },
  "3869": {
    name: "tiny dinos winter 2022 #3869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3869,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3869.png",
  },
  "3870": {
    name: "tiny dinos winter 2022 #3870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3870,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3870.png",
  },
  "3871": {
    name: "tiny dinos winter 2022 #3871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3871,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3871.png",
  },
  "3872": {
    name: "tiny dinos winter 2022 #3872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3872,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3872.png",
  },
  "3873": {
    name: "tiny dinos winter 2022 #3873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3873,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3873.png",
  },
  "3874": {
    name: "tiny dinos winter 2022 #3874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3874,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3874.png",
  },
  "3875": {
    name: "tiny dinos winter 2022 #3875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3875,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3875.png",
  },
  "3876": {
    name: "tiny dinos winter 2022 #3876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3876,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3876.png",
  },
  "3877": {
    name: "tiny dinos winter 2022 #3877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3877,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3877.png",
  },
  "3878": {
    name: "tiny dinos winter 2022 #3878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3878,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3878.png",
  },
  "3879": {
    name: "tiny dinos winter 2022 #3879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3879,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3879.png",
  },
  "3880": {
    name: "tiny dinos winter 2022 #3880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3880,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3880.png",
  },
  "3881": {
    name: "tiny dinos winter 2022 #3881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3881,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3881.png",
  },
  "3882": {
    name: "tiny dinos winter 2022 #3882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3882,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3882.png",
  },
  "3883": {
    name: "tiny dinos winter 2022 #3883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3883,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3883.png",
  },
  "3884": {
    name: "tiny dinos winter 2022 #3884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3884,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3884.png",
  },
  "3885": {
    name: "tiny dinos winter 2022 #3885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3885,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3885.png",
  },
  "3886": {
    name: "tiny dinos winter 2022 #3886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3886,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3886.png",
  },
  "3887": {
    name: "tiny dinos winter 2022 #3887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3887,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3887.png",
  },
  "3888": {
    name: "tiny dinos winter 2022 #3888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3888,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3888.png",
  },
  "3889": {
    name: "tiny dinos winter 2022 #3889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3889,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3889.png",
  },
  "3890": {
    name: "tiny dinos winter 2022 #3890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3890,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3890.png",
  },
  "3891": {
    name: "tiny dinos winter 2022 #3891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3891,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3891.png",
  },
  "3892": {
    name: "tiny dinos winter 2022 #3892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3892,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3892.png",
  },
  "3893": {
    name: "tiny dinos winter 2022 #3893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3893,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3893.png",
  },
  "3894": {
    name: "tiny dinos winter 2022 #3894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3894,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3894.png",
  },
  "3895": {
    name: "tiny dinos winter 2022 #3895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3895,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3895.png",
  },
  "3896": {
    name: "tiny dinos winter 2022 #3896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3896,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3896.png",
  },
  "3897": {
    name: "tiny dinos winter 2022 #3897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3897,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3897.png",
  },
  "3898": {
    name: "tiny dinos winter 2022 #3898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3898,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3898.png",
  },
  "3899": {
    name: "tiny dinos winter 2022 #3899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3899,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3899.png",
  },
  "3900": {
    name: "tiny dinos winter 2022 #3900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3900,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3900.png",
  },
  "3901": {
    name: "tiny dinos winter 2022 #3901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3901,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3901.png",
  },
  "3902": {
    name: "tiny dinos winter 2022 #3902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3902,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3902.png",
  },
  "3903": {
    name: "tiny dinos winter 2022 #3903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3903,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3903.png",
  },
  "3904": {
    name: "tiny dinos winter 2022 #3904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3904,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3904.png",
  },
  "3905": {
    name: "tiny dinos winter 2022 #3905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3905,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3905.png",
  },
  "3906": {
    name: "tiny dinos winter 2022 #3906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3906,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3906.png",
  },
  "3907": {
    name: "tiny dinos winter 2022 #3907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3907,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3907.png",
  },
  "3908": {
    name: "tiny dinos winter 2022 #3908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3908,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3908.png",
  },
  "3909": {
    name: "tiny dinos winter 2022 #3909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3909,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3909.png",
  },
  "3910": {
    name: "tiny dinos winter 2022 #3910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3910,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3910.png",
  },
  "3911": {
    name: "tiny dinos winter 2022 #3911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3911,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3911.png",
  },
  "3912": {
    name: "tiny dinos winter 2022 #3912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3912,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3912.png",
  },
  "3913": {
    name: "tiny dinos winter 2022 #3913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3913,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3913.png",
  },
  "3914": {
    name: "tiny dinos winter 2022 #3914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3914,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3914.png",
  },
  "3915": {
    name: "tiny dinos winter 2022 #3915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3915,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3915.png",
  },
  "3916": {
    name: "tiny dinos winter 2022 #3916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3916,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3916.png",
  },
  "3917": {
    name: "tiny dinos winter 2022 #3917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3917,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3917.png",
  },
  "3918": {
    name: "tiny dinos winter 2022 #3918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3918,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3918.png",
  },
  "3919": {
    name: "tiny dinos winter 2022 #3919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3919,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3919.png",
  },
  "3920": {
    name: "tiny dinos winter 2022 #3920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3920,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3920.png",
  },
  "3921": {
    name: "tiny dinos winter 2022 #3921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3921,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3921.png",
  },
  "3922": {
    name: "tiny dinos winter 2022 #3922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3922,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3922.png",
  },
  "3923": {
    name: "tiny dinos winter 2022 #3923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3923,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3923.png",
  },
  "3924": {
    name: "tiny dinos winter 2022 #3924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3924,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3924.png",
  },
  "3925": {
    name: "tiny dinos winter 2022 #3925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3925,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3925.png",
  },
  "3926": {
    name: "tiny dinos winter 2022 #3926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3926,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3926.png",
  },
  "3927": {
    name: "tiny dinos winter 2022 #3927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3927,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3927.png",
  },
  "3928": {
    name: "tiny dinos winter 2022 #3928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3928,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3928.png",
  },
  "3929": {
    name: "tiny dinos winter 2022 #3929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3929,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3929.png",
  },
  "3930": {
    name: "tiny dinos winter 2022 #3930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3930,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3930.png",
  },
  "3931": {
    name: "tiny dinos winter 2022 #3931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3931,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3931.png",
  },
  "3932": {
    name: "tiny dinos winter 2022 #3932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3932,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3932.png",
  },
  "3933": {
    name: "tiny dinos winter 2022 #3933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3933,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3933.png",
  },
  "3934": {
    name: "tiny dinos winter 2022 #3934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3934,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3934.png",
  },
  "3935": {
    name: "tiny dinos winter 2022 #3935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3935,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3935.png",
  },
  "3936": {
    name: "tiny dinos winter 2022 #3936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3936,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3936.png",
  },
  "3937": {
    name: "tiny dinos winter 2022 #3937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3937,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3937.png",
  },
  "3938": {
    name: "tiny dinos winter 2022 #3938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3938,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3938.png",
  },
  "3939": {
    name: "tiny dinos winter 2022 #3939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3939,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3939.png",
  },
  "3940": {
    name: "tiny dinos winter 2022 #3940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3940,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3940.png",
  },
  "3941": {
    name: "tiny dinos winter 2022 #3941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3941,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3941.png",
  },
  "3942": {
    name: "tiny dinos winter 2022 #3942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3942,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3942.png",
  },
  "3943": {
    name: "tiny dinos winter 2022 #3943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3943,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3943.png",
  },
  "3944": {
    name: "tiny dinos winter 2022 #3944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3944,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3944.png",
  },
  "3945": {
    name: "tiny dinos winter 2022 #3945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3945,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3945.png",
  },
  "3946": {
    name: "tiny dinos winter 2022 #3946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3946,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3946.png",
  },
  "3947": {
    name: "tiny dinos winter 2022 #3947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3947,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3947.png",
  },
  "3948": {
    name: "tiny dinos winter 2022 #3948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3948,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3948.png",
  },
  "3949": {
    name: "tiny dinos winter 2022 #3949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3949,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3949.png",
  },
  "3950": {
    name: "tiny dinos winter 2022 #3950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3950,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3950.png",
  },
  "3951": {
    name: "tiny dinos winter 2022 #3951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3951,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3951.png",
  },
  "3952": {
    name: "tiny dinos winter 2022 #3952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3952,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3952.png",
  },
  "3953": {
    name: "tiny dinos winter 2022 #3953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3953,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3953.png",
  },
  "3954": {
    name: "tiny dinos winter 2022 #3954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3954,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3954.png",
  },
  "3955": {
    name: "tiny dinos winter 2022 #3955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3955,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3955.png",
  },
  "3956": {
    name: "tiny dinos winter 2022 #3956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3956,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3956.png",
  },
  "3957": {
    name: "tiny dinos winter 2022 #3957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3957,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3957.png",
  },
  "3958": {
    name: "tiny dinos winter 2022 #3958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3958,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3958.png",
  },
  "3959": {
    name: "tiny dinos winter 2022 #3959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3959,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3959.png",
  },
  "3960": {
    name: "tiny dinos winter 2022 #3960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3960,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3960.png",
  },
  "3961": {
    name: "tiny dinos winter 2022 #3961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3961,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3961.png",
  },
  "3962": {
    name: "tiny dinos winter 2022 #3962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3962,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3962.png",
  },
  "3963": {
    name: "tiny dinos winter 2022 #3963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3963,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3963.png",
  },
  "3964": {
    name: "tiny dinos winter 2022 #3964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3964,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3964.png",
  },
  "3965": {
    name: "tiny dinos winter 2022 #3965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3965,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3965.png",
  },
  "3966": {
    name: "tiny dinos winter 2022 #3966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3966,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3966.png",
  },
  "3967": {
    name: "tiny dinos winter 2022 #3967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3967,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3967.png",
  },
  "3968": {
    name: "tiny dinos winter 2022 #3968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3968,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3968.png",
  },
  "3969": {
    name: "tiny dinos winter 2022 #3969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3969,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3969.png",
  },
  "3970": {
    name: "tiny dinos winter 2022 #3970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3970,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3970.png",
  },
  "3971": {
    name: "tiny dinos winter 2022 #3971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3971,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3971.png",
  },
  "3972": {
    name: "tiny dinos winter 2022 #3972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3972,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3972.png",
  },
  "3973": {
    name: "tiny dinos winter 2022 #3973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3973,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3973.png",
  },
  "3974": {
    name: "tiny dinos winter 2022 #3974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3974,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3974.png",
  },
  "3975": {
    name: "tiny dinos winter 2022 #3975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3975,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3975.png",
  },
  "3976": {
    name: "tiny dinos winter 2022 #3976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3976,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3976.png",
  },
  "3977": {
    name: "tiny dinos winter 2022 #3977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3977,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3977.png",
  },
  "3978": {
    name: "tiny dinos winter 2022 #3978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3978,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3978.png",
  },
  "3979": {
    name: "tiny dinos winter 2022 #3979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3979,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3979.png",
  },
  "3980": {
    name: "tiny dinos winter 2022 #3980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3980,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3980.png",
  },
  "3981": {
    name: "tiny dinos winter 2022 #3981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3981,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3981.png",
  },
  "3982": {
    name: "tiny dinos winter 2022 #3982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3982,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3982.png",
  },
  "3983": {
    name: "tiny dinos winter 2022 #3983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3983,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3983.png",
  },
  "3984": {
    name: "tiny dinos winter 2022 #3984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3984,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3984.png",
  },
  "3985": {
    name: "tiny dinos winter 2022 #3985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3985,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3985.png",
  },
  "3986": {
    name: "tiny dinos winter 2022 #3986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3986,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3986.png",
  },
  "3987": {
    name: "tiny dinos winter 2022 #3987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3987,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3987.png",
  },
  "3988": {
    name: "tiny dinos winter 2022 #3988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3988,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3988.png",
  },
  "3989": {
    name: "tiny dinos winter 2022 #3989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3989,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3989.png",
  },
  "3990": {
    name: "tiny dinos winter 2022 #3990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3990,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3990.png",
  },
  "3991": {
    name: "tiny dinos winter 2022 #3991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3991,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3991.png",
  },
  "3992": {
    name: "tiny dinos winter 2022 #3992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3992,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3992.png",
  },
  "3993": {
    name: "tiny dinos winter 2022 #3993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3993,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3993.png",
  },
  "3994": {
    name: "tiny dinos winter 2022 #3994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3994,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3994.png",
  },
  "3995": {
    name: "tiny dinos winter 2022 #3995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3995,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3995.png",
  },
  "3996": {
    name: "tiny dinos winter 2022 #3996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3996,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3996.png",
  },
  "3997": {
    name: "tiny dinos winter 2022 #3997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3997,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3997.png",
  },
  "3998": {
    name: "tiny dinos winter 2022 #3998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3998,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3998.png",
  },
  "3999": {
    name: "tiny dinos winter 2022 #3999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 3999,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/3999.png",
  },
  "4000": {
    name: "tiny dinos winter 2022 #4000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4000,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4000.png",
  },
  "4001": {
    name: "tiny dinos winter 2022 #4001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4001,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4001.png",
  },
  "4002": {
    name: "tiny dinos winter 2022 #4002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4002,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4002.png",
  },
  "4003": {
    name: "tiny dinos winter 2022 #4003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4003,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4003.png",
  },
  "4004": {
    name: "tiny dinos winter 2022 #4004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4004,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4004.png",
  },
  "4005": {
    name: "tiny dinos winter 2022 #4005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4005,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4005.png",
  },
  "4006": {
    name: "tiny dinos winter 2022 #4006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4006,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4006.png",
  },
  "4007": {
    name: "tiny dinos winter 2022 #4007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4007,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4007.png",
  },
  "4008": {
    name: "tiny dinos winter 2022 #4008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4008,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4008.png",
  },
  "4009": {
    name: "tiny dinos winter 2022 #4009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4009,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4009.png",
  },
  "4010": {
    name: "tiny dinos winter 2022 #4010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4010,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4010.png",
  },
  "4011": {
    name: "tiny dinos winter 2022 #4011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4011,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4011.png",
  },
  "4012": {
    name: "tiny dinos winter 2022 #4012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4012,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4012.png",
  },
  "4013": {
    name: "tiny dinos winter 2022 #4013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4013,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4013.png",
  },
  "4014": {
    name: "tiny dinos winter 2022 #4014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4014,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4014.png",
  },
  "4015": {
    name: "tiny dinos winter 2022 #4015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4015,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4015.png",
  },
  "4016": {
    name: "tiny dinos winter 2022 #4016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4016,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4016.png",
  },
  "4017": {
    name: "tiny dinos winter 2022 #4017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4017,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4017.png",
  },
  "4018": {
    name: "tiny dinos winter 2022 #4018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4018,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4018.png",
  },
  "4019": {
    name: "tiny dinos winter 2022 #4019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4019,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4019.png",
  },
  "4020": {
    name: "tiny dinos winter 2022 #4020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4020,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4020.png",
  },
  "4021": {
    name: "tiny dinos winter 2022 #4021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4021,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4021.png",
  },
  "4022": {
    name: "tiny dinos winter 2022 #4022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4022,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4022.png",
  },
  "4023": {
    name: "tiny dinos winter 2022 #4023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4023,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4023.png",
  },
  "4024": {
    name: "tiny dinos winter 2022 #4024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4024,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4024.png",
  },
  "4025": {
    name: "tiny dinos winter 2022 #4025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4025,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4025.png",
  },
  "4026": {
    name: "tiny dinos winter 2022 #4026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4026,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4026.png",
  },
  "4027": {
    name: "tiny dinos winter 2022 #4027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4027,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4027.png",
  },
  "4028": {
    name: "tiny dinos winter 2022 #4028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4028,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4028.png",
  },
  "4029": {
    name: "tiny dinos winter 2022 #4029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4029,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4029.png",
  },
  "4030": {
    name: "tiny dinos winter 2022 #4030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4030,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4030.png",
  },
  "4031": {
    name: "tiny dinos winter 2022 #4031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4031,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4031.png",
  },
  "4032": {
    name: "tiny dinos winter 2022 #4032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4032,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4032.png",
  },
  "4033": {
    name: "tiny dinos winter 2022 #4033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4033,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4033.png",
  },
  "4034": {
    name: "tiny dinos winter 2022 #4034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4034,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4034.png",
  },
  "4035": {
    name: "tiny dinos winter 2022 #4035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4035,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4035.png",
  },
  "4036": {
    name: "tiny dinos winter 2022 #4036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4036,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4036.png",
  },
  "4037": {
    name: "tiny dinos winter 2022 #4037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4037,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4037.png",
  },
  "4038": {
    name: "tiny dinos winter 2022 #4038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4038,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4038.png",
  },
  "4039": {
    name: "tiny dinos winter 2022 #4039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4039,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4039.png",
  },
  "4040": {
    name: "tiny dinos winter 2022 #4040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4040,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4040.png",
  },
  "4041": {
    name: "tiny dinos winter 2022 #4041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4041,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4041.png",
  },
  "4042": {
    name: "tiny dinos winter 2022 #4042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4042,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4042.png",
  },
  "4043": {
    name: "tiny dinos winter 2022 #4043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4043,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4043.png",
  },
  "4044": {
    name: "tiny dinos winter 2022 #4044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4044,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4044.png",
  },
  "4045": {
    name: "tiny dinos winter 2022 #4045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4045,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4045.png",
  },
  "4046": {
    name: "tiny dinos winter 2022 #4046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4046,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4046.png",
  },
  "4047": {
    name: "tiny dinos winter 2022 #4047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4047,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4047.png",
  },
  "4048": {
    name: "tiny dinos winter 2022 #4048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4048,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4048.png",
  },
  "4049": {
    name: "tiny dinos winter 2022 #4049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4049,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4049.png",
  },
  "4050": {
    name: "tiny dinos winter 2022 #4050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4050,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4050.png",
  },
  "4051": {
    name: "tiny dinos winter 2022 #4051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4051,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4051.png",
  },
  "4052": {
    name: "tiny dinos winter 2022 #4052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4052,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4052.png",
  },
  "4053": {
    name: "tiny dinos winter 2022 #4053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4053,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4053.png",
  },
  "4054": {
    name: "tiny dinos winter 2022 #4054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4054,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4054.png",
  },
  "4055": {
    name: "tiny dinos winter 2022 #4055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4055,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4055.png",
  },
  "4056": {
    name: "tiny dinos winter 2022 #4056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4056,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4056.png",
  },
  "4057": {
    name: "tiny dinos winter 2022 #4057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4057,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4057.png",
  },
  "4058": {
    name: "tiny dinos winter 2022 #4058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4058,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4058.png",
  },
  "4059": {
    name: "tiny dinos winter 2022 #4059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4059,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4059.png",
  },
  "4060": {
    name: "tiny dinos winter 2022 #4060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4060,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4060.png",
  },
  "4061": {
    name: "tiny dinos winter 2022 #4061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4061,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4061.png",
  },
  "4062": {
    name: "tiny dinos winter 2022 #4062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4062,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4062.png",
  },
  "4063": {
    name: "tiny dinos winter 2022 #4063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4063,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4063.png",
  },
  "4064": {
    name: "tiny dinos winter 2022 #4064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4064,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4064.png",
  },
  "4065": {
    name: "tiny dinos winter 2022 #4065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4065,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4065.png",
  },
  "4066": {
    name: "tiny dinos winter 2022 #4066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4066,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4066.png",
  },
  "4067": {
    name: "tiny dinos winter 2022 #4067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4067,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4067.png",
  },
  "4068": {
    name: "tiny dinos winter 2022 #4068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4068,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4068.png",
  },
  "4069": {
    name: "tiny dinos winter 2022 #4069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4069,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4069.png",
  },
  "4070": {
    name: "tiny dinos winter 2022 #4070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4070,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4070.png",
  },
  "4071": {
    name: "tiny dinos winter 2022 #4071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4071,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4071.png",
  },
  "4072": {
    name: "tiny dinos winter 2022 #4072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4072,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4072.png",
  },
  "4073": {
    name: "tiny dinos winter 2022 #4073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4073,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4073.png",
  },
  "4074": {
    name: "tiny dinos winter 2022 #4074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4074,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4074.png",
  },
  "4075": {
    name: "tiny dinos winter 2022 #4075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4075,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4075.png",
  },
  "4076": {
    name: "tiny dinos winter 2022 #4076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4076,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4076.png",
  },
  "4077": {
    name: "tiny dinos winter 2022 #4077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4077,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4077.png",
  },
  "4078": {
    name: "tiny dinos winter 2022 #4078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4078,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4078.png",
  },
  "4079": {
    name: "tiny dinos winter 2022 #4079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4079,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4079.png",
  },
  "4080": {
    name: "tiny dinos winter 2022 #4080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4080,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4080.png",
  },
  "4081": {
    name: "tiny dinos winter 2022 #4081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4081,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4081.png",
  },
  "4082": {
    name: "tiny dinos winter 2022 #4082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4082,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4082.png",
  },
  "4083": {
    name: "tiny dinos winter 2022 #4083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4083,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4083.png",
  },
  "4084": {
    name: "tiny dinos winter 2022 #4084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4084,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4084.png",
  },
  "4085": {
    name: "tiny dinos winter 2022 #4085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4085,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4085.png",
  },
  "4086": {
    name: "tiny dinos winter 2022 #4086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4086,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4086.png",
  },
  "4087": {
    name: "tiny dinos winter 2022 #4087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4087,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4087.png",
  },
  "4088": {
    name: "tiny dinos winter 2022 #4088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4088,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4088.png",
  },
  "4089": {
    name: "tiny dinos winter 2022 #4089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4089,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4089.png",
  },
  "4090": {
    name: "tiny dinos winter 2022 #4090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4090,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4090.png",
  },
  "4091": {
    name: "tiny dinos winter 2022 #4091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4091,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4091.png",
  },
  "4092": {
    name: "tiny dinos winter 2022 #4092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4092,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4092.png",
  },
  "4093": {
    name: "tiny dinos winter 2022 #4093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4093,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4093.png",
  },
  "4094": {
    name: "tiny dinos winter 2022 #4094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4094,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4094.png",
  },
  "4095": {
    name: "tiny dinos winter 2022 #4095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4095,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4095.png",
  },
  "4096": {
    name: "tiny dinos winter 2022 #4096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4096,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4096.png",
  },
  "4097": {
    name: "tiny dinos winter 2022 #4097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4097,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4097.png",
  },
  "4098": {
    name: "tiny dinos winter 2022 #4098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4098,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4098.png",
  },
  "4099": {
    name: "tiny dinos winter 2022 #4099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4099,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4099.png",
  },
  "4100": {
    name: "tiny dinos winter 2022 #4100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4100,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4100.png",
  },
  "4101": {
    name: "tiny dinos winter 2022 #4101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4101,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4101.png",
  },
  "4102": {
    name: "tiny dinos winter 2022 #4102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4102,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4102.png",
  },
  "4103": {
    name: "tiny dinos winter 2022 #4103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4103,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4103.png",
  },
  "4104": {
    name: "tiny dinos winter 2022 #4104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4104,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4104.png",
  },
  "4105": {
    name: "tiny dinos winter 2022 #4105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4105,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4105.png",
  },
  "4106": {
    name: "tiny dinos winter 2022 #4106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4106,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4106.png",
  },
  "4107": {
    name: "tiny dinos winter 2022 #4107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4107,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4107.png",
  },
  "4108": {
    name: "tiny dinos winter 2022 #4108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4108,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4108.png",
  },
  "4109": {
    name: "tiny dinos winter 2022 #4109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4109,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4109.png",
  },
  "4110": {
    name: "tiny dinos winter 2022 #4110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4110,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4110.png",
  },
  "4111": {
    name: "tiny dinos winter 2022 #4111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4111,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4111.png",
  },
  "4112": {
    name: "tiny dinos winter 2022 #4112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4112,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4112.png",
  },
  "4113": {
    name: "tiny dinos winter 2022 #4113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4113,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4113.png",
  },
  "4114": {
    name: "tiny dinos winter 2022 #4114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4114,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4114.png",
  },
  "4115": {
    name: "tiny dinos winter 2022 #4115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4115,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4115.png",
  },
  "4116": {
    name: "tiny dinos winter 2022 #4116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4116,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4116.png",
  },
  "4117": {
    name: "tiny dinos winter 2022 #4117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4117,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4117.png",
  },
  "4118": {
    name: "tiny dinos winter 2022 #4118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4118,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4118.png",
  },
  "4119": {
    name: "tiny dinos winter 2022 #4119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4119,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4119.png",
  },
  "4120": {
    name: "tiny dinos winter 2022 #4120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4120,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4120.png",
  },
  "4121": {
    name: "tiny dinos winter 2022 #4121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4121,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4121.png",
  },
  "4122": {
    name: "tiny dinos winter 2022 #4122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4122,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4122.png",
  },
  "4123": {
    name: "tiny dinos winter 2022 #4123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4123,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4123.png",
  },
  "4124": {
    name: "tiny dinos winter 2022 #4124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4124,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4124.png",
  },
  "4125": {
    name: "tiny dinos winter 2022 #4125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4125,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4125.png",
  },
  "4126": {
    name: "tiny dinos winter 2022 #4126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4126,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4126.png",
  },
  "4127": {
    name: "tiny dinos winter 2022 #4127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4127,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4127.png",
  },
  "4128": {
    name: "tiny dinos winter 2022 #4128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4128,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4128.png",
  },
  "4129": {
    name: "tiny dinos winter 2022 #4129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4129,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4129.png",
  },
  "4130": {
    name: "tiny dinos winter 2022 #4130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4130,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4130.png",
  },
  "4131": {
    name: "tiny dinos winter 2022 #4131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4131,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4131.png",
  },
  "4132": {
    name: "tiny dinos winter 2022 #4132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4132,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4132.png",
  },
  "4133": {
    name: "tiny dinos winter 2022 #4133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4133,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4133.png",
  },
  "4134": {
    name: "tiny dinos winter 2022 #4134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4134,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4134.png",
  },
  "4135": {
    name: "tiny dinos winter 2022 #4135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4135,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4135.png",
  },
  "4136": {
    name: "tiny dinos winter 2022 #4136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4136,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4136.png",
  },
  "4137": {
    name: "tiny dinos winter 2022 #4137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4137,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4137.png",
  },
  "4138": {
    name: "tiny dinos winter 2022 #4138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4138,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4138.png",
  },
  "4139": {
    name: "tiny dinos winter 2022 #4139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4139,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4139.png",
  },
  "4140": {
    name: "tiny dinos winter 2022 #4140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4140,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4140.png",
  },
  "4141": {
    name: "tiny dinos winter 2022 #4141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4141,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4141.png",
  },
  "4142": {
    name: "tiny dinos winter 2022 #4142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4142,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4142.png",
  },
  "4143": {
    name: "tiny dinos winter 2022 #4143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4143,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4143.png",
  },
  "4144": {
    name: "tiny dinos winter 2022 #4144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4144,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4144.png",
  },
  "4145": {
    name: "tiny dinos winter 2022 #4145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4145,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4145.png",
  },
  "4146": {
    name: "tiny dinos winter 2022 #4146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4146,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4146.png",
  },
  "4147": {
    name: "tiny dinos winter 2022 #4147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4147,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4147.png",
  },
  "4148": {
    name: "tiny dinos winter 2022 #4148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4148,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4148.png",
  },
  "4149": {
    name: "tiny dinos winter 2022 #4149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4149,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4149.png",
  },
  "4150": {
    name: "tiny dinos winter 2022 #4150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4150,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4150.png",
  },
  "4151": {
    name: "tiny dinos winter 2022 #4151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4151,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4151.png",
  },
  "4152": {
    name: "tiny dinos winter 2022 #4152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4152,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4152.png",
  },
  "4153": {
    name: "tiny dinos winter 2022 #4153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4153,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4153.png",
  },
  "4154": {
    name: "tiny dinos winter 2022 #4154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4154,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4154.png",
  },
  "4155": {
    name: "tiny dinos winter 2022 #4155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4155,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4155.png",
  },
  "4156": {
    name: "tiny dinos winter 2022 #4156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4156,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4156.png",
  },
  "4157": {
    name: "tiny dinos winter 2022 #4157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4157,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4157.png",
  },
  "4158": {
    name: "tiny dinos winter 2022 #4158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4158,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4158.png",
  },
  "4159": {
    name: "tiny dinos winter 2022 #4159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4159,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4159.png",
  },
  "4160": {
    name: "tiny dinos winter 2022 #4160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4160,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4160.png",
  },
  "4161": {
    name: "tiny dinos winter 2022 #4161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4161,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4161.png",
  },
  "4162": {
    name: "tiny dinos winter 2022 #4162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4162,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4162.png",
  },
  "4163": {
    name: "tiny dinos winter 2022 #4163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4163,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4163.png",
  },
  "4164": {
    name: "tiny dinos winter 2022 #4164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4164,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4164.png",
  },
  "4165": {
    name: "tiny dinos winter 2022 #4165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4165,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4165.png",
  },
  "4166": {
    name: "tiny dinos winter 2022 #4166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4166,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4166.png",
  },
  "4167": {
    name: "tiny dinos winter 2022 #4167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4167,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4167.png",
  },
  "4168": {
    name: "tiny dinos winter 2022 #4168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4168,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4168.png",
  },
  "4169": {
    name: "tiny dinos winter 2022 #4169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4169,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4169.png",
  },
  "4170": {
    name: "tiny dinos winter 2022 #4170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4170,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4170.png",
  },
  "4171": {
    name: "tiny dinos winter 2022 #4171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4171,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4171.png",
  },
  "4172": {
    name: "tiny dinos winter 2022 #4172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4172,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4172.png",
  },
  "4173": {
    name: "tiny dinos winter 2022 #4173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4173,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4173.png",
  },
  "4174": {
    name: "tiny dinos winter 2022 #4174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4174,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4174.png",
  },
  "4175": {
    name: "tiny dinos winter 2022 #4175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4175,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4175.png",
  },
  "4176": {
    name: "tiny dinos winter 2022 #4176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4176,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4176.png",
  },
  "4177": {
    name: "tiny dinos winter 2022 #4177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4177,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4177.png",
  },
  "4178": {
    name: "tiny dinos winter 2022 #4178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4178,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4178.png",
  },
  "4179": {
    name: "tiny dinos winter 2022 #4179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4179,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4179.png",
  },
  "4180": {
    name: "tiny dinos winter 2022 #4180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4180,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4180.png",
  },
  "4181": {
    name: "tiny dinos winter 2022 #4181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4181,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4181.png",
  },
  "4182": {
    name: "tiny dinos winter 2022 #4182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4182,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4182.png",
  },
  "4183": {
    name: "tiny dinos winter 2022 #4183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4183,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4183.png",
  },
  "4184": {
    name: "tiny dinos winter 2022 #4184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4184,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4184.png",
  },
  "4185": {
    name: "tiny dinos winter 2022 #4185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4185,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4185.png",
  },
  "4186": {
    name: "tiny dinos winter 2022 #4186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4186,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4186.png",
  },
  "4187": {
    name: "tiny dinos winter 2022 #4187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4187,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4187.png",
  },
  "4188": {
    name: "tiny dinos winter 2022 #4188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4188,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4188.png",
  },
  "4189": {
    name: "tiny dinos winter 2022 #4189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4189,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4189.png",
  },
  "4190": {
    name: "tiny dinos winter 2022 #4190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4190,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4190.png",
  },
  "4191": {
    name: "tiny dinos winter 2022 #4191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4191,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4191.png",
  },
  "4192": {
    name: "tiny dinos winter 2022 #4192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4192,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4192.png",
  },
  "4193": {
    name: "tiny dinos winter 2022 #4193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4193,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4193.png",
  },
  "4194": {
    name: "tiny dinos winter 2022 #4194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4194,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4194.png",
  },
  "4195": {
    name: "tiny dinos winter 2022 #4195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4195,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4195.png",
  },
  "4196": {
    name: "tiny dinos winter 2022 #4196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4196,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4196.png",
  },
  "4197": {
    name: "tiny dinos winter 2022 #4197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4197,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4197.png",
  },
  "4198": {
    name: "tiny dinos winter 2022 #4198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4198,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4198.png",
  },
  "4199": {
    name: "tiny dinos winter 2022 #4199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4199,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4199.png",
  },
  "4200": {
    name: "tiny dinos winter 2022 #4200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4200,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4200.png",
  },
  "4201": {
    name: "tiny dinos winter 2022 #4201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4201,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4201.png",
  },
  "4202": {
    name: "tiny dinos winter 2022 #4202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4202,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4202.png",
  },
  "4203": {
    name: "tiny dinos winter 2022 #4203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4203,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4203.png",
  },
  "4204": {
    name: "tiny dinos winter 2022 #4204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4204,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4204.png",
  },
  "4205": {
    name: "tiny dinos winter 2022 #4205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4205,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4205.png",
  },
  "4206": {
    name: "tiny dinos winter 2022 #4206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4206,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4206.png",
  },
  "4207": {
    name: "tiny dinos winter 2022 #4207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4207,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4207.png",
  },
  "4208": {
    name: "tiny dinos winter 2022 #4208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4208,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4208.png",
  },
  "4209": {
    name: "tiny dinos winter 2022 #4209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4209,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4209.png",
  },
  "4210": {
    name: "tiny dinos winter 2022 #4210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4210,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4210.png",
  },
  "4211": {
    name: "tiny dinos winter 2022 #4211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4211,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4211.png",
  },
  "4212": {
    name: "tiny dinos winter 2022 #4212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4212,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4212.png",
  },
  "4213": {
    name: "tiny dinos winter 2022 #4213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4213,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4213.png",
  },
  "4214": {
    name: "tiny dinos winter 2022 #4214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4214,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4214.png",
  },
  "4215": {
    name: "tiny dinos winter 2022 #4215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4215,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4215.png",
  },
  "4216": {
    name: "tiny dinos winter 2022 #4216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4216,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4216.png",
  },
  "4217": {
    name: "tiny dinos winter 2022 #4217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4217,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4217.png",
  },
  "4218": {
    name: "tiny dinos winter 2022 #4218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4218,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4218.png",
  },
  "4219": {
    name: "tiny dinos winter 2022 #4219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4219,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4219.png",
  },
  "4220": {
    name: "tiny dinos winter 2022 #4220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4220,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4220.png",
  },
  "4221": {
    name: "tiny dinos winter 2022 #4221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4221,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4221.png",
  },
  "4222": {
    name: "tiny dinos winter 2022 #4222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4222,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4222.png",
  },
  "4223": {
    name: "tiny dinos winter 2022 #4223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4223,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4223.png",
  },
  "4224": {
    name: "tiny dinos winter 2022 #4224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4224,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4224.png",
  },
  "4225": {
    name: "tiny dinos winter 2022 #4225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4225,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4225.png",
  },
  "4226": {
    name: "tiny dinos winter 2022 #4226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4226,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4226.png",
  },
  "4227": {
    name: "tiny dinos winter 2022 #4227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4227,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4227.png",
  },
  "4228": {
    name: "tiny dinos winter 2022 #4228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4228,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4228.png",
  },
  "4229": {
    name: "tiny dinos winter 2022 #4229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4229,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4229.png",
  },
  "4230": {
    name: "tiny dinos winter 2022 #4230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4230,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4230.png",
  },
  "4231": {
    name: "tiny dinos winter 2022 #4231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4231,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4231.png",
  },
  "4232": {
    name: "tiny dinos winter 2022 #4232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4232,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4232.png",
  },
  "4233": {
    name: "tiny dinos winter 2022 #4233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4233,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4233.png",
  },
  "4234": {
    name: "tiny dinos winter 2022 #4234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4234,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4234.png",
  },
  "4235": {
    name: "tiny dinos winter 2022 #4235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4235,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4235.png",
  },
  "4236": {
    name: "tiny dinos winter 2022 #4236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4236,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4236.png",
  },
  "4237": {
    name: "tiny dinos winter 2022 #4237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4237,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4237.png",
  },
  "4238": {
    name: "tiny dinos winter 2022 #4238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4238,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4238.png",
  },
  "4239": {
    name: "tiny dinos winter 2022 #4239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4239,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4239.png",
  },
  "4240": {
    name: "tiny dinos winter 2022 #4240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4240,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4240.png",
  },
  "4241": {
    name: "tiny dinos winter 2022 #4241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4241,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4241.png",
  },
  "4242": {
    name: "tiny dinos winter 2022 #4242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4242,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4242.png",
  },
  "4243": {
    name: "tiny dinos winter 2022 #4243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4243,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4243.png",
  },
  "4244": {
    name: "tiny dinos winter 2022 #4244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4244,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4244.png",
  },
  "4245": {
    name: "tiny dinos winter 2022 #4245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4245,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4245.png",
  },
  "4246": {
    name: "tiny dinos winter 2022 #4246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4246,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4246.png",
  },
  "4247": {
    name: "tiny dinos winter 2022 #4247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4247,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4247.png",
  },
  "4248": {
    name: "tiny dinos winter 2022 #4248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4248,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4248.png",
  },
  "4249": {
    name: "tiny dinos winter 2022 #4249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4249,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4249.png",
  },
  "4250": {
    name: "tiny dinos winter 2022 #4250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4250,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4250.png",
  },
  "4251": {
    name: "tiny dinos winter 2022 #4251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4251,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4251.png",
  },
  "4252": {
    name: "tiny dinos winter 2022 #4252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4252,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4252.png",
  },
  "4253": {
    name: "tiny dinos winter 2022 #4253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4253,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4253.png",
  },
  "4254": {
    name: "tiny dinos winter 2022 #4254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4254,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4254.png",
  },
  "4255": {
    name: "tiny dinos winter 2022 #4255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4255,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4255.png",
  },
  "4256": {
    name: "tiny dinos winter 2022 #4256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4256,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4256.png",
  },
  "4257": {
    name: "tiny dinos winter 2022 #4257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4257,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4257.png",
  },
  "4258": {
    name: "tiny dinos winter 2022 #4258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4258,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4258.png",
  },
  "4259": {
    name: "tiny dinos winter 2022 #4259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4259,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4259.png",
  },
  "4260": {
    name: "tiny dinos winter 2022 #4260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4260,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4260.png",
  },
  "4261": {
    name: "tiny dinos winter 2022 #4261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4261,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4261.png",
  },
  "4262": {
    name: "tiny dinos winter 2022 #4262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4262,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4262.png",
  },
  "4263": {
    name: "tiny dinos winter 2022 #4263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4263,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4263.png",
  },
  "4264": {
    name: "tiny dinos winter 2022 #4264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4264,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4264.png",
  },
  "4265": {
    name: "tiny dinos winter 2022 #4265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4265,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4265.png",
  },
  "4266": {
    name: "tiny dinos winter 2022 #4266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4266,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4266.png",
  },
  "4267": {
    name: "tiny dinos winter 2022 #4267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4267,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4267.png",
  },
  "4268": {
    name: "tiny dinos winter 2022 #4268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4268,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4268.png",
  },
  "4269": {
    name: "tiny dinos winter 2022 #4269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4269,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4269.png",
  },
  "4270": {
    name: "tiny dinos winter 2022 #4270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4270,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4270.png",
  },
  "4271": {
    name: "tiny dinos winter 2022 #4271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4271,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4271.png",
  },
  "4272": {
    name: "tiny dinos winter 2022 #4272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4272,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4272.png",
  },
  "4273": {
    name: "tiny dinos winter 2022 #4273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4273,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4273.png",
  },
  "4274": {
    name: "tiny dinos winter 2022 #4274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4274,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4274.png",
  },
  "4275": {
    name: "tiny dinos winter 2022 #4275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4275,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4275.png",
  },
  "4276": {
    name: "tiny dinos winter 2022 #4276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4276,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4276.png",
  },
  "4277": {
    name: "tiny dinos winter 2022 #4277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4277,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4277.png",
  },
  "4278": {
    name: "tiny dinos winter 2022 #4278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4278,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4278.png",
  },
  "4279": {
    name: "tiny dinos winter 2022 #4279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4279,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4279.png",
  },
  "4280": {
    name: "tiny dinos winter 2022 #4280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4280,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4280.png",
  },
  "4281": {
    name: "tiny dinos winter 2022 #4281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4281,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4281.png",
  },
  "4282": {
    name: "tiny dinos winter 2022 #4282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4282,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4282.png",
  },
  "4283": {
    name: "tiny dinos winter 2022 #4283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4283,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4283.png",
  },
  "4284": {
    name: "tiny dinos winter 2022 #4284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4284,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4284.png",
  },
  "4285": {
    name: "tiny dinos winter 2022 #4285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4285,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4285.png",
  },
  "4286": {
    name: "tiny dinos winter 2022 #4286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4286,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4286.png",
  },
  "4287": {
    name: "tiny dinos winter 2022 #4287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4287,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4287.png",
  },
  "4288": {
    name: "tiny dinos winter 2022 #4288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4288,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4288.png",
  },
  "4289": {
    name: "tiny dinos winter 2022 #4289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4289,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4289.png",
  },
  "4290": {
    name: "tiny dinos winter 2022 #4290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4290,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4290.png",
  },
  "4291": {
    name: "tiny dinos winter 2022 #4291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4291,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4291.png",
  },
  "4292": {
    name: "tiny dinos winter 2022 #4292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4292,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4292.png",
  },
  "4293": {
    name: "tiny dinos winter 2022 #4293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4293,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4293.png",
  },
  "4294": {
    name: "tiny dinos winter 2022 #4294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4294,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4294.png",
  },
  "4295": {
    name: "tiny dinos winter 2022 #4295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4295,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4295.png",
  },
  "4296": {
    name: "tiny dinos winter 2022 #4296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4296,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4296.png",
  },
  "4297": {
    name: "tiny dinos winter 2022 #4297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4297,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4297.png",
  },
  "4298": {
    name: "tiny dinos winter 2022 #4298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4298,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4298.png",
  },
  "4299": {
    name: "tiny dinos winter 2022 #4299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4299,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4299.png",
  },
  "4300": {
    name: "tiny dinos winter 2022 #4300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4300,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4300.png",
  },
  "4301": {
    name: "tiny dinos winter 2022 #4301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4301,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4301.png",
  },
  "4302": {
    name: "tiny dinos winter 2022 #4302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4302,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4302.png",
  },
  "4303": {
    name: "tiny dinos winter 2022 #4303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4303,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4303.png",
  },
  "4304": {
    name: "tiny dinos winter 2022 #4304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4304,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4304.png",
  },
  "4305": {
    name: "tiny dinos winter 2022 #4305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4305,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4305.png",
  },
  "4306": {
    name: "tiny dinos winter 2022 #4306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4306,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4306.png",
  },
  "4307": {
    name: "tiny dinos winter 2022 #4307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4307,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4307.png",
  },
  "4308": {
    name: "tiny dinos winter 2022 #4308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4308,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4308.png",
  },
  "4309": {
    name: "tiny dinos winter 2022 #4309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4309,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4309.png",
  },
  "4310": {
    name: "tiny dinos winter 2022 #4310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4310,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4310.png",
  },
  "4311": {
    name: "tiny dinos winter 2022 #4311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4311,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4311.png",
  },
  "4312": {
    name: "tiny dinos winter 2022 #4312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4312,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4312.png",
  },
  "4313": {
    name: "tiny dinos winter 2022 #4313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4313,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4313.png",
  },
  "4314": {
    name: "tiny dinos winter 2022 #4314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4314,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4314.png",
  },
  "4315": {
    name: "tiny dinos winter 2022 #4315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4315,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4315.png",
  },
  "4316": {
    name: "tiny dinos winter 2022 #4316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4316,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4316.png",
  },
  "4317": {
    name: "tiny dinos winter 2022 #4317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4317,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4317.png",
  },
  "4318": {
    name: "tiny dinos winter 2022 #4318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4318,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4318.png",
  },
  "4319": {
    name: "tiny dinos winter 2022 #4319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4319,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4319.png",
  },
  "4320": {
    name: "tiny dinos winter 2022 #4320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4320,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4320.png",
  },
  "4321": {
    name: "tiny dinos winter 2022 #4321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4321,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4321.png",
  },
  "4322": {
    name: "tiny dinos winter 2022 #4322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4322,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4322.png",
  },
  "4323": {
    name: "tiny dinos winter 2022 #4323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4323,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4323.png",
  },
  "4324": {
    name: "tiny dinos winter 2022 #4324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4324,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4324.png",
  },
  "4325": {
    name: "tiny dinos winter 2022 #4325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4325,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4325.png",
  },
  "4326": {
    name: "tiny dinos winter 2022 #4326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4326,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4326.png",
  },
  "4327": {
    name: "tiny dinos winter 2022 #4327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4327,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4327.png",
  },
  "4328": {
    name: "tiny dinos winter 2022 #4328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4328,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4328.png",
  },
  "4329": {
    name: "tiny dinos winter 2022 #4329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4329,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4329.png",
  },
  "4330": {
    name: "tiny dinos winter 2022 #4330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4330,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4330.png",
  },
  "4331": {
    name: "tiny dinos winter 2022 #4331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4331,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4331.png",
  },
  "4332": {
    name: "tiny dinos winter 2022 #4332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4332,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4332.png",
  },
  "4333": {
    name: "tiny dinos winter 2022 #4333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4333,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4333.png",
  },
  "4334": {
    name: "tiny dinos winter 2022 #4334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4334,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4334.png",
  },
  "4335": {
    name: "tiny dinos winter 2022 #4335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4335,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4335.png",
  },
  "4336": {
    name: "tiny dinos winter 2022 #4336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4336,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4336.png",
  },
  "4337": {
    name: "tiny dinos winter 2022 #4337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4337,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4337.png",
  },
  "4338": {
    name: "tiny dinos winter 2022 #4338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4338,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4338.png",
  },
  "4339": {
    name: "tiny dinos winter 2022 #4339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4339,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4339.png",
  },
  "4340": {
    name: "tiny dinos winter 2022 #4340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4340,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4340.png",
  },
  "4341": {
    name: "tiny dinos winter 2022 #4341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4341,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4341.png",
  },
  "4342": {
    name: "tiny dinos winter 2022 #4342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4342,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4342.png",
  },
  "4343": {
    name: "tiny dinos winter 2022 #4343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4343,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4343.png",
  },
  "4344": {
    name: "tiny dinos winter 2022 #4344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4344,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4344.png",
  },
  "4345": {
    name: "tiny dinos winter 2022 #4345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4345,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4345.png",
  },
  "4346": {
    name: "tiny dinos winter 2022 #4346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4346,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4346.png",
  },
  "4347": {
    name: "tiny dinos winter 2022 #4347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4347,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4347.png",
  },
  "4348": {
    name: "tiny dinos winter 2022 #4348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4348,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4348.png",
  },
  "4349": {
    name: "tiny dinos winter 2022 #4349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4349,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4349.png",
  },
  "4350": {
    name: "tiny dinos winter 2022 #4350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4350,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4350.png",
  },
  "4351": {
    name: "tiny dinos winter 2022 #4351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4351,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4351.png",
  },
  "4352": {
    name: "tiny dinos winter 2022 #4352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4352,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4352.png",
  },
  "4353": {
    name: "tiny dinos winter 2022 #4353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4353,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4353.png",
  },
  "4354": {
    name: "tiny dinos winter 2022 #4354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4354,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4354.png",
  },
  "4355": {
    name: "tiny dinos winter 2022 #4355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4355,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4355.png",
  },
  "4356": {
    name: "tiny dinos winter 2022 #4356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4356,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4356.png",
  },
  "4357": {
    name: "tiny dinos winter 2022 #4357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4357,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4357.png",
  },
  "4358": {
    name: "tiny dinos winter 2022 #4358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4358,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4358.png",
  },
  "4359": {
    name: "tiny dinos winter 2022 #4359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4359,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4359.png",
  },
  "4360": {
    name: "tiny dinos winter 2022 #4360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4360,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4360.png",
  },
  "4361": {
    name: "tiny dinos winter 2022 #4361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4361,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4361.png",
  },
  "4362": {
    name: "tiny dinos winter 2022 #4362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4362,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4362.png",
  },
  "4363": {
    name: "tiny dinos winter 2022 #4363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4363,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4363.png",
  },
  "4364": {
    name: "tiny dinos winter 2022 #4364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4364,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4364.png",
  },
  "4365": {
    name: "tiny dinos winter 2022 #4365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4365,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4365.png",
  },
  "4366": {
    name: "tiny dinos winter 2022 #4366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4366,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4366.png",
  },
  "4367": {
    name: "tiny dinos winter 2022 #4367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4367,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4367.png",
  },
  "4368": {
    name: "tiny dinos winter 2022 #4368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4368,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4368.png",
  },
  "4369": {
    name: "tiny dinos winter 2022 #4369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4369,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4369.png",
  },
  "4370": {
    name: "tiny dinos winter 2022 #4370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4370,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4370.png",
  },
  "4371": {
    name: "tiny dinos winter 2022 #4371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4371,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4371.png",
  },
  "4372": {
    name: "tiny dinos winter 2022 #4372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4372,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4372.png",
  },
  "4373": {
    name: "tiny dinos winter 2022 #4373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4373,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4373.png",
  },
  "4374": {
    name: "tiny dinos winter 2022 #4374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4374,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4374.png",
  },
  "4375": {
    name: "tiny dinos winter 2022 #4375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4375,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4375.png",
  },
  "4376": {
    name: "tiny dinos winter 2022 #4376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4376,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4376.png",
  },
  "4377": {
    name: "tiny dinos winter 2022 #4377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4377,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4377.png",
  },
  "4378": {
    name: "tiny dinos winter 2022 #4378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4378,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4378.png",
  },
  "4379": {
    name: "tiny dinos winter 2022 #4379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4379,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4379.png",
  },
  "4380": {
    name: "tiny dinos winter 2022 #4380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4380,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4380.png",
  },
  "4381": {
    name: "tiny dinos winter 2022 #4381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4381,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4381.png",
  },
  "4382": {
    name: "tiny dinos winter 2022 #4382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4382,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4382.png",
  },
  "4383": {
    name: "tiny dinos winter 2022 #4383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4383,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4383.png",
  },
  "4384": {
    name: "tiny dinos winter 2022 #4384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4384,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4384.png",
  },
  "4385": {
    name: "tiny dinos winter 2022 #4385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4385,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4385.png",
  },
  "4386": {
    name: "tiny dinos winter 2022 #4386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4386,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4386.png",
  },
  "4387": {
    name: "tiny dinos winter 2022 #4387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4387,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4387.png",
  },
  "4388": {
    name: "tiny dinos winter 2022 #4388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4388,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4388.png",
  },
  "4389": {
    name: "tiny dinos winter 2022 #4389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4389,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4389.png",
  },
  "4390": {
    name: "tiny dinos winter 2022 #4390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4390,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4390.png",
  },
  "4391": {
    name: "tiny dinos winter 2022 #4391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4391,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4391.png",
  },
  "4392": {
    name: "tiny dinos winter 2022 #4392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4392,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4392.png",
  },
  "4393": {
    name: "tiny dinos winter 2022 #4393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4393,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4393.png",
  },
  "4394": {
    name: "tiny dinos winter 2022 #4394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4394,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4394.png",
  },
  "4395": {
    name: "tiny dinos winter 2022 #4395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4395,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4395.png",
  },
  "4396": {
    name: "tiny dinos winter 2022 #4396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4396,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4396.png",
  },
  "4397": {
    name: "tiny dinos winter 2022 #4397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4397,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4397.png",
  },
  "4398": {
    name: "tiny dinos winter 2022 #4398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4398,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4398.png",
  },
  "4399": {
    name: "tiny dinos winter 2022 #4399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4399,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4399.png",
  },
  "4400": {
    name: "tiny dinos winter 2022 #4400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4400,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4400.png",
  },
  "4401": {
    name: "tiny dinos winter 2022 #4401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4401,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4401.png",
  },
  "4402": {
    name: "tiny dinos winter 2022 #4402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4402,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4402.png",
  },
  "4403": {
    name: "tiny dinos winter 2022 #4403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4403,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4403.png",
  },
  "4404": {
    name: "tiny dinos winter 2022 #4404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4404,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4404.png",
  },
  "4405": {
    name: "tiny dinos winter 2022 #4405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4405,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4405.png",
  },
  "4406": {
    name: "tiny dinos winter 2022 #4406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4406,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4406.png",
  },
  "4407": {
    name: "tiny dinos winter 2022 #4407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4407,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4407.png",
  },
  "4408": {
    name: "tiny dinos winter 2022 #4408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4408,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4408.png",
  },
  "4409": {
    name: "tiny dinos winter 2022 #4409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4409,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4409.png",
  },
  "4410": {
    name: "tiny dinos winter 2022 #4410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4410,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4410.png",
  },
  "4411": {
    name: "tiny dinos winter 2022 #4411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4411,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4411.png",
  },
  "4412": {
    name: "tiny dinos winter 2022 #4412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4412,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4412.png",
  },
  "4413": {
    name: "tiny dinos winter 2022 #4413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4413,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4413.png",
  },
  "4414": {
    name: "tiny dinos winter 2022 #4414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4414,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4414.png",
  },
  "4415": {
    name: "tiny dinos winter 2022 #4415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4415,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4415.png",
  },
  "4416": {
    name: "tiny dinos winter 2022 #4416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4416,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4416.png",
  },
  "4417": {
    name: "tiny dinos winter 2022 #4417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4417,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4417.png",
  },
  "4418": {
    name: "tiny dinos winter 2022 #4418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4418,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4418.png",
  },
  "4419": {
    name: "tiny dinos winter 2022 #4419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4419,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4419.png",
  },
  "4420": {
    name: "tiny dinos winter 2022 #4420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4420,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4420.png",
  },
  "4421": {
    name: "tiny dinos winter 2022 #4421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4421,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4421.png",
  },
  "4422": {
    name: "tiny dinos winter 2022 #4422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4422,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4422.png",
  },
  "4423": {
    name: "tiny dinos winter 2022 #4423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4423,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4423.png",
  },
  "4424": {
    name: "tiny dinos winter 2022 #4424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4424,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4424.png",
  },
  "4425": {
    name: "tiny dinos winter 2022 #4425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4425,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4425.png",
  },
  "4426": {
    name: "tiny dinos winter 2022 #4426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4426,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4426.png",
  },
  "4427": {
    name: "tiny dinos winter 2022 #4427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4427,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4427.png",
  },
  "4428": {
    name: "tiny dinos winter 2022 #4428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4428,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4428.png",
  },
  "4429": {
    name: "tiny dinos winter 2022 #4429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4429,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4429.png",
  },
  "4430": {
    name: "tiny dinos winter 2022 #4430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4430,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4430.png",
  },
  "4431": {
    name: "tiny dinos winter 2022 #4431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4431,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4431.png",
  },
  "4432": {
    name: "tiny dinos winter 2022 #4432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4432,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4432.png",
  },
  "4433": {
    name: "tiny dinos winter 2022 #4433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4433,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4433.png",
  },
  "4434": {
    name: "tiny dinos winter 2022 #4434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4434,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4434.png",
  },
  "4435": {
    name: "tiny dinos winter 2022 #4435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4435,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4435.png",
  },
  "4436": {
    name: "tiny dinos winter 2022 #4436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4436,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4436.png",
  },
  "4437": {
    name: "tiny dinos winter 2022 #4437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4437,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4437.png",
  },
  "4438": {
    name: "tiny dinos winter 2022 #4438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4438,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4438.png",
  },
  "4439": {
    name: "tiny dinos winter 2022 #4439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4439,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4439.png",
  },
  "4440": {
    name: "tiny dinos winter 2022 #4440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4440,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4440.png",
  },
  "4441": {
    name: "tiny dinos winter 2022 #4441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4441,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4441.png",
  },
  "4442": {
    name: "tiny dinos winter 2022 #4442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4442,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4442.png",
  },
  "4443": {
    name: "tiny dinos winter 2022 #4443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4443,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4443.png",
  },
  "4444": {
    name: "tiny dinos winter 2022 #4444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4444,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4444.png",
  },
  "4445": {
    name: "tiny dinos winter 2022 #4445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4445,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4445.png",
  },
  "4446": {
    name: "tiny dinos winter 2022 #4446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4446,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4446.png",
  },
  "4447": {
    name: "tiny dinos winter 2022 #4447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4447,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4447.png",
  },
  "4448": {
    name: "tiny dinos winter 2022 #4448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4448,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4448.png",
  },
  "4449": {
    name: "tiny dinos winter 2022 #4449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4449,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4449.png",
  },
  "4450": {
    name: "tiny dinos winter 2022 #4450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4450,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4450.png",
  },
  "4451": {
    name: "tiny dinos winter 2022 #4451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4451,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4451.png",
  },
  "4452": {
    name: "tiny dinos winter 2022 #4452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4452,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4452.png",
  },
  "4453": {
    name: "tiny dinos winter 2022 #4453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4453,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4453.png",
  },
  "4454": {
    name: "tiny dinos winter 2022 #4454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4454,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4454.png",
  },
  "4455": {
    name: "tiny dinos winter 2022 #4455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4455,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4455.png",
  },
  "4456": {
    name: "tiny dinos winter 2022 #4456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4456,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4456.png",
  },
  "4457": {
    name: "tiny dinos winter 2022 #4457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4457,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4457.png",
  },
  "4458": {
    name: "tiny dinos winter 2022 #4458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4458,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4458.png",
  },
  "4459": {
    name: "tiny dinos winter 2022 #4459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4459,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4459.png",
  },
  "4460": {
    name: "tiny dinos winter 2022 #4460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4460,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4460.png",
  },
  "4461": {
    name: "tiny dinos winter 2022 #4461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4461,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4461.png",
  },
  "4462": {
    name: "tiny dinos winter 2022 #4462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4462,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4462.png",
  },
  "4463": {
    name: "tiny dinos winter 2022 #4463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4463,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4463.png",
  },
  "4464": {
    name: "tiny dinos winter 2022 #4464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4464,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4464.png",
  },
  "4465": {
    name: "tiny dinos winter 2022 #4465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4465,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4465.png",
  },
  "4466": {
    name: "tiny dinos winter 2022 #4466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4466,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4466.png",
  },
  "4467": {
    name: "tiny dinos winter 2022 #4467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4467,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4467.png",
  },
  "4468": {
    name: "tiny dinos winter 2022 #4468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4468,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4468.png",
  },
  "4469": {
    name: "tiny dinos winter 2022 #4469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4469,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4469.png",
  },
  "4470": {
    name: "tiny dinos winter 2022 #4470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4470,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4470.png",
  },
  "4471": {
    name: "tiny dinos winter 2022 #4471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4471,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4471.png",
  },
  "4472": {
    name: "tiny dinos winter 2022 #4472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4472,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4472.png",
  },
  "4473": {
    name: "tiny dinos winter 2022 #4473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4473,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4473.png",
  },
  "4474": {
    name: "tiny dinos winter 2022 #4474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4474,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4474.png",
  },
  "4475": {
    name: "tiny dinos winter 2022 #4475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4475,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4475.png",
  },
  "4476": {
    name: "tiny dinos winter 2022 #4476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4476,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4476.png",
  },
  "4477": {
    name: "tiny dinos winter 2022 #4477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4477,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4477.png",
  },
  "4478": {
    name: "tiny dinos winter 2022 #4478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4478,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4478.png",
  },
  "4479": {
    name: "tiny dinos winter 2022 #4479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4479,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4479.png",
  },
  "4480": {
    name: "tiny dinos winter 2022 #4480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4480,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4480.png",
  },
  "4481": {
    name: "tiny dinos winter 2022 #4481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4481,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4481.png",
  },
  "4482": {
    name: "tiny dinos winter 2022 #4482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4482,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4482.png",
  },
  "4483": {
    name: "tiny dinos winter 2022 #4483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4483,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4483.png",
  },
  "4484": {
    name: "tiny dinos winter 2022 #4484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4484,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4484.png",
  },
  "4485": {
    name: "tiny dinos winter 2022 #4485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4485,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4485.png",
  },
  "4486": {
    name: "tiny dinos winter 2022 #4486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4486,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4486.png",
  },
  "4487": {
    name: "tiny dinos winter 2022 #4487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4487,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4487.png",
  },
  "4488": {
    name: "tiny dinos winter 2022 #4488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4488,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4488.png",
  },
  "4489": {
    name: "tiny dinos winter 2022 #4489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4489,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4489.png",
  },
  "4490": {
    name: "tiny dinos winter 2022 #4490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4490,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4490.png",
  },
  "4491": {
    name: "tiny dinos winter 2022 #4491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4491,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4491.png",
  },
  "4492": {
    name: "tiny dinos winter 2022 #4492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4492,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4492.png",
  },
  "4493": {
    name: "tiny dinos winter 2022 #4493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4493,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4493.png",
  },
  "4494": {
    name: "tiny dinos winter 2022 #4494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4494,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4494.png",
  },
  "4495": {
    name: "tiny dinos winter 2022 #4495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4495,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4495.png",
  },
  "4496": {
    name: "tiny dinos winter 2022 #4496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4496,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4496.png",
  },
  "4497": {
    name: "tiny dinos winter 2022 #4497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4497,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4497.png",
  },
  "4498": {
    name: "tiny dinos winter 2022 #4498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4498,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4498.png",
  },
  "4499": {
    name: "tiny dinos winter 2022 #4499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4499,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4499.png",
  },
  "4500": {
    name: "tiny dinos winter 2022 #4500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4500,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4500.png",
  },
  "4501": {
    name: "tiny dinos winter 2022 #4501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4501,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4501.png",
  },
  "4502": {
    name: "tiny dinos winter 2022 #4502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4502,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4502.png",
  },
  "4503": {
    name: "tiny dinos winter 2022 #4503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4503,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4503.png",
  },
  "4504": {
    name: "tiny dinos winter 2022 #4504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4504,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4504.png",
  },
  "4505": {
    name: "tiny dinos winter 2022 #4505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4505,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4505.png",
  },
  "4506": {
    name: "tiny dinos winter 2022 #4506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4506,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4506.png",
  },
  "4507": {
    name: "tiny dinos winter 2022 #4507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4507,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4507.png",
  },
  "4508": {
    name: "tiny dinos winter 2022 #4508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4508,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4508.png",
  },
  "4509": {
    name: "tiny dinos winter 2022 #4509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4509,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4509.png",
  },
  "4510": {
    name: "tiny dinos winter 2022 #4510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4510,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4510.png",
  },
  "4511": {
    name: "tiny dinos winter 2022 #4511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4511,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4511.png",
  },
  "4512": {
    name: "tiny dinos winter 2022 #4512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4512,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4512.png",
  },
  "4513": {
    name: "tiny dinos winter 2022 #4513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4513,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4513.png",
  },
  "4514": {
    name: "tiny dinos winter 2022 #4514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4514,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4514.png",
  },
  "4515": {
    name: "tiny dinos winter 2022 #4515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4515,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4515.png",
  },
  "4516": {
    name: "tiny dinos winter 2022 #4516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4516,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4516.png",
  },
  "4517": {
    name: "tiny dinos winter 2022 #4517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4517,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4517.png",
  },
  "4518": {
    name: "tiny dinos winter 2022 #4518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4518,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4518.png",
  },
  "4519": {
    name: "tiny dinos winter 2022 #4519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4519,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4519.png",
  },
  "4520": {
    name: "tiny dinos winter 2022 #4520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4520,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4520.png",
  },
  "4521": {
    name: "tiny dinos winter 2022 #4521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4521,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4521.png",
  },
  "4522": {
    name: "tiny dinos winter 2022 #4522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4522,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4522.png",
  },
  "4523": {
    name: "tiny dinos winter 2022 #4523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4523,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4523.png",
  },
  "4524": {
    name: "tiny dinos winter 2022 #4524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4524,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4524.png",
  },
  "4525": {
    name: "tiny dinos winter 2022 #4525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4525,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4525.png",
  },
  "4526": {
    name: "tiny dinos winter 2022 #4526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4526,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4526.png",
  },
  "4527": {
    name: "tiny dinos winter 2022 #4527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4527,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4527.png",
  },
  "4528": {
    name: "tiny dinos winter 2022 #4528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4528,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4528.png",
  },
  "4529": {
    name: "tiny dinos winter 2022 #4529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4529,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4529.png",
  },
  "4530": {
    name: "tiny dinos winter 2022 #4530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4530,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4530.png",
  },
  "4531": {
    name: "tiny dinos winter 2022 #4531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4531,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4531.png",
  },
  "4532": {
    name: "tiny dinos winter 2022 #4532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4532,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4532.png",
  },
  "4533": {
    name: "tiny dinos winter 2022 #4533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4533,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4533.png",
  },
  "4534": {
    name: "tiny dinos winter 2022 #4534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4534,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4534.png",
  },
  "4535": {
    name: "tiny dinos winter 2022 #4535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4535,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4535.png",
  },
  "4536": {
    name: "tiny dinos winter 2022 #4536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4536,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4536.png",
  },
  "4537": {
    name: "tiny dinos winter 2022 #4537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4537,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4537.png",
  },
  "4538": {
    name: "tiny dinos winter 2022 #4538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4538,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4538.png",
  },
  "4539": {
    name: "tiny dinos winter 2022 #4539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4539,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4539.png",
  },
  "4540": {
    name: "tiny dinos winter 2022 #4540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4540,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4540.png",
  },
  "4541": {
    name: "tiny dinos winter 2022 #4541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4541,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4541.png",
  },
  "4542": {
    name: "tiny dinos winter 2022 #4542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4542,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4542.png",
  },
  "4543": {
    name: "tiny dinos winter 2022 #4543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4543,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4543.png",
  },
  "4544": {
    name: "tiny dinos winter 2022 #4544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4544,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4544.png",
  },
  "4545": {
    name: "tiny dinos winter 2022 #4545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4545,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4545.png",
  },
  "4546": {
    name: "tiny dinos winter 2022 #4546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4546,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4546.png",
  },
  "4547": {
    name: "tiny dinos winter 2022 #4547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4547,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4547.png",
  },
  "4548": {
    name: "tiny dinos winter 2022 #4548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4548,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4548.png",
  },
  "4549": {
    name: "tiny dinos winter 2022 #4549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4549,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4549.png",
  },
  "4550": {
    name: "tiny dinos winter 2022 #4550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4550,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4550.png",
  },
  "4551": {
    name: "tiny dinos winter 2022 #4551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4551,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4551.png",
  },
  "4552": {
    name: "tiny dinos winter 2022 #4552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4552,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4552.png",
  },
  "4553": {
    name: "tiny dinos winter 2022 #4553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4553,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4553.png",
  },
  "4554": {
    name: "tiny dinos winter 2022 #4554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4554,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4554.png",
  },
  "4555": {
    name: "tiny dinos winter 2022 #4555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4555,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4555.png",
  },
  "4556": {
    name: "tiny dinos winter 2022 #4556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4556,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4556.png",
  },
  "4557": {
    name: "tiny dinos winter 2022 #4557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4557,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4557.png",
  },
  "4558": {
    name: "tiny dinos winter 2022 #4558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4558,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4558.png",
  },
  "4559": {
    name: "tiny dinos winter 2022 #4559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4559,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4559.png",
  },
  "4560": {
    name: "tiny dinos winter 2022 #4560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4560,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4560.png",
  },
  "4561": {
    name: "tiny dinos winter 2022 #4561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4561,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4561.png",
  },
  "4562": {
    name: "tiny dinos winter 2022 #4562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4562,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4562.png",
  },
  "4563": {
    name: "tiny dinos winter 2022 #4563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4563,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4563.png",
  },
  "4564": {
    name: "tiny dinos winter 2022 #4564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4564,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4564.png",
  },
  "4565": {
    name: "tiny dinos winter 2022 #4565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4565,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4565.png",
  },
  "4566": {
    name: "tiny dinos winter 2022 #4566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4566,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4566.png",
  },
  "4567": {
    name: "tiny dinos winter 2022 #4567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4567,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4567.png",
  },
  "4568": {
    name: "tiny dinos winter 2022 #4568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4568,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4568.png",
  },
  "4569": {
    name: "tiny dinos winter 2022 #4569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4569,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4569.png",
  },
  "4570": {
    name: "tiny dinos winter 2022 #4570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4570,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4570.png",
  },
  "4571": {
    name: "tiny dinos winter 2022 #4571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4571,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4571.png",
  },
  "4572": {
    name: "tiny dinos winter 2022 #4572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4572,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4572.png",
  },
  "4573": {
    name: "tiny dinos winter 2022 #4573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4573,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4573.png",
  },
  "4574": {
    name: "tiny dinos winter 2022 #4574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4574,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4574.png",
  },
  "4575": {
    name: "tiny dinos winter 2022 #4575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4575,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4575.png",
  },
  "4576": {
    name: "tiny dinos winter 2022 #4576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4576,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4576.png",
  },
  "4577": {
    name: "tiny dinos winter 2022 #4577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4577,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4577.png",
  },
  "4578": {
    name: "tiny dinos winter 2022 #4578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4578,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4578.png",
  },
  "4579": {
    name: "tiny dinos winter 2022 #4579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4579,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4579.png",
  },
  "4580": {
    name: "tiny dinos winter 2022 #4580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4580,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4580.png",
  },
  "4581": {
    name: "tiny dinos winter 2022 #4581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4581,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4581.png",
  },
  "4582": {
    name: "tiny dinos winter 2022 #4582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4582,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4582.png",
  },
  "4583": {
    name: "tiny dinos winter 2022 #4583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4583,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4583.png",
  },
  "4584": {
    name: "tiny dinos winter 2022 #4584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4584,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4584.png",
  },
  "4585": {
    name: "tiny dinos winter 2022 #4585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4585,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4585.png",
  },
  "4586": {
    name: "tiny dinos winter 2022 #4586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4586,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4586.png",
  },
  "4587": {
    name: "tiny dinos winter 2022 #4587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4587,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4587.png",
  },
  "4588": {
    name: "tiny dinos winter 2022 #4588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4588,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4588.png",
  },
  "4589": {
    name: "tiny dinos winter 2022 #4589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4589,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4589.png",
  },
  "4590": {
    name: "tiny dinos winter 2022 #4590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4590,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4590.png",
  },
  "4591": {
    name: "tiny dinos winter 2022 #4591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4591,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4591.png",
  },
  "4592": {
    name: "tiny dinos winter 2022 #4592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4592,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4592.png",
  },
  "4593": {
    name: "tiny dinos winter 2022 #4593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4593,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4593.png",
  },
  "4594": {
    name: "tiny dinos winter 2022 #4594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4594,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4594.png",
  },
  "4595": {
    name: "tiny dinos winter 2022 #4595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4595,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4595.png",
  },
  "4596": {
    name: "tiny dinos winter 2022 #4596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4596,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4596.png",
  },
  "4597": {
    name: "tiny dinos winter 2022 #4597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4597,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4597.png",
  },
  "4598": {
    name: "tiny dinos winter 2022 #4598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4598,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4598.png",
  },
  "4599": {
    name: "tiny dinos winter 2022 #4599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4599,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4599.png",
  },
  "4600": {
    name: "tiny dinos winter 2022 #4600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4600,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4600.png",
  },
  "4601": {
    name: "tiny dinos winter 2022 #4601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4601,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4601.png",
  },
  "4602": {
    name: "tiny dinos winter 2022 #4602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4602,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4602.png",
  },
  "4603": {
    name: "tiny dinos winter 2022 #4603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4603,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4603.png",
  },
  "4604": {
    name: "tiny dinos winter 2022 #4604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4604,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4604.png",
  },
  "4605": {
    name: "tiny dinos winter 2022 #4605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4605,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4605.png",
  },
  "4606": {
    name: "tiny dinos winter 2022 #4606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4606,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4606.png",
  },
  "4607": {
    name: "tiny dinos winter 2022 #4607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4607,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4607.png",
  },
  "4608": {
    name: "tiny dinos winter 2022 #4608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4608,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4608.png",
  },
  "4609": {
    name: "tiny dinos winter 2022 #4609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4609,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4609.png",
  },
  "4610": {
    name: "tiny dinos winter 2022 #4610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4610,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4610.png",
  },
  "4611": {
    name: "tiny dinos winter 2022 #4611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4611,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4611.png",
  },
  "4612": {
    name: "tiny dinos winter 2022 #4612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4612,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4612.png",
  },
  "4613": {
    name: "tiny dinos winter 2022 #4613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4613,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4613.png",
  },
  "4614": {
    name: "tiny dinos winter 2022 #4614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4614,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4614.png",
  },
  "4615": {
    name: "tiny dinos winter 2022 #4615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4615,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4615.png",
  },
  "4616": {
    name: "tiny dinos winter 2022 #4616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4616,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4616.png",
  },
  "4617": {
    name: "tiny dinos winter 2022 #4617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4617,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4617.png",
  },
  "4618": {
    name: "tiny dinos winter 2022 #4618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4618,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4618.png",
  },
  "4619": {
    name: "tiny dinos winter 2022 #4619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4619,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4619.png",
  },
  "4620": {
    name: "tiny dinos winter 2022 #4620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4620,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4620.png",
  },
  "4621": {
    name: "tiny dinos winter 2022 #4621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4621,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4621.png",
  },
  "4622": {
    name: "tiny dinos winter 2022 #4622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4622,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4622.png",
  },
  "4623": {
    name: "tiny dinos winter 2022 #4623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4623,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4623.png",
  },
  "4624": {
    name: "tiny dinos winter 2022 #4624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4624,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4624.png",
  },
  "4625": {
    name: "tiny dinos winter 2022 #4625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4625,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4625.png",
  },
  "4626": {
    name: "tiny dinos winter 2022 #4626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4626,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4626.png",
  },
  "4627": {
    name: "tiny dinos winter 2022 #4627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4627,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4627.png",
  },
  "4628": {
    name: "tiny dinos winter 2022 #4628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4628,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4628.png",
  },
  "4629": {
    name: "tiny dinos winter 2022 #4629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4629,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4629.png",
  },
  "4630": {
    name: "tiny dinos winter 2022 #4630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4630,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4630.png",
  },
  "4631": {
    name: "tiny dinos winter 2022 #4631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4631,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4631.png",
  },
  "4632": {
    name: "tiny dinos winter 2022 #4632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4632,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4632.png",
  },
  "4633": {
    name: "tiny dinos winter 2022 #4633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4633,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4633.png",
  },
  "4634": {
    name: "tiny dinos winter 2022 #4634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4634,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4634.png",
  },
  "4635": {
    name: "tiny dinos winter 2022 #4635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4635,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4635.png",
  },
  "4636": {
    name: "tiny dinos winter 2022 #4636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4636,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4636.png",
  },
  "4637": {
    name: "tiny dinos winter 2022 #4637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4637,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4637.png",
  },
  "4638": {
    name: "tiny dinos winter 2022 #4638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4638,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4638.png",
  },
  "4639": {
    name: "tiny dinos winter 2022 #4639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4639,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4639.png",
  },
  "4640": {
    name: "tiny dinos winter 2022 #4640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4640,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4640.png",
  },
  "4641": {
    name: "tiny dinos winter 2022 #4641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4641,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4641.png",
  },
  "4642": {
    name: "tiny dinos winter 2022 #4642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4642,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4642.png",
  },
  "4643": {
    name: "tiny dinos winter 2022 #4643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4643,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4643.png",
  },
  "4644": {
    name: "tiny dinos winter 2022 #4644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4644,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4644.png",
  },
  "4645": {
    name: "tiny dinos winter 2022 #4645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4645,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4645.png",
  },
  "4646": {
    name: "tiny dinos winter 2022 #4646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4646,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4646.png",
  },
  "4647": {
    name: "tiny dinos winter 2022 #4647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4647,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4647.png",
  },
  "4648": {
    name: "tiny dinos winter 2022 #4648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4648,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4648.png",
  },
  "4649": {
    name: "tiny dinos winter 2022 #4649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4649,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4649.png",
  },
  "4650": {
    name: "tiny dinos winter 2022 #4650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4650,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4650.png",
  },
  "4651": {
    name: "tiny dinos winter 2022 #4651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4651,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4651.png",
  },
  "4652": {
    name: "tiny dinos winter 2022 #4652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4652,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4652.png",
  },
  "4653": {
    name: "tiny dinos winter 2022 #4653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4653,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4653.png",
  },
  "4654": {
    name: "tiny dinos winter 2022 #4654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4654,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4654.png",
  },
  "4655": {
    name: "tiny dinos winter 2022 #4655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4655,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4655.png",
  },
  "4656": {
    name: "tiny dinos winter 2022 #4656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4656,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4656.png",
  },
  "4657": {
    name: "tiny dinos winter 2022 #4657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4657,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4657.png",
  },
  "4658": {
    name: "tiny dinos winter 2022 #4658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4658,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4658.png",
  },
  "4659": {
    name: "tiny dinos winter 2022 #4659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4659,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4659.png",
  },
  "4660": {
    name: "tiny dinos winter 2022 #4660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4660,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4660.png",
  },
  "4661": {
    name: "tiny dinos winter 2022 #4661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4661,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4661.png",
  },
  "4662": {
    name: "tiny dinos winter 2022 #4662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4662,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4662.png",
  },
  "4663": {
    name: "tiny dinos winter 2022 #4663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4663,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4663.png",
  },
  "4664": {
    name: "tiny dinos winter 2022 #4664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4664,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4664.png",
  },
  "4665": {
    name: "tiny dinos winter 2022 #4665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4665,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4665.png",
  },
  "4666": {
    name: "tiny dinos winter 2022 #4666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4666,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4666.png",
  },
  "4667": {
    name: "tiny dinos winter 2022 #4667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4667,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4667.png",
  },
  "4668": {
    name: "tiny dinos winter 2022 #4668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4668,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4668.png",
  },
  "4669": {
    name: "tiny dinos winter 2022 #4669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4669,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4669.png",
  },
  "4670": {
    name: "tiny dinos winter 2022 #4670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4670,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4670.png",
  },
  "4671": {
    name: "tiny dinos winter 2022 #4671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4671,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4671.png",
  },
  "4672": {
    name: "tiny dinos winter 2022 #4672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4672,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4672.png",
  },
  "4673": {
    name: "tiny dinos winter 2022 #4673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4673,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4673.png",
  },
  "4674": {
    name: "tiny dinos winter 2022 #4674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4674,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4674.png",
  },
  "4675": {
    name: "tiny dinos winter 2022 #4675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4675,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4675.png",
  },
  "4676": {
    name: "tiny dinos winter 2022 #4676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4676,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4676.png",
  },
  "4677": {
    name: "tiny dinos winter 2022 #4677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4677,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4677.png",
  },
  "4678": {
    name: "tiny dinos winter 2022 #4678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4678,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4678.png",
  },
  "4679": {
    name: "tiny dinos winter 2022 #4679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4679,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4679.png",
  },
  "4680": {
    name: "tiny dinos winter 2022 #4680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4680,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4680.png",
  },
  "4681": {
    name: "tiny dinos winter 2022 #4681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4681,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4681.png",
  },
  "4682": {
    name: "tiny dinos winter 2022 #4682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4682,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4682.png",
  },
  "4683": {
    name: "tiny dinos winter 2022 #4683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4683,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4683.png",
  },
  "4684": {
    name: "tiny dinos winter 2022 #4684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4684,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4684.png",
  },
  "4685": {
    name: "tiny dinos winter 2022 #4685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4685,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4685.png",
  },
  "4686": {
    name: "tiny dinos winter 2022 #4686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4686,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4686.png",
  },
  "4687": {
    name: "tiny dinos winter 2022 #4687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4687,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4687.png",
  },
  "4688": {
    name: "tiny dinos winter 2022 #4688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4688,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4688.png",
  },
  "4689": {
    name: "tiny dinos winter 2022 #4689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4689,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4689.png",
  },
  "4690": {
    name: "tiny dinos winter 2022 #4690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4690,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4690.png",
  },
  "4691": {
    name: "tiny dinos winter 2022 #4691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4691,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4691.png",
  },
  "4692": {
    name: "tiny dinos winter 2022 #4692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4692,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4692.png",
  },
  "4693": {
    name: "tiny dinos winter 2022 #4693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4693,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4693.png",
  },
  "4694": {
    name: "tiny dinos winter 2022 #4694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4694,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4694.png",
  },
  "4695": {
    name: "tiny dinos winter 2022 #4695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4695,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4695.png",
  },
  "4696": {
    name: "tiny dinos winter 2022 #4696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4696,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4696.png",
  },
  "4697": {
    name: "tiny dinos winter 2022 #4697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4697,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4697.png",
  },
  "4698": {
    name: "tiny dinos winter 2022 #4698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4698,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4698.png",
  },
  "4699": {
    name: "tiny dinos winter 2022 #4699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4699,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4699.png",
  },
  "4700": {
    name: "tiny dinos winter 2022 #4700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4700,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4700.png",
  },
  "4701": {
    name: "tiny dinos winter 2022 #4701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4701,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4701.png",
  },
  "4702": {
    name: "tiny dinos winter 2022 #4702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4702,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4702.png",
  },
  "4703": {
    name: "tiny dinos winter 2022 #4703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4703,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4703.png",
  },
  "4704": {
    name: "tiny dinos winter 2022 #4704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4704,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4704.png",
  },
  "4705": {
    name: "tiny dinos winter 2022 #4705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4705,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4705.png",
  },
  "4706": {
    name: "tiny dinos winter 2022 #4706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4706,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4706.png",
  },
  "4707": {
    name: "tiny dinos winter 2022 #4707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4707,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4707.png",
  },
  "4708": {
    name: "tiny dinos winter 2022 #4708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4708,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4708.png",
  },
  "4709": {
    name: "tiny dinos winter 2022 #4709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4709,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4709.png",
  },
  "4710": {
    name: "tiny dinos winter 2022 #4710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4710,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4710.png",
  },
  "4711": {
    name: "tiny dinos winter 2022 #4711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4711,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4711.png",
  },
  "4712": {
    name: "tiny dinos winter 2022 #4712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4712,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4712.png",
  },
  "4713": {
    name: "tiny dinos winter 2022 #4713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4713,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4713.png",
  },
  "4714": {
    name: "tiny dinos winter 2022 #4714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4714,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4714.png",
  },
  "4715": {
    name: "tiny dinos winter 2022 #4715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4715,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4715.png",
  },
  "4716": {
    name: "tiny dinos winter 2022 #4716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4716,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4716.png",
  },
  "4717": {
    name: "tiny dinos winter 2022 #4717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4717,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4717.png",
  },
  "4718": {
    name: "tiny dinos winter 2022 #4718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4718,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4718.png",
  },
  "4719": {
    name: "tiny dinos winter 2022 #4719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4719,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4719.png",
  },
  "4720": {
    name: "tiny dinos winter 2022 #4720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4720,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4720.png",
  },
  "4721": {
    name: "tiny dinos winter 2022 #4721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4721,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4721.png",
  },
  "4722": {
    name: "tiny dinos winter 2022 #4722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4722,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4722.png",
  },
  "4723": {
    name: "tiny dinos winter 2022 #4723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4723,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4723.png",
  },
  "4724": {
    name: "tiny dinos winter 2022 #4724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4724,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4724.png",
  },
  "4725": {
    name: "tiny dinos winter 2022 #4725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4725,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4725.png",
  },
  "4726": {
    name: "tiny dinos winter 2022 #4726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4726,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4726.png",
  },
  "4727": {
    name: "tiny dinos winter 2022 #4727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4727,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4727.png",
  },
  "4728": {
    name: "tiny dinos winter 2022 #4728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4728,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4728.png",
  },
  "4729": {
    name: "tiny dinos winter 2022 #4729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4729,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4729.png",
  },
  "4730": {
    name: "tiny dinos winter 2022 #4730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4730,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4730.png",
  },
  "4731": {
    name: "tiny dinos winter 2022 #4731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4731,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4731.png",
  },
  "4732": {
    name: "tiny dinos winter 2022 #4732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4732,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4732.png",
  },
  "4733": {
    name: "tiny dinos winter 2022 #4733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4733,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4733.png",
  },
  "4734": {
    name: "tiny dinos winter 2022 #4734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4734,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4734.png",
  },
  "4735": {
    name: "tiny dinos winter 2022 #4735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4735,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4735.png",
  },
  "4736": {
    name: "tiny dinos winter 2022 #4736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4736,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4736.png",
  },
  "4737": {
    name: "tiny dinos winter 2022 #4737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4737,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4737.png",
  },
  "4738": {
    name: "tiny dinos winter 2022 #4738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4738,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4738.png",
  },
  "4739": {
    name: "tiny dinos winter 2022 #4739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4739,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4739.png",
  },
  "4740": {
    name: "tiny dinos winter 2022 #4740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4740,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4740.png",
  },
  "4741": {
    name: "tiny dinos winter 2022 #4741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4741,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4741.png",
  },
  "4742": {
    name: "tiny dinos winter 2022 #4742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4742,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4742.png",
  },
  "4743": {
    name: "tiny dinos winter 2022 #4743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4743,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4743.png",
  },
  "4744": {
    name: "tiny dinos winter 2022 #4744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4744,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4744.png",
  },
  "4745": {
    name: "tiny dinos winter 2022 #4745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4745,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4745.png",
  },
  "4746": {
    name: "tiny dinos winter 2022 #4746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4746,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4746.png",
  },
  "4747": {
    name: "tiny dinos winter 2022 #4747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4747,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4747.png",
  },
  "4748": {
    name: "tiny dinos winter 2022 #4748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4748,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4748.png",
  },
  "4749": {
    name: "tiny dinos winter 2022 #4749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4749,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4749.png",
  },
  "4750": {
    name: "tiny dinos winter 2022 #4750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4750,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4750.png",
  },
  "4751": {
    name: "tiny dinos winter 2022 #4751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4751,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4751.png",
  },
  "4752": {
    name: "tiny dinos winter 2022 #4752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4752,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4752.png",
  },
  "4753": {
    name: "tiny dinos winter 2022 #4753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4753,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4753.png",
  },
  "4754": {
    name: "tiny dinos winter 2022 #4754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4754,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4754.png",
  },
  "4755": {
    name: "tiny dinos winter 2022 #4755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4755,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4755.png",
  },
  "4756": {
    name: "tiny dinos winter 2022 #4756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4756,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4756.png",
  },
  "4757": {
    name: "tiny dinos winter 2022 #4757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4757,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4757.png",
  },
  "4758": {
    name: "tiny dinos winter 2022 #4758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4758,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4758.png",
  },
  "4759": {
    name: "tiny dinos winter 2022 #4759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4759,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4759.png",
  },
  "4760": {
    name: "tiny dinos winter 2022 #4760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4760,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4760.png",
  },
  "4761": {
    name: "tiny dinos winter 2022 #4761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4761,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4761.png",
  },
  "4762": {
    name: "tiny dinos winter 2022 #4762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4762,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4762.png",
  },
  "4763": {
    name: "tiny dinos winter 2022 #4763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4763,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4763.png",
  },
  "4764": {
    name: "tiny dinos winter 2022 #4764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4764,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4764.png",
  },
  "4765": {
    name: "tiny dinos winter 2022 #4765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4765,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4765.png",
  },
  "4766": {
    name: "tiny dinos winter 2022 #4766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4766,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4766.png",
  },
  "4767": {
    name: "tiny dinos winter 2022 #4767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4767,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4767.png",
  },
  "4768": {
    name: "tiny dinos winter 2022 #4768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4768,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4768.png",
  },
  "4769": {
    name: "tiny dinos winter 2022 #4769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4769,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4769.png",
  },
  "4770": {
    name: "tiny dinos winter 2022 #4770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4770,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4770.png",
  },
  "4771": {
    name: "tiny dinos winter 2022 #4771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4771,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4771.png",
  },
  "4772": {
    name: "tiny dinos winter 2022 #4772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4772,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4772.png",
  },
  "4773": {
    name: "tiny dinos winter 2022 #4773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4773,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4773.png",
  },
  "4774": {
    name: "tiny dinos winter 2022 #4774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4774,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4774.png",
  },
  "4775": {
    name: "tiny dinos winter 2022 #4775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4775,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4775.png",
  },
  "4776": {
    name: "tiny dinos winter 2022 #4776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4776,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4776.png",
  },
  "4777": {
    name: "tiny dinos winter 2022 #4777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4777,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4777.png",
  },
  "4778": {
    name: "tiny dinos winter 2022 #4778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4778,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4778.png",
  },
  "4779": {
    name: "tiny dinos winter 2022 #4779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4779,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4779.png",
  },
  "4780": {
    name: "tiny dinos winter 2022 #4780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4780,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4780.png",
  },
  "4781": {
    name: "tiny dinos winter 2022 #4781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4781,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4781.png",
  },
  "4782": {
    name: "tiny dinos winter 2022 #4782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4782,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4782.png",
  },
  "4783": {
    name: "tiny dinos winter 2022 #4783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4783,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4783.png",
  },
  "4784": {
    name: "tiny dinos winter 2022 #4784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4784,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4784.png",
  },
  "4785": {
    name: "tiny dinos winter 2022 #4785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4785,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4785.png",
  },
  "4786": {
    name: "tiny dinos winter 2022 #4786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4786,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4786.png",
  },
  "4787": {
    name: "tiny dinos winter 2022 #4787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4787,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4787.png",
  },
  "4788": {
    name: "tiny dinos winter 2022 #4788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4788,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4788.png",
  },
  "4789": {
    name: "tiny dinos winter 2022 #4789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4789,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4789.png",
  },
  "4790": {
    name: "tiny dinos winter 2022 #4790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4790,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4790.png",
  },
  "4791": {
    name: "tiny dinos winter 2022 #4791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4791,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4791.png",
  },
  "4792": {
    name: "tiny dinos winter 2022 #4792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4792,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4792.png",
  },
  "4793": {
    name: "tiny dinos winter 2022 #4793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4793,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4793.png",
  },
  "4794": {
    name: "tiny dinos winter 2022 #4794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4794,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4794.png",
  },
  "4795": {
    name: "tiny dinos winter 2022 #4795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4795,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4795.png",
  },
  "4796": {
    name: "tiny dinos winter 2022 #4796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4796,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4796.png",
  },
  "4797": {
    name: "tiny dinos winter 2022 #4797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4797,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4797.png",
  },
  "4798": {
    name: "tiny dinos winter 2022 #4798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4798,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4798.png",
  },
  "4799": {
    name: "tiny dinos winter 2022 #4799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4799,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4799.png",
  },
  "4800": {
    name: "tiny dinos winter 2022 #4800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4800,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4800.png",
  },
  "4801": {
    name: "tiny dinos winter 2022 #4801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4801,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4801.png",
  },
  "4802": {
    name: "tiny dinos winter 2022 #4802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4802,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4802.png",
  },
  "4803": {
    name: "tiny dinos winter 2022 #4803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4803,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4803.png",
  },
  "4804": {
    name: "tiny dinos winter 2022 #4804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4804,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4804.png",
  },
  "4805": {
    name: "tiny dinos winter 2022 #4805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4805,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4805.png",
  },
  "4806": {
    name: "tiny dinos winter 2022 #4806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4806,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4806.png",
  },
  "4807": {
    name: "tiny dinos winter 2022 #4807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4807,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4807.png",
  },
  "4808": {
    name: "tiny dinos winter 2022 #4808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4808,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4808.png",
  },
  "4809": {
    name: "tiny dinos winter 2022 #4809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4809,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4809.png",
  },
  "4810": {
    name: "tiny dinos winter 2022 #4810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4810,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4810.png",
  },
  "4811": {
    name: "tiny dinos winter 2022 #4811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4811,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4811.png",
  },
  "4812": {
    name: "tiny dinos winter 2022 #4812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4812,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4812.png",
  },
  "4813": {
    name: "tiny dinos winter 2022 #4813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4813,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4813.png",
  },
  "4814": {
    name: "tiny dinos winter 2022 #4814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4814,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4814.png",
  },
  "4815": {
    name: "tiny dinos winter 2022 #4815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4815,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4815.png",
  },
  "4816": {
    name: "tiny dinos winter 2022 #4816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4816,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4816.png",
  },
  "4817": {
    name: "tiny dinos winter 2022 #4817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4817,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4817.png",
  },
  "4818": {
    name: "tiny dinos winter 2022 #4818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4818,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4818.png",
  },
  "4819": {
    name: "tiny dinos winter 2022 #4819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4819,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4819.png",
  },
  "4820": {
    name: "tiny dinos winter 2022 #4820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4820,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4820.png",
  },
  "4821": {
    name: "tiny dinos winter 2022 #4821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4821,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4821.png",
  },
  "4822": {
    name: "tiny dinos winter 2022 #4822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4822,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4822.png",
  },
  "4823": {
    name: "tiny dinos winter 2022 #4823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4823,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4823.png",
  },
  "4824": {
    name: "tiny dinos winter 2022 #4824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4824,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4824.png",
  },
  "4825": {
    name: "tiny dinos winter 2022 #4825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4825,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4825.png",
  },
  "4826": {
    name: "tiny dinos winter 2022 #4826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4826,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4826.png",
  },
  "4827": {
    name: "tiny dinos winter 2022 #4827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4827,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4827.png",
  },
  "4828": {
    name: "tiny dinos winter 2022 #4828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4828,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4828.png",
  },
  "4829": {
    name: "tiny dinos winter 2022 #4829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4829,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4829.png",
  },
  "4830": {
    name: "tiny dinos winter 2022 #4830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4830,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4830.png",
  },
  "4831": {
    name: "tiny dinos winter 2022 #4831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4831,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4831.png",
  },
  "4832": {
    name: "tiny dinos winter 2022 #4832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4832,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4832.png",
  },
  "4833": {
    name: "tiny dinos winter 2022 #4833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4833,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4833.png",
  },
  "4834": {
    name: "tiny dinos winter 2022 #4834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4834,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4834.png",
  },
  "4835": {
    name: "tiny dinos winter 2022 #4835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4835,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4835.png",
  },
  "4836": {
    name: "tiny dinos winter 2022 #4836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4836,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4836.png",
  },
  "4837": {
    name: "tiny dinos winter 2022 #4837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4837,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4837.png",
  },
  "4838": {
    name: "tiny dinos winter 2022 #4838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4838,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4838.png",
  },
  "4839": {
    name: "tiny dinos winter 2022 #4839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4839,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4839.png",
  },
  "4840": {
    name: "tiny dinos winter 2022 #4840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4840,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4840.png",
  },
  "4841": {
    name: "tiny dinos winter 2022 #4841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4841,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4841.png",
  },
  "4842": {
    name: "tiny dinos winter 2022 #4842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4842,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4842.png",
  },
  "4843": {
    name: "tiny dinos winter 2022 #4843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4843,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4843.png",
  },
  "4844": {
    name: "tiny dinos winter 2022 #4844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4844,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4844.png",
  },
  "4845": {
    name: "tiny dinos winter 2022 #4845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4845,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4845.png",
  },
  "4846": {
    name: "tiny dinos winter 2022 #4846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4846,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4846.png",
  },
  "4847": {
    name: "tiny dinos winter 2022 #4847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4847,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4847.png",
  },
  "4848": {
    name: "tiny dinos winter 2022 #4848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4848,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4848.png",
  },
  "4849": {
    name: "tiny dinos winter 2022 #4849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4849,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4849.png",
  },
  "4850": {
    name: "tiny dinos winter 2022 #4850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4850,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4850.png",
  },
  "4851": {
    name: "tiny dinos winter 2022 #4851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4851,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4851.png",
  },
  "4852": {
    name: "tiny dinos winter 2022 #4852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4852,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4852.png",
  },
  "4853": {
    name: "tiny dinos winter 2022 #4853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4853,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4853.png",
  },
  "4854": {
    name: "tiny dinos winter 2022 #4854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4854,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4854.png",
  },
  "4855": {
    name: "tiny dinos winter 2022 #4855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4855,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4855.png",
  },
  "4856": {
    name: "tiny dinos winter 2022 #4856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4856,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4856.png",
  },
  "4857": {
    name: "tiny dinos winter 2022 #4857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4857,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4857.png",
  },
  "4858": {
    name: "tiny dinos winter 2022 #4858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4858,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4858.png",
  },
  "4859": {
    name: "tiny dinos winter 2022 #4859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4859,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4859.png",
  },
  "4860": {
    name: "tiny dinos winter 2022 #4860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4860,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4860.png",
  },
  "4861": {
    name: "tiny dinos winter 2022 #4861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4861,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4861.png",
  },
  "4862": {
    name: "tiny dinos winter 2022 #4862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4862,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4862.png",
  },
  "4863": {
    name: "tiny dinos winter 2022 #4863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4863,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4863.png",
  },
  "4864": {
    name: "tiny dinos winter 2022 #4864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4864,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4864.png",
  },
  "4865": {
    name: "tiny dinos winter 2022 #4865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4865,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4865.png",
  },
  "4866": {
    name: "tiny dinos winter 2022 #4866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4866,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4866.png",
  },
  "4867": {
    name: "tiny dinos winter 2022 #4867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4867,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4867.png",
  },
  "4868": {
    name: "tiny dinos winter 2022 #4868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4868,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4868.png",
  },
  "4869": {
    name: "tiny dinos winter 2022 #4869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4869,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4869.png",
  },
  "4870": {
    name: "tiny dinos winter 2022 #4870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4870,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4870.png",
  },
  "4871": {
    name: "tiny dinos winter 2022 #4871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4871,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4871.png",
  },
  "4872": {
    name: "tiny dinos winter 2022 #4872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4872,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4872.png",
  },
  "4873": {
    name: "tiny dinos winter 2022 #4873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4873,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4873.png",
  },
  "4874": {
    name: "tiny dinos winter 2022 #4874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4874,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4874.png",
  },
  "4875": {
    name: "tiny dinos winter 2022 #4875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4875,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4875.png",
  },
  "4876": {
    name: "tiny dinos winter 2022 #4876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4876,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4876.png",
  },
  "4877": {
    name: "tiny dinos winter 2022 #4877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4877,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4877.png",
  },
  "4878": {
    name: "tiny dinos winter 2022 #4878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4878,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4878.png",
  },
  "4879": {
    name: "tiny dinos winter 2022 #4879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4879,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4879.png",
  },
  "4880": {
    name: "tiny dinos winter 2022 #4880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4880,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4880.png",
  },
  "4881": {
    name: "tiny dinos winter 2022 #4881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4881,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4881.png",
  },
  "4882": {
    name: "tiny dinos winter 2022 #4882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4882,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4882.png",
  },
  "4883": {
    name: "tiny dinos winter 2022 #4883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4883,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4883.png",
  },
  "4884": {
    name: "tiny dinos winter 2022 #4884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4884,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4884.png",
  },
  "4885": {
    name: "tiny dinos winter 2022 #4885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4885,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4885.png",
  },
  "4886": {
    name: "tiny dinos winter 2022 #4886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4886,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4886.png",
  },
  "4887": {
    name: "tiny dinos winter 2022 #4887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4887,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4887.png",
  },
  "4888": {
    name: "tiny dinos winter 2022 #4888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4888,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4888.png",
  },
  "4889": {
    name: "tiny dinos winter 2022 #4889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4889,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4889.png",
  },
  "4890": {
    name: "tiny dinos winter 2022 #4890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4890,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4890.png",
  },
  "4891": {
    name: "tiny dinos winter 2022 #4891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4891,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4891.png",
  },
  "4892": {
    name: "tiny dinos winter 2022 #4892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4892,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4892.png",
  },
  "4893": {
    name: "tiny dinos winter 2022 #4893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4893,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4893.png",
  },
  "4894": {
    name: "tiny dinos winter 2022 #4894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4894,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4894.png",
  },
  "4895": {
    name: "tiny dinos winter 2022 #4895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4895,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4895.png",
  },
  "4896": {
    name: "tiny dinos winter 2022 #4896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4896,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4896.png",
  },
  "4897": {
    name: "tiny dinos winter 2022 #4897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4897,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4897.png",
  },
  "4898": {
    name: "tiny dinos winter 2022 #4898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4898,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4898.png",
  },
  "4899": {
    name: "tiny dinos winter 2022 #4899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4899,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4899.png",
  },
  "4900": {
    name: "tiny dinos winter 2022 #4900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4900,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4900.png",
  },
  "4901": {
    name: "tiny dinos winter 2022 #4901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4901,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4901.png",
  },
  "4902": {
    name: "tiny dinos winter 2022 #4902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4902,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4902.png",
  },
  "4903": {
    name: "tiny dinos winter 2022 #4903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4903,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4903.png",
  },
  "4904": {
    name: "tiny dinos winter 2022 #4904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4904,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4904.png",
  },
  "4905": {
    name: "tiny dinos winter 2022 #4905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4905,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4905.png",
  },
  "4906": {
    name: "tiny dinos winter 2022 #4906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4906,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4906.png",
  },
  "4907": {
    name: "tiny dinos winter 2022 #4907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4907,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4907.png",
  },
  "4908": {
    name: "tiny dinos winter 2022 #4908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4908,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4908.png",
  },
  "4909": {
    name: "tiny dinos winter 2022 #4909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4909,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4909.png",
  },
  "4910": {
    name: "tiny dinos winter 2022 #4910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4910,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4910.png",
  },
  "4911": {
    name: "tiny dinos winter 2022 #4911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4911,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4911.png",
  },
  "4912": {
    name: "tiny dinos winter 2022 #4912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4912,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4912.png",
  },
  "4913": {
    name: "tiny dinos winter 2022 #4913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4913,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4913.png",
  },
  "4914": {
    name: "tiny dinos winter 2022 #4914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4914,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4914.png",
  },
  "4915": {
    name: "tiny dinos winter 2022 #4915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4915,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4915.png",
  },
  "4916": {
    name: "tiny dinos winter 2022 #4916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4916,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4916.png",
  },
  "4917": {
    name: "tiny dinos winter 2022 #4917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4917,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4917.png",
  },
  "4918": {
    name: "tiny dinos winter 2022 #4918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4918,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4918.png",
  },
  "4919": {
    name: "tiny dinos winter 2022 #4919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4919,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4919.png",
  },
  "4920": {
    name: "tiny dinos winter 2022 #4920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4920,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4920.png",
  },
  "4921": {
    name: "tiny dinos winter 2022 #4921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4921,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4921.png",
  },
  "4922": {
    name: "tiny dinos winter 2022 #4922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4922,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4922.png",
  },
  "4923": {
    name: "tiny dinos winter 2022 #4923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4923,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4923.png",
  },
  "4924": {
    name: "tiny dinos winter 2022 #4924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4924,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4924.png",
  },
  "4925": {
    name: "tiny dinos winter 2022 #4925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4925,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4925.png",
  },
  "4926": {
    name: "tiny dinos winter 2022 #4926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4926,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4926.png",
  },
  "4927": {
    name: "tiny dinos winter 2022 #4927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4927,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4927.png",
  },
  "4928": {
    name: "tiny dinos winter 2022 #4928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4928,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4928.png",
  },
  "4929": {
    name: "tiny dinos winter 2022 #4929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4929,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4929.png",
  },
  "4930": {
    name: "tiny dinos winter 2022 #4930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4930,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4930.png",
  },
  "4931": {
    name: "tiny dinos winter 2022 #4931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4931,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4931.png",
  },
  "4932": {
    name: "tiny dinos winter 2022 #4932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4932,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4932.png",
  },
  "4933": {
    name: "tiny dinos winter 2022 #4933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4933,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4933.png",
  },
  "4934": {
    name: "tiny dinos winter 2022 #4934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4934,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4934.png",
  },
  "4935": {
    name: "tiny dinos winter 2022 #4935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4935,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4935.png",
  },
  "4936": {
    name: "tiny dinos winter 2022 #4936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4936,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4936.png",
  },
  "4937": {
    name: "tiny dinos winter 2022 #4937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4937,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4937.png",
  },
  "4938": {
    name: "tiny dinos winter 2022 #4938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4938,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4938.png",
  },
  "4939": {
    name: "tiny dinos winter 2022 #4939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4939,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4939.png",
  },
  "4940": {
    name: "tiny dinos winter 2022 #4940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4940,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4940.png",
  },
  "4941": {
    name: "tiny dinos winter 2022 #4941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4941,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4941.png",
  },
  "4942": {
    name: "tiny dinos winter 2022 #4942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4942,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4942.png",
  },
  "4943": {
    name: "tiny dinos winter 2022 #4943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4943,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4943.png",
  },
  "4944": {
    name: "tiny dinos winter 2022 #4944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4944,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4944.png",
  },
  "4945": {
    name: "tiny dinos winter 2022 #4945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4945,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4945.png",
  },
  "4946": {
    name: "tiny dinos winter 2022 #4946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4946,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4946.png",
  },
  "4947": {
    name: "tiny dinos winter 2022 #4947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4947,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4947.png",
  },
  "4948": {
    name: "tiny dinos winter 2022 #4948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4948,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4948.png",
  },
  "4949": {
    name: "tiny dinos winter 2022 #4949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4949,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4949.png",
  },
  "4950": {
    name: "tiny dinos winter 2022 #4950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4950,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4950.png",
  },
  "4951": {
    name: "tiny dinos winter 2022 #4951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4951,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4951.png",
  },
  "4952": {
    name: "tiny dinos winter 2022 #4952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4952,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4952.png",
  },
  "4953": {
    name: "tiny dinos winter 2022 #4953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4953,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4953.png",
  },
  "4954": {
    name: "tiny dinos winter 2022 #4954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4954,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4954.png",
  },
  "4955": {
    name: "tiny dinos winter 2022 #4955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4955,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4955.png",
  },
  "4956": {
    name: "tiny dinos winter 2022 #4956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4956,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4956.png",
  },
  "4957": {
    name: "tiny dinos winter 2022 #4957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4957,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4957.png",
  },
  "4958": {
    name: "tiny dinos winter 2022 #4958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4958,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4958.png",
  },
  "4959": {
    name: "tiny dinos winter 2022 #4959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4959,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4959.png",
  },
  "4960": {
    name: "tiny dinos winter 2022 #4960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4960,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4960.png",
  },
  "4961": {
    name: "tiny dinos winter 2022 #4961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4961,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4961.png",
  },
  "4962": {
    name: "tiny dinos winter 2022 #4962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4962,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4962.png",
  },
  "4963": {
    name: "tiny dinos winter 2022 #4963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4963,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4963.png",
  },
  "4964": {
    name: "tiny dinos winter 2022 #4964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4964,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4964.png",
  },
  "4965": {
    name: "tiny dinos winter 2022 #4965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4965,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4965.png",
  },
  "4966": {
    name: "tiny dinos winter 2022 #4966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4966,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4966.png",
  },
  "4967": {
    name: "tiny dinos winter 2022 #4967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4967,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4967.png",
  },
  "4968": {
    name: "tiny dinos winter 2022 #4968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4968,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4968.png",
  },
  "4969": {
    name: "tiny dinos winter 2022 #4969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4969,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4969.png",
  },
  "4970": {
    name: "tiny dinos winter 2022 #4970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4970,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4970.png",
  },
  "4971": {
    name: "tiny dinos winter 2022 #4971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4971,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4971.png",
  },
  "4972": {
    name: "tiny dinos winter 2022 #4972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4972,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4972.png",
  },
  "4973": {
    name: "tiny dinos winter 2022 #4973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4973,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4973.png",
  },
  "4974": {
    name: "tiny dinos winter 2022 #4974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4974,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4974.png",
  },
  "4975": {
    name: "tiny dinos winter 2022 #4975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4975,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4975.png",
  },
  "4976": {
    name: "tiny dinos winter 2022 #4976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4976,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4976.png",
  },
  "4977": {
    name: "tiny dinos winter 2022 #4977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4977,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4977.png",
  },
  "4978": {
    name: "tiny dinos winter 2022 #4978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4978,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4978.png",
  },
  "4979": {
    name: "tiny dinos winter 2022 #4979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4979,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4979.png",
  },
  "4980": {
    name: "tiny dinos winter 2022 #4980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4980,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4980.png",
  },
  "4981": {
    name: "tiny dinos winter 2022 #4981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4981,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4981.png",
  },
  "4982": {
    name: "tiny dinos winter 2022 #4982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4982,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4982.png",
  },
  "4983": {
    name: "tiny dinos winter 2022 #4983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4983,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4983.png",
  },
  "4984": {
    name: "tiny dinos winter 2022 #4984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4984,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4984.png",
  },
  "4985": {
    name: "tiny dinos winter 2022 #4985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4985,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4985.png",
  },
  "4986": {
    name: "tiny dinos winter 2022 #4986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4986,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4986.png",
  },
  "4987": {
    name: "tiny dinos winter 2022 #4987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4987,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4987.png",
  },
  "4988": {
    name: "tiny dinos winter 2022 #4988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4988,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4988.png",
  },
  "4989": {
    name: "tiny dinos winter 2022 #4989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4989,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4989.png",
  },
  "4990": {
    name: "tiny dinos winter 2022 #4990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4990,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4990.png",
  },
  "4991": {
    name: "tiny dinos winter 2022 #4991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4991,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4991.png",
  },
  "4992": {
    name: "tiny dinos winter 2022 #4992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4992,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4992.png",
  },
  "4993": {
    name: "tiny dinos winter 2022 #4993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4993,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4993.png",
  },
  "4994": {
    name: "tiny dinos winter 2022 #4994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4994,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4994.png",
  },
  "4995": {
    name: "tiny dinos winter 2022 #4995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4995,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4995.png",
  },
  "4996": {
    name: "tiny dinos winter 2022 #4996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4996,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4996.png",
  },
  "4997": {
    name: "tiny dinos winter 2022 #4997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4997,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4997.png",
  },
  "4998": {
    name: "tiny dinos winter 2022 #4998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4998,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4998.png",
  },
  "4999": {
    name: "tiny dinos winter 2022 #4999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 4999,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/4999.png",
  },
  "5000": {
    name: "tiny dinos winter 2022 #5000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5000,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5000.png",
  },
  "5001": {
    name: "tiny dinos winter 2022 #5001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5001,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5001.png",
  },
  "5002": {
    name: "tiny dinos winter 2022 #5002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5002,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5002.png",
  },
  "5003": {
    name: "tiny dinos winter 2022 #5003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5003,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5003.png",
  },
  "5004": {
    name: "tiny dinos winter 2022 #5004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5004,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5004.png",
  },
  "5005": {
    name: "tiny dinos winter 2022 #5005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5005,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5005.png",
  },
  "5006": {
    name: "tiny dinos winter 2022 #5006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5006,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5006.png",
  },
  "5007": {
    name: "tiny dinos winter 2022 #5007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5007,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5007.png",
  },
  "5008": {
    name: "tiny dinos winter 2022 #5008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5008,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5008.png",
  },
  "5009": {
    name: "tiny dinos winter 2022 #5009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5009,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5009.png",
  },
  "5010": {
    name: "tiny dinos winter 2022 #5010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5010,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5010.png",
  },
  "5011": {
    name: "tiny dinos winter 2022 #5011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5011,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5011.png",
  },
  "5012": {
    name: "tiny dinos winter 2022 #5012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5012,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5012.png",
  },
  "5013": {
    name: "tiny dinos winter 2022 #5013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5013,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5013.png",
  },
  "5014": {
    name: "tiny dinos winter 2022 #5014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5014,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5014.png",
  },
  "5015": {
    name: "tiny dinos winter 2022 #5015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5015,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5015.png",
  },
  "5016": {
    name: "tiny dinos winter 2022 #5016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5016,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5016.png",
  },
  "5017": {
    name: "tiny dinos winter 2022 #5017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5017,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5017.png",
  },
  "5018": {
    name: "tiny dinos winter 2022 #5018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5018,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5018.png",
  },
  "5019": {
    name: "tiny dinos winter 2022 #5019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5019,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5019.png",
  },
  "5020": {
    name: "tiny dinos winter 2022 #5020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5020,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5020.png",
  },
  "5021": {
    name: "tiny dinos winter 2022 #5021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5021,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5021.png",
  },
  "5022": {
    name: "tiny dinos winter 2022 #5022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5022,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5022.png",
  },
  "5023": {
    name: "tiny dinos winter 2022 #5023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5023,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5023.png",
  },
  "5024": {
    name: "tiny dinos winter 2022 #5024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5024,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5024.png",
  },
  "5025": {
    name: "tiny dinos winter 2022 #5025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5025,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5025.png",
  },
  "5026": {
    name: "tiny dinos winter 2022 #5026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5026,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5026.png",
  },
  "5027": {
    name: "tiny dinos winter 2022 #5027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5027,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5027.png",
  },
  "5028": {
    name: "tiny dinos winter 2022 #5028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5028,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5028.png",
  },
  "5029": {
    name: "tiny dinos winter 2022 #5029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5029,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5029.png",
  },
  "5030": {
    name: "tiny dinos winter 2022 #5030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5030,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5030.png",
  },
  "5031": {
    name: "tiny dinos winter 2022 #5031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5031,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5031.png",
  },
  "5032": {
    name: "tiny dinos winter 2022 #5032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5032,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5032.png",
  },
  "5033": {
    name: "tiny dinos winter 2022 #5033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5033,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5033.png",
  },
  "5034": {
    name: "tiny dinos winter 2022 #5034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5034,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5034.png",
  },
  "5035": {
    name: "tiny dinos winter 2022 #5035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5035,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5035.png",
  },
  "5036": {
    name: "tiny dinos winter 2022 #5036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5036,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5036.png",
  },
  "5037": {
    name: "tiny dinos winter 2022 #5037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5037,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5037.png",
  },
  "5038": {
    name: "tiny dinos winter 2022 #5038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5038,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5038.png",
  },
  "5039": {
    name: "tiny dinos winter 2022 #5039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5039,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5039.png",
  },
  "5040": {
    name: "tiny dinos winter 2022 #5040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5040,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5040.png",
  },
  "5041": {
    name: "tiny dinos winter 2022 #5041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5041,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5041.png",
  },
  "5042": {
    name: "tiny dinos winter 2022 #5042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5042,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5042.png",
  },
  "5043": {
    name: "tiny dinos winter 2022 #5043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5043,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5043.png",
  },
  "5044": {
    name: "tiny dinos winter 2022 #5044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5044,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5044.png",
  },
  "5045": {
    name: "tiny dinos winter 2022 #5045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5045,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5045.png",
  },
  "5046": {
    name: "tiny dinos winter 2022 #5046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5046,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5046.png",
  },
  "5047": {
    name: "tiny dinos winter 2022 #5047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5047,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5047.png",
  },
  "5048": {
    name: "tiny dinos winter 2022 #5048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5048,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5048.png",
  },
  "5049": {
    name: "tiny dinos winter 2022 #5049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5049,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5049.png",
  },
  "5050": {
    name: "tiny dinos winter 2022 #5050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5050,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5050.png",
  },
  "5051": {
    name: "tiny dinos winter 2022 #5051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5051,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5051.png",
  },
  "5052": {
    name: "tiny dinos winter 2022 #5052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5052,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5052.png",
  },
  "5053": {
    name: "tiny dinos winter 2022 #5053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5053,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5053.png",
  },
  "5054": {
    name: "tiny dinos winter 2022 #5054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5054,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5054.png",
  },
  "5055": {
    name: "tiny dinos winter 2022 #5055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5055,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5055.png",
  },
  "5056": {
    name: "tiny dinos winter 2022 #5056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5056,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5056.png",
  },
  "5057": {
    name: "tiny dinos winter 2022 #5057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5057,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5057.png",
  },
  "5058": {
    name: "tiny dinos winter 2022 #5058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5058,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5058.png",
  },
  "5059": {
    name: "tiny dinos winter 2022 #5059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5059,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5059.png",
  },
  "5060": {
    name: "tiny dinos winter 2022 #5060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5060,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5060.png",
  },
  "5061": {
    name: "tiny dinos winter 2022 #5061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5061,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5061.png",
  },
  "5062": {
    name: "tiny dinos winter 2022 #5062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5062,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5062.png",
  },
  "5063": {
    name: "tiny dinos winter 2022 #5063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5063,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5063.png",
  },
  "5064": {
    name: "tiny dinos winter 2022 #5064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5064,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5064.png",
  },
  "5065": {
    name: "tiny dinos winter 2022 #5065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5065,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5065.png",
  },
  "5066": {
    name: "tiny dinos winter 2022 #5066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5066,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5066.png",
  },
  "5067": {
    name: "tiny dinos winter 2022 #5067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5067,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5067.png",
  },
  "5068": {
    name: "tiny dinos winter 2022 #5068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5068,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5068.png",
  },
  "5069": {
    name: "tiny dinos winter 2022 #5069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5069,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5069.png",
  },
  "5070": {
    name: "tiny dinos winter 2022 #5070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5070,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5070.png",
  },
  "5071": {
    name: "tiny dinos winter 2022 #5071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5071,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5071.png",
  },
  "5072": {
    name: "tiny dinos winter 2022 #5072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5072,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5072.png",
  },
  "5073": {
    name: "tiny dinos winter 2022 #5073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5073,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5073.png",
  },
  "5074": {
    name: "tiny dinos winter 2022 #5074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5074,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5074.png",
  },
  "5075": {
    name: "tiny dinos winter 2022 #5075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5075,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5075.png",
  },
  "5076": {
    name: "tiny dinos winter 2022 #5076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5076,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5076.png",
  },
  "5077": {
    name: "tiny dinos winter 2022 #5077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5077,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5077.png",
  },
  "5078": {
    name: "tiny dinos winter 2022 #5078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5078,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5078.png",
  },
  "5079": {
    name: "tiny dinos winter 2022 #5079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5079,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5079.png",
  },
  "5080": {
    name: "tiny dinos winter 2022 #5080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5080,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5080.png",
  },
  "5081": {
    name: "tiny dinos winter 2022 #5081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5081,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5081.png",
  },
  "5082": {
    name: "tiny dinos winter 2022 #5082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5082,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5082.png",
  },
  "5083": {
    name: "tiny dinos winter 2022 #5083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5083,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5083.png",
  },
  "5084": {
    name: "tiny dinos winter 2022 #5084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5084,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5084.png",
  },
  "5085": {
    name: "tiny dinos winter 2022 #5085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5085,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5085.png",
  },
  "5086": {
    name: "tiny dinos winter 2022 #5086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5086,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5086.png",
  },
  "5087": {
    name: "tiny dinos winter 2022 #5087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5087,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5087.png",
  },
  "5088": {
    name: "tiny dinos winter 2022 #5088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5088,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5088.png",
  },
  "5089": {
    name: "tiny dinos winter 2022 #5089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5089,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5089.png",
  },
  "5090": {
    name: "tiny dinos winter 2022 #5090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5090,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5090.png",
  },
  "5091": {
    name: "tiny dinos winter 2022 #5091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5091,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5091.png",
  },
  "5092": {
    name: "tiny dinos winter 2022 #5092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5092,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5092.png",
  },
  "5093": {
    name: "tiny dinos winter 2022 #5093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5093,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5093.png",
  },
  "5094": {
    name: "tiny dinos winter 2022 #5094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5094,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5094.png",
  },
  "5095": {
    name: "tiny dinos winter 2022 #5095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5095,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5095.png",
  },
  "5096": {
    name: "tiny dinos winter 2022 #5096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5096,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5096.png",
  },
  "5097": {
    name: "tiny dinos winter 2022 #5097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5097,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5097.png",
  },
  "5098": {
    name: "tiny dinos winter 2022 #5098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5098,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5098.png",
  },
  "5099": {
    name: "tiny dinos winter 2022 #5099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5099,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5099.png",
  },
  "5100": {
    name: "tiny dinos winter 2022 #5100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5100,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5100.png",
  },
  "5101": {
    name: "tiny dinos winter 2022 #5101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5101,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5101.png",
  },
  "5102": {
    name: "tiny dinos winter 2022 #5102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5102,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5102.png",
  },
  "5103": {
    name: "tiny dinos winter 2022 #5103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5103,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5103.png",
  },
  "5104": {
    name: "tiny dinos winter 2022 #5104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5104,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5104.png",
  },
  "5105": {
    name: "tiny dinos winter 2022 #5105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5105,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5105.png",
  },
  "5106": {
    name: "tiny dinos winter 2022 #5106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5106,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5106.png",
  },
  "5107": {
    name: "tiny dinos winter 2022 #5107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5107,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5107.png",
  },
  "5108": {
    name: "tiny dinos winter 2022 #5108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5108,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5108.png",
  },
  "5109": {
    name: "tiny dinos winter 2022 #5109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5109,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5109.png",
  },
  "5110": {
    name: "tiny dinos winter 2022 #5110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5110,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5110.png",
  },
  "5111": {
    name: "tiny dinos winter 2022 #5111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5111,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5111.png",
  },
  "5112": {
    name: "tiny dinos winter 2022 #5112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5112,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5112.png",
  },
  "5113": {
    name: "tiny dinos winter 2022 #5113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5113,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5113.png",
  },
  "5114": {
    name: "tiny dinos winter 2022 #5114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5114,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5114.png",
  },
  "5115": {
    name: "tiny dinos winter 2022 #5115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5115,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5115.png",
  },
  "5116": {
    name: "tiny dinos winter 2022 #5116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5116,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5116.png",
  },
  "5117": {
    name: "tiny dinos winter 2022 #5117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5117,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5117.png",
  },
  "5118": {
    name: "tiny dinos winter 2022 #5118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5118,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5118.png",
  },
  "5119": {
    name: "tiny dinos winter 2022 #5119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5119,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5119.png",
  },
  "5120": {
    name: "tiny dinos winter 2022 #5120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5120,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5120.png",
  },
  "5121": {
    name: "tiny dinos winter 2022 #5121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5121,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5121.png",
  },
  "5122": {
    name: "tiny dinos winter 2022 #5122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5122,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5122.png",
  },
  "5123": {
    name: "tiny dinos winter 2022 #5123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5123,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5123.png",
  },
  "5124": {
    name: "tiny dinos winter 2022 #5124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5124,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5124.png",
  },
  "5125": {
    name: "tiny dinos winter 2022 #5125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5125,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5125.png",
  },
  "5126": {
    name: "tiny dinos winter 2022 #5126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5126,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5126.png",
  },
  "5127": {
    name: "tiny dinos winter 2022 #5127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5127,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5127.png",
  },
  "5128": {
    name: "tiny dinos winter 2022 #5128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5128,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5128.png",
  },
  "5129": {
    name: "tiny dinos winter 2022 #5129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5129,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5129.png",
  },
  "5130": {
    name: "tiny dinos winter 2022 #5130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5130,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5130.png",
  },
  "5131": {
    name: "tiny dinos winter 2022 #5131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5131,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5131.png",
  },
  "5132": {
    name: "tiny dinos winter 2022 #5132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5132,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5132.png",
  },
  "5133": {
    name: "tiny dinos winter 2022 #5133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5133,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5133.png",
  },
  "5134": {
    name: "tiny dinos winter 2022 #5134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5134,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5134.png",
  },
  "5135": {
    name: "tiny dinos winter 2022 #5135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5135,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5135.png",
  },
  "5136": {
    name: "tiny dinos winter 2022 #5136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5136,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5136.png",
  },
  "5137": {
    name: "tiny dinos winter 2022 #5137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5137,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5137.png",
  },
  "5138": {
    name: "tiny dinos winter 2022 #5138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5138,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5138.png",
  },
  "5139": {
    name: "tiny dinos winter 2022 #5139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5139,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5139.png",
  },
  "5140": {
    name: "tiny dinos winter 2022 #5140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5140,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5140.png",
  },
  "5141": {
    name: "tiny dinos winter 2022 #5141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5141,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5141.png",
  },
  "5142": {
    name: "tiny dinos winter 2022 #5142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5142,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5142.png",
  },
  "5143": {
    name: "tiny dinos winter 2022 #5143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5143,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5143.png",
  },
  "5144": {
    name: "tiny dinos winter 2022 #5144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5144,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5144.png",
  },
  "5145": {
    name: "tiny dinos winter 2022 #5145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5145,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5145.png",
  },
  "5146": {
    name: "tiny dinos winter 2022 #5146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5146,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5146.png",
  },
  "5147": {
    name: "tiny dinos winter 2022 #5147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5147,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5147.png",
  },
  "5148": {
    name: "tiny dinos winter 2022 #5148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5148,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5148.png",
  },
  "5149": {
    name: "tiny dinos winter 2022 #5149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5149,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5149.png",
  },
  "5150": {
    name: "tiny dinos winter 2022 #5150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5150,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5150.png",
  },
  "5151": {
    name: "tiny dinos winter 2022 #5151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5151,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5151.png",
  },
  "5152": {
    name: "tiny dinos winter 2022 #5152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5152,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5152.png",
  },
  "5153": {
    name: "tiny dinos winter 2022 #5153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5153,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5153.png",
  },
  "5154": {
    name: "tiny dinos winter 2022 #5154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5154,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5154.png",
  },
  "5155": {
    name: "tiny dinos winter 2022 #5155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5155,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5155.png",
  },
  "5156": {
    name: "tiny dinos winter 2022 #5156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5156,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5156.png",
  },
  "5157": {
    name: "tiny dinos winter 2022 #5157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5157,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5157.png",
  },
  "5158": {
    name: "tiny dinos winter 2022 #5158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5158,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5158.png",
  },
  "5159": {
    name: "tiny dinos winter 2022 #5159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5159,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5159.png",
  },
  "5160": {
    name: "tiny dinos winter 2022 #5160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5160,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5160.png",
  },
  "5161": {
    name: "tiny dinos winter 2022 #5161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5161,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5161.png",
  },
  "5162": {
    name: "tiny dinos winter 2022 #5162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5162,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5162.png",
  },
  "5163": {
    name: "tiny dinos winter 2022 #5163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5163,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5163.png",
  },
  "5164": {
    name: "tiny dinos winter 2022 #5164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5164,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5164.png",
  },
  "5165": {
    name: "tiny dinos winter 2022 #5165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5165,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5165.png",
  },
  "5166": {
    name: "tiny dinos winter 2022 #5166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5166,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5166.png",
  },
  "5167": {
    name: "tiny dinos winter 2022 #5167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5167,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5167.png",
  },
  "5168": {
    name: "tiny dinos winter 2022 #5168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5168,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5168.png",
  },
  "5169": {
    name: "tiny dinos winter 2022 #5169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5169,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5169.png",
  },
  "5170": {
    name: "tiny dinos winter 2022 #5170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5170,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5170.png",
  },
  "5171": {
    name: "tiny dinos winter 2022 #5171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5171,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5171.png",
  },
  "5172": {
    name: "tiny dinos winter 2022 #5172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5172,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5172.png",
  },
  "5173": {
    name: "tiny dinos winter 2022 #5173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5173,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5173.png",
  },
  "5174": {
    name: "tiny dinos winter 2022 #5174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5174,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5174.png",
  },
  "5175": {
    name: "tiny dinos winter 2022 #5175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5175,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5175.png",
  },
  "5176": {
    name: "tiny dinos winter 2022 #5176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5176,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5176.png",
  },
  "5177": {
    name: "tiny dinos winter 2022 #5177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5177,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5177.png",
  },
  "5178": {
    name: "tiny dinos winter 2022 #5178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5178,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5178.png",
  },
  "5179": {
    name: "tiny dinos winter 2022 #5179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5179,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5179.png",
  },
  "5180": {
    name: "tiny dinos winter 2022 #5180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5180,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5180.png",
  },
  "5181": {
    name: "tiny dinos winter 2022 #5181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5181,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5181.png",
  },
  "5182": {
    name: "tiny dinos winter 2022 #5182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5182,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5182.png",
  },
  "5183": {
    name: "tiny dinos winter 2022 #5183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5183,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5183.png",
  },
  "5184": {
    name: "tiny dinos winter 2022 #5184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5184,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5184.png",
  },
  "5185": {
    name: "tiny dinos winter 2022 #5185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5185,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5185.png",
  },
  "5186": {
    name: "tiny dinos winter 2022 #5186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5186,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5186.png",
  },
  "5187": {
    name: "tiny dinos winter 2022 #5187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5187,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5187.png",
  },
  "5188": {
    name: "tiny dinos winter 2022 #5188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5188,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5188.png",
  },
  "5189": {
    name: "tiny dinos winter 2022 #5189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5189,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5189.png",
  },
  "5190": {
    name: "tiny dinos winter 2022 #5190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5190,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5190.png",
  },
  "5191": {
    name: "tiny dinos winter 2022 #5191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5191,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5191.png",
  },
  "5192": {
    name: "tiny dinos winter 2022 #5192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5192,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5192.png",
  },
  "5193": {
    name: "tiny dinos winter 2022 #5193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5193,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5193.png",
  },
  "5194": {
    name: "tiny dinos winter 2022 #5194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5194,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5194.png",
  },
  "5195": {
    name: "tiny dinos winter 2022 #5195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5195,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5195.png",
  },
  "5196": {
    name: "tiny dinos winter 2022 #5196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5196,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5196.png",
  },
  "5197": {
    name: "tiny dinos winter 2022 #5197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5197,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5197.png",
  },
  "5198": {
    name: "tiny dinos winter 2022 #5198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5198,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5198.png",
  },
  "5199": {
    name: "tiny dinos winter 2022 #5199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5199,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5199.png",
  },
  "5200": {
    name: "tiny dinos winter 2022 #5200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5200,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5200.png",
  },
  "5201": {
    name: "tiny dinos winter 2022 #5201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5201,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5201.png",
  },
  "5202": {
    name: "tiny dinos winter 2022 #5202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5202,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5202.png",
  },
  "5203": {
    name: "tiny dinos winter 2022 #5203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5203,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5203.png",
  },
  "5204": {
    name: "tiny dinos winter 2022 #5204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5204,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5204.png",
  },
  "5205": {
    name: "tiny dinos winter 2022 #5205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5205,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5205.png",
  },
  "5206": {
    name: "tiny dinos winter 2022 #5206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5206,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5206.png",
  },
  "5207": {
    name: "tiny dinos winter 2022 #5207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5207,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5207.png",
  },
  "5208": {
    name: "tiny dinos winter 2022 #5208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5208,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5208.png",
  },
  "5209": {
    name: "tiny dinos winter 2022 #5209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5209,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5209.png",
  },
  "5210": {
    name: "tiny dinos winter 2022 #5210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5210,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5210.png",
  },
  "5211": {
    name: "tiny dinos winter 2022 #5211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5211,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5211.png",
  },
  "5212": {
    name: "tiny dinos winter 2022 #5212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5212,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5212.png",
  },
  "5213": {
    name: "tiny dinos winter 2022 #5213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5213,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5213.png",
  },
  "5214": {
    name: "tiny dinos winter 2022 #5214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5214,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5214.png",
  },
  "5215": {
    name: "tiny dinos winter 2022 #5215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5215,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5215.png",
  },
  "5216": {
    name: "tiny dinos winter 2022 #5216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5216,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5216.png",
  },
  "5217": {
    name: "tiny dinos winter 2022 #5217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5217,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5217.png",
  },
  "5218": {
    name: "tiny dinos winter 2022 #5218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5218,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5218.png",
  },
  "5219": {
    name: "tiny dinos winter 2022 #5219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5219,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5219.png",
  },
  "5220": {
    name: "tiny dinos winter 2022 #5220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5220,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5220.png",
  },
  "5221": {
    name: "tiny dinos winter 2022 #5221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5221,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5221.png",
  },
  "5222": {
    name: "tiny dinos winter 2022 #5222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5222,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5222.png",
  },
  "5223": {
    name: "tiny dinos winter 2022 #5223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5223,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5223.png",
  },
  "5224": {
    name: "tiny dinos winter 2022 #5224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5224,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5224.png",
  },
  "5225": {
    name: "tiny dinos winter 2022 #5225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5225,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5225.png",
  },
  "5226": {
    name: "tiny dinos winter 2022 #5226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5226,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5226.png",
  },
  "5227": {
    name: "tiny dinos winter 2022 #5227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5227,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5227.png",
  },
  "5228": {
    name: "tiny dinos winter 2022 #5228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5228,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5228.png",
  },
  "5229": {
    name: "tiny dinos winter 2022 #5229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5229,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5229.png",
  },
  "5230": {
    name: "tiny dinos winter 2022 #5230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5230,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5230.png",
  },
  "5231": {
    name: "tiny dinos winter 2022 #5231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5231,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5231.png",
  },
  "5232": {
    name: "tiny dinos winter 2022 #5232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5232,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5232.png",
  },
  "5233": {
    name: "tiny dinos winter 2022 #5233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5233,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5233.png",
  },
  "5234": {
    name: "tiny dinos winter 2022 #5234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5234,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5234.png",
  },
  "5235": {
    name: "tiny dinos winter 2022 #5235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5235,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5235.png",
  },
  "5236": {
    name: "tiny dinos winter 2022 #5236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5236,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5236.png",
  },
  "5237": {
    name: "tiny dinos winter 2022 #5237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5237,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5237.png",
  },
  "5238": {
    name: "tiny dinos winter 2022 #5238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5238,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5238.png",
  },
  "5239": {
    name: "tiny dinos winter 2022 #5239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5239,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5239.png",
  },
  "5240": {
    name: "tiny dinos winter 2022 #5240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5240,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5240.png",
  },
  "5241": {
    name: "tiny dinos winter 2022 #5241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5241,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5241.png",
  },
  "5242": {
    name: "tiny dinos winter 2022 #5242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5242,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5242.png",
  },
  "5243": {
    name: "tiny dinos winter 2022 #5243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5243,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5243.png",
  },
  "5244": {
    name: "tiny dinos winter 2022 #5244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5244,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5244.png",
  },
  "5245": {
    name: "tiny dinos winter 2022 #5245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5245,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5245.png",
  },
  "5246": {
    name: "tiny dinos winter 2022 #5246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5246,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5246.png",
  },
  "5247": {
    name: "tiny dinos winter 2022 #5247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5247,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5247.png",
  },
  "5248": {
    name: "tiny dinos winter 2022 #5248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5248,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5248.png",
  },
  "5249": {
    name: "tiny dinos winter 2022 #5249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5249,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5249.png",
  },
  "5250": {
    name: "tiny dinos winter 2022 #5250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5250,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5250.png",
  },
  "5251": {
    name: "tiny dinos winter 2022 #5251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5251,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5251.png",
  },
  "5252": {
    name: "tiny dinos winter 2022 #5252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5252,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5252.png",
  },
  "5253": {
    name: "tiny dinos winter 2022 #5253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5253,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5253.png",
  },
  "5254": {
    name: "tiny dinos winter 2022 #5254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5254,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5254.png",
  },
  "5255": {
    name: "tiny dinos winter 2022 #5255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5255,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5255.png",
  },
  "5256": {
    name: "tiny dinos winter 2022 #5256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5256,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5256.png",
  },
  "5257": {
    name: "tiny dinos winter 2022 #5257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5257,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5257.png",
  },
  "5258": {
    name: "tiny dinos winter 2022 #5258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5258,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5258.png",
  },
  "5259": {
    name: "tiny dinos winter 2022 #5259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5259,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5259.png",
  },
  "5260": {
    name: "tiny dinos winter 2022 #5260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5260,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5260.png",
  },
  "5261": {
    name: "tiny dinos winter 2022 #5261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5261,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5261.png",
  },
  "5262": {
    name: "tiny dinos winter 2022 #5262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5262,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5262.png",
  },
  "5263": {
    name: "tiny dinos winter 2022 #5263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5263,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5263.png",
  },
  "5264": {
    name: "tiny dinos winter 2022 #5264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5264,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5264.png",
  },
  "5265": {
    name: "tiny dinos winter 2022 #5265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5265,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5265.png",
  },
  "5266": {
    name: "tiny dinos winter 2022 #5266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5266,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5266.png",
  },
  "5267": {
    name: "tiny dinos winter 2022 #5267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5267,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5267.png",
  },
  "5268": {
    name: "tiny dinos winter 2022 #5268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5268,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5268.png",
  },
  "5269": {
    name: "tiny dinos winter 2022 #5269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5269,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5269.png",
  },
  "5270": {
    name: "tiny dinos winter 2022 #5270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5270,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5270.png",
  },
  "5271": {
    name: "tiny dinos winter 2022 #5271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5271,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5271.png",
  },
  "5272": {
    name: "tiny dinos winter 2022 #5272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5272,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5272.png",
  },
  "5273": {
    name: "tiny dinos winter 2022 #5273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5273,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5273.png",
  },
  "5274": {
    name: "tiny dinos winter 2022 #5274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5274,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5274.png",
  },
  "5275": {
    name: "tiny dinos winter 2022 #5275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5275,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5275.png",
  },
  "5276": {
    name: "tiny dinos winter 2022 #5276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5276,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5276.png",
  },
  "5277": {
    name: "tiny dinos winter 2022 #5277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5277,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5277.png",
  },
  "5278": {
    name: "tiny dinos winter 2022 #5278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5278,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5278.png",
  },
  "5279": {
    name: "tiny dinos winter 2022 #5279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5279,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5279.png",
  },
  "5280": {
    name: "tiny dinos winter 2022 #5280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5280,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5280.png",
  },
  "5281": {
    name: "tiny dinos winter 2022 #5281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5281,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5281.png",
  },
  "5282": {
    name: "tiny dinos winter 2022 #5282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5282,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5282.png",
  },
  "5283": {
    name: "tiny dinos winter 2022 #5283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5283,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5283.png",
  },
  "5284": {
    name: "tiny dinos winter 2022 #5284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5284,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5284.png",
  },
  "5285": {
    name: "tiny dinos winter 2022 #5285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5285,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5285.png",
  },
  "5286": {
    name: "tiny dinos winter 2022 #5286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5286,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5286.png",
  },
  "5287": {
    name: "tiny dinos winter 2022 #5287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5287,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5287.png",
  },
  "5288": {
    name: "tiny dinos winter 2022 #5288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5288,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5288.png",
  },
  "5289": {
    name: "tiny dinos winter 2022 #5289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5289,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5289.png",
  },
  "5290": {
    name: "tiny dinos winter 2022 #5290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5290,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5290.png",
  },
  "5291": {
    name: "tiny dinos winter 2022 #5291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5291,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5291.png",
  },
  "5292": {
    name: "tiny dinos winter 2022 #5292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5292,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5292.png",
  },
  "5293": {
    name: "tiny dinos winter 2022 #5293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5293,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5293.png",
  },
  "5294": {
    name: "tiny dinos winter 2022 #5294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5294,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5294.png",
  },
  "5295": {
    name: "tiny dinos winter 2022 #5295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5295,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5295.png",
  },
  "5296": {
    name: "tiny dinos winter 2022 #5296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5296,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5296.png",
  },
  "5297": {
    name: "tiny dinos winter 2022 #5297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5297,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5297.png",
  },
  "5298": {
    name: "tiny dinos winter 2022 #5298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5298,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5298.png",
  },
  "5299": {
    name: "tiny dinos winter 2022 #5299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5299,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5299.png",
  },
  "5300": {
    name: "tiny dinos winter 2022 #5300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5300,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5300.png",
  },
  "5301": {
    name: "tiny dinos winter 2022 #5301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5301,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5301.png",
  },
  "5302": {
    name: "tiny dinos winter 2022 #5302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5302,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5302.png",
  },
  "5303": {
    name: "tiny dinos winter 2022 #5303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5303,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5303.png",
  },
  "5304": {
    name: "tiny dinos winter 2022 #5304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5304,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5304.png",
  },
  "5305": {
    name: "tiny dinos winter 2022 #5305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5305,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5305.png",
  },
  "5306": {
    name: "tiny dinos winter 2022 #5306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5306,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5306.png",
  },
  "5307": {
    name: "tiny dinos winter 2022 #5307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5307,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5307.png",
  },
  "5308": {
    name: "tiny dinos winter 2022 #5308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5308,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5308.png",
  },
  "5309": {
    name: "tiny dinos winter 2022 #5309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5309,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5309.png",
  },
  "5310": {
    name: "tiny dinos winter 2022 #5310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5310,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5310.png",
  },
  "5311": {
    name: "tiny dinos winter 2022 #5311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5311,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5311.png",
  },
  "5312": {
    name: "tiny dinos winter 2022 #5312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5312,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5312.png",
  },
  "5313": {
    name: "tiny dinos winter 2022 #5313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5313,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5313.png",
  },
  "5314": {
    name: "tiny dinos winter 2022 #5314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5314,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5314.png",
  },
  "5315": {
    name: "tiny dinos winter 2022 #5315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5315,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5315.png",
  },
  "5316": {
    name: "tiny dinos winter 2022 #5316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5316,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5316.png",
  },
  "5317": {
    name: "tiny dinos winter 2022 #5317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5317,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5317.png",
  },
  "5318": {
    name: "tiny dinos winter 2022 #5318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5318,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5318.png",
  },
  "5319": {
    name: "tiny dinos winter 2022 #5319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5319,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5319.png",
  },
  "5320": {
    name: "tiny dinos winter 2022 #5320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5320,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5320.png",
  },
  "5321": {
    name: "tiny dinos winter 2022 #5321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5321,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5321.png",
  },
  "5322": {
    name: "tiny dinos winter 2022 #5322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5322,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5322.png",
  },
  "5323": {
    name: "tiny dinos winter 2022 #5323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5323,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5323.png",
  },
  "5324": {
    name: "tiny dinos winter 2022 #5324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5324,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5324.png",
  },
  "5325": {
    name: "tiny dinos winter 2022 #5325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5325,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5325.png",
  },
  "5326": {
    name: "tiny dinos winter 2022 #5326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5326,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5326.png",
  },
  "5327": {
    name: "tiny dinos winter 2022 #5327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5327,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5327.png",
  },
  "5328": {
    name: "tiny dinos winter 2022 #5328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5328,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5328.png",
  },
  "5329": {
    name: "tiny dinos winter 2022 #5329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5329,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5329.png",
  },
  "5330": {
    name: "tiny dinos winter 2022 #5330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5330,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5330.png",
  },
  "5331": {
    name: "tiny dinos winter 2022 #5331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5331,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5331.png",
  },
  "5332": {
    name: "tiny dinos winter 2022 #5332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5332,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5332.png",
  },
  "5333": {
    name: "tiny dinos winter 2022 #5333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5333,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5333.png",
  },
  "5334": {
    name: "tiny dinos winter 2022 #5334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5334,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5334.png",
  },
  "5335": {
    name: "tiny dinos winter 2022 #5335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5335,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5335.png",
  },
  "5336": {
    name: "tiny dinos winter 2022 #5336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5336,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5336.png",
  },
  "5337": {
    name: "tiny dinos winter 2022 #5337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5337,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5337.png",
  },
  "5338": {
    name: "tiny dinos winter 2022 #5338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5338,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5338.png",
  },
  "5339": {
    name: "tiny dinos winter 2022 #5339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5339,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5339.png",
  },
  "5340": {
    name: "tiny dinos winter 2022 #5340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5340,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5340.png",
  },
  "5341": {
    name: "tiny dinos winter 2022 #5341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5341,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5341.png",
  },
  "5342": {
    name: "tiny dinos winter 2022 #5342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5342,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5342.png",
  },
  "5343": {
    name: "tiny dinos winter 2022 #5343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5343,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5343.png",
  },
  "5344": {
    name: "tiny dinos winter 2022 #5344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5344,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5344.png",
  },
  "5345": {
    name: "tiny dinos winter 2022 #5345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5345,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5345.png",
  },
  "5346": {
    name: "tiny dinos winter 2022 #5346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5346,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5346.png",
  },
  "5347": {
    name: "tiny dinos winter 2022 #5347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5347,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5347.png",
  },
  "5348": {
    name: "tiny dinos winter 2022 #5348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5348,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5348.png",
  },
  "5349": {
    name: "tiny dinos winter 2022 #5349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5349,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5349.png",
  },
  "5350": {
    name: "tiny dinos winter 2022 #5350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5350,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5350.png",
  },
  "5351": {
    name: "tiny dinos winter 2022 #5351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5351,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5351.png",
  },
  "5352": {
    name: "tiny dinos winter 2022 #5352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5352,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5352.png",
  },
  "5353": {
    name: "tiny dinos winter 2022 #5353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5353,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5353.png",
  },
  "5354": {
    name: "tiny dinos winter 2022 #5354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5354,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5354.png",
  },
  "5355": {
    name: "tiny dinos winter 2022 #5355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5355,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5355.png",
  },
  "5356": {
    name: "tiny dinos winter 2022 #5356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5356,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5356.png",
  },
  "5357": {
    name: "tiny dinos winter 2022 #5357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5357,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5357.png",
  },
  "5358": {
    name: "tiny dinos winter 2022 #5358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5358,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5358.png",
  },
  "5359": {
    name: "tiny dinos winter 2022 #5359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5359,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5359.png",
  },
  "5360": {
    name: "tiny dinos winter 2022 #5360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5360,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5360.png",
  },
  "5361": {
    name: "tiny dinos winter 2022 #5361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5361,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5361.png",
  },
  "5362": {
    name: "tiny dinos winter 2022 #5362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5362,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5362.png",
  },
  "5363": {
    name: "tiny dinos winter 2022 #5363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5363,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5363.png",
  },
  "5364": {
    name: "tiny dinos winter 2022 #5364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5364,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5364.png",
  },
  "5365": {
    name: "tiny dinos winter 2022 #5365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5365,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5365.png",
  },
  "5366": {
    name: "tiny dinos winter 2022 #5366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5366,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5366.png",
  },
  "5367": {
    name: "tiny dinos winter 2022 #5367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5367,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5367.png",
  },
  "5368": {
    name: "tiny dinos winter 2022 #5368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5368,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5368.png",
  },
  "5369": {
    name: "tiny dinos winter 2022 #5369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5369,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5369.png",
  },
  "5370": {
    name: "tiny dinos winter 2022 #5370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5370,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5370.png",
  },
  "5371": {
    name: "tiny dinos winter 2022 #5371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5371,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5371.png",
  },
  "5372": {
    name: "tiny dinos winter 2022 #5372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5372,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5372.png",
  },
  "5373": {
    name: "tiny dinos winter 2022 #5373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5373,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5373.png",
  },
  "5374": {
    name: "tiny dinos winter 2022 #5374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5374,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5374.png",
  },
  "5375": {
    name: "tiny dinos winter 2022 #5375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5375,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5375.png",
  },
  "5376": {
    name: "tiny dinos winter 2022 #5376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5376,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5376.png",
  },
  "5377": {
    name: "tiny dinos winter 2022 #5377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5377,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5377.png",
  },
  "5378": {
    name: "tiny dinos winter 2022 #5378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5378,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5378.png",
  },
  "5379": {
    name: "tiny dinos winter 2022 #5379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5379,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5379.png",
  },
  "5380": {
    name: "tiny dinos winter 2022 #5380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5380,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5380.png",
  },
  "5381": {
    name: "tiny dinos winter 2022 #5381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5381,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5381.png",
  },
  "5382": {
    name: "tiny dinos winter 2022 #5382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5382,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5382.png",
  },
  "5383": {
    name: "tiny dinos winter 2022 #5383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5383,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5383.png",
  },
  "5384": {
    name: "tiny dinos winter 2022 #5384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5384,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5384.png",
  },
  "5385": {
    name: "tiny dinos winter 2022 #5385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5385,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5385.png",
  },
  "5386": {
    name: "tiny dinos winter 2022 #5386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5386,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5386.png",
  },
  "5387": {
    name: "tiny dinos winter 2022 #5387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5387,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5387.png",
  },
  "5388": {
    name: "tiny dinos winter 2022 #5388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5388,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5388.png",
  },
  "5389": {
    name: "tiny dinos winter 2022 #5389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5389,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5389.png",
  },
  "5390": {
    name: "tiny dinos winter 2022 #5390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5390,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5390.png",
  },
  "5391": {
    name: "tiny dinos winter 2022 #5391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5391,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5391.png",
  },
  "5392": {
    name: "tiny dinos winter 2022 #5392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5392,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5392.png",
  },
  "5393": {
    name: "tiny dinos winter 2022 #5393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5393,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5393.png",
  },
  "5394": {
    name: "tiny dinos winter 2022 #5394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5394,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5394.png",
  },
  "5395": {
    name: "tiny dinos winter 2022 #5395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5395,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5395.png",
  },
  "5396": {
    name: "tiny dinos winter 2022 #5396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5396,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5396.png",
  },
  "5397": {
    name: "tiny dinos winter 2022 #5397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5397,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5397.png",
  },
  "5398": {
    name: "tiny dinos winter 2022 #5398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5398,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5398.png",
  },
  "5399": {
    name: "tiny dinos winter 2022 #5399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5399,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5399.png",
  },
  "5400": {
    name: "tiny dinos winter 2022 #5400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5400,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5400.png",
  },
  "5401": {
    name: "tiny dinos winter 2022 #5401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5401,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5401.png",
  },
  "5402": {
    name: "tiny dinos winter 2022 #5402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5402,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5402.png",
  },
  "5403": {
    name: "tiny dinos winter 2022 #5403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5403,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5403.png",
  },
  "5404": {
    name: "tiny dinos winter 2022 #5404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5404,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5404.png",
  },
  "5405": {
    name: "tiny dinos winter 2022 #5405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5405,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5405.png",
  },
  "5406": {
    name: "tiny dinos winter 2022 #5406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5406,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5406.png",
  },
  "5407": {
    name: "tiny dinos winter 2022 #5407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5407,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5407.png",
  },
  "5408": {
    name: "tiny dinos winter 2022 #5408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5408,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5408.png",
  },
  "5409": {
    name: "tiny dinos winter 2022 #5409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5409,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5409.png",
  },
  "5410": {
    name: "tiny dinos winter 2022 #5410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5410,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5410.png",
  },
  "5411": {
    name: "tiny dinos winter 2022 #5411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5411,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5411.png",
  },
  "5412": {
    name: "tiny dinos winter 2022 #5412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5412,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5412.png",
  },
  "5413": {
    name: "tiny dinos winter 2022 #5413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5413,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5413.png",
  },
  "5414": {
    name: "tiny dinos winter 2022 #5414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5414,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5414.png",
  },
  "5415": {
    name: "tiny dinos winter 2022 #5415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5415,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5415.png",
  },
  "5416": {
    name: "tiny dinos winter 2022 #5416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5416,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5416.png",
  },
  "5417": {
    name: "tiny dinos winter 2022 #5417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5417,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5417.png",
  },
  "5418": {
    name: "tiny dinos winter 2022 #5418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5418,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5418.png",
  },
  "5419": {
    name: "tiny dinos winter 2022 #5419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5419,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5419.png",
  },
  "5420": {
    name: "tiny dinos winter 2022 #5420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5420,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5420.png",
  },
  "5421": {
    name: "tiny dinos winter 2022 #5421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5421,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5421.png",
  },
  "5422": {
    name: "tiny dinos winter 2022 #5422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5422,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5422.png",
  },
  "5423": {
    name: "tiny dinos winter 2022 #5423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5423,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5423.png",
  },
  "5424": {
    name: "tiny dinos winter 2022 #5424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5424,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5424.png",
  },
  "5425": {
    name: "tiny dinos winter 2022 #5425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5425,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5425.png",
  },
  "5426": {
    name: "tiny dinos winter 2022 #5426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5426,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5426.png",
  },
  "5427": {
    name: "tiny dinos winter 2022 #5427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5427,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5427.png",
  },
  "5428": {
    name: "tiny dinos winter 2022 #5428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5428,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5428.png",
  },
  "5429": {
    name: "tiny dinos winter 2022 #5429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5429,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5429.png",
  },
  "5430": {
    name: "tiny dinos winter 2022 #5430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5430,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5430.png",
  },
  "5431": {
    name: "tiny dinos winter 2022 #5431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5431,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5431.png",
  },
  "5432": {
    name: "tiny dinos winter 2022 #5432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5432,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5432.png",
  },
  "5433": {
    name: "tiny dinos winter 2022 #5433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5433,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5433.png",
  },
  "5434": {
    name: "tiny dinos winter 2022 #5434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5434,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5434.png",
  },
  "5435": {
    name: "tiny dinos winter 2022 #5435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5435,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5435.png",
  },
  "5436": {
    name: "tiny dinos winter 2022 #5436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5436,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5436.png",
  },
  "5437": {
    name: "tiny dinos winter 2022 #5437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5437,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5437.png",
  },
  "5438": {
    name: "tiny dinos winter 2022 #5438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5438,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5438.png",
  },
  "5439": {
    name: "tiny dinos winter 2022 #5439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5439,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5439.png",
  },
  "5440": {
    name: "tiny dinos winter 2022 #5440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5440,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5440.png",
  },
  "5441": {
    name: "tiny dinos winter 2022 #5441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5441,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5441.png",
  },
  "5442": {
    name: "tiny dinos winter 2022 #5442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5442,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5442.png",
  },
  "5443": {
    name: "tiny dinos winter 2022 #5443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5443,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5443.png",
  },
  "5444": {
    name: "tiny dinos winter 2022 #5444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5444,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5444.png",
  },
  "5445": {
    name: "tiny dinos winter 2022 #5445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5445,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5445.png",
  },
  "5446": {
    name: "tiny dinos winter 2022 #5446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5446,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5446.png",
  },
  "5447": {
    name: "tiny dinos winter 2022 #5447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5447,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5447.png",
  },
  "5448": {
    name: "tiny dinos winter 2022 #5448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5448,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5448.png",
  },
  "5449": {
    name: "tiny dinos winter 2022 #5449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5449,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5449.png",
  },
  "5450": {
    name: "tiny dinos winter 2022 #5450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5450,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5450.png",
  },
  "5451": {
    name: "tiny dinos winter 2022 #5451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5451,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5451.png",
  },
  "5452": {
    name: "tiny dinos winter 2022 #5452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5452,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5452.png",
  },
  "5453": {
    name: "tiny dinos winter 2022 #5453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5453,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5453.png",
  },
  "5454": {
    name: "tiny dinos winter 2022 #5454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5454,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5454.png",
  },
  "5455": {
    name: "tiny dinos winter 2022 #5455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5455,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5455.png",
  },
  "5456": {
    name: "tiny dinos winter 2022 #5456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5456,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5456.png",
  },
  "5457": {
    name: "tiny dinos winter 2022 #5457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5457,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5457.png",
  },
  "5458": {
    name: "tiny dinos winter 2022 #5458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5458,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5458.png",
  },
  "5459": {
    name: "tiny dinos winter 2022 #5459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5459,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5459.png",
  },
  "5460": {
    name: "tiny dinos winter 2022 #5460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5460,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5460.png",
  },
  "5461": {
    name: "tiny dinos winter 2022 #5461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5461,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5461.png",
  },
  "5462": {
    name: "tiny dinos winter 2022 #5462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5462,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5462.png",
  },
  "5463": {
    name: "tiny dinos winter 2022 #5463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5463,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5463.png",
  },
  "5464": {
    name: "tiny dinos winter 2022 #5464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5464,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5464.png",
  },
  "5465": {
    name: "tiny dinos winter 2022 #5465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5465,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5465.png",
  },
  "5466": {
    name: "tiny dinos winter 2022 #5466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5466,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5466.png",
  },
  "5467": {
    name: "tiny dinos winter 2022 #5467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5467,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5467.png",
  },
  "5468": {
    name: "tiny dinos winter 2022 #5468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5468,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5468.png",
  },
  "5469": {
    name: "tiny dinos winter 2022 #5469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5469,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5469.png",
  },
  "5470": {
    name: "tiny dinos winter 2022 #5470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5470,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5470.png",
  },
  "5471": {
    name: "tiny dinos winter 2022 #5471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5471,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5471.png",
  },
  "5472": {
    name: "tiny dinos winter 2022 #5472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5472,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5472.png",
  },
  "5473": {
    name: "tiny dinos winter 2022 #5473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5473,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5473.png",
  },
  "5474": {
    name: "tiny dinos winter 2022 #5474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5474,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5474.png",
  },
  "5475": {
    name: "tiny dinos winter 2022 #5475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5475,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5475.png",
  },
  "5476": {
    name: "tiny dinos winter 2022 #5476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5476,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5476.png",
  },
  "5477": {
    name: "tiny dinos winter 2022 #5477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5477,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5477.png",
  },
  "5478": {
    name: "tiny dinos winter 2022 #5478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5478,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5478.png",
  },
  "5479": {
    name: "tiny dinos winter 2022 #5479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5479,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5479.png",
  },
  "5480": {
    name: "tiny dinos winter 2022 #5480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5480,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5480.png",
  },
  "5481": {
    name: "tiny dinos winter 2022 #5481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5481,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5481.png",
  },
  "5482": {
    name: "tiny dinos winter 2022 #5482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5482,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5482.png",
  },
  "5483": {
    name: "tiny dinos winter 2022 #5483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5483,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5483.png",
  },
  "5484": {
    name: "tiny dinos winter 2022 #5484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5484,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5484.png",
  },
  "5485": {
    name: "tiny dinos winter 2022 #5485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5485,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5485.png",
  },
  "5486": {
    name: "tiny dinos winter 2022 #5486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5486,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5486.png",
  },
  "5487": {
    name: "tiny dinos winter 2022 #5487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5487,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5487.png",
  },
  "5488": {
    name: "tiny dinos winter 2022 #5488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5488,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5488.png",
  },
  "5489": {
    name: "tiny dinos winter 2022 #5489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5489,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5489.png",
  },
  "5490": {
    name: "tiny dinos winter 2022 #5490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5490,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5490.png",
  },
  "5491": {
    name: "tiny dinos winter 2022 #5491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5491,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5491.png",
  },
  "5492": {
    name: "tiny dinos winter 2022 #5492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5492,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5492.png",
  },
  "5493": {
    name: "tiny dinos winter 2022 #5493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5493,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5493.png",
  },
  "5494": {
    name: "tiny dinos winter 2022 #5494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5494,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5494.png",
  },
  "5495": {
    name: "tiny dinos winter 2022 #5495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5495,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5495.png",
  },
  "5496": {
    name: "tiny dinos winter 2022 #5496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5496,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5496.png",
  },
  "5497": {
    name: "tiny dinos winter 2022 #5497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5497,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5497.png",
  },
  "5498": {
    name: "tiny dinos winter 2022 #5498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5498,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5498.png",
  },
  "5499": {
    name: "tiny dinos winter 2022 #5499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5499,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5499.png",
  },
  "5500": {
    name: "tiny dinos winter 2022 #5500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5500,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5500.png",
  },
  "5501": {
    name: "tiny dinos winter 2022 #5501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5501,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5501.png",
  },
  "5502": {
    name: "tiny dinos winter 2022 #5502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5502,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5502.png",
  },
  "5503": {
    name: "tiny dinos winter 2022 #5503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5503,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5503.png",
  },
  "5504": {
    name: "tiny dinos winter 2022 #5504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5504,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5504.png",
  },
  "5505": {
    name: "tiny dinos winter 2022 #5505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5505,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5505.png",
  },
  "5506": {
    name: "tiny dinos winter 2022 #5506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5506,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5506.png",
  },
  "5507": {
    name: "tiny dinos winter 2022 #5507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5507,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5507.png",
  },
  "5508": {
    name: "tiny dinos winter 2022 #5508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5508,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5508.png",
  },
  "5509": {
    name: "tiny dinos winter 2022 #5509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5509,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5509.png",
  },
  "5510": {
    name: "tiny dinos winter 2022 #5510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5510,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5510.png",
  },
  "5511": {
    name: "tiny dinos winter 2022 #5511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5511,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5511.png",
  },
  "5512": {
    name: "tiny dinos winter 2022 #5512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5512,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5512.png",
  },
  "5513": {
    name: "tiny dinos winter 2022 #5513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5513,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5513.png",
  },
  "5514": {
    name: "tiny dinos winter 2022 #5514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5514,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5514.png",
  },
  "5515": {
    name: "tiny dinos winter 2022 #5515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5515,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5515.png",
  },
  "5516": {
    name: "tiny dinos winter 2022 #5516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5516,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5516.png",
  },
  "5517": {
    name: "tiny dinos winter 2022 #5517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5517,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5517.png",
  },
  "5518": {
    name: "tiny dinos winter 2022 #5518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5518,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5518.png",
  },
  "5519": {
    name: "tiny dinos winter 2022 #5519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5519,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5519.png",
  },
  "5520": {
    name: "tiny dinos winter 2022 #5520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5520,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5520.png",
  },
  "5521": {
    name: "tiny dinos winter 2022 #5521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5521,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5521.png",
  },
  "5522": {
    name: "tiny dinos winter 2022 #5522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5522,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5522.png",
  },
  "5523": {
    name: "tiny dinos winter 2022 #5523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5523,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5523.png",
  },
  "5524": {
    name: "tiny dinos winter 2022 #5524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5524,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5524.png",
  },
  "5525": {
    name: "tiny dinos winter 2022 #5525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5525,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5525.png",
  },
  "5526": {
    name: "tiny dinos winter 2022 #5526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5526,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5526.png",
  },
  "5527": {
    name: "tiny dinos winter 2022 #5527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5527,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5527.png",
  },
  "5528": {
    name: "tiny dinos winter 2022 #5528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5528,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5528.png",
  },
  "5529": {
    name: "tiny dinos winter 2022 #5529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5529,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5529.png",
  },
  "5530": {
    name: "tiny dinos winter 2022 #5530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5530,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5530.png",
  },
  "5531": {
    name: "tiny dinos winter 2022 #5531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5531,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5531.png",
  },
  "5532": {
    name: "tiny dinos winter 2022 #5532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5532,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5532.png",
  },
  "5533": {
    name: "tiny dinos winter 2022 #5533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5533,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5533.png",
  },
  "5534": {
    name: "tiny dinos winter 2022 #5534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5534,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5534.png",
  },
  "5535": {
    name: "tiny dinos winter 2022 #5535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5535,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5535.png",
  },
  "5536": {
    name: "tiny dinos winter 2022 #5536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5536,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5536.png",
  },
  "5537": {
    name: "tiny dinos winter 2022 #5537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5537,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5537.png",
  },
  "5538": {
    name: "tiny dinos winter 2022 #5538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5538,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5538.png",
  },
  "5539": {
    name: "tiny dinos winter 2022 #5539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5539,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5539.png",
  },
  "5540": {
    name: "tiny dinos winter 2022 #5540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5540,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5540.png",
  },
  "5541": {
    name: "tiny dinos winter 2022 #5541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5541,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5541.png",
  },
  "5542": {
    name: "tiny dinos winter 2022 #5542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5542,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5542.png",
  },
  "5543": {
    name: "tiny dinos winter 2022 #5543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5543,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5543.png",
  },
  "5544": {
    name: "tiny dinos winter 2022 #5544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5544,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5544.png",
  },
  "5545": {
    name: "tiny dinos winter 2022 #5545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5545,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5545.png",
  },
  "5546": {
    name: "tiny dinos winter 2022 #5546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5546,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5546.png",
  },
  "5547": {
    name: "tiny dinos winter 2022 #5547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5547,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5547.png",
  },
  "5548": {
    name: "tiny dinos winter 2022 #5548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5548,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5548.png",
  },
  "5549": {
    name: "tiny dinos winter 2022 #5549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5549,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5549.png",
  },
  "5550": {
    name: "tiny dinos winter 2022 #5550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5550,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5550.png",
  },
  "5551": {
    name: "tiny dinos winter 2022 #5551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5551,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5551.png",
  },
  "5552": {
    name: "tiny dinos winter 2022 #5552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5552,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5552.png",
  },
  "5553": {
    name: "tiny dinos winter 2022 #5553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5553,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5553.png",
  },
  "5554": {
    name: "tiny dinos winter 2022 #5554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5554,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5554.png",
  },
  "5555": {
    name: "tiny dinos winter 2022 #5555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5555,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5555.png",
  },
  "5556": {
    name: "tiny dinos winter 2022 #5556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5556,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5556.png",
  },
  "5557": {
    name: "tiny dinos winter 2022 #5557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5557,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5557.png",
  },
  "5558": {
    name: "tiny dinos winter 2022 #5558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5558,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5558.png",
  },
  "5559": {
    name: "tiny dinos winter 2022 #5559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5559,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5559.png",
  },
  "5560": {
    name: "tiny dinos winter 2022 #5560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5560,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5560.png",
  },
  "5561": {
    name: "tiny dinos winter 2022 #5561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5561,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5561.png",
  },
  "5562": {
    name: "tiny dinos winter 2022 #5562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5562,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5562.png",
  },
  "5563": {
    name: "tiny dinos winter 2022 #5563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5563,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5563.png",
  },
  "5564": {
    name: "tiny dinos winter 2022 #5564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5564,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5564.png",
  },
  "5565": {
    name: "tiny dinos winter 2022 #5565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5565,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5565.png",
  },
  "5566": {
    name: "tiny dinos winter 2022 #5566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5566,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5566.png",
  },
  "5567": {
    name: "tiny dinos winter 2022 #5567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5567,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5567.png",
  },
  "5568": {
    name: "tiny dinos winter 2022 #5568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5568,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5568.png",
  },
  "5569": {
    name: "tiny dinos winter 2022 #5569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5569,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5569.png",
  },
  "5570": {
    name: "tiny dinos winter 2022 #5570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5570,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5570.png",
  },
  "5571": {
    name: "tiny dinos winter 2022 #5571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5571,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5571.png",
  },
  "5572": {
    name: "tiny dinos winter 2022 #5572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5572,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5572.png",
  },
  "5573": {
    name: "tiny dinos winter 2022 #5573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5573,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5573.png",
  },
  "5574": {
    name: "tiny dinos winter 2022 #5574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5574,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5574.png",
  },
  "5575": {
    name: "tiny dinos winter 2022 #5575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5575,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5575.png",
  },
  "5576": {
    name: "tiny dinos winter 2022 #5576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5576,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5576.png",
  },
  "5577": {
    name: "tiny dinos winter 2022 #5577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5577,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5577.png",
  },
  "5578": {
    name: "tiny dinos winter 2022 #5578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5578,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5578.png",
  },
  "5579": {
    name: "tiny dinos winter 2022 #5579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5579,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5579.png",
  },
  "5580": {
    name: "tiny dinos winter 2022 #5580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5580,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5580.png",
  },
  "5581": {
    name: "tiny dinos winter 2022 #5581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5581,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5581.png",
  },
  "5582": {
    name: "tiny dinos winter 2022 #5582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5582,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5582.png",
  },
  "5583": {
    name: "tiny dinos winter 2022 #5583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5583,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5583.png",
  },
  "5584": {
    name: "tiny dinos winter 2022 #5584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5584,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5584.png",
  },
  "5585": {
    name: "tiny dinos winter 2022 #5585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5585,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5585.png",
  },
  "5586": {
    name: "tiny dinos winter 2022 #5586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5586,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5586.png",
  },
  "5587": {
    name: "tiny dinos winter 2022 #5587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5587,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5587.png",
  },
  "5588": {
    name: "tiny dinos winter 2022 #5588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5588,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5588.png",
  },
  "5589": {
    name: "tiny dinos winter 2022 #5589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5589,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5589.png",
  },
  "5590": {
    name: "tiny dinos winter 2022 #5590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5590,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5590.png",
  },
  "5591": {
    name: "tiny dinos winter 2022 #5591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5591,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5591.png",
  },
  "5592": {
    name: "tiny dinos winter 2022 #5592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5592,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5592.png",
  },
  "5593": {
    name: "tiny dinos winter 2022 #5593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5593,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5593.png",
  },
  "5594": {
    name: "tiny dinos winter 2022 #5594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5594,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5594.png",
  },
  "5595": {
    name: "tiny dinos winter 2022 #5595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5595,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5595.png",
  },
  "5596": {
    name: "tiny dinos winter 2022 #5596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5596,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5596.png",
  },
  "5597": {
    name: "tiny dinos winter 2022 #5597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5597,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5597.png",
  },
  "5598": {
    name: "tiny dinos winter 2022 #5598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5598,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5598.png",
  },
  "5599": {
    name: "tiny dinos winter 2022 #5599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5599,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5599.png",
  },
  "5600": {
    name: "tiny dinos winter 2022 #5600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5600,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5600.png",
  },
  "5601": {
    name: "tiny dinos winter 2022 #5601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5601,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5601.png",
  },
  "5602": {
    name: "tiny dinos winter 2022 #5602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5602,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5602.png",
  },
  "5603": {
    name: "tiny dinos winter 2022 #5603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5603,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5603.png",
  },
  "5604": {
    name: "tiny dinos winter 2022 #5604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5604,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5604.png",
  },
  "5605": {
    name: "tiny dinos winter 2022 #5605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5605,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5605.png",
  },
  "5606": {
    name: "tiny dinos winter 2022 #5606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5606,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5606.png",
  },
  "5607": {
    name: "tiny dinos winter 2022 #5607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5607,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5607.png",
  },
  "5608": {
    name: "tiny dinos winter 2022 #5608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5608,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5608.png",
  },
  "5609": {
    name: "tiny dinos winter 2022 #5609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5609,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5609.png",
  },
  "5610": {
    name: "tiny dinos winter 2022 #5610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5610,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5610.png",
  },
  "5611": {
    name: "tiny dinos winter 2022 #5611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5611,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5611.png",
  },
  "5612": {
    name: "tiny dinos winter 2022 #5612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5612,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5612.png",
  },
  "5613": {
    name: "tiny dinos winter 2022 #5613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5613,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5613.png",
  },
  "5614": {
    name: "tiny dinos winter 2022 #5614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5614,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5614.png",
  },
  "5615": {
    name: "tiny dinos winter 2022 #5615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5615,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5615.png",
  },
  "5616": {
    name: "tiny dinos winter 2022 #5616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5616,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5616.png",
  },
  "5617": {
    name: "tiny dinos winter 2022 #5617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5617,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5617.png",
  },
  "5618": {
    name: "tiny dinos winter 2022 #5618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5618,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5618.png",
  },
  "5619": {
    name: "tiny dinos winter 2022 #5619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5619,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5619.png",
  },
  "5620": {
    name: "tiny dinos winter 2022 #5620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5620,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5620.png",
  },
  "5621": {
    name: "tiny dinos winter 2022 #5621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5621,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5621.png",
  },
  "5622": {
    name: "tiny dinos winter 2022 #5622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5622,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5622.png",
  },
  "5623": {
    name: "tiny dinos winter 2022 #5623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5623,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5623.png",
  },
  "5624": {
    name: "tiny dinos winter 2022 #5624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5624,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5624.png",
  },
  "5625": {
    name: "tiny dinos winter 2022 #5625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5625,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5625.png",
  },
  "5626": {
    name: "tiny dinos winter 2022 #5626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5626,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5626.png",
  },
  "5627": {
    name: "tiny dinos winter 2022 #5627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5627,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5627.png",
  },
  "5628": {
    name: "tiny dinos winter 2022 #5628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5628,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5628.png",
  },
  "5629": {
    name: "tiny dinos winter 2022 #5629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5629,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5629.png",
  },
  "5630": {
    name: "tiny dinos winter 2022 #5630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5630,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5630.png",
  },
  "5631": {
    name: "tiny dinos winter 2022 #5631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5631,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5631.png",
  },
  "5632": {
    name: "tiny dinos winter 2022 #5632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5632,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5632.png",
  },
  "5633": {
    name: "tiny dinos winter 2022 #5633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5633,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5633.png",
  },
  "5634": {
    name: "tiny dinos winter 2022 #5634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5634,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5634.png",
  },
  "5635": {
    name: "tiny dinos winter 2022 #5635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5635,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5635.png",
  },
  "5636": {
    name: "tiny dinos winter 2022 #5636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5636,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5636.png",
  },
  "5637": {
    name: "tiny dinos winter 2022 #5637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5637,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5637.png",
  },
  "5638": {
    name: "tiny dinos winter 2022 #5638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5638,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5638.png",
  },
  "5639": {
    name: "tiny dinos winter 2022 #5639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5639,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5639.png",
  },
  "5640": {
    name: "tiny dinos winter 2022 #5640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5640,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5640.png",
  },
  "5641": {
    name: "tiny dinos winter 2022 #5641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5641,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5641.png",
  },
  "5642": {
    name: "tiny dinos winter 2022 #5642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5642,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5642.png",
  },
  "5643": {
    name: "tiny dinos winter 2022 #5643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5643,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5643.png",
  },
  "5644": {
    name: "tiny dinos winter 2022 #5644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5644,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5644.png",
  },
  "5645": {
    name: "tiny dinos winter 2022 #5645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5645,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5645.png",
  },
  "5646": {
    name: "tiny dinos winter 2022 #5646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5646,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5646.png",
  },
  "5647": {
    name: "tiny dinos winter 2022 #5647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5647,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5647.png",
  },
  "5648": {
    name: "tiny dinos winter 2022 #5648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5648,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5648.png",
  },
  "5649": {
    name: "tiny dinos winter 2022 #5649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5649,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5649.png",
  },
  "5650": {
    name: "tiny dinos winter 2022 #5650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5650,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5650.png",
  },
  "5651": {
    name: "tiny dinos winter 2022 #5651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5651,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5651.png",
  },
  "5652": {
    name: "tiny dinos winter 2022 #5652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5652,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5652.png",
  },
  "5653": {
    name: "tiny dinos winter 2022 #5653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5653,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5653.png",
  },
  "5654": {
    name: "tiny dinos winter 2022 #5654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5654,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5654.png",
  },
  "5655": {
    name: "tiny dinos winter 2022 #5655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5655,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5655.png",
  },
  "5656": {
    name: "tiny dinos winter 2022 #5656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5656,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5656.png",
  },
  "5657": {
    name: "tiny dinos winter 2022 #5657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5657,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5657.png",
  },
  "5658": {
    name: "tiny dinos winter 2022 #5658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5658,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5658.png",
  },
  "5659": {
    name: "tiny dinos winter 2022 #5659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5659,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5659.png",
  },
  "5660": {
    name: "tiny dinos winter 2022 #5660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5660,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5660.png",
  },
  "5661": {
    name: "tiny dinos winter 2022 #5661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5661,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5661.png",
  },
  "5662": {
    name: "tiny dinos winter 2022 #5662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5662,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5662.png",
  },
  "5663": {
    name: "tiny dinos winter 2022 #5663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5663,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5663.png",
  },
  "5664": {
    name: "tiny dinos winter 2022 #5664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5664,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5664.png",
  },
  "5665": {
    name: "tiny dinos winter 2022 #5665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5665,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5665.png",
  },
  "5666": {
    name: "tiny dinos winter 2022 #5666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5666,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5666.png",
  },
  "5667": {
    name: "tiny dinos winter 2022 #5667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5667,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5667.png",
  },
  "5668": {
    name: "tiny dinos winter 2022 #5668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5668,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5668.png",
  },
  "5669": {
    name: "tiny dinos winter 2022 #5669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5669,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5669.png",
  },
  "5670": {
    name: "tiny dinos winter 2022 #5670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5670,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5670.png",
  },
  "5671": {
    name: "tiny dinos winter 2022 #5671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5671,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5671.png",
  },
  "5672": {
    name: "tiny dinos winter 2022 #5672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5672,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5672.png",
  },
  "5673": {
    name: "tiny dinos winter 2022 #5673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5673,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5673.png",
  },
  "5674": {
    name: "tiny dinos winter 2022 #5674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5674,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5674.png",
  },
  "5675": {
    name: "tiny dinos winter 2022 #5675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5675,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5675.png",
  },
  "5676": {
    name: "tiny dinos winter 2022 #5676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5676,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5676.png",
  },
  "5677": {
    name: "tiny dinos winter 2022 #5677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5677,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5677.png",
  },
  "5678": {
    name: "tiny dinos winter 2022 #5678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5678,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5678.png",
  },
  "5679": {
    name: "tiny dinos winter 2022 #5679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5679,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5679.png",
  },
  "5680": {
    name: "tiny dinos winter 2022 #5680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5680,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5680.png",
  },
  "5681": {
    name: "tiny dinos winter 2022 #5681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5681,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5681.png",
  },
  "5682": {
    name: "tiny dinos winter 2022 #5682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5682,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5682.png",
  },
  "5683": {
    name: "tiny dinos winter 2022 #5683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5683,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5683.png",
  },
  "5684": {
    name: "tiny dinos winter 2022 #5684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5684,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5684.png",
  },
  "5685": {
    name: "tiny dinos winter 2022 #5685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5685,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5685.png",
  },
  "5686": {
    name: "tiny dinos winter 2022 #5686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5686,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5686.png",
  },
  "5687": {
    name: "tiny dinos winter 2022 #5687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5687,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5687.png",
  },
  "5688": {
    name: "tiny dinos winter 2022 #5688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5688,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5688.png",
  },
  "5689": {
    name: "tiny dinos winter 2022 #5689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5689,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5689.png",
  },
  "5690": {
    name: "tiny dinos winter 2022 #5690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5690,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5690.png",
  },
  "5691": {
    name: "tiny dinos winter 2022 #5691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5691,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5691.png",
  },
  "5692": {
    name: "tiny dinos winter 2022 #5692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5692,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5692.png",
  },
  "5693": {
    name: "tiny dinos winter 2022 #5693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5693,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5693.png",
  },
  "5694": {
    name: "tiny dinos winter 2022 #5694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5694,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5694.png",
  },
  "5695": {
    name: "tiny dinos winter 2022 #5695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5695,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5695.png",
  },
  "5696": {
    name: "tiny dinos winter 2022 #5696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5696,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5696.png",
  },
  "5697": {
    name: "tiny dinos winter 2022 #5697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5697,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5697.png",
  },
  "5698": {
    name: "tiny dinos winter 2022 #5698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5698,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5698.png",
  },
  "5699": {
    name: "tiny dinos winter 2022 #5699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5699,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5699.png",
  },
  "5700": {
    name: "tiny dinos winter 2022 #5700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5700,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5700.png",
  },
  "5701": {
    name: "tiny dinos winter 2022 #5701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5701,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5701.png",
  },
  "5702": {
    name: "tiny dinos winter 2022 #5702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5702,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5702.png",
  },
  "5703": {
    name: "tiny dinos winter 2022 #5703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5703,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5703.png",
  },
  "5704": {
    name: "tiny dinos winter 2022 #5704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5704,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5704.png",
  },
  "5705": {
    name: "tiny dinos winter 2022 #5705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5705,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5705.png",
  },
  "5706": {
    name: "tiny dinos winter 2022 #5706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5706,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5706.png",
  },
  "5707": {
    name: "tiny dinos winter 2022 #5707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5707,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5707.png",
  },
  "5708": {
    name: "tiny dinos winter 2022 #5708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5708,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5708.png",
  },
  "5709": {
    name: "tiny dinos winter 2022 #5709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5709,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5709.png",
  },
  "5710": {
    name: "tiny dinos winter 2022 #5710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5710,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5710.png",
  },
  "5711": {
    name: "tiny dinos winter 2022 #5711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5711,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5711.png",
  },
  "5712": {
    name: "tiny dinos winter 2022 #5712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5712,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5712.png",
  },
  "5713": {
    name: "tiny dinos winter 2022 #5713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5713,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5713.png",
  },
  "5714": {
    name: "tiny dinos winter 2022 #5714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5714,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5714.png",
  },
  "5715": {
    name: "tiny dinos winter 2022 #5715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5715,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5715.png",
  },
  "5716": {
    name: "tiny dinos winter 2022 #5716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5716,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5716.png",
  },
  "5717": {
    name: "tiny dinos winter 2022 #5717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5717,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5717.png",
  },
  "5718": {
    name: "tiny dinos winter 2022 #5718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5718,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5718.png",
  },
  "5719": {
    name: "tiny dinos winter 2022 #5719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5719,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5719.png",
  },
  "5720": {
    name: "tiny dinos winter 2022 #5720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5720,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5720.png",
  },
  "5721": {
    name: "tiny dinos winter 2022 #5721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5721,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5721.png",
  },
  "5722": {
    name: "tiny dinos winter 2022 #5722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5722,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5722.png",
  },
  "5723": {
    name: "tiny dinos winter 2022 #5723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5723,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5723.png",
  },
  "5724": {
    name: "tiny dinos winter 2022 #5724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5724,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5724.png",
  },
  "5725": {
    name: "tiny dinos winter 2022 #5725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5725,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5725.png",
  },
  "5726": {
    name: "tiny dinos winter 2022 #5726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5726,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5726.png",
  },
  "5727": {
    name: "tiny dinos winter 2022 #5727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5727,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5727.png",
  },
  "5728": {
    name: "tiny dinos winter 2022 #5728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5728,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5728.png",
  },
  "5729": {
    name: "tiny dinos winter 2022 #5729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5729,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5729.png",
  },
  "5730": {
    name: "tiny dinos winter 2022 #5730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5730,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5730.png",
  },
  "5731": {
    name: "tiny dinos winter 2022 #5731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5731,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5731.png",
  },
  "5732": {
    name: "tiny dinos winter 2022 #5732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5732,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5732.png",
  },
  "5733": {
    name: "tiny dinos winter 2022 #5733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5733,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5733.png",
  },
  "5734": {
    name: "tiny dinos winter 2022 #5734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5734,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5734.png",
  },
  "5735": {
    name: "tiny dinos winter 2022 #5735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5735,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5735.png",
  },
  "5736": {
    name: "tiny dinos winter 2022 #5736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5736,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5736.png",
  },
  "5737": {
    name: "tiny dinos winter 2022 #5737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5737,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5737.png",
  },
  "5738": {
    name: "tiny dinos winter 2022 #5738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5738,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5738.png",
  },
  "5739": {
    name: "tiny dinos winter 2022 #5739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5739,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5739.png",
  },
  "5740": {
    name: "tiny dinos winter 2022 #5740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5740,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5740.png",
  },
  "5741": {
    name: "tiny dinos winter 2022 #5741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5741,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5741.png",
  },
  "5742": {
    name: "tiny dinos winter 2022 #5742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5742,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5742.png",
  },
  "5743": {
    name: "tiny dinos winter 2022 #5743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5743,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5743.png",
  },
  "5744": {
    name: "tiny dinos winter 2022 #5744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5744,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5744.png",
  },
  "5745": {
    name: "tiny dinos winter 2022 #5745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5745,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5745.png",
  },
  "5746": {
    name: "tiny dinos winter 2022 #5746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5746,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5746.png",
  },
  "5747": {
    name: "tiny dinos winter 2022 #5747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5747,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5747.png",
  },
  "5748": {
    name: "tiny dinos winter 2022 #5748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5748,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5748.png",
  },
  "5749": {
    name: "tiny dinos winter 2022 #5749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5749,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5749.png",
  },
  "5750": {
    name: "tiny dinos winter 2022 #5750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5750,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5750.png",
  },
  "5751": {
    name: "tiny dinos winter 2022 #5751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5751,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5751.png",
  },
  "5752": {
    name: "tiny dinos winter 2022 #5752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5752,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5752.png",
  },
  "5753": {
    name: "tiny dinos winter 2022 #5753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5753,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5753.png",
  },
  "5754": {
    name: "tiny dinos winter 2022 #5754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5754,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5754.png",
  },
  "5755": {
    name: "tiny dinos winter 2022 #5755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5755,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5755.png",
  },
  "5756": {
    name: "tiny dinos winter 2022 #5756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5756,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5756.png",
  },
  "5757": {
    name: "tiny dinos winter 2022 #5757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5757,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5757.png",
  },
  "5758": {
    name: "tiny dinos winter 2022 #5758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5758,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5758.png",
  },
  "5759": {
    name: "tiny dinos winter 2022 #5759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5759,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5759.png",
  },
  "5760": {
    name: "tiny dinos winter 2022 #5760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5760,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5760.png",
  },
  "5761": {
    name: "tiny dinos winter 2022 #5761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5761,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5761.png",
  },
  "5762": {
    name: "tiny dinos winter 2022 #5762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5762,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5762.png",
  },
  "5763": {
    name: "tiny dinos winter 2022 #5763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5763,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5763.png",
  },
  "5764": {
    name: "tiny dinos winter 2022 #5764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5764,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5764.png",
  },
  "5765": {
    name: "tiny dinos winter 2022 #5765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5765,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5765.png",
  },
  "5766": {
    name: "tiny dinos winter 2022 #5766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5766,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5766.png",
  },
  "5767": {
    name: "tiny dinos winter 2022 #5767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5767,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5767.png",
  },
  "5768": {
    name: "tiny dinos winter 2022 #5768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5768,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5768.png",
  },
  "5769": {
    name: "tiny dinos winter 2022 #5769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5769,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5769.png",
  },
  "5770": {
    name: "tiny dinos winter 2022 #5770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5770,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5770.png",
  },
  "5771": {
    name: "tiny dinos winter 2022 #5771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5771,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5771.png",
  },
  "5772": {
    name: "tiny dinos winter 2022 #5772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5772,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5772.png",
  },
  "5773": {
    name: "tiny dinos winter 2022 #5773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5773,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5773.png",
  },
  "5774": {
    name: "tiny dinos winter 2022 #5774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5774,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5774.png",
  },
  "5775": {
    name: "tiny dinos winter 2022 #5775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5775,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5775.png",
  },
  "5776": {
    name: "tiny dinos winter 2022 #5776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5776,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5776.png",
  },
  "5777": {
    name: "tiny dinos winter 2022 #5777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5777,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5777.png",
  },
  "5778": {
    name: "tiny dinos winter 2022 #5778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5778,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5778.png",
  },
  "5779": {
    name: "tiny dinos winter 2022 #5779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5779,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5779.png",
  },
  "5780": {
    name: "tiny dinos winter 2022 #5780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5780,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5780.png",
  },
  "5781": {
    name: "tiny dinos winter 2022 #5781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5781,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5781.png",
  },
  "5782": {
    name: "tiny dinos winter 2022 #5782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5782,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5782.png",
  },
  "5783": {
    name: "tiny dinos winter 2022 #5783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5783,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5783.png",
  },
  "5784": {
    name: "tiny dinos winter 2022 #5784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5784,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5784.png",
  },
  "5785": {
    name: "tiny dinos winter 2022 #5785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5785,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5785.png",
  },
  "5786": {
    name: "tiny dinos winter 2022 #5786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5786,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5786.png",
  },
  "5787": {
    name: "tiny dinos winter 2022 #5787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5787,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5787.png",
  },
  "5788": {
    name: "tiny dinos winter 2022 #5788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5788,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5788.png",
  },
  "5789": {
    name: "tiny dinos winter 2022 #5789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5789,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5789.png",
  },
  "5790": {
    name: "tiny dinos winter 2022 #5790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5790,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5790.png",
  },
  "5791": {
    name: "tiny dinos winter 2022 #5791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5791,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5791.png",
  },
  "5792": {
    name: "tiny dinos winter 2022 #5792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5792,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5792.png",
  },
  "5793": {
    name: "tiny dinos winter 2022 #5793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5793,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5793.png",
  },
  "5794": {
    name: "tiny dinos winter 2022 #5794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5794,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5794.png",
  },
  "5795": {
    name: "tiny dinos winter 2022 #5795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5795,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5795.png",
  },
  "5796": {
    name: "tiny dinos winter 2022 #5796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5796,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5796.png",
  },
  "5797": {
    name: "tiny dinos winter 2022 #5797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5797,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5797.png",
  },
  "5798": {
    name: "tiny dinos winter 2022 #5798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5798,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5798.png",
  },
  "5799": {
    name: "tiny dinos winter 2022 #5799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5799,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5799.png",
  },
  "5800": {
    name: "tiny dinos winter 2022 #5800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5800,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5800.png",
  },
  "5801": {
    name: "tiny dinos winter 2022 #5801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5801,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5801.png",
  },
  "5802": {
    name: "tiny dinos winter 2022 #5802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5802,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5802.png",
  },
  "5803": {
    name: "tiny dinos winter 2022 #5803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5803,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5803.png",
  },
  "5804": {
    name: "tiny dinos winter 2022 #5804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5804,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5804.png",
  },
  "5805": {
    name: "tiny dinos winter 2022 #5805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5805,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5805.png",
  },
  "5806": {
    name: "tiny dinos winter 2022 #5806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5806,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5806.png",
  },
  "5807": {
    name: "tiny dinos winter 2022 #5807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5807,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5807.png",
  },
  "5808": {
    name: "tiny dinos winter 2022 #5808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5808,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5808.png",
  },
  "5809": {
    name: "tiny dinos winter 2022 #5809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5809,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5809.png",
  },
  "5810": {
    name: "tiny dinos winter 2022 #5810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5810,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5810.png",
  },
  "5811": {
    name: "tiny dinos winter 2022 #5811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5811,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5811.png",
  },
  "5812": {
    name: "tiny dinos winter 2022 #5812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5812,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5812.png",
  },
  "5813": {
    name: "tiny dinos winter 2022 #5813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5813,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5813.png",
  },
  "5814": {
    name: "tiny dinos winter 2022 #5814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5814,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5814.png",
  },
  "5815": {
    name: "tiny dinos winter 2022 #5815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5815,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5815.png",
  },
  "5816": {
    name: "tiny dinos winter 2022 #5816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5816,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5816.png",
  },
  "5817": {
    name: "tiny dinos winter 2022 #5817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5817,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5817.png",
  },
  "5818": {
    name: "tiny dinos winter 2022 #5818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5818,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5818.png",
  },
  "5819": {
    name: "tiny dinos winter 2022 #5819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5819,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5819.png",
  },
  "5820": {
    name: "tiny dinos winter 2022 #5820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5820,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5820.png",
  },
  "5821": {
    name: "tiny dinos winter 2022 #5821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5821,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5821.png",
  },
  "5822": {
    name: "tiny dinos winter 2022 #5822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5822,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5822.png",
  },
  "5823": {
    name: "tiny dinos winter 2022 #5823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5823,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5823.png",
  },
  "5824": {
    name: "tiny dinos winter 2022 #5824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5824,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5824.png",
  },
  "5825": {
    name: "tiny dinos winter 2022 #5825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5825,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5825.png",
  },
  "5826": {
    name: "tiny dinos winter 2022 #5826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5826,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5826.png",
  },
  "5827": {
    name: "tiny dinos winter 2022 #5827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5827,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5827.png",
  },
  "5828": {
    name: "tiny dinos winter 2022 #5828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5828,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5828.png",
  },
  "5829": {
    name: "tiny dinos winter 2022 #5829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5829,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5829.png",
  },
  "5830": {
    name: "tiny dinos winter 2022 #5830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5830,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5830.png",
  },
  "5831": {
    name: "tiny dinos winter 2022 #5831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5831,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5831.png",
  },
  "5832": {
    name: "tiny dinos winter 2022 #5832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5832,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5832.png",
  },
  "5833": {
    name: "tiny dinos winter 2022 #5833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5833,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5833.png",
  },
  "5834": {
    name: "tiny dinos winter 2022 #5834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5834,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5834.png",
  },
  "5835": {
    name: "tiny dinos winter 2022 #5835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5835,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5835.png",
  },
  "5836": {
    name: "tiny dinos winter 2022 #5836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5836,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5836.png",
  },
  "5837": {
    name: "tiny dinos winter 2022 #5837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5837,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5837.png",
  },
  "5838": {
    name: "tiny dinos winter 2022 #5838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5838,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5838.png",
  },
  "5839": {
    name: "tiny dinos winter 2022 #5839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5839,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5839.png",
  },
  "5840": {
    name: "tiny dinos winter 2022 #5840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5840,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5840.png",
  },
  "5841": {
    name: "tiny dinos winter 2022 #5841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5841,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5841.png",
  },
  "5842": {
    name: "tiny dinos winter 2022 #5842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5842,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5842.png",
  },
  "5843": {
    name: "tiny dinos winter 2022 #5843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5843,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5843.png",
  },
  "5844": {
    name: "tiny dinos winter 2022 #5844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5844,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5844.png",
  },
  "5845": {
    name: "tiny dinos winter 2022 #5845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5845,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5845.png",
  },
  "5846": {
    name: "tiny dinos winter 2022 #5846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5846,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5846.png",
  },
  "5847": {
    name: "tiny dinos winter 2022 #5847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5847,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5847.png",
  },
  "5848": {
    name: "tiny dinos winter 2022 #5848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5848,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5848.png",
  },
  "5849": {
    name: "tiny dinos winter 2022 #5849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5849,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5849.png",
  },
  "5850": {
    name: "tiny dinos winter 2022 #5850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5850,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5850.png",
  },
  "5851": {
    name: "tiny dinos winter 2022 #5851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5851,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5851.png",
  },
  "5852": {
    name: "tiny dinos winter 2022 #5852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5852,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5852.png",
  },
  "5853": {
    name: "tiny dinos winter 2022 #5853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5853,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5853.png",
  },
  "5854": {
    name: "tiny dinos winter 2022 #5854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5854,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5854.png",
  },
  "5855": {
    name: "tiny dinos winter 2022 #5855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5855,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5855.png",
  },
  "5856": {
    name: "tiny dinos winter 2022 #5856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5856,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5856.png",
  },
  "5857": {
    name: "tiny dinos winter 2022 #5857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5857,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5857.png",
  },
  "5858": {
    name: "tiny dinos winter 2022 #5858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5858,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5858.png",
  },
  "5859": {
    name: "tiny dinos winter 2022 #5859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5859,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5859.png",
  },
  "5860": {
    name: "tiny dinos winter 2022 #5860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5860,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5860.png",
  },
  "5861": {
    name: "tiny dinos winter 2022 #5861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5861,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5861.png",
  },
  "5862": {
    name: "tiny dinos winter 2022 #5862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5862,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5862.png",
  },
  "5863": {
    name: "tiny dinos winter 2022 #5863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5863,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5863.png",
  },
  "5864": {
    name: "tiny dinos winter 2022 #5864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5864,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5864.png",
  },
  "5865": {
    name: "tiny dinos winter 2022 #5865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5865,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5865.png",
  },
  "5866": {
    name: "tiny dinos winter 2022 #5866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5866,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5866.png",
  },
  "5867": {
    name: "tiny dinos winter 2022 #5867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5867,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5867.png",
  },
  "5868": {
    name: "tiny dinos winter 2022 #5868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5868,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5868.png",
  },
  "5869": {
    name: "tiny dinos winter 2022 #5869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5869,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5869.png",
  },
  "5870": {
    name: "tiny dinos winter 2022 #5870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5870,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5870.png",
  },
  "5871": {
    name: "tiny dinos winter 2022 #5871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5871,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5871.png",
  },
  "5872": {
    name: "tiny dinos winter 2022 #5872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5872,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5872.png",
  },
  "5873": {
    name: "tiny dinos winter 2022 #5873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5873,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5873.png",
  },
  "5874": {
    name: "tiny dinos winter 2022 #5874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5874,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5874.png",
  },
  "5875": {
    name: "tiny dinos winter 2022 #5875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5875,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5875.png",
  },
  "5876": {
    name: "tiny dinos winter 2022 #5876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5876,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5876.png",
  },
  "5877": {
    name: "tiny dinos winter 2022 #5877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5877,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5877.png",
  },
  "5878": {
    name: "tiny dinos winter 2022 #5878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5878,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5878.png",
  },
  "5879": {
    name: "tiny dinos winter 2022 #5879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5879,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5879.png",
  },
  "5880": {
    name: "tiny dinos winter 2022 #5880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5880,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5880.png",
  },
  "5881": {
    name: "tiny dinos winter 2022 #5881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5881,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5881.png",
  },
  "5882": {
    name: "tiny dinos winter 2022 #5882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5882,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5882.png",
  },
  "5883": {
    name: "tiny dinos winter 2022 #5883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5883,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5883.png",
  },
  "5884": {
    name: "tiny dinos winter 2022 #5884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5884,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5884.png",
  },
  "5885": {
    name: "tiny dinos winter 2022 #5885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5885,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5885.png",
  },
  "5886": {
    name: "tiny dinos winter 2022 #5886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5886,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5886.png",
  },
  "5887": {
    name: "tiny dinos winter 2022 #5887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5887,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5887.png",
  },
  "5888": {
    name: "tiny dinos winter 2022 #5888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5888,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5888.png",
  },
  "5889": {
    name: "tiny dinos winter 2022 #5889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5889,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5889.png",
  },
  "5890": {
    name: "tiny dinos winter 2022 #5890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5890,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5890.png",
  },
  "5891": {
    name: "tiny dinos winter 2022 #5891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5891,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5891.png",
  },
  "5892": {
    name: "tiny dinos winter 2022 #5892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5892,
    attributes: [
      {
        trait_type: "1/1",
        value: "smol birb",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5892.png",
  },
  "5893": {
    name: "tiny dinos winter 2022 #5893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5893,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5893.png",
  },
  "5894": {
    name: "tiny dinos winter 2022 #5894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5894,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5894.png",
  },
  "5895": {
    name: "tiny dinos winter 2022 #5895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5895,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5895.png",
  },
  "5896": {
    name: "tiny dinos winter 2022 #5896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5896,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5896.png",
  },
  "5897": {
    name: "tiny dinos winter 2022 #5897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5897,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5897.png",
  },
  "5898": {
    name: "tiny dinos winter 2022 #5898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5898,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5898.png",
  },
  "5899": {
    name: "tiny dinos winter 2022 #5899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5899,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5899.png",
  },
  "5900": {
    name: "tiny dinos winter 2022 #5900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5900,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5900.png",
  },
  "5901": {
    name: "tiny dinos winter 2022 #5901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5901,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5901.png",
  },
  "5902": {
    name: "tiny dinos winter 2022 #5902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5902,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5902.png",
  },
  "5903": {
    name: "tiny dinos winter 2022 #5903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5903,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5903.png",
  },
  "5904": {
    name: "tiny dinos winter 2022 #5904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5904,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5904.png",
  },
  "5905": {
    name: "tiny dinos winter 2022 #5905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5905,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5905.png",
  },
  "5906": {
    name: "tiny dinos winter 2022 #5906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5906,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5906.png",
  },
  "5907": {
    name: "tiny dinos winter 2022 #5907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5907,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5907.png",
  },
  "5908": {
    name: "tiny dinos winter 2022 #5908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5908,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5908.png",
  },
  "5909": {
    name: "tiny dinos winter 2022 #5909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5909,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5909.png",
  },
  "5910": {
    name: "tiny dinos winter 2022 #5910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5910,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5910.png",
  },
  "5911": {
    name: "tiny dinos winter 2022 #5911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5911,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5911.png",
  },
  "5912": {
    name: "tiny dinos winter 2022 #5912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5912,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5912.png",
  },
  "5913": {
    name: "tiny dinos winter 2022 #5913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5913,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5913.png",
  },
  "5914": {
    name: "tiny dinos winter 2022 #5914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5914,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5914.png",
  },
  "5915": {
    name: "tiny dinos winter 2022 #5915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5915,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5915.png",
  },
  "5916": {
    name: "tiny dinos winter 2022 #5916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5916,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5916.png",
  },
  "5917": {
    name: "tiny dinos winter 2022 #5917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5917,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5917.png",
  },
  "5918": {
    name: "tiny dinos winter 2022 #5918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5918,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5918.png",
  },
  "5919": {
    name: "tiny dinos winter 2022 #5919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5919,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5919.png",
  },
  "5920": {
    name: "tiny dinos winter 2022 #5920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5920,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5920.png",
  },
  "5921": {
    name: "tiny dinos winter 2022 #5921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5921,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5921.png",
  },
  "5922": {
    name: "tiny dinos winter 2022 #5922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5922,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5922.png",
  },
  "5923": {
    name: "tiny dinos winter 2022 #5923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5923,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5923.png",
  },
  "5924": {
    name: "tiny dinos winter 2022 #5924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5924,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5924.png",
  },
  "5925": {
    name: "tiny dinos winter 2022 #5925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5925,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5925.png",
  },
  "5926": {
    name: "tiny dinos winter 2022 #5926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5926,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5926.png",
  },
  "5927": {
    name: "tiny dinos winter 2022 #5927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5927,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5927.png",
  },
  "5928": {
    name: "tiny dinos winter 2022 #5928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5928,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5928.png",
  },
  "5929": {
    name: "tiny dinos winter 2022 #5929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5929,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5929.png",
  },
  "5930": {
    name: "tiny dinos winter 2022 #5930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5930,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5930.png",
  },
  "5931": {
    name: "tiny dinos winter 2022 #5931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5931,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5931.png",
  },
  "5932": {
    name: "tiny dinos winter 2022 #5932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5932,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5932.png",
  },
  "5933": {
    name: "tiny dinos winter 2022 #5933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5933,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5933.png",
  },
  "5934": {
    name: "tiny dinos winter 2022 #5934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5934,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5934.png",
  },
  "5935": {
    name: "tiny dinos winter 2022 #5935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5935,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5935.png",
  },
  "5936": {
    name: "tiny dinos winter 2022 #5936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5936,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5936.png",
  },
  "5937": {
    name: "tiny dinos winter 2022 #5937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5937,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5937.png",
  },
  "5938": {
    name: "tiny dinos winter 2022 #5938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5938,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5938.png",
  },
  "5939": {
    name: "tiny dinos winter 2022 #5939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5939,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5939.png",
  },
  "5940": {
    name: "tiny dinos winter 2022 #5940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5940,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5940.png",
  },
  "5941": {
    name: "tiny dinos winter 2022 #5941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5941,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5941.png",
  },
  "5942": {
    name: "tiny dinos winter 2022 #5942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5942,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5942.png",
  },
  "5943": {
    name: "tiny dinos winter 2022 #5943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5943,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5943.png",
  },
  "5944": {
    name: "tiny dinos winter 2022 #5944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5944,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5944.png",
  },
  "5945": {
    name: "tiny dinos winter 2022 #5945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5945,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5945.png",
  },
  "5946": {
    name: "tiny dinos winter 2022 #5946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5946,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5946.png",
  },
  "5947": {
    name: "tiny dinos winter 2022 #5947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5947,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5947.png",
  },
  "5948": {
    name: "tiny dinos winter 2022 #5948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5948,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5948.png",
  },
  "5949": {
    name: "tiny dinos winter 2022 #5949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5949,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5949.png",
  },
  "5950": {
    name: "tiny dinos winter 2022 #5950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5950,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5950.png",
  },
  "5951": {
    name: "tiny dinos winter 2022 #5951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5951,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5951.png",
  },
  "5952": {
    name: "tiny dinos winter 2022 #5952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5952,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5952.png",
  },
  "5953": {
    name: "tiny dinos winter 2022 #5953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5953,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5953.png",
  },
  "5954": {
    name: "tiny dinos winter 2022 #5954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5954,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5954.png",
  },
  "5955": {
    name: "tiny dinos winter 2022 #5955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5955,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5955.png",
  },
  "5956": {
    name: "tiny dinos winter 2022 #5956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5956,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5956.png",
  },
  "5957": {
    name: "tiny dinos winter 2022 #5957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5957,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5957.png",
  },
  "5958": {
    name: "tiny dinos winter 2022 #5958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5958,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5958.png",
  },
  "5959": {
    name: "tiny dinos winter 2022 #5959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5959,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5959.png",
  },
  "5960": {
    name: "tiny dinos winter 2022 #5960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5960,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5960.png",
  },
  "5961": {
    name: "tiny dinos winter 2022 #5961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5961,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5961.png",
  },
  "5962": {
    name: "tiny dinos winter 2022 #5962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5962,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5962.png",
  },
  "5963": {
    name: "tiny dinos winter 2022 #5963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5963,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5963.png",
  },
  "5964": {
    name: "tiny dinos winter 2022 #5964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5964,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5964.png",
  },
  "5965": {
    name: "tiny dinos winter 2022 #5965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5965,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5965.png",
  },
  "5966": {
    name: "tiny dinos winter 2022 #5966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5966,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5966.png",
  },
  "5967": {
    name: "tiny dinos winter 2022 #5967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5967,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5967.png",
  },
  "5968": {
    name: "tiny dinos winter 2022 #5968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5968,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5968.png",
  },
  "5969": {
    name: "tiny dinos winter 2022 #5969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5969,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5969.png",
  },
  "5970": {
    name: "tiny dinos winter 2022 #5970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5970,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5970.png",
  },
  "5971": {
    name: "tiny dinos winter 2022 #5971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5971,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5971.png",
  },
  "5972": {
    name: "tiny dinos winter 2022 #5972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5972,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5972.png",
  },
  "5973": {
    name: "tiny dinos winter 2022 #5973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5973,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5973.png",
  },
  "5974": {
    name: "tiny dinos winter 2022 #5974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5974,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5974.png",
  },
  "5975": {
    name: "tiny dinos winter 2022 #5975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5975,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5975.png",
  },
  "5976": {
    name: "tiny dinos winter 2022 #5976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5976,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5976.png",
  },
  "5977": {
    name: "tiny dinos winter 2022 #5977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5977,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5977.png",
  },
  "5978": {
    name: "tiny dinos winter 2022 #5978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5978,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5978.png",
  },
  "5979": {
    name: "tiny dinos winter 2022 #5979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5979,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5979.png",
  },
  "5980": {
    name: "tiny dinos winter 2022 #5980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5980,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5980.png",
  },
  "5981": {
    name: "tiny dinos winter 2022 #5981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5981,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5981.png",
  },
  "5982": {
    name: "tiny dinos winter 2022 #5982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5982,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5982.png",
  },
  "5983": {
    name: "tiny dinos winter 2022 #5983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5983,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5983.png",
  },
  "5984": {
    name: "tiny dinos winter 2022 #5984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5984,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5984.png",
  },
  "5985": {
    name: "tiny dinos winter 2022 #5985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5985,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5985.png",
  },
  "5986": {
    name: "tiny dinos winter 2022 #5986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5986,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5986.png",
  },
  "5987": {
    name: "tiny dinos winter 2022 #5987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5987,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5987.png",
  },
  "5988": {
    name: "tiny dinos winter 2022 #5988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5988,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5988.png",
  },
  "5989": {
    name: "tiny dinos winter 2022 #5989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5989,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5989.png",
  },
  "5990": {
    name: "tiny dinos winter 2022 #5990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5990,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5990.png",
  },
  "5991": {
    name: "tiny dinos winter 2022 #5991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5991,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5991.png",
  },
  "5992": {
    name: "tiny dinos winter 2022 #5992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5992,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5992.png",
  },
  "5993": {
    name: "tiny dinos winter 2022 #5993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5993,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5993.png",
  },
  "5994": {
    name: "tiny dinos winter 2022 #5994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5994,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5994.png",
  },
  "5995": {
    name: "tiny dinos winter 2022 #5995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5995,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5995.png",
  },
  "5996": {
    name: "tiny dinos winter 2022 #5996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5996,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5996.png",
  },
  "5997": {
    name: "tiny dinos winter 2022 #5997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5997,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5997.png",
  },
  "5998": {
    name: "tiny dinos winter 2022 #5998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5998,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5998.png",
  },
  "5999": {
    name: "tiny dinos winter 2022 #5999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 5999,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/5999.png",
  },
  "6000": {
    name: "tiny dinos winter 2022 #6000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6000,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6000.png",
  },
  "6001": {
    name: "tiny dinos winter 2022 #6001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6001,
    attributes: [
      {
        trait_type: "1/1",
        value: "xoshi",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6001.png",
  },
  "6002": {
    name: "tiny dinos winter 2022 #6002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6002,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6002.png",
  },
  "6003": {
    name: "tiny dinos winter 2022 #6003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6003,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6003.png",
  },
  "6004": {
    name: "tiny dinos winter 2022 #6004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6004,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6004.png",
  },
  "6005": {
    name: "tiny dinos winter 2022 #6005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6005,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6005.png",
  },
  "6006": {
    name: "tiny dinos winter 2022 #6006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6006,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6006.png",
  },
  "6007": {
    name: "tiny dinos winter 2022 #6007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6007,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6007.png",
  },
  "6008": {
    name: "tiny dinos winter 2022 #6008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6008,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6008.png",
  },
  "6009": {
    name: "tiny dinos winter 2022 #6009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6009,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6009.png",
  },
  "6010": {
    name: "tiny dinos winter 2022 #6010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6010,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6010.png",
  },
  "6011": {
    name: "tiny dinos winter 2022 #6011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6011,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6011.png",
  },
  "6012": {
    name: "tiny dinos winter 2022 #6012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6012,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6012.png",
  },
  "6013": {
    name: "tiny dinos winter 2022 #6013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6013,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6013.png",
  },
  "6014": {
    name: "tiny dinos winter 2022 #6014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6014,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6014.png",
  },
  "6015": {
    name: "tiny dinos winter 2022 #6015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6015,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6015.png",
  },
  "6016": {
    name: "tiny dinos winter 2022 #6016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6016,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6016.png",
  },
  "6017": {
    name: "tiny dinos winter 2022 #6017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6017,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6017.png",
  },
  "6018": {
    name: "tiny dinos winter 2022 #6018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6018,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6018.png",
  },
  "6019": {
    name: "tiny dinos winter 2022 #6019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6019,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6019.png",
  },
  "6020": {
    name: "tiny dinos winter 2022 #6020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6020,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6020.png",
  },
  "6021": {
    name: "tiny dinos winter 2022 #6021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6021,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6021.png",
  },
  "6022": {
    name: "tiny dinos winter 2022 #6022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6022,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6022.png",
  },
  "6023": {
    name: "tiny dinos winter 2022 #6023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6023,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6023.png",
  },
  "6024": {
    name: "tiny dinos winter 2022 #6024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6024,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6024.png",
  },
  "6025": {
    name: "tiny dinos winter 2022 #6025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6025,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6025.png",
  },
  "6026": {
    name: "tiny dinos winter 2022 #6026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6026,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6026.png",
  },
  "6027": {
    name: "tiny dinos winter 2022 #6027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6027,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6027.png",
  },
  "6028": {
    name: "tiny dinos winter 2022 #6028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6028,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6028.png",
  },
  "6029": {
    name: "tiny dinos winter 2022 #6029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6029,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6029.png",
  },
  "6030": {
    name: "tiny dinos winter 2022 #6030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6030,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6030.png",
  },
  "6031": {
    name: "tiny dinos winter 2022 #6031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6031,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6031.png",
  },
  "6032": {
    name: "tiny dinos winter 2022 #6032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6032,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6032.png",
  },
  "6033": {
    name: "tiny dinos winter 2022 #6033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6033,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6033.png",
  },
  "6034": {
    name: "tiny dinos winter 2022 #6034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6034,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6034.png",
  },
  "6035": {
    name: "tiny dinos winter 2022 #6035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6035,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6035.png",
  },
  "6036": {
    name: "tiny dinos winter 2022 #6036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6036,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6036.png",
  },
  "6037": {
    name: "tiny dinos winter 2022 #6037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6037,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6037.png",
  },
  "6038": {
    name: "tiny dinos winter 2022 #6038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6038,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6038.png",
  },
  "6039": {
    name: "tiny dinos winter 2022 #6039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6039,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6039.png",
  },
  "6040": {
    name: "tiny dinos winter 2022 #6040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6040,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6040.png",
  },
  "6041": {
    name: "tiny dinos winter 2022 #6041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6041,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6041.png",
  },
  "6042": {
    name: "tiny dinos winter 2022 #6042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6042,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6042.png",
  },
  "6043": {
    name: "tiny dinos winter 2022 #6043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6043,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6043.png",
  },
  "6044": {
    name: "tiny dinos winter 2022 #6044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6044,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6044.png",
  },
  "6045": {
    name: "tiny dinos winter 2022 #6045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6045,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6045.png",
  },
  "6046": {
    name: "tiny dinos winter 2022 #6046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6046,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6046.png",
  },
  "6047": {
    name: "tiny dinos winter 2022 #6047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6047,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6047.png",
  },
  "6048": {
    name: "tiny dinos winter 2022 #6048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6048,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6048.png",
  },
  "6049": {
    name: "tiny dinos winter 2022 #6049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6049,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6049.png",
  },
  "6050": {
    name: "tiny dinos winter 2022 #6050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6050,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6050.png",
  },
  "6051": {
    name: "tiny dinos winter 2022 #6051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6051,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6051.png",
  },
  "6052": {
    name: "tiny dinos winter 2022 #6052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6052,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6052.png",
  },
  "6053": {
    name: "tiny dinos winter 2022 #6053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6053,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6053.png",
  },
  "6054": {
    name: "tiny dinos winter 2022 #6054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6054,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6054.png",
  },
  "6055": {
    name: "tiny dinos winter 2022 #6055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6055,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6055.png",
  },
  "6056": {
    name: "tiny dinos winter 2022 #6056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6056,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6056.png",
  },
  "6057": {
    name: "tiny dinos winter 2022 #6057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6057,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6057.png",
  },
  "6058": {
    name: "tiny dinos winter 2022 #6058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6058,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6058.png",
  },
  "6059": {
    name: "tiny dinos winter 2022 #6059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6059,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6059.png",
  },
  "6060": {
    name: "tiny dinos winter 2022 #6060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6060,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6060.png",
  },
  "6061": {
    name: "tiny dinos winter 2022 #6061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6061,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6061.png",
  },
  "6062": {
    name: "tiny dinos winter 2022 #6062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6062,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6062.png",
  },
  "6063": {
    name: "tiny dinos winter 2022 #6063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6063,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6063.png",
  },
  "6064": {
    name: "tiny dinos winter 2022 #6064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6064,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6064.png",
  },
  "6065": {
    name: "tiny dinos winter 2022 #6065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6065,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6065.png",
  },
  "6066": {
    name: "tiny dinos winter 2022 #6066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6066,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6066.png",
  },
  "6067": {
    name: "tiny dinos winter 2022 #6067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6067,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6067.png",
  },
  "6068": {
    name: "tiny dinos winter 2022 #6068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6068,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6068.png",
  },
  "6069": {
    name: "tiny dinos winter 2022 #6069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6069,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6069.png",
  },
  "6070": {
    name: "tiny dinos winter 2022 #6070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6070,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6070.png",
  },
  "6071": {
    name: "tiny dinos winter 2022 #6071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6071,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6071.png",
  },
  "6072": {
    name: "tiny dinos winter 2022 #6072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6072,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6072.png",
  },
  "6073": {
    name: "tiny dinos winter 2022 #6073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6073,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6073.png",
  },
  "6074": {
    name: "tiny dinos winter 2022 #6074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6074,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6074.png",
  },
  "6075": {
    name: "tiny dinos winter 2022 #6075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6075,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6075.png",
  },
  "6076": {
    name: "tiny dinos winter 2022 #6076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6076,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6076.png",
  },
  "6077": {
    name: "tiny dinos winter 2022 #6077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6077,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6077.png",
  },
  "6078": {
    name: "tiny dinos winter 2022 #6078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6078,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6078.png",
  },
  "6079": {
    name: "tiny dinos winter 2022 #6079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6079,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6079.png",
  },
  "6080": {
    name: "tiny dinos winter 2022 #6080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6080,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6080.png",
  },
  "6081": {
    name: "tiny dinos winter 2022 #6081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6081,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6081.png",
  },
  "6082": {
    name: "tiny dinos winter 2022 #6082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6082,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6082.png",
  },
  "6083": {
    name: "tiny dinos winter 2022 #6083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6083,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6083.png",
  },
  "6084": {
    name: "tiny dinos winter 2022 #6084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6084,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6084.png",
  },
  "6085": {
    name: "tiny dinos winter 2022 #6085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6085,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6085.png",
  },
  "6086": {
    name: "tiny dinos winter 2022 #6086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6086,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6086.png",
  },
  "6087": {
    name: "tiny dinos winter 2022 #6087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6087,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6087.png",
  },
  "6088": {
    name: "tiny dinos winter 2022 #6088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6088,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6088.png",
  },
  "6089": {
    name: "tiny dinos winter 2022 #6089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6089,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6089.png",
  },
  "6090": {
    name: "tiny dinos winter 2022 #6090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6090,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6090.png",
  },
  "6091": {
    name: "tiny dinos winter 2022 #6091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6091,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6091.png",
  },
  "6092": {
    name: "tiny dinos winter 2022 #6092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6092,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6092.png",
  },
  "6093": {
    name: "tiny dinos winter 2022 #6093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6093,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6093.png",
  },
  "6094": {
    name: "tiny dinos winter 2022 #6094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6094,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6094.png",
  },
  "6095": {
    name: "tiny dinos winter 2022 #6095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6095,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6095.png",
  },
  "6096": {
    name: "tiny dinos winter 2022 #6096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6096,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6096.png",
  },
  "6097": {
    name: "tiny dinos winter 2022 #6097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6097,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6097.png",
  },
  "6098": {
    name: "tiny dinos winter 2022 #6098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6098,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6098.png",
  },
  "6099": {
    name: "tiny dinos winter 2022 #6099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6099,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6099.png",
  },
  "6100": {
    name: "tiny dinos winter 2022 #6100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6100,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6100.png",
  },
  "6101": {
    name: "tiny dinos winter 2022 #6101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6101,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6101.png",
  },
  "6102": {
    name: "tiny dinos winter 2022 #6102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6102,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6102.png",
  },
  "6103": {
    name: "tiny dinos winter 2022 #6103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6103,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6103.png",
  },
  "6104": {
    name: "tiny dinos winter 2022 #6104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6104,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6104.png",
  },
  "6105": {
    name: "tiny dinos winter 2022 #6105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6105,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6105.png",
  },
  "6106": {
    name: "tiny dinos winter 2022 #6106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6106,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6106.png",
  },
  "6107": {
    name: "tiny dinos winter 2022 #6107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6107,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6107.png",
  },
  "6108": {
    name: "tiny dinos winter 2022 #6108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6108,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6108.png",
  },
  "6109": {
    name: "tiny dinos winter 2022 #6109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6109,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6109.png",
  },
  "6110": {
    name: "tiny dinos winter 2022 #6110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6110,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6110.png",
  },
  "6111": {
    name: "tiny dinos winter 2022 #6111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6111,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6111.png",
  },
  "6112": {
    name: "tiny dinos winter 2022 #6112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6112,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6112.png",
  },
  "6113": {
    name: "tiny dinos winter 2022 #6113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6113,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6113.png",
  },
  "6114": {
    name: "tiny dinos winter 2022 #6114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6114,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6114.png",
  },
  "6115": {
    name: "tiny dinos winter 2022 #6115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6115,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6115.png",
  },
  "6116": {
    name: "tiny dinos winter 2022 #6116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6116,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6116.png",
  },
  "6117": {
    name: "tiny dinos winter 2022 #6117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6117,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6117.png",
  },
  "6118": {
    name: "tiny dinos winter 2022 #6118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6118,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6118.png",
  },
  "6119": {
    name: "tiny dinos winter 2022 #6119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6119,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6119.png",
  },
  "6120": {
    name: "tiny dinos winter 2022 #6120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6120,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6120.png",
  },
  "6121": {
    name: "tiny dinos winter 2022 #6121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6121,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6121.png",
  },
  "6122": {
    name: "tiny dinos winter 2022 #6122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6122,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6122.png",
  },
  "6123": {
    name: "tiny dinos winter 2022 #6123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6123,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6123.png",
  },
  "6124": {
    name: "tiny dinos winter 2022 #6124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6124,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6124.png",
  },
  "6125": {
    name: "tiny dinos winter 2022 #6125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6125,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6125.png",
  },
  "6126": {
    name: "tiny dinos winter 2022 #6126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6126,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6126.png",
  },
  "6127": {
    name: "tiny dinos winter 2022 #6127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6127,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6127.png",
  },
  "6128": {
    name: "tiny dinos winter 2022 #6128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6128,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6128.png",
  },
  "6129": {
    name: "tiny dinos winter 2022 #6129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6129,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6129.png",
  },
  "6130": {
    name: "tiny dinos winter 2022 #6130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6130,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6130.png",
  },
  "6131": {
    name: "tiny dinos winter 2022 #6131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6131,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6131.png",
  },
  "6132": {
    name: "tiny dinos winter 2022 #6132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6132,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6132.png",
  },
  "6133": {
    name: "tiny dinos winter 2022 #6133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6133,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6133.png",
  },
  "6134": {
    name: "tiny dinos winter 2022 #6134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6134,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6134.png",
  },
  "6135": {
    name: "tiny dinos winter 2022 #6135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6135,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6135.png",
  },
  "6136": {
    name: "tiny dinos winter 2022 #6136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6136,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6136.png",
  },
  "6137": {
    name: "tiny dinos winter 2022 #6137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6137,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6137.png",
  },
  "6138": {
    name: "tiny dinos winter 2022 #6138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6138,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6138.png",
  },
  "6139": {
    name: "tiny dinos winter 2022 #6139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6139,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6139.png",
  },
  "6140": {
    name: "tiny dinos winter 2022 #6140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6140,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6140.png",
  },
  "6141": {
    name: "tiny dinos winter 2022 #6141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6141,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6141.png",
  },
  "6142": {
    name: "tiny dinos winter 2022 #6142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6142,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6142.png",
  },
  "6143": {
    name: "tiny dinos winter 2022 #6143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6143,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6143.png",
  },
  "6144": {
    name: "tiny dinos winter 2022 #6144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6144,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6144.png",
  },
  "6145": {
    name: "tiny dinos winter 2022 #6145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6145,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6145.png",
  },
  "6146": {
    name: "tiny dinos winter 2022 #6146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6146,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6146.png",
  },
  "6147": {
    name: "tiny dinos winter 2022 #6147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6147,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6147.png",
  },
  "6148": {
    name: "tiny dinos winter 2022 #6148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6148,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6148.png",
  },
  "6149": {
    name: "tiny dinos winter 2022 #6149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6149,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6149.png",
  },
  "6150": {
    name: "tiny dinos winter 2022 #6150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6150,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6150.png",
  },
  "6151": {
    name: "tiny dinos winter 2022 #6151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6151,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6151.png",
  },
  "6152": {
    name: "tiny dinos winter 2022 #6152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6152,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6152.png",
  },
  "6153": {
    name: "tiny dinos winter 2022 #6153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6153,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6153.png",
  },
  "6154": {
    name: "tiny dinos winter 2022 #6154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6154,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6154.png",
  },
  "6155": {
    name: "tiny dinos winter 2022 #6155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6155,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6155.png",
  },
  "6156": {
    name: "tiny dinos winter 2022 #6156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6156,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6156.png",
  },
  "6157": {
    name: "tiny dinos winter 2022 #6157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6157,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6157.png",
  },
  "6158": {
    name: "tiny dinos winter 2022 #6158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6158,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6158.png",
  },
  "6159": {
    name: "tiny dinos winter 2022 #6159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6159,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6159.png",
  },
  "6160": {
    name: "tiny dinos winter 2022 #6160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6160,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6160.png",
  },
  "6161": {
    name: "tiny dinos winter 2022 #6161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6161,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6161.png",
  },
  "6162": {
    name: "tiny dinos winter 2022 #6162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6162,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6162.png",
  },
  "6163": {
    name: "tiny dinos winter 2022 #6163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6163,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6163.png",
  },
  "6164": {
    name: "tiny dinos winter 2022 #6164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6164,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6164.png",
  },
  "6165": {
    name: "tiny dinos winter 2022 #6165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6165,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6165.png",
  },
  "6166": {
    name: "tiny dinos winter 2022 #6166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6166,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6166.png",
  },
  "6167": {
    name: "tiny dinos winter 2022 #6167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6167,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6167.png",
  },
  "6168": {
    name: "tiny dinos winter 2022 #6168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6168,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6168.png",
  },
  "6169": {
    name: "tiny dinos winter 2022 #6169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6169,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6169.png",
  },
  "6170": {
    name: "tiny dinos winter 2022 #6170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6170,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6170.png",
  },
  "6171": {
    name: "tiny dinos winter 2022 #6171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6171,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6171.png",
  },
  "6172": {
    name: "tiny dinos winter 2022 #6172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6172,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6172.png",
  },
  "6173": {
    name: "tiny dinos winter 2022 #6173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6173,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6173.png",
  },
  "6174": {
    name: "tiny dinos winter 2022 #6174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6174,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6174.png",
  },
  "6175": {
    name: "tiny dinos winter 2022 #6175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6175,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6175.png",
  },
  "6176": {
    name: "tiny dinos winter 2022 #6176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6176,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6176.png",
  },
  "6177": {
    name: "tiny dinos winter 2022 #6177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6177,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6177.png",
  },
  "6178": {
    name: "tiny dinos winter 2022 #6178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6178,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6178.png",
  },
  "6179": {
    name: "tiny dinos winter 2022 #6179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6179,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6179.png",
  },
  "6180": {
    name: "tiny dinos winter 2022 #6180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6180,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6180.png",
  },
  "6181": {
    name: "tiny dinos winter 2022 #6181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6181,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6181.png",
  },
  "6182": {
    name: "tiny dinos winter 2022 #6182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6182,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6182.png",
  },
  "6183": {
    name: "tiny dinos winter 2022 #6183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6183,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6183.png",
  },
  "6184": {
    name: "tiny dinos winter 2022 #6184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6184,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6184.png",
  },
  "6185": {
    name: "tiny dinos winter 2022 #6185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6185,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6185.png",
  },
  "6186": {
    name: "tiny dinos winter 2022 #6186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6186,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6186.png",
  },
  "6187": {
    name: "tiny dinos winter 2022 #6187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6187,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6187.png",
  },
  "6188": {
    name: "tiny dinos winter 2022 #6188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6188,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6188.png",
  },
  "6189": {
    name: "tiny dinos winter 2022 #6189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6189,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6189.png",
  },
  "6190": {
    name: "tiny dinos winter 2022 #6190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6190,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6190.png",
  },
  "6191": {
    name: "tiny dinos winter 2022 #6191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6191,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6191.png",
  },
  "6192": {
    name: "tiny dinos winter 2022 #6192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6192,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6192.png",
  },
  "6193": {
    name: "tiny dinos winter 2022 #6193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6193,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6193.png",
  },
  "6194": {
    name: "tiny dinos winter 2022 #6194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6194,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6194.png",
  },
  "6195": {
    name: "tiny dinos winter 2022 #6195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6195,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6195.png",
  },
  "6196": {
    name: "tiny dinos winter 2022 #6196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6196,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6196.png",
  },
  "6197": {
    name: "tiny dinos winter 2022 #6197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6197,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6197.png",
  },
  "6198": {
    name: "tiny dinos winter 2022 #6198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6198,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6198.png",
  },
  "6199": {
    name: "tiny dinos winter 2022 #6199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6199,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6199.png",
  },
  "6200": {
    name: "tiny dinos winter 2022 #6200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6200,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6200.png",
  },
  "6201": {
    name: "tiny dinos winter 2022 #6201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6201,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6201.png",
  },
  "6202": {
    name: "tiny dinos winter 2022 #6202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6202,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6202.png",
  },
  "6203": {
    name: "tiny dinos winter 2022 #6203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6203,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6203.png",
  },
  "6204": {
    name: "tiny dinos winter 2022 #6204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6204,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6204.png",
  },
  "6205": {
    name: "tiny dinos winter 2022 #6205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6205,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6205.png",
  },
  "6206": {
    name: "tiny dinos winter 2022 #6206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6206,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6206.png",
  },
  "6207": {
    name: "tiny dinos winter 2022 #6207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6207,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6207.png",
  },
  "6208": {
    name: "tiny dinos winter 2022 #6208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6208,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6208.png",
  },
  "6209": {
    name: "tiny dinos winter 2022 #6209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6209,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6209.png",
  },
  "6210": {
    name: "tiny dinos winter 2022 #6210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6210,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6210.png",
  },
  "6211": {
    name: "tiny dinos winter 2022 #6211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6211,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6211.png",
  },
  "6212": {
    name: "tiny dinos winter 2022 #6212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6212,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6212.png",
  },
  "6213": {
    name: "tiny dinos winter 2022 #6213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6213,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6213.png",
  },
  "6214": {
    name: "tiny dinos winter 2022 #6214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6214,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6214.png",
  },
  "6215": {
    name: "tiny dinos winter 2022 #6215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6215,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6215.png",
  },
  "6216": {
    name: "tiny dinos winter 2022 #6216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6216,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6216.png",
  },
  "6217": {
    name: "tiny dinos winter 2022 #6217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6217,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6217.png",
  },
  "6218": {
    name: "tiny dinos winter 2022 #6218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6218,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6218.png",
  },
  "6219": {
    name: "tiny dinos winter 2022 #6219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6219,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6219.png",
  },
  "6220": {
    name: "tiny dinos winter 2022 #6220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6220,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6220.png",
  },
  "6221": {
    name: "tiny dinos winter 2022 #6221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6221,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6221.png",
  },
  "6222": {
    name: "tiny dinos winter 2022 #6222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6222,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6222.png",
  },
  "6223": {
    name: "tiny dinos winter 2022 #6223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6223,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6223.png",
  },
  "6224": {
    name: "tiny dinos winter 2022 #6224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6224,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6224.png",
  },
  "6225": {
    name: "tiny dinos winter 2022 #6225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6225,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6225.png",
  },
  "6226": {
    name: "tiny dinos winter 2022 #6226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6226,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6226.png",
  },
  "6227": {
    name: "tiny dinos winter 2022 #6227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6227,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6227.png",
  },
  "6228": {
    name: "tiny dinos winter 2022 #6228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6228,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6228.png",
  },
  "6229": {
    name: "tiny dinos winter 2022 #6229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6229,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6229.png",
  },
  "6230": {
    name: "tiny dinos winter 2022 #6230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6230,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6230.png",
  },
  "6231": {
    name: "tiny dinos winter 2022 #6231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6231,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6231.png",
  },
  "6232": {
    name: "tiny dinos winter 2022 #6232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6232,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6232.png",
  },
  "6233": {
    name: "tiny dinos winter 2022 #6233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6233,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6233.png",
  },
  "6234": {
    name: "tiny dinos winter 2022 #6234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6234,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6234.png",
  },
  "6235": {
    name: "tiny dinos winter 2022 #6235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6235,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6235.png",
  },
  "6236": {
    name: "tiny dinos winter 2022 #6236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6236,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6236.png",
  },
  "6237": {
    name: "tiny dinos winter 2022 #6237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6237,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6237.png",
  },
  "6238": {
    name: "tiny dinos winter 2022 #6238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6238,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6238.png",
  },
  "6239": {
    name: "tiny dinos winter 2022 #6239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6239,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6239.png",
  },
  "6240": {
    name: "tiny dinos winter 2022 #6240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6240,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6240.png",
  },
  "6241": {
    name: "tiny dinos winter 2022 #6241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6241,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6241.png",
  },
  "6242": {
    name: "tiny dinos winter 2022 #6242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6242,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6242.png",
  },
  "6243": {
    name: "tiny dinos winter 2022 #6243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6243,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6243.png",
  },
  "6244": {
    name: "tiny dinos winter 2022 #6244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6244,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6244.png",
  },
  "6245": {
    name: "tiny dinos winter 2022 #6245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6245,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6245.png",
  },
  "6246": {
    name: "tiny dinos winter 2022 #6246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6246,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6246.png",
  },
  "6247": {
    name: "tiny dinos winter 2022 #6247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6247,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6247.png",
  },
  "6248": {
    name: "tiny dinos winter 2022 #6248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6248,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6248.png",
  },
  "6249": {
    name: "tiny dinos winter 2022 #6249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6249,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6249.png",
  },
  "6250": {
    name: "tiny dinos winter 2022 #6250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6250,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6250.png",
  },
  "6251": {
    name: "tiny dinos winter 2022 #6251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6251,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6251.png",
  },
  "6252": {
    name: "tiny dinos winter 2022 #6252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6252,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6252.png",
  },
  "6253": {
    name: "tiny dinos winter 2022 #6253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6253,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6253.png",
  },
  "6254": {
    name: "tiny dinos winter 2022 #6254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6254,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6254.png",
  },
  "6255": {
    name: "tiny dinos winter 2022 #6255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6255,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6255.png",
  },
  "6256": {
    name: "tiny dinos winter 2022 #6256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6256,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6256.png",
  },
  "6257": {
    name: "tiny dinos winter 2022 #6257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6257,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6257.png",
  },
  "6258": {
    name: "tiny dinos winter 2022 #6258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6258,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6258.png",
  },
  "6259": {
    name: "tiny dinos winter 2022 #6259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6259,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6259.png",
  },
  "6260": {
    name: "tiny dinos winter 2022 #6260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6260,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6260.png",
  },
  "6261": {
    name: "tiny dinos winter 2022 #6261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6261,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6261.png",
  },
  "6262": {
    name: "tiny dinos winter 2022 #6262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6262,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6262.png",
  },
  "6263": {
    name: "tiny dinos winter 2022 #6263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6263,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6263.png",
  },
  "6264": {
    name: "tiny dinos winter 2022 #6264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6264,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6264.png",
  },
  "6265": {
    name: "tiny dinos winter 2022 #6265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6265,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6265.png",
  },
  "6266": {
    name: "tiny dinos winter 2022 #6266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6266,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6266.png",
  },
  "6267": {
    name: "tiny dinos winter 2022 #6267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6267,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6267.png",
  },
  "6268": {
    name: "tiny dinos winter 2022 #6268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6268,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6268.png",
  },
  "6269": {
    name: "tiny dinos winter 2022 #6269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6269,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6269.png",
  },
  "6270": {
    name: "tiny dinos winter 2022 #6270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6270,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6270.png",
  },
  "6271": {
    name: "tiny dinos winter 2022 #6271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6271,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6271.png",
  },
  "6272": {
    name: "tiny dinos winter 2022 #6272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6272,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6272.png",
  },
  "6273": {
    name: "tiny dinos winter 2022 #6273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6273,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6273.png",
  },
  "6274": {
    name: "tiny dinos winter 2022 #6274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6274,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6274.png",
  },
  "6275": {
    name: "tiny dinos winter 2022 #6275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6275,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6275.png",
  },
  "6276": {
    name: "tiny dinos winter 2022 #6276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6276,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6276.png",
  },
  "6277": {
    name: "tiny dinos winter 2022 #6277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6277,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6277.png",
  },
  "6278": {
    name: "tiny dinos winter 2022 #6278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6278,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6278.png",
  },
  "6279": {
    name: "tiny dinos winter 2022 #6279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6279,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6279.png",
  },
  "6280": {
    name: "tiny dinos winter 2022 #6280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6280,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6280.png",
  },
  "6281": {
    name: "tiny dinos winter 2022 #6281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6281,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6281.png",
  },
  "6282": {
    name: "tiny dinos winter 2022 #6282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6282,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6282.png",
  },
  "6283": {
    name: "tiny dinos winter 2022 #6283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6283,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6283.png",
  },
  "6284": {
    name: "tiny dinos winter 2022 #6284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6284,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6284.png",
  },
  "6285": {
    name: "tiny dinos winter 2022 #6285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6285,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6285.png",
  },
  "6286": {
    name: "tiny dinos winter 2022 #6286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6286,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6286.png",
  },
  "6287": {
    name: "tiny dinos winter 2022 #6287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6287,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6287.png",
  },
  "6288": {
    name: "tiny dinos winter 2022 #6288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6288,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6288.png",
  },
  "6289": {
    name: "tiny dinos winter 2022 #6289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6289,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6289.png",
  },
  "6290": {
    name: "tiny dinos winter 2022 #6290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6290,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6290.png",
  },
  "6291": {
    name: "tiny dinos winter 2022 #6291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6291,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6291.png",
  },
  "6292": {
    name: "tiny dinos winter 2022 #6292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6292,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6292.png",
  },
  "6293": {
    name: "tiny dinos winter 2022 #6293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6293,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6293.png",
  },
  "6294": {
    name: "tiny dinos winter 2022 #6294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6294,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6294.png",
  },
  "6295": {
    name: "tiny dinos winter 2022 #6295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6295,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6295.png",
  },
  "6296": {
    name: "tiny dinos winter 2022 #6296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6296,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6296.png",
  },
  "6297": {
    name: "tiny dinos winter 2022 #6297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6297,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6297.png",
  },
  "6298": {
    name: "tiny dinos winter 2022 #6298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6298,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6298.png",
  },
  "6299": {
    name: "tiny dinos winter 2022 #6299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6299,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6299.png",
  },
  "6300": {
    name: "tiny dinos winter 2022 #6300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6300,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6300.png",
  },
  "6301": {
    name: "tiny dinos winter 2022 #6301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6301,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6301.png",
  },
  "6302": {
    name: "tiny dinos winter 2022 #6302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6302,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6302.png",
  },
  "6303": {
    name: "tiny dinos winter 2022 #6303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6303,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6303.png",
  },
  "6304": {
    name: "tiny dinos winter 2022 #6304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6304,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6304.png",
  },
  "6305": {
    name: "tiny dinos winter 2022 #6305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6305,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6305.png",
  },
  "6306": {
    name: "tiny dinos winter 2022 #6306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6306,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6306.png",
  },
  "6307": {
    name: "tiny dinos winter 2022 #6307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6307,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6307.png",
  },
  "6308": {
    name: "tiny dinos winter 2022 #6308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6308,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6308.png",
  },
  "6309": {
    name: "tiny dinos winter 2022 #6309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6309,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6309.png",
  },
  "6310": {
    name: "tiny dinos winter 2022 #6310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6310,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6310.png",
  },
  "6311": {
    name: "tiny dinos winter 2022 #6311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6311,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6311.png",
  },
  "6312": {
    name: "tiny dinos winter 2022 #6312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6312,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6312.png",
  },
  "6313": {
    name: "tiny dinos winter 2022 #6313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6313,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6313.png",
  },
  "6314": {
    name: "tiny dinos winter 2022 #6314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6314,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6314.png",
  },
  "6315": {
    name: "tiny dinos winter 2022 #6315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6315,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6315.png",
  },
  "6316": {
    name: "tiny dinos winter 2022 #6316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6316,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6316.png",
  },
  "6317": {
    name: "tiny dinos winter 2022 #6317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6317,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6317.png",
  },
  "6318": {
    name: "tiny dinos winter 2022 #6318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6318,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6318.png",
  },
  "6319": {
    name: "tiny dinos winter 2022 #6319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6319,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6319.png",
  },
  "6320": {
    name: "tiny dinos winter 2022 #6320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6320,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6320.png",
  },
  "6321": {
    name: "tiny dinos winter 2022 #6321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6321,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6321.png",
  },
  "6322": {
    name: "tiny dinos winter 2022 #6322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6322,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6322.png",
  },
  "6323": {
    name: "tiny dinos winter 2022 #6323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6323,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6323.png",
  },
  "6324": {
    name: "tiny dinos winter 2022 #6324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6324,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6324.png",
  },
  "6325": {
    name: "tiny dinos winter 2022 #6325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6325,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6325.png",
  },
  "6326": {
    name: "tiny dinos winter 2022 #6326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6326,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6326.png",
  },
  "6327": {
    name: "tiny dinos winter 2022 #6327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6327,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6327.png",
  },
  "6328": {
    name: "tiny dinos winter 2022 #6328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6328,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6328.png",
  },
  "6329": {
    name: "tiny dinos winter 2022 #6329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6329,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6329.png",
  },
  "6330": {
    name: "tiny dinos winter 2022 #6330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6330,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6330.png",
  },
  "6331": {
    name: "tiny dinos winter 2022 #6331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6331,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6331.png",
  },
  "6332": {
    name: "tiny dinos winter 2022 #6332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6332,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6332.png",
  },
  "6333": {
    name: "tiny dinos winter 2022 #6333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6333,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6333.png",
  },
  "6334": {
    name: "tiny dinos winter 2022 #6334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6334,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6334.png",
  },
  "6335": {
    name: "tiny dinos winter 2022 #6335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6335,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6335.png",
  },
  "6336": {
    name: "tiny dinos winter 2022 #6336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6336,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6336.png",
  },
  "6337": {
    name: "tiny dinos winter 2022 #6337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6337,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6337.png",
  },
  "6338": {
    name: "tiny dinos winter 2022 #6338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6338,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6338.png",
  },
  "6339": {
    name: "tiny dinos winter 2022 #6339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6339,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6339.png",
  },
  "6340": {
    name: "tiny dinos winter 2022 #6340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6340,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6340.png",
  },
  "6341": {
    name: "tiny dinos winter 2022 #6341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6341,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6341.png",
  },
  "6342": {
    name: "tiny dinos winter 2022 #6342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6342,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6342.png",
  },
  "6343": {
    name: "tiny dinos winter 2022 #6343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6343,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6343.png",
  },
  "6344": {
    name: "tiny dinos winter 2022 #6344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6344,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6344.png",
  },
  "6345": {
    name: "tiny dinos winter 2022 #6345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6345,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6345.png",
  },
  "6346": {
    name: "tiny dinos winter 2022 #6346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6346,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6346.png",
  },
  "6347": {
    name: "tiny dinos winter 2022 #6347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6347,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6347.png",
  },
  "6348": {
    name: "tiny dinos winter 2022 #6348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6348,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6348.png",
  },
  "6349": {
    name: "tiny dinos winter 2022 #6349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6349,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6349.png",
  },
  "6350": {
    name: "tiny dinos winter 2022 #6350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6350,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6350.png",
  },
  "6351": {
    name: "tiny dinos winter 2022 #6351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6351,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6351.png",
  },
  "6352": {
    name: "tiny dinos winter 2022 #6352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6352,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6352.png",
  },
  "6353": {
    name: "tiny dinos winter 2022 #6353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6353,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6353.png",
  },
  "6354": {
    name: "tiny dinos winter 2022 #6354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6354,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6354.png",
  },
  "6355": {
    name: "tiny dinos winter 2022 #6355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6355,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6355.png",
  },
  "6356": {
    name: "tiny dinos winter 2022 #6356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6356,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6356.png",
  },
  "6357": {
    name: "tiny dinos winter 2022 #6357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6357,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6357.png",
  },
  "6358": {
    name: "tiny dinos winter 2022 #6358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6358,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6358.png",
  },
  "6359": {
    name: "tiny dinos winter 2022 #6359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6359,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6359.png",
  },
  "6360": {
    name: "tiny dinos winter 2022 #6360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6360,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6360.png",
  },
  "6361": {
    name: "tiny dinos winter 2022 #6361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6361,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6361.png",
  },
  "6362": {
    name: "tiny dinos winter 2022 #6362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6362,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6362.png",
  },
  "6363": {
    name: "tiny dinos winter 2022 #6363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6363,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6363.png",
  },
  "6364": {
    name: "tiny dinos winter 2022 #6364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6364,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6364.png",
  },
  "6365": {
    name: "tiny dinos winter 2022 #6365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6365,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6365.png",
  },
  "6366": {
    name: "tiny dinos winter 2022 #6366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6366,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6366.png",
  },
  "6367": {
    name: "tiny dinos winter 2022 #6367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6367,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6367.png",
  },
  "6368": {
    name: "tiny dinos winter 2022 #6368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6368,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6368.png",
  },
  "6369": {
    name: "tiny dinos winter 2022 #6369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6369,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6369.png",
  },
  "6370": {
    name: "tiny dinos winter 2022 #6370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6370,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6370.png",
  },
  "6371": {
    name: "tiny dinos winter 2022 #6371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6371,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6371.png",
  },
  "6372": {
    name: "tiny dinos winter 2022 #6372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6372,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6372.png",
  },
  "6373": {
    name: "tiny dinos winter 2022 #6373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6373,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6373.png",
  },
  "6374": {
    name: "tiny dinos winter 2022 #6374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6374,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6374.png",
  },
  "6375": {
    name: "tiny dinos winter 2022 #6375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6375,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6375.png",
  },
  "6376": {
    name: "tiny dinos winter 2022 #6376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6376,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6376.png",
  },
  "6377": {
    name: "tiny dinos winter 2022 #6377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6377,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6377.png",
  },
  "6378": {
    name: "tiny dinos winter 2022 #6378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6378,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6378.png",
  },
  "6379": {
    name: "tiny dinos winter 2022 #6379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6379,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6379.png",
  },
  "6380": {
    name: "tiny dinos winter 2022 #6380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6380,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6380.png",
  },
  "6381": {
    name: "tiny dinos winter 2022 #6381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6381,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6381.png",
  },
  "6382": {
    name: "tiny dinos winter 2022 #6382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6382,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6382.png",
  },
  "6383": {
    name: "tiny dinos winter 2022 #6383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6383,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6383.png",
  },
  "6384": {
    name: "tiny dinos winter 2022 #6384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6384,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6384.png",
  },
  "6385": {
    name: "tiny dinos winter 2022 #6385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6385,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6385.png",
  },
  "6386": {
    name: "tiny dinos winter 2022 #6386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6386,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6386.png",
  },
  "6387": {
    name: "tiny dinos winter 2022 #6387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6387,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6387.png",
  },
  "6388": {
    name: "tiny dinos winter 2022 #6388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6388,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6388.png",
  },
  "6389": {
    name: "tiny dinos winter 2022 #6389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6389,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6389.png",
  },
  "6390": {
    name: "tiny dinos winter 2022 #6390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6390,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6390.png",
  },
  "6391": {
    name: "tiny dinos winter 2022 #6391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6391,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6391.png",
  },
  "6392": {
    name: "tiny dinos winter 2022 #6392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6392,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6392.png",
  },
  "6393": {
    name: "tiny dinos winter 2022 #6393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6393,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6393.png",
  },
  "6394": {
    name: "tiny dinos winter 2022 #6394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6394,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6394.png",
  },
  "6395": {
    name: "tiny dinos winter 2022 #6395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6395,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6395.png",
  },
  "6396": {
    name: "tiny dinos winter 2022 #6396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6396,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6396.png",
  },
  "6397": {
    name: "tiny dinos winter 2022 #6397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6397,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6397.png",
  },
  "6398": {
    name: "tiny dinos winter 2022 #6398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6398,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6398.png",
  },
  "6399": {
    name: "tiny dinos winter 2022 #6399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6399,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6399.png",
  },
  "6400": {
    name: "tiny dinos winter 2022 #6400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6400,
    attributes: [
      {
        trait_type: "1/1",
        value: "bsc",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6400.png",
  },
  "6401": {
    name: "tiny dinos winter 2022 #6401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6401,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6401.png",
  },
  "6402": {
    name: "tiny dinos winter 2022 #6402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6402,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6402.png",
  },
  "6403": {
    name: "tiny dinos winter 2022 #6403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6403,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6403.png",
  },
  "6404": {
    name: "tiny dinos winter 2022 #6404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6404,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6404.png",
  },
  "6405": {
    name: "tiny dinos winter 2022 #6405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6405,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6405.png",
  },
  "6406": {
    name: "tiny dinos winter 2022 #6406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6406,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6406.png",
  },
  "6407": {
    name: "tiny dinos winter 2022 #6407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6407,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6407.png",
  },
  "6408": {
    name: "tiny dinos winter 2022 #6408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6408,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6408.png",
  },
  "6409": {
    name: "tiny dinos winter 2022 #6409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6409,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6409.png",
  },
  "6410": {
    name: "tiny dinos winter 2022 #6410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6410,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6410.png",
  },
  "6411": {
    name: "tiny dinos winter 2022 #6411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6411,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6411.png",
  },
  "6412": {
    name: "tiny dinos winter 2022 #6412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6412,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6412.png",
  },
  "6413": {
    name: "tiny dinos winter 2022 #6413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6413,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6413.png",
  },
  "6414": {
    name: "tiny dinos winter 2022 #6414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6414,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6414.png",
  },
  "6415": {
    name: "tiny dinos winter 2022 #6415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6415,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6415.png",
  },
  "6416": {
    name: "tiny dinos winter 2022 #6416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6416,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6416.png",
  },
  "6417": {
    name: "tiny dinos winter 2022 #6417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6417,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6417.png",
  },
  "6418": {
    name: "tiny dinos winter 2022 #6418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6418,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6418.png",
  },
  "6419": {
    name: "tiny dinos winter 2022 #6419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6419,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6419.png",
  },
  "6420": {
    name: "tiny dinos winter 2022 #6420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6420,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6420.png",
  },
  "6421": {
    name: "tiny dinos winter 2022 #6421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6421,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6421.png",
  },
  "6422": {
    name: "tiny dinos winter 2022 #6422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6422,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6422.png",
  },
  "6423": {
    name: "tiny dinos winter 2022 #6423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6423,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6423.png",
  },
  "6424": {
    name: "tiny dinos winter 2022 #6424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6424,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6424.png",
  },
  "6425": {
    name: "tiny dinos winter 2022 #6425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6425,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6425.png",
  },
  "6426": {
    name: "tiny dinos winter 2022 #6426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6426,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6426.png",
  },
  "6427": {
    name: "tiny dinos winter 2022 #6427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6427,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6427.png",
  },
  "6428": {
    name: "tiny dinos winter 2022 #6428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6428,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6428.png",
  },
  "6429": {
    name: "tiny dinos winter 2022 #6429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6429,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6429.png",
  },
  "6430": {
    name: "tiny dinos winter 2022 #6430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6430,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6430.png",
  },
  "6431": {
    name: "tiny dinos winter 2022 #6431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6431,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6431.png",
  },
  "6432": {
    name: "tiny dinos winter 2022 #6432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6432,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6432.png",
  },
  "6433": {
    name: "tiny dinos winter 2022 #6433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6433,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6433.png",
  },
  "6434": {
    name: "tiny dinos winter 2022 #6434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6434,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6434.png",
  },
  "6435": {
    name: "tiny dinos winter 2022 #6435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6435,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6435.png",
  },
  "6436": {
    name: "tiny dinos winter 2022 #6436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6436,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6436.png",
  },
  "6437": {
    name: "tiny dinos winter 2022 #6437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6437,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6437.png",
  },
  "6438": {
    name: "tiny dinos winter 2022 #6438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6438,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6438.png",
  },
  "6439": {
    name: "tiny dinos winter 2022 #6439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6439,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6439.png",
  },
  "6440": {
    name: "tiny dinos winter 2022 #6440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6440,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6440.png",
  },
  "6441": {
    name: "tiny dinos winter 2022 #6441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6441,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6441.png",
  },
  "6442": {
    name: "tiny dinos winter 2022 #6442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6442,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6442.png",
  },
  "6443": {
    name: "tiny dinos winter 2022 #6443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6443,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6443.png",
  },
  "6444": {
    name: "tiny dinos winter 2022 #6444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6444,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6444.png",
  },
  "6445": {
    name: "tiny dinos winter 2022 #6445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6445,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6445.png",
  },
  "6446": {
    name: "tiny dinos winter 2022 #6446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6446,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6446.png",
  },
  "6447": {
    name: "tiny dinos winter 2022 #6447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6447,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6447.png",
  },
  "6448": {
    name: "tiny dinos winter 2022 #6448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6448,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6448.png",
  },
  "6449": {
    name: "tiny dinos winter 2022 #6449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6449,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6449.png",
  },
  "6450": {
    name: "tiny dinos winter 2022 #6450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6450,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6450.png",
  },
  "6451": {
    name: "tiny dinos winter 2022 #6451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6451,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6451.png",
  },
  "6452": {
    name: "tiny dinos winter 2022 #6452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6452,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6452.png",
  },
  "6453": {
    name: "tiny dinos winter 2022 #6453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6453,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6453.png",
  },
  "6454": {
    name: "tiny dinos winter 2022 #6454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6454,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6454.png",
  },
  "6455": {
    name: "tiny dinos winter 2022 #6455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6455,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6455.png",
  },
  "6456": {
    name: "tiny dinos winter 2022 #6456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6456,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6456.png",
  },
  "6457": {
    name: "tiny dinos winter 2022 #6457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6457,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6457.png",
  },
  "6458": {
    name: "tiny dinos winter 2022 #6458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6458,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6458.png",
  },
  "6459": {
    name: "tiny dinos winter 2022 #6459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6459,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6459.png",
  },
  "6460": {
    name: "tiny dinos winter 2022 #6460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6460,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6460.png",
  },
  "6461": {
    name: "tiny dinos winter 2022 #6461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6461,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6461.png",
  },
  "6462": {
    name: "tiny dinos winter 2022 #6462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6462,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6462.png",
  },
  "6463": {
    name: "tiny dinos winter 2022 #6463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6463,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6463.png",
  },
  "6464": {
    name: "tiny dinos winter 2022 #6464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6464,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6464.png",
  },
  "6465": {
    name: "tiny dinos winter 2022 #6465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6465,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6465.png",
  },
  "6466": {
    name: "tiny dinos winter 2022 #6466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6466,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6466.png",
  },
  "6467": {
    name: "tiny dinos winter 2022 #6467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6467,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6467.png",
  },
  "6468": {
    name: "tiny dinos winter 2022 #6468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6468,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6468.png",
  },
  "6469": {
    name: "tiny dinos winter 2022 #6469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6469,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6469.png",
  },
  "6470": {
    name: "tiny dinos winter 2022 #6470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6470,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6470.png",
  },
  "6471": {
    name: "tiny dinos winter 2022 #6471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6471,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6471.png",
  },
  "6472": {
    name: "tiny dinos winter 2022 #6472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6472,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6472.png",
  },
  "6473": {
    name: "tiny dinos winter 2022 #6473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6473,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6473.png",
  },
  "6474": {
    name: "tiny dinos winter 2022 #6474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6474,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6474.png",
  },
  "6475": {
    name: "tiny dinos winter 2022 #6475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6475,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6475.png",
  },
  "6476": {
    name: "tiny dinos winter 2022 #6476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6476,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6476.png",
  },
  "6477": {
    name: "tiny dinos winter 2022 #6477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6477,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6477.png",
  },
  "6478": {
    name: "tiny dinos winter 2022 #6478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6478,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6478.png",
  },
  "6479": {
    name: "tiny dinos winter 2022 #6479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6479,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6479.png",
  },
  "6480": {
    name: "tiny dinos winter 2022 #6480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6480,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6480.png",
  },
  "6481": {
    name: "tiny dinos winter 2022 #6481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6481,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6481.png",
  },
  "6482": {
    name: "tiny dinos winter 2022 #6482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6482,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6482.png",
  },
  "6483": {
    name: "tiny dinos winter 2022 #6483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6483,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6483.png",
  },
  "6484": {
    name: "tiny dinos winter 2022 #6484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6484,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6484.png",
  },
  "6485": {
    name: "tiny dinos winter 2022 #6485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6485,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6485.png",
  },
  "6486": {
    name: "tiny dinos winter 2022 #6486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6486,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6486.png",
  },
  "6487": {
    name: "tiny dinos winter 2022 #6487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6487,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6487.png",
  },
  "6488": {
    name: "tiny dinos winter 2022 #6488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6488,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6488.png",
  },
  "6489": {
    name: "tiny dinos winter 2022 #6489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6489,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6489.png",
  },
  "6490": {
    name: "tiny dinos winter 2022 #6490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6490,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6490.png",
  },
  "6491": {
    name: "tiny dinos winter 2022 #6491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6491,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6491.png",
  },
  "6492": {
    name: "tiny dinos winter 2022 #6492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6492,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6492.png",
  },
  "6493": {
    name: "tiny dinos winter 2022 #6493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6493,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6493.png",
  },
  "6494": {
    name: "tiny dinos winter 2022 #6494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6494,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6494.png",
  },
  "6495": {
    name: "tiny dinos winter 2022 #6495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6495,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6495.png",
  },
  "6496": {
    name: "tiny dinos winter 2022 #6496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6496,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6496.png",
  },
  "6497": {
    name: "tiny dinos winter 2022 #6497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6497,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6497.png",
  },
  "6498": {
    name: "tiny dinos winter 2022 #6498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6498,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6498.png",
  },
  "6499": {
    name: "tiny dinos winter 2022 #6499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6499,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6499.png",
  },
  "6500": {
    name: "tiny dinos winter 2022 #6500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6500,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6500.png",
  },
  "6501": {
    name: "tiny dinos winter 2022 #6501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6501,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6501.png",
  },
  "6502": {
    name: "tiny dinos winter 2022 #6502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6502,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6502.png",
  },
  "6503": {
    name: "tiny dinos winter 2022 #6503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6503,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6503.png",
  },
  "6504": {
    name: "tiny dinos winter 2022 #6504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6504,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6504.png",
  },
  "6505": {
    name: "tiny dinos winter 2022 #6505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6505,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6505.png",
  },
  "6506": {
    name: "tiny dinos winter 2022 #6506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6506,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6506.png",
  },
  "6507": {
    name: "tiny dinos winter 2022 #6507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6507,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6507.png",
  },
  "6508": {
    name: "tiny dinos winter 2022 #6508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6508,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6508.png",
  },
  "6509": {
    name: "tiny dinos winter 2022 #6509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6509,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6509.png",
  },
  "6510": {
    name: "tiny dinos winter 2022 #6510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6510,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6510.png",
  },
  "6511": {
    name: "tiny dinos winter 2022 #6511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6511,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6511.png",
  },
  "6512": {
    name: "tiny dinos winter 2022 #6512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6512,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6512.png",
  },
  "6513": {
    name: "tiny dinos winter 2022 #6513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6513,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6513.png",
  },
  "6514": {
    name: "tiny dinos winter 2022 #6514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6514,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6514.png",
  },
  "6515": {
    name: "tiny dinos winter 2022 #6515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6515,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6515.png",
  },
  "6516": {
    name: "tiny dinos winter 2022 #6516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6516,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6516.png",
  },
  "6517": {
    name: "tiny dinos winter 2022 #6517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6517,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6517.png",
  },
  "6518": {
    name: "tiny dinos winter 2022 #6518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6518,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6518.png",
  },
  "6519": {
    name: "tiny dinos winter 2022 #6519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6519,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6519.png",
  },
  "6520": {
    name: "tiny dinos winter 2022 #6520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6520,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6520.png",
  },
  "6521": {
    name: "tiny dinos winter 2022 #6521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6521,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6521.png",
  },
  "6522": {
    name: "tiny dinos winter 2022 #6522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6522,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6522.png",
  },
  "6523": {
    name: "tiny dinos winter 2022 #6523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6523,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6523.png",
  },
  "6524": {
    name: "tiny dinos winter 2022 #6524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6524,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6524.png",
  },
  "6525": {
    name: "tiny dinos winter 2022 #6525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6525,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6525.png",
  },
  "6526": {
    name: "tiny dinos winter 2022 #6526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6526,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6526.png",
  },
  "6527": {
    name: "tiny dinos winter 2022 #6527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6527,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6527.png",
  },
  "6528": {
    name: "tiny dinos winter 2022 #6528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6528,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6528.png",
  },
  "6529": {
    name: "tiny dinos winter 2022 #6529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6529,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6529.png",
  },
  "6530": {
    name: "tiny dinos winter 2022 #6530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6530,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6530.png",
  },
  "6531": {
    name: "tiny dinos winter 2022 #6531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6531,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6531.png",
  },
  "6532": {
    name: "tiny dinos winter 2022 #6532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6532,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6532.png",
  },
  "6533": {
    name: "tiny dinos winter 2022 #6533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6533,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6533.png",
  },
  "6534": {
    name: "tiny dinos winter 2022 #6534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6534,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6534.png",
  },
  "6535": {
    name: "tiny dinos winter 2022 #6535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6535,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6535.png",
  },
  "6536": {
    name: "tiny dinos winter 2022 #6536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6536,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6536.png",
  },
  "6537": {
    name: "tiny dinos winter 2022 #6537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6537,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6537.png",
  },
  "6538": {
    name: "tiny dinos winter 2022 #6538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6538,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6538.png",
  },
  "6539": {
    name: "tiny dinos winter 2022 #6539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6539,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6539.png",
  },
  "6540": {
    name: "tiny dinos winter 2022 #6540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6540,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6540.png",
  },
  "6541": {
    name: "tiny dinos winter 2022 #6541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6541,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6541.png",
  },
  "6542": {
    name: "tiny dinos winter 2022 #6542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6542,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6542.png",
  },
  "6543": {
    name: "tiny dinos winter 2022 #6543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6543,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6543.png",
  },
  "6544": {
    name: "tiny dinos winter 2022 #6544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6544,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6544.png",
  },
  "6545": {
    name: "tiny dinos winter 2022 #6545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6545,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6545.png",
  },
  "6546": {
    name: "tiny dinos winter 2022 #6546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6546,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6546.png",
  },
  "6547": {
    name: "tiny dinos winter 2022 #6547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6547,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6547.png",
  },
  "6548": {
    name: "tiny dinos winter 2022 #6548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6548,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6548.png",
  },
  "6549": {
    name: "tiny dinos winter 2022 #6549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6549,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6549.png",
  },
  "6550": {
    name: "tiny dinos winter 2022 #6550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6550,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6550.png",
  },
  "6551": {
    name: "tiny dinos winter 2022 #6551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6551,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6551.png",
  },
  "6552": {
    name: "tiny dinos winter 2022 #6552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6552,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6552.png",
  },
  "6553": {
    name: "tiny dinos winter 2022 #6553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6553,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6553.png",
  },
  "6554": {
    name: "tiny dinos winter 2022 #6554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6554,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6554.png",
  },
  "6555": {
    name: "tiny dinos winter 2022 #6555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6555,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6555.png",
  },
  "6556": {
    name: "tiny dinos winter 2022 #6556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6556,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6556.png",
  },
  "6557": {
    name: "tiny dinos winter 2022 #6557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6557,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6557.png",
  },
  "6558": {
    name: "tiny dinos winter 2022 #6558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6558,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6558.png",
  },
  "6559": {
    name: "tiny dinos winter 2022 #6559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6559,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6559.png",
  },
  "6560": {
    name: "tiny dinos winter 2022 #6560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6560,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6560.png",
  },
  "6561": {
    name: "tiny dinos winter 2022 #6561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6561,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6561.png",
  },
  "6562": {
    name: "tiny dinos winter 2022 #6562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6562,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6562.png",
  },
  "6563": {
    name: "tiny dinos winter 2022 #6563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6563,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6563.png",
  },
  "6564": {
    name: "tiny dinos winter 2022 #6564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6564,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6564.png",
  },
  "6565": {
    name: "tiny dinos winter 2022 #6565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6565,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6565.png",
  },
  "6566": {
    name: "tiny dinos winter 2022 #6566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6566,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6566.png",
  },
  "6567": {
    name: "tiny dinos winter 2022 #6567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6567,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6567.png",
  },
  "6568": {
    name: "tiny dinos winter 2022 #6568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6568,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6568.png",
  },
  "6569": {
    name: "tiny dinos winter 2022 #6569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6569,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6569.png",
  },
  "6570": {
    name: "tiny dinos winter 2022 #6570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6570,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6570.png",
  },
  "6571": {
    name: "tiny dinos winter 2022 #6571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6571,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6571.png",
  },
  "6572": {
    name: "tiny dinos winter 2022 #6572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6572,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6572.png",
  },
  "6573": {
    name: "tiny dinos winter 2022 #6573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6573,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6573.png",
  },
  "6574": {
    name: "tiny dinos winter 2022 #6574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6574,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6574.png",
  },
  "6575": {
    name: "tiny dinos winter 2022 #6575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6575,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6575.png",
  },
  "6576": {
    name: "tiny dinos winter 2022 #6576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6576,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6576.png",
  },
  "6577": {
    name: "tiny dinos winter 2022 #6577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6577,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6577.png",
  },
  "6578": {
    name: "tiny dinos winter 2022 #6578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6578,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6578.png",
  },
  "6579": {
    name: "tiny dinos winter 2022 #6579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6579,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6579.png",
  },
  "6580": {
    name: "tiny dinos winter 2022 #6580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6580,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6580.png",
  },
  "6581": {
    name: "tiny dinos winter 2022 #6581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6581,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6581.png",
  },
  "6582": {
    name: "tiny dinos winter 2022 #6582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6582,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6582.png",
  },
  "6583": {
    name: "tiny dinos winter 2022 #6583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6583,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6583.png",
  },
  "6584": {
    name: "tiny dinos winter 2022 #6584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6584,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6584.png",
  },
  "6585": {
    name: "tiny dinos winter 2022 #6585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6585,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6585.png",
  },
  "6586": {
    name: "tiny dinos winter 2022 #6586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6586,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6586.png",
  },
  "6587": {
    name: "tiny dinos winter 2022 #6587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6587,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6587.png",
  },
  "6588": {
    name: "tiny dinos winter 2022 #6588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6588,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6588.png",
  },
  "6589": {
    name: "tiny dinos winter 2022 #6589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6589,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6589.png",
  },
  "6590": {
    name: "tiny dinos winter 2022 #6590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6590,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6590.png",
  },
  "6591": {
    name: "tiny dinos winter 2022 #6591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6591,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6591.png",
  },
  "6592": {
    name: "tiny dinos winter 2022 #6592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6592,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6592.png",
  },
  "6593": {
    name: "tiny dinos winter 2022 #6593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6593,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6593.png",
  },
  "6594": {
    name: "tiny dinos winter 2022 #6594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6594,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6594.png",
  },
  "6595": {
    name: "tiny dinos winter 2022 #6595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6595,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6595.png",
  },
  "6596": {
    name: "tiny dinos winter 2022 #6596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6596,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6596.png",
  },
  "6597": {
    name: "tiny dinos winter 2022 #6597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6597,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6597.png",
  },
  "6598": {
    name: "tiny dinos winter 2022 #6598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6598,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6598.png",
  },
  "6599": {
    name: "tiny dinos winter 2022 #6599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6599,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6599.png",
  },
  "6600": {
    name: "tiny dinos winter 2022 #6600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6600,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6600.png",
  },
  "6601": {
    name: "tiny dinos winter 2022 #6601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6601,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6601.png",
  },
  "6602": {
    name: "tiny dinos winter 2022 #6602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6602,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6602.png",
  },
  "6603": {
    name: "tiny dinos winter 2022 #6603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6603,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6603.png",
  },
  "6604": {
    name: "tiny dinos winter 2022 #6604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6604,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6604.png",
  },
  "6605": {
    name: "tiny dinos winter 2022 #6605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6605,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6605.png",
  },
  "6606": {
    name: "tiny dinos winter 2022 #6606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6606,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6606.png",
  },
  "6607": {
    name: "tiny dinos winter 2022 #6607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6607,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6607.png",
  },
  "6608": {
    name: "tiny dinos winter 2022 #6608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6608,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6608.png",
  },
  "6609": {
    name: "tiny dinos winter 2022 #6609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6609,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6609.png",
  },
  "6610": {
    name: "tiny dinos winter 2022 #6610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6610,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6610.png",
  },
  "6611": {
    name: "tiny dinos winter 2022 #6611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6611,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6611.png",
  },
  "6612": {
    name: "tiny dinos winter 2022 #6612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6612,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6612.png",
  },
  "6613": {
    name: "tiny dinos winter 2022 #6613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6613,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6613.png",
  },
  "6614": {
    name: "tiny dinos winter 2022 #6614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6614,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6614.png",
  },
  "6615": {
    name: "tiny dinos winter 2022 #6615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6615,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6615.png",
  },
  "6616": {
    name: "tiny dinos winter 2022 #6616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6616,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6616.png",
  },
  "6617": {
    name: "tiny dinos winter 2022 #6617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6617,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6617.png",
  },
  "6618": {
    name: "tiny dinos winter 2022 #6618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6618,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6618.png",
  },
  "6619": {
    name: "tiny dinos winter 2022 #6619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6619,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6619.png",
  },
  "6620": {
    name: "tiny dinos winter 2022 #6620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6620,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6620.png",
  },
  "6621": {
    name: "tiny dinos winter 2022 #6621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6621,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6621.png",
  },
  "6622": {
    name: "tiny dinos winter 2022 #6622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6622,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6622.png",
  },
  "6623": {
    name: "tiny dinos winter 2022 #6623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6623,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6623.png",
  },
  "6624": {
    name: "tiny dinos winter 2022 #6624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6624,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6624.png",
  },
  "6625": {
    name: "tiny dinos winter 2022 #6625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6625,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6625.png",
  },
  "6626": {
    name: "tiny dinos winter 2022 #6626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6626,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6626.png",
  },
  "6627": {
    name: "tiny dinos winter 2022 #6627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6627,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6627.png",
  },
  "6628": {
    name: "tiny dinos winter 2022 #6628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6628,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6628.png",
  },
  "6629": {
    name: "tiny dinos winter 2022 #6629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6629,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6629.png",
  },
  "6630": {
    name: "tiny dinos winter 2022 #6630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6630,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6630.png",
  },
  "6631": {
    name: "tiny dinos winter 2022 #6631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6631,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6631.png",
  },
  "6632": {
    name: "tiny dinos winter 2022 #6632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6632,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6632.png",
  },
  "6633": {
    name: "tiny dinos winter 2022 #6633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6633,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6633.png",
  },
  "6634": {
    name: "tiny dinos winter 2022 #6634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6634,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6634.png",
  },
  "6635": {
    name: "tiny dinos winter 2022 #6635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6635,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6635.png",
  },
  "6636": {
    name: "tiny dinos winter 2022 #6636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6636,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6636.png",
  },
  "6637": {
    name: "tiny dinos winter 2022 #6637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6637,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6637.png",
  },
  "6638": {
    name: "tiny dinos winter 2022 #6638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6638,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6638.png",
  },
  "6639": {
    name: "tiny dinos winter 2022 #6639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6639,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6639.png",
  },
  "6640": {
    name: "tiny dinos winter 2022 #6640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6640,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6640.png",
  },
  "6641": {
    name: "tiny dinos winter 2022 #6641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6641,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6641.png",
  },
  "6642": {
    name: "tiny dinos winter 2022 #6642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6642,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6642.png",
  },
  "6643": {
    name: "tiny dinos winter 2022 #6643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6643,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6643.png",
  },
  "6644": {
    name: "tiny dinos winter 2022 #6644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6644,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6644.png",
  },
  "6645": {
    name: "tiny dinos winter 2022 #6645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6645,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6645.png",
  },
  "6646": {
    name: "tiny dinos winter 2022 #6646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6646,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6646.png",
  },
  "6647": {
    name: "tiny dinos winter 2022 #6647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6647,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6647.png",
  },
  "6648": {
    name: "tiny dinos winter 2022 #6648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6648,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6648.png",
  },
  "6649": {
    name: "tiny dinos winter 2022 #6649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6649,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6649.png",
  },
  "6650": {
    name: "tiny dinos winter 2022 #6650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6650,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6650.png",
  },
  "6651": {
    name: "tiny dinos winter 2022 #6651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6651,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6651.png",
  },
  "6652": {
    name: "tiny dinos winter 2022 #6652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6652,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6652.png",
  },
  "6653": {
    name: "tiny dinos winter 2022 #6653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6653,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6653.png",
  },
  "6654": {
    name: "tiny dinos winter 2022 #6654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6654,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6654.png",
  },
  "6655": {
    name: "tiny dinos winter 2022 #6655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6655,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6655.png",
  },
  "6656": {
    name: "tiny dinos winter 2022 #6656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6656,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6656.png",
  },
  "6657": {
    name: "tiny dinos winter 2022 #6657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6657,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6657.png",
  },
  "6658": {
    name: "tiny dinos winter 2022 #6658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6658,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6658.png",
  },
  "6659": {
    name: "tiny dinos winter 2022 #6659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6659,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6659.png",
  },
  "6660": {
    name: "tiny dinos winter 2022 #6660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6660,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6660.png",
  },
  "6661": {
    name: "tiny dinos winter 2022 #6661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6661,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6661.png",
  },
  "6662": {
    name: "tiny dinos winter 2022 #6662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6662,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6662.png",
  },
  "6663": {
    name: "tiny dinos winter 2022 #6663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6663,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6663.png",
  },
  "6664": {
    name: "tiny dinos winter 2022 #6664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6664,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6664.png",
  },
  "6665": {
    name: "tiny dinos winter 2022 #6665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6665,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6665.png",
  },
  "6666": {
    name: "tiny dinos winter 2022 #6666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6666,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6666.png",
  },
  "6667": {
    name: "tiny dinos winter 2022 #6667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6667,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6667.png",
  },
  "6668": {
    name: "tiny dinos winter 2022 #6668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6668,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6668.png",
  },
  "6669": {
    name: "tiny dinos winter 2022 #6669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6669,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6669.png",
  },
  "6670": {
    name: "tiny dinos winter 2022 #6670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6670,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6670.png",
  },
  "6671": {
    name: "tiny dinos winter 2022 #6671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6671,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6671.png",
  },
  "6672": {
    name: "tiny dinos winter 2022 #6672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6672,
    attributes: [
      {
        trait_type: "1/1",
        value: "avax",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6672.png",
  },
  "6673": {
    name: "tiny dinos winter 2022 #6673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6673,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6673.png",
  },
  "6674": {
    name: "tiny dinos winter 2022 #6674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6674,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6674.png",
  },
  "6675": {
    name: "tiny dinos winter 2022 #6675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6675,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6675.png",
  },
  "6676": {
    name: "tiny dinos winter 2022 #6676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6676,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6676.png",
  },
  "6677": {
    name: "tiny dinos winter 2022 #6677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6677,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6677.png",
  },
  "6678": {
    name: "tiny dinos winter 2022 #6678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6678,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6678.png",
  },
  "6679": {
    name: "tiny dinos winter 2022 #6679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6679,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6679.png",
  },
  "6680": {
    name: "tiny dinos winter 2022 #6680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6680,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6680.png",
  },
  "6681": {
    name: "tiny dinos winter 2022 #6681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6681,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6681.png",
  },
  "6682": {
    name: "tiny dinos winter 2022 #6682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6682,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6682.png",
  },
  "6683": {
    name: "tiny dinos winter 2022 #6683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6683,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6683.png",
  },
  "6684": {
    name: "tiny dinos winter 2022 #6684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6684,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6684.png",
  },
  "6685": {
    name: "tiny dinos winter 2022 #6685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6685,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6685.png",
  },
  "6686": {
    name: "tiny dinos winter 2022 #6686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6686,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6686.png",
  },
  "6687": {
    name: "tiny dinos winter 2022 #6687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6687,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6687.png",
  },
  "6688": {
    name: "tiny dinos winter 2022 #6688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6688,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6688.png",
  },
  "6689": {
    name: "tiny dinos winter 2022 #6689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6689,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6689.png",
  },
  "6690": {
    name: "tiny dinos winter 2022 #6690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6690,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6690.png",
  },
  "6691": {
    name: "tiny dinos winter 2022 #6691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6691,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6691.png",
  },
  "6692": {
    name: "tiny dinos winter 2022 #6692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6692,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6692.png",
  },
  "6693": {
    name: "tiny dinos winter 2022 #6693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6693,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6693.png",
  },
  "6694": {
    name: "tiny dinos winter 2022 #6694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6694,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6694.png",
  },
  "6695": {
    name: "tiny dinos winter 2022 #6695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6695,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6695.png",
  },
  "6696": {
    name: "tiny dinos winter 2022 #6696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6696,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6696.png",
  },
  "6697": {
    name: "tiny dinos winter 2022 #6697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6697,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6697.png",
  },
  "6698": {
    name: "tiny dinos winter 2022 #6698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6698,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6698.png",
  },
  "6699": {
    name: "tiny dinos winter 2022 #6699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6699,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6699.png",
  },
  "6700": {
    name: "tiny dinos winter 2022 #6700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6700,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6700.png",
  },
  "6701": {
    name: "tiny dinos winter 2022 #6701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6701,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6701.png",
  },
  "6702": {
    name: "tiny dinos winter 2022 #6702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6702,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6702.png",
  },
  "6703": {
    name: "tiny dinos winter 2022 #6703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6703,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6703.png",
  },
  "6704": {
    name: "tiny dinos winter 2022 #6704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6704,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6704.png",
  },
  "6705": {
    name: "tiny dinos winter 2022 #6705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6705,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6705.png",
  },
  "6706": {
    name: "tiny dinos winter 2022 #6706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6706,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6706.png",
  },
  "6707": {
    name: "tiny dinos winter 2022 #6707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6707,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6707.png",
  },
  "6708": {
    name: "tiny dinos winter 2022 #6708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6708,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6708.png",
  },
  "6709": {
    name: "tiny dinos winter 2022 #6709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6709,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6709.png",
  },
  "6710": {
    name: "tiny dinos winter 2022 #6710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6710,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6710.png",
  },
  "6711": {
    name: "tiny dinos winter 2022 #6711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6711,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6711.png",
  },
  "6712": {
    name: "tiny dinos winter 2022 #6712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6712,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6712.png",
  },
  "6713": {
    name: "tiny dinos winter 2022 #6713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6713,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6713.png",
  },
  "6714": {
    name: "tiny dinos winter 2022 #6714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6714,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6714.png",
  },
  "6715": {
    name: "tiny dinos winter 2022 #6715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6715,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6715.png",
  },
  "6716": {
    name: "tiny dinos winter 2022 #6716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6716,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6716.png",
  },
  "6717": {
    name: "tiny dinos winter 2022 #6717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6717,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6717.png",
  },
  "6718": {
    name: "tiny dinos winter 2022 #6718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6718,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6718.png",
  },
  "6719": {
    name: "tiny dinos winter 2022 #6719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6719,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6719.png",
  },
  "6720": {
    name: "tiny dinos winter 2022 #6720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6720,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6720.png",
  },
  "6721": {
    name: "tiny dinos winter 2022 #6721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6721,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6721.png",
  },
  "6722": {
    name: "tiny dinos winter 2022 #6722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6722,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6722.png",
  },
  "6723": {
    name: "tiny dinos winter 2022 #6723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6723,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6723.png",
  },
  "6724": {
    name: "tiny dinos winter 2022 #6724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6724,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6724.png",
  },
  "6725": {
    name: "tiny dinos winter 2022 #6725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6725,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6725.png",
  },
  "6726": {
    name: "tiny dinos winter 2022 #6726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6726,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6726.png",
  },
  "6727": {
    name: "tiny dinos winter 2022 #6727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6727,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6727.png",
  },
  "6728": {
    name: "tiny dinos winter 2022 #6728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6728,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6728.png",
  },
  "6729": {
    name: "tiny dinos winter 2022 #6729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6729,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6729.png",
  },
  "6730": {
    name: "tiny dinos winter 2022 #6730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6730,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6730.png",
  },
  "6731": {
    name: "tiny dinos winter 2022 #6731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6731,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6731.png",
  },
  "6732": {
    name: "tiny dinos winter 2022 #6732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6732,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6732.png",
  },
  "6733": {
    name: "tiny dinos winter 2022 #6733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6733,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6733.png",
  },
  "6734": {
    name: "tiny dinos winter 2022 #6734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6734,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6734.png",
  },
  "6735": {
    name: "tiny dinos winter 2022 #6735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6735,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6735.png",
  },
  "6736": {
    name: "tiny dinos winter 2022 #6736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6736,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6736.png",
  },
  "6737": {
    name: "tiny dinos winter 2022 #6737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6737,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6737.png",
  },
  "6738": {
    name: "tiny dinos winter 2022 #6738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6738,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6738.png",
  },
  "6739": {
    name: "tiny dinos winter 2022 #6739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6739,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6739.png",
  },
  "6740": {
    name: "tiny dinos winter 2022 #6740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6740,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6740.png",
  },
  "6741": {
    name: "tiny dinos winter 2022 #6741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6741,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6741.png",
  },
  "6742": {
    name: "tiny dinos winter 2022 #6742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6742,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6742.png",
  },
  "6743": {
    name: "tiny dinos winter 2022 #6743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6743,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6743.png",
  },
  "6744": {
    name: "tiny dinos winter 2022 #6744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6744,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6744.png",
  },
  "6745": {
    name: "tiny dinos winter 2022 #6745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6745,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6745.png",
  },
  "6746": {
    name: "tiny dinos winter 2022 #6746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6746,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6746.png",
  },
  "6747": {
    name: "tiny dinos winter 2022 #6747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6747,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6747.png",
  },
  "6748": {
    name: "tiny dinos winter 2022 #6748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6748,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6748.png",
  },
  "6749": {
    name: "tiny dinos winter 2022 #6749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6749,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6749.png",
  },
  "6750": {
    name: "tiny dinos winter 2022 #6750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6750,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6750.png",
  },
  "6751": {
    name: "tiny dinos winter 2022 #6751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6751,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6751.png",
  },
  "6752": {
    name: "tiny dinos winter 2022 #6752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6752,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6752.png",
  },
  "6753": {
    name: "tiny dinos winter 2022 #6753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6753,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6753.png",
  },
  "6754": {
    name: "tiny dinos winter 2022 #6754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6754,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6754.png",
  },
  "6755": {
    name: "tiny dinos winter 2022 #6755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6755,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6755.png",
  },
  "6756": {
    name: "tiny dinos winter 2022 #6756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6756,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6756.png",
  },
  "6757": {
    name: "tiny dinos winter 2022 #6757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6757,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6757.png",
  },
  "6758": {
    name: "tiny dinos winter 2022 #6758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6758,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6758.png",
  },
  "6759": {
    name: "tiny dinos winter 2022 #6759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6759,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6759.png",
  },
  "6760": {
    name: "tiny dinos winter 2022 #6760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6760,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6760.png",
  },
  "6761": {
    name: "tiny dinos winter 2022 #6761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6761,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6761.png",
  },
  "6762": {
    name: "tiny dinos winter 2022 #6762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6762,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6762.png",
  },
  "6763": {
    name: "tiny dinos winter 2022 #6763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6763,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6763.png",
  },
  "6764": {
    name: "tiny dinos winter 2022 #6764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6764,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6764.png",
  },
  "6765": {
    name: "tiny dinos winter 2022 #6765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6765,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6765.png",
  },
  "6766": {
    name: "tiny dinos winter 2022 #6766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6766,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6766.png",
  },
  "6767": {
    name: "tiny dinos winter 2022 #6767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6767,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6767.png",
  },
  "6768": {
    name: "tiny dinos winter 2022 #6768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6768,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6768.png",
  },
  "6769": {
    name: "tiny dinos winter 2022 #6769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6769,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6769.png",
  },
  "6770": {
    name: "tiny dinos winter 2022 #6770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6770,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6770.png",
  },
  "6771": {
    name: "tiny dinos winter 2022 #6771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6771,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6771.png",
  },
  "6772": {
    name: "tiny dinos winter 2022 #6772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6772,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6772.png",
  },
  "6773": {
    name: "tiny dinos winter 2022 #6773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6773,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6773.png",
  },
  "6774": {
    name: "tiny dinos winter 2022 #6774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6774,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6774.png",
  },
  "6775": {
    name: "tiny dinos winter 2022 #6775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6775,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6775.png",
  },
  "6776": {
    name: "tiny dinos winter 2022 #6776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6776,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6776.png",
  },
  "6777": {
    name: "tiny dinos winter 2022 #6777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6777,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6777.png",
  },
  "6778": {
    name: "tiny dinos winter 2022 #6778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6778,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6778.png",
  },
  "6779": {
    name: "tiny dinos winter 2022 #6779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6779,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6779.png",
  },
  "6780": {
    name: "tiny dinos winter 2022 #6780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6780,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6780.png",
  },
  "6781": {
    name: "tiny dinos winter 2022 #6781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6781,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6781.png",
  },
  "6782": {
    name: "tiny dinos winter 2022 #6782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6782,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6782.png",
  },
  "6783": {
    name: "tiny dinos winter 2022 #6783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6783,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6783.png",
  },
  "6784": {
    name: "tiny dinos winter 2022 #6784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6784,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6784.png",
  },
  "6785": {
    name: "tiny dinos winter 2022 #6785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6785,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6785.png",
  },
  "6786": {
    name: "tiny dinos winter 2022 #6786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6786,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6786.png",
  },
  "6787": {
    name: "tiny dinos winter 2022 #6787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6787,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6787.png",
  },
  "6788": {
    name: "tiny dinos winter 2022 #6788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6788,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6788.png",
  },
  "6789": {
    name: "tiny dinos winter 2022 #6789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6789,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6789.png",
  },
  "6790": {
    name: "tiny dinos winter 2022 #6790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6790,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6790.png",
  },
  "6791": {
    name: "tiny dinos winter 2022 #6791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6791,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6791.png",
  },
  "6792": {
    name: "tiny dinos winter 2022 #6792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6792,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6792.png",
  },
  "6793": {
    name: "tiny dinos winter 2022 #6793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6793,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6793.png",
  },
  "6794": {
    name: "tiny dinos winter 2022 #6794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6794,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6794.png",
  },
  "6795": {
    name: "tiny dinos winter 2022 #6795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6795,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6795.png",
  },
  "6796": {
    name: "tiny dinos winter 2022 #6796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6796,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6796.png",
  },
  "6797": {
    name: "tiny dinos winter 2022 #6797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6797,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6797.png",
  },
  "6798": {
    name: "tiny dinos winter 2022 #6798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6798,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6798.png",
  },
  "6799": {
    name: "tiny dinos winter 2022 #6799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6799,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6799.png",
  },
  "6800": {
    name: "tiny dinos winter 2022 #6800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6800,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6800.png",
  },
  "6801": {
    name: "tiny dinos winter 2022 #6801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6801,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6801.png",
  },
  "6802": {
    name: "tiny dinos winter 2022 #6802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6802,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6802.png",
  },
  "6803": {
    name: "tiny dinos winter 2022 #6803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6803,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6803.png",
  },
  "6804": {
    name: "tiny dinos winter 2022 #6804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6804,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6804.png",
  },
  "6805": {
    name: "tiny dinos winter 2022 #6805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6805,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6805.png",
  },
  "6806": {
    name: "tiny dinos winter 2022 #6806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6806,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6806.png",
  },
  "6807": {
    name: "tiny dinos winter 2022 #6807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6807,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6807.png",
  },
  "6808": {
    name: "tiny dinos winter 2022 #6808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6808,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6808.png",
  },
  "6809": {
    name: "tiny dinos winter 2022 #6809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6809,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6809.png",
  },
  "6810": {
    name: "tiny dinos winter 2022 #6810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6810,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6810.png",
  },
  "6811": {
    name: "tiny dinos winter 2022 #6811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6811,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6811.png",
  },
  "6812": {
    name: "tiny dinos winter 2022 #6812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6812,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6812.png",
  },
  "6813": {
    name: "tiny dinos winter 2022 #6813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6813,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6813.png",
  },
  "6814": {
    name: "tiny dinos winter 2022 #6814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6814,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6814.png",
  },
  "6815": {
    name: "tiny dinos winter 2022 #6815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6815,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6815.png",
  },
  "6816": {
    name: "tiny dinos winter 2022 #6816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6816,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6816.png",
  },
  "6817": {
    name: "tiny dinos winter 2022 #6817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6817,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6817.png",
  },
  "6818": {
    name: "tiny dinos winter 2022 #6818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6818,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6818.png",
  },
  "6819": {
    name: "tiny dinos winter 2022 #6819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6819,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6819.png",
  },
  "6820": {
    name: "tiny dinos winter 2022 #6820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6820,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6820.png",
  },
  "6821": {
    name: "tiny dinos winter 2022 #6821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6821,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6821.png",
  },
  "6822": {
    name: "tiny dinos winter 2022 #6822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6822,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6822.png",
  },
  "6823": {
    name: "tiny dinos winter 2022 #6823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6823,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6823.png",
  },
  "6824": {
    name: "tiny dinos winter 2022 #6824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6824,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6824.png",
  },
  "6825": {
    name: "tiny dinos winter 2022 #6825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6825,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6825.png",
  },
  "6826": {
    name: "tiny dinos winter 2022 #6826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6826,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6826.png",
  },
  "6827": {
    name: "tiny dinos winter 2022 #6827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6827,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6827.png",
  },
  "6828": {
    name: "tiny dinos winter 2022 #6828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6828,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6828.png",
  },
  "6829": {
    name: "tiny dinos winter 2022 #6829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6829,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6829.png",
  },
  "6830": {
    name: "tiny dinos winter 2022 #6830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6830,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6830.png",
  },
  "6831": {
    name: "tiny dinos winter 2022 #6831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6831,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6831.png",
  },
  "6832": {
    name: "tiny dinos winter 2022 #6832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6832,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6832.png",
  },
  "6833": {
    name: "tiny dinos winter 2022 #6833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6833,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6833.png",
  },
  "6834": {
    name: "tiny dinos winter 2022 #6834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6834,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6834.png",
  },
  "6835": {
    name: "tiny dinos winter 2022 #6835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6835,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6835.png",
  },
  "6836": {
    name: "tiny dinos winter 2022 #6836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6836,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6836.png",
  },
  "6837": {
    name: "tiny dinos winter 2022 #6837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6837,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6837.png",
  },
  "6838": {
    name: "tiny dinos winter 2022 #6838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6838,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6838.png",
  },
  "6839": {
    name: "tiny dinos winter 2022 #6839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6839,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6839.png",
  },
  "6840": {
    name: "tiny dinos winter 2022 #6840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6840,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6840.png",
  },
  "6841": {
    name: "tiny dinos winter 2022 #6841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6841,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6841.png",
  },
  "6842": {
    name: "tiny dinos winter 2022 #6842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6842,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6842.png",
  },
  "6843": {
    name: "tiny dinos winter 2022 #6843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6843,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6843.png",
  },
  "6844": {
    name: "tiny dinos winter 2022 #6844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6844,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6844.png",
  },
  "6845": {
    name: "tiny dinos winter 2022 #6845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6845,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6845.png",
  },
  "6846": {
    name: "tiny dinos winter 2022 #6846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6846,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6846.png",
  },
  "6847": {
    name: "tiny dinos winter 2022 #6847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6847,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6847.png",
  },
  "6848": {
    name: "tiny dinos winter 2022 #6848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6848,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6848.png",
  },
  "6849": {
    name: "tiny dinos winter 2022 #6849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6849,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6849.png",
  },
  "6850": {
    name: "tiny dinos winter 2022 #6850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6850,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6850.png",
  },
  "6851": {
    name: "tiny dinos winter 2022 #6851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6851,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6851.png",
  },
  "6852": {
    name: "tiny dinos winter 2022 #6852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6852,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6852.png",
  },
  "6853": {
    name: "tiny dinos winter 2022 #6853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6853,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6853.png",
  },
  "6854": {
    name: "tiny dinos winter 2022 #6854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6854,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6854.png",
  },
  "6855": {
    name: "tiny dinos winter 2022 #6855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6855,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6855.png",
  },
  "6856": {
    name: "tiny dinos winter 2022 #6856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6856,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6856.png",
  },
  "6857": {
    name: "tiny dinos winter 2022 #6857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6857,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6857.png",
  },
  "6858": {
    name: "tiny dinos winter 2022 #6858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6858,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6858.png",
  },
  "6859": {
    name: "tiny dinos winter 2022 #6859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6859,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6859.png",
  },
  "6860": {
    name: "tiny dinos winter 2022 #6860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6860,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6860.png",
  },
  "6861": {
    name: "tiny dinos winter 2022 #6861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6861,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6861.png",
  },
  "6862": {
    name: "tiny dinos winter 2022 #6862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6862,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6862.png",
  },
  "6863": {
    name: "tiny dinos winter 2022 #6863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6863,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6863.png",
  },
  "6864": {
    name: "tiny dinos winter 2022 #6864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6864,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6864.png",
  },
  "6865": {
    name: "tiny dinos winter 2022 #6865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6865,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6865.png",
  },
  "6866": {
    name: "tiny dinos winter 2022 #6866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6866,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6866.png",
  },
  "6867": {
    name: "tiny dinos winter 2022 #6867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6867,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6867.png",
  },
  "6868": {
    name: "tiny dinos winter 2022 #6868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6868,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6868.png",
  },
  "6869": {
    name: "tiny dinos winter 2022 #6869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6869,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6869.png",
  },
  "6870": {
    name: "tiny dinos winter 2022 #6870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6870,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6870.png",
  },
  "6871": {
    name: "tiny dinos winter 2022 #6871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6871,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6871.png",
  },
  "6872": {
    name: "tiny dinos winter 2022 #6872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6872,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6872.png",
  },
  "6873": {
    name: "tiny dinos winter 2022 #6873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6873,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6873.png",
  },
  "6874": {
    name: "tiny dinos winter 2022 #6874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6874,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6874.png",
  },
  "6875": {
    name: "tiny dinos winter 2022 #6875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6875,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6875.png",
  },
  "6876": {
    name: "tiny dinos winter 2022 #6876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6876,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6876.png",
  },
  "6877": {
    name: "tiny dinos winter 2022 #6877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6877,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6877.png",
  },
  "6878": {
    name: "tiny dinos winter 2022 #6878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6878,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6878.png",
  },
  "6879": {
    name: "tiny dinos winter 2022 #6879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6879,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6879.png",
  },
  "6880": {
    name: "tiny dinos winter 2022 #6880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6880,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6880.png",
  },
  "6881": {
    name: "tiny dinos winter 2022 #6881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6881,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6881.png",
  },
  "6882": {
    name: "tiny dinos winter 2022 #6882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6882,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6882.png",
  },
  "6883": {
    name: "tiny dinos winter 2022 #6883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6883,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6883.png",
  },
  "6884": {
    name: "tiny dinos winter 2022 #6884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6884,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6884.png",
  },
  "6885": {
    name: "tiny dinos winter 2022 #6885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6885,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6885.png",
  },
  "6886": {
    name: "tiny dinos winter 2022 #6886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6886,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6886.png",
  },
  "6887": {
    name: "tiny dinos winter 2022 #6887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6887,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6887.png",
  },
  "6888": {
    name: "tiny dinos winter 2022 #6888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6888,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6888.png",
  },
  "6889": {
    name: "tiny dinos winter 2022 #6889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6889,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6889.png",
  },
  "6890": {
    name: "tiny dinos winter 2022 #6890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6890,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6890.png",
  },
  "6891": {
    name: "tiny dinos winter 2022 #6891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6891,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6891.png",
  },
  "6892": {
    name: "tiny dinos winter 2022 #6892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6892,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6892.png",
  },
  "6893": {
    name: "tiny dinos winter 2022 #6893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6893,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6893.png",
  },
  "6894": {
    name: "tiny dinos winter 2022 #6894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6894,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6894.png",
  },
  "6895": {
    name: "tiny dinos winter 2022 #6895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6895,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6895.png",
  },
  "6896": {
    name: "tiny dinos winter 2022 #6896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6896,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6896.png",
  },
  "6897": {
    name: "tiny dinos winter 2022 #6897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6897,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6897.png",
  },
  "6898": {
    name: "tiny dinos winter 2022 #6898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6898,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6898.png",
  },
  "6899": {
    name: "tiny dinos winter 2022 #6899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6899,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6899.png",
  },
  "6900": {
    name: "tiny dinos winter 2022 #6900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6900,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6900.png",
  },
  "6901": {
    name: "tiny dinos winter 2022 #6901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6901,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6901.png",
  },
  "6902": {
    name: "tiny dinos winter 2022 #6902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6902,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6902.png",
  },
  "6903": {
    name: "tiny dinos winter 2022 #6903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6903,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6903.png",
  },
  "6904": {
    name: "tiny dinos winter 2022 #6904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6904,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6904.png",
  },
  "6905": {
    name: "tiny dinos winter 2022 #6905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6905,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6905.png",
  },
  "6906": {
    name: "tiny dinos winter 2022 #6906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6906,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6906.png",
  },
  "6907": {
    name: "tiny dinos winter 2022 #6907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6907,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6907.png",
  },
  "6908": {
    name: "tiny dinos winter 2022 #6908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6908,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6908.png",
  },
  "6909": {
    name: "tiny dinos winter 2022 #6909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6909,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6909.png",
  },
  "6910": {
    name: "tiny dinos winter 2022 #6910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6910,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6910.png",
  },
  "6911": {
    name: "tiny dinos winter 2022 #6911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6911,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6911.png",
  },
  "6912": {
    name: "tiny dinos winter 2022 #6912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6912,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6912.png",
  },
  "6913": {
    name: "tiny dinos winter 2022 #6913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6913,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6913.png",
  },
  "6914": {
    name: "tiny dinos winter 2022 #6914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6914,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6914.png",
  },
  "6915": {
    name: "tiny dinos winter 2022 #6915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6915,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6915.png",
  },
  "6916": {
    name: "tiny dinos winter 2022 #6916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6916,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6916.png",
  },
  "6917": {
    name: "tiny dinos winter 2022 #6917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6917,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6917.png",
  },
  "6918": {
    name: "tiny dinos winter 2022 #6918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6918,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6918.png",
  },
  "6919": {
    name: "tiny dinos winter 2022 #6919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6919,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6919.png",
  },
  "6920": {
    name: "tiny dinos winter 2022 #6920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6920,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6920.png",
  },
  "6921": {
    name: "tiny dinos winter 2022 #6921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6921,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6921.png",
  },
  "6922": {
    name: "tiny dinos winter 2022 #6922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6922,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6922.png",
  },
  "6923": {
    name: "tiny dinos winter 2022 #6923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6923,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6923.png",
  },
  "6924": {
    name: "tiny dinos winter 2022 #6924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6924,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6924.png",
  },
  "6925": {
    name: "tiny dinos winter 2022 #6925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6925,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6925.png",
  },
  "6926": {
    name: "tiny dinos winter 2022 #6926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6926,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6926.png",
  },
  "6927": {
    name: "tiny dinos winter 2022 #6927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6927,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6927.png",
  },
  "6928": {
    name: "tiny dinos winter 2022 #6928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6928,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6928.png",
  },
  "6929": {
    name: "tiny dinos winter 2022 #6929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6929,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6929.png",
  },
  "6930": {
    name: "tiny dinos winter 2022 #6930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6930,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6930.png",
  },
  "6931": {
    name: "tiny dinos winter 2022 #6931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6931,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6931.png",
  },
  "6932": {
    name: "tiny dinos winter 2022 #6932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6932,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6932.png",
  },
  "6933": {
    name: "tiny dinos winter 2022 #6933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6933,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6933.png",
  },
  "6934": {
    name: "tiny dinos winter 2022 #6934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6934,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6934.png",
  },
  "6935": {
    name: "tiny dinos winter 2022 #6935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6935,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6935.png",
  },
  "6936": {
    name: "tiny dinos winter 2022 #6936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6936,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6936.png",
  },
  "6937": {
    name: "tiny dinos winter 2022 #6937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6937,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6937.png",
  },
  "6938": {
    name: "tiny dinos winter 2022 #6938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6938,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6938.png",
  },
  "6939": {
    name: "tiny dinos winter 2022 #6939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6939,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6939.png",
  },
  "6940": {
    name: "tiny dinos winter 2022 #6940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6940,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6940.png",
  },
  "6941": {
    name: "tiny dinos winter 2022 #6941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6941,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6941.png",
  },
  "6942": {
    name: "tiny dinos winter 2022 #6942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6942,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6942.png",
  },
  "6943": {
    name: "tiny dinos winter 2022 #6943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6943,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6943.png",
  },
  "6944": {
    name: "tiny dinos winter 2022 #6944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6944,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6944.png",
  },
  "6945": {
    name: "tiny dinos winter 2022 #6945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6945,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6945.png",
  },
  "6946": {
    name: "tiny dinos winter 2022 #6946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6946,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6946.png",
  },
  "6947": {
    name: "tiny dinos winter 2022 #6947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6947,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6947.png",
  },
  "6948": {
    name: "tiny dinos winter 2022 #6948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6948,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6948.png",
  },
  "6949": {
    name: "tiny dinos winter 2022 #6949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6949,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6949.png",
  },
  "6950": {
    name: "tiny dinos winter 2022 #6950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6950,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6950.png",
  },
  "6951": {
    name: "tiny dinos winter 2022 #6951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6951,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6951.png",
  },
  "6952": {
    name: "tiny dinos winter 2022 #6952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6952,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6952.png",
  },
  "6953": {
    name: "tiny dinos winter 2022 #6953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6953,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6953.png",
  },
  "6954": {
    name: "tiny dinos winter 2022 #6954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6954,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6954.png",
  },
  "6955": {
    name: "tiny dinos winter 2022 #6955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6955,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6955.png",
  },
  "6956": {
    name: "tiny dinos winter 2022 #6956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6956,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6956.png",
  },
  "6957": {
    name: "tiny dinos winter 2022 #6957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6957,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6957.png",
  },
  "6958": {
    name: "tiny dinos winter 2022 #6958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6958,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6958.png",
  },
  "6959": {
    name: "tiny dinos winter 2022 #6959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6959,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6959.png",
  },
  "6960": {
    name: "tiny dinos winter 2022 #6960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6960,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6960.png",
  },
  "6961": {
    name: "tiny dinos winter 2022 #6961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6961,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6961.png",
  },
  "6962": {
    name: "tiny dinos winter 2022 #6962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6962,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6962.png",
  },
  "6963": {
    name: "tiny dinos winter 2022 #6963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6963,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6963.png",
  },
  "6964": {
    name: "tiny dinos winter 2022 #6964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6964,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6964.png",
  },
  "6965": {
    name: "tiny dinos winter 2022 #6965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6965,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6965.png",
  },
  "6966": {
    name: "tiny dinos winter 2022 #6966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6966,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6966.png",
  },
  "6967": {
    name: "tiny dinos winter 2022 #6967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6967,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6967.png",
  },
  "6968": {
    name: "tiny dinos winter 2022 #6968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6968,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6968.png",
  },
  "6969": {
    name: "tiny dinos winter 2022 #6969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6969,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6969.png",
  },
  "6970": {
    name: "tiny dinos winter 2022 #6970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6970,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6970.png",
  },
  "6971": {
    name: "tiny dinos winter 2022 #6971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6971,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6971.png",
  },
  "6972": {
    name: "tiny dinos winter 2022 #6972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6972,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6972.png",
  },
  "6973": {
    name: "tiny dinos winter 2022 #6973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6973,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6973.png",
  },
  "6974": {
    name: "tiny dinos winter 2022 #6974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6974,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6974.png",
  },
  "6975": {
    name: "tiny dinos winter 2022 #6975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6975,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6975.png",
  },
  "6976": {
    name: "tiny dinos winter 2022 #6976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6976,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6976.png",
  },
  "6977": {
    name: "tiny dinos winter 2022 #6977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6977,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6977.png",
  },
  "6978": {
    name: "tiny dinos winter 2022 #6978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6978,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6978.png",
  },
  "6979": {
    name: "tiny dinos winter 2022 #6979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6979,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6979.png",
  },
  "6980": {
    name: "tiny dinos winter 2022 #6980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6980,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6980.png",
  },
  "6981": {
    name: "tiny dinos winter 2022 #6981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6981,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6981.png",
  },
  "6982": {
    name: "tiny dinos winter 2022 #6982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6982,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6982.png",
  },
  "6983": {
    name: "tiny dinos winter 2022 #6983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6983,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6983.png",
  },
  "6984": {
    name: "tiny dinos winter 2022 #6984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6984,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6984.png",
  },
  "6985": {
    name: "tiny dinos winter 2022 #6985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6985,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6985.png",
  },
  "6986": {
    name: "tiny dinos winter 2022 #6986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6986,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6986.png",
  },
  "6987": {
    name: "tiny dinos winter 2022 #6987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6987,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6987.png",
  },
  "6988": {
    name: "tiny dinos winter 2022 #6988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6988,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6988.png",
  },
  "6989": {
    name: "tiny dinos winter 2022 #6989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6989,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6989.png",
  },
  "6990": {
    name: "tiny dinos winter 2022 #6990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6990,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6990.png",
  },
  "6991": {
    name: "tiny dinos winter 2022 #6991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6991,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6991.png",
  },
  "6992": {
    name: "tiny dinos winter 2022 #6992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6992,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6992.png",
  },
  "6993": {
    name: "tiny dinos winter 2022 #6993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6993,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6993.png",
  },
  "6994": {
    name: "tiny dinos winter 2022 #6994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6994,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6994.png",
  },
  "6995": {
    name: "tiny dinos winter 2022 #6995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6995,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6995.png",
  },
  "6996": {
    name: "tiny dinos winter 2022 #6996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6996,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6996.png",
  },
  "6997": {
    name: "tiny dinos winter 2022 #6997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6997,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6997.png",
  },
  "6998": {
    name: "tiny dinos winter 2022 #6998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6998,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6998.png",
  },
  "6999": {
    name: "tiny dinos winter 2022 #6999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 6999,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/6999.png",
  },
  "7000": {
    name: "tiny dinos winter 2022 #7000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7000,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7000.png",
  },
  "7001": {
    name: "tiny dinos winter 2022 #7001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7001,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7001.png",
  },
  "7002": {
    name: "tiny dinos winter 2022 #7002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7002,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7002.png",
  },
  "7003": {
    name: "tiny dinos winter 2022 #7003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7003,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7003.png",
  },
  "7004": {
    name: "tiny dinos winter 2022 #7004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7004,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7004.png",
  },
  "7005": {
    name: "tiny dinos winter 2022 #7005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7005,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7005.png",
  },
  "7006": {
    name: "tiny dinos winter 2022 #7006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7006,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7006.png",
  },
  "7007": {
    name: "tiny dinos winter 2022 #7007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7007,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7007.png",
  },
  "7008": {
    name: "tiny dinos winter 2022 #7008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7008,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7008.png",
  },
  "7009": {
    name: "tiny dinos winter 2022 #7009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7009,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7009.png",
  },
  "7010": {
    name: "tiny dinos winter 2022 #7010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7010,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7010.png",
  },
  "7011": {
    name: "tiny dinos winter 2022 #7011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7011,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7011.png",
  },
  "7012": {
    name: "tiny dinos winter 2022 #7012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7012,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7012.png",
  },
  "7013": {
    name: "tiny dinos winter 2022 #7013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7013,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7013.png",
  },
  "7014": {
    name: "tiny dinos winter 2022 #7014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7014,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7014.png",
  },
  "7015": {
    name: "tiny dinos winter 2022 #7015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7015,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7015.png",
  },
  "7016": {
    name: "tiny dinos winter 2022 #7016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7016,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7016.png",
  },
  "7017": {
    name: "tiny dinos winter 2022 #7017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7017,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7017.png",
  },
  "7018": {
    name: "tiny dinos winter 2022 #7018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7018,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7018.png",
  },
  "7019": {
    name: "tiny dinos winter 2022 #7019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7019,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7019.png",
  },
  "7020": {
    name: "tiny dinos winter 2022 #7020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7020,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7020.png",
  },
  "7021": {
    name: "tiny dinos winter 2022 #7021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7021,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7021.png",
  },
  "7022": {
    name: "tiny dinos winter 2022 #7022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7022,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7022.png",
  },
  "7023": {
    name: "tiny dinos winter 2022 #7023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7023,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7023.png",
  },
  "7024": {
    name: "tiny dinos winter 2022 #7024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7024,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7024.png",
  },
  "7025": {
    name: "tiny dinos winter 2022 #7025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7025,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7025.png",
  },
  "7026": {
    name: "tiny dinos winter 2022 #7026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7026,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7026.png",
  },
  "7027": {
    name: "tiny dinos winter 2022 #7027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7027,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7027.png",
  },
  "7028": {
    name: "tiny dinos winter 2022 #7028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7028,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7028.png",
  },
  "7029": {
    name: "tiny dinos winter 2022 #7029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7029,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7029.png",
  },
  "7030": {
    name: "tiny dinos winter 2022 #7030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7030,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7030.png",
  },
  "7031": {
    name: "tiny dinos winter 2022 #7031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7031,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7031.png",
  },
  "7032": {
    name: "tiny dinos winter 2022 #7032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7032,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7032.png",
  },
  "7033": {
    name: "tiny dinos winter 2022 #7033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7033,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7033.png",
  },
  "7034": {
    name: "tiny dinos winter 2022 #7034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7034,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7034.png",
  },
  "7035": {
    name: "tiny dinos winter 2022 #7035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7035,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7035.png",
  },
  "7036": {
    name: "tiny dinos winter 2022 #7036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7036,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7036.png",
  },
  "7037": {
    name: "tiny dinos winter 2022 #7037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7037,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7037.png",
  },
  "7038": {
    name: "tiny dinos winter 2022 #7038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7038,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7038.png",
  },
  "7039": {
    name: "tiny dinos winter 2022 #7039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7039,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7039.png",
  },
  "7040": {
    name: "tiny dinos winter 2022 #7040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7040,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7040.png",
  },
  "7041": {
    name: "tiny dinos winter 2022 #7041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7041,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7041.png",
  },
  "7042": {
    name: "tiny dinos winter 2022 #7042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7042,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7042.png",
  },
  "7043": {
    name: "tiny dinos winter 2022 #7043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7043,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7043.png",
  },
  "7044": {
    name: "tiny dinos winter 2022 #7044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7044,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7044.png",
  },
  "7045": {
    name: "tiny dinos winter 2022 #7045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7045,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7045.png",
  },
  "7046": {
    name: "tiny dinos winter 2022 #7046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7046,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7046.png",
  },
  "7047": {
    name: "tiny dinos winter 2022 #7047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7047,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7047.png",
  },
  "7048": {
    name: "tiny dinos winter 2022 #7048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7048,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7048.png",
  },
  "7049": {
    name: "tiny dinos winter 2022 #7049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7049,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7049.png",
  },
  "7050": {
    name: "tiny dinos winter 2022 #7050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7050,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7050.png",
  },
  "7051": {
    name: "tiny dinos winter 2022 #7051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7051,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7051.png",
  },
  "7052": {
    name: "tiny dinos winter 2022 #7052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7052,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7052.png",
  },
  "7053": {
    name: "tiny dinos winter 2022 #7053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7053,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7053.png",
  },
  "7054": {
    name: "tiny dinos winter 2022 #7054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7054,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7054.png",
  },
  "7055": {
    name: "tiny dinos winter 2022 #7055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7055,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7055.png",
  },
  "7056": {
    name: "tiny dinos winter 2022 #7056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7056,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7056.png",
  },
  "7057": {
    name: "tiny dinos winter 2022 #7057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7057,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7057.png",
  },
  "7058": {
    name: "tiny dinos winter 2022 #7058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7058,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7058.png",
  },
  "7059": {
    name: "tiny dinos winter 2022 #7059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7059,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7059.png",
  },
  "7060": {
    name: "tiny dinos winter 2022 #7060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7060,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7060.png",
  },
  "7061": {
    name: "tiny dinos winter 2022 #7061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7061,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7061.png",
  },
  "7062": {
    name: "tiny dinos winter 2022 #7062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7062,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7062.png",
  },
  "7063": {
    name: "tiny dinos winter 2022 #7063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7063,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7063.png",
  },
  "7064": {
    name: "tiny dinos winter 2022 #7064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7064,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7064.png",
  },
  "7065": {
    name: "tiny dinos winter 2022 #7065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7065,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7065.png",
  },
  "7066": {
    name: "tiny dinos winter 2022 #7066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7066,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7066.png",
  },
  "7067": {
    name: "tiny dinos winter 2022 #7067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7067,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7067.png",
  },
  "7068": {
    name: "tiny dinos winter 2022 #7068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7068,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7068.png",
  },
  "7069": {
    name: "tiny dinos winter 2022 #7069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7069,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7069.png",
  },
  "7070": {
    name: "tiny dinos winter 2022 #7070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7070,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7070.png",
  },
  "7071": {
    name: "tiny dinos winter 2022 #7071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7071,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7071.png",
  },
  "7072": {
    name: "tiny dinos winter 2022 #7072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7072,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7072.png",
  },
  "7073": {
    name: "tiny dinos winter 2022 #7073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7073,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7073.png",
  },
  "7074": {
    name: "tiny dinos winter 2022 #7074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7074,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7074.png",
  },
  "7075": {
    name: "tiny dinos winter 2022 #7075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7075,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7075.png",
  },
  "7076": {
    name: "tiny dinos winter 2022 #7076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7076,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7076.png",
  },
  "7077": {
    name: "tiny dinos winter 2022 #7077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7077,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7077.png",
  },
  "7078": {
    name: "tiny dinos winter 2022 #7078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7078,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7078.png",
  },
  "7079": {
    name: "tiny dinos winter 2022 #7079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7079,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7079.png",
  },
  "7080": {
    name: "tiny dinos winter 2022 #7080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7080,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7080.png",
  },
  "7081": {
    name: "tiny dinos winter 2022 #7081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7081,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7081.png",
  },
  "7082": {
    name: "tiny dinos winter 2022 #7082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7082,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7082.png",
  },
  "7083": {
    name: "tiny dinos winter 2022 #7083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7083,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7083.png",
  },
  "7084": {
    name: "tiny dinos winter 2022 #7084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7084,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7084.png",
  },
  "7085": {
    name: "tiny dinos winter 2022 #7085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7085,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7085.png",
  },
  "7086": {
    name: "tiny dinos winter 2022 #7086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7086,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7086.png",
  },
  "7087": {
    name: "tiny dinos winter 2022 #7087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7087,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7087.png",
  },
  "7088": {
    name: "tiny dinos winter 2022 #7088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7088,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7088.png",
  },
  "7089": {
    name: "tiny dinos winter 2022 #7089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7089,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7089.png",
  },
  "7090": {
    name: "tiny dinos winter 2022 #7090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7090,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7090.png",
  },
  "7091": {
    name: "tiny dinos winter 2022 #7091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7091,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7091.png",
  },
  "7092": {
    name: "tiny dinos winter 2022 #7092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7092,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7092.png",
  },
  "7093": {
    name: "tiny dinos winter 2022 #7093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7093,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7093.png",
  },
  "7094": {
    name: "tiny dinos winter 2022 #7094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7094,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7094.png",
  },
  "7095": {
    name: "tiny dinos winter 2022 #7095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7095,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7095.png",
  },
  "7096": {
    name: "tiny dinos winter 2022 #7096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7096,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7096.png",
  },
  "7097": {
    name: "tiny dinos winter 2022 #7097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7097,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7097.png",
  },
  "7098": {
    name: "tiny dinos winter 2022 #7098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7098,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7098.png",
  },
  "7099": {
    name: "tiny dinos winter 2022 #7099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7099,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7099.png",
  },
  "7100": {
    name: "tiny dinos winter 2022 #7100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7100,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7100.png",
  },
  "7101": {
    name: "tiny dinos winter 2022 #7101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7101,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7101.png",
  },
  "7102": {
    name: "tiny dinos winter 2022 #7102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7102,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7102.png",
  },
  "7103": {
    name: "tiny dinos winter 2022 #7103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7103,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7103.png",
  },
  "7104": {
    name: "tiny dinos winter 2022 #7104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7104,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7104.png",
  },
  "7105": {
    name: "tiny dinos winter 2022 #7105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7105,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7105.png",
  },
  "7106": {
    name: "tiny dinos winter 2022 #7106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7106,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7106.png",
  },
  "7107": {
    name: "tiny dinos winter 2022 #7107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7107,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7107.png",
  },
  "7108": {
    name: "tiny dinos winter 2022 #7108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7108,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7108.png",
  },
  "7109": {
    name: "tiny dinos winter 2022 #7109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7109,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7109.png",
  },
  "7110": {
    name: "tiny dinos winter 2022 #7110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7110,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7110.png",
  },
  "7111": {
    name: "tiny dinos winter 2022 #7111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7111,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7111.png",
  },
  "7112": {
    name: "tiny dinos winter 2022 #7112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7112,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7112.png",
  },
  "7113": {
    name: "tiny dinos winter 2022 #7113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7113,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7113.png",
  },
  "7114": {
    name: "tiny dinos winter 2022 #7114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7114,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7114.png",
  },
  "7115": {
    name: "tiny dinos winter 2022 #7115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7115,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7115.png",
  },
  "7116": {
    name: "tiny dinos winter 2022 #7116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7116,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7116.png",
  },
  "7117": {
    name: "tiny dinos winter 2022 #7117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7117,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7117.png",
  },
  "7118": {
    name: "tiny dinos winter 2022 #7118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7118,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7118.png",
  },
  "7119": {
    name: "tiny dinos winter 2022 #7119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7119,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7119.png",
  },
  "7120": {
    name: "tiny dinos winter 2022 #7120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7120,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7120.png",
  },
  "7121": {
    name: "tiny dinos winter 2022 #7121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7121,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7121.png",
  },
  "7122": {
    name: "tiny dinos winter 2022 #7122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7122,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7122.png",
  },
  "7123": {
    name: "tiny dinos winter 2022 #7123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7123,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7123.png",
  },
  "7124": {
    name: "tiny dinos winter 2022 #7124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7124,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7124.png",
  },
  "7125": {
    name: "tiny dinos winter 2022 #7125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7125,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7125.png",
  },
  "7126": {
    name: "tiny dinos winter 2022 #7126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7126,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7126.png",
  },
  "7127": {
    name: "tiny dinos winter 2022 #7127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7127,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7127.png",
  },
  "7128": {
    name: "tiny dinos winter 2022 #7128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7128,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7128.png",
  },
  "7129": {
    name: "tiny dinos winter 2022 #7129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7129,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7129.png",
  },
  "7130": {
    name: "tiny dinos winter 2022 #7130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7130,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7130.png",
  },
  "7131": {
    name: "tiny dinos winter 2022 #7131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7131,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7131.png",
  },
  "7132": {
    name: "tiny dinos winter 2022 #7132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7132,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7132.png",
  },
  "7133": {
    name: "tiny dinos winter 2022 #7133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7133,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7133.png",
  },
  "7134": {
    name: "tiny dinos winter 2022 #7134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7134,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7134.png",
  },
  "7135": {
    name: "tiny dinos winter 2022 #7135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7135,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7135.png",
  },
  "7136": {
    name: "tiny dinos winter 2022 #7136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7136,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7136.png",
  },
  "7137": {
    name: "tiny dinos winter 2022 #7137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7137,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7137.png",
  },
  "7138": {
    name: "tiny dinos winter 2022 #7138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7138,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7138.png",
  },
  "7139": {
    name: "tiny dinos winter 2022 #7139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7139,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7139.png",
  },
  "7140": {
    name: "tiny dinos winter 2022 #7140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7140,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7140.png",
  },
  "7141": {
    name: "tiny dinos winter 2022 #7141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7141,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7141.png",
  },
  "7142": {
    name: "tiny dinos winter 2022 #7142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7142,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7142.png",
  },
  "7143": {
    name: "tiny dinos winter 2022 #7143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7143,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7143.png",
  },
  "7144": {
    name: "tiny dinos winter 2022 #7144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7144,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7144.png",
  },
  "7145": {
    name: "tiny dinos winter 2022 #7145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7145,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7145.png",
  },
  "7146": {
    name: "tiny dinos winter 2022 #7146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7146,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7146.png",
  },
  "7147": {
    name: "tiny dinos winter 2022 #7147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7147,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7147.png",
  },
  "7148": {
    name: "tiny dinos winter 2022 #7148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7148,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7148.png",
  },
  "7149": {
    name: "tiny dinos winter 2022 #7149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7149,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7149.png",
  },
  "7150": {
    name: "tiny dinos winter 2022 #7150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7150,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7150.png",
  },
  "7151": {
    name: "tiny dinos winter 2022 #7151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7151,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7151.png",
  },
  "7152": {
    name: "tiny dinos winter 2022 #7152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7152,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7152.png",
  },
  "7153": {
    name: "tiny dinos winter 2022 #7153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7153,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7153.png",
  },
  "7154": {
    name: "tiny dinos winter 2022 #7154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7154,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7154.png",
  },
  "7155": {
    name: "tiny dinos winter 2022 #7155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7155,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7155.png",
  },
  "7156": {
    name: "tiny dinos winter 2022 #7156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7156,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7156.png",
  },
  "7157": {
    name: "tiny dinos winter 2022 #7157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7157,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7157.png",
  },
  "7158": {
    name: "tiny dinos winter 2022 #7158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7158,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7158.png",
  },
  "7159": {
    name: "tiny dinos winter 2022 #7159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7159,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7159.png",
  },
  "7160": {
    name: "tiny dinos winter 2022 #7160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7160,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7160.png",
  },
  "7161": {
    name: "tiny dinos winter 2022 #7161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7161,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7161.png",
  },
  "7162": {
    name: "tiny dinos winter 2022 #7162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7162,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7162.png",
  },
  "7163": {
    name: "tiny dinos winter 2022 #7163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7163,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7163.png",
  },
  "7164": {
    name: "tiny dinos winter 2022 #7164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7164,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7164.png",
  },
  "7165": {
    name: "tiny dinos winter 2022 #7165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7165,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7165.png",
  },
  "7166": {
    name: "tiny dinos winter 2022 #7166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7166,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7166.png",
  },
  "7167": {
    name: "tiny dinos winter 2022 #7167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7167,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7167.png",
  },
  "7168": {
    name: "tiny dinos winter 2022 #7168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7168,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7168.png",
  },
  "7169": {
    name: "tiny dinos winter 2022 #7169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7169,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7169.png",
  },
  "7170": {
    name: "tiny dinos winter 2022 #7170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7170,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7170.png",
  },
  "7171": {
    name: "tiny dinos winter 2022 #7171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7171,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7171.png",
  },
  "7172": {
    name: "tiny dinos winter 2022 #7172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7172,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7172.png",
  },
  "7173": {
    name: "tiny dinos winter 2022 #7173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7173,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7173.png",
  },
  "7174": {
    name: "tiny dinos winter 2022 #7174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7174,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7174.png",
  },
  "7175": {
    name: "tiny dinos winter 2022 #7175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7175,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7175.png",
  },
  "7176": {
    name: "tiny dinos winter 2022 #7176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7176,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7176.png",
  },
  "7177": {
    name: "tiny dinos winter 2022 #7177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7177,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7177.png",
  },
  "7178": {
    name: "tiny dinos winter 2022 #7178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7178,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7178.png",
  },
  "7179": {
    name: "tiny dinos winter 2022 #7179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7179,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7179.png",
  },
  "7180": {
    name: "tiny dinos winter 2022 #7180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7180,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7180.png",
  },
  "7181": {
    name: "tiny dinos winter 2022 #7181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7181,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7181.png",
  },
  "7182": {
    name: "tiny dinos winter 2022 #7182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7182,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7182.png",
  },
  "7183": {
    name: "tiny dinos winter 2022 #7183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7183,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7183.png",
  },
  "7184": {
    name: "tiny dinos winter 2022 #7184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7184,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7184.png",
  },
  "7185": {
    name: "tiny dinos winter 2022 #7185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7185,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7185.png",
  },
  "7186": {
    name: "tiny dinos winter 2022 #7186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7186,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7186.png",
  },
  "7187": {
    name: "tiny dinos winter 2022 #7187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7187,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7187.png",
  },
  "7188": {
    name: "tiny dinos winter 2022 #7188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7188,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7188.png",
  },
  "7189": {
    name: "tiny dinos winter 2022 #7189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7189,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7189.png",
  },
  "7190": {
    name: "tiny dinos winter 2022 #7190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7190,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7190.png",
  },
  "7191": {
    name: "tiny dinos winter 2022 #7191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7191,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7191.png",
  },
  "7192": {
    name: "tiny dinos winter 2022 #7192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7192,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7192.png",
  },
  "7193": {
    name: "tiny dinos winter 2022 #7193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7193,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7193.png",
  },
  "7194": {
    name: "tiny dinos winter 2022 #7194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7194,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7194.png",
  },
  "7195": {
    name: "tiny dinos winter 2022 #7195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7195,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7195.png",
  },
  "7196": {
    name: "tiny dinos winter 2022 #7196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7196,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7196.png",
  },
  "7197": {
    name: "tiny dinos winter 2022 #7197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7197,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7197.png",
  },
  "7198": {
    name: "tiny dinos winter 2022 #7198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7198,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7198.png",
  },
  "7199": {
    name: "tiny dinos winter 2022 #7199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7199,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7199.png",
  },
  "7200": {
    name: "tiny dinos winter 2022 #7200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7200,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7200.png",
  },
  "7201": {
    name: "tiny dinos winter 2022 #7201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7201,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7201.png",
  },
  "7202": {
    name: "tiny dinos winter 2022 #7202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7202,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7202.png",
  },
  "7203": {
    name: "tiny dinos winter 2022 #7203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7203,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7203.png",
  },
  "7204": {
    name: "tiny dinos winter 2022 #7204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7204,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7204.png",
  },
  "7205": {
    name: "tiny dinos winter 2022 #7205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7205,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7205.png",
  },
  "7206": {
    name: "tiny dinos winter 2022 #7206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7206,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7206.png",
  },
  "7207": {
    name: "tiny dinos winter 2022 #7207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7207,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7207.png",
  },
  "7208": {
    name: "tiny dinos winter 2022 #7208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7208,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7208.png",
  },
  "7209": {
    name: "tiny dinos winter 2022 #7209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7209,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7209.png",
  },
  "7210": {
    name: "tiny dinos winter 2022 #7210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7210,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7210.png",
  },
  "7211": {
    name: "tiny dinos winter 2022 #7211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7211,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7211.png",
  },
  "7212": {
    name: "tiny dinos winter 2022 #7212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7212,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7212.png",
  },
  "7213": {
    name: "tiny dinos winter 2022 #7213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7213,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7213.png",
  },
  "7214": {
    name: "tiny dinos winter 2022 #7214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7214,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7214.png",
  },
  "7215": {
    name: "tiny dinos winter 2022 #7215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7215,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7215.png",
  },
  "7216": {
    name: "tiny dinos winter 2022 #7216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7216,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7216.png",
  },
  "7217": {
    name: "tiny dinos winter 2022 #7217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7217,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7217.png",
  },
  "7218": {
    name: "tiny dinos winter 2022 #7218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7218,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7218.png",
  },
  "7219": {
    name: "tiny dinos winter 2022 #7219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7219,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7219.png",
  },
  "7220": {
    name: "tiny dinos winter 2022 #7220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7220,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7220.png",
  },
  "7221": {
    name: "tiny dinos winter 2022 #7221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7221,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7221.png",
  },
  "7222": {
    name: "tiny dinos winter 2022 #7222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7222,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7222.png",
  },
  "7223": {
    name: "tiny dinos winter 2022 #7223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7223,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7223.png",
  },
  "7224": {
    name: "tiny dinos winter 2022 #7224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7224,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7224.png",
  },
  "7225": {
    name: "tiny dinos winter 2022 #7225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7225,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7225.png",
  },
  "7226": {
    name: "tiny dinos winter 2022 #7226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7226,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7226.png",
  },
  "7227": {
    name: "tiny dinos winter 2022 #7227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7227,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7227.png",
  },
  "7228": {
    name: "tiny dinos winter 2022 #7228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7228,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7228.png",
  },
  "7229": {
    name: "tiny dinos winter 2022 #7229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7229,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7229.png",
  },
  "7230": {
    name: "tiny dinos winter 2022 #7230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7230,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7230.png",
  },
  "7231": {
    name: "tiny dinos winter 2022 #7231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7231,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7231.png",
  },
  "7232": {
    name: "tiny dinos winter 2022 #7232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7232,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7232.png",
  },
  "7233": {
    name: "tiny dinos winter 2022 #7233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7233,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7233.png",
  },
  "7234": {
    name: "tiny dinos winter 2022 #7234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7234,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7234.png",
  },
  "7235": {
    name: "tiny dinos winter 2022 #7235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7235,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7235.png",
  },
  "7236": {
    name: "tiny dinos winter 2022 #7236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7236,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7236.png",
  },
  "7237": {
    name: "tiny dinos winter 2022 #7237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7237,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7237.png",
  },
  "7238": {
    name: "tiny dinos winter 2022 #7238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7238,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7238.png",
  },
  "7239": {
    name: "tiny dinos winter 2022 #7239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7239,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7239.png",
  },
  "7240": {
    name: "tiny dinos winter 2022 #7240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7240,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7240.png",
  },
  "7241": {
    name: "tiny dinos winter 2022 #7241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7241,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7241.png",
  },
  "7242": {
    name: "tiny dinos winter 2022 #7242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7242,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7242.png",
  },
  "7243": {
    name: "tiny dinos winter 2022 #7243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7243,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7243.png",
  },
  "7244": {
    name: "tiny dinos winter 2022 #7244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7244,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7244.png",
  },
  "7245": {
    name: "tiny dinos winter 2022 #7245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7245,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7245.png",
  },
  "7246": {
    name: "tiny dinos winter 2022 #7246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7246,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7246.png",
  },
  "7247": {
    name: "tiny dinos winter 2022 #7247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7247,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7247.png",
  },
  "7248": {
    name: "tiny dinos winter 2022 #7248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7248,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7248.png",
  },
  "7249": {
    name: "tiny dinos winter 2022 #7249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7249,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7249.png",
  },
  "7250": {
    name: "tiny dinos winter 2022 #7250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7250,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7250.png",
  },
  "7251": {
    name: "tiny dinos winter 2022 #7251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7251,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7251.png",
  },
  "7252": {
    name: "tiny dinos winter 2022 #7252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7252,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7252.png",
  },
  "7253": {
    name: "tiny dinos winter 2022 #7253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7253,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7253.png",
  },
  "7254": {
    name: "tiny dinos winter 2022 #7254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7254,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7254.png",
  },
  "7255": {
    name: "tiny dinos winter 2022 #7255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7255,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7255.png",
  },
  "7256": {
    name: "tiny dinos winter 2022 #7256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7256,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7256.png",
  },
  "7257": {
    name: "tiny dinos winter 2022 #7257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7257,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7257.png",
  },
  "7258": {
    name: "tiny dinos winter 2022 #7258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7258,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7258.png",
  },
  "7259": {
    name: "tiny dinos winter 2022 #7259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7259,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7259.png",
  },
  "7260": {
    name: "tiny dinos winter 2022 #7260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7260,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7260.png",
  },
  "7261": {
    name: "tiny dinos winter 2022 #7261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7261,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7261.png",
  },
  "7262": {
    name: "tiny dinos winter 2022 #7262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7262,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7262.png",
  },
  "7263": {
    name: "tiny dinos winter 2022 #7263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7263,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7263.png",
  },
  "7264": {
    name: "tiny dinos winter 2022 #7264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7264,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7264.png",
  },
  "7265": {
    name: "tiny dinos winter 2022 #7265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7265,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7265.png",
  },
  "7266": {
    name: "tiny dinos winter 2022 #7266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7266,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7266.png",
  },
  "7267": {
    name: "tiny dinos winter 2022 #7267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7267,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7267.png",
  },
  "7268": {
    name: "tiny dinos winter 2022 #7268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7268,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7268.png",
  },
  "7269": {
    name: "tiny dinos winter 2022 #7269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7269,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7269.png",
  },
  "7270": {
    name: "tiny dinos winter 2022 #7270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7270,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7270.png",
  },
  "7271": {
    name: "tiny dinos winter 2022 #7271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7271,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7271.png",
  },
  "7272": {
    name: "tiny dinos winter 2022 #7272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7272,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7272.png",
  },
  "7273": {
    name: "tiny dinos winter 2022 #7273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7273,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7273.png",
  },
  "7274": {
    name: "tiny dinos winter 2022 #7274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7274,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7274.png",
  },
  "7275": {
    name: "tiny dinos winter 2022 #7275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7275,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7275.png",
  },
  "7276": {
    name: "tiny dinos winter 2022 #7276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7276,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7276.png",
  },
  "7277": {
    name: "tiny dinos winter 2022 #7277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7277,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7277.png",
  },
  "7278": {
    name: "tiny dinos winter 2022 #7278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7278,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7278.png",
  },
  "7279": {
    name: "tiny dinos winter 2022 #7279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7279,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7279.png",
  },
  "7280": {
    name: "tiny dinos winter 2022 #7280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7280,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7280.png",
  },
  "7281": {
    name: "tiny dinos winter 2022 #7281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7281,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7281.png",
  },
  "7282": {
    name: "tiny dinos winter 2022 #7282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7282,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7282.png",
  },
  "7283": {
    name: "tiny dinos winter 2022 #7283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7283,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7283.png",
  },
  "7284": {
    name: "tiny dinos winter 2022 #7284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7284,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7284.png",
  },
  "7285": {
    name: "tiny dinos winter 2022 #7285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7285,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7285.png",
  },
  "7286": {
    name: "tiny dinos winter 2022 #7286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7286,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7286.png",
  },
  "7287": {
    name: "tiny dinos winter 2022 #7287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7287,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7287.png",
  },
  "7288": {
    name: "tiny dinos winter 2022 #7288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7288,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7288.png",
  },
  "7289": {
    name: "tiny dinos winter 2022 #7289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7289,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7289.png",
  },
  "7290": {
    name: "tiny dinos winter 2022 #7290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7290,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7290.png",
  },
  "7291": {
    name: "tiny dinos winter 2022 #7291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7291,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7291.png",
  },
  "7292": {
    name: "tiny dinos winter 2022 #7292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7292,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7292.png",
  },
  "7293": {
    name: "tiny dinos winter 2022 #7293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7293,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7293.png",
  },
  "7294": {
    name: "tiny dinos winter 2022 #7294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7294,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7294.png",
  },
  "7295": {
    name: "tiny dinos winter 2022 #7295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7295,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7295.png",
  },
  "7296": {
    name: "tiny dinos winter 2022 #7296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7296,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7296.png",
  },
  "7297": {
    name: "tiny dinos winter 2022 #7297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7297,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7297.png",
  },
  "7298": {
    name: "tiny dinos winter 2022 #7298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7298,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7298.png",
  },
  "7299": {
    name: "tiny dinos winter 2022 #7299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7299,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7299.png",
  },
  "7300": {
    name: "tiny dinos winter 2022 #7300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7300,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7300.png",
  },
  "7301": {
    name: "tiny dinos winter 2022 #7301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7301,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7301.png",
  },
  "7302": {
    name: "tiny dinos winter 2022 #7302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7302,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7302.png",
  },
  "7303": {
    name: "tiny dinos winter 2022 #7303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7303,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7303.png",
  },
  "7304": {
    name: "tiny dinos winter 2022 #7304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7304,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7304.png",
  },
  "7305": {
    name: "tiny dinos winter 2022 #7305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7305,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7305.png",
  },
  "7306": {
    name: "tiny dinos winter 2022 #7306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7306,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7306.png",
  },
  "7307": {
    name: "tiny dinos winter 2022 #7307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7307,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7307.png",
  },
  "7308": {
    name: "tiny dinos winter 2022 #7308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7308,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7308.png",
  },
  "7309": {
    name: "tiny dinos winter 2022 #7309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7309,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7309.png",
  },
  "7310": {
    name: "tiny dinos winter 2022 #7310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7310,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7310.png",
  },
  "7311": {
    name: "tiny dinos winter 2022 #7311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7311,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7311.png",
  },
  "7312": {
    name: "tiny dinos winter 2022 #7312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7312,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7312.png",
  },
  "7313": {
    name: "tiny dinos winter 2022 #7313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7313,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7313.png",
  },
  "7314": {
    name: "tiny dinos winter 2022 #7314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7314,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7314.png",
  },
  "7315": {
    name: "tiny dinos winter 2022 #7315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7315,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7315.png",
  },
  "7316": {
    name: "tiny dinos winter 2022 #7316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7316,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7316.png",
  },
  "7317": {
    name: "tiny dinos winter 2022 #7317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7317,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7317.png",
  },
  "7318": {
    name: "tiny dinos winter 2022 #7318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7318,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7318.png",
  },
  "7319": {
    name: "tiny dinos winter 2022 #7319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7319,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7319.png",
  },
  "7320": {
    name: "tiny dinos winter 2022 #7320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7320,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7320.png",
  },
  "7321": {
    name: "tiny dinos winter 2022 #7321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7321,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7321.png",
  },
  "7322": {
    name: "tiny dinos winter 2022 #7322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7322,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7322.png",
  },
  "7323": {
    name: "tiny dinos winter 2022 #7323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7323,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7323.png",
  },
  "7324": {
    name: "tiny dinos winter 2022 #7324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7324,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7324.png",
  },
  "7325": {
    name: "tiny dinos winter 2022 #7325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7325,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7325.png",
  },
  "7326": {
    name: "tiny dinos winter 2022 #7326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7326,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7326.png",
  },
  "7327": {
    name: "tiny dinos winter 2022 #7327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7327,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7327.png",
  },
  "7328": {
    name: "tiny dinos winter 2022 #7328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7328,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7328.png",
  },
  "7329": {
    name: "tiny dinos winter 2022 #7329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7329,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7329.png",
  },
  "7330": {
    name: "tiny dinos winter 2022 #7330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7330,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7330.png",
  },
  "7331": {
    name: "tiny dinos winter 2022 #7331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7331,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7331.png",
  },
  "7332": {
    name: "tiny dinos winter 2022 #7332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7332,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7332.png",
  },
  "7333": {
    name: "tiny dinos winter 2022 #7333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7333,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7333.png",
  },
  "7334": {
    name: "tiny dinos winter 2022 #7334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7334,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7334.png",
  },
  "7335": {
    name: "tiny dinos winter 2022 #7335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7335,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7335.png",
  },
  "7336": {
    name: "tiny dinos winter 2022 #7336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7336,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7336.png",
  },
  "7337": {
    name: "tiny dinos winter 2022 #7337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7337,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7337.png",
  },
  "7338": {
    name: "tiny dinos winter 2022 #7338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7338,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7338.png",
  },
  "7339": {
    name: "tiny dinos winter 2022 #7339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7339,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7339.png",
  },
  "7340": {
    name: "tiny dinos winter 2022 #7340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7340,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7340.png",
  },
  "7341": {
    name: "tiny dinos winter 2022 #7341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7341,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7341.png",
  },
  "7342": {
    name: "tiny dinos winter 2022 #7342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7342,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7342.png",
  },
  "7343": {
    name: "tiny dinos winter 2022 #7343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7343,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7343.png",
  },
  "7344": {
    name: "tiny dinos winter 2022 #7344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7344,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7344.png",
  },
  "7345": {
    name: "tiny dinos winter 2022 #7345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7345,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7345.png",
  },
  "7346": {
    name: "tiny dinos winter 2022 #7346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7346,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7346.png",
  },
  "7347": {
    name: "tiny dinos winter 2022 #7347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7347,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7347.png",
  },
  "7348": {
    name: "tiny dinos winter 2022 #7348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7348,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7348.png",
  },
  "7349": {
    name: "tiny dinos winter 2022 #7349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7349,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7349.png",
  },
  "7350": {
    name: "tiny dinos winter 2022 #7350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7350,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7350.png",
  },
  "7351": {
    name: "tiny dinos winter 2022 #7351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7351,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7351.png",
  },
  "7352": {
    name: "tiny dinos winter 2022 #7352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7352,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7352.png",
  },
  "7353": {
    name: "tiny dinos winter 2022 #7353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7353,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7353.png",
  },
  "7354": {
    name: "tiny dinos winter 2022 #7354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7354,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7354.png",
  },
  "7355": {
    name: "tiny dinos winter 2022 #7355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7355,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7355.png",
  },
  "7356": {
    name: "tiny dinos winter 2022 #7356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7356,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7356.png",
  },
  "7357": {
    name: "tiny dinos winter 2022 #7357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7357,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7357.png",
  },
  "7358": {
    name: "tiny dinos winter 2022 #7358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7358,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7358.png",
  },
  "7359": {
    name: "tiny dinos winter 2022 #7359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7359,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7359.png",
  },
  "7360": {
    name: "tiny dinos winter 2022 #7360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7360,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7360.png",
  },
  "7361": {
    name: "tiny dinos winter 2022 #7361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7361,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7361.png",
  },
  "7362": {
    name: "tiny dinos winter 2022 #7362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7362,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7362.png",
  },
  "7363": {
    name: "tiny dinos winter 2022 #7363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7363,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7363.png",
  },
  "7364": {
    name: "tiny dinos winter 2022 #7364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7364,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7364.png",
  },
  "7365": {
    name: "tiny dinos winter 2022 #7365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7365,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7365.png",
  },
  "7366": {
    name: "tiny dinos winter 2022 #7366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7366,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7366.png",
  },
  "7367": {
    name: "tiny dinos winter 2022 #7367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7367,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7367.png",
  },
  "7368": {
    name: "tiny dinos winter 2022 #7368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7368,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7368.png",
  },
  "7369": {
    name: "tiny dinos winter 2022 #7369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7369,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7369.png",
  },
  "7370": {
    name: "tiny dinos winter 2022 #7370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7370,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7370.png",
  },
  "7371": {
    name: "tiny dinos winter 2022 #7371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7371,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7371.png",
  },
  "7372": {
    name: "tiny dinos winter 2022 #7372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7372,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7372.png",
  },
  "7373": {
    name: "tiny dinos winter 2022 #7373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7373,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7373.png",
  },
  "7374": {
    name: "tiny dinos winter 2022 #7374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7374,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7374.png",
  },
  "7375": {
    name: "tiny dinos winter 2022 #7375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7375,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7375.png",
  },
  "7376": {
    name: "tiny dinos winter 2022 #7376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7376,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7376.png",
  },
  "7377": {
    name: "tiny dinos winter 2022 #7377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7377,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7377.png",
  },
  "7378": {
    name: "tiny dinos winter 2022 #7378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7378,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7378.png",
  },
  "7379": {
    name: "tiny dinos winter 2022 #7379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7379,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7379.png",
  },
  "7380": {
    name: "tiny dinos winter 2022 #7380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7380,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7380.png",
  },
  "7381": {
    name: "tiny dinos winter 2022 #7381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7381,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7381.png",
  },
  "7382": {
    name: "tiny dinos winter 2022 #7382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7382,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7382.png",
  },
  "7383": {
    name: "tiny dinos winter 2022 #7383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7383,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7383.png",
  },
  "7384": {
    name: "tiny dinos winter 2022 #7384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7384,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7384.png",
  },
  "7385": {
    name: "tiny dinos winter 2022 #7385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7385,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7385.png",
  },
  "7386": {
    name: "tiny dinos winter 2022 #7386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7386,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7386.png",
  },
  "7387": {
    name: "tiny dinos winter 2022 #7387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7387,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7387.png",
  },
  "7388": {
    name: "tiny dinos winter 2022 #7388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7388,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7388.png",
  },
  "7389": {
    name: "tiny dinos winter 2022 #7389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7389,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7389.png",
  },
  "7390": {
    name: "tiny dinos winter 2022 #7390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7390,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7390.png",
  },
  "7391": {
    name: "tiny dinos winter 2022 #7391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7391,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7391.png",
  },
  "7392": {
    name: "tiny dinos winter 2022 #7392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7392,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7392.png",
  },
  "7393": {
    name: "tiny dinos winter 2022 #7393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7393,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7393.png",
  },
  "7394": {
    name: "tiny dinos winter 2022 #7394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7394,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7394.png",
  },
  "7395": {
    name: "tiny dinos winter 2022 #7395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7395,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7395.png",
  },
  "7396": {
    name: "tiny dinos winter 2022 #7396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7396,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7396.png",
  },
  "7397": {
    name: "tiny dinos winter 2022 #7397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7397,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7397.png",
  },
  "7398": {
    name: "tiny dinos winter 2022 #7398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7398,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7398.png",
  },
  "7399": {
    name: "tiny dinos winter 2022 #7399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7399,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7399.png",
  },
  "7400": {
    name: "tiny dinos winter 2022 #7400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7400,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7400.png",
  },
  "7401": {
    name: "tiny dinos winter 2022 #7401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7401,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7401.png",
  },
  "7402": {
    name: "tiny dinos winter 2022 #7402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7402,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7402.png",
  },
  "7403": {
    name: "tiny dinos winter 2022 #7403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7403,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7403.png",
  },
  "7404": {
    name: "tiny dinos winter 2022 #7404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7404,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7404.png",
  },
  "7405": {
    name: "tiny dinos winter 2022 #7405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7405,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7405.png",
  },
  "7406": {
    name: "tiny dinos winter 2022 #7406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7406,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7406.png",
  },
  "7407": {
    name: "tiny dinos winter 2022 #7407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7407,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7407.png",
  },
  "7408": {
    name: "tiny dinos winter 2022 #7408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7408,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7408.png",
  },
  "7409": {
    name: "tiny dinos winter 2022 #7409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7409,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7409.png",
  },
  "7410": {
    name: "tiny dinos winter 2022 #7410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7410,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7410.png",
  },
  "7411": {
    name: "tiny dinos winter 2022 #7411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7411,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7411.png",
  },
  "7412": {
    name: "tiny dinos winter 2022 #7412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7412,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7412.png",
  },
  "7413": {
    name: "tiny dinos winter 2022 #7413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7413,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7413.png",
  },
  "7414": {
    name: "tiny dinos winter 2022 #7414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7414,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7414.png",
  },
  "7415": {
    name: "tiny dinos winter 2022 #7415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7415,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7415.png",
  },
  "7416": {
    name: "tiny dinos winter 2022 #7416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7416,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7416.png",
  },
  "7417": {
    name: "tiny dinos winter 2022 #7417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7417,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7417.png",
  },
  "7418": {
    name: "tiny dinos winter 2022 #7418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7418,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7418.png",
  },
  "7419": {
    name: "tiny dinos winter 2022 #7419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7419,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7419.png",
  },
  "7420": {
    name: "tiny dinos winter 2022 #7420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7420,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7420.png",
  },
  "7421": {
    name: "tiny dinos winter 2022 #7421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7421,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7421.png",
  },
  "7422": {
    name: "tiny dinos winter 2022 #7422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7422,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7422.png",
  },
  "7423": {
    name: "tiny dinos winter 2022 #7423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7423,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7423.png",
  },
  "7424": {
    name: "tiny dinos winter 2022 #7424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7424,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7424.png",
  },
  "7425": {
    name: "tiny dinos winter 2022 #7425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7425,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7425.png",
  },
  "7426": {
    name: "tiny dinos winter 2022 #7426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7426,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7426.png",
  },
  "7427": {
    name: "tiny dinos winter 2022 #7427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7427,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7427.png",
  },
  "7428": {
    name: "tiny dinos winter 2022 #7428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7428,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7428.png",
  },
  "7429": {
    name: "tiny dinos winter 2022 #7429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7429,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7429.png",
  },
  "7430": {
    name: "tiny dinos winter 2022 #7430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7430,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7430.png",
  },
  "7431": {
    name: "tiny dinos winter 2022 #7431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7431,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7431.png",
  },
  "7432": {
    name: "tiny dinos winter 2022 #7432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7432,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7432.png",
  },
  "7433": {
    name: "tiny dinos winter 2022 #7433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7433,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7433.png",
  },
  "7434": {
    name: "tiny dinos winter 2022 #7434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7434,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7434.png",
  },
  "7435": {
    name: "tiny dinos winter 2022 #7435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7435,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7435.png",
  },
  "7436": {
    name: "tiny dinos winter 2022 #7436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7436,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7436.png",
  },
  "7437": {
    name: "tiny dinos winter 2022 #7437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7437,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7437.png",
  },
  "7438": {
    name: "tiny dinos winter 2022 #7438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7438,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7438.png",
  },
  "7439": {
    name: "tiny dinos winter 2022 #7439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7439,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7439.png",
  },
  "7440": {
    name: "tiny dinos winter 2022 #7440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7440,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7440.png",
  },
  "7441": {
    name: "tiny dinos winter 2022 #7441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7441,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7441.png",
  },
  "7442": {
    name: "tiny dinos winter 2022 #7442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7442,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7442.png",
  },
  "7443": {
    name: "tiny dinos winter 2022 #7443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7443,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7443.png",
  },
  "7444": {
    name: "tiny dinos winter 2022 #7444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7444,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7444.png",
  },
  "7445": {
    name: "tiny dinos winter 2022 #7445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7445,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7445.png",
  },
  "7446": {
    name: "tiny dinos winter 2022 #7446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7446,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7446.png",
  },
  "7447": {
    name: "tiny dinos winter 2022 #7447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7447,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7447.png",
  },
  "7448": {
    name: "tiny dinos winter 2022 #7448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7448,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7448.png",
  },
  "7449": {
    name: "tiny dinos winter 2022 #7449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7449,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7449.png",
  },
  "7450": {
    name: "tiny dinos winter 2022 #7450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7450,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7450.png",
  },
  "7451": {
    name: "tiny dinos winter 2022 #7451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7451,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7451.png",
  },
  "7452": {
    name: "tiny dinos winter 2022 #7452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7452,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7452.png",
  },
  "7453": {
    name: "tiny dinos winter 2022 #7453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7453,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7453.png",
  },
  "7454": {
    name: "tiny dinos winter 2022 #7454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7454,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7454.png",
  },
  "7455": {
    name: "tiny dinos winter 2022 #7455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7455,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7455.png",
  },
  "7456": {
    name: "tiny dinos winter 2022 #7456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7456,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7456.png",
  },
  "7457": {
    name: "tiny dinos winter 2022 #7457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7457,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7457.png",
  },
  "7458": {
    name: "tiny dinos winter 2022 #7458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7458,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7458.png",
  },
  "7459": {
    name: "tiny dinos winter 2022 #7459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7459,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7459.png",
  },
  "7460": {
    name: "tiny dinos winter 2022 #7460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7460,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7460.png",
  },
  "7461": {
    name: "tiny dinos winter 2022 #7461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7461,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7461.png",
  },
  "7462": {
    name: "tiny dinos winter 2022 #7462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7462,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7462.png",
  },
  "7463": {
    name: "tiny dinos winter 2022 #7463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7463,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7463.png",
  },
  "7464": {
    name: "tiny dinos winter 2022 #7464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7464,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7464.png",
  },
  "7465": {
    name: "tiny dinos winter 2022 #7465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7465,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7465.png",
  },
  "7466": {
    name: "tiny dinos winter 2022 #7466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7466,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7466.png",
  },
  "7467": {
    name: "tiny dinos winter 2022 #7467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7467,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7467.png",
  },
  "7468": {
    name: "tiny dinos winter 2022 #7468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7468,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7468.png",
  },
  "7469": {
    name: "tiny dinos winter 2022 #7469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7469,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7469.png",
  },
  "7470": {
    name: "tiny dinos winter 2022 #7470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7470,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7470.png",
  },
  "7471": {
    name: "tiny dinos winter 2022 #7471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7471,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7471.png",
  },
  "7472": {
    name: "tiny dinos winter 2022 #7472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7472,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7472.png",
  },
  "7473": {
    name: "tiny dinos winter 2022 #7473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7473,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7473.png",
  },
  "7474": {
    name: "tiny dinos winter 2022 #7474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7474,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7474.png",
  },
  "7475": {
    name: "tiny dinos winter 2022 #7475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7475,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7475.png",
  },
  "7476": {
    name: "tiny dinos winter 2022 #7476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7476,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7476.png",
  },
  "7477": {
    name: "tiny dinos winter 2022 #7477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7477,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7477.png",
  },
  "7478": {
    name: "tiny dinos winter 2022 #7478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7478,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7478.png",
  },
  "7479": {
    name: "tiny dinos winter 2022 #7479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7479,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7479.png",
  },
  "7480": {
    name: "tiny dinos winter 2022 #7480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7480,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7480.png",
  },
  "7481": {
    name: "tiny dinos winter 2022 #7481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7481,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7481.png",
  },
  "7482": {
    name: "tiny dinos winter 2022 #7482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7482,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7482.png",
  },
  "7483": {
    name: "tiny dinos winter 2022 #7483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7483,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7483.png",
  },
  "7484": {
    name: "tiny dinos winter 2022 #7484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7484,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7484.png",
  },
  "7485": {
    name: "tiny dinos winter 2022 #7485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7485,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7485.png",
  },
  "7486": {
    name: "tiny dinos winter 2022 #7486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7486,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7486.png",
  },
  "7487": {
    name: "tiny dinos winter 2022 #7487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7487,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7487.png",
  },
  "7488": {
    name: "tiny dinos winter 2022 #7488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7488,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7488.png",
  },
  "7489": {
    name: "tiny dinos winter 2022 #7489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7489,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7489.png",
  },
  "7490": {
    name: "tiny dinos winter 2022 #7490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7490,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7490.png",
  },
  "7491": {
    name: "tiny dinos winter 2022 #7491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7491,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7491.png",
  },
  "7492": {
    name: "tiny dinos winter 2022 #7492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7492,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7492.png",
  },
  "7493": {
    name: "tiny dinos winter 2022 #7493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7493,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7493.png",
  },
  "7494": {
    name: "tiny dinos winter 2022 #7494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7494,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7494.png",
  },
  "7495": {
    name: "tiny dinos winter 2022 #7495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7495,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7495.png",
  },
  "7496": {
    name: "tiny dinos winter 2022 #7496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7496,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7496.png",
  },
  "7497": {
    name: "tiny dinos winter 2022 #7497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7497,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7497.png",
  },
  "7498": {
    name: "tiny dinos winter 2022 #7498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7498,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7498.png",
  },
  "7499": {
    name: "tiny dinos winter 2022 #7499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7499,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7499.png",
  },
  "7500": {
    name: "tiny dinos winter 2022 #7500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7500,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7500.png",
  },
  "7501": {
    name: "tiny dinos winter 2022 #7501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7501,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7501.png",
  },
  "7502": {
    name: "tiny dinos winter 2022 #7502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7502,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7502.png",
  },
  "7503": {
    name: "tiny dinos winter 2022 #7503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7503,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7503.png",
  },
  "7504": {
    name: "tiny dinos winter 2022 #7504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7504,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7504.png",
  },
  "7505": {
    name: "tiny dinos winter 2022 #7505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7505,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7505.png",
  },
  "7506": {
    name: "tiny dinos winter 2022 #7506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7506,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7506.png",
  },
  "7507": {
    name: "tiny dinos winter 2022 #7507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7507,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7507.png",
  },
  "7508": {
    name: "tiny dinos winter 2022 #7508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7508,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7508.png",
  },
  "7509": {
    name: "tiny dinos winter 2022 #7509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7509,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7509.png",
  },
  "7510": {
    name: "tiny dinos winter 2022 #7510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7510,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7510.png",
  },
  "7511": {
    name: "tiny dinos winter 2022 #7511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7511,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7511.png",
  },
  "7512": {
    name: "tiny dinos winter 2022 #7512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7512,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7512.png",
  },
  "7513": {
    name: "tiny dinos winter 2022 #7513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7513,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7513.png",
  },
  "7514": {
    name: "tiny dinos winter 2022 #7514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7514,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7514.png",
  },
  "7515": {
    name: "tiny dinos winter 2022 #7515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7515,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7515.png",
  },
  "7516": {
    name: "tiny dinos winter 2022 #7516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7516,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7516.png",
  },
  "7517": {
    name: "tiny dinos winter 2022 #7517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7517,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7517.png",
  },
  "7518": {
    name: "tiny dinos winter 2022 #7518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7518,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7518.png",
  },
  "7519": {
    name: "tiny dinos winter 2022 #7519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7519,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7519.png",
  },
  "7520": {
    name: "tiny dinos winter 2022 #7520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7520,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7520.png",
  },
  "7521": {
    name: "tiny dinos winter 2022 #7521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7521,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7521.png",
  },
  "7522": {
    name: "tiny dinos winter 2022 #7522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7522,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7522.png",
  },
  "7523": {
    name: "tiny dinos winter 2022 #7523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7523,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7523.png",
  },
  "7524": {
    name: "tiny dinos winter 2022 #7524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7524,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7524.png",
  },
  "7525": {
    name: "tiny dinos winter 2022 #7525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7525,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7525.png",
  },
  "7526": {
    name: "tiny dinos winter 2022 #7526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7526,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7526.png",
  },
  "7527": {
    name: "tiny dinos winter 2022 #7527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7527,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7527.png",
  },
  "7528": {
    name: "tiny dinos winter 2022 #7528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7528,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7528.png",
  },
  "7529": {
    name: "tiny dinos winter 2022 #7529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7529,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7529.png",
  },
  "7530": {
    name: "tiny dinos winter 2022 #7530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7530,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7530.png",
  },
  "7531": {
    name: "tiny dinos winter 2022 #7531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7531,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7531.png",
  },
  "7532": {
    name: "tiny dinos winter 2022 #7532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7532,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7532.png",
  },
  "7533": {
    name: "tiny dinos winter 2022 #7533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7533,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7533.png",
  },
  "7534": {
    name: "tiny dinos winter 2022 #7534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7534,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7534.png",
  },
  "7535": {
    name: "tiny dinos winter 2022 #7535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7535,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7535.png",
  },
  "7536": {
    name: "tiny dinos winter 2022 #7536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7536,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7536.png",
  },
  "7537": {
    name: "tiny dinos winter 2022 #7537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7537,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7537.png",
  },
  "7538": {
    name: "tiny dinos winter 2022 #7538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7538,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7538.png",
  },
  "7539": {
    name: "tiny dinos winter 2022 #7539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7539,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7539.png",
  },
  "7540": {
    name: "tiny dinos winter 2022 #7540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7540,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7540.png",
  },
  "7541": {
    name: "tiny dinos winter 2022 #7541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7541,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7541.png",
  },
  "7542": {
    name: "tiny dinos winter 2022 #7542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7542,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7542.png",
  },
  "7543": {
    name: "tiny dinos winter 2022 #7543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7543,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7543.png",
  },
  "7544": {
    name: "tiny dinos winter 2022 #7544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7544,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7544.png",
  },
  "7545": {
    name: "tiny dinos winter 2022 #7545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7545,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7545.png",
  },
  "7546": {
    name: "tiny dinos winter 2022 #7546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7546,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7546.png",
  },
  "7547": {
    name: "tiny dinos winter 2022 #7547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7547,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7547.png",
  },
  "7548": {
    name: "tiny dinos winter 2022 #7548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7548,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7548.png",
  },
  "7549": {
    name: "tiny dinos winter 2022 #7549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7549,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7549.png",
  },
  "7550": {
    name: "tiny dinos winter 2022 #7550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7550,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7550.png",
  },
  "7551": {
    name: "tiny dinos winter 2022 #7551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7551,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7551.png",
  },
  "7552": {
    name: "tiny dinos winter 2022 #7552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7552,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7552.png",
  },
  "7553": {
    name: "tiny dinos winter 2022 #7553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7553,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7553.png",
  },
  "7554": {
    name: "tiny dinos winter 2022 #7554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7554,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7554.png",
  },
  "7555": {
    name: "tiny dinos winter 2022 #7555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7555,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7555.png",
  },
  "7556": {
    name: "tiny dinos winter 2022 #7556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7556,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7556.png",
  },
  "7557": {
    name: "tiny dinos winter 2022 #7557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7557,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7557.png",
  },
  "7558": {
    name: "tiny dinos winter 2022 #7558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7558,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7558.png",
  },
  "7559": {
    name: "tiny dinos winter 2022 #7559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7559,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7559.png",
  },
  "7560": {
    name: "tiny dinos winter 2022 #7560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7560,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7560.png",
  },
  "7561": {
    name: "tiny dinos winter 2022 #7561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7561,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7561.png",
  },
  "7562": {
    name: "tiny dinos winter 2022 #7562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7562,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7562.png",
  },
  "7563": {
    name: "tiny dinos winter 2022 #7563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7563,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7563.png",
  },
  "7564": {
    name: "tiny dinos winter 2022 #7564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7564,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7564.png",
  },
  "7565": {
    name: "tiny dinos winter 2022 #7565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7565,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7565.png",
  },
  "7566": {
    name: "tiny dinos winter 2022 #7566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7566,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7566.png",
  },
  "7567": {
    name: "tiny dinos winter 2022 #7567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7567,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7567.png",
  },
  "7568": {
    name: "tiny dinos winter 2022 #7568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7568,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7568.png",
  },
  "7569": {
    name: "tiny dinos winter 2022 #7569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7569,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7569.png",
  },
  "7570": {
    name: "tiny dinos winter 2022 #7570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7570,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7570.png",
  },
  "7571": {
    name: "tiny dinos winter 2022 #7571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7571,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7571.png",
  },
  "7572": {
    name: "tiny dinos winter 2022 #7572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7572,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7572.png",
  },
  "7573": {
    name: "tiny dinos winter 2022 #7573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7573,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7573.png",
  },
  "7574": {
    name: "tiny dinos winter 2022 #7574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7574,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7574.png",
  },
  "7575": {
    name: "tiny dinos winter 2022 #7575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7575,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7575.png",
  },
  "7576": {
    name: "tiny dinos winter 2022 #7576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7576,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7576.png",
  },
  "7577": {
    name: "tiny dinos winter 2022 #7577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7577,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7577.png",
  },
  "7578": {
    name: "tiny dinos winter 2022 #7578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7578,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7578.png",
  },
  "7579": {
    name: "tiny dinos winter 2022 #7579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7579,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7579.png",
  },
  "7580": {
    name: "tiny dinos winter 2022 #7580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7580,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7580.png",
  },
  "7581": {
    name: "tiny dinos winter 2022 #7581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7581,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7581.png",
  },
  "7582": {
    name: "tiny dinos winter 2022 #7582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7582,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7582.png",
  },
  "7583": {
    name: "tiny dinos winter 2022 #7583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7583,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7583.png",
  },
  "7584": {
    name: "tiny dinos winter 2022 #7584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7584,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7584.png",
  },
  "7585": {
    name: "tiny dinos winter 2022 #7585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7585,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7585.png",
  },
  "7586": {
    name: "tiny dinos winter 2022 #7586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7586,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7586.png",
  },
  "7587": {
    name: "tiny dinos winter 2022 #7587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7587,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7587.png",
  },
  "7588": {
    name: "tiny dinos winter 2022 #7588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7588,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7588.png",
  },
  "7589": {
    name: "tiny dinos winter 2022 #7589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7589,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7589.png",
  },
  "7590": {
    name: "tiny dinos winter 2022 #7590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7590,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7590.png",
  },
  "7591": {
    name: "tiny dinos winter 2022 #7591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7591,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7591.png",
  },
  "7592": {
    name: "tiny dinos winter 2022 #7592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7592,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7592.png",
  },
  "7593": {
    name: "tiny dinos winter 2022 #7593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7593,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7593.png",
  },
  "7594": {
    name: "tiny dinos winter 2022 #7594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7594,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7594.png",
  },
  "7595": {
    name: "tiny dinos winter 2022 #7595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7595,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7595.png",
  },
  "7596": {
    name: "tiny dinos winter 2022 #7596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7596,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7596.png",
  },
  "7597": {
    name: "tiny dinos winter 2022 #7597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7597,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7597.png",
  },
  "7598": {
    name: "tiny dinos winter 2022 #7598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7598,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7598.png",
  },
  "7599": {
    name: "tiny dinos winter 2022 #7599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7599,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7599.png",
  },
  "7600": {
    name: "tiny dinos winter 2022 #7600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7600,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7600.png",
  },
  "7601": {
    name: "tiny dinos winter 2022 #7601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7601,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7601.png",
  },
  "7602": {
    name: "tiny dinos winter 2022 #7602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7602,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7602.png",
  },
  "7603": {
    name: "tiny dinos winter 2022 #7603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7603,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7603.png",
  },
  "7604": {
    name: "tiny dinos winter 2022 #7604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7604,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7604.png",
  },
  "7605": {
    name: "tiny dinos winter 2022 #7605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7605,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7605.png",
  },
  "7606": {
    name: "tiny dinos winter 2022 #7606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7606,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7606.png",
  },
  "7607": {
    name: "tiny dinos winter 2022 #7607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7607,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7607.png",
  },
  "7608": {
    name: "tiny dinos winter 2022 #7608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7608,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7608.png",
  },
  "7609": {
    name: "tiny dinos winter 2022 #7609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7609,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7609.png",
  },
  "7610": {
    name: "tiny dinos winter 2022 #7610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7610,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7610.png",
  },
  "7611": {
    name: "tiny dinos winter 2022 #7611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7611,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7611.png",
  },
  "7612": {
    name: "tiny dinos winter 2022 #7612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7612,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7612.png",
  },
  "7613": {
    name: "tiny dinos winter 2022 #7613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7613,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7613.png",
  },
  "7614": {
    name: "tiny dinos winter 2022 #7614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7614,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7614.png",
  },
  "7615": {
    name: "tiny dinos winter 2022 #7615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7615,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7615.png",
  },
  "7616": {
    name: "tiny dinos winter 2022 #7616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7616,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7616.png",
  },
  "7617": {
    name: "tiny dinos winter 2022 #7617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7617,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7617.png",
  },
  "7618": {
    name: "tiny dinos winter 2022 #7618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7618,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7618.png",
  },
  "7619": {
    name: "tiny dinos winter 2022 #7619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7619,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7619.png",
  },
  "7620": {
    name: "tiny dinos winter 2022 #7620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7620,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7620.png",
  },
  "7621": {
    name: "tiny dinos winter 2022 #7621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7621,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7621.png",
  },
  "7622": {
    name: "tiny dinos winter 2022 #7622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7622,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7622.png",
  },
  "7623": {
    name: "tiny dinos winter 2022 #7623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7623,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7623.png",
  },
  "7624": {
    name: "tiny dinos winter 2022 #7624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7624,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7624.png",
  },
  "7625": {
    name: "tiny dinos winter 2022 #7625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7625,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7625.png",
  },
  "7626": {
    name: "tiny dinos winter 2022 #7626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7626,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7626.png",
  },
  "7627": {
    name: "tiny dinos winter 2022 #7627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7627,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7627.png",
  },
  "7628": {
    name: "tiny dinos winter 2022 #7628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7628,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7628.png",
  },
  "7629": {
    name: "tiny dinos winter 2022 #7629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7629,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7629.png",
  },
  "7630": {
    name: "tiny dinos winter 2022 #7630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7630,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7630.png",
  },
  "7631": {
    name: "tiny dinos winter 2022 #7631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7631,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7631.png",
  },
  "7632": {
    name: "tiny dinos winter 2022 #7632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7632,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7632.png",
  },
  "7633": {
    name: "tiny dinos winter 2022 #7633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7633,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7633.png",
  },
  "7634": {
    name: "tiny dinos winter 2022 #7634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7634,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7634.png",
  },
  "7635": {
    name: "tiny dinos winter 2022 #7635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7635,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7635.png",
  },
  "7636": {
    name: "tiny dinos winter 2022 #7636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7636,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7636.png",
  },
  "7637": {
    name: "tiny dinos winter 2022 #7637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7637,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7637.png",
  },
  "7638": {
    name: "tiny dinos winter 2022 #7638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7638,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7638.png",
  },
  "7639": {
    name: "tiny dinos winter 2022 #7639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7639,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7639.png",
  },
  "7640": {
    name: "tiny dinos winter 2022 #7640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7640,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7640.png",
  },
  "7641": {
    name: "tiny dinos winter 2022 #7641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7641,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7641.png",
  },
  "7642": {
    name: "tiny dinos winter 2022 #7642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7642,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7642.png",
  },
  "7643": {
    name: "tiny dinos winter 2022 #7643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7643,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7643.png",
  },
  "7644": {
    name: "tiny dinos winter 2022 #7644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7644,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7644.png",
  },
  "7645": {
    name: "tiny dinos winter 2022 #7645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7645,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7645.png",
  },
  "7646": {
    name: "tiny dinos winter 2022 #7646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7646,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7646.png",
  },
  "7647": {
    name: "tiny dinos winter 2022 #7647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7647,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7647.png",
  },
  "7648": {
    name: "tiny dinos winter 2022 #7648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7648,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7648.png",
  },
  "7649": {
    name: "tiny dinos winter 2022 #7649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7649,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7649.png",
  },
  "7650": {
    name: "tiny dinos winter 2022 #7650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7650,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7650.png",
  },
  "7651": {
    name: "tiny dinos winter 2022 #7651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7651,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7651.png",
  },
  "7652": {
    name: "tiny dinos winter 2022 #7652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7652,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7652.png",
  },
  "7653": {
    name: "tiny dinos winter 2022 #7653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7653,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7653.png",
  },
  "7654": {
    name: "tiny dinos winter 2022 #7654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7654,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7654.png",
  },
  "7655": {
    name: "tiny dinos winter 2022 #7655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7655,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7655.png",
  },
  "7656": {
    name: "tiny dinos winter 2022 #7656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7656,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7656.png",
  },
  "7657": {
    name: "tiny dinos winter 2022 #7657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7657,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7657.png",
  },
  "7658": {
    name: "tiny dinos winter 2022 #7658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7658,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7658.png",
  },
  "7659": {
    name: "tiny dinos winter 2022 #7659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7659,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7659.png",
  },
  "7660": {
    name: "tiny dinos winter 2022 #7660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7660,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7660.png",
  },
  "7661": {
    name: "tiny dinos winter 2022 #7661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7661,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7661.png",
  },
  "7662": {
    name: "tiny dinos winter 2022 #7662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7662,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7662.png",
  },
  "7663": {
    name: "tiny dinos winter 2022 #7663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7663,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7663.png",
  },
  "7664": {
    name: "tiny dinos winter 2022 #7664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7664,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7664.png",
  },
  "7665": {
    name: "tiny dinos winter 2022 #7665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7665,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7665.png",
  },
  "7666": {
    name: "tiny dinos winter 2022 #7666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7666,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7666.png",
  },
  "7667": {
    name: "tiny dinos winter 2022 #7667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7667,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7667.png",
  },
  "7668": {
    name: "tiny dinos winter 2022 #7668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7668,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7668.png",
  },
  "7669": {
    name: "tiny dinos winter 2022 #7669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7669,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7669.png",
  },
  "7670": {
    name: "tiny dinos winter 2022 #7670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7670,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7670.png",
  },
  "7671": {
    name: "tiny dinos winter 2022 #7671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7671,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7671.png",
  },
  "7672": {
    name: "tiny dinos winter 2022 #7672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7672,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7672.png",
  },
  "7673": {
    name: "tiny dinos winter 2022 #7673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7673,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7673.png",
  },
  "7674": {
    name: "tiny dinos winter 2022 #7674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7674,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7674.png",
  },
  "7675": {
    name: "tiny dinos winter 2022 #7675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7675,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7675.png",
  },
  "7676": {
    name: "tiny dinos winter 2022 #7676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7676,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7676.png",
  },
  "7677": {
    name: "tiny dinos winter 2022 #7677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7677,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7677.png",
  },
  "7678": {
    name: "tiny dinos winter 2022 #7678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7678,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7678.png",
  },
  "7679": {
    name: "tiny dinos winter 2022 #7679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7679,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7679.png",
  },
  "7680": {
    name: "tiny dinos winter 2022 #7680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7680,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7680.png",
  },
  "7681": {
    name: "tiny dinos winter 2022 #7681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7681,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7681.png",
  },
  "7682": {
    name: "tiny dinos winter 2022 #7682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7682,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7682.png",
  },
  "7683": {
    name: "tiny dinos winter 2022 #7683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7683,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7683.png",
  },
  "7684": {
    name: "tiny dinos winter 2022 #7684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7684,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7684.png",
  },
  "7685": {
    name: "tiny dinos winter 2022 #7685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7685,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7685.png",
  },
  "7686": {
    name: "tiny dinos winter 2022 #7686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7686,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7686.png",
  },
  "7687": {
    name: "tiny dinos winter 2022 #7687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7687,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7687.png",
  },
  "7688": {
    name: "tiny dinos winter 2022 #7688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7688,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7688.png",
  },
  "7689": {
    name: "tiny dinos winter 2022 #7689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7689,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7689.png",
  },
  "7690": {
    name: "tiny dinos winter 2022 #7690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7690,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7690.png",
  },
  "7691": {
    name: "tiny dinos winter 2022 #7691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7691,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7691.png",
  },
  "7692": {
    name: "tiny dinos winter 2022 #7692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7692,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7692.png",
  },
  "7693": {
    name: "tiny dinos winter 2022 #7693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7693,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7693.png",
  },
  "7694": {
    name: "tiny dinos winter 2022 #7694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7694,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7694.png",
  },
  "7695": {
    name: "tiny dinos winter 2022 #7695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7695,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7695.png",
  },
  "7696": {
    name: "tiny dinos winter 2022 #7696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7696,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7696.png",
  },
  "7697": {
    name: "tiny dinos winter 2022 #7697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7697,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7697.png",
  },
  "7698": {
    name: "tiny dinos winter 2022 #7698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7698,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7698.png",
  },
  "7699": {
    name: "tiny dinos winter 2022 #7699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7699,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7699.png",
  },
  "7700": {
    name: "tiny dinos winter 2022 #7700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7700,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7700.png",
  },
  "7701": {
    name: "tiny dinos winter 2022 #7701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7701,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7701.png",
  },
  "7702": {
    name: "tiny dinos winter 2022 #7702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7702,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7702.png",
  },
  "7703": {
    name: "tiny dinos winter 2022 #7703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7703,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7703.png",
  },
  "7704": {
    name: "tiny dinos winter 2022 #7704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7704,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7704.png",
  },
  "7705": {
    name: "tiny dinos winter 2022 #7705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7705,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7705.png",
  },
  "7706": {
    name: "tiny dinos winter 2022 #7706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7706,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7706.png",
  },
  "7707": {
    name: "tiny dinos winter 2022 #7707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7707,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7707.png",
  },
  "7708": {
    name: "tiny dinos winter 2022 #7708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7708,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7708.png",
  },
  "7709": {
    name: "tiny dinos winter 2022 #7709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7709,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7709.png",
  },
  "7710": {
    name: "tiny dinos winter 2022 #7710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7710,
    attributes: [
      {
        trait_type: "1/1",
        value: "mfer",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7710.png",
  },
  "7711": {
    name: "tiny dinos winter 2022 #7711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7711,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7711.png",
  },
  "7712": {
    name: "tiny dinos winter 2022 #7712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7712,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7712.png",
  },
  "7713": {
    name: "tiny dinos winter 2022 #7713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7713,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7713.png",
  },
  "7714": {
    name: "tiny dinos winter 2022 #7714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7714,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7714.png",
  },
  "7715": {
    name: "tiny dinos winter 2022 #7715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7715,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7715.png",
  },
  "7716": {
    name: "tiny dinos winter 2022 #7716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7716,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7716.png",
  },
  "7717": {
    name: "tiny dinos winter 2022 #7717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7717,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7717.png",
  },
  "7718": {
    name: "tiny dinos winter 2022 #7718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7718,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7718.png",
  },
  "7719": {
    name: "tiny dinos winter 2022 #7719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7719,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7719.png",
  },
  "7720": {
    name: "tiny dinos winter 2022 #7720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7720,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7720.png",
  },
  "7721": {
    name: "tiny dinos winter 2022 #7721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7721,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7721.png",
  },
  "7722": {
    name: "tiny dinos winter 2022 #7722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7722,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7722.png",
  },
  "7723": {
    name: "tiny dinos winter 2022 #7723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7723,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7723.png",
  },
  "7724": {
    name: "tiny dinos winter 2022 #7724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7724,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7724.png",
  },
  "7725": {
    name: "tiny dinos winter 2022 #7725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7725,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7725.png",
  },
  "7726": {
    name: "tiny dinos winter 2022 #7726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7726,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7726.png",
  },
  "7727": {
    name: "tiny dinos winter 2022 #7727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7727,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7727.png",
  },
  "7728": {
    name: "tiny dinos winter 2022 #7728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7728,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7728.png",
  },
  "7729": {
    name: "tiny dinos winter 2022 #7729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7729,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7729.png",
  },
  "7730": {
    name: "tiny dinos winter 2022 #7730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7730,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7730.png",
  },
  "7731": {
    name: "tiny dinos winter 2022 #7731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7731,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7731.png",
  },
  "7732": {
    name: "tiny dinos winter 2022 #7732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7732,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7732.png",
  },
  "7733": {
    name: "tiny dinos winter 2022 #7733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7733,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7733.png",
  },
  "7734": {
    name: "tiny dinos winter 2022 #7734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7734,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7734.png",
  },
  "7735": {
    name: "tiny dinos winter 2022 #7735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7735,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7735.png",
  },
  "7736": {
    name: "tiny dinos winter 2022 #7736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7736,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7736.png",
  },
  "7737": {
    name: "tiny dinos winter 2022 #7737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7737,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7737.png",
  },
  "7738": {
    name: "tiny dinos winter 2022 #7738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7738,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7738.png",
  },
  "7739": {
    name: "tiny dinos winter 2022 #7739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7739,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7739.png",
  },
  "7740": {
    name: "tiny dinos winter 2022 #7740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7740,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7740.png",
  },
  "7741": {
    name: "tiny dinos winter 2022 #7741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7741,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7741.png",
  },
  "7742": {
    name: "tiny dinos winter 2022 #7742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7742,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7742.png",
  },
  "7743": {
    name: "tiny dinos winter 2022 #7743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7743,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7743.png",
  },
  "7744": {
    name: "tiny dinos winter 2022 #7744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7744,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7744.png",
  },
  "7745": {
    name: "tiny dinos winter 2022 #7745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7745,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7745.png",
  },
  "7746": {
    name: "tiny dinos winter 2022 #7746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7746,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7746.png",
  },
  "7747": {
    name: "tiny dinos winter 2022 #7747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7747,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7747.png",
  },
  "7748": {
    name: "tiny dinos winter 2022 #7748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7748,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7748.png",
  },
  "7749": {
    name: "tiny dinos winter 2022 #7749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7749,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7749.png",
  },
  "7750": {
    name: "tiny dinos winter 2022 #7750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7750,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7750.png",
  },
  "7751": {
    name: "tiny dinos winter 2022 #7751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7751,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7751.png",
  },
  "7752": {
    name: "tiny dinos winter 2022 #7752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7752,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7752.png",
  },
  "7753": {
    name: "tiny dinos winter 2022 #7753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7753,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7753.png",
  },
  "7754": {
    name: "tiny dinos winter 2022 #7754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7754,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7754.png",
  },
  "7755": {
    name: "tiny dinos winter 2022 #7755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7755,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7755.png",
  },
  "7756": {
    name: "tiny dinos winter 2022 #7756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7756,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7756.png",
  },
  "7757": {
    name: "tiny dinos winter 2022 #7757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7757,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7757.png",
  },
  "7758": {
    name: "tiny dinos winter 2022 #7758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7758,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7758.png",
  },
  "7759": {
    name: "tiny dinos winter 2022 #7759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7759,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7759.png",
  },
  "7760": {
    name: "tiny dinos winter 2022 #7760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7760,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7760.png",
  },
  "7761": {
    name: "tiny dinos winter 2022 #7761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7761,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7761.png",
  },
  "7762": {
    name: "tiny dinos winter 2022 #7762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7762,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7762.png",
  },
  "7763": {
    name: "tiny dinos winter 2022 #7763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7763,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7763.png",
  },
  "7764": {
    name: "tiny dinos winter 2022 #7764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7764,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7764.png",
  },
  "7765": {
    name: "tiny dinos winter 2022 #7765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7765,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7765.png",
  },
  "7766": {
    name: "tiny dinos winter 2022 #7766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7766,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7766.png",
  },
  "7767": {
    name: "tiny dinos winter 2022 #7767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7767,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7767.png",
  },
  "7768": {
    name: "tiny dinos winter 2022 #7768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7768,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7768.png",
  },
  "7769": {
    name: "tiny dinos winter 2022 #7769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7769,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7769.png",
  },
  "7770": {
    name: "tiny dinos winter 2022 #7770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7770,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7770.png",
  },
  "7771": {
    name: "tiny dinos winter 2022 #7771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7771,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7771.png",
  },
  "7772": {
    name: "tiny dinos winter 2022 #7772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7772,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7772.png",
  },
  "7773": {
    name: "tiny dinos winter 2022 #7773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7773,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7773.png",
  },
  "7774": {
    name: "tiny dinos winter 2022 #7774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7774,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7774.png",
  },
  "7775": {
    name: "tiny dinos winter 2022 #7775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7775,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7775.png",
  },
  "7776": {
    name: "tiny dinos winter 2022 #7776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7776,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7776.png",
  },
  "7777": {
    name: "tiny dinos winter 2022 #7777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7777,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7777.png",
  },
  "7778": {
    name: "tiny dinos winter 2022 #7778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7778,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7778.png",
  },
  "7779": {
    name: "tiny dinos winter 2022 #7779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7779,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7779.png",
  },
  "7780": {
    name: "tiny dinos winter 2022 #7780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7780,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7780.png",
  },
  "7781": {
    name: "tiny dinos winter 2022 #7781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7781,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7781.png",
  },
  "7782": {
    name: "tiny dinos winter 2022 #7782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7782,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7782.png",
  },
  "7783": {
    name: "tiny dinos winter 2022 #7783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7783,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7783.png",
  },
  "7784": {
    name: "tiny dinos winter 2022 #7784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7784,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7784.png",
  },
  "7785": {
    name: "tiny dinos winter 2022 #7785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7785,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7785.png",
  },
  "7786": {
    name: "tiny dinos winter 2022 #7786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7786,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7786.png",
  },
  "7787": {
    name: "tiny dinos winter 2022 #7787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7787,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7787.png",
  },
  "7788": {
    name: "tiny dinos winter 2022 #7788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7788,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7788.png",
  },
  "7789": {
    name: "tiny dinos winter 2022 #7789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7789,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7789.png",
  },
  "7790": {
    name: "tiny dinos winter 2022 #7790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7790,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7790.png",
  },
  "7791": {
    name: "tiny dinos winter 2022 #7791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7791,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7791.png",
  },
  "7792": {
    name: "tiny dinos winter 2022 #7792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7792,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7792.png",
  },
  "7793": {
    name: "tiny dinos winter 2022 #7793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7793,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7793.png",
  },
  "7794": {
    name: "tiny dinos winter 2022 #7794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7794,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7794.png",
  },
  "7795": {
    name: "tiny dinos winter 2022 #7795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7795,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7795.png",
  },
  "7796": {
    name: "tiny dinos winter 2022 #7796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7796,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7796.png",
  },
  "7797": {
    name: "tiny dinos winter 2022 #7797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7797,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7797.png",
  },
  "7798": {
    name: "tiny dinos winter 2022 #7798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7798,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7798.png",
  },
  "7799": {
    name: "tiny dinos winter 2022 #7799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7799,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7799.png",
  },
  "7800": {
    name: "tiny dinos winter 2022 #7800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7800,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7800.png",
  },
  "7801": {
    name: "tiny dinos winter 2022 #7801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7801,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7801.png",
  },
  "7802": {
    name: "tiny dinos winter 2022 #7802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7802,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7802.png",
  },
  "7803": {
    name: "tiny dinos winter 2022 #7803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7803,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7803.png",
  },
  "7804": {
    name: "tiny dinos winter 2022 #7804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7804,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7804.png",
  },
  "7805": {
    name: "tiny dinos winter 2022 #7805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7805,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7805.png",
  },
  "7806": {
    name: "tiny dinos winter 2022 #7806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7806,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7806.png",
  },
  "7807": {
    name: "tiny dinos winter 2022 #7807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7807,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7807.png",
  },
  "7808": {
    name: "tiny dinos winter 2022 #7808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7808,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7808.png",
  },
  "7809": {
    name: "tiny dinos winter 2022 #7809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7809,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7809.png",
  },
  "7810": {
    name: "tiny dinos winter 2022 #7810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7810,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7810.png",
  },
  "7811": {
    name: "tiny dinos winter 2022 #7811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7811,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7811.png",
  },
  "7812": {
    name: "tiny dinos winter 2022 #7812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7812,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7812.png",
  },
  "7813": {
    name: "tiny dinos winter 2022 #7813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7813,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7813.png",
  },
  "7814": {
    name: "tiny dinos winter 2022 #7814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7814,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7814.png",
  },
  "7815": {
    name: "tiny dinos winter 2022 #7815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7815,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7815.png",
  },
  "7816": {
    name: "tiny dinos winter 2022 #7816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7816,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7816.png",
  },
  "7817": {
    name: "tiny dinos winter 2022 #7817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7817,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7817.png",
  },
  "7818": {
    name: "tiny dinos winter 2022 #7818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7818,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7818.png",
  },
  "7819": {
    name: "tiny dinos winter 2022 #7819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7819,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7819.png",
  },
  "7820": {
    name: "tiny dinos winter 2022 #7820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7820,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7820.png",
  },
  "7821": {
    name: "tiny dinos winter 2022 #7821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7821,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7821.png",
  },
  "7822": {
    name: "tiny dinos winter 2022 #7822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7822,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7822.png",
  },
  "7823": {
    name: "tiny dinos winter 2022 #7823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7823,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7823.png",
  },
  "7824": {
    name: "tiny dinos winter 2022 #7824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7824,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7824.png",
  },
  "7825": {
    name: "tiny dinos winter 2022 #7825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7825,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7825.png",
  },
  "7826": {
    name: "tiny dinos winter 2022 #7826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7826,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7826.png",
  },
  "7827": {
    name: "tiny dinos winter 2022 #7827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7827,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7827.png",
  },
  "7828": {
    name: "tiny dinos winter 2022 #7828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7828,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7828.png",
  },
  "7829": {
    name: "tiny dinos winter 2022 #7829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7829,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7829.png",
  },
  "7830": {
    name: "tiny dinos winter 2022 #7830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7830,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7830.png",
  },
  "7831": {
    name: "tiny dinos winter 2022 #7831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7831,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7831.png",
  },
  "7832": {
    name: "tiny dinos winter 2022 #7832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7832,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7832.png",
  },
  "7833": {
    name: "tiny dinos winter 2022 #7833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7833,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7833.png",
  },
  "7834": {
    name: "tiny dinos winter 2022 #7834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7834,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7834.png",
  },
  "7835": {
    name: "tiny dinos winter 2022 #7835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7835,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7835.png",
  },
  "7836": {
    name: "tiny dinos winter 2022 #7836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7836,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7836.png",
  },
  "7837": {
    name: "tiny dinos winter 2022 #7837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7837,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7837.png",
  },
  "7838": {
    name: "tiny dinos winter 2022 #7838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7838,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7838.png",
  },
  "7839": {
    name: "tiny dinos winter 2022 #7839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7839,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7839.png",
  },
  "7840": {
    name: "tiny dinos winter 2022 #7840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7840,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7840.png",
  },
  "7841": {
    name: "tiny dinos winter 2022 #7841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7841,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7841.png",
  },
  "7842": {
    name: "tiny dinos winter 2022 #7842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7842,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7842.png",
  },
  "7843": {
    name: "tiny dinos winter 2022 #7843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7843,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7843.png",
  },
  "7844": {
    name: "tiny dinos winter 2022 #7844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7844,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7844.png",
  },
  "7845": {
    name: "tiny dinos winter 2022 #7845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7845,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7845.png",
  },
  "7846": {
    name: "tiny dinos winter 2022 #7846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7846,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7846.png",
  },
  "7847": {
    name: "tiny dinos winter 2022 #7847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7847,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7847.png",
  },
  "7848": {
    name: "tiny dinos winter 2022 #7848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7848,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7848.png",
  },
  "7849": {
    name: "tiny dinos winter 2022 #7849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7849,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7849.png",
  },
  "7850": {
    name: "tiny dinos winter 2022 #7850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7850,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7850.png",
  },
  "7851": {
    name: "tiny dinos winter 2022 #7851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7851,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7851.png",
  },
  "7852": {
    name: "tiny dinos winter 2022 #7852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7852,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7852.png",
  },
  "7853": {
    name: "tiny dinos winter 2022 #7853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7853,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7853.png",
  },
  "7854": {
    name: "tiny dinos winter 2022 #7854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7854,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7854.png",
  },
  "7855": {
    name: "tiny dinos winter 2022 #7855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7855,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7855.png",
  },
  "7856": {
    name: "tiny dinos winter 2022 #7856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7856,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7856.png",
  },
  "7857": {
    name: "tiny dinos winter 2022 #7857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7857,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7857.png",
  },
  "7858": {
    name: "tiny dinos winter 2022 #7858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7858,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7858.png",
  },
  "7859": {
    name: "tiny dinos winter 2022 #7859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7859,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7859.png",
  },
  "7860": {
    name: "tiny dinos winter 2022 #7860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7860,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7860.png",
  },
  "7861": {
    name: "tiny dinos winter 2022 #7861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7861,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7861.png",
  },
  "7862": {
    name: "tiny dinos winter 2022 #7862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7862,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7862.png",
  },
  "7863": {
    name: "tiny dinos winter 2022 #7863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7863,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7863.png",
  },
  "7864": {
    name: "tiny dinos winter 2022 #7864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7864,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7864.png",
  },
  "7865": {
    name: "tiny dinos winter 2022 #7865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7865,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7865.png",
  },
  "7866": {
    name: "tiny dinos winter 2022 #7866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7866,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7866.png",
  },
  "7867": {
    name: "tiny dinos winter 2022 #7867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7867,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7867.png",
  },
  "7868": {
    name: "tiny dinos winter 2022 #7868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7868,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7868.png",
  },
  "7869": {
    name: "tiny dinos winter 2022 #7869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7869,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7869.png",
  },
  "7870": {
    name: "tiny dinos winter 2022 #7870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7870,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7870.png",
  },
  "7871": {
    name: "tiny dinos winter 2022 #7871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7871,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7871.png",
  },
  "7872": {
    name: "tiny dinos winter 2022 #7872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7872,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7872.png",
  },
  "7873": {
    name: "tiny dinos winter 2022 #7873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7873,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7873.png",
  },
  "7874": {
    name: "tiny dinos winter 2022 #7874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7874,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7874.png",
  },
  "7875": {
    name: "tiny dinos winter 2022 #7875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7875,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7875.png",
  },
  "7876": {
    name: "tiny dinos winter 2022 #7876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7876,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7876.png",
  },
  "7877": {
    name: "tiny dinos winter 2022 #7877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7877,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7877.png",
  },
  "7878": {
    name: "tiny dinos winter 2022 #7878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7878,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7878.png",
  },
  "7879": {
    name: "tiny dinos winter 2022 #7879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7879,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7879.png",
  },
  "7880": {
    name: "tiny dinos winter 2022 #7880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7880,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7880.png",
  },
  "7881": {
    name: "tiny dinos winter 2022 #7881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7881,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7881.png",
  },
  "7882": {
    name: "tiny dinos winter 2022 #7882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7882,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7882.png",
  },
  "7883": {
    name: "tiny dinos winter 2022 #7883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7883,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7883.png",
  },
  "7884": {
    name: "tiny dinos winter 2022 #7884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7884,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7884.png",
  },
  "7885": {
    name: "tiny dinos winter 2022 #7885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7885,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7885.png",
  },
  "7886": {
    name: "tiny dinos winter 2022 #7886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7886,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7886.png",
  },
  "7887": {
    name: "tiny dinos winter 2022 #7887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7887,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7887.png",
  },
  "7888": {
    name: "tiny dinos winter 2022 #7888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7888,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7888.png",
  },
  "7889": {
    name: "tiny dinos winter 2022 #7889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7889,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7889.png",
  },
  "7890": {
    name: "tiny dinos winter 2022 #7890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7890,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7890.png",
  },
  "7891": {
    name: "tiny dinos winter 2022 #7891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7891,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7891.png",
  },
  "7892": {
    name: "tiny dinos winter 2022 #7892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7892,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7892.png",
  },
  "7893": {
    name: "tiny dinos winter 2022 #7893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7893,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7893.png",
  },
  "7894": {
    name: "tiny dinos winter 2022 #7894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7894,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7894.png",
  },
  "7895": {
    name: "tiny dinos winter 2022 #7895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7895,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7895.png",
  },
  "7896": {
    name: "tiny dinos winter 2022 #7896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7896,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7896.png",
  },
  "7897": {
    name: "tiny dinos winter 2022 #7897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7897,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7897.png",
  },
  "7898": {
    name: "tiny dinos winter 2022 #7898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7898,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7898.png",
  },
  "7899": {
    name: "tiny dinos winter 2022 #7899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7899,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7899.png",
  },
  "7900": {
    name: "tiny dinos winter 2022 #7900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7900,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7900.png",
  },
  "7901": {
    name: "tiny dinos winter 2022 #7901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7901,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7901.png",
  },
  "7902": {
    name: "tiny dinos winter 2022 #7902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7902,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7902.png",
  },
  "7903": {
    name: "tiny dinos winter 2022 #7903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7903,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7903.png",
  },
  "7904": {
    name: "tiny dinos winter 2022 #7904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7904,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7904.png",
  },
  "7905": {
    name: "tiny dinos winter 2022 #7905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7905,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7905.png",
  },
  "7906": {
    name: "tiny dinos winter 2022 #7906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7906,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7906.png",
  },
  "7907": {
    name: "tiny dinos winter 2022 #7907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7907,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7907.png",
  },
  "7908": {
    name: "tiny dinos winter 2022 #7908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7908,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7908.png",
  },
  "7909": {
    name: "tiny dinos winter 2022 #7909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7909,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7909.png",
  },
  "7910": {
    name: "tiny dinos winter 2022 #7910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7910,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7910.png",
  },
  "7911": {
    name: "tiny dinos winter 2022 #7911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7911,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7911.png",
  },
  "7912": {
    name: "tiny dinos winter 2022 #7912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7912,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7912.png",
  },
  "7913": {
    name: "tiny dinos winter 2022 #7913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7913,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7913.png",
  },
  "7914": {
    name: "tiny dinos winter 2022 #7914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7914,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7914.png",
  },
  "7915": {
    name: "tiny dinos winter 2022 #7915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7915,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7915.png",
  },
  "7916": {
    name: "tiny dinos winter 2022 #7916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7916,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7916.png",
  },
  "7917": {
    name: "tiny dinos winter 2022 #7917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7917,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7917.png",
  },
  "7918": {
    name: "tiny dinos winter 2022 #7918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7918,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7918.png",
  },
  "7919": {
    name: "tiny dinos winter 2022 #7919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7919,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7919.png",
  },
  "7920": {
    name: "tiny dinos winter 2022 #7920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7920,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7920.png",
  },
  "7921": {
    name: "tiny dinos winter 2022 #7921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7921,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7921.png",
  },
  "7922": {
    name: "tiny dinos winter 2022 #7922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7922,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7922.png",
  },
  "7923": {
    name: "tiny dinos winter 2022 #7923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7923,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7923.png",
  },
  "7924": {
    name: "tiny dinos winter 2022 #7924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7924,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7924.png",
  },
  "7925": {
    name: "tiny dinos winter 2022 #7925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7925,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7925.png",
  },
  "7926": {
    name: "tiny dinos winter 2022 #7926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7926,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7926.png",
  },
  "7927": {
    name: "tiny dinos winter 2022 #7927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7927,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7927.png",
  },
  "7928": {
    name: "tiny dinos winter 2022 #7928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7928,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7928.png",
  },
  "7929": {
    name: "tiny dinos winter 2022 #7929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7929,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7929.png",
  },
  "7930": {
    name: "tiny dinos winter 2022 #7930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7930,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7930.png",
  },
  "7931": {
    name: "tiny dinos winter 2022 #7931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7931,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7931.png",
  },
  "7932": {
    name: "tiny dinos winter 2022 #7932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7932,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7932.png",
  },
  "7933": {
    name: "tiny dinos winter 2022 #7933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7933,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7933.png",
  },
  "7934": {
    name: "tiny dinos winter 2022 #7934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7934,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7934.png",
  },
  "7935": {
    name: "tiny dinos winter 2022 #7935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7935,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7935.png",
  },
  "7936": {
    name: "tiny dinos winter 2022 #7936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7936,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7936.png",
  },
  "7937": {
    name: "tiny dinos winter 2022 #7937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7937,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7937.png",
  },
  "7938": {
    name: "tiny dinos winter 2022 #7938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7938,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7938.png",
  },
  "7939": {
    name: "tiny dinos winter 2022 #7939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7939,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7939.png",
  },
  "7940": {
    name: "tiny dinos winter 2022 #7940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7940,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7940.png",
  },
  "7941": {
    name: "tiny dinos winter 2022 #7941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7941,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7941.png",
  },
  "7942": {
    name: "tiny dinos winter 2022 #7942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7942,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7942.png",
  },
  "7943": {
    name: "tiny dinos winter 2022 #7943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7943,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7943.png",
  },
  "7944": {
    name: "tiny dinos winter 2022 #7944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7944,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7944.png",
  },
  "7945": {
    name: "tiny dinos winter 2022 #7945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7945,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7945.png",
  },
  "7946": {
    name: "tiny dinos winter 2022 #7946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7946,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7946.png",
  },
  "7947": {
    name: "tiny dinos winter 2022 #7947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7947,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7947.png",
  },
  "7948": {
    name: "tiny dinos winter 2022 #7948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7948,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7948.png",
  },
  "7949": {
    name: "tiny dinos winter 2022 #7949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7949,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7949.png",
  },
  "7950": {
    name: "tiny dinos winter 2022 #7950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7950,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7950.png",
  },
  "7951": {
    name: "tiny dinos winter 2022 #7951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7951,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7951.png",
  },
  "7952": {
    name: "tiny dinos winter 2022 #7952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7952,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7952.png",
  },
  "7953": {
    name: "tiny dinos winter 2022 #7953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7953,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7953.png",
  },
  "7954": {
    name: "tiny dinos winter 2022 #7954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7954,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7954.png",
  },
  "7955": {
    name: "tiny dinos winter 2022 #7955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7955,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7955.png",
  },
  "7956": {
    name: "tiny dinos winter 2022 #7956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7956,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7956.png",
  },
  "7957": {
    name: "tiny dinos winter 2022 #7957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7957,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7957.png",
  },
  "7958": {
    name: "tiny dinos winter 2022 #7958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7958,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7958.png",
  },
  "7959": {
    name: "tiny dinos winter 2022 #7959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7959,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7959.png",
  },
  "7960": {
    name: "tiny dinos winter 2022 #7960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7960,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7960.png",
  },
  "7961": {
    name: "tiny dinos winter 2022 #7961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7961,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7961.png",
  },
  "7962": {
    name: "tiny dinos winter 2022 #7962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7962,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7962.png",
  },
  "7963": {
    name: "tiny dinos winter 2022 #7963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7963,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7963.png",
  },
  "7964": {
    name: "tiny dinos winter 2022 #7964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7964,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7964.png",
  },
  "7965": {
    name: "tiny dinos winter 2022 #7965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7965,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7965.png",
  },
  "7966": {
    name: "tiny dinos winter 2022 #7966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7966,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7966.png",
  },
  "7967": {
    name: "tiny dinos winter 2022 #7967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7967,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7967.png",
  },
  "7968": {
    name: "tiny dinos winter 2022 #7968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7968,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7968.png",
  },
  "7969": {
    name: "tiny dinos winter 2022 #7969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7969,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7969.png",
  },
  "7970": {
    name: "tiny dinos winter 2022 #7970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7970,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7970.png",
  },
  "7971": {
    name: "tiny dinos winter 2022 #7971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7971,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7971.png",
  },
  "7972": {
    name: "tiny dinos winter 2022 #7972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7972,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7972.png",
  },
  "7973": {
    name: "tiny dinos winter 2022 #7973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7973,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7973.png",
  },
  "7974": {
    name: "tiny dinos winter 2022 #7974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7974,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7974.png",
  },
  "7975": {
    name: "tiny dinos winter 2022 #7975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7975,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7975.png",
  },
  "7976": {
    name: "tiny dinos winter 2022 #7976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7976,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7976.png",
  },
  "7977": {
    name: "tiny dinos winter 2022 #7977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7977,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7977.png",
  },
  "7978": {
    name: "tiny dinos winter 2022 #7978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7978,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7978.png",
  },
  "7979": {
    name: "tiny dinos winter 2022 #7979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7979,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7979.png",
  },
  "7980": {
    name: "tiny dinos winter 2022 #7980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7980,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7980.png",
  },
  "7981": {
    name: "tiny dinos winter 2022 #7981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7981,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7981.png",
  },
  "7982": {
    name: "tiny dinos winter 2022 #7982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7982,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7982.png",
  },
  "7983": {
    name: "tiny dinos winter 2022 #7983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7983,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7983.png",
  },
  "7984": {
    name: "tiny dinos winter 2022 #7984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7984,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7984.png",
  },
  "7985": {
    name: "tiny dinos winter 2022 #7985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7985,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7985.png",
  },
  "7986": {
    name: "tiny dinos winter 2022 #7986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7986,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7986.png",
  },
  "7987": {
    name: "tiny dinos winter 2022 #7987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7987,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7987.png",
  },
  "7988": {
    name: "tiny dinos winter 2022 #7988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7988,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7988.png",
  },
  "7989": {
    name: "tiny dinos winter 2022 #7989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7989,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7989.png",
  },
  "7990": {
    name: "tiny dinos winter 2022 #7990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7990,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7990.png",
  },
  "7991": {
    name: "tiny dinos winter 2022 #7991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7991,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7991.png",
  },
  "7992": {
    name: "tiny dinos winter 2022 #7992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7992,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7992.png",
  },
  "7993": {
    name: "tiny dinos winter 2022 #7993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7993,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7993.png",
  },
  "7994": {
    name: "tiny dinos winter 2022 #7994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7994,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7994.png",
  },
  "7995": {
    name: "tiny dinos winter 2022 #7995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7995,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7995.png",
  },
  "7996": {
    name: "tiny dinos winter 2022 #7996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7996,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7996.png",
  },
  "7997": {
    name: "tiny dinos winter 2022 #7997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7997,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7997.png",
  },
  "7998": {
    name: "tiny dinos winter 2022 #7998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7998,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7998.png",
  },
  "7999": {
    name: "tiny dinos winter 2022 #7999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 7999,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/7999.png",
  },
  "8000": {
    name: "tiny dinos winter 2022 #8000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8000,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8000.png",
  },
  "8001": {
    name: "tiny dinos winter 2022 #8001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8001,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8001.png",
  },
  "8002": {
    name: "tiny dinos winter 2022 #8002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8002,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8002.png",
  },
  "8003": {
    name: "tiny dinos winter 2022 #8003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8003,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8003.png",
  },
  "8004": {
    name: "tiny dinos winter 2022 #8004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8004,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8004.png",
  },
  "8005": {
    name: "tiny dinos winter 2022 #8005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8005,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8005.png",
  },
  "8006": {
    name: "tiny dinos winter 2022 #8006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8006,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8006.png",
  },
  "8007": {
    name: "tiny dinos winter 2022 #8007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8007,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8007.png",
  },
  "8008": {
    name: "tiny dinos winter 2022 #8008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8008,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8008.png",
  },
  "8009": {
    name: "tiny dinos winter 2022 #8009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8009,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8009.png",
  },
  "8010": {
    name: "tiny dinos winter 2022 #8010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8010,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8010.png",
  },
  "8011": {
    name: "tiny dinos winter 2022 #8011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8011,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8011.png",
  },
  "8012": {
    name: "tiny dinos winter 2022 #8012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8012,
    attributes: [
      {
        trait_type: "1/1",
        value: "rapter",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8012.png",
  },
  "8013": {
    name: "tiny dinos winter 2022 #8013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8013,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8013.png",
  },
  "8014": {
    name: "tiny dinos winter 2022 #8014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8014,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8014.png",
  },
  "8015": {
    name: "tiny dinos winter 2022 #8015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8015,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8015.png",
  },
  "8016": {
    name: "tiny dinos winter 2022 #8016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8016,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8016.png",
  },
  "8017": {
    name: "tiny dinos winter 2022 #8017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8017,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8017.png",
  },
  "8018": {
    name: "tiny dinos winter 2022 #8018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8018,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8018.png",
  },
  "8019": {
    name: "tiny dinos winter 2022 #8019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8019,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8019.png",
  },
  "8020": {
    name: "tiny dinos winter 2022 #8020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8020,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8020.png",
  },
  "8021": {
    name: "tiny dinos winter 2022 #8021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8021,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8021.png",
  },
  "8022": {
    name: "tiny dinos winter 2022 #8022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8022,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8022.png",
  },
  "8023": {
    name: "tiny dinos winter 2022 #8023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8023,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8023.png",
  },
  "8024": {
    name: "tiny dinos winter 2022 #8024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8024,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8024.png",
  },
  "8025": {
    name: "tiny dinos winter 2022 #8025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8025,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8025.png",
  },
  "8026": {
    name: "tiny dinos winter 2022 #8026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8026,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8026.png",
  },
  "8027": {
    name: "tiny dinos winter 2022 #8027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8027,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8027.png",
  },
  "8028": {
    name: "tiny dinos winter 2022 #8028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8028,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8028.png",
  },
  "8029": {
    name: "tiny dinos winter 2022 #8029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8029,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8029.png",
  },
  "8030": {
    name: "tiny dinos winter 2022 #8030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8030,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8030.png",
  },
  "8031": {
    name: "tiny dinos winter 2022 #8031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8031,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8031.png",
  },
  "8032": {
    name: "tiny dinos winter 2022 #8032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8032,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8032.png",
  },
  "8033": {
    name: "tiny dinos winter 2022 #8033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8033,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8033.png",
  },
  "8034": {
    name: "tiny dinos winter 2022 #8034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8034,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8034.png",
  },
  "8035": {
    name: "tiny dinos winter 2022 #8035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8035,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8035.png",
  },
  "8036": {
    name: "tiny dinos winter 2022 #8036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8036,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8036.png",
  },
  "8037": {
    name: "tiny dinos winter 2022 #8037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8037,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8037.png",
  },
  "8038": {
    name: "tiny dinos winter 2022 #8038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8038,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8038.png",
  },
  "8039": {
    name: "tiny dinos winter 2022 #8039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8039,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8039.png",
  },
  "8040": {
    name: "tiny dinos winter 2022 #8040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8040,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8040.png",
  },
  "8041": {
    name: "tiny dinos winter 2022 #8041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8041,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8041.png",
  },
  "8042": {
    name: "tiny dinos winter 2022 #8042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8042,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8042.png",
  },
  "8043": {
    name: "tiny dinos winter 2022 #8043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8043,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8043.png",
  },
  "8044": {
    name: "tiny dinos winter 2022 #8044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8044,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8044.png",
  },
  "8045": {
    name: "tiny dinos winter 2022 #8045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8045,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8045.png",
  },
  "8046": {
    name: "tiny dinos winter 2022 #8046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8046,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8046.png",
  },
  "8047": {
    name: "tiny dinos winter 2022 #8047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8047,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8047.png",
  },
  "8048": {
    name: "tiny dinos winter 2022 #8048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8048,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8048.png",
  },
  "8049": {
    name: "tiny dinos winter 2022 #8049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8049,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8049.png",
  },
  "8050": {
    name: "tiny dinos winter 2022 #8050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8050,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8050.png",
  },
  "8051": {
    name: "tiny dinos winter 2022 #8051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8051,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8051.png",
  },
  "8052": {
    name: "tiny dinos winter 2022 #8052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8052,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8052.png",
  },
  "8053": {
    name: "tiny dinos winter 2022 #8053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8053,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8053.png",
  },
  "8054": {
    name: "tiny dinos winter 2022 #8054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8054,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8054.png",
  },
  "8055": {
    name: "tiny dinos winter 2022 #8055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8055,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8055.png",
  },
  "8056": {
    name: "tiny dinos winter 2022 #8056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8056,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8056.png",
  },
  "8057": {
    name: "tiny dinos winter 2022 #8057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8057,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8057.png",
  },
  "8058": {
    name: "tiny dinos winter 2022 #8058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8058,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8058.png",
  },
  "8059": {
    name: "tiny dinos winter 2022 #8059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8059,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8059.png",
  },
  "8060": {
    name: "tiny dinos winter 2022 #8060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8060,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8060.png",
  },
  "8061": {
    name: "tiny dinos winter 2022 #8061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8061,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8061.png",
  },
  "8062": {
    name: "tiny dinos winter 2022 #8062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8062,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8062.png",
  },
  "8063": {
    name: "tiny dinos winter 2022 #8063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8063,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8063.png",
  },
  "8064": {
    name: "tiny dinos winter 2022 #8064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8064,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8064.png",
  },
  "8065": {
    name: "tiny dinos winter 2022 #8065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8065,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8065.png",
  },
  "8066": {
    name: "tiny dinos winter 2022 #8066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8066,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8066.png",
  },
  "8067": {
    name: "tiny dinos winter 2022 #8067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8067,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8067.png",
  },
  "8068": {
    name: "tiny dinos winter 2022 #8068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8068,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8068.png",
  },
  "8069": {
    name: "tiny dinos winter 2022 #8069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8069,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8069.png",
  },
  "8070": {
    name: "tiny dinos winter 2022 #8070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8070,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8070.png",
  },
  "8071": {
    name: "tiny dinos winter 2022 #8071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8071,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8071.png",
  },
  "8072": {
    name: "tiny dinos winter 2022 #8072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8072,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8072.png",
  },
  "8073": {
    name: "tiny dinos winter 2022 #8073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8073,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8073.png",
  },
  "8074": {
    name: "tiny dinos winter 2022 #8074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8074,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8074.png",
  },
  "8075": {
    name: "tiny dinos winter 2022 #8075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8075,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8075.png",
  },
  "8076": {
    name: "tiny dinos winter 2022 #8076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8076,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8076.png",
  },
  "8077": {
    name: "tiny dinos winter 2022 #8077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8077,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8077.png",
  },
  "8078": {
    name: "tiny dinos winter 2022 #8078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8078,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8078.png",
  },
  "8079": {
    name: "tiny dinos winter 2022 #8079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8079,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8079.png",
  },
  "8080": {
    name: "tiny dinos winter 2022 #8080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8080,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8080.png",
  },
  "8081": {
    name: "tiny dinos winter 2022 #8081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8081,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8081.png",
  },
  "8082": {
    name: "tiny dinos winter 2022 #8082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8082,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8082.png",
  },
  "8083": {
    name: "tiny dinos winter 2022 #8083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8083,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8083.png",
  },
  "8084": {
    name: "tiny dinos winter 2022 #8084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8084,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8084.png",
  },
  "8085": {
    name: "tiny dinos winter 2022 #8085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8085,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8085.png",
  },
  "8086": {
    name: "tiny dinos winter 2022 #8086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8086,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8086.png",
  },
  "8087": {
    name: "tiny dinos winter 2022 #8087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8087,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8087.png",
  },
  "8088": {
    name: "tiny dinos winter 2022 #8088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8088,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8088.png",
  },
  "8089": {
    name: "tiny dinos winter 2022 #8089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8089,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8089.png",
  },
  "8090": {
    name: "tiny dinos winter 2022 #8090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8090,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8090.png",
  },
  "8091": {
    name: "tiny dinos winter 2022 #8091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8091,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8091.png",
  },
  "8092": {
    name: "tiny dinos winter 2022 #8092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8092,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8092.png",
  },
  "8093": {
    name: "tiny dinos winter 2022 #8093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8093,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8093.png",
  },
  "8094": {
    name: "tiny dinos winter 2022 #8094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8094,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8094.png",
  },
  "8095": {
    name: "tiny dinos winter 2022 #8095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8095,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8095.png",
  },
  "8096": {
    name: "tiny dinos winter 2022 #8096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8096,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8096.png",
  },
  "8097": {
    name: "tiny dinos winter 2022 #8097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8097,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8097.png",
  },
  "8098": {
    name: "tiny dinos winter 2022 #8098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8098,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8098.png",
  },
  "8099": {
    name: "tiny dinos winter 2022 #8099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8099,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8099.png",
  },
  "8100": {
    name: "tiny dinos winter 2022 #8100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8100,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8100.png",
  },
  "8101": {
    name: "tiny dinos winter 2022 #8101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8101,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8101.png",
  },
  "8102": {
    name: "tiny dinos winter 2022 #8102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8102,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8102.png",
  },
  "8103": {
    name: "tiny dinos winter 2022 #8103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8103,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8103.png",
  },
  "8104": {
    name: "tiny dinos winter 2022 #8104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8104,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8104.png",
  },
  "8105": {
    name: "tiny dinos winter 2022 #8105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8105,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8105.png",
  },
  "8106": {
    name: "tiny dinos winter 2022 #8106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8106,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8106.png",
  },
  "8107": {
    name: "tiny dinos winter 2022 #8107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8107,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8107.png",
  },
  "8108": {
    name: "tiny dinos winter 2022 #8108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8108,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8108.png",
  },
  "8109": {
    name: "tiny dinos winter 2022 #8109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8109,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8109.png",
  },
  "8110": {
    name: "tiny dinos winter 2022 #8110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8110,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8110.png",
  },
  "8111": {
    name: "tiny dinos winter 2022 #8111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8111,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8111.png",
  },
  "8112": {
    name: "tiny dinos winter 2022 #8112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8112,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8112.png",
  },
  "8113": {
    name: "tiny dinos winter 2022 #8113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8113,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8113.png",
  },
  "8114": {
    name: "tiny dinos winter 2022 #8114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8114,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8114.png",
  },
  "8115": {
    name: "tiny dinos winter 2022 #8115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8115,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8115.png",
  },
  "8116": {
    name: "tiny dinos winter 2022 #8116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8116,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8116.png",
  },
  "8117": {
    name: "tiny dinos winter 2022 #8117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8117,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8117.png",
  },
  "8118": {
    name: "tiny dinos winter 2022 #8118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8118,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8118.png",
  },
  "8119": {
    name: "tiny dinos winter 2022 #8119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8119,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8119.png",
  },
  "8120": {
    name: "tiny dinos winter 2022 #8120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8120,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8120.png",
  },
  "8121": {
    name: "tiny dinos winter 2022 #8121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8121,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8121.png",
  },
  "8122": {
    name: "tiny dinos winter 2022 #8122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8122,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8122.png",
  },
  "8123": {
    name: "tiny dinos winter 2022 #8123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8123,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8123.png",
  },
  "8124": {
    name: "tiny dinos winter 2022 #8124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8124,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8124.png",
  },
  "8125": {
    name: "tiny dinos winter 2022 #8125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8125,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8125.png",
  },
  "8126": {
    name: "tiny dinos winter 2022 #8126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8126,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8126.png",
  },
  "8127": {
    name: "tiny dinos winter 2022 #8127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8127,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8127.png",
  },
  "8128": {
    name: "tiny dinos winter 2022 #8128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8128,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8128.png",
  },
  "8129": {
    name: "tiny dinos winter 2022 #8129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8129,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8129.png",
  },
  "8130": {
    name: "tiny dinos winter 2022 #8130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8130,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8130.png",
  },
  "8131": {
    name: "tiny dinos winter 2022 #8131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8131,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8131.png",
  },
  "8132": {
    name: "tiny dinos winter 2022 #8132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8132,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8132.png",
  },
  "8133": {
    name: "tiny dinos winter 2022 #8133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8133,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8133.png",
  },
  "8134": {
    name: "tiny dinos winter 2022 #8134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8134,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8134.png",
  },
  "8135": {
    name: "tiny dinos winter 2022 #8135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8135,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8135.png",
  },
  "8136": {
    name: "tiny dinos winter 2022 #8136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8136,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8136.png",
  },
  "8137": {
    name: "tiny dinos winter 2022 #8137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8137,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8137.png",
  },
  "8138": {
    name: "tiny dinos winter 2022 #8138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8138,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8138.png",
  },
  "8139": {
    name: "tiny dinos winter 2022 #8139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8139,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8139.png",
  },
  "8140": {
    name: "tiny dinos winter 2022 #8140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8140,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8140.png",
  },
  "8141": {
    name: "tiny dinos winter 2022 #8141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8141,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8141.png",
  },
  "8142": {
    name: "tiny dinos winter 2022 #8142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8142,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8142.png",
  },
  "8143": {
    name: "tiny dinos winter 2022 #8143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8143,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8143.png",
  },
  "8144": {
    name: "tiny dinos winter 2022 #8144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8144,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8144.png",
  },
  "8145": {
    name: "tiny dinos winter 2022 #8145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8145,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8145.png",
  },
  "8146": {
    name: "tiny dinos winter 2022 #8146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8146,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8146.png",
  },
  "8147": {
    name: "tiny dinos winter 2022 #8147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8147,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8147.png",
  },
  "8148": {
    name: "tiny dinos winter 2022 #8148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8148,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8148.png",
  },
  "8149": {
    name: "tiny dinos winter 2022 #8149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8149,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8149.png",
  },
  "8150": {
    name: "tiny dinos winter 2022 #8150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8150,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8150.png",
  },
  "8151": {
    name: "tiny dinos winter 2022 #8151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8151,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8151.png",
  },
  "8152": {
    name: "tiny dinos winter 2022 #8152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8152,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8152.png",
  },
  "8153": {
    name: "tiny dinos winter 2022 #8153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8153,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8153.png",
  },
  "8154": {
    name: "tiny dinos winter 2022 #8154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8154,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8154.png",
  },
  "8155": {
    name: "tiny dinos winter 2022 #8155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8155,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8155.png",
  },
  "8156": {
    name: "tiny dinos winter 2022 #8156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8156,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8156.png",
  },
  "8157": {
    name: "tiny dinos winter 2022 #8157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8157,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8157.png",
  },
  "8158": {
    name: "tiny dinos winter 2022 #8158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8158,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8158.png",
  },
  "8159": {
    name: "tiny dinos winter 2022 #8159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8159,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8159.png",
  },
  "8160": {
    name: "tiny dinos winter 2022 #8160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8160,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8160.png",
  },
  "8161": {
    name: "tiny dinos winter 2022 #8161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8161,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8161.png",
  },
  "8162": {
    name: "tiny dinos winter 2022 #8162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8162,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8162.png",
  },
  "8163": {
    name: "tiny dinos winter 2022 #8163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8163,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8163.png",
  },
  "8164": {
    name: "tiny dinos winter 2022 #8164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8164,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8164.png",
  },
  "8165": {
    name: "tiny dinos winter 2022 #8165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8165,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8165.png",
  },
  "8166": {
    name: "tiny dinos winter 2022 #8166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8166,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8166.png",
  },
  "8167": {
    name: "tiny dinos winter 2022 #8167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8167,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8167.png",
  },
  "8168": {
    name: "tiny dinos winter 2022 #8168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8168,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8168.png",
  },
  "8169": {
    name: "tiny dinos winter 2022 #8169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8169,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8169.png",
  },
  "8170": {
    name: "tiny dinos winter 2022 #8170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8170,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8170.png",
  },
  "8171": {
    name: "tiny dinos winter 2022 #8171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8171,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8171.png",
  },
  "8172": {
    name: "tiny dinos winter 2022 #8172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8172,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8172.png",
  },
  "8173": {
    name: "tiny dinos winter 2022 #8173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8173,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8173.png",
  },
  "8174": {
    name: "tiny dinos winter 2022 #8174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8174,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8174.png",
  },
  "8175": {
    name: "tiny dinos winter 2022 #8175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8175,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8175.png",
  },
  "8176": {
    name: "tiny dinos winter 2022 #8176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8176,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8176.png",
  },
  "8177": {
    name: "tiny dinos winter 2022 #8177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8177,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8177.png",
  },
  "8178": {
    name: "tiny dinos winter 2022 #8178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8178,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8178.png",
  },
  "8179": {
    name: "tiny dinos winter 2022 #8179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8179,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8179.png",
  },
  "8180": {
    name: "tiny dinos winter 2022 #8180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8180,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8180.png",
  },
  "8181": {
    name: "tiny dinos winter 2022 #8181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8181,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8181.png",
  },
  "8182": {
    name: "tiny dinos winter 2022 #8182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8182,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8182.png",
  },
  "8183": {
    name: "tiny dinos winter 2022 #8183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8183,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8183.png",
  },
  "8184": {
    name: "tiny dinos winter 2022 #8184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8184,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8184.png",
  },
  "8185": {
    name: "tiny dinos winter 2022 #8185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8185,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8185.png",
  },
  "8186": {
    name: "tiny dinos winter 2022 #8186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8186,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8186.png",
  },
  "8187": {
    name: "tiny dinos winter 2022 #8187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8187,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8187.png",
  },
  "8188": {
    name: "tiny dinos winter 2022 #8188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8188,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8188.png",
  },
  "8189": {
    name: "tiny dinos winter 2022 #8189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8189,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8189.png",
  },
  "8190": {
    name: "tiny dinos winter 2022 #8190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8190,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8190.png",
  },
  "8191": {
    name: "tiny dinos winter 2022 #8191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8191,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8191.png",
  },
  "8192": {
    name: "tiny dinos winter 2022 #8192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8192,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8192.png",
  },
  "8193": {
    name: "tiny dinos winter 2022 #8193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8193,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8193.png",
  },
  "8194": {
    name: "tiny dinos winter 2022 #8194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8194,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8194.png",
  },
  "8195": {
    name: "tiny dinos winter 2022 #8195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8195,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8195.png",
  },
  "8196": {
    name: "tiny dinos winter 2022 #8196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8196,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8196.png",
  },
  "8197": {
    name: "tiny dinos winter 2022 #8197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8197,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8197.png",
  },
  "8198": {
    name: "tiny dinos winter 2022 #8198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8198,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8198.png",
  },
  "8199": {
    name: "tiny dinos winter 2022 #8199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8199,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8199.png",
  },
  "8200": {
    name: "tiny dinos winter 2022 #8200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8200,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8200.png",
  },
  "8201": {
    name: "tiny dinos winter 2022 #8201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8201,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8201.png",
  },
  "8202": {
    name: "tiny dinos winter 2022 #8202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8202,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8202.png",
  },
  "8203": {
    name: "tiny dinos winter 2022 #8203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8203,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8203.png",
  },
  "8204": {
    name: "tiny dinos winter 2022 #8204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8204,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8204.png",
  },
  "8205": {
    name: "tiny dinos winter 2022 #8205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8205,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8205.png",
  },
  "8206": {
    name: "tiny dinos winter 2022 #8206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8206,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8206.png",
  },
  "8207": {
    name: "tiny dinos winter 2022 #8207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8207,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8207.png",
  },
  "8208": {
    name: "tiny dinos winter 2022 #8208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8208,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8208.png",
  },
  "8209": {
    name: "tiny dinos winter 2022 #8209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8209,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8209.png",
  },
  "8210": {
    name: "tiny dinos winter 2022 #8210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8210,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8210.png",
  },
  "8211": {
    name: "tiny dinos winter 2022 #8211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8211,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8211.png",
  },
  "8212": {
    name: "tiny dinos winter 2022 #8212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8212,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8212.png",
  },
  "8213": {
    name: "tiny dinos winter 2022 #8213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8213,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8213.png",
  },
  "8214": {
    name: "tiny dinos winter 2022 #8214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8214,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8214.png",
  },
  "8215": {
    name: "tiny dinos winter 2022 #8215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8215,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8215.png",
  },
  "8216": {
    name: "tiny dinos winter 2022 #8216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8216,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8216.png",
  },
  "8217": {
    name: "tiny dinos winter 2022 #8217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8217,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8217.png",
  },
  "8218": {
    name: "tiny dinos winter 2022 #8218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8218,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8218.png",
  },
  "8219": {
    name: "tiny dinos winter 2022 #8219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8219,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8219.png",
  },
  "8220": {
    name: "tiny dinos winter 2022 #8220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8220,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8220.png",
  },
  "8221": {
    name: "tiny dinos winter 2022 #8221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8221,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8221.png",
  },
  "8222": {
    name: "tiny dinos winter 2022 #8222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8222,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8222.png",
  },
  "8223": {
    name: "tiny dinos winter 2022 #8223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8223,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8223.png",
  },
  "8224": {
    name: "tiny dinos winter 2022 #8224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8224,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8224.png",
  },
  "8225": {
    name: "tiny dinos winter 2022 #8225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8225,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8225.png",
  },
  "8226": {
    name: "tiny dinos winter 2022 #8226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8226,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8226.png",
  },
  "8227": {
    name: "tiny dinos winter 2022 #8227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8227,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8227.png",
  },
  "8228": {
    name: "tiny dinos winter 2022 #8228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8228,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8228.png",
  },
  "8229": {
    name: "tiny dinos winter 2022 #8229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8229,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8229.png",
  },
  "8230": {
    name: "tiny dinos winter 2022 #8230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8230,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8230.png",
  },
  "8231": {
    name: "tiny dinos winter 2022 #8231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8231,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8231.png",
  },
  "8232": {
    name: "tiny dinos winter 2022 #8232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8232,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8232.png",
  },
  "8233": {
    name: "tiny dinos winter 2022 #8233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8233,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8233.png",
  },
  "8234": {
    name: "tiny dinos winter 2022 #8234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8234,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8234.png",
  },
  "8235": {
    name: "tiny dinos winter 2022 #8235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8235,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8235.png",
  },
  "8236": {
    name: "tiny dinos winter 2022 #8236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8236,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8236.png",
  },
  "8237": {
    name: "tiny dinos winter 2022 #8237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8237,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8237.png",
  },
  "8238": {
    name: "tiny dinos winter 2022 #8238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8238,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8238.png",
  },
  "8239": {
    name: "tiny dinos winter 2022 #8239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8239,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8239.png",
  },
  "8240": {
    name: "tiny dinos winter 2022 #8240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8240,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8240.png",
  },
  "8241": {
    name: "tiny dinos winter 2022 #8241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8241,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8241.png",
  },
  "8242": {
    name: "tiny dinos winter 2022 #8242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8242,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8242.png",
  },
  "8243": {
    name: "tiny dinos winter 2022 #8243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8243,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8243.png",
  },
  "8244": {
    name: "tiny dinos winter 2022 #8244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8244,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8244.png",
  },
  "8245": {
    name: "tiny dinos winter 2022 #8245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8245,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8245.png",
  },
  "8246": {
    name: "tiny dinos winter 2022 #8246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8246,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8246.png",
  },
  "8247": {
    name: "tiny dinos winter 2022 #8247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8247,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8247.png",
  },
  "8248": {
    name: "tiny dinos winter 2022 #8248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8248,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8248.png",
  },
  "8249": {
    name: "tiny dinos winter 2022 #8249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8249,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8249.png",
  },
  "8250": {
    name: "tiny dinos winter 2022 #8250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8250,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8250.png",
  },
  "8251": {
    name: "tiny dinos winter 2022 #8251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8251,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8251.png",
  },
  "8252": {
    name: "tiny dinos winter 2022 #8252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8252,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8252.png",
  },
  "8253": {
    name: "tiny dinos winter 2022 #8253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8253,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8253.png",
  },
  "8254": {
    name: "tiny dinos winter 2022 #8254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8254,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8254.png",
  },
  "8255": {
    name: "tiny dinos winter 2022 #8255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8255,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8255.png",
  },
  "8256": {
    name: "tiny dinos winter 2022 #8256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8256,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8256.png",
  },
  "8257": {
    name: "tiny dinos winter 2022 #8257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8257,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8257.png",
  },
  "8258": {
    name: "tiny dinos winter 2022 #8258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8258,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8258.png",
  },
  "8259": {
    name: "tiny dinos winter 2022 #8259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8259,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8259.png",
  },
  "8260": {
    name: "tiny dinos winter 2022 #8260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8260,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8260.png",
  },
  "8261": {
    name: "tiny dinos winter 2022 #8261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8261,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8261.png",
  },
  "8262": {
    name: "tiny dinos winter 2022 #8262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8262,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8262.png",
  },
  "8263": {
    name: "tiny dinos winter 2022 #8263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8263,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8263.png",
  },
  "8264": {
    name: "tiny dinos winter 2022 #8264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8264,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8264.png",
  },
  "8265": {
    name: "tiny dinos winter 2022 #8265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8265,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8265.png",
  },
  "8266": {
    name: "tiny dinos winter 2022 #8266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8266,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8266.png",
  },
  "8267": {
    name: "tiny dinos winter 2022 #8267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8267,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8267.png",
  },
  "8268": {
    name: "tiny dinos winter 2022 #8268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8268,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8268.png",
  },
  "8269": {
    name: "tiny dinos winter 2022 #8269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8269,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8269.png",
  },
  "8270": {
    name: "tiny dinos winter 2022 #8270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8270,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8270.png",
  },
  "8271": {
    name: "tiny dinos winter 2022 #8271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8271,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8271.png",
  },
  "8272": {
    name: "tiny dinos winter 2022 #8272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8272,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8272.png",
  },
  "8273": {
    name: "tiny dinos winter 2022 #8273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8273,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8273.png",
  },
  "8274": {
    name: "tiny dinos winter 2022 #8274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8274,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8274.png",
  },
  "8275": {
    name: "tiny dinos winter 2022 #8275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8275,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8275.png",
  },
  "8276": {
    name: "tiny dinos winter 2022 #8276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8276,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8276.png",
  },
  "8277": {
    name: "tiny dinos winter 2022 #8277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8277,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8277.png",
  },
  "8278": {
    name: "tiny dinos winter 2022 #8278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8278,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8278.png",
  },
  "8279": {
    name: "tiny dinos winter 2022 #8279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8279,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8279.png",
  },
  "8280": {
    name: "tiny dinos winter 2022 #8280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8280,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8280.png",
  },
  "8281": {
    name: "tiny dinos winter 2022 #8281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8281,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8281.png",
  },
  "8282": {
    name: "tiny dinos winter 2022 #8282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8282,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8282.png",
  },
  "8283": {
    name: "tiny dinos winter 2022 #8283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8283,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8283.png",
  },
  "8284": {
    name: "tiny dinos winter 2022 #8284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8284,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8284.png",
  },
  "8285": {
    name: "tiny dinos winter 2022 #8285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8285,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8285.png",
  },
  "8286": {
    name: "tiny dinos winter 2022 #8286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8286,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8286.png",
  },
  "8287": {
    name: "tiny dinos winter 2022 #8287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8287,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8287.png",
  },
  "8288": {
    name: "tiny dinos winter 2022 #8288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8288,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8288.png",
  },
  "8289": {
    name: "tiny dinos winter 2022 #8289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8289,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8289.png",
  },
  "8290": {
    name: "tiny dinos winter 2022 #8290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8290,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8290.png",
  },
  "8291": {
    name: "tiny dinos winter 2022 #8291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8291,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8291.png",
  },
  "8292": {
    name: "tiny dinos winter 2022 #8292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8292,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8292.png",
  },
  "8293": {
    name: "tiny dinos winter 2022 #8293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8293,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8293.png",
  },
  "8294": {
    name: "tiny dinos winter 2022 #8294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8294,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8294.png",
  },
  "8295": {
    name: "tiny dinos winter 2022 #8295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8295,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8295.png",
  },
  "8296": {
    name: "tiny dinos winter 2022 #8296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8296,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8296.png",
  },
  "8297": {
    name: "tiny dinos winter 2022 #8297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8297,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8297.png",
  },
  "8298": {
    name: "tiny dinos winter 2022 #8298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8298,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8298.png",
  },
  "8299": {
    name: "tiny dinos winter 2022 #8299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8299,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8299.png",
  },
  "8300": {
    name: "tiny dinos winter 2022 #8300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8300,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8300.png",
  },
  "8301": {
    name: "tiny dinos winter 2022 #8301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8301,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8301.png",
  },
  "8302": {
    name: "tiny dinos winter 2022 #8302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8302,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8302.png",
  },
  "8303": {
    name: "tiny dinos winter 2022 #8303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8303,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8303.png",
  },
  "8304": {
    name: "tiny dinos winter 2022 #8304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8304,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8304.png",
  },
  "8305": {
    name: "tiny dinos winter 2022 #8305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8305,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8305.png",
  },
  "8306": {
    name: "tiny dinos winter 2022 #8306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8306,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8306.png",
  },
  "8307": {
    name: "tiny dinos winter 2022 #8307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8307,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8307.png",
  },
  "8308": {
    name: "tiny dinos winter 2022 #8308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8308,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8308.png",
  },
  "8309": {
    name: "tiny dinos winter 2022 #8309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8309,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8309.png",
  },
  "8310": {
    name: "tiny dinos winter 2022 #8310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8310,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8310.png",
  },
  "8311": {
    name: "tiny dinos winter 2022 #8311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8311,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8311.png",
  },
  "8312": {
    name: "tiny dinos winter 2022 #8312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8312,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8312.png",
  },
  "8313": {
    name: "tiny dinos winter 2022 #8313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8313,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8313.png",
  },
  "8314": {
    name: "tiny dinos winter 2022 #8314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8314,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8314.png",
  },
  "8315": {
    name: "tiny dinos winter 2022 #8315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8315,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8315.png",
  },
  "8316": {
    name: "tiny dinos winter 2022 #8316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8316,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8316.png",
  },
  "8317": {
    name: "tiny dinos winter 2022 #8317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8317,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8317.png",
  },
  "8318": {
    name: "tiny dinos winter 2022 #8318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8318,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8318.png",
  },
  "8319": {
    name: "tiny dinos winter 2022 #8319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8319,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8319.png",
  },
  "8320": {
    name: "tiny dinos winter 2022 #8320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8320,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8320.png",
  },
  "8321": {
    name: "tiny dinos winter 2022 #8321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8321,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8321.png",
  },
  "8322": {
    name: "tiny dinos winter 2022 #8322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8322,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8322.png",
  },
  "8323": {
    name: "tiny dinos winter 2022 #8323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8323,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8323.png",
  },
  "8324": {
    name: "tiny dinos winter 2022 #8324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8324,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8324.png",
  },
  "8325": {
    name: "tiny dinos winter 2022 #8325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8325,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8325.png",
  },
  "8326": {
    name: "tiny dinos winter 2022 #8326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8326,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8326.png",
  },
  "8327": {
    name: "tiny dinos winter 2022 #8327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8327,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8327.png",
  },
  "8328": {
    name: "tiny dinos winter 2022 #8328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8328,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8328.png",
  },
  "8329": {
    name: "tiny dinos winter 2022 #8329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8329,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8329.png",
  },
  "8330": {
    name: "tiny dinos winter 2022 #8330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8330,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8330.png",
  },
  "8331": {
    name: "tiny dinos winter 2022 #8331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8331,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8331.png",
  },
  "8332": {
    name: "tiny dinos winter 2022 #8332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8332,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8332.png",
  },
  "8333": {
    name: "tiny dinos winter 2022 #8333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8333,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8333.png",
  },
  "8334": {
    name: "tiny dinos winter 2022 #8334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8334,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8334.png",
  },
  "8335": {
    name: "tiny dinos winter 2022 #8335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8335,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8335.png",
  },
  "8336": {
    name: "tiny dinos winter 2022 #8336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8336,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8336.png",
  },
  "8337": {
    name: "tiny dinos winter 2022 #8337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8337,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8337.png",
  },
  "8338": {
    name: "tiny dinos winter 2022 #8338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8338,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8338.png",
  },
  "8339": {
    name: "tiny dinos winter 2022 #8339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8339,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8339.png",
  },
  "8340": {
    name: "tiny dinos winter 2022 #8340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8340,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8340.png",
  },
  "8341": {
    name: "tiny dinos winter 2022 #8341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8341,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8341.png",
  },
  "8342": {
    name: "tiny dinos winter 2022 #8342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8342,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8342.png",
  },
  "8343": {
    name: "tiny dinos winter 2022 #8343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8343,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8343.png",
  },
  "8344": {
    name: "tiny dinos winter 2022 #8344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8344,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8344.png",
  },
  "8345": {
    name: "tiny dinos winter 2022 #8345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8345,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8345.png",
  },
  "8346": {
    name: "tiny dinos winter 2022 #8346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8346,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8346.png",
  },
  "8347": {
    name: "tiny dinos winter 2022 #8347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8347,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8347.png",
  },
  "8348": {
    name: "tiny dinos winter 2022 #8348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8348,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8348.png",
  },
  "8349": {
    name: "tiny dinos winter 2022 #8349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8349,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8349.png",
  },
  "8350": {
    name: "tiny dinos winter 2022 #8350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8350,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8350.png",
  },
  "8351": {
    name: "tiny dinos winter 2022 #8351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8351,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8351.png",
  },
  "8352": {
    name: "tiny dinos winter 2022 #8352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8352,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8352.png",
  },
  "8353": {
    name: "tiny dinos winter 2022 #8353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8353,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8353.png",
  },
  "8354": {
    name: "tiny dinos winter 2022 #8354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8354,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8354.png",
  },
  "8355": {
    name: "tiny dinos winter 2022 #8355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8355,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8355.png",
  },
  "8356": {
    name: "tiny dinos winter 2022 #8356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8356,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8356.png",
  },
  "8357": {
    name: "tiny dinos winter 2022 #8357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8357,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8357.png",
  },
  "8358": {
    name: "tiny dinos winter 2022 #8358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8358,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8358.png",
  },
  "8359": {
    name: "tiny dinos winter 2022 #8359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8359,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8359.png",
  },
  "8360": {
    name: "tiny dinos winter 2022 #8360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8360,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8360.png",
  },
  "8361": {
    name: "tiny dinos winter 2022 #8361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8361,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8361.png",
  },
  "8362": {
    name: "tiny dinos winter 2022 #8362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8362,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8362.png",
  },
  "8363": {
    name: "tiny dinos winter 2022 #8363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8363,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8363.png",
  },
  "8364": {
    name: "tiny dinos winter 2022 #8364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8364,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8364.png",
  },
  "8365": {
    name: "tiny dinos winter 2022 #8365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8365,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8365.png",
  },
  "8366": {
    name: "tiny dinos winter 2022 #8366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8366,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8366.png",
  },
  "8367": {
    name: "tiny dinos winter 2022 #8367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8367,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8367.png",
  },
  "8368": {
    name: "tiny dinos winter 2022 #8368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8368,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8368.png",
  },
  "8369": {
    name: "tiny dinos winter 2022 #8369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8369,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8369.png",
  },
  "8370": {
    name: "tiny dinos winter 2022 #8370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8370,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8370.png",
  },
  "8371": {
    name: "tiny dinos winter 2022 #8371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8371,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8371.png",
  },
  "8372": {
    name: "tiny dinos winter 2022 #8372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8372,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8372.png",
  },
  "8373": {
    name: "tiny dinos winter 2022 #8373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8373,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8373.png",
  },
  "8374": {
    name: "tiny dinos winter 2022 #8374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8374,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8374.png",
  },
  "8375": {
    name: "tiny dinos winter 2022 #8375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8375,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8375.png",
  },
  "8376": {
    name: "tiny dinos winter 2022 #8376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8376,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8376.png",
  },
  "8377": {
    name: "tiny dinos winter 2022 #8377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8377,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8377.png",
  },
  "8378": {
    name: "tiny dinos winter 2022 #8378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8378,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8378.png",
  },
  "8379": {
    name: "tiny dinos winter 2022 #8379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8379,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8379.png",
  },
  "8380": {
    name: "tiny dinos winter 2022 #8380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8380,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8380.png",
  },
  "8381": {
    name: "tiny dinos winter 2022 #8381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8381,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8381.png",
  },
  "8382": {
    name: "tiny dinos winter 2022 #8382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8382,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8382.png",
  },
  "8383": {
    name: "tiny dinos winter 2022 #8383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8383,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8383.png",
  },
  "8384": {
    name: "tiny dinos winter 2022 #8384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8384,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8384.png",
  },
  "8385": {
    name: "tiny dinos winter 2022 #8385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8385,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8385.png",
  },
  "8386": {
    name: "tiny dinos winter 2022 #8386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8386,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8386.png",
  },
  "8387": {
    name: "tiny dinos winter 2022 #8387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8387,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8387.png",
  },
  "8388": {
    name: "tiny dinos winter 2022 #8388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8388,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8388.png",
  },
  "8389": {
    name: "tiny dinos winter 2022 #8389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8389,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8389.png",
  },
  "8390": {
    name: "tiny dinos winter 2022 #8390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8390,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8390.png",
  },
  "8391": {
    name: "tiny dinos winter 2022 #8391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8391,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8391.png",
  },
  "8392": {
    name: "tiny dinos winter 2022 #8392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8392,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8392.png",
  },
  "8393": {
    name: "tiny dinos winter 2022 #8393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8393,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8393.png",
  },
  "8394": {
    name: "tiny dinos winter 2022 #8394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8394,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8394.png",
  },
  "8395": {
    name: "tiny dinos winter 2022 #8395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8395,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8395.png",
  },
  "8396": {
    name: "tiny dinos winter 2022 #8396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8396,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8396.png",
  },
  "8397": {
    name: "tiny dinos winter 2022 #8397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8397,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8397.png",
  },
  "8398": {
    name: "tiny dinos winter 2022 #8398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8398,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8398.png",
  },
  "8399": {
    name: "tiny dinos winter 2022 #8399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8399,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8399.png",
  },
  "8400": {
    name: "tiny dinos winter 2022 #8400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8400,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8400.png",
  },
  "8401": {
    name: "tiny dinos winter 2022 #8401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8401,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8401.png",
  },
  "8402": {
    name: "tiny dinos winter 2022 #8402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8402,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8402.png",
  },
  "8403": {
    name: "tiny dinos winter 2022 #8403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8403,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8403.png",
  },
  "8404": {
    name: "tiny dinos winter 2022 #8404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8404,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8404.png",
  },
  "8405": {
    name: "tiny dinos winter 2022 #8405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8405,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8405.png",
  },
  "8406": {
    name: "tiny dinos winter 2022 #8406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8406,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8406.png",
  },
  "8407": {
    name: "tiny dinos winter 2022 #8407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8407,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8407.png",
  },
  "8408": {
    name: "tiny dinos winter 2022 #8408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8408,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8408.png",
  },
  "8409": {
    name: "tiny dinos winter 2022 #8409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8409,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8409.png",
  },
  "8410": {
    name: "tiny dinos winter 2022 #8410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8410,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8410.png",
  },
  "8411": {
    name: "tiny dinos winter 2022 #8411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8411,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8411.png",
  },
  "8412": {
    name: "tiny dinos winter 2022 #8412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8412,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8412.png",
  },
  "8413": {
    name: "tiny dinos winter 2022 #8413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8413,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8413.png",
  },
  "8414": {
    name: "tiny dinos winter 2022 #8414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8414,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8414.png",
  },
  "8415": {
    name: "tiny dinos winter 2022 #8415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8415,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8415.png",
  },
  "8416": {
    name: "tiny dinos winter 2022 #8416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8416,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8416.png",
  },
  "8417": {
    name: "tiny dinos winter 2022 #8417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8417,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8417.png",
  },
  "8418": {
    name: "tiny dinos winter 2022 #8418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8418,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8418.png",
  },
  "8419": {
    name: "tiny dinos winter 2022 #8419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8419,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8419.png",
  },
  "8420": {
    name: "tiny dinos winter 2022 #8420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8420,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8420.png",
  },
  "8421": {
    name: "tiny dinos winter 2022 #8421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8421,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8421.png",
  },
  "8422": {
    name: "tiny dinos winter 2022 #8422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8422,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8422.png",
  },
  "8423": {
    name: "tiny dinos winter 2022 #8423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8423,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8423.png",
  },
  "8424": {
    name: "tiny dinos winter 2022 #8424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8424,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8424.png",
  },
  "8425": {
    name: "tiny dinos winter 2022 #8425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8425,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8425.png",
  },
  "8426": {
    name: "tiny dinos winter 2022 #8426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8426,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8426.png",
  },
  "8427": {
    name: "tiny dinos winter 2022 #8427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8427,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8427.png",
  },
  "8428": {
    name: "tiny dinos winter 2022 #8428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8428,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8428.png",
  },
  "8429": {
    name: "tiny dinos winter 2022 #8429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8429,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8429.png",
  },
  "8430": {
    name: "tiny dinos winter 2022 #8430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8430,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8430.png",
  },
  "8431": {
    name: "tiny dinos winter 2022 #8431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8431,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8431.png",
  },
  "8432": {
    name: "tiny dinos winter 2022 #8432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8432,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8432.png",
  },
  "8433": {
    name: "tiny dinos winter 2022 #8433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8433,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8433.png",
  },
  "8434": {
    name: "tiny dinos winter 2022 #8434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8434,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8434.png",
  },
  "8435": {
    name: "tiny dinos winter 2022 #8435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8435,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8435.png",
  },
  "8436": {
    name: "tiny dinos winter 2022 #8436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8436,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8436.png",
  },
  "8437": {
    name: "tiny dinos winter 2022 #8437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8437,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8437.png",
  },
  "8438": {
    name: "tiny dinos winter 2022 #8438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8438,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8438.png",
  },
  "8439": {
    name: "tiny dinos winter 2022 #8439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8439,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8439.png",
  },
  "8440": {
    name: "tiny dinos winter 2022 #8440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8440,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8440.png",
  },
  "8441": {
    name: "tiny dinos winter 2022 #8441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8441,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8441.png",
  },
  "8442": {
    name: "tiny dinos winter 2022 #8442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8442,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8442.png",
  },
  "8443": {
    name: "tiny dinos winter 2022 #8443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8443,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8443.png",
  },
  "8444": {
    name: "tiny dinos winter 2022 #8444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8444,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8444.png",
  },
  "8445": {
    name: "tiny dinos winter 2022 #8445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8445,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8445.png",
  },
  "8446": {
    name: "tiny dinos winter 2022 #8446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8446,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8446.png",
  },
  "8447": {
    name: "tiny dinos winter 2022 #8447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8447,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8447.png",
  },
  "8448": {
    name: "tiny dinos winter 2022 #8448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8448,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8448.png",
  },
  "8449": {
    name: "tiny dinos winter 2022 #8449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8449,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8449.png",
  },
  "8450": {
    name: "tiny dinos winter 2022 #8450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8450,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8450.png",
  },
  "8451": {
    name: "tiny dinos winter 2022 #8451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8451,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8451.png",
  },
  "8452": {
    name: "tiny dinos winter 2022 #8452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8452,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8452.png",
  },
  "8453": {
    name: "tiny dinos winter 2022 #8453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8453,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8453.png",
  },
  "8454": {
    name: "tiny dinos winter 2022 #8454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8454,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8454.png",
  },
  "8455": {
    name: "tiny dinos winter 2022 #8455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8455,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8455.png",
  },
  "8456": {
    name: "tiny dinos winter 2022 #8456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8456,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8456.png",
  },
  "8457": {
    name: "tiny dinos winter 2022 #8457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8457,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8457.png",
  },
  "8458": {
    name: "tiny dinos winter 2022 #8458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8458,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8458.png",
  },
  "8459": {
    name: "tiny dinos winter 2022 #8459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8459,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8459.png",
  },
  "8460": {
    name: "tiny dinos winter 2022 #8460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8460,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8460.png",
  },
  "8461": {
    name: "tiny dinos winter 2022 #8461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8461,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8461.png",
  },
  "8462": {
    name: "tiny dinos winter 2022 #8462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8462,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8462.png",
  },
  "8463": {
    name: "tiny dinos winter 2022 #8463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8463,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8463.png",
  },
  "8464": {
    name: "tiny dinos winter 2022 #8464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8464,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8464.png",
  },
  "8465": {
    name: "tiny dinos winter 2022 #8465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8465,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8465.png",
  },
  "8466": {
    name: "tiny dinos winter 2022 #8466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8466,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8466.png",
  },
  "8467": {
    name: "tiny dinos winter 2022 #8467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8467,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8467.png",
  },
  "8468": {
    name: "tiny dinos winter 2022 #8468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8468,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8468.png",
  },
  "8469": {
    name: "tiny dinos winter 2022 #8469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8469,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8469.png",
  },
  "8470": {
    name: "tiny dinos winter 2022 #8470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8470,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8470.png",
  },
  "8471": {
    name: "tiny dinos winter 2022 #8471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8471,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8471.png",
  },
  "8472": {
    name: "tiny dinos winter 2022 #8472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8472,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8472.png",
  },
  "8473": {
    name: "tiny dinos winter 2022 #8473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8473,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8473.png",
  },
  "8474": {
    name: "tiny dinos winter 2022 #8474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8474,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8474.png",
  },
  "8475": {
    name: "tiny dinos winter 2022 #8475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8475,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8475.png",
  },
  "8476": {
    name: "tiny dinos winter 2022 #8476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8476,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8476.png",
  },
  "8477": {
    name: "tiny dinos winter 2022 #8477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8477,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8477.png",
  },
  "8478": {
    name: "tiny dinos winter 2022 #8478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8478,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8478.png",
  },
  "8479": {
    name: "tiny dinos winter 2022 #8479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8479,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8479.png",
  },
  "8480": {
    name: "tiny dinos winter 2022 #8480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8480,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8480.png",
  },
  "8481": {
    name: "tiny dinos winter 2022 #8481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8481,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8481.png",
  },
  "8482": {
    name: "tiny dinos winter 2022 #8482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8482,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8482.png",
  },
  "8483": {
    name: "tiny dinos winter 2022 #8483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8483,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8483.png",
  },
  "8484": {
    name: "tiny dinos winter 2022 #8484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8484,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8484.png",
  },
  "8485": {
    name: "tiny dinos winter 2022 #8485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8485,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8485.png",
  },
  "8486": {
    name: "tiny dinos winter 2022 #8486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8486,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8486.png",
  },
  "8487": {
    name: "tiny dinos winter 2022 #8487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8487,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8487.png",
  },
  "8488": {
    name: "tiny dinos winter 2022 #8488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8488,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8488.png",
  },
  "8489": {
    name: "tiny dinos winter 2022 #8489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8489,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8489.png",
  },
  "8490": {
    name: "tiny dinos winter 2022 #8490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8490,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8490.png",
  },
  "8491": {
    name: "tiny dinos winter 2022 #8491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8491,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8491.png",
  },
  "8492": {
    name: "tiny dinos winter 2022 #8492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8492,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8492.png",
  },
  "8493": {
    name: "tiny dinos winter 2022 #8493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8493,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8493.png",
  },
  "8494": {
    name: "tiny dinos winter 2022 #8494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8494,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8494.png",
  },
  "8495": {
    name: "tiny dinos winter 2022 #8495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8495,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8495.png",
  },
  "8496": {
    name: "tiny dinos winter 2022 #8496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8496,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8496.png",
  },
  "8497": {
    name: "tiny dinos winter 2022 #8497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8497,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8497.png",
  },
  "8498": {
    name: "tiny dinos winter 2022 #8498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8498,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8498.png",
  },
  "8499": {
    name: "tiny dinos winter 2022 #8499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8499,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8499.png",
  },
  "8500": {
    name: "tiny dinos winter 2022 #8500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8500,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8500.png",
  },
  "8501": {
    name: "tiny dinos winter 2022 #8501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8501,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8501.png",
  },
  "8502": {
    name: "tiny dinos winter 2022 #8502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8502,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8502.png",
  },
  "8503": {
    name: "tiny dinos winter 2022 #8503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8503,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8503.png",
  },
  "8504": {
    name: "tiny dinos winter 2022 #8504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8504,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8504.png",
  },
  "8505": {
    name: "tiny dinos winter 2022 #8505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8505,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8505.png",
  },
  "8506": {
    name: "tiny dinos winter 2022 #8506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8506,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8506.png",
  },
  "8507": {
    name: "tiny dinos winter 2022 #8507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8507,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8507.png",
  },
  "8508": {
    name: "tiny dinos winter 2022 #8508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8508,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8508.png",
  },
  "8509": {
    name: "tiny dinos winter 2022 #8509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8509,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8509.png",
  },
  "8510": {
    name: "tiny dinos winter 2022 #8510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8510,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8510.png",
  },
  "8511": {
    name: "tiny dinos winter 2022 #8511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8511,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8511.png",
  },
  "8512": {
    name: "tiny dinos winter 2022 #8512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8512,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8512.png",
  },
  "8513": {
    name: "tiny dinos winter 2022 #8513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8513,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8513.png",
  },
  "8514": {
    name: "tiny dinos winter 2022 #8514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8514,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8514.png",
  },
  "8515": {
    name: "tiny dinos winter 2022 #8515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8515,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8515.png",
  },
  "8516": {
    name: "tiny dinos winter 2022 #8516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8516,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8516.png",
  },
  "8517": {
    name: "tiny dinos winter 2022 #8517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8517,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8517.png",
  },
  "8518": {
    name: "tiny dinos winter 2022 #8518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8518,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8518.png",
  },
  "8519": {
    name: "tiny dinos winter 2022 #8519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8519,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8519.png",
  },
  "8520": {
    name: "tiny dinos winter 2022 #8520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8520,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8520.png",
  },
  "8521": {
    name: "tiny dinos winter 2022 #8521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8521,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8521.png",
  },
  "8522": {
    name: "tiny dinos winter 2022 #8522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8522,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8522.png",
  },
  "8523": {
    name: "tiny dinos winter 2022 #8523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8523,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8523.png",
  },
  "8524": {
    name: "tiny dinos winter 2022 #8524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8524,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8524.png",
  },
  "8525": {
    name: "tiny dinos winter 2022 #8525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8525,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8525.png",
  },
  "8526": {
    name: "tiny dinos winter 2022 #8526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8526,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8526.png",
  },
  "8527": {
    name: "tiny dinos winter 2022 #8527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8527,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8527.png",
  },
  "8528": {
    name: "tiny dinos winter 2022 #8528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8528,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8528.png",
  },
  "8529": {
    name: "tiny dinos winter 2022 #8529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8529,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8529.png",
  },
  "8530": {
    name: "tiny dinos winter 2022 #8530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8530,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8530.png",
  },
  "8531": {
    name: "tiny dinos winter 2022 #8531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8531,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8531.png",
  },
  "8532": {
    name: "tiny dinos winter 2022 #8532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8532,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8532.png",
  },
  "8533": {
    name: "tiny dinos winter 2022 #8533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8533,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8533.png",
  },
  "8534": {
    name: "tiny dinos winter 2022 #8534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8534,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8534.png",
  },
  "8535": {
    name: "tiny dinos winter 2022 #8535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8535,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8535.png",
  },
  "8536": {
    name: "tiny dinos winter 2022 #8536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8536,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8536.png",
  },
  "8537": {
    name: "tiny dinos winter 2022 #8537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8537,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8537.png",
  },
  "8538": {
    name: "tiny dinos winter 2022 #8538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8538,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8538.png",
  },
  "8539": {
    name: "tiny dinos winter 2022 #8539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8539,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8539.png",
  },
  "8540": {
    name: "tiny dinos winter 2022 #8540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8540,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8540.png",
  },
  "8541": {
    name: "tiny dinos winter 2022 #8541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8541,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8541.png",
  },
  "8542": {
    name: "tiny dinos winter 2022 #8542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8542,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8542.png",
  },
  "8543": {
    name: "tiny dinos winter 2022 #8543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8543,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8543.png",
  },
  "8544": {
    name: "tiny dinos winter 2022 #8544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8544,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8544.png",
  },
  "8545": {
    name: "tiny dinos winter 2022 #8545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8545,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8545.png",
  },
  "8546": {
    name: "tiny dinos winter 2022 #8546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8546,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8546.png",
  },
  "8547": {
    name: "tiny dinos winter 2022 #8547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8547,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8547.png",
  },
  "8548": {
    name: "tiny dinos winter 2022 #8548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8548,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8548.png",
  },
  "8549": {
    name: "tiny dinos winter 2022 #8549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8549,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8549.png",
  },
  "8550": {
    name: "tiny dinos winter 2022 #8550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8550,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8550.png",
  },
  "8551": {
    name: "tiny dinos winter 2022 #8551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8551,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8551.png",
  },
  "8552": {
    name: "tiny dinos winter 2022 #8552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8552,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8552.png",
  },
  "8553": {
    name: "tiny dinos winter 2022 #8553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8553,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8553.png",
  },
  "8554": {
    name: "tiny dinos winter 2022 #8554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8554,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8554.png",
  },
  "8555": {
    name: "tiny dinos winter 2022 #8555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8555,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8555.png",
  },
  "8556": {
    name: "tiny dinos winter 2022 #8556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8556,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8556.png",
  },
  "8557": {
    name: "tiny dinos winter 2022 #8557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8557,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8557.png",
  },
  "8558": {
    name: "tiny dinos winter 2022 #8558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8558,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8558.png",
  },
  "8559": {
    name: "tiny dinos winter 2022 #8559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8559,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8559.png",
  },
  "8560": {
    name: "tiny dinos winter 2022 #8560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8560,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8560.png",
  },
  "8561": {
    name: "tiny dinos winter 2022 #8561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8561,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8561.png",
  },
  "8562": {
    name: "tiny dinos winter 2022 #8562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8562,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8562.png",
  },
  "8563": {
    name: "tiny dinos winter 2022 #8563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8563,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8563.png",
  },
  "8564": {
    name: "tiny dinos winter 2022 #8564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8564,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8564.png",
  },
  "8565": {
    name: "tiny dinos winter 2022 #8565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8565,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8565.png",
  },
  "8566": {
    name: "tiny dinos winter 2022 #8566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8566,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8566.png",
  },
  "8567": {
    name: "tiny dinos winter 2022 #8567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8567,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8567.png",
  },
  "8568": {
    name: "tiny dinos winter 2022 #8568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8568,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8568.png",
  },
  "8569": {
    name: "tiny dinos winter 2022 #8569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8569,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8569.png",
  },
  "8570": {
    name: "tiny dinos winter 2022 #8570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8570,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8570.png",
  },
  "8571": {
    name: "tiny dinos winter 2022 #8571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8571,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8571.png",
  },
  "8572": {
    name: "tiny dinos winter 2022 #8572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8572,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8572.png",
  },
  "8573": {
    name: "tiny dinos winter 2022 #8573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8573,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8573.png",
  },
  "8574": {
    name: "tiny dinos winter 2022 #8574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8574,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8574.png",
  },
  "8575": {
    name: "tiny dinos winter 2022 #8575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8575,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8575.png",
  },
  "8576": {
    name: "tiny dinos winter 2022 #8576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8576,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8576.png",
  },
  "8577": {
    name: "tiny dinos winter 2022 #8577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8577,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8577.png",
  },
  "8578": {
    name: "tiny dinos winter 2022 #8578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8578,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8578.png",
  },
  "8579": {
    name: "tiny dinos winter 2022 #8579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8579,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8579.png",
  },
  "8580": {
    name: "tiny dinos winter 2022 #8580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8580,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8580.png",
  },
  "8581": {
    name: "tiny dinos winter 2022 #8581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8581,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8581.png",
  },
  "8582": {
    name: "tiny dinos winter 2022 #8582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8582,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8582.png",
  },
  "8583": {
    name: "tiny dinos winter 2022 #8583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8583,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8583.png",
  },
  "8584": {
    name: "tiny dinos winter 2022 #8584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8584,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8584.png",
  },
  "8585": {
    name: "tiny dinos winter 2022 #8585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8585,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8585.png",
  },
  "8586": {
    name: "tiny dinos winter 2022 #8586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8586,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8586.png",
  },
  "8587": {
    name: "tiny dinos winter 2022 #8587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8587,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8587.png",
  },
  "8588": {
    name: "tiny dinos winter 2022 #8588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8588,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8588.png",
  },
  "8589": {
    name: "tiny dinos winter 2022 #8589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8589,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8589.png",
  },
  "8590": {
    name: "tiny dinos winter 2022 #8590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8590,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8590.png",
  },
  "8591": {
    name: "tiny dinos winter 2022 #8591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8591,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8591.png",
  },
  "8592": {
    name: "tiny dinos winter 2022 #8592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8592,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8592.png",
  },
  "8593": {
    name: "tiny dinos winter 2022 #8593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8593,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8593.png",
  },
  "8594": {
    name: "tiny dinos winter 2022 #8594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8594,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8594.png",
  },
  "8595": {
    name: "tiny dinos winter 2022 #8595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8595,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8595.png",
  },
  "8596": {
    name: "tiny dinos winter 2022 #8596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8596,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8596.png",
  },
  "8597": {
    name: "tiny dinos winter 2022 #8597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8597,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8597.png",
  },
  "8598": {
    name: "tiny dinos winter 2022 #8598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8598,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8598.png",
  },
  "8599": {
    name: "tiny dinos winter 2022 #8599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8599,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8599.png",
  },
  "8600": {
    name: "tiny dinos winter 2022 #8600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8600,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8600.png",
  },
  "8601": {
    name: "tiny dinos winter 2022 #8601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8601,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8601.png",
  },
  "8602": {
    name: "tiny dinos winter 2022 #8602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8602,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8602.png",
  },
  "8603": {
    name: "tiny dinos winter 2022 #8603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8603,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8603.png",
  },
  "8604": {
    name: "tiny dinos winter 2022 #8604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8604,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8604.png",
  },
  "8605": {
    name: "tiny dinos winter 2022 #8605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8605,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8605.png",
  },
  "8606": {
    name: "tiny dinos winter 2022 #8606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8606,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8606.png",
  },
  "8607": {
    name: "tiny dinos winter 2022 #8607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8607,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8607.png",
  },
  "8608": {
    name: "tiny dinos winter 2022 #8608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8608,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8608.png",
  },
  "8609": {
    name: "tiny dinos winter 2022 #8609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8609,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8609.png",
  },
  "8610": {
    name: "tiny dinos winter 2022 #8610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8610,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8610.png",
  },
  "8611": {
    name: "tiny dinos winter 2022 #8611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8611,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8611.png",
  },
  "8612": {
    name: "tiny dinos winter 2022 #8612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8612,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8612.png",
  },
  "8613": {
    name: "tiny dinos winter 2022 #8613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8613,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8613.png",
  },
  "8614": {
    name: "tiny dinos winter 2022 #8614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8614,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8614.png",
  },
  "8615": {
    name: "tiny dinos winter 2022 #8615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8615,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8615.png",
  },
  "8616": {
    name: "tiny dinos winter 2022 #8616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8616,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8616.png",
  },
  "8617": {
    name: "tiny dinos winter 2022 #8617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8617,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8617.png",
  },
  "8618": {
    name: "tiny dinos winter 2022 #8618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8618,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8618.png",
  },
  "8619": {
    name: "tiny dinos winter 2022 #8619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8619,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8619.png",
  },
  "8620": {
    name: "tiny dinos winter 2022 #8620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8620,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8620.png",
  },
  "8621": {
    name: "tiny dinos winter 2022 #8621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8621,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8621.png",
  },
  "8622": {
    name: "tiny dinos winter 2022 #8622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8622,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8622.png",
  },
  "8623": {
    name: "tiny dinos winter 2022 #8623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8623,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8623.png",
  },
  "8624": {
    name: "tiny dinos winter 2022 #8624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8624,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8624.png",
  },
  "8625": {
    name: "tiny dinos winter 2022 #8625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8625,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8625.png",
  },
  "8626": {
    name: "tiny dinos winter 2022 #8626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8626,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8626.png",
  },
  "8627": {
    name: "tiny dinos winter 2022 #8627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8627,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8627.png",
  },
  "8628": {
    name: "tiny dinos winter 2022 #8628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8628,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8628.png",
  },
  "8629": {
    name: "tiny dinos winter 2022 #8629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8629,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8629.png",
  },
  "8630": {
    name: "tiny dinos winter 2022 #8630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8630,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8630.png",
  },
  "8631": {
    name: "tiny dinos winter 2022 #8631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8631,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8631.png",
  },
  "8632": {
    name: "tiny dinos winter 2022 #8632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8632,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8632.png",
  },
  "8633": {
    name: "tiny dinos winter 2022 #8633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8633,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8633.png",
  },
  "8634": {
    name: "tiny dinos winter 2022 #8634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8634,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8634.png",
  },
  "8635": {
    name: "tiny dinos winter 2022 #8635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8635,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8635.png",
  },
  "8636": {
    name: "tiny dinos winter 2022 #8636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8636,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8636.png",
  },
  "8637": {
    name: "tiny dinos winter 2022 #8637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8637,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8637.png",
  },
  "8638": {
    name: "tiny dinos winter 2022 #8638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8638,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8638.png",
  },
  "8639": {
    name: "tiny dinos winter 2022 #8639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8639,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8639.png",
  },
  "8640": {
    name: "tiny dinos winter 2022 #8640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8640,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8640.png",
  },
  "8641": {
    name: "tiny dinos winter 2022 #8641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8641,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8641.png",
  },
  "8642": {
    name: "tiny dinos winter 2022 #8642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8642,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8642.png",
  },
  "8643": {
    name: "tiny dinos winter 2022 #8643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8643,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8643.png",
  },
  "8644": {
    name: "tiny dinos winter 2022 #8644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8644,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8644.png",
  },
  "8645": {
    name: "tiny dinos winter 2022 #8645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8645,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8645.png",
  },
  "8646": {
    name: "tiny dinos winter 2022 #8646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8646,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8646.png",
  },
  "8647": {
    name: "tiny dinos winter 2022 #8647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8647,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8647.png",
  },
  "8648": {
    name: "tiny dinos winter 2022 #8648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8648,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8648.png",
  },
  "8649": {
    name: "tiny dinos winter 2022 #8649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8649,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8649.png",
  },
  "8650": {
    name: "tiny dinos winter 2022 #8650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8650,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8650.png",
  },
  "8651": {
    name: "tiny dinos winter 2022 #8651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8651,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8651.png",
  },
  "8652": {
    name: "tiny dinos winter 2022 #8652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8652,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8652.png",
  },
  "8653": {
    name: "tiny dinos winter 2022 #8653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8653,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8653.png",
  },
  "8654": {
    name: "tiny dinos winter 2022 #8654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8654,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8654.png",
  },
  "8655": {
    name: "tiny dinos winter 2022 #8655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8655,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8655.png",
  },
  "8656": {
    name: "tiny dinos winter 2022 #8656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8656,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8656.png",
  },
  "8657": {
    name: "tiny dinos winter 2022 #8657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8657,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8657.png",
  },
  "8658": {
    name: "tiny dinos winter 2022 #8658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8658,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8658.png",
  },
  "8659": {
    name: "tiny dinos winter 2022 #8659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8659,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8659.png",
  },
  "8660": {
    name: "tiny dinos winter 2022 #8660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8660,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8660.png",
  },
  "8661": {
    name: "tiny dinos winter 2022 #8661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8661,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8661.png",
  },
  "8662": {
    name: "tiny dinos winter 2022 #8662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8662,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8662.png",
  },
  "8663": {
    name: "tiny dinos winter 2022 #8663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8663,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8663.png",
  },
  "8664": {
    name: "tiny dinos winter 2022 #8664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8664,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8664.png",
  },
  "8665": {
    name: "tiny dinos winter 2022 #8665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8665,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8665.png",
  },
  "8666": {
    name: "tiny dinos winter 2022 #8666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8666,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8666.png",
  },
  "8667": {
    name: "tiny dinos winter 2022 #8667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8667,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8667.png",
  },
  "8668": {
    name: "tiny dinos winter 2022 #8668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8668,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8668.png",
  },
  "8669": {
    name: "tiny dinos winter 2022 #8669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8669,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8669.png",
  },
  "8670": {
    name: "tiny dinos winter 2022 #8670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8670,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8670.png",
  },
  "8671": {
    name: "tiny dinos winter 2022 #8671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8671,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8671.png",
  },
  "8672": {
    name: "tiny dinos winter 2022 #8672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8672,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8672.png",
  },
  "8673": {
    name: "tiny dinos winter 2022 #8673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8673,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8673.png",
  },
  "8674": {
    name: "tiny dinos winter 2022 #8674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8674,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8674.png",
  },
  "8675": {
    name: "tiny dinos winter 2022 #8675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8675,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8675.png",
  },
  "8676": {
    name: "tiny dinos winter 2022 #8676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8676,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8676.png",
  },
  "8677": {
    name: "tiny dinos winter 2022 #8677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8677,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8677.png",
  },
  "8678": {
    name: "tiny dinos winter 2022 #8678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8678,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8678.png",
  },
  "8679": {
    name: "tiny dinos winter 2022 #8679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8679,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8679.png",
  },
  "8680": {
    name: "tiny dinos winter 2022 #8680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8680,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8680.png",
  },
  "8681": {
    name: "tiny dinos winter 2022 #8681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8681,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8681.png",
  },
  "8682": {
    name: "tiny dinos winter 2022 #8682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8682,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8682.png",
  },
  "8683": {
    name: "tiny dinos winter 2022 #8683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8683,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8683.png",
  },
  "8684": {
    name: "tiny dinos winter 2022 #8684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8684,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8684.png",
  },
  "8685": {
    name: "tiny dinos winter 2022 #8685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8685,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8685.png",
  },
  "8686": {
    name: "tiny dinos winter 2022 #8686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8686,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8686.png",
  },
  "8687": {
    name: "tiny dinos winter 2022 #8687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8687,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8687.png",
  },
  "8688": {
    name: "tiny dinos winter 2022 #8688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8688,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8688.png",
  },
  "8689": {
    name: "tiny dinos winter 2022 #8689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8689,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8689.png",
  },
  "8690": {
    name: "tiny dinos winter 2022 #8690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8690,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8690.png",
  },
  "8691": {
    name: "tiny dinos winter 2022 #8691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8691,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8691.png",
  },
  "8692": {
    name: "tiny dinos winter 2022 #8692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8692,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8692.png",
  },
  "8693": {
    name: "tiny dinos winter 2022 #8693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8693,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8693.png",
  },
  "8694": {
    name: "tiny dinos winter 2022 #8694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8694,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8694.png",
  },
  "8695": {
    name: "tiny dinos winter 2022 #8695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8695,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8695.png",
  },
  "8696": {
    name: "tiny dinos winter 2022 #8696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8696,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8696.png",
  },
  "8697": {
    name: "tiny dinos winter 2022 #8697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8697,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8697.png",
  },
  "8698": {
    name: "tiny dinos winter 2022 #8698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8698,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8698.png",
  },
  "8699": {
    name: "tiny dinos winter 2022 #8699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8699,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8699.png",
  },
  "8700": {
    name: "tiny dinos winter 2022 #8700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8700,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8700.png",
  },
  "8701": {
    name: "tiny dinos winter 2022 #8701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8701,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8701.png",
  },
  "8702": {
    name: "tiny dinos winter 2022 #8702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8702,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8702.png",
  },
  "8703": {
    name: "tiny dinos winter 2022 #8703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8703,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8703.png",
  },
  "8704": {
    name: "tiny dinos winter 2022 #8704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8704,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8704.png",
  },
  "8705": {
    name: "tiny dinos winter 2022 #8705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8705,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8705.png",
  },
  "8706": {
    name: "tiny dinos winter 2022 #8706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8706,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8706.png",
  },
  "8707": {
    name: "tiny dinos winter 2022 #8707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8707,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8707.png",
  },
  "8708": {
    name: "tiny dinos winter 2022 #8708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8708,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8708.png",
  },
  "8709": {
    name: "tiny dinos winter 2022 #8709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8709,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8709.png",
  },
  "8710": {
    name: "tiny dinos winter 2022 #8710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8710,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8710.png",
  },
  "8711": {
    name: "tiny dinos winter 2022 #8711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8711,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8711.png",
  },
  "8712": {
    name: "tiny dinos winter 2022 #8712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8712,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8712.png",
  },
  "8713": {
    name: "tiny dinos winter 2022 #8713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8713,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8713.png",
  },
  "8714": {
    name: "tiny dinos winter 2022 #8714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8714,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8714.png",
  },
  "8715": {
    name: "tiny dinos winter 2022 #8715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8715,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8715.png",
  },
  "8716": {
    name: "tiny dinos winter 2022 #8716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8716,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8716.png",
  },
  "8717": {
    name: "tiny dinos winter 2022 #8717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8717,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8717.png",
  },
  "8718": {
    name: "tiny dinos winter 2022 #8718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8718,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8718.png",
  },
  "8719": {
    name: "tiny dinos winter 2022 #8719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8719,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8719.png",
  },
  "8720": {
    name: "tiny dinos winter 2022 #8720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8720,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8720.png",
  },
  "8721": {
    name: "tiny dinos winter 2022 #8721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8721,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8721.png",
  },
  "8722": {
    name: "tiny dinos winter 2022 #8722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8722,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8722.png",
  },
  "8723": {
    name: "tiny dinos winter 2022 #8723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8723,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8723.png",
  },
  "8724": {
    name: "tiny dinos winter 2022 #8724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8724,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8724.png",
  },
  "8725": {
    name: "tiny dinos winter 2022 #8725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8725,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8725.png",
  },
  "8726": {
    name: "tiny dinos winter 2022 #8726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8726,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8726.png",
  },
  "8727": {
    name: "tiny dinos winter 2022 #8727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8727,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8727.png",
  },
  "8728": {
    name: "tiny dinos winter 2022 #8728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8728,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8728.png",
  },
  "8729": {
    name: "tiny dinos winter 2022 #8729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8729,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8729.png",
  },
  "8730": {
    name: "tiny dinos winter 2022 #8730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8730,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8730.png",
  },
  "8731": {
    name: "tiny dinos winter 2022 #8731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8731,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8731.png",
  },
  "8732": {
    name: "tiny dinos winter 2022 #8732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8732,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8732.png",
  },
  "8733": {
    name: "tiny dinos winter 2022 #8733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8733,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8733.png",
  },
  "8734": {
    name: "tiny dinos winter 2022 #8734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8734,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8734.png",
  },
  "8735": {
    name: "tiny dinos winter 2022 #8735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8735,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8735.png",
  },
  "8736": {
    name: "tiny dinos winter 2022 #8736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8736,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8736.png",
  },
  "8737": {
    name: "tiny dinos winter 2022 #8737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8737,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8737.png",
  },
  "8738": {
    name: "tiny dinos winter 2022 #8738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8738,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8738.png",
  },
  "8739": {
    name: "tiny dinos winter 2022 #8739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8739,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8739.png",
  },
  "8740": {
    name: "tiny dinos winter 2022 #8740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8740,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8740.png",
  },
  "8741": {
    name: "tiny dinos winter 2022 #8741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8741,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8741.png",
  },
  "8742": {
    name: "tiny dinos winter 2022 #8742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8742,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8742.png",
  },
  "8743": {
    name: "tiny dinos winter 2022 #8743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8743,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8743.png",
  },
  "8744": {
    name: "tiny dinos winter 2022 #8744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8744,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8744.png",
  },
  "8745": {
    name: "tiny dinos winter 2022 #8745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8745,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8745.png",
  },
  "8746": {
    name: "tiny dinos winter 2022 #8746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8746,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8746.png",
  },
  "8747": {
    name: "tiny dinos winter 2022 #8747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8747,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8747.png",
  },
  "8748": {
    name: "tiny dinos winter 2022 #8748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8748,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8748.png",
  },
  "8749": {
    name: "tiny dinos winter 2022 #8749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8749,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8749.png",
  },
  "8750": {
    name: "tiny dinos winter 2022 #8750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8750,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8750.png",
  },
  "8751": {
    name: "tiny dinos winter 2022 #8751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8751,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8751.png",
  },
  "8752": {
    name: "tiny dinos winter 2022 #8752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8752,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8752.png",
  },
  "8753": {
    name: "tiny dinos winter 2022 #8753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8753,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8753.png",
  },
  "8754": {
    name: "tiny dinos winter 2022 #8754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8754,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8754.png",
  },
  "8755": {
    name: "tiny dinos winter 2022 #8755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8755,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8755.png",
  },
  "8756": {
    name: "tiny dinos winter 2022 #8756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8756,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8756.png",
  },
  "8757": {
    name: "tiny dinos winter 2022 #8757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8757,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8757.png",
  },
  "8758": {
    name: "tiny dinos winter 2022 #8758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8758,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8758.png",
  },
  "8759": {
    name: "tiny dinos winter 2022 #8759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8759,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8759.png",
  },
  "8760": {
    name: "tiny dinos winter 2022 #8760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8760,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8760.png",
  },
  "8761": {
    name: "tiny dinos winter 2022 #8761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8761,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8761.png",
  },
  "8762": {
    name: "tiny dinos winter 2022 #8762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8762,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8762.png",
  },
  "8763": {
    name: "tiny dinos winter 2022 #8763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8763,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8763.png",
  },
  "8764": {
    name: "tiny dinos winter 2022 #8764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8764,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8764.png",
  },
  "8765": {
    name: "tiny dinos winter 2022 #8765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8765,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8765.png",
  },
  "8766": {
    name: "tiny dinos winter 2022 #8766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8766,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8766.png",
  },
  "8767": {
    name: "tiny dinos winter 2022 #8767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8767,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8767.png",
  },
  "8768": {
    name: "tiny dinos winter 2022 #8768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8768,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8768.png",
  },
  "8769": {
    name: "tiny dinos winter 2022 #8769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8769,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8769.png",
  },
  "8770": {
    name: "tiny dinos winter 2022 #8770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8770,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8770.png",
  },
  "8771": {
    name: "tiny dinos winter 2022 #8771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8771,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8771.png",
  },
  "8772": {
    name: "tiny dinos winter 2022 #8772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8772,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8772.png",
  },
  "8773": {
    name: "tiny dinos winter 2022 #8773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8773,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8773.png",
  },
  "8774": {
    name: "tiny dinos winter 2022 #8774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8774,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8774.png",
  },
  "8775": {
    name: "tiny dinos winter 2022 #8775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8775,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8775.png",
  },
  "8776": {
    name: "tiny dinos winter 2022 #8776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8776,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8776.png",
  },
  "8777": {
    name: "tiny dinos winter 2022 #8777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8777,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8777.png",
  },
  "8778": {
    name: "tiny dinos winter 2022 #8778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8778,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8778.png",
  },
  "8779": {
    name: "tiny dinos winter 2022 #8779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8779,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8779.png",
  },
  "8780": {
    name: "tiny dinos winter 2022 #8780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8780,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8780.png",
  },
  "8781": {
    name: "tiny dinos winter 2022 #8781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8781,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8781.png",
  },
  "8782": {
    name: "tiny dinos winter 2022 #8782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8782,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8782.png",
  },
  "8783": {
    name: "tiny dinos winter 2022 #8783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8783,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8783.png",
  },
  "8784": {
    name: "tiny dinos winter 2022 #8784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8784,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8784.png",
  },
  "8785": {
    name: "tiny dinos winter 2022 #8785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8785,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8785.png",
  },
  "8786": {
    name: "tiny dinos winter 2022 #8786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8786,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8786.png",
  },
  "8787": {
    name: "tiny dinos winter 2022 #8787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8787,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8787.png",
  },
  "8788": {
    name: "tiny dinos winter 2022 #8788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8788,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8788.png",
  },
  "8789": {
    name: "tiny dinos winter 2022 #8789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8789,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8789.png",
  },
  "8790": {
    name: "tiny dinos winter 2022 #8790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8790,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8790.png",
  },
  "8791": {
    name: "tiny dinos winter 2022 #8791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8791,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8791.png",
  },
  "8792": {
    name: "tiny dinos winter 2022 #8792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8792,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8792.png",
  },
  "8793": {
    name: "tiny dinos winter 2022 #8793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8793,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8793.png",
  },
  "8794": {
    name: "tiny dinos winter 2022 #8794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8794,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8794.png",
  },
  "8795": {
    name: "tiny dinos winter 2022 #8795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8795,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8795.png",
  },
  "8796": {
    name: "tiny dinos winter 2022 #8796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8796,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8796.png",
  },
  "8797": {
    name: "tiny dinos winter 2022 #8797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8797,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8797.png",
  },
  "8798": {
    name: "tiny dinos winter 2022 #8798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8798,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8798.png",
  },
  "8799": {
    name: "tiny dinos winter 2022 #8799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8799,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8799.png",
  },
  "8800": {
    name: "tiny dinos winter 2022 #8800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8800,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8800.png",
  },
  "8801": {
    name: "tiny dinos winter 2022 #8801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8801,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8801.png",
  },
  "8802": {
    name: "tiny dinos winter 2022 #8802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8802,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8802.png",
  },
  "8803": {
    name: "tiny dinos winter 2022 #8803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8803,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8803.png",
  },
  "8804": {
    name: "tiny dinos winter 2022 #8804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8804,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8804.png",
  },
  "8805": {
    name: "tiny dinos winter 2022 #8805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8805,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8805.png",
  },
  "8806": {
    name: "tiny dinos winter 2022 #8806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8806,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8806.png",
  },
  "8807": {
    name: "tiny dinos winter 2022 #8807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8807,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8807.png",
  },
  "8808": {
    name: "tiny dinos winter 2022 #8808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8808,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8808.png",
  },
  "8809": {
    name: "tiny dinos winter 2022 #8809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8809,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8809.png",
  },
  "8810": {
    name: "tiny dinos winter 2022 #8810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8810,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8810.png",
  },
  "8811": {
    name: "tiny dinos winter 2022 #8811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8811,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8811.png",
  },
  "8812": {
    name: "tiny dinos winter 2022 #8812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8812,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8812.png",
  },
  "8813": {
    name: "tiny dinos winter 2022 #8813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8813,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8813.png",
  },
  "8814": {
    name: "tiny dinos winter 2022 #8814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8814,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8814.png",
  },
  "8815": {
    name: "tiny dinos winter 2022 #8815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8815,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8815.png",
  },
  "8816": {
    name: "tiny dinos winter 2022 #8816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8816,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8816.png",
  },
  "8817": {
    name: "tiny dinos winter 2022 #8817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8817,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8817.png",
  },
  "8818": {
    name: "tiny dinos winter 2022 #8818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8818,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8818.png",
  },
  "8819": {
    name: "tiny dinos winter 2022 #8819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8819,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8819.png",
  },
  "8820": {
    name: "tiny dinos winter 2022 #8820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8820,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8820.png",
  },
  "8821": {
    name: "tiny dinos winter 2022 #8821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8821,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8821.png",
  },
  "8822": {
    name: "tiny dinos winter 2022 #8822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8822,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8822.png",
  },
  "8823": {
    name: "tiny dinos winter 2022 #8823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8823,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8823.png",
  },
  "8824": {
    name: "tiny dinos winter 2022 #8824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8824,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8824.png",
  },
  "8825": {
    name: "tiny dinos winter 2022 #8825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8825,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8825.png",
  },
  "8826": {
    name: "tiny dinos winter 2022 #8826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8826,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8826.png",
  },
  "8827": {
    name: "tiny dinos winter 2022 #8827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8827,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8827.png",
  },
  "8828": {
    name: "tiny dinos winter 2022 #8828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8828,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8828.png",
  },
  "8829": {
    name: "tiny dinos winter 2022 #8829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8829,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8829.png",
  },
  "8830": {
    name: "tiny dinos winter 2022 #8830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8830,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8830.png",
  },
  "8831": {
    name: "tiny dinos winter 2022 #8831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8831,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8831.png",
  },
  "8832": {
    name: "tiny dinos winter 2022 #8832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8832,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8832.png",
  },
  "8833": {
    name: "tiny dinos winter 2022 #8833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8833,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8833.png",
  },
  "8834": {
    name: "tiny dinos winter 2022 #8834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8834,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8834.png",
  },
  "8835": {
    name: "tiny dinos winter 2022 #8835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8835,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8835.png",
  },
  "8836": {
    name: "tiny dinos winter 2022 #8836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8836,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8836.png",
  },
  "8837": {
    name: "tiny dinos winter 2022 #8837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8837,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8837.png",
  },
  "8838": {
    name: "tiny dinos winter 2022 #8838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8838,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8838.png",
  },
  "8839": {
    name: "tiny dinos winter 2022 #8839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8839,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8839.png",
  },
  "8840": {
    name: "tiny dinos winter 2022 #8840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8840,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8840.png",
  },
  "8841": {
    name: "tiny dinos winter 2022 #8841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8841,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8841.png",
  },
  "8842": {
    name: "tiny dinos winter 2022 #8842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8842,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8842.png",
  },
  "8843": {
    name: "tiny dinos winter 2022 #8843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8843,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8843.png",
  },
  "8844": {
    name: "tiny dinos winter 2022 #8844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8844,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8844.png",
  },
  "8845": {
    name: "tiny dinos winter 2022 #8845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8845,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8845.png",
  },
  "8846": {
    name: "tiny dinos winter 2022 #8846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8846,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8846.png",
  },
  "8847": {
    name: "tiny dinos winter 2022 #8847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8847,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8847.png",
  },
  "8848": {
    name: "tiny dinos winter 2022 #8848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8848,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8848.png",
  },
  "8849": {
    name: "tiny dinos winter 2022 #8849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8849,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8849.png",
  },
  "8850": {
    name: "tiny dinos winter 2022 #8850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8850,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8850.png",
  },
  "8851": {
    name: "tiny dinos winter 2022 #8851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8851,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8851.png",
  },
  "8852": {
    name: "tiny dinos winter 2022 #8852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8852,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8852.png",
  },
  "8853": {
    name: "tiny dinos winter 2022 #8853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8853,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8853.png",
  },
  "8854": {
    name: "tiny dinos winter 2022 #8854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8854,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8854.png",
  },
  "8855": {
    name: "tiny dinos winter 2022 #8855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8855,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8855.png",
  },
  "8856": {
    name: "tiny dinos winter 2022 #8856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8856,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8856.png",
  },
  "8857": {
    name: "tiny dinos winter 2022 #8857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8857,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8857.png",
  },
  "8858": {
    name: "tiny dinos winter 2022 #8858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8858,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8858.png",
  },
  "8859": {
    name: "tiny dinos winter 2022 #8859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8859,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8859.png",
  },
  "8860": {
    name: "tiny dinos winter 2022 #8860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8860,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8860.png",
  },
  "8861": {
    name: "tiny dinos winter 2022 #8861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8861,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8861.png",
  },
  "8862": {
    name: "tiny dinos winter 2022 #8862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8862,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8862.png",
  },
  "8863": {
    name: "tiny dinos winter 2022 #8863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8863,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8863.png",
  },
  "8864": {
    name: "tiny dinos winter 2022 #8864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8864,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8864.png",
  },
  "8865": {
    name: "tiny dinos winter 2022 #8865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8865,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8865.png",
  },
  "8866": {
    name: "tiny dinos winter 2022 #8866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8866,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8866.png",
  },
  "8867": {
    name: "tiny dinos winter 2022 #8867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8867,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8867.png",
  },
  "8868": {
    name: "tiny dinos winter 2022 #8868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8868,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8868.png",
  },
  "8869": {
    name: "tiny dinos winter 2022 #8869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8869,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8869.png",
  },
  "8870": {
    name: "tiny dinos winter 2022 #8870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8870,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8870.png",
  },
  "8871": {
    name: "tiny dinos winter 2022 #8871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8871,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8871.png",
  },
  "8872": {
    name: "tiny dinos winter 2022 #8872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8872,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8872.png",
  },
  "8873": {
    name: "tiny dinos winter 2022 #8873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8873,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8873.png",
  },
  "8874": {
    name: "tiny dinos winter 2022 #8874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8874,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8874.png",
  },
  "8875": {
    name: "tiny dinos winter 2022 #8875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8875,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8875.png",
  },
  "8876": {
    name: "tiny dinos winter 2022 #8876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8876,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8876.png",
  },
  "8877": {
    name: "tiny dinos winter 2022 #8877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8877,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8877.png",
  },
  "8878": {
    name: "tiny dinos winter 2022 #8878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8878,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8878.png",
  },
  "8879": {
    name: "tiny dinos winter 2022 #8879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8879,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8879.png",
  },
  "8880": {
    name: "tiny dinos winter 2022 #8880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8880,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8880.png",
  },
  "8881": {
    name: "tiny dinos winter 2022 #8881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8881,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8881.png",
  },
  "8882": {
    name: "tiny dinos winter 2022 #8882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8882,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8882.png",
  },
  "8883": {
    name: "tiny dinos winter 2022 #8883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8883,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8883.png",
  },
  "8884": {
    name: "tiny dinos winter 2022 #8884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8884,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8884.png",
  },
  "8885": {
    name: "tiny dinos winter 2022 #8885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8885,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8885.png",
  },
  "8886": {
    name: "tiny dinos winter 2022 #8886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8886,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8886.png",
  },
  "8887": {
    name: "tiny dinos winter 2022 #8887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8887,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8887.png",
  },
  "8888": {
    name: "tiny dinos winter 2022 #8888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8888,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8888.png",
  },
  "8889": {
    name: "tiny dinos winter 2022 #8889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8889,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8889.png",
  },
  "8890": {
    name: "tiny dinos winter 2022 #8890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8890,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8890.png",
  },
  "8891": {
    name: "tiny dinos winter 2022 #8891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8891,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8891.png",
  },
  "8892": {
    name: "tiny dinos winter 2022 #8892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8892,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8892.png",
  },
  "8893": {
    name: "tiny dinos winter 2022 #8893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8893,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8893.png",
  },
  "8894": {
    name: "tiny dinos winter 2022 #8894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8894,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8894.png",
  },
  "8895": {
    name: "tiny dinos winter 2022 #8895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8895,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8895.png",
  },
  "8896": {
    name: "tiny dinos winter 2022 #8896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8896,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8896.png",
  },
  "8897": {
    name: "tiny dinos winter 2022 #8897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8897,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8897.png",
  },
  "8898": {
    name: "tiny dinos winter 2022 #8898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8898,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8898.png",
  },
  "8899": {
    name: "tiny dinos winter 2022 #8899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8899,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8899.png",
  },
  "8900": {
    name: "tiny dinos winter 2022 #8900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8900,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8900.png",
  },
  "8901": {
    name: "tiny dinos winter 2022 #8901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8901,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8901.png",
  },
  "8902": {
    name: "tiny dinos winter 2022 #8902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8902,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8902.png",
  },
  "8903": {
    name: "tiny dinos winter 2022 #8903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8903,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8903.png",
  },
  "8904": {
    name: "tiny dinos winter 2022 #8904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8904,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8904.png",
  },
  "8905": {
    name: "tiny dinos winter 2022 #8905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8905,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8905.png",
  },
  "8906": {
    name: "tiny dinos winter 2022 #8906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8906,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8906.png",
  },
  "8907": {
    name: "tiny dinos winter 2022 #8907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8907,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8907.png",
  },
  "8908": {
    name: "tiny dinos winter 2022 #8908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8908,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8908.png",
  },
  "8909": {
    name: "tiny dinos winter 2022 #8909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8909,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8909.png",
  },
  "8910": {
    name: "tiny dinos winter 2022 #8910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8910,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8910.png",
  },
  "8911": {
    name: "tiny dinos winter 2022 #8911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8911,
    attributes: [
      {
        trait_type: "1/1",
        value: "eth",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8911.png",
  },
  "8912": {
    name: "tiny dinos winter 2022 #8912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8912,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8912.png",
  },
  "8913": {
    name: "tiny dinos winter 2022 #8913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8913,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8913.png",
  },
  "8914": {
    name: "tiny dinos winter 2022 #8914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8914,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8914.png",
  },
  "8915": {
    name: "tiny dinos winter 2022 #8915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8915,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8915.png",
  },
  "8916": {
    name: "tiny dinos winter 2022 #8916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8916,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8916.png",
  },
  "8917": {
    name: "tiny dinos winter 2022 #8917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8917,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8917.png",
  },
  "8918": {
    name: "tiny dinos winter 2022 #8918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8918,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8918.png",
  },
  "8919": {
    name: "tiny dinos winter 2022 #8919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8919,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8919.png",
  },
  "8920": {
    name: "tiny dinos winter 2022 #8920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8920,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8920.png",
  },
  "8921": {
    name: "tiny dinos winter 2022 #8921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8921,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8921.png",
  },
  "8922": {
    name: "tiny dinos winter 2022 #8922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8922,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8922.png",
  },
  "8923": {
    name: "tiny dinos winter 2022 #8923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8923,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8923.png",
  },
  "8924": {
    name: "tiny dinos winter 2022 #8924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8924,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8924.png",
  },
  "8925": {
    name: "tiny dinos winter 2022 #8925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8925,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8925.png",
  },
  "8926": {
    name: "tiny dinos winter 2022 #8926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8926,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8926.png",
  },
  "8927": {
    name: "tiny dinos winter 2022 #8927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8927,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8927.png",
  },
  "8928": {
    name: "tiny dinos winter 2022 #8928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8928,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8928.png",
  },
  "8929": {
    name: "tiny dinos winter 2022 #8929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8929,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8929.png",
  },
  "8930": {
    name: "tiny dinos winter 2022 #8930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8930,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8930.png",
  },
  "8931": {
    name: "tiny dinos winter 2022 #8931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8931,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8931.png",
  },
  "8932": {
    name: "tiny dinos winter 2022 #8932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8932,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8932.png",
  },
  "8933": {
    name: "tiny dinos winter 2022 #8933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8933,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8933.png",
  },
  "8934": {
    name: "tiny dinos winter 2022 #8934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8934,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8934.png",
  },
  "8935": {
    name: "tiny dinos winter 2022 #8935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8935,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8935.png",
  },
  "8936": {
    name: "tiny dinos winter 2022 #8936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8936,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8936.png",
  },
  "8937": {
    name: "tiny dinos winter 2022 #8937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8937,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8937.png",
  },
  "8938": {
    name: "tiny dinos winter 2022 #8938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8938,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8938.png",
  },
  "8939": {
    name: "tiny dinos winter 2022 #8939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8939,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8939.png",
  },
  "8940": {
    name: "tiny dinos winter 2022 #8940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8940,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8940.png",
  },
  "8941": {
    name: "tiny dinos winter 2022 #8941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8941,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8941.png",
  },
  "8942": {
    name: "tiny dinos winter 2022 #8942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8942,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8942.png",
  },
  "8943": {
    name: "tiny dinos winter 2022 #8943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8943,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8943.png",
  },
  "8944": {
    name: "tiny dinos winter 2022 #8944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8944,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8944.png",
  },
  "8945": {
    name: "tiny dinos winter 2022 #8945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8945,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8945.png",
  },
  "8946": {
    name: "tiny dinos winter 2022 #8946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8946,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8946.png",
  },
  "8947": {
    name: "tiny dinos winter 2022 #8947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8947,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8947.png",
  },
  "8948": {
    name: "tiny dinos winter 2022 #8948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8948,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8948.png",
  },
  "8949": {
    name: "tiny dinos winter 2022 #8949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8949,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8949.png",
  },
  "8950": {
    name: "tiny dinos winter 2022 #8950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8950,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8950.png",
  },
  "8951": {
    name: "tiny dinos winter 2022 #8951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8951,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8951.png",
  },
  "8952": {
    name: "tiny dinos winter 2022 #8952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8952,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8952.png",
  },
  "8953": {
    name: "tiny dinos winter 2022 #8953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8953,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8953.png",
  },
  "8954": {
    name: "tiny dinos winter 2022 #8954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8954,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8954.png",
  },
  "8955": {
    name: "tiny dinos winter 2022 #8955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8955,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8955.png",
  },
  "8956": {
    name: "tiny dinos winter 2022 #8956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8956,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8956.png",
  },
  "8957": {
    name: "tiny dinos winter 2022 #8957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8957,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8957.png",
  },
  "8958": {
    name: "tiny dinos winter 2022 #8958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8958,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8958.png",
  },
  "8959": {
    name: "tiny dinos winter 2022 #8959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8959,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8959.png",
  },
  "8960": {
    name: "tiny dinos winter 2022 #8960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8960,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8960.png",
  },
  "8961": {
    name: "tiny dinos winter 2022 #8961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8961,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8961.png",
  },
  "8962": {
    name: "tiny dinos winter 2022 #8962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8962,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8962.png",
  },
  "8963": {
    name: "tiny dinos winter 2022 #8963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8963,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8963.png",
  },
  "8964": {
    name: "tiny dinos winter 2022 #8964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8964,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8964.png",
  },
  "8965": {
    name: "tiny dinos winter 2022 #8965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8965,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8965.png",
  },
  "8966": {
    name: "tiny dinos winter 2022 #8966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8966,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8966.png",
  },
  "8967": {
    name: "tiny dinos winter 2022 #8967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8967,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8967.png",
  },
  "8968": {
    name: "tiny dinos winter 2022 #8968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8968,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8968.png",
  },
  "8969": {
    name: "tiny dinos winter 2022 #8969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8969,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8969.png",
  },
  "8970": {
    name: "tiny dinos winter 2022 #8970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8970,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8970.png",
  },
  "8971": {
    name: "tiny dinos winter 2022 #8971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8971,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8971.png",
  },
  "8972": {
    name: "tiny dinos winter 2022 #8972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8972,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8972.png",
  },
  "8973": {
    name: "tiny dinos winter 2022 #8973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8973,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8973.png",
  },
  "8974": {
    name: "tiny dinos winter 2022 #8974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8974,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8974.png",
  },
  "8975": {
    name: "tiny dinos winter 2022 #8975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8975,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8975.png",
  },
  "8976": {
    name: "tiny dinos winter 2022 #8976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8976,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8976.png",
  },
  "8977": {
    name: "tiny dinos winter 2022 #8977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8977,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8977.png",
  },
  "8978": {
    name: "tiny dinos winter 2022 #8978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8978,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8978.png",
  },
  "8979": {
    name: "tiny dinos winter 2022 #8979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8979,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8979.png",
  },
  "8980": {
    name: "tiny dinos winter 2022 #8980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8980,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8980.png",
  },
  "8981": {
    name: "tiny dinos winter 2022 #8981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8981,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8981.png",
  },
  "8982": {
    name: "tiny dinos winter 2022 #8982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8982,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8982.png",
  },
  "8983": {
    name: "tiny dinos winter 2022 #8983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8983,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8983.png",
  },
  "8984": {
    name: "tiny dinos winter 2022 #8984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8984,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8984.png",
  },
  "8985": {
    name: "tiny dinos winter 2022 #8985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8985,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8985.png",
  },
  "8986": {
    name: "tiny dinos winter 2022 #8986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8986,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8986.png",
  },
  "8987": {
    name: "tiny dinos winter 2022 #8987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8987,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8987.png",
  },
  "8988": {
    name: "tiny dinos winter 2022 #8988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8988,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8988.png",
  },
  "8989": {
    name: "tiny dinos winter 2022 #8989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8989,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8989.png",
  },
  "8990": {
    name: "tiny dinos winter 2022 #8990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8990,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8990.png",
  },
  "8991": {
    name: "tiny dinos winter 2022 #8991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8991,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8991.png",
  },
  "8992": {
    name: "tiny dinos winter 2022 #8992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8992,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8992.png",
  },
  "8993": {
    name: "tiny dinos winter 2022 #8993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8993,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8993.png",
  },
  "8994": {
    name: "tiny dinos winter 2022 #8994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8994,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8994.png",
  },
  "8995": {
    name: "tiny dinos winter 2022 #8995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8995,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8995.png",
  },
  "8996": {
    name: "tiny dinos winter 2022 #8996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8996,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8996.png",
  },
  "8997": {
    name: "tiny dinos winter 2022 #8997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8997,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8997.png",
  },
  "8998": {
    name: "tiny dinos winter 2022 #8998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8998,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8998.png",
  },
  "8999": {
    name: "tiny dinos winter 2022 #8999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 8999,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/8999.png",
  },
  "9000": {
    name: "tiny dinos winter 2022 #9000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9000,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9000.png",
  },
  "9001": {
    name: "tiny dinos winter 2022 #9001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9001,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9001.png",
  },
  "9002": {
    name: "tiny dinos winter 2022 #9002",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9002,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9002.png",
  },
  "9003": {
    name: "tiny dinos winter 2022 #9003",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9003,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9003.png",
  },
  "9004": {
    name: "tiny dinos winter 2022 #9004",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9004,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9004.png",
  },
  "9005": {
    name: "tiny dinos winter 2022 #9005",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9005,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9005.png",
  },
  "9006": {
    name: "tiny dinos winter 2022 #9006",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9006,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9006.png",
  },
  "9007": {
    name: "tiny dinos winter 2022 #9007",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9007,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9007.png",
  },
  "9008": {
    name: "tiny dinos winter 2022 #9008",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9008,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9008.png",
  },
  "9009": {
    name: "tiny dinos winter 2022 #9009",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9009,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9009.png",
  },
  "9010": {
    name: "tiny dinos winter 2022 #9010",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9010,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9010.png",
  },
  "9011": {
    name: "tiny dinos winter 2022 #9011",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9011,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9011.png",
  },
  "9012": {
    name: "tiny dinos winter 2022 #9012",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9012,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9012.png",
  },
  "9013": {
    name: "tiny dinos winter 2022 #9013",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9013,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9013.png",
  },
  "9014": {
    name: "tiny dinos winter 2022 #9014",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9014,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9014.png",
  },
  "9015": {
    name: "tiny dinos winter 2022 #9015",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9015,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9015.png",
  },
  "9016": {
    name: "tiny dinos winter 2022 #9016",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9016,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9016.png",
  },
  "9017": {
    name: "tiny dinos winter 2022 #9017",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9017,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9017.png",
  },
  "9018": {
    name: "tiny dinos winter 2022 #9018",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9018,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9018.png",
  },
  "9019": {
    name: "tiny dinos winter 2022 #9019",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9019,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9019.png",
  },
  "9020": {
    name: "tiny dinos winter 2022 #9020",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9020,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9020.png",
  },
  "9021": {
    name: "tiny dinos winter 2022 #9021",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9021,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9021.png",
  },
  "9022": {
    name: "tiny dinos winter 2022 #9022",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9022,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9022.png",
  },
  "9023": {
    name: "tiny dinos winter 2022 #9023",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9023,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9023.png",
  },
  "9024": {
    name: "tiny dinos winter 2022 #9024",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9024,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9024.png",
  },
  "9025": {
    name: "tiny dinos winter 2022 #9025",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9025,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9025.png",
  },
  "9026": {
    name: "tiny dinos winter 2022 #9026",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9026,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9026.png",
  },
  "9027": {
    name: "tiny dinos winter 2022 #9027",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9027,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9027.png",
  },
  "9028": {
    name: "tiny dinos winter 2022 #9028",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9028,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9028.png",
  },
  "9029": {
    name: "tiny dinos winter 2022 #9029",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9029,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9029.png",
  },
  "9030": {
    name: "tiny dinos winter 2022 #9030",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9030,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9030.png",
  },
  "9031": {
    name: "tiny dinos winter 2022 #9031",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9031,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9031.png",
  },
  "9032": {
    name: "tiny dinos winter 2022 #9032",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9032,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9032.png",
  },
  "9033": {
    name: "tiny dinos winter 2022 #9033",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9033,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9033.png",
  },
  "9034": {
    name: "tiny dinos winter 2022 #9034",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9034,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9034.png",
  },
  "9035": {
    name: "tiny dinos winter 2022 #9035",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9035,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9035.png",
  },
  "9036": {
    name: "tiny dinos winter 2022 #9036",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9036,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9036.png",
  },
  "9037": {
    name: "tiny dinos winter 2022 #9037",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9037,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9037.png",
  },
  "9038": {
    name: "tiny dinos winter 2022 #9038",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9038,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9038.png",
  },
  "9039": {
    name: "tiny dinos winter 2022 #9039",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9039,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9039.png",
  },
  "9040": {
    name: "tiny dinos winter 2022 #9040",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9040,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9040.png",
  },
  "9041": {
    name: "tiny dinos winter 2022 #9041",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9041,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9041.png",
  },
  "9042": {
    name: "tiny dinos winter 2022 #9042",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9042,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9042.png",
  },
  "9043": {
    name: "tiny dinos winter 2022 #9043",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9043,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9043.png",
  },
  "9044": {
    name: "tiny dinos winter 2022 #9044",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9044,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9044.png",
  },
  "9045": {
    name: "tiny dinos winter 2022 #9045",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9045,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9045.png",
  },
  "9046": {
    name: "tiny dinos winter 2022 #9046",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9046,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9046.png",
  },
  "9047": {
    name: "tiny dinos winter 2022 #9047",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9047,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9047.png",
  },
  "9048": {
    name: "tiny dinos winter 2022 #9048",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9048,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9048.png",
  },
  "9049": {
    name: "tiny dinos winter 2022 #9049",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9049,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9049.png",
  },
  "9050": {
    name: "tiny dinos winter 2022 #9050",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9050,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9050.png",
  },
  "9051": {
    name: "tiny dinos winter 2022 #9051",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9051,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9051.png",
  },
  "9052": {
    name: "tiny dinos winter 2022 #9052",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9052,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9052.png",
  },
  "9053": {
    name: "tiny dinos winter 2022 #9053",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9053,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9053.png",
  },
  "9054": {
    name: "tiny dinos winter 2022 #9054",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9054,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9054.png",
  },
  "9055": {
    name: "tiny dinos winter 2022 #9055",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9055,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9055.png",
  },
  "9056": {
    name: "tiny dinos winter 2022 #9056",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9056,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9056.png",
  },
  "9057": {
    name: "tiny dinos winter 2022 #9057",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9057,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9057.png",
  },
  "9058": {
    name: "tiny dinos winter 2022 #9058",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9058,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9058.png",
  },
  "9059": {
    name: "tiny dinos winter 2022 #9059",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9059,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9059.png",
  },
  "9060": {
    name: "tiny dinos winter 2022 #9060",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9060,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9060.png",
  },
  "9061": {
    name: "tiny dinos winter 2022 #9061",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9061,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9061.png",
  },
  "9062": {
    name: "tiny dinos winter 2022 #9062",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9062,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9062.png",
  },
  "9063": {
    name: "tiny dinos winter 2022 #9063",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9063,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9063.png",
  },
  "9064": {
    name: "tiny dinos winter 2022 #9064",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9064,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9064.png",
  },
  "9065": {
    name: "tiny dinos winter 2022 #9065",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9065,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9065.png",
  },
  "9066": {
    name: "tiny dinos winter 2022 #9066",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9066,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9066.png",
  },
  "9067": {
    name: "tiny dinos winter 2022 #9067",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9067,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9067.png",
  },
  "9068": {
    name: "tiny dinos winter 2022 #9068",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9068,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9068.png",
  },
  "9069": {
    name: "tiny dinos winter 2022 #9069",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9069,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9069.png",
  },
  "9070": {
    name: "tiny dinos winter 2022 #9070",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9070,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9070.png",
  },
  "9071": {
    name: "tiny dinos winter 2022 #9071",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9071,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9071.png",
  },
  "9072": {
    name: "tiny dinos winter 2022 #9072",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9072,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9072.png",
  },
  "9073": {
    name: "tiny dinos winter 2022 #9073",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9073,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9073.png",
  },
  "9074": {
    name: "tiny dinos winter 2022 #9074",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9074,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9074.png",
  },
  "9075": {
    name: "tiny dinos winter 2022 #9075",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9075,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9075.png",
  },
  "9076": {
    name: "tiny dinos winter 2022 #9076",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9076,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9076.png",
  },
  "9077": {
    name: "tiny dinos winter 2022 #9077",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9077,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9077.png",
  },
  "9078": {
    name: "tiny dinos winter 2022 #9078",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9078,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9078.png",
  },
  "9079": {
    name: "tiny dinos winter 2022 #9079",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9079,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9079.png",
  },
  "9080": {
    name: "tiny dinos winter 2022 #9080",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9080,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9080.png",
  },
  "9081": {
    name: "tiny dinos winter 2022 #9081",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9081,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9081.png",
  },
  "9082": {
    name: "tiny dinos winter 2022 #9082",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9082,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9082.png",
  },
  "9083": {
    name: "tiny dinos winter 2022 #9083",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9083,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9083.png",
  },
  "9084": {
    name: "tiny dinos winter 2022 #9084",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9084,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9084.png",
  },
  "9085": {
    name: "tiny dinos winter 2022 #9085",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9085,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9085.png",
  },
  "9086": {
    name: "tiny dinos winter 2022 #9086",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9086,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9086.png",
  },
  "9087": {
    name: "tiny dinos winter 2022 #9087",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9087,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9087.png",
  },
  "9088": {
    name: "tiny dinos winter 2022 #9088",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9088,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9088.png",
  },
  "9089": {
    name: "tiny dinos winter 2022 #9089",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9089,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9089.png",
  },
  "9090": {
    name: "tiny dinos winter 2022 #9090",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9090,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9090.png",
  },
  "9091": {
    name: "tiny dinos winter 2022 #9091",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9091,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9091.png",
  },
  "9092": {
    name: "tiny dinos winter 2022 #9092",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9092,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9092.png",
  },
  "9093": {
    name: "tiny dinos winter 2022 #9093",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9093,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9093.png",
  },
  "9094": {
    name: "tiny dinos winter 2022 #9094",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9094,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9094.png",
  },
  "9095": {
    name: "tiny dinos winter 2022 #9095",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9095,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9095.png",
  },
  "9096": {
    name: "tiny dinos winter 2022 #9096",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9096,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9096.png",
  },
  "9097": {
    name: "tiny dinos winter 2022 #9097",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9097,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9097.png",
  },
  "9098": {
    name: "tiny dinos winter 2022 #9098",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9098,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9098.png",
  },
  "9099": {
    name: "tiny dinos winter 2022 #9099",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9099,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9099.png",
  },
  "9100": {
    name: "tiny dinos winter 2022 #9100",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9100,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9100.png",
  },
  "9101": {
    name: "tiny dinos winter 2022 #9101",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9101,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9101.png",
  },
  "9102": {
    name: "tiny dinos winter 2022 #9102",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9102,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9102.png",
  },
  "9103": {
    name: "tiny dinos winter 2022 #9103",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9103,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9103.png",
  },
  "9104": {
    name: "tiny dinos winter 2022 #9104",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9104,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9104.png",
  },
  "9105": {
    name: "tiny dinos winter 2022 #9105",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9105,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9105.png",
  },
  "9106": {
    name: "tiny dinos winter 2022 #9106",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9106,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9106.png",
  },
  "9107": {
    name: "tiny dinos winter 2022 #9107",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9107,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9107.png",
  },
  "9108": {
    name: "tiny dinos winter 2022 #9108",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9108,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9108.png",
  },
  "9109": {
    name: "tiny dinos winter 2022 #9109",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9109,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9109.png",
  },
  "9110": {
    name: "tiny dinos winter 2022 #9110",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9110,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9110.png",
  },
  "9111": {
    name: "tiny dinos winter 2022 #9111",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9111,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9111.png",
  },
  "9112": {
    name: "tiny dinos winter 2022 #9112",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9112,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9112.png",
  },
  "9113": {
    name: "tiny dinos winter 2022 #9113",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9113,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9113.png",
  },
  "9114": {
    name: "tiny dinos winter 2022 #9114",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9114,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9114.png",
  },
  "9115": {
    name: "tiny dinos winter 2022 #9115",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9115,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9115.png",
  },
  "9116": {
    name: "tiny dinos winter 2022 #9116",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9116,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9116.png",
  },
  "9117": {
    name: "tiny dinos winter 2022 #9117",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9117,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9117.png",
  },
  "9118": {
    name: "tiny dinos winter 2022 #9118",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9118,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9118.png",
  },
  "9119": {
    name: "tiny dinos winter 2022 #9119",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9119,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9119.png",
  },
  "9120": {
    name: "tiny dinos winter 2022 #9120",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9120,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9120.png",
  },
  "9121": {
    name: "tiny dinos winter 2022 #9121",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9121,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9121.png",
  },
  "9122": {
    name: "tiny dinos winter 2022 #9122",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9122,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9122.png",
  },
  "9123": {
    name: "tiny dinos winter 2022 #9123",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9123,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9123.png",
  },
  "9124": {
    name: "tiny dinos winter 2022 #9124",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9124,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9124.png",
  },
  "9125": {
    name: "tiny dinos winter 2022 #9125",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9125,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9125.png",
  },
  "9126": {
    name: "tiny dinos winter 2022 #9126",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9126,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9126.png",
  },
  "9127": {
    name: "tiny dinos winter 2022 #9127",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9127,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9127.png",
  },
  "9128": {
    name: "tiny dinos winter 2022 #9128",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9128,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9128.png",
  },
  "9129": {
    name: "tiny dinos winter 2022 #9129",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9129,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9129.png",
  },
  "9130": {
    name: "tiny dinos winter 2022 #9130",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9130,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9130.png",
  },
  "9131": {
    name: "tiny dinos winter 2022 #9131",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9131,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9131.png",
  },
  "9132": {
    name: "tiny dinos winter 2022 #9132",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9132,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9132.png",
  },
  "9133": {
    name: "tiny dinos winter 2022 #9133",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9133,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9133.png",
  },
  "9134": {
    name: "tiny dinos winter 2022 #9134",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9134,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9134.png",
  },
  "9135": {
    name: "tiny dinos winter 2022 #9135",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9135,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9135.png",
  },
  "9136": {
    name: "tiny dinos winter 2022 #9136",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9136,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9136.png",
  },
  "9137": {
    name: "tiny dinos winter 2022 #9137",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9137,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9137.png",
  },
  "9138": {
    name: "tiny dinos winter 2022 #9138",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9138,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9138.png",
  },
  "9139": {
    name: "tiny dinos winter 2022 #9139",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9139,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9139.png",
  },
  "9140": {
    name: "tiny dinos winter 2022 #9140",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9140,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9140.png",
  },
  "9141": {
    name: "tiny dinos winter 2022 #9141",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9141,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9141.png",
  },
  "9142": {
    name: "tiny dinos winter 2022 #9142",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9142,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9142.png",
  },
  "9143": {
    name: "tiny dinos winter 2022 #9143",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9143,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9143.png",
  },
  "9144": {
    name: "tiny dinos winter 2022 #9144",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9144,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9144.png",
  },
  "9145": {
    name: "tiny dinos winter 2022 #9145",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9145,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9145.png",
  },
  "9146": {
    name: "tiny dinos winter 2022 #9146",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9146,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9146.png",
  },
  "9147": {
    name: "tiny dinos winter 2022 #9147",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9147,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9147.png",
  },
  "9148": {
    name: "tiny dinos winter 2022 #9148",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9148,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9148.png",
  },
  "9149": {
    name: "tiny dinos winter 2022 #9149",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9149,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9149.png",
  },
  "9150": {
    name: "tiny dinos winter 2022 #9150",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9150,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9150.png",
  },
  "9151": {
    name: "tiny dinos winter 2022 #9151",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9151,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9151.png",
  },
  "9152": {
    name: "tiny dinos winter 2022 #9152",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9152,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9152.png",
  },
  "9153": {
    name: "tiny dinos winter 2022 #9153",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9153,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9153.png",
  },
  "9154": {
    name: "tiny dinos winter 2022 #9154",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9154,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9154.png",
  },
  "9155": {
    name: "tiny dinos winter 2022 #9155",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9155,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9155.png",
  },
  "9156": {
    name: "tiny dinos winter 2022 #9156",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9156,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9156.png",
  },
  "9157": {
    name: "tiny dinos winter 2022 #9157",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9157,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9157.png",
  },
  "9158": {
    name: "tiny dinos winter 2022 #9158",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9158,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9158.png",
  },
  "9159": {
    name: "tiny dinos winter 2022 #9159",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9159,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9159.png",
  },
  "9160": {
    name: "tiny dinos winter 2022 #9160",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9160,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9160.png",
  },
  "9161": {
    name: "tiny dinos winter 2022 #9161",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9161,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9161.png",
  },
  "9162": {
    name: "tiny dinos winter 2022 #9162",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9162,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9162.png",
  },
  "9163": {
    name: "tiny dinos winter 2022 #9163",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9163,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9163.png",
  },
  "9164": {
    name: "tiny dinos winter 2022 #9164",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9164,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9164.png",
  },
  "9165": {
    name: "tiny dinos winter 2022 #9165",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9165,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9165.png",
  },
  "9166": {
    name: "tiny dinos winter 2022 #9166",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9166,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9166.png",
  },
  "9167": {
    name: "tiny dinos winter 2022 #9167",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9167,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9167.png",
  },
  "9168": {
    name: "tiny dinos winter 2022 #9168",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9168,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9168.png",
  },
  "9169": {
    name: "tiny dinos winter 2022 #9169",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9169,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9169.png",
  },
  "9170": {
    name: "tiny dinos winter 2022 #9170",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9170,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9170.png",
  },
  "9171": {
    name: "tiny dinos winter 2022 #9171",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9171,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9171.png",
  },
  "9172": {
    name: "tiny dinos winter 2022 #9172",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9172,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9172.png",
  },
  "9173": {
    name: "tiny dinos winter 2022 #9173",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9173,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9173.png",
  },
  "9174": {
    name: "tiny dinos winter 2022 #9174",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9174,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9174.png",
  },
  "9175": {
    name: "tiny dinos winter 2022 #9175",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9175,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9175.png",
  },
  "9176": {
    name: "tiny dinos winter 2022 #9176",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9176,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9176.png",
  },
  "9177": {
    name: "tiny dinos winter 2022 #9177",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9177,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9177.png",
  },
  "9178": {
    name: "tiny dinos winter 2022 #9178",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9178,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9178.png",
  },
  "9179": {
    name: "tiny dinos winter 2022 #9179",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9179,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9179.png",
  },
  "9180": {
    name: "tiny dinos winter 2022 #9180",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9180,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9180.png",
  },
  "9181": {
    name: "tiny dinos winter 2022 #9181",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9181,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9181.png",
  },
  "9182": {
    name: "tiny dinos winter 2022 #9182",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9182,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9182.png",
  },
  "9183": {
    name: "tiny dinos winter 2022 #9183",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9183,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9183.png",
  },
  "9184": {
    name: "tiny dinos winter 2022 #9184",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9184,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9184.png",
  },
  "9185": {
    name: "tiny dinos winter 2022 #9185",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9185,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9185.png",
  },
  "9186": {
    name: "tiny dinos winter 2022 #9186",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9186,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9186.png",
  },
  "9187": {
    name: "tiny dinos winter 2022 #9187",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9187,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9187.png",
  },
  "9188": {
    name: "tiny dinos winter 2022 #9188",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9188,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9188.png",
  },
  "9189": {
    name: "tiny dinos winter 2022 #9189",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9189,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9189.png",
  },
  "9190": {
    name: "tiny dinos winter 2022 #9190",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9190,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9190.png",
  },
  "9191": {
    name: "tiny dinos winter 2022 #9191",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9191,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9191.png",
  },
  "9192": {
    name: "tiny dinos winter 2022 #9192",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9192,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9192.png",
  },
  "9193": {
    name: "tiny dinos winter 2022 #9193",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9193,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9193.png",
  },
  "9194": {
    name: "tiny dinos winter 2022 #9194",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9194,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9194.png",
  },
  "9195": {
    name: "tiny dinos winter 2022 #9195",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9195,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9195.png",
  },
  "9196": {
    name: "tiny dinos winter 2022 #9196",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9196,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9196.png",
  },
  "9197": {
    name: "tiny dinos winter 2022 #9197",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9197,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9197.png",
  },
  "9198": {
    name: "tiny dinos winter 2022 #9198",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9198,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9198.png",
  },
  "9199": {
    name: "tiny dinos winter 2022 #9199",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9199,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9199.png",
  },
  "9200": {
    name: "tiny dinos winter 2022 #9200",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9200,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9200.png",
  },
  "9201": {
    name: "tiny dinos winter 2022 #9201",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9201,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9201.png",
  },
  "9202": {
    name: "tiny dinos winter 2022 #9202",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9202,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9202.png",
  },
  "9203": {
    name: "tiny dinos winter 2022 #9203",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9203,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9203.png",
  },
  "9204": {
    name: "tiny dinos winter 2022 #9204",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9204,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9204.png",
  },
  "9205": {
    name: "tiny dinos winter 2022 #9205",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9205,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9205.png",
  },
  "9206": {
    name: "tiny dinos winter 2022 #9206",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9206,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9206.png",
  },
  "9207": {
    name: "tiny dinos winter 2022 #9207",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9207,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9207.png",
  },
  "9208": {
    name: "tiny dinos winter 2022 #9208",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9208,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9208.png",
  },
  "9209": {
    name: "tiny dinos winter 2022 #9209",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9209,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9209.png",
  },
  "9210": {
    name: "tiny dinos winter 2022 #9210",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9210,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9210.png",
  },
  "9211": {
    name: "tiny dinos winter 2022 #9211",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9211,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9211.png",
  },
  "9212": {
    name: "tiny dinos winter 2022 #9212",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9212,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9212.png",
  },
  "9213": {
    name: "tiny dinos winter 2022 #9213",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9213,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9213.png",
  },
  "9214": {
    name: "tiny dinos winter 2022 #9214",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9214,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9214.png",
  },
  "9215": {
    name: "tiny dinos winter 2022 #9215",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9215,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9215.png",
  },
  "9216": {
    name: "tiny dinos winter 2022 #9216",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9216,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9216.png",
  },
  "9217": {
    name: "tiny dinos winter 2022 #9217",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9217,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9217.png",
  },
  "9218": {
    name: "tiny dinos winter 2022 #9218",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9218,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9218.png",
  },
  "9219": {
    name: "tiny dinos winter 2022 #9219",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9219,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9219.png",
  },
  "9220": {
    name: "tiny dinos winter 2022 #9220",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9220,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9220.png",
  },
  "9221": {
    name: "tiny dinos winter 2022 #9221",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9221,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9221.png",
  },
  "9222": {
    name: "tiny dinos winter 2022 #9222",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9222,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9222.png",
  },
  "9223": {
    name: "tiny dinos winter 2022 #9223",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9223,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9223.png",
  },
  "9224": {
    name: "tiny dinos winter 2022 #9224",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9224,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9224.png",
  },
  "9225": {
    name: "tiny dinos winter 2022 #9225",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9225,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9225.png",
  },
  "9226": {
    name: "tiny dinos winter 2022 #9226",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9226,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9226.png",
  },
  "9227": {
    name: "tiny dinos winter 2022 #9227",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9227,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9227.png",
  },
  "9228": {
    name: "tiny dinos winter 2022 #9228",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9228,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9228.png",
  },
  "9229": {
    name: "tiny dinos winter 2022 #9229",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9229,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9229.png",
  },
  "9230": {
    name: "tiny dinos winter 2022 #9230",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9230,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9230.png",
  },
  "9231": {
    name: "tiny dinos winter 2022 #9231",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9231,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9231.png",
  },
  "9232": {
    name: "tiny dinos winter 2022 #9232",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9232,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9232.png",
  },
  "9233": {
    name: "tiny dinos winter 2022 #9233",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9233,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9233.png",
  },
  "9234": {
    name: "tiny dinos winter 2022 #9234",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9234,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9234.png",
  },
  "9235": {
    name: "tiny dinos winter 2022 #9235",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9235,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9235.png",
  },
  "9236": {
    name: "tiny dinos winter 2022 #9236",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9236,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9236.png",
  },
  "9237": {
    name: "tiny dinos winter 2022 #9237",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9237,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9237.png",
  },
  "9238": {
    name: "tiny dinos winter 2022 #9238",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9238,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9238.png",
  },
  "9239": {
    name: "tiny dinos winter 2022 #9239",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9239,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9239.png",
  },
  "9240": {
    name: "tiny dinos winter 2022 #9240",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9240,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9240.png",
  },
  "9241": {
    name: "tiny dinos winter 2022 #9241",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9241,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9241.png",
  },
  "9242": {
    name: "tiny dinos winter 2022 #9242",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9242,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9242.png",
  },
  "9243": {
    name: "tiny dinos winter 2022 #9243",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9243,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9243.png",
  },
  "9244": {
    name: "tiny dinos winter 2022 #9244",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9244,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9244.png",
  },
  "9245": {
    name: "tiny dinos winter 2022 #9245",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9245,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9245.png",
  },
  "9246": {
    name: "tiny dinos winter 2022 #9246",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9246,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9246.png",
  },
  "9247": {
    name: "tiny dinos winter 2022 #9247",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9247,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9247.png",
  },
  "9248": {
    name: "tiny dinos winter 2022 #9248",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9248,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9248.png",
  },
  "9249": {
    name: "tiny dinos winter 2022 #9249",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9249,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9249.png",
  },
  "9250": {
    name: "tiny dinos winter 2022 #9250",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9250,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9250.png",
  },
  "9251": {
    name: "tiny dinos winter 2022 #9251",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9251,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9251.png",
  },
  "9252": {
    name: "tiny dinos winter 2022 #9252",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9252,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9252.png",
  },
  "9253": {
    name: "tiny dinos winter 2022 #9253",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9253,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9253.png",
  },
  "9254": {
    name: "tiny dinos winter 2022 #9254",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9254,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9254.png",
  },
  "9255": {
    name: "tiny dinos winter 2022 #9255",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9255,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9255.png",
  },
  "9256": {
    name: "tiny dinos winter 2022 #9256",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9256,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9256.png",
  },
  "9257": {
    name: "tiny dinos winter 2022 #9257",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9257,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9257.png",
  },
  "9258": {
    name: "tiny dinos winter 2022 #9258",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9258,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9258.png",
  },
  "9259": {
    name: "tiny dinos winter 2022 #9259",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9259,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9259.png",
  },
  "9260": {
    name: "tiny dinos winter 2022 #9260",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9260,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9260.png",
  },
  "9261": {
    name: "tiny dinos winter 2022 #9261",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9261,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9261.png",
  },
  "9262": {
    name: "tiny dinos winter 2022 #9262",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9262,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9262.png",
  },
  "9263": {
    name: "tiny dinos winter 2022 #9263",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9263,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9263.png",
  },
  "9264": {
    name: "tiny dinos winter 2022 #9264",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9264,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9264.png",
  },
  "9265": {
    name: "tiny dinos winter 2022 #9265",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9265,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9265.png",
  },
  "9266": {
    name: "tiny dinos winter 2022 #9266",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9266,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9266.png",
  },
  "9267": {
    name: "tiny dinos winter 2022 #9267",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9267,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9267.png",
  },
  "9268": {
    name: "tiny dinos winter 2022 #9268",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9268,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9268.png",
  },
  "9269": {
    name: "tiny dinos winter 2022 #9269",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9269,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9269.png",
  },
  "9270": {
    name: "tiny dinos winter 2022 #9270",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9270,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9270.png",
  },
  "9271": {
    name: "tiny dinos winter 2022 #9271",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9271,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9271.png",
  },
  "9272": {
    name: "tiny dinos winter 2022 #9272",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9272,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9272.png",
  },
  "9273": {
    name: "tiny dinos winter 2022 #9273",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9273,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9273.png",
  },
  "9274": {
    name: "tiny dinos winter 2022 #9274",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9274,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9274.png",
  },
  "9275": {
    name: "tiny dinos winter 2022 #9275",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9275,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9275.png",
  },
  "9276": {
    name: "tiny dinos winter 2022 #9276",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9276,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9276.png",
  },
  "9277": {
    name: "tiny dinos winter 2022 #9277",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9277,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9277.png",
  },
  "9278": {
    name: "tiny dinos winter 2022 #9278",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9278,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9278.png",
  },
  "9279": {
    name: "tiny dinos winter 2022 #9279",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9279,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9279.png",
  },
  "9280": {
    name: "tiny dinos winter 2022 #9280",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9280,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9280.png",
  },
  "9281": {
    name: "tiny dinos winter 2022 #9281",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9281,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9281.png",
  },
  "9282": {
    name: "tiny dinos winter 2022 #9282",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9282,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9282.png",
  },
  "9283": {
    name: "tiny dinos winter 2022 #9283",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9283,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9283.png",
  },
  "9284": {
    name: "tiny dinos winter 2022 #9284",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9284,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9284.png",
  },
  "9285": {
    name: "tiny dinos winter 2022 #9285",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9285,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9285.png",
  },
  "9286": {
    name: "tiny dinos winter 2022 #9286",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9286,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9286.png",
  },
  "9287": {
    name: "tiny dinos winter 2022 #9287",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9287,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9287.png",
  },
  "9288": {
    name: "tiny dinos winter 2022 #9288",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9288,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9288.png",
  },
  "9289": {
    name: "tiny dinos winter 2022 #9289",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9289,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9289.png",
  },
  "9290": {
    name: "tiny dinos winter 2022 #9290",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9290,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9290.png",
  },
  "9291": {
    name: "tiny dinos winter 2022 #9291",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9291,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9291.png",
  },
  "9292": {
    name: "tiny dinos winter 2022 #9292",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9292,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9292.png",
  },
  "9293": {
    name: "tiny dinos winter 2022 #9293",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9293,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9293.png",
  },
  "9294": {
    name: "tiny dinos winter 2022 #9294",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9294,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9294.png",
  },
  "9295": {
    name: "tiny dinos winter 2022 #9295",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9295,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9295.png",
  },
  "9296": {
    name: "tiny dinos winter 2022 #9296",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9296,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9296.png",
  },
  "9297": {
    name: "tiny dinos winter 2022 #9297",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9297,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9297.png",
  },
  "9298": {
    name: "tiny dinos winter 2022 #9298",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9298,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9298.png",
  },
  "9299": {
    name: "tiny dinos winter 2022 #9299",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9299,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9299.png",
  },
  "9300": {
    name: "tiny dinos winter 2022 #9300",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9300,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9300.png",
  },
  "9301": {
    name: "tiny dinos winter 2022 #9301",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9301,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9301.png",
  },
  "9302": {
    name: "tiny dinos winter 2022 #9302",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9302,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9302.png",
  },
  "9303": {
    name: "tiny dinos winter 2022 #9303",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9303,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9303.png",
  },
  "9304": {
    name: "tiny dinos winter 2022 #9304",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9304,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9304.png",
  },
  "9305": {
    name: "tiny dinos winter 2022 #9305",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9305,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9305.png",
  },
  "9306": {
    name: "tiny dinos winter 2022 #9306",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9306,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9306.png",
  },
  "9307": {
    name: "tiny dinos winter 2022 #9307",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9307,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9307.png",
  },
  "9308": {
    name: "tiny dinos winter 2022 #9308",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9308,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9308.png",
  },
  "9309": {
    name: "tiny dinos winter 2022 #9309",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9309,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9309.png",
  },
  "9310": {
    name: "tiny dinos winter 2022 #9310",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9310,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9310.png",
  },
  "9311": {
    name: "tiny dinos winter 2022 #9311",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9311,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9311.png",
  },
  "9312": {
    name: "tiny dinos winter 2022 #9312",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9312,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9312.png",
  },
  "9313": {
    name: "tiny dinos winter 2022 #9313",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9313,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9313.png",
  },
  "9314": {
    name: "tiny dinos winter 2022 #9314",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9314,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9314.png",
  },
  "9315": {
    name: "tiny dinos winter 2022 #9315",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9315,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9315.png",
  },
  "9316": {
    name: "tiny dinos winter 2022 #9316",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9316,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9316.png",
  },
  "9317": {
    name: "tiny dinos winter 2022 #9317",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9317,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9317.png",
  },
  "9318": {
    name: "tiny dinos winter 2022 #9318",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9318,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9318.png",
  },
  "9319": {
    name: "tiny dinos winter 2022 #9319",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9319,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9319.png",
  },
  "9320": {
    name: "tiny dinos winter 2022 #9320",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9320,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9320.png",
  },
  "9321": {
    name: "tiny dinos winter 2022 #9321",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9321,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9321.png",
  },
  "9322": {
    name: "tiny dinos winter 2022 #9322",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9322,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9322.png",
  },
  "9323": {
    name: "tiny dinos winter 2022 #9323",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9323,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9323.png",
  },
  "9324": {
    name: "tiny dinos winter 2022 #9324",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9324,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9324.png",
  },
  "9325": {
    name: "tiny dinos winter 2022 #9325",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9325,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9325.png",
  },
  "9326": {
    name: "tiny dinos winter 2022 #9326",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9326,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9326.png",
  },
  "9327": {
    name: "tiny dinos winter 2022 #9327",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9327,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9327.png",
  },
  "9328": {
    name: "tiny dinos winter 2022 #9328",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9328,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9328.png",
  },
  "9329": {
    name: "tiny dinos winter 2022 #9329",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9329,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9329.png",
  },
  "9330": {
    name: "tiny dinos winter 2022 #9330",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9330,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9330.png",
  },
  "9331": {
    name: "tiny dinos winter 2022 #9331",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9331,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9331.png",
  },
  "9332": {
    name: "tiny dinos winter 2022 #9332",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9332,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9332.png",
  },
  "9333": {
    name: "tiny dinos winter 2022 #9333",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9333,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9333.png",
  },
  "9334": {
    name: "tiny dinos winter 2022 #9334",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9334,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9334.png",
  },
  "9335": {
    name: "tiny dinos winter 2022 #9335",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9335,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9335.png",
  },
  "9336": {
    name: "tiny dinos winter 2022 #9336",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9336,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9336.png",
  },
  "9337": {
    name: "tiny dinos winter 2022 #9337",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9337,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9337.png",
  },
  "9338": {
    name: "tiny dinos winter 2022 #9338",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9338,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9338.png",
  },
  "9339": {
    name: "tiny dinos winter 2022 #9339",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9339,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9339.png",
  },
  "9340": {
    name: "tiny dinos winter 2022 #9340",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9340,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9340.png",
  },
  "9341": {
    name: "tiny dinos winter 2022 #9341",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9341,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9341.png",
  },
  "9342": {
    name: "tiny dinos winter 2022 #9342",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9342,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9342.png",
  },
  "9343": {
    name: "tiny dinos winter 2022 #9343",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9343,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9343.png",
  },
  "9344": {
    name: "tiny dinos winter 2022 #9344",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9344,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9344.png",
  },
  "9345": {
    name: "tiny dinos winter 2022 #9345",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9345,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9345.png",
  },
  "9346": {
    name: "tiny dinos winter 2022 #9346",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9346,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9346.png",
  },
  "9347": {
    name: "tiny dinos winter 2022 #9347",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9347,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9347.png",
  },
  "9348": {
    name: "tiny dinos winter 2022 #9348",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9348,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9348.png",
  },
  "9349": {
    name: "tiny dinos winter 2022 #9349",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9349,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9349.png",
  },
  "9350": {
    name: "tiny dinos winter 2022 #9350",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9350,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9350.png",
  },
  "9351": {
    name: "tiny dinos winter 2022 #9351",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9351,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9351.png",
  },
  "9352": {
    name: "tiny dinos winter 2022 #9352",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9352,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9352.png",
  },
  "9353": {
    name: "tiny dinos winter 2022 #9353",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9353,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9353.png",
  },
  "9354": {
    name: "tiny dinos winter 2022 #9354",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9354,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9354.png",
  },
  "9355": {
    name: "tiny dinos winter 2022 #9355",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9355,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9355.png",
  },
  "9356": {
    name: "tiny dinos winter 2022 #9356",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9356,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9356.png",
  },
  "9357": {
    name: "tiny dinos winter 2022 #9357",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9357,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9357.png",
  },
  "9358": {
    name: "tiny dinos winter 2022 #9358",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9358,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9358.png",
  },
  "9359": {
    name: "tiny dinos winter 2022 #9359",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9359,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9359.png",
  },
  "9360": {
    name: "tiny dinos winter 2022 #9360",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9360,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9360.png",
  },
  "9361": {
    name: "tiny dinos winter 2022 #9361",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9361,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9361.png",
  },
  "9362": {
    name: "tiny dinos winter 2022 #9362",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9362,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9362.png",
  },
  "9363": {
    name: "tiny dinos winter 2022 #9363",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9363,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9363.png",
  },
  "9364": {
    name: "tiny dinos winter 2022 #9364",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9364,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9364.png",
  },
  "9365": {
    name: "tiny dinos winter 2022 #9365",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9365,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9365.png",
  },
  "9366": {
    name: "tiny dinos winter 2022 #9366",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9366,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9366.png",
  },
  "9367": {
    name: "tiny dinos winter 2022 #9367",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9367,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9367.png",
  },
  "9368": {
    name: "tiny dinos winter 2022 #9368",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9368,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9368.png",
  },
  "9369": {
    name: "tiny dinos winter 2022 #9369",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9369,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9369.png",
  },
  "9370": {
    name: "tiny dinos winter 2022 #9370",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9370,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9370.png",
  },
  "9371": {
    name: "tiny dinos winter 2022 #9371",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9371,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9371.png",
  },
  "9372": {
    name: "tiny dinos winter 2022 #9372",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9372,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9372.png",
  },
  "9373": {
    name: "tiny dinos winter 2022 #9373",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9373,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9373.png",
  },
  "9374": {
    name: "tiny dinos winter 2022 #9374",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9374,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9374.png",
  },
  "9375": {
    name: "tiny dinos winter 2022 #9375",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9375,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9375.png",
  },
  "9376": {
    name: "tiny dinos winter 2022 #9376",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9376,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9376.png",
  },
  "9377": {
    name: "tiny dinos winter 2022 #9377",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9377,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9377.png",
  },
  "9378": {
    name: "tiny dinos winter 2022 #9378",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9378,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9378.png",
  },
  "9379": {
    name: "tiny dinos winter 2022 #9379",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9379,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9379.png",
  },
  "9380": {
    name: "tiny dinos winter 2022 #9380",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9380,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9380.png",
  },
  "9381": {
    name: "tiny dinos winter 2022 #9381",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9381,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9381.png",
  },
  "9382": {
    name: "tiny dinos winter 2022 #9382",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9382,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9382.png",
  },
  "9383": {
    name: "tiny dinos winter 2022 #9383",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9383,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9383.png",
  },
  "9384": {
    name: "tiny dinos winter 2022 #9384",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9384,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9384.png",
  },
  "9385": {
    name: "tiny dinos winter 2022 #9385",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9385,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9385.png",
  },
  "9386": {
    name: "tiny dinos winter 2022 #9386",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9386,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9386.png",
  },
  "9387": {
    name: "tiny dinos winter 2022 #9387",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9387,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9387.png",
  },
  "9388": {
    name: "tiny dinos winter 2022 #9388",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9388,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9388.png",
  },
  "9389": {
    name: "tiny dinos winter 2022 #9389",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9389,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9389.png",
  },
  "9390": {
    name: "tiny dinos winter 2022 #9390",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9390,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9390.png",
  },
  "9391": {
    name: "tiny dinos winter 2022 #9391",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9391,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9391.png",
  },
  "9392": {
    name: "tiny dinos winter 2022 #9392",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9392,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9392.png",
  },
  "9393": {
    name: "tiny dinos winter 2022 #9393",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9393,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9393.png",
  },
  "9394": {
    name: "tiny dinos winter 2022 #9394",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9394,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9394.png",
  },
  "9395": {
    name: "tiny dinos winter 2022 #9395",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9395,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9395.png",
  },
  "9396": {
    name: "tiny dinos winter 2022 #9396",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9396,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9396.png",
  },
  "9397": {
    name: "tiny dinos winter 2022 #9397",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9397,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9397.png",
  },
  "9398": {
    name: "tiny dinos winter 2022 #9398",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9398,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9398.png",
  },
  "9399": {
    name: "tiny dinos winter 2022 #9399",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9399,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9399.png",
  },
  "9400": {
    name: "tiny dinos winter 2022 #9400",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9400,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9400.png",
  },
  "9401": {
    name: "tiny dinos winter 2022 #9401",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9401,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9401.png",
  },
  "9402": {
    name: "tiny dinos winter 2022 #9402",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9402,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9402.png",
  },
  "9403": {
    name: "tiny dinos winter 2022 #9403",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9403,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9403.png",
  },
  "9404": {
    name: "tiny dinos winter 2022 #9404",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9404,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9404.png",
  },
  "9405": {
    name: "tiny dinos winter 2022 #9405",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9405,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9405.png",
  },
  "9406": {
    name: "tiny dinos winter 2022 #9406",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9406,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9406.png",
  },
  "9407": {
    name: "tiny dinos winter 2022 #9407",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9407,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9407.png",
  },
  "9408": {
    name: "tiny dinos winter 2022 #9408",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9408,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9408.png",
  },
  "9409": {
    name: "tiny dinos winter 2022 #9409",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9409,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9409.png",
  },
  "9410": {
    name: "tiny dinos winter 2022 #9410",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9410,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9410.png",
  },
  "9411": {
    name: "tiny dinos winter 2022 #9411",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9411,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9411.png",
  },
  "9412": {
    name: "tiny dinos winter 2022 #9412",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9412,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9412.png",
  },
  "9413": {
    name: "tiny dinos winter 2022 #9413",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9413,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9413.png",
  },
  "9414": {
    name: "tiny dinos winter 2022 #9414",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9414,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9414.png",
  },
  "9415": {
    name: "tiny dinos winter 2022 #9415",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9415,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9415.png",
  },
  "9416": {
    name: "tiny dinos winter 2022 #9416",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9416,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9416.png",
  },
  "9417": {
    name: "tiny dinos winter 2022 #9417",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9417,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9417.png",
  },
  "9418": {
    name: "tiny dinos winter 2022 #9418",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9418,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9418.png",
  },
  "9419": {
    name: "tiny dinos winter 2022 #9419",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9419,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9419.png",
  },
  "9420": {
    name: "tiny dinos winter 2022 #9420",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9420,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9420.png",
  },
  "9421": {
    name: "tiny dinos winter 2022 #9421",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9421,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9421.png",
  },
  "9422": {
    name: "tiny dinos winter 2022 #9422",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9422,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9422.png",
  },
  "9423": {
    name: "tiny dinos winter 2022 #9423",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9423,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9423.png",
  },
  "9424": {
    name: "tiny dinos winter 2022 #9424",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9424,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9424.png",
  },
  "9425": {
    name: "tiny dinos winter 2022 #9425",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9425,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9425.png",
  },
  "9426": {
    name: "tiny dinos winter 2022 #9426",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9426,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9426.png",
  },
  "9427": {
    name: "tiny dinos winter 2022 #9427",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9427,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9427.png",
  },
  "9428": {
    name: "tiny dinos winter 2022 #9428",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9428,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9428.png",
  },
  "9429": {
    name: "tiny dinos winter 2022 #9429",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9429,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9429.png",
  },
  "9430": {
    name: "tiny dinos winter 2022 #9430",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9430,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9430.png",
  },
  "9431": {
    name: "tiny dinos winter 2022 #9431",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9431,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9431.png",
  },
  "9432": {
    name: "tiny dinos winter 2022 #9432",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9432,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9432.png",
  },
  "9433": {
    name: "tiny dinos winter 2022 #9433",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9433,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9433.png",
  },
  "9434": {
    name: "tiny dinos winter 2022 #9434",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9434,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9434.png",
  },
  "9435": {
    name: "tiny dinos winter 2022 #9435",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9435,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9435.png",
  },
  "9436": {
    name: "tiny dinos winter 2022 #9436",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9436,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9436.png",
  },
  "9437": {
    name: "tiny dinos winter 2022 #9437",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9437,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9437.png",
  },
  "9438": {
    name: "tiny dinos winter 2022 #9438",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9438,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9438.png",
  },
  "9439": {
    name: "tiny dinos winter 2022 #9439",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9439,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9439.png",
  },
  "9440": {
    name: "tiny dinos winter 2022 #9440",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9440,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9440.png",
  },
  "9441": {
    name: "tiny dinos winter 2022 #9441",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9441,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9441.png",
  },
  "9442": {
    name: "tiny dinos winter 2022 #9442",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9442,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9442.png",
  },
  "9443": {
    name: "tiny dinos winter 2022 #9443",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9443,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9443.png",
  },
  "9444": {
    name: "tiny dinos winter 2022 #9444",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9444,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9444.png",
  },
  "9445": {
    name: "tiny dinos winter 2022 #9445",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9445,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9445.png",
  },
  "9446": {
    name: "tiny dinos winter 2022 #9446",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9446,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9446.png",
  },
  "9447": {
    name: "tiny dinos winter 2022 #9447",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9447,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9447.png",
  },
  "9448": {
    name: "tiny dinos winter 2022 #9448",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9448,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9448.png",
  },
  "9449": {
    name: "tiny dinos winter 2022 #9449",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9449,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9449.png",
  },
  "9450": {
    name: "tiny dinos winter 2022 #9450",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9450,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9450.png",
  },
  "9451": {
    name: "tiny dinos winter 2022 #9451",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9451,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9451.png",
  },
  "9452": {
    name: "tiny dinos winter 2022 #9452",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9452,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9452.png",
  },
  "9453": {
    name: "tiny dinos winter 2022 #9453",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9453,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9453.png",
  },
  "9454": {
    name: "tiny dinos winter 2022 #9454",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9454,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9454.png",
  },
  "9455": {
    name: "tiny dinos winter 2022 #9455",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9455,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9455.png",
  },
  "9456": {
    name: "tiny dinos winter 2022 #9456",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9456,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9456.png",
  },
  "9457": {
    name: "tiny dinos winter 2022 #9457",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9457,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9457.png",
  },
  "9458": {
    name: "tiny dinos winter 2022 #9458",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9458,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9458.png",
  },
  "9459": {
    name: "tiny dinos winter 2022 #9459",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9459,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9459.png",
  },
  "9460": {
    name: "tiny dinos winter 2022 #9460",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9460,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9460.png",
  },
  "9461": {
    name: "tiny dinos winter 2022 #9461",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9461,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9461.png",
  },
  "9462": {
    name: "tiny dinos winter 2022 #9462",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9462,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9462.png",
  },
  "9463": {
    name: "tiny dinos winter 2022 #9463",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9463,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9463.png",
  },
  "9464": {
    name: "tiny dinos winter 2022 #9464",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9464,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9464.png",
  },
  "9465": {
    name: "tiny dinos winter 2022 #9465",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9465,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9465.png",
  },
  "9466": {
    name: "tiny dinos winter 2022 #9466",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9466,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9466.png",
  },
  "9467": {
    name: "tiny dinos winter 2022 #9467",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9467,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9467.png",
  },
  "9468": {
    name: "tiny dinos winter 2022 #9468",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9468,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9468.png",
  },
  "9469": {
    name: "tiny dinos winter 2022 #9469",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9469,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9469.png",
  },
  "9470": {
    name: "tiny dinos winter 2022 #9470",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9470,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9470.png",
  },
  "9471": {
    name: "tiny dinos winter 2022 #9471",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9471,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9471.png",
  },
  "9472": {
    name: "tiny dinos winter 2022 #9472",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9472,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9472.png",
  },
  "9473": {
    name: "tiny dinos winter 2022 #9473",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9473,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9473.png",
  },
  "9474": {
    name: "tiny dinos winter 2022 #9474",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9474,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9474.png",
  },
  "9475": {
    name: "tiny dinos winter 2022 #9475",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9475,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9475.png",
  },
  "9476": {
    name: "tiny dinos winter 2022 #9476",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9476,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9476.png",
  },
  "9477": {
    name: "tiny dinos winter 2022 #9477",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9477,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9477.png",
  },
  "9478": {
    name: "tiny dinos winter 2022 #9478",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9478,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9478.png",
  },
  "9479": {
    name: "tiny dinos winter 2022 #9479",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9479,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9479.png",
  },
  "9480": {
    name: "tiny dinos winter 2022 #9480",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9480,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9480.png",
  },
  "9481": {
    name: "tiny dinos winter 2022 #9481",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9481,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9481.png",
  },
  "9482": {
    name: "tiny dinos winter 2022 #9482",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9482,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9482.png",
  },
  "9483": {
    name: "tiny dinos winter 2022 #9483",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9483,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9483.png",
  },
  "9484": {
    name: "tiny dinos winter 2022 #9484",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9484,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9484.png",
  },
  "9485": {
    name: "tiny dinos winter 2022 #9485",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9485,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9485.png",
  },
  "9486": {
    name: "tiny dinos winter 2022 #9486",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9486,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9486.png",
  },
  "9487": {
    name: "tiny dinos winter 2022 #9487",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9487,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9487.png",
  },
  "9488": {
    name: "tiny dinos winter 2022 #9488",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9488,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9488.png",
  },
  "9489": {
    name: "tiny dinos winter 2022 #9489",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9489,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9489.png",
  },
  "9490": {
    name: "tiny dinos winter 2022 #9490",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9490,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9490.png",
  },
  "9491": {
    name: "tiny dinos winter 2022 #9491",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9491,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9491.png",
  },
  "9492": {
    name: "tiny dinos winter 2022 #9492",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9492,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9492.png",
  },
  "9493": {
    name: "tiny dinos winter 2022 #9493",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9493,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9493.png",
  },
  "9494": {
    name: "tiny dinos winter 2022 #9494",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9494,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9494.png",
  },
  "9495": {
    name: "tiny dinos winter 2022 #9495",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9495,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9495.png",
  },
  "9496": {
    name: "tiny dinos winter 2022 #9496",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9496,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9496.png",
  },
  "9497": {
    name: "tiny dinos winter 2022 #9497",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9497,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9497.png",
  },
  "9498": {
    name: "tiny dinos winter 2022 #9498",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9498,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9498.png",
  },
  "9499": {
    name: "tiny dinos winter 2022 #9499",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9499,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9499.png",
  },
  "9500": {
    name: "tiny dinos winter 2022 #9500",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9500,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9500.png",
  },
  "9501": {
    name: "tiny dinos winter 2022 #9501",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9501,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9501.png",
  },
  "9502": {
    name: "tiny dinos winter 2022 #9502",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9502,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9502.png",
  },
  "9503": {
    name: "tiny dinos winter 2022 #9503",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9503,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9503.png",
  },
  "9504": {
    name: "tiny dinos winter 2022 #9504",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9504,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9504.png",
  },
  "9505": {
    name: "tiny dinos winter 2022 #9505",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9505,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9505.png",
  },
  "9506": {
    name: "tiny dinos winter 2022 #9506",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9506,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9506.png",
  },
  "9507": {
    name: "tiny dinos winter 2022 #9507",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9507,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9507.png",
  },
  "9508": {
    name: "tiny dinos winter 2022 #9508",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9508,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9508.png",
  },
  "9509": {
    name: "tiny dinos winter 2022 #9509",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9509,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9509.png",
  },
  "9510": {
    name: "tiny dinos winter 2022 #9510",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9510,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9510.png",
  },
  "9511": {
    name: "tiny dinos winter 2022 #9511",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9511,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9511.png",
  },
  "9512": {
    name: "tiny dinos winter 2022 #9512",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9512,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9512.png",
  },
  "9513": {
    name: "tiny dinos winter 2022 #9513",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9513,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9513.png",
  },
  "9514": {
    name: "tiny dinos winter 2022 #9514",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9514,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9514.png",
  },
  "9515": {
    name: "tiny dinos winter 2022 #9515",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9515,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9515.png",
  },
  "9516": {
    name: "tiny dinos winter 2022 #9516",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9516,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9516.png",
  },
  "9517": {
    name: "tiny dinos winter 2022 #9517",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9517,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9517.png",
  },
  "9518": {
    name: "tiny dinos winter 2022 #9518",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9518,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9518.png",
  },
  "9519": {
    name: "tiny dinos winter 2022 #9519",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9519,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9519.png",
  },
  "9520": {
    name: "tiny dinos winter 2022 #9520",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9520,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9520.png",
  },
  "9521": {
    name: "tiny dinos winter 2022 #9521",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9521,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9521.png",
  },
  "9522": {
    name: "tiny dinos winter 2022 #9522",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9522,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9522.png",
  },
  "9523": {
    name: "tiny dinos winter 2022 #9523",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9523,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9523.png",
  },
  "9524": {
    name: "tiny dinos winter 2022 #9524",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9524,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9524.png",
  },
  "9525": {
    name: "tiny dinos winter 2022 #9525",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9525,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9525.png",
  },
  "9526": {
    name: "tiny dinos winter 2022 #9526",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9526,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9526.png",
  },
  "9527": {
    name: "tiny dinos winter 2022 #9527",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9527,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9527.png",
  },
  "9528": {
    name: "tiny dinos winter 2022 #9528",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9528,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9528.png",
  },
  "9529": {
    name: "tiny dinos winter 2022 #9529",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9529,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9529.png",
  },
  "9530": {
    name: "tiny dinos winter 2022 #9530",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9530,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9530.png",
  },
  "9531": {
    name: "tiny dinos winter 2022 #9531",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9531,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9531.png",
  },
  "9532": {
    name: "tiny dinos winter 2022 #9532",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9532,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9532.png",
  },
  "9533": {
    name: "tiny dinos winter 2022 #9533",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9533,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9533.png",
  },
  "9534": {
    name: "tiny dinos winter 2022 #9534",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9534,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9534.png",
  },
  "9535": {
    name: "tiny dinos winter 2022 #9535",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9535,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9535.png",
  },
  "9536": {
    name: "tiny dinos winter 2022 #9536",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9536,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9536.png",
  },
  "9537": {
    name: "tiny dinos winter 2022 #9537",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9537,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9537.png",
  },
  "9538": {
    name: "tiny dinos winter 2022 #9538",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9538,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9538.png",
  },
  "9539": {
    name: "tiny dinos winter 2022 #9539",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9539,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9539.png",
  },
  "9540": {
    name: "tiny dinos winter 2022 #9540",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9540,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9540.png",
  },
  "9541": {
    name: "tiny dinos winter 2022 #9541",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9541,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9541.png",
  },
  "9542": {
    name: "tiny dinos winter 2022 #9542",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9542,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9542.png",
  },
  "9543": {
    name: "tiny dinos winter 2022 #9543",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9543,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9543.png",
  },
  "9544": {
    name: "tiny dinos winter 2022 #9544",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9544,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9544.png",
  },
  "9545": {
    name: "tiny dinos winter 2022 #9545",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9545,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9545.png",
  },
  "9546": {
    name: "tiny dinos winter 2022 #9546",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9546,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9546.png",
  },
  "9547": {
    name: "tiny dinos winter 2022 #9547",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9547,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9547.png",
  },
  "9548": {
    name: "tiny dinos winter 2022 #9548",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9548,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9548.png",
  },
  "9549": {
    name: "tiny dinos winter 2022 #9549",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9549,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9549.png",
  },
  "9550": {
    name: "tiny dinos winter 2022 #9550",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9550,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9550.png",
  },
  "9551": {
    name: "tiny dinos winter 2022 #9551",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9551,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9551.png",
  },
  "9552": {
    name: "tiny dinos winter 2022 #9552",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9552,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9552.png",
  },
  "9553": {
    name: "tiny dinos winter 2022 #9553",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9553,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9553.png",
  },
  "9554": {
    name: "tiny dinos winter 2022 #9554",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9554,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9554.png",
  },
  "9555": {
    name: "tiny dinos winter 2022 #9555",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9555,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9555.png",
  },
  "9556": {
    name: "tiny dinos winter 2022 #9556",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9556,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9556.png",
  },
  "9557": {
    name: "tiny dinos winter 2022 #9557",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9557,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9557.png",
  },
  "9558": {
    name: "tiny dinos winter 2022 #9558",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9558,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9558.png",
  },
  "9559": {
    name: "tiny dinos winter 2022 #9559",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9559,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9559.png",
  },
  "9560": {
    name: "tiny dinos winter 2022 #9560",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9560,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9560.png",
  },
  "9561": {
    name: "tiny dinos winter 2022 #9561",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9561,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9561.png",
  },
  "9562": {
    name: "tiny dinos winter 2022 #9562",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9562,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9562.png",
  },
  "9563": {
    name: "tiny dinos winter 2022 #9563",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9563,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9563.png",
  },
  "9564": {
    name: "tiny dinos winter 2022 #9564",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9564,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9564.png",
  },
  "9565": {
    name: "tiny dinos winter 2022 #9565",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9565,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9565.png",
  },
  "9566": {
    name: "tiny dinos winter 2022 #9566",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9566,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9566.png",
  },
  "9567": {
    name: "tiny dinos winter 2022 #9567",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9567,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9567.png",
  },
  "9568": {
    name: "tiny dinos winter 2022 #9568",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9568,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9568.png",
  },
  "9569": {
    name: "tiny dinos winter 2022 #9569",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9569,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9569.png",
  },
  "9570": {
    name: "tiny dinos winter 2022 #9570",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9570,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9570.png",
  },
  "9571": {
    name: "tiny dinos winter 2022 #9571",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9571,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9571.png",
  },
  "9572": {
    name: "tiny dinos winter 2022 #9572",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9572,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9572.png",
  },
  "9573": {
    name: "tiny dinos winter 2022 #9573",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9573,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9573.png",
  },
  "9574": {
    name: "tiny dinos winter 2022 #9574",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9574,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9574.png",
  },
  "9575": {
    name: "tiny dinos winter 2022 #9575",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9575,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9575.png",
  },
  "9576": {
    name: "tiny dinos winter 2022 #9576",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9576,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9576.png",
  },
  "9577": {
    name: "tiny dinos winter 2022 #9577",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9577,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9577.png",
  },
  "9578": {
    name: "tiny dinos winter 2022 #9578",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9578,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9578.png",
  },
  "9579": {
    name: "tiny dinos winter 2022 #9579",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9579,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9579.png",
  },
  "9580": {
    name: "tiny dinos winter 2022 #9580",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9580,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9580.png",
  },
  "9581": {
    name: "tiny dinos winter 2022 #9581",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9581,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9581.png",
  },
  "9582": {
    name: "tiny dinos winter 2022 #9582",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9582,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9582.png",
  },
  "9583": {
    name: "tiny dinos winter 2022 #9583",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9583,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9583.png",
  },
  "9584": {
    name: "tiny dinos winter 2022 #9584",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9584,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9584.png",
  },
  "9585": {
    name: "tiny dinos winter 2022 #9585",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9585,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9585.png",
  },
  "9586": {
    name: "tiny dinos winter 2022 #9586",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9586,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9586.png",
  },
  "9587": {
    name: "tiny dinos winter 2022 #9587",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9587,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9587.png",
  },
  "9588": {
    name: "tiny dinos winter 2022 #9588",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9588,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9588.png",
  },
  "9589": {
    name: "tiny dinos winter 2022 #9589",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9589,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9589.png",
  },
  "9590": {
    name: "tiny dinos winter 2022 #9590",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9590,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9590.png",
  },
  "9591": {
    name: "tiny dinos winter 2022 #9591",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9591,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9591.png",
  },
  "9592": {
    name: "tiny dinos winter 2022 #9592",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9592,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9592.png",
  },
  "9593": {
    name: "tiny dinos winter 2022 #9593",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9593,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9593.png",
  },
  "9594": {
    name: "tiny dinos winter 2022 #9594",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9594,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9594.png",
  },
  "9595": {
    name: "tiny dinos winter 2022 #9595",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9595,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9595.png",
  },
  "9596": {
    name: "tiny dinos winter 2022 #9596",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9596,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9596.png",
  },
  "9597": {
    name: "tiny dinos winter 2022 #9597",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9597,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9597.png",
  },
  "9598": {
    name: "tiny dinos winter 2022 #9598",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9598,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9598.png",
  },
  "9599": {
    name: "tiny dinos winter 2022 #9599",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9599,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9599.png",
  },
  "9600": {
    name: "tiny dinos winter 2022 #9600",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9600,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9600.png",
  },
  "9601": {
    name: "tiny dinos winter 2022 #9601",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9601,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9601.png",
  },
  "9602": {
    name: "tiny dinos winter 2022 #9602",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9602,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9602.png",
  },
  "9603": {
    name: "tiny dinos winter 2022 #9603",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9603,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9603.png",
  },
  "9604": {
    name: "tiny dinos winter 2022 #9604",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9604,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9604.png",
  },
  "9605": {
    name: "tiny dinos winter 2022 #9605",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9605,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9605.png",
  },
  "9606": {
    name: "tiny dinos winter 2022 #9606",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9606,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9606.png",
  },
  "9607": {
    name: "tiny dinos winter 2022 #9607",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9607,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9607.png",
  },
  "9608": {
    name: "tiny dinos winter 2022 #9608",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9608,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9608.png",
  },
  "9609": {
    name: "tiny dinos winter 2022 #9609",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9609,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9609.png",
  },
  "9610": {
    name: "tiny dinos winter 2022 #9610",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9610,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9610.png",
  },
  "9611": {
    name: "tiny dinos winter 2022 #9611",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9611,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9611.png",
  },
  "9612": {
    name: "tiny dinos winter 2022 #9612",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9612,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9612.png",
  },
  "9613": {
    name: "tiny dinos winter 2022 #9613",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9613,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9613.png",
  },
  "9614": {
    name: "tiny dinos winter 2022 #9614",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9614,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9614.png",
  },
  "9615": {
    name: "tiny dinos winter 2022 #9615",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9615,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9615.png",
  },
  "9616": {
    name: "tiny dinos winter 2022 #9616",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9616,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9616.png",
  },
  "9617": {
    name: "tiny dinos winter 2022 #9617",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9617,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9617.png",
  },
  "9618": {
    name: "tiny dinos winter 2022 #9618",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9618,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9618.png",
  },
  "9619": {
    name: "tiny dinos winter 2022 #9619",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9619,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9619.png",
  },
  "9620": {
    name: "tiny dinos winter 2022 #9620",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9620,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9620.png",
  },
  "9621": {
    name: "tiny dinos winter 2022 #9621",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9621,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9621.png",
  },
  "9622": {
    name: "tiny dinos winter 2022 #9622",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9622,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9622.png",
  },
  "9623": {
    name: "tiny dinos winter 2022 #9623",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9623,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9623.png",
  },
  "9624": {
    name: "tiny dinos winter 2022 #9624",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9624,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9624.png",
  },
  "9625": {
    name: "tiny dinos winter 2022 #9625",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9625,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9625.png",
  },
  "9626": {
    name: "tiny dinos winter 2022 #9626",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9626,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9626.png",
  },
  "9627": {
    name: "tiny dinos winter 2022 #9627",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9627,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9627.png",
  },
  "9628": {
    name: "tiny dinos winter 2022 #9628",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9628,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9628.png",
  },
  "9629": {
    name: "tiny dinos winter 2022 #9629",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9629,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9629.png",
  },
  "9630": {
    name: "tiny dinos winter 2022 #9630",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9630,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9630.png",
  },
  "9631": {
    name: "tiny dinos winter 2022 #9631",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9631,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9631.png",
  },
  "9632": {
    name: "tiny dinos winter 2022 #9632",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9632,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9632.png",
  },
  "9633": {
    name: "tiny dinos winter 2022 #9633",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9633,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9633.png",
  },
  "9634": {
    name: "tiny dinos winter 2022 #9634",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9634,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9634.png",
  },
  "9635": {
    name: "tiny dinos winter 2022 #9635",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9635,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9635.png",
  },
  "9636": {
    name: "tiny dinos winter 2022 #9636",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9636,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9636.png",
  },
  "9637": {
    name: "tiny dinos winter 2022 #9637",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9637,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9637.png",
  },
  "9638": {
    name: "tiny dinos winter 2022 #9638",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9638,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9638.png",
  },
  "9639": {
    name: "tiny dinos winter 2022 #9639",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9639,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9639.png",
  },
  "9640": {
    name: "tiny dinos winter 2022 #9640",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9640,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9640.png",
  },
  "9641": {
    name: "tiny dinos winter 2022 #9641",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9641,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9641.png",
  },
  "9642": {
    name: "tiny dinos winter 2022 #9642",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9642,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9642.png",
  },
  "9643": {
    name: "tiny dinos winter 2022 #9643",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9643,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9643.png",
  },
  "9644": {
    name: "tiny dinos winter 2022 #9644",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9644,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9644.png",
  },
  "9645": {
    name: "tiny dinos winter 2022 #9645",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9645,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9645.png",
  },
  "9646": {
    name: "tiny dinos winter 2022 #9646",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9646,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9646.png",
  },
  "9647": {
    name: "tiny dinos winter 2022 #9647",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9647,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9647.png",
  },
  "9648": {
    name: "tiny dinos winter 2022 #9648",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9648,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9648.png",
  },
  "9649": {
    name: "tiny dinos winter 2022 #9649",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9649,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9649.png",
  },
  "9650": {
    name: "tiny dinos winter 2022 #9650",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9650,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9650.png",
  },
  "9651": {
    name: "tiny dinos winter 2022 #9651",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9651,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9651.png",
  },
  "9652": {
    name: "tiny dinos winter 2022 #9652",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9652,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9652.png",
  },
  "9653": {
    name: "tiny dinos winter 2022 #9653",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9653,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9653.png",
  },
  "9654": {
    name: "tiny dinos winter 2022 #9654",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9654,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9654.png",
  },
  "9655": {
    name: "tiny dinos winter 2022 #9655",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9655,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9655.png",
  },
  "9656": {
    name: "tiny dinos winter 2022 #9656",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9656,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9656.png",
  },
  "9657": {
    name: "tiny dinos winter 2022 #9657",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9657,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9657.png",
  },
  "9658": {
    name: "tiny dinos winter 2022 #9658",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9658,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9658.png",
  },
  "9659": {
    name: "tiny dinos winter 2022 #9659",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9659,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9659.png",
  },
  "9660": {
    name: "tiny dinos winter 2022 #9660",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9660,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9660.png",
  },
  "9661": {
    name: "tiny dinos winter 2022 #9661",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9661,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9661.png",
  },
  "9662": {
    name: "tiny dinos winter 2022 #9662",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9662,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9662.png",
  },
  "9663": {
    name: "tiny dinos winter 2022 #9663",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9663,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9663.png",
  },
  "9664": {
    name: "tiny dinos winter 2022 #9664",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9664,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9664.png",
  },
  "9665": {
    name: "tiny dinos winter 2022 #9665",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9665,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9665.png",
  },
  "9666": {
    name: "tiny dinos winter 2022 #9666",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9666,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9666.png",
  },
  "9667": {
    name: "tiny dinos winter 2022 #9667",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9667,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9667.png",
  },
  "9668": {
    name: "tiny dinos winter 2022 #9668",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9668,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9668.png",
  },
  "9669": {
    name: "tiny dinos winter 2022 #9669",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9669,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9669.png",
  },
  "9670": {
    name: "tiny dinos winter 2022 #9670",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9670,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9670.png",
  },
  "9671": {
    name: "tiny dinos winter 2022 #9671",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9671,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9671.png",
  },
  "9672": {
    name: "tiny dinos winter 2022 #9672",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9672,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9672.png",
  },
  "9673": {
    name: "tiny dinos winter 2022 #9673",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9673,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9673.png",
  },
  "9674": {
    name: "tiny dinos winter 2022 #9674",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9674,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9674.png",
  },
  "9675": {
    name: "tiny dinos winter 2022 #9675",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9675,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9675.png",
  },
  "9676": {
    name: "tiny dinos winter 2022 #9676",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9676,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9676.png",
  },
  "9677": {
    name: "tiny dinos winter 2022 #9677",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9677,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9677.png",
  },
  "9678": {
    name: "tiny dinos winter 2022 #9678",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9678,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9678.png",
  },
  "9679": {
    name: "tiny dinos winter 2022 #9679",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9679,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9679.png",
  },
  "9680": {
    name: "tiny dinos winter 2022 #9680",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9680,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9680.png",
  },
  "9681": {
    name: "tiny dinos winter 2022 #9681",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9681,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9681.png",
  },
  "9682": {
    name: "tiny dinos winter 2022 #9682",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9682,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9682.png",
  },
  "9683": {
    name: "tiny dinos winter 2022 #9683",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9683,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9683.png",
  },
  "9684": {
    name: "tiny dinos winter 2022 #9684",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9684,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9684.png",
  },
  "9685": {
    name: "tiny dinos winter 2022 #9685",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9685,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9685.png",
  },
  "9686": {
    name: "tiny dinos winter 2022 #9686",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9686,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9686.png",
  },
  "9687": {
    name: "tiny dinos winter 2022 #9687",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9687,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9687.png",
  },
  "9688": {
    name: "tiny dinos winter 2022 #9688",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9688,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9688.png",
  },
  "9689": {
    name: "tiny dinos winter 2022 #9689",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9689,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9689.png",
  },
  "9690": {
    name: "tiny dinos winter 2022 #9690",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9690,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9690.png",
  },
  "9691": {
    name: "tiny dinos winter 2022 #9691",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9691,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9691.png",
  },
  "9692": {
    name: "tiny dinos winter 2022 #9692",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9692,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9692.png",
  },
  "9693": {
    name: "tiny dinos winter 2022 #9693",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9693,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9693.png",
  },
  "9694": {
    name: "tiny dinos winter 2022 #9694",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9694,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9694.png",
  },
  "9695": {
    name: "tiny dinos winter 2022 #9695",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9695,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9695.png",
  },
  "9696": {
    name: "tiny dinos winter 2022 #9696",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9696,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9696.png",
  },
  "9697": {
    name: "tiny dinos winter 2022 #9697",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9697,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9697.png",
  },
  "9698": {
    name: "tiny dinos winter 2022 #9698",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9698,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9698.png",
  },
  "9699": {
    name: "tiny dinos winter 2022 #9699",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9699,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9699.png",
  },
  "9700": {
    name: "tiny dinos winter 2022 #9700",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9700,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9700.png",
  },
  "9701": {
    name: "tiny dinos winter 2022 #9701",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9701,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9701.png",
  },
  "9702": {
    name: "tiny dinos winter 2022 #9702",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9702,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9702.png",
  },
  "9703": {
    name: "tiny dinos winter 2022 #9703",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9703,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9703.png",
  },
  "9704": {
    name: "tiny dinos winter 2022 #9704",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9704,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9704.png",
  },
  "9705": {
    name: "tiny dinos winter 2022 #9705",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9705,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9705.png",
  },
  "9706": {
    name: "tiny dinos winter 2022 #9706",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9706,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9706.png",
  },
  "9707": {
    name: "tiny dinos winter 2022 #9707",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9707,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9707.png",
  },
  "9708": {
    name: "tiny dinos winter 2022 #9708",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9708,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9708.png",
  },
  "9709": {
    name: "tiny dinos winter 2022 #9709",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9709,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9709.png",
  },
  "9710": {
    name: "tiny dinos winter 2022 #9710",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9710,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9710.png",
  },
  "9711": {
    name: "tiny dinos winter 2022 #9711",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9711,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9711.png",
  },
  "9712": {
    name: "tiny dinos winter 2022 #9712",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9712,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9712.png",
  },
  "9713": {
    name: "tiny dinos winter 2022 #9713",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9713,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9713.png",
  },
  "9714": {
    name: "tiny dinos winter 2022 #9714",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9714,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9714.png",
  },
  "9715": {
    name: "tiny dinos winter 2022 #9715",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9715,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9715.png",
  },
  "9716": {
    name: "tiny dinos winter 2022 #9716",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9716,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9716.png",
  },
  "9717": {
    name: "tiny dinos winter 2022 #9717",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9717,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9717.png",
  },
  "9718": {
    name: "tiny dinos winter 2022 #9718",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9718,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9718.png",
  },
  "9719": {
    name: "tiny dinos winter 2022 #9719",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9719,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9719.png",
  },
  "9720": {
    name: "tiny dinos winter 2022 #9720",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9720,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9720.png",
  },
  "9721": {
    name: "tiny dinos winter 2022 #9721",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9721,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9721.png",
  },
  "9722": {
    name: "tiny dinos winter 2022 #9722",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9722,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9722.png",
  },
  "9723": {
    name: "tiny dinos winter 2022 #9723",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9723,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9723.png",
  },
  "9724": {
    name: "tiny dinos winter 2022 #9724",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9724,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9724.png",
  },
  "9725": {
    name: "tiny dinos winter 2022 #9725",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9725,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9725.png",
  },
  "9726": {
    name: "tiny dinos winter 2022 #9726",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9726,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9726.png",
  },
  "9727": {
    name: "tiny dinos winter 2022 #9727",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9727,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9727.png",
  },
  "9728": {
    name: "tiny dinos winter 2022 #9728",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9728,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9728.png",
  },
  "9729": {
    name: "tiny dinos winter 2022 #9729",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9729,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9729.png",
  },
  "9730": {
    name: "tiny dinos winter 2022 #9730",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9730,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9730.png",
  },
  "9731": {
    name: "tiny dinos winter 2022 #9731",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9731,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9731.png",
  },
  "9732": {
    name: "tiny dinos winter 2022 #9732",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9732,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9732.png",
  },
  "9733": {
    name: "tiny dinos winter 2022 #9733",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9733,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9733.png",
  },
  "9734": {
    name: "tiny dinos winter 2022 #9734",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9734,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9734.png",
  },
  "9735": {
    name: "tiny dinos winter 2022 #9735",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9735,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9735.png",
  },
  "9736": {
    name: "tiny dinos winter 2022 #9736",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9736,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9736.png",
  },
  "9737": {
    name: "tiny dinos winter 2022 #9737",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9737,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9737.png",
  },
  "9738": {
    name: "tiny dinos winter 2022 #9738",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9738,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9738.png",
  },
  "9739": {
    name: "tiny dinos winter 2022 #9739",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9739,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9739.png",
  },
  "9740": {
    name: "tiny dinos winter 2022 #9740",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9740,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9740.png",
  },
  "9741": {
    name: "tiny dinos winter 2022 #9741",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9741,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9741.png",
  },
  "9742": {
    name: "tiny dinos winter 2022 #9742",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9742,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9742.png",
  },
  "9743": {
    name: "tiny dinos winter 2022 #9743",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9743,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9743.png",
  },
  "9744": {
    name: "tiny dinos winter 2022 #9744",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9744,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9744.png",
  },
  "9745": {
    name: "tiny dinos winter 2022 #9745",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9745,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9745.png",
  },
  "9746": {
    name: "tiny dinos winter 2022 #9746",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9746,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9746.png",
  },
  "9747": {
    name: "tiny dinos winter 2022 #9747",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9747,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9747.png",
  },
  "9748": {
    name: "tiny dinos winter 2022 #9748",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9748,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9748.png",
  },
  "9749": {
    name: "tiny dinos winter 2022 #9749",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9749,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9749.png",
  },
  "9750": {
    name: "tiny dinos winter 2022 #9750",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9750,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9750.png",
  },
  "9751": {
    name: "tiny dinos winter 2022 #9751",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9751,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9751.png",
  },
  "9752": {
    name: "tiny dinos winter 2022 #9752",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9752,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9752.png",
  },
  "9753": {
    name: "tiny dinos winter 2022 #9753",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9753,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9753.png",
  },
  "9754": {
    name: "tiny dinos winter 2022 #9754",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9754,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9754.png",
  },
  "9755": {
    name: "tiny dinos winter 2022 #9755",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9755,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9755.png",
  },
  "9756": {
    name: "tiny dinos winter 2022 #9756",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9756,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9756.png",
  },
  "9757": {
    name: "tiny dinos winter 2022 #9757",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9757,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9757.png",
  },
  "9758": {
    name: "tiny dinos winter 2022 #9758",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9758,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9758.png",
  },
  "9759": {
    name: "tiny dinos winter 2022 #9759",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9759,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9759.png",
  },
  "9760": {
    name: "tiny dinos winter 2022 #9760",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9760,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9760.png",
  },
  "9761": {
    name: "tiny dinos winter 2022 #9761",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9761,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9761.png",
  },
  "9762": {
    name: "tiny dinos winter 2022 #9762",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9762,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9762.png",
  },
  "9763": {
    name: "tiny dinos winter 2022 #9763",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9763,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9763.png",
  },
  "9764": {
    name: "tiny dinos winter 2022 #9764",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9764,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9764.png",
  },
  "9765": {
    name: "tiny dinos winter 2022 #9765",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9765,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9765.png",
  },
  "9766": {
    name: "tiny dinos winter 2022 #9766",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9766,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9766.png",
  },
  "9767": {
    name: "tiny dinos winter 2022 #9767",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9767,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9767.png",
  },
  "9768": {
    name: "tiny dinos winter 2022 #9768",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9768,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9768.png",
  },
  "9769": {
    name: "tiny dinos winter 2022 #9769",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9769,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9769.png",
  },
  "9770": {
    name: "tiny dinos winter 2022 #9770",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9770,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9770.png",
  },
  "9771": {
    name: "tiny dinos winter 2022 #9771",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9771,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9771.png",
  },
  "9772": {
    name: "tiny dinos winter 2022 #9772",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9772,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9772.png",
  },
  "9773": {
    name: "tiny dinos winter 2022 #9773",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9773,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9773.png",
  },
  "9774": {
    name: "tiny dinos winter 2022 #9774",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9774,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9774.png",
  },
  "9775": {
    name: "tiny dinos winter 2022 #9775",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9775,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9775.png",
  },
  "9776": {
    name: "tiny dinos winter 2022 #9776",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9776,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9776.png",
  },
  "9777": {
    name: "tiny dinos winter 2022 #9777",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9777,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9777.png",
  },
  "9778": {
    name: "tiny dinos winter 2022 #9778",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9778,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9778.png",
  },
  "9779": {
    name: "tiny dinos winter 2022 #9779",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9779,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9779.png",
  },
  "9780": {
    name: "tiny dinos winter 2022 #9780",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9780,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9780.png",
  },
  "9781": {
    name: "tiny dinos winter 2022 #9781",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9781,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9781.png",
  },
  "9782": {
    name: "tiny dinos winter 2022 #9782",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9782,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9782.png",
  },
  "9783": {
    name: "tiny dinos winter 2022 #9783",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9783,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9783.png",
  },
  "9784": {
    name: "tiny dinos winter 2022 #9784",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9784,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9784.png",
  },
  "9785": {
    name: "tiny dinos winter 2022 #9785",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9785,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9785.png",
  },
  "9786": {
    name: "tiny dinos winter 2022 #9786",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9786,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9786.png",
  },
  "9787": {
    name: "tiny dinos winter 2022 #9787",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9787,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9787.png",
  },
  "9788": {
    name: "tiny dinos winter 2022 #9788",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9788,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9788.png",
  },
  "9789": {
    name: "tiny dinos winter 2022 #9789",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9789,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9789.png",
  },
  "9790": {
    name: "tiny dinos winter 2022 #9790",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9790,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9790.png",
  },
  "9791": {
    name: "tiny dinos winter 2022 #9791",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9791,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9791.png",
  },
  "9792": {
    name: "tiny dinos winter 2022 #9792",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9792,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9792.png",
  },
  "9793": {
    name: "tiny dinos winter 2022 #9793",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9793,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9793.png",
  },
  "9794": {
    name: "tiny dinos winter 2022 #9794",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9794,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9794.png",
  },
  "9795": {
    name: "tiny dinos winter 2022 #9795",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9795,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9795.png",
  },
  "9796": {
    name: "tiny dinos winter 2022 #9796",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9796,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9796.png",
  },
  "9797": {
    name: "tiny dinos winter 2022 #9797",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9797,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9797.png",
  },
  "9798": {
    name: "tiny dinos winter 2022 #9798",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9798,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9798.png",
  },
  "9799": {
    name: "tiny dinos winter 2022 #9799",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9799,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9799.png",
  },
  "9800": {
    name: "tiny dinos winter 2022 #9800",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9800,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9800.png",
  },
  "9801": {
    name: "tiny dinos winter 2022 #9801",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9801,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9801.png",
  },
  "9802": {
    name: "tiny dinos winter 2022 #9802",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9802,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9802.png",
  },
  "9803": {
    name: "tiny dinos winter 2022 #9803",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9803,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9803.png",
  },
  "9804": {
    name: "tiny dinos winter 2022 #9804",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9804,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9804.png",
  },
  "9805": {
    name: "tiny dinos winter 2022 #9805",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9805,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9805.png",
  },
  "9806": {
    name: "tiny dinos winter 2022 #9806",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9806,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9806.png",
  },
  "9807": {
    name: "tiny dinos winter 2022 #9807",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9807,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9807.png",
  },
  "9808": {
    name: "tiny dinos winter 2022 #9808",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9808,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9808.png",
  },
  "9809": {
    name: "tiny dinos winter 2022 #9809",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9809,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9809.png",
  },
  "9810": {
    name: "tiny dinos winter 2022 #9810",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9810,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9810.png",
  },
  "9811": {
    name: "tiny dinos winter 2022 #9811",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9811,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9811.png",
  },
  "9812": {
    name: "tiny dinos winter 2022 #9812",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9812,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9812.png",
  },
  "9813": {
    name: "tiny dinos winter 2022 #9813",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9813,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9813.png",
  },
  "9814": {
    name: "tiny dinos winter 2022 #9814",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9814,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9814.png",
  },
  "9815": {
    name: "tiny dinos winter 2022 #9815",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9815,
    attributes: [
      {
        trait_type: "body",
        value: "rainbow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9815.png",
  },
  "9816": {
    name: "tiny dinos winter 2022 #9816",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9816,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9816.png",
  },
  "9817": {
    name: "tiny dinos winter 2022 #9817",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9817,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9817.png",
  },
  "9818": {
    name: "tiny dinos winter 2022 #9818",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9818,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9818.png",
  },
  "9819": {
    name: "tiny dinos winter 2022 #9819",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9819,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9819.png",
  },
  "9820": {
    name: "tiny dinos winter 2022 #9820",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9820,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9820.png",
  },
  "9821": {
    name: "tiny dinos winter 2022 #9821",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9821,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9821.png",
  },
  "9822": {
    name: "tiny dinos winter 2022 #9822",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9822,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9822.png",
  },
  "9823": {
    name: "tiny dinos winter 2022 #9823",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9823,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9823.png",
  },
  "9824": {
    name: "tiny dinos winter 2022 #9824",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9824,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9824.png",
  },
  "9825": {
    name: "tiny dinos winter 2022 #9825",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9825,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9825.png",
  },
  "9826": {
    name: "tiny dinos winter 2022 #9826",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9826,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9826.png",
  },
  "9827": {
    name: "tiny dinos winter 2022 #9827",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9827,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9827.png",
  },
  "9828": {
    name: "tiny dinos winter 2022 #9828",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9828,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9828.png",
  },
  "9829": {
    name: "tiny dinos winter 2022 #9829",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9829,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9829.png",
  },
  "9830": {
    name: "tiny dinos winter 2022 #9830",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9830,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9830.png",
  },
  "9831": {
    name: "tiny dinos winter 2022 #9831",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9831,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9831.png",
  },
  "9832": {
    name: "tiny dinos winter 2022 #9832",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9832,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9832.png",
  },
  "9833": {
    name: "tiny dinos winter 2022 #9833",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9833,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9833.png",
  },
  "9834": {
    name: "tiny dinos winter 2022 #9834",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9834,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9834.png",
  },
  "9835": {
    name: "tiny dinos winter 2022 #9835",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9835,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9835.png",
  },
  "9836": {
    name: "tiny dinos winter 2022 #9836",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9836,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9836.png",
  },
  "9837": {
    name: "tiny dinos winter 2022 #9837",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9837,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9837.png",
  },
  "9838": {
    name: "tiny dinos winter 2022 #9838",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9838,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9838.png",
  },
  "9839": {
    name: "tiny dinos winter 2022 #9839",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9839,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9839.png",
  },
  "9840": {
    name: "tiny dinos winter 2022 #9840",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9840,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9840.png",
  },
  "9841": {
    name: "tiny dinos winter 2022 #9841",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9841,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9841.png",
  },
  "9842": {
    name: "tiny dinos winter 2022 #9842",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9842,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9842.png",
  },
  "9843": {
    name: "tiny dinos winter 2022 #9843",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9843,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9843.png",
  },
  "9844": {
    name: "tiny dinos winter 2022 #9844",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9844,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9844.png",
  },
  "9845": {
    name: "tiny dinos winter 2022 #9845",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9845,
    attributes: [
      {
        trait_type: "1/1",
        value: "cryptoad",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9845.png",
  },
  "9846": {
    name: "tiny dinos winter 2022 #9846",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9846,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9846.png",
  },
  "9847": {
    name: "tiny dinos winter 2022 #9847",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9847,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9847.png",
  },
  "9848": {
    name: "tiny dinos winter 2022 #9848",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9848,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9848.png",
  },
  "9849": {
    name: "tiny dinos winter 2022 #9849",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9849,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9849.png",
  },
  "9850": {
    name: "tiny dinos winter 2022 #9850",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9850,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9850.png",
  },
  "9851": {
    name: "tiny dinos winter 2022 #9851",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9851,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9851.png",
  },
  "9852": {
    name: "tiny dinos winter 2022 #9852",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9852,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9852.png",
  },
  "9853": {
    name: "tiny dinos winter 2022 #9853",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9853,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9853.png",
  },
  "9854": {
    name: "tiny dinos winter 2022 #9854",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9854,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9854.png",
  },
  "9855": {
    name: "tiny dinos winter 2022 #9855",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9855,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9855.png",
  },
  "9856": {
    name: "tiny dinos winter 2022 #9856",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9856,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9856.png",
  },
  "9857": {
    name: "tiny dinos winter 2022 #9857",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9857,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9857.png",
  },
  "9858": {
    name: "tiny dinos winter 2022 #9858",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9858,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9858.png",
  },
  "9859": {
    name: "tiny dinos winter 2022 #9859",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9859,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9859.png",
  },
  "9860": {
    name: "tiny dinos winter 2022 #9860",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9860,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9860.png",
  },
  "9861": {
    name: "tiny dinos winter 2022 #9861",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9861,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9861.png",
  },
  "9862": {
    name: "tiny dinos winter 2022 #9862",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9862,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9862.png",
  },
  "9863": {
    name: "tiny dinos winter 2022 #9863",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9863,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9863.png",
  },
  "9864": {
    name: "tiny dinos winter 2022 #9864",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9864,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9864.png",
  },
  "9865": {
    name: "tiny dinos winter 2022 #9865",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9865,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9865.png",
  },
  "9866": {
    name: "tiny dinos winter 2022 #9866",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9866,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9866.png",
  },
  "9867": {
    name: "tiny dinos winter 2022 #9867",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9867,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9867.png",
  },
  "9868": {
    name: "tiny dinos winter 2022 #9868",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9868,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9868.png",
  },
  "9869": {
    name: "tiny dinos winter 2022 #9869",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9869,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9869.png",
  },
  "9870": {
    name: "tiny dinos winter 2022 #9870",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9870,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9870.png",
  },
  "9871": {
    name: "tiny dinos winter 2022 #9871",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9871,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9871.png",
  },
  "9872": {
    name: "tiny dinos winter 2022 #9872",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9872,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9872.png",
  },
  "9873": {
    name: "tiny dinos winter 2022 #9873",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9873,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9873.png",
  },
  "9874": {
    name: "tiny dinos winter 2022 #9874",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9874,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9874.png",
  },
  "9875": {
    name: "tiny dinos winter 2022 #9875",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9875,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9875.png",
  },
  "9876": {
    name: "tiny dinos winter 2022 #9876",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9876,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9876.png",
  },
  "9877": {
    name: "tiny dinos winter 2022 #9877",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9877,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9877.png",
  },
  "9878": {
    name: "tiny dinos winter 2022 #9878",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9878,
    attributes: [
      {
        trait_type: "body",
        value: "orange linear gradient",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9878.png",
  },
  "9879": {
    name: "tiny dinos winter 2022 #9879",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9879,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9879.png",
  },
  "9880": {
    name: "tiny dinos winter 2022 #9880",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9880,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9880.png",
  },
  "9881": {
    name: "tiny dinos winter 2022 #9881",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9881,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9881.png",
  },
  "9882": {
    name: "tiny dinos winter 2022 #9882",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9882,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9882.png",
  },
  "9883": {
    name: "tiny dinos winter 2022 #9883",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9883,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9883.png",
  },
  "9884": {
    name: "tiny dinos winter 2022 #9884",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9884,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9884.png",
  },
  "9885": {
    name: "tiny dinos winter 2022 #9885",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9885,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9885.png",
  },
  "9886": {
    name: "tiny dinos winter 2022 #9886",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9886,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9886.png",
  },
  "9887": {
    name: "tiny dinos winter 2022 #9887",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9887,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9887.png",
  },
  "9888": {
    name: "tiny dinos winter 2022 #9888",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9888,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9888.png",
  },
  "9889": {
    name: "tiny dinos winter 2022 #9889",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9889,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9889.png",
  },
  "9890": {
    name: "tiny dinos winter 2022 #9890",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9890,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9890.png",
  },
  "9891": {
    name: "tiny dinos winter 2022 #9891",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9891,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9891.png",
  },
  "9892": {
    name: "tiny dinos winter 2022 #9892",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9892,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9892.png",
  },
  "9893": {
    name: "tiny dinos winter 2022 #9893",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9893,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9893.png",
  },
  "9894": {
    name: "tiny dinos winter 2022 #9894",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9894,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9894.png",
  },
  "9895": {
    name: "tiny dinos winter 2022 #9895",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9895,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9895.png",
  },
  "9896": {
    name: "tiny dinos winter 2022 #9896",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9896,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9896.png",
  },
  "9897": {
    name: "tiny dinos winter 2022 #9897",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9897,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9897.png",
  },
  "9898": {
    name: "tiny dinos winter 2022 #9898",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9898,
    attributes: [
      {
        trait_type: "body",
        value: "grayspace linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9898.png",
  },
  "9899": {
    name: "tiny dinos winter 2022 #9899",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9899,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9899.png",
  },
  "9900": {
    name: "tiny dinos winter 2022 #9900",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9900,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9900.png",
  },
  "9901": {
    name: "tiny dinos winter 2022 #9901",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9901,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9901.png",
  },
  "9902": {
    name: "tiny dinos winter 2022 #9902",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9902,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9902.png",
  },
  "9903": {
    name: "tiny dinos winter 2022 #9903",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9903,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9903.png",
  },
  "9904": {
    name: "tiny dinos winter 2022 #9904",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9904,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9904.png",
  },
  "9905": {
    name: "tiny dinos winter 2022 #9905",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9905,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9905.png",
  },
  "9906": {
    name: "tiny dinos winter 2022 #9906",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9906,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9906.png",
  },
  "9907": {
    name: "tiny dinos winter 2022 #9907",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9907,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9907.png",
  },
  "9908": {
    name: "tiny dinos winter 2022 #9908",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9908,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9908.png",
  },
  "9909": {
    name: "tiny dinos winter 2022 #9909",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9909,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9909.png",
  },
  "9910": {
    name: "tiny dinos winter 2022 #9910",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9910,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9910.png",
  },
  "9911": {
    name: "tiny dinos winter 2022 #9911",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9911,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9911.png",
  },
  "9912": {
    name: "tiny dinos winter 2022 #9912",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9912,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9912.png",
  },
  "9913": {
    name: "tiny dinos winter 2022 #9913",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9913,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9913.png",
  },
  "9914": {
    name: "tiny dinos winter 2022 #9914",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9914,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9914.png",
  },
  "9915": {
    name: "tiny dinos winter 2022 #9915",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9915,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9915.png",
  },
  "9916": {
    name: "tiny dinos winter 2022 #9916",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9916,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9916.png",
  },
  "9917": {
    name: "tiny dinos winter 2022 #9917",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9917,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9917.png",
  },
  "9918": {
    name: "tiny dinos winter 2022 #9918",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9918,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9918.png",
  },
  "9919": {
    name: "tiny dinos winter 2022 #9919",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9919,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9919.png",
  },
  "9920": {
    name: "tiny dinos winter 2022 #9920",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9920,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9920.png",
  },
  "9921": {
    name: "tiny dinos winter 2022 #9921",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9921,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9921.png",
  },
  "9922": {
    name: "tiny dinos winter 2022 #9922",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9922,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9922.png",
  },
  "9923": {
    name: "tiny dinos winter 2022 #9923",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9923,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9923.png",
  },
  "9924": {
    name: "tiny dinos winter 2022 #9924",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9924,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9924.png",
  },
  "9925": {
    name: "tiny dinos winter 2022 #9925",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9925,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9925.png",
  },
  "9926": {
    name: "tiny dinos winter 2022 #9926",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9926,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9926.png",
  },
  "9927": {
    name: "tiny dinos winter 2022 #9927",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9927,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9927.png",
  },
  "9928": {
    name: "tiny dinos winter 2022 #9928",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9928,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9928.png",
  },
  "9929": {
    name: "tiny dinos winter 2022 #9929",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9929,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9929.png",
  },
  "9930": {
    name: "tiny dinos winter 2022 #9930",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9930,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9930.png",
  },
  "9931": {
    name: "tiny dinos winter 2022 #9931",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9931,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9931.png",
  },
  "9932": {
    name: "tiny dinos winter 2022 #9932",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9932,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9932.png",
  },
  "9933": {
    name: "tiny dinos winter 2022 #9933",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9933,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9933.png",
  },
  "9934": {
    name: "tiny dinos winter 2022 #9934",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9934,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9934.png",
  },
  "9935": {
    name: "tiny dinos winter 2022 #9935",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9935,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9935.png",
  },
  "9936": {
    name: "tiny dinos winter 2022 #9936",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9936,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9936.png",
  },
  "9937": {
    name: "tiny dinos winter 2022 #9937",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9937,
    attributes: [
      {
        trait_type: "body",
        value: "purple linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9937.png",
  },
  "9938": {
    name: "tiny dinos winter 2022 #9938",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9938,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9938.png",
  },
  "9939": {
    name: "tiny dinos winter 2022 #9939",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9939,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9939.png",
  },
  "9940": {
    name: "tiny dinos winter 2022 #9940",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9940,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9940.png",
  },
  "9941": {
    name: "tiny dinos winter 2022 #9941",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9941,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9941.png",
  },
  "9942": {
    name: "tiny dinos winter 2022 #9942",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9942,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9942.png",
  },
  "9943": {
    name: "tiny dinos winter 2022 #9943",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9943,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9943.png",
  },
  "9944": {
    name: "tiny dinos winter 2022 #9944",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9944,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "lazer",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9944.png",
  },
  "9945": {
    name: "tiny dinos winter 2022 #9945",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9945,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9945.png",
  },
  "9946": {
    name: "tiny dinos winter 2022 #9946",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9946,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9946.png",
  },
  "9947": {
    name: "tiny dinos winter 2022 #9947",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9947,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9947.png",
  },
  "9948": {
    name: "tiny dinos winter 2022 #9948",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9948,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9948.png",
  },
  "9949": {
    name: "tiny dinos winter 2022 #9949",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9949,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9949.png",
  },
  "9950": {
    name: "tiny dinos winter 2022 #9950",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9950,
    attributes: [
      {
        trait_type: "body",
        value: "pink",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9950.png",
  },
  "9951": {
    name: "tiny dinos winter 2022 #9951",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9951,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9951.png",
  },
  "9952": {
    name: "tiny dinos winter 2022 #9952",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9952,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9952.png",
  },
  "9953": {
    name: "tiny dinos winter 2022 #9953",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9953,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9953.png",
  },
  "9954": {
    name: "tiny dinos winter 2022 #9954",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9954,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9954.png",
  },
  "9955": {
    name: "tiny dinos winter 2022 #9955",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9955,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9955.png",
  },
  "9956": {
    name: "tiny dinos winter 2022 #9956",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9956,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "red",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9956.png",
  },
  "9957": {
    name: "tiny dinos winter 2022 #9957",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9957,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9957.png",
  },
  "9958": {
    name: "tiny dinos winter 2022 #9958",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9958,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9958.png",
  },
  "9959": {
    name: "tiny dinos winter 2022 #9959",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9959,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9959.png",
  },
  "9960": {
    name: "tiny dinos winter 2022 #9960",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9960,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9960.png",
  },
  "9961": {
    name: "tiny dinos winter 2022 #9961",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9961,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9961.png",
  },
  "9962": {
    name: "tiny dinos winter 2022 #9962",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9962,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9962.png",
  },
  "9963": {
    name: "tiny dinos winter 2022 #9963",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9963,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9963.png",
  },
  "9964": {
    name: "tiny dinos winter 2022 #9964",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9964,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "blue",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9964.png",
  },
  "9965": {
    name: "tiny dinos winter 2022 #9965",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9965,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9965.png",
  },
  "9966": {
    name: "tiny dinos winter 2022 #9966",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9966,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9966.png",
  },
  "9967": {
    name: "tiny dinos winter 2022 #9967",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9967,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9967.png",
  },
  "9968": {
    name: "tiny dinos winter 2022 #9968",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9968,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9968.png",
  },
  "9969": {
    name: "tiny dinos winter 2022 #9969",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9969,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "red",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9969.png",
  },
  "9970": {
    name: "tiny dinos winter 2022 #9970",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9970,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "none",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9970.png",
  },
  "9971": {
    name: "tiny dinos winter 2022 #9971",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9971,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9971.png",
  },
  "9972": {
    name: "tiny dinos winter 2022 #9972",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9972,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9972.png",
  },
  "9973": {
    name: "tiny dinos winter 2022 #9973",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9973,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9973.png",
  },
  "9974": {
    name: "tiny dinos winter 2022 #9974",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9974,
    attributes: [
      {
        trait_type: "body",
        value: "yellow linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9974.png",
  },
  "9975": {
    name: "tiny dinos winter 2022 #9975",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9975,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "multicolor",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9975.png",
  },
  "9976": {
    name: "tiny dinos winter 2022 #9976",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9976,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9976.png",
  },
  "9977": {
    name: "tiny dinos winter 2022 #9977",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9977,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9977.png",
  },
  "9978": {
    name: "tiny dinos winter 2022 #9978",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9978,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "blue yellow",
      },
      {
        trait_type: "spikes",
        value: "teal",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9978.png",
  },
  "9979": {
    name: "tiny dinos winter 2022 #9979",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9979,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9979.png",
  },
  "9980": {
    name: "tiny dinos winter 2022 #9980",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9980,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9980.png",
  },
  "9981": {
    name: "tiny dinos winter 2022 #9981",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9981,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9981.png",
  },
  "9982": {
    name: "tiny dinos winter 2022 #9982",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9982,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9982.png",
  },
  "9983": {
    name: "tiny dinos winter 2022 #9983",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9983,
    attributes: [
      {
        trait_type: "body",
        value: "purple",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "orange",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9983.png",
  },
  "9984": {
    name: "tiny dinos winter 2022 #9984",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9984,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "light blue",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9984.png",
  },
  "9985": {
    name: "tiny dinos winter 2022 #9985",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9985,
    attributes: [
      {
        trait_type: "body",
        value: "green linear gradient",
      },
      {
        trait_type: "chest",
        value: "yellow",
      },
      {
        trait_type: "eyes",
        value: "dark gray",
      },
      {
        trait_type: "spikes",
        value: "light gray",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9985.png",
  },
  "9986": {
    name: "tiny dinos winter 2022 #9986",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9986,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "purple",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9986.png",
  },
  "9987": {
    name: "tiny dinos winter 2022 #9987",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9987,
    attributes: [
      {
        trait_type: "body",
        value: "red",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "purple",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9987.png",
  },
  "9988": {
    name: "tiny dinos winter 2022 #9988",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9988,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "light gray",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9988.png",
  },
  "9989": {
    name: "tiny dinos winter 2022 #9989",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9989,
    attributes: [
      {
        trait_type: "body",
        value: "blue linear gradient",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "green",
      },
      {
        trait_type: "spikes",
        value: "green",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9989.png",
  },
  "9990": {
    name: "tiny dinos winter 2022 #9990",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9990,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "gray",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "gold",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9990.png",
  },
  "9991": {
    name: "tiny dinos winter 2022 #9991",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9991,
    attributes: [
      {
        trait_type: "body",
        value: "lime green",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "yellow",
      },
      {
        trait_type: "spikes",
        value: "burnt orange",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9991.png",
  },
  "9992": {
    name: "tiny dinos winter 2022 #9992",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9992,
    attributes: [
      {
        trait_type: "body",
        value: "yellow",
      },
      {
        trait_type: "chest",
        value: "green",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9992.png",
  },
  "9993": {
    name: "tiny dinos winter 2022 #9993",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9993,
    attributes: [
      {
        trait_type: "body",
        value: "green",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "light gray",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "santa hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "striped scarf",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9993.png",
  },
  "9994": {
    name: "tiny dinos winter 2022 #9994",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9994,
    attributes: [
      {
        trait_type: "body",
        value: "gray",
      },
      {
        trait_type: "chest",
        value: "orange",
      },
      {
        trait_type: "eyes",
        value: "dark red",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "ear muffs",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "normal",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9994.png",
  },
  "9995": {
    name: "tiny dinos winter 2022 #9995",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9995,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "orangered",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "light green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9995.png",
  },
  "9996": {
    name: "tiny dinos winter 2022 #9996",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9996,
    attributes: [
      {
        trait_type: "body",
        value: "teal",
      },
      {
        trait_type: "chest",
        value: "light blue",
      },
      {
        trait_type: "eyes",
        value: "blue",
      },
      {
        trait_type: "spikes",
        value: "pink",
      },
      {
        trait_type: "background",
        value: "blue",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9996.png",
  },
  "9997": {
    name: "tiny dinos winter 2022 #9997",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9997,
    attributes: [
      {
        trait_type: "body",
        value: "aqua",
      },
      {
        trait_type: "chest",
        value: "aqua",
      },
      {
        trait_type: "eyes",
        value: "purple",
      },
      {
        trait_type: "spikes",
        value: "white",
      },
      {
        trait_type: "background",
        value: "red",
      },
      {
        trait_type: "head",
        value: "elf hat",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "scarf",
      },
      {
        trait_type: "feet",
        value: "snowboard",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9997.png",
  },
  "9998": {
    name: "tiny dinos winter 2022 #9998",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9998,
    attributes: [
      {
        trait_type: "body",
        value: "blue",
      },
      {
        trait_type: "chest",
        value: "purple",
      },
      {
        trait_type: "eyes",
        value: "green red",
      },
      {
        trait_type: "spikes",
        value: "dark gray",
      },
      {
        trait_type: "background",
        value: "dark green",
      },
      {
        trait_type: "head",
        value: "none",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "warm drink",
      },
      {
        trait_type: "feet",
        value: "sleigh",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9998.png",
  },
  "9999": {
    name: "tiny dinos winter 2022 #9999",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 9999,
    attributes: [
      {
        trait_type: "body",
        value: "pink linear gradient",
      },
      {
        trait_type: "chest",
        value: "blue",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "orange",
      },
      {
        trait_type: "background",
        value: "brown",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "snow shades",
      },
      {
        trait_type: "hands",
        value: "gift",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/9999.png",
  },
  "10000": {
    name: "tiny dinos winter 2022 #10000",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 10000,
    attributes: [
      {
        trait_type: "body",
        value: "light green",
      },
      {
        trait_type: "chest",
        value: "pink",
      },
      {
        trait_type: "eyes",
        value: "white",
      },
      {
        trait_type: "spikes",
        value: "yellow",
      },
      {
        trait_type: "background",
        value: "green",
      },
      {
        trait_type: "head",
        value: "knitted cap",
      },
      {
        trait_type: "face",
        value: "normal",
      },
      {
        trait_type: "hands",
        value: "gloves",
      },
      {
        trait_type: "feet",
        value: "none",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/10000.png",
  },
  "10001": {
    name: "tiny dinos winter 2022 #10001",
    description: "one of 10k tiny dinos ready for winter",
    tokenId: 10001,
    attributes: [
      {
        trait_type: "1/1",
        value: "bug",
      },
    ],
    image: "https://tinydinos.fun/images/winter-2022/10001.png",
  },
};
