import { Fragment } from "react";
import {
  VStack,
  StackDivider,
  Text,
  Heading,
  chakra,
  Link,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";

const faqs = {
  event: [
    {
      q: "When does the winter event end?",
      a: "The winter event ends at the end of the year.",
    },
    {
      q: "How long does traversal take?",
      a: "Traversal can take up to 20 minutes.",
    },
    {
      q: "How do I know when my traversal has finished?",
      a: (
        <>
          You can track your traverse transaction status on{" "}
          <Link href="https://layerzeroscan.com" isExternal>
            LayerZero Scan <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
          .
        </>
      ),
    },
    {
      q: "Can I swap back to my genesis dino?",
      a: "During the event you can swap back and forth between collections. Once the event ends halloween dinos cannot swap back to genesis dinos and won't be able to swap into future collections.",
    },
    {
      q: "Why do I have to pay a traverse fee?",
      a: "The traverse fee pays for the gas needed to complete the transaction on the destination chain. The fee is estimated ahead of traversal but isn't 100% known until traversal is complete. Once complete any of the fee not used for gas on the destination chain is refunded back to you.",
    },
    {
      q: "What's a deflationary event?",
      a: "Deflationary events are limited time events where you can traverse your tiny dinos into a brand new collection. This reduces the supply of the original tiny dinos collection.",
    },
    {
      q: "Can I swap my winter dino back to a genesis dino after the event ends?",
      a: "No",
    },
    {
      q: "Will my winter dino be eligible to take part in future traversal events?",
      a: "No",
    },
    {
      q: "How is this possible?",
      a: "Tiny dinos is an Omnichain collection which means all dinos travel between different blockchains. On top of this groundbreaking functionality tiny dinos also leverage the ability to swap collections both on different chains and on the same chain using the same technology.",
    },
  ],
  traverse: [
    {
      q: "How do I swap my dino to a different chain?",
      a: "In the form above enter your dino id, the collection your dino belongs to and the chain you want to switch your dino to. Tap traverse, confirm the transaction and the traversal process will begin.",
    },
    {
      q: "How long does traversal take?",
      a: "Traversal can take up to 20 minutes.",
    },
    {
      q: "How do I know when my traversal has finished?",
      a: (
        <>
          You can track your traverse transaction status on{" "}
          <Link href="https://layerzeroscan.com" isExternal>
            LayerZero Scan <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
          .
        </>
      ),
    },
    {
      q: "Why do I have to pay a traverse fee?",
      a: "The traverse fee pays for the gas needed to complete the transaction on the destination chain. The fee is estimated ahead of traversal but isn't 100% known until traversal is complete. Once complete any of the fee not used for gas on the destination chain is refunded back to you.",
    },
    {
      q: "What are the different collections?",
      a: "The genesis collection is the original tiny dinos collection. Subsequent collections, for example summer and halloween, use a unique Omnichain mechanism which means they were only available for a limited time by traversing your genesis dino into the collection.",
    },
    {
      q: "What does Omnichain mean?",
      a: "Omnichain is a term coined by LayerZero Labs referring to assets which can be swapped between multiple chains.",
    },
  ],
};

export const Faq = ({ page }: { page: string }) => {
  const faq = faqs[page as keyof typeof faqs];
  return (
    <chakra.div id="faq" w="full">
      <Heading
        as="h4"
        textAlign="center"
        fontWeight="bold"
        fontSize="4xl"
        mb={6}
      >
        FAQ
      </Heading>
      <VStack
        divider={<StackDivider color="gray.50" />}
        spacing={4}
        align="stretch"
      >
        {faq.map(({ q, a }) => (
          <Fragment key={q}>
            <Text fontSize="lg" fontWeight="bold" mb={3} mt={3}>
              {q}
            </Text>
            <Text>{a}</Text>
          </Fragment>
        ))}
      </VStack>
    </chakra.div>
  );
};
