import { ethers, BigNumber } from "ethers";
import { formatEther } from "@ethersproject/units";
import { supportedChains, layerZeroContract } from "../constants";
import { useEthers, useCall } from "@usedapp/core";
import { useWatch, Control } from "react-hook-form";
import { Inputs } from "../components/TraverseForm";

export function useEstimateFees(control: Control<Inputs>) {
  const { chainId } = useEthers();
  const destination = useWatch({
    control,
    name: "destination",
  });

  const { value, error } =
    useCall(
      chainId &&
        destination && {
          contract: layerZeroContract(chainId),
          method: "estimateFees",
          args: [
            supportedChains[parseInt(destination, 10)].LayerZero.chainId,
            supportedChains[chainId].dinos.genesis.address,
            ethers.utils.defaultAbiCoder.encode(
              ["address", "uint"],
              [supportedChains[chainId].dinos.genesis.address, 1234]
            ),
            false,
            ethers.utils.solidityPack(["uint16", "uint256"], [1, 350000]),
          ],
        }
    ) ?? {};

  if (error) {
    console.error(error.message);
  }

  // add 25% buffer to gas fee estimate provided by layerzero
  const percentage = value?.[0].div(BigNumber.from(4));
  const paddedGasFee = value?.[0].add(percentage);

  const gasFeeEstimate =
    value && gasFeePadLength(formatEther(paddedGasFee.toString()));

  function gasFeePadLength(gasFee: string) {
    const splitGasFee = gasFee.split("");
    let firstValueIndex = 6;

    for (const [index, num] of splitGasFee.entries()) {
      if (num !== "0" && num !== ".") {
        firstValueIndex = index;
        break;
      }
    }

    return Number(gasFee).toFixed(firstValueIndex + 1);
  }

  return { gasFeeEstimate };
}
