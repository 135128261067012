import { Tooltip as ChakraTooltip } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";

export const QuestionTooltip = ({ tip, ...rest }: { tip: string }) => {
  return (
    <ChakraTooltip label={tip} hasArrow {...rest}>
      <QuestionIcon />
    </ChakraTooltip>
  );
};
