export const LayerZeroLogo = (props: any) => (
  <svg
    viewBox="0 0 728 196"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M56.3409 1C49.0364 1.03643 41.8104 2.51911 35.0759 5.36363C28.3414 8.20814 22.2302 12.359 17.0912 17.5785C11.9523 22.7979 7.88627 28.9841 5.12543 35.7836C2.36459 42.5832 0.96305 49.8629 1.00074 57.2072V75.7728H45.965V48.875C45.9645 47.6118 46.2115 46.3609 46.692 45.1937C47.1725 44.0265 47.877 42.966 48.7654 42.0727C49.6537 41.1794 50.7084 40.4711 51.8691 39.9877C53.0299 39.5044 54.274 39.2558 55.5304 39.256H57.1514C58.4079 39.2558 59.6521 39.5044 60.813 39.9877C61.9738 40.471 63.0286 41.1794 63.9171 42.0727C64.8056 42.966 65.5103 44.0265 65.991 45.1937C66.4717 46.3609 66.719 47.6117 66.7188 48.875V120.982C72.6236 120.982 78.4707 119.812 83.9261 117.541C89.3816 115.269 94.3385 111.939 98.5139 107.741C102.689 103.543 106.001 98.559 108.261 93.0739C110.52 87.5888 111.683 81.7097 111.683 75.7728V57.2072C111.715 42.3268 105.887 28.0386 95.473 17.4654C90.363 12.2479 84.2714 8.105 77.553 5.27816C70.8347 2.45132 63.6239 0.996939 56.3409 1V1Z"
      fill={props.color}
    />
    <path
      d="M57.1536 157.489H55.5326C54.2764 157.49 53.0324 157.241 51.8718 156.758C50.7111 156.274 49.6565 155.566 48.7682 154.673C47.8799 153.78 47.1754 152.72 46.6948 151.553C46.2142 150.386 45.9669 149.135 45.9671 147.872V75.7656C40.0622 75.7651 34.215 76.934 28.7594 79.2057C23.3039 81.4775 18.347 84.8075 14.1715 89.0056C9.99612 93.2037 6.684 98.1877 4.42452 103.673C2.16503 109.158 1.00239 115.037 1.0029 120.974V139.281C0.927992 146.636 2.30411 153.933 5.05167 160.75C7.79923 167.567 11.8637 173.768 17.01 178.996C22.1564 184.224 28.2824 188.373 35.0339 191.206C41.7855 194.038 49.0285 195.496 56.344 195.496C63.6596 195.496 70.9026 194.038 77.6541 191.206C84.4056 188.373 90.5317 184.224 95.678 178.996C100.824 173.768 104.889 167.567 107.636 160.75C110.384 153.933 111.76 146.636 111.685 139.281V120.974H66.7209V147.872C66.7209 149.135 66.4734 150.386 65.9926 151.553C65.5118 152.72 64.807 153.78 63.9186 154.673C63.0301 155.566 61.9754 156.274 60.8146 156.758C59.6539 157.241 58.4099 157.49 57.1536 157.489V157.489Z"
      fill={props.color}
    />
    <path
      d="M188.549 58.0547H172.79V138.437H219.044V123.282H188.551L188.549 58.0547Z"
      fill={props.color}
    />
    <path
      d="M269.523 87.7908C267.3 85.0134 264.454 82.8055 261.217 81.3484C257.981 79.8913 254.448 79.2264 250.906 79.4083C247.206 79.379 243.543 80.1483 240.164 81.6642C236.785 83.18 233.769 85.4073 231.319 88.1954C226.06 94.1208 223.153 101.785 223.153 109.727C223.153 117.669 226.06 125.333 231.319 131.258C233.77 134.045 236.786 136.272 240.165 137.787C243.544 139.302 247.207 140.071 250.906 140.042C258.902 140.042 265.107 137.248 269.523 131.659V138.435H284.257V81.0203H269.523V87.7908ZM265.014 121.381C263.528 122.876 261.752 124.05 259.798 124.83C257.844 125.61 255.751 125.979 253.65 125.916C251.556 125.983 249.47 125.616 247.525 124.835C245.579 124.055 243.814 122.879 242.341 121.381C239.473 118.183 237.886 114.03 237.886 109.724C237.886 105.418 239.473 101.265 242.341 98.0674C243.815 96.5707 245.58 95.3955 247.525 94.6154C249.471 93.8354 251.556 93.4673 253.65 93.5338C255.751 93.4711 257.843 93.8411 259.797 94.6207C261.752 95.4003 263.527 96.5731 265.014 98.0674C267.914 101.249 269.523 105.408 269.523 109.724C269.523 114.04 267.914 118.199 265.014 121.381V121.381Z"
      fill={props.color}
    />
    <path
      d="M322.219 119.61L307.325 81.0195H291.221L314.627 136.6C313.648 139.917 311.721 142.871 309.087 145.097C306.319 146.985 302.998 147.876 299.663 147.625V161.407C306.165 162.012 312.667 160.236 317.972 156.407C322.872 152.688 326.808 146.73 329.781 138.533L350.6 81.0233H334.839L322.219 119.61Z"
      fill={props.color}
    />
    <path
      d="M382.484 79.4126C378.467 79.2706 374.465 79.9678 370.73 81.4605C366.996 82.9532 363.609 85.209 360.786 88.0847C355.334 93.9774 352.313 101.733 352.334 109.781C352.354 117.829 355.416 125.568 360.898 131.432C366.61 137.172 374.186 140.043 383.627 140.044C394.588 140.044 402.888 135.949 408.524 127.76L396.647 120.867C395.137 122.795 393.184 124.324 390.955 125.324C388.727 126.324 386.291 126.765 383.855 126.609C375.098 126.609 369.729 123.012 367.75 115.817H410.809C411.177 113.808 411.368 111.77 411.379 109.727C411.598 101.73 408.663 93.9702 403.214 88.1393C400.576 85.2852 397.361 83.03 393.786 81.5251C390.211 80.0201 386.358 79.3 382.484 79.4126ZM367.523 104.332C368.175 100.958 369.996 97.9265 372.661 95.775C375.496 93.6682 378.96 92.5949 382.482 92.7328C385.701 92.6906 388.848 93.6986 391.45 95.6055C394.217 97.748 396.074 100.866 396.645 104.33L367.523 104.332Z"
      fill={props.color}
    />
    <path
      d="M436.391 90.8983V81.0201H421.657V138.437H436.391V110.993C436.391 105.481 438.161 101.538 441.701 99.164C445.245 96.79 449.535 95.8092 453.752 96.4081V79.8767C450.048 79.8294 446.4 80.781 443.186 82.6326C440.013 84.4841 437.602 87.4159 436.391 90.8983Z"
      fill={props.color}
    />
    <path
      d="M525.587 70.4865V58.0547H473.181V73.2134H505.618L472.337 125.777V138.435H526.223V123.28H492.302L525.587 70.4865Z"
      fill={props.color}
    />
    <path
      d="M561.005 79.4126C556.988 79.2706 552.985 79.9678 549.25 81.4605C545.515 82.9532 542.129 85.2089 539.305 88.0848C533.852 93.9772 530.83 101.733 530.852 109.781C530.873 117.83 533.935 125.569 539.419 131.432C545.13 137.172 552.706 140.043 562.146 140.044C573.107 140.044 581.407 135.949 587.045 127.76L575.166 120.867C573.656 122.794 571.703 124.324 569.475 125.324C567.247 126.324 564.811 126.765 562.376 126.609C553.617 126.609 548.249 123.012 546.272 115.817H589.331C589.697 113.808 589.888 111.77 589.9 109.727C590.118 101.73 587.183 93.9702 581.735 88.1394C579.096 85.2851 575.882 83.03 572.307 81.5251C568.732 80.0201 564.879 79.2999 561.005 79.4126V79.4126ZM546.044 104.332C546.696 100.958 548.517 97.9259 551.184 95.775C554.018 93.6675 557.482 92.5941 561.005 92.7328C564.224 92.6896 567.371 93.6977 569.972 95.6055C572.74 97.7477 574.596 100.866 575.166 104.33L546.044 104.332Z"
      fill={props.color}
    />
    <path
      d="M614.915 90.8982V81.02H600.181V138.437H614.915V110.993C614.915 105.481 616.685 101.538 620.225 99.164C623.769 96.7901 628.058 95.8093 632.274 96.4081V79.8766C628.571 79.8298 624.924 80.7814 621.711 82.6325C618.537 84.4838 616.126 87.4157 614.915 90.8982V90.8982Z"
      fill={props.color}
    />
    <path
      d="M665.849 79.414C658.936 79.4906 652.263 81.9707 646.962 86.4328C641.661 90.8949 638.06 97.0642 636.769 103.893C635.479 110.722 636.579 117.789 639.883 123.895C643.186 130.001 648.49 134.768 654.894 137.389C661.297 140.009 668.405 140.321 675.01 138.27C681.616 136.22 687.312 131.934 691.132 126.14C694.951 120.346 696.659 113.402 695.964 106.486C695.269 99.5707 692.215 93.1098 687.32 88.201C684.531 85.3359 681.186 83.0774 677.492 81.5659C673.799 80.0543 669.836 79.322 665.849 79.414V79.414ZM676.927 121.099C675.482 122.565 673.754 123.719 671.849 124.488C669.943 125.257 667.902 125.626 665.849 125.572C663.804 125.63 661.769 125.263 659.871 124.493C657.974 123.723 656.255 122.568 654.823 121.099C651.995 117.995 650.427 113.939 650.427 109.73C650.427 105.52 651.995 101.464 654.823 98.3608C656.255 96.8919 657.974 95.7365 659.872 94.9668C661.769 94.197 663.804 93.8293 665.849 93.8875C667.902 93.8334 669.943 94.2027 671.848 94.972C673.754 95.7413 675.482 96.8944 676.927 98.3608C679.79 101.447 681.383 105.509 681.383 109.73C681.383 113.95 679.79 118.013 676.927 121.099V121.099Z"
      fill={props.color}
    />
    <path d="M727 120.75H709.068V138.435H727V120.75Z" fill={props.color} />
  </svg>
);
