import {
  Mainnet,
  Avalanche,
  BSC,
  Polygon,
  Arbitrum,
  Fantom,
  Optimism,
  ChainId,
} from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

import LZAbi from "./abis/LayerZero.json";
import GenesisAbi from "./abis/Genesis.json";
import EventAbi from "./abis/Event.json";

export const readOnlyUrls = {
  [Mainnet.chainId]: "https://rpc.ankr.com/eth",
  [Avalanche.chainId]: "https://api.avax.network/ext/bc/C/rpc",
  [BSC.chainId]: "https://bsc-dataseed1.binance.org",
  [Polygon.chainId]: "https://polygon-rpc.com/",
  [Arbitrum.chainId]: "https://arb1.arbitrum.io/rpc",
  [Fantom.chainId]: "https://rpc.ftm.tools",
  [Optimism.chainId]: "https://mainnet.optimism.io/",
};

export const genesisContract = (chainId: ChainId): Contract | null => {
  if (supportedChains[chainId]) {
    return new Contract(
      supportedChains[chainId].dinos.genesis.address,
      new utils.Interface(GenesisAbi)
    );
  }

  return null;
};

export const summer2022Contract = (chainId: ChainId): Contract | null => {
  if (supportedChains[chainId]) {
    return new Contract(
      supportedChains[chainId].dinos.summer2022.address,
      new utils.Interface(EventAbi)
    );
  }

  return null;
};

export const halloween2022Contract = (chainId: ChainId): Contract | null => {
  if (supportedChains[chainId]) {
    return new Contract(
      supportedChains[chainId].dinos.halloween2022.address,
      new utils.Interface(EventAbi)
    );
  }

  return null;
};

export const winter2022Contract = (chainId: ChainId): Contract | null => {
  if (supportedChains[chainId]) {
    return new Contract(
      supportedChains[chainId].dinos.winter2022.address,
      new utils.Interface(EventAbi)
    );
  }

  return null;
};

export const layerZeroContract = (chainId: ChainId): Contract =>
  new Contract(
    supportedChains[chainId].LayerZero.endpoint,
    new utils.Interface(LZAbi)
  );

export const supportedChains = {
  [Mainnet.chainId]: {
    displayName: "Ethereum",
    symbol: "ETH",
    LayerZero: {
      endpoint: "0x66A71Dcef29A0fFBDBE3c6a460a3B5BC225Cd675",
      chainId: 101,
    },
    dinos: {
      genesis: {
        address: "0xd9b78a2f1dafc8bb9c60961790d2beefebee56f4",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-eth",
          },
        ],
      },
      summer2022: {
        address: "0x5a1190759c9e7cf42da401639016f8f60affd465",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022",
          },
        ],
      },
      halloween2022: {
        address: "0xc1dCC70e27B187457709E0c72DB3df941034eC6f",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022",
          },
        ],
      },
      winter2022: {
        address: "0x89e83F99Bc48B9229EA7F2B9509a995e89C8472F",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-winter-2022",
          },
        ],
      },
    },
    ...Mainnet,
    rpcUrl: readOnlyUrls[Mainnet.chainId],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
  },
  [BSC.chainId]: {
    displayName: "Binance Smart Chain",
    symbol: "BSC",
    LayerZero: {
      endpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
      chainId: 102,
    },
    dinos: {
      genesis: {
        address: "0xEFb872050656d1F3eFeb4643df71B716Bbf812d5",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-bnb",
          },
        ],
      },
      summer2022: {
        address: "0xfEaFdC67892A8D00869fD081B8307ef18EAAA62B",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022-v3",
          },
        ],
      },
      halloween2022: {
        address: "0xA784491d2A8361051329f1a301F7390CC51AF6C6",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022-v3",
          },
        ],
      },
      winter2022: {
        address: "0xD8E0f750e34C4bf8bbEdD09e35BaFc8A78483584",
        marketplaces: [
          {
            name: "OpenSea",
            link: "",
          },
        ],
      },
    },
    ...BSC,
    rpcUrl: readOnlyUrls[BSC.chainId],
    nativeCurrency: {
      name: "Binance Chain Native Token",
      symbol: "BNB",
      decimals: 18,
    },
    chainName: "Binance Smart Chain Mainnet",
  },
  [Avalanche.chainId]: {
    displayName: "Avalanche",
    symbol: "AVAX",
    LayerZero: {
      endpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
      chainId: 106,
    },
    dinos: {
      genesis: {
        address: "0xaAEEf52Ad4695b8e3B758215ca6BBCa4D7680C62",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-avax",
          },
        ],
      },
      summer2022: {
        address: "0x907D2a4e0DcD20D614850800ECf83B4f59b708df",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022-v4",
          },
        ],
      },
      halloween2022: {
        address: "0x8c648DD5d34D9b3b1B4184333E5C15843339F76b",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022-s83hbxrpap",
          },
        ],
      },
      winter2022: {
        address: "0x9FC531cf9b2Fdb8F11f08391c965C2baD42a1F5c",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-winter-2022-v4",
          },
        ],
      },
    },
    ...Avalanche,
    rpcUrl: readOnlyUrls[Avalanche.chainId],
    chainName: "Avalanche C-Chain",
  },
  [Polygon.chainId]: {
    displayName: "Polygon",
    symbol: "Poly",
    LayerZero: {
      endpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
      chainId: 109,
    },
    dinos: {
      genesis: {
        address: "0xaAEEf52Ad4695b8e3B758215ca6BBCa4D7680C62",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-poly",
          },
        ],
      },
      summer2022: {
        address: "0xfEaFdC67892A8D00869fD081B8307ef18EAAA62B",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022-poly",
          },
        ],
      },
      halloween2022: {
        address: "0x5CaB0996dc2d1B5933da89553907BA010A80d97e",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022-v2",
          },
        ],
      },
      winter2022: {
        address: "0xd9026BFcdd38A260567519cF7db97616489D896B",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-winter-2022-v2",
          },
        ],
      },
    },
    rpcUrl: readOnlyUrls[Polygon.chainId],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18,
    },
    ...Polygon,
    chainName: "Polygon Mainnet",
  },
  [Arbitrum.chainId]: {
    displayName: "Arbitrum",
    symbol: "Arb",
    LayerZero: {
      endpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
      chainId: 110,
    },
    dinos: {
      genesis: {
        address: "0xaAEEf52Ad4695b8e3B758215ca6BBCa4D7680C62",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-arbitrum",
          },
        ],
      },
      summer2022: {
        address: "0x907D2a4e0DcD20D614850800ECf83B4f59b708df",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022-1",
          },
        ],
      },
      halloween2022: {
        address: "0xaec825b1C6E5154F07884e2AA35Eb6247A14b009",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022-v4",
          },
        ],
      },
      winter2022: {
        address: "0xBCd40bd88bE04996903EedD76D221Fd68d796969",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-winter-2022-v3",
          },
        ],
      },
    },
    ...Arbitrum,
    rpcUrl: readOnlyUrls[Arbitrum.chainId],
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    chainName: "Arbitrum One",
  },
  [Optimism.chainId]: {
    displayName: "Optimism",
    symbol: "Opt",
    LayerZero: {
      endpoint: "0x3c2269811836af69497E5F486A85D7316753cf62",
      chainId: 111,
    },
    dinos: {
      genesis: {
        address: "0xaAEEf52Ad4695b8e3B758215ca6BBCa4D7680C62",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-optimism",
          },
        ],
      },
      summer2022: {
        address: "0x907D2a4e0DcD20D614850800ECf83B4f59b708df",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-summer-2022-v2",
          },
        ],
      },
      halloween2022: {
        address: "0x8c648DD5d34D9b3b1B4184333E5C15843339F76b",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-halloween-2022-7slmf5xvdg",
          },
        ],
      },
      winter2022: {
        address: "0xBCd40bd88bE04996903EedD76D221Fd68d796969",
        marketplaces: [
          {
            name: "OpenSea",
            link: "https://opensea.io/collection/tiny-dinos-winter-2022-sx2ewwmx0y",
          },
        ],
      },
    },
    nativeCurrency: {
      name: "Ether",
      symbol: "ETH",
      decimals: 18,
    },
    ...Optimism,
    rpcUrl: readOnlyUrls[Optimism.chainId],
  },
  [Fantom.chainId]: {
    displayName: "Fantom",
    symbol: "FTM",
    LayerZero: {
      endpoint: "0xb6319cC6c8c27A8F5dAF0dD3DF91EA35C4720dd7",
      chainId: 112,
    },
    dinos: {
      genesis: {
        address: "0xaAEEf52Ad4695b8e3B758215ca6BBCa4D7680C62",
        marketplaces: [
          {
            name: "Tofu",
            link: "https://tofunft.com/collection/tiny-dinos-ftm/items",
          },
        ],
      },
      summer2022: {
        address: "0x907D2a4e0DcD20D614850800ECf83B4f59b708df",
        marketplaces: [
          {
            name: "Tofu",
            link: "https://tofunft.com/collection/tiny-dinos-summer-2022-ftm/items",
          },
        ],
      },
      halloween2022: {
        address: "0x5CaB0996dc2d1B5933da89553907BA010A80d97e",
        marketplaces: [
          {
            name: "Tofu",
            link: "https://tofunft.com/discover/items?contracts=56506&network=250",
          },
        ],
      },
      winter2022: {
        address: "0xd9026BFcdd38A260567519cF7db97616489D896B",
        marketplaces: [
          {
            name: "Tofu",
            link: "https://tofunft.com/discover/items?contracts=64125&network=137",
          },
        ],
      },
    },
    nativeCurrency: {
      name: "Fantom",
      symbol: "FTM",
      decimals: 18,
    },
    ...Fantom,
    rpcUrl: readOnlyUrls[Fantom.chainId],
    chainName: "Fantom Opera",
  },
};

export const networks = Object.keys(supportedChains).map(
  (chainId) => supportedChains[chainId as any]
);

export const marketplaces = [
  {
    name: "Genesis",
    collectionKey: "genesis",
    chains: Object.keys(supportedChains).map((chainId) => {
      const chain = supportedChains[chainId as any];
      return {
        ...chain.dinos.genesis.marketplaces?.[0],
        displayName: chain.displayName,
      };
    }),
  },
  {
    name: "Summer",
    collectionKey: "summer2022",
    chains: Object.keys(supportedChains).map((chainId) => {
      const chain = supportedChains[chainId as any];
      return {
        ...chain.dinos.summer2022.marketplaces?.[0],
        displayName: chain.displayName,
      };
    }),
  },
  {
    name: "Halloween",
    collectionKey: "halloween2022",
    disabled: false,
    chains: Object.keys(supportedChains).map((chainId) => {
      const chain = supportedChains[chainId as any];
      return {
        ...chain.dinos.halloween2022.marketplaces?.[0],
        displayName: chain.displayName,
      };
    }),
  },
  {
    name: "Winter",
    collectionKey: "winter2022",
    disabled: false,
    chains: Object.keys(supportedChains).map((chainId) => {
      const chain = supportedChains[chainId as any];
      return {
        ...chain.dinos.winter2022.marketplaces?.[0],
        displayName: chain.displayName,
      };
    }),
  },
];
