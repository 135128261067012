import { Box, Link, chakra, Flex, Spacer } from "@chakra-ui/react";

export const Footer = () => {
  return (
    <chakra.footer mt={20}>
      <Flex alignItems="flex-end">
        <Box display={{ base: "none", sm: "block" }}></Box>
        <Spacer />
        <Link isExternal href="https://twitter.com/intent/tweet?text=%23rawr">
          #rawr
        </Link>
      </Flex>
    </chakra.footer>
  );
};
