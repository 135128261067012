export const inscriptions: any = [
  {
    inscription:
      "40cdb4e944131797db22f747d769a52a7e0410ee828d26981cb061bea7837879i0",
    index: 9215,
    name: "dino #2701",
    explorer:
      "https://ordinals.com/inscription/40cdb4e944131797db22f747d769a52a7e0410ee828d26981cb061bea7837879i0",
  },
  {
    inscription:
      "b67cffc6aa5295b47f19272b0aacfe8b4c6d150db23dc494cf71dd3d69101748i0",
    index: 9439,
    name: "dino #5588",
    explorer:
      "https://ordinals.com/inscription/b67cffc6aa5295b47f19272b0aacfe8b4c6d150db23dc494cf71dd3d69101748i0",
  },
  {
    inscription:
      "12f74dce8ade0e886b1a8e1ce37f9ef0770ceb410f1f590e8aad26d3baa1368ai0",
    index: 9512,
    name: "dino #775",
    explorer:
      "https://ordinals.com/inscription/12f74dce8ade0e886b1a8e1ce37f9ef0770ceb410f1f590e8aad26d3baa1368ai0",
  },
  {
    inscription:
      "b327cfbc7804053a3951023a75c8db60edf5a6c197d3490d55348b368105ae7bi0",
    index: 9936,
    name: "dino #2992",
    explorer:
      "https://ordinals.com/inscription/b327cfbc7804053a3951023a75c8db60edf5a6c197d3490d55348b368105ae7bi0",
  },
  {
    inscription:
      "01241938a74f4924f1cf3b07d8af1eca099e17d9097dee375bc1df11c10a14e5i0",
    index: 10370,
    name: "dino #1086",
    explorer:
      "https://ordinals.com/inscription/01241938a74f4924f1cf3b07d8af1eca099e17d9097dee375bc1df11c10a14e5i0",
  },
  {
    inscription:
      "7d4117b725d6e8d244462b0702ee395aa85d26a2dc522592260101f4dbc82108i0",
    location:
      "7d4117b725d6e8d244462b0702ee395aa85d26a2dc522592260101f4dbc82108:0:0",
    explorer:
      "https://ordinals.com/inscription/7d4117b725d6e8d244462b0702ee395aa85d26a2dc522592260101f4dbc82108i0",
    index: 56927,
    name: "solid gold straw hat",
  },
  {
    inscription:
      "ea4a10aaa1624e9f6b87d33446f63cbea96999077799ccf10283b608d2213d0bi0",
    location:
      "ea4a10aaa1624e9f6b87d33446f63cbea96999077799ccf10283b608d2213d0b:0:0",
    explorer:
      "https://ordinals.com/inscription/ea4a10aaa1624e9f6b87d33446f63cbea96999077799ccf10283b608d2213d0bi0",
    index: 56929,
    name: "superman",
  },
  {
    inscription:
      "79a3b367a25e1e21d0c5291ba4f19d7d1aa01805e97bc65850ba9d412003de0ci0",
    location:
      "79a3b367a25e1e21d0c5291ba4f19d7d1aa01805e97bc65850ba9d412003de0c:0:0",
    explorer:
      "https://ordinals.com/inscription/79a3b367a25e1e21d0c5291ba4f19d7d1aa01805e97bc65850ba9d412003de0ci0",
    index: 56828,
    name: "btc noggles",
  },
  {
    inscription:
      "2a29b681d5682b07690332603fe1a5881d316a9792952b20918a463376d1760di0",
    location:
      "2a29b681d5682b07690332603fe1a5881d316a9792952b20918a463376d1760d:0:0",
    explorer:
      "https://ordinals.com/inscription/2a29b681d5682b07690332603fe1a5881d316a9792952b20918a463376d1760di0",
    index: 56829,
    name: "btc winter",
  },
  {
    inscription:
      "b766208bc23ca288c13e4d3a4e1b9b801588e4c8f0c2bfb43e48392cdae3e01di0",
    location:
      "b766208bc23ca288c13e4d3a4e1b9b801588e4c8f0c2bfb43e48392cdae3e01d:0:0",
    explorer:
      "https://ordinals.com/inscription/b766208bc23ca288c13e4d3a4e1b9b801588e4c8f0c2bfb43e48392cdae3e01di0",
    index: 56930,
    name: "mickey",
  },
  {
    inscription:
      "16ca6a4640c185826df53669bb730765fba266f726f020c1d9c24ae798fbcd27i0",
    location:
      "16ca6a4640c185826df53669bb730765fba266f726f020c1d9c24ae798fbcd27:0:0",
    explorer:
      "https://ordinals.com/inscription/16ca6a4640c185826df53669bb730765fba266f726f020c1d9c24ae798fbcd27i0",
    index: 56931,
    name: "rainbow 2.0",
  },
  {
    inscription:
      "3b615d1051c2f61ec77d2961e123eaa456a68e7a683a05704124ee4ba6838734i0",
    location:
      "3b615d1051c2f61ec77d2961e123eaa456a68e7a683a05704124ee4ba6838734:0:0",
    explorer:
      "https://ordinals.com/inscription/3b615d1051c2f61ec77d2961e123eaa456a68e7a683a05704124ee4ba6838734i0",
    index: 56830,
    name: "bart",
  },
  {
    inscription:
      "a39cacd4460282da29c98363f92dfed4b25126f606ea41ff5b2fa770356f0e37i0",
    location:
      "a39cacd4460282da29c98363f92dfed4b25126f606ea41ff5b2fa770356f0e37:0:0",
    explorer:
      "https://ordinals.com/inscription/a39cacd4460282da29c98363f92dfed4b25126f606ea41ff5b2fa770356f0e37i0",
    index: 56831,
    name: "btc straw hat",
  },
  {
    inscription:
      "8c82b9152d10a17c249352ce65dc8c12a5afc2b3a9d872666f1325370a4ecc3ci0",
    location:
      "8c82b9152d10a17c249352ce65dc8c12a5afc2b3a9d872666f1325370a4ecc3c:0:0",
    explorer:
      "https://ordinals.com/inscription/8c82b9152d10a17c249352ce65dc8c12a5afc2b3a9d872666f1325370a4ecc3ci0",
    index: 56932,
    name: "zombie 3d glasses",
  },
  {
    inscription:
      "c4ca844e2619e37f0269542bee4cd7e4e64e21fcd872fb667b1f75b50363af42i0",
    location:
      "c4ca844e2619e37f0269542bee4cd7e4e64e21fcd872fb667b1f75b50363af42:0:0",
    explorer:
      "https://ordinals.com/inscription/c4ca844e2619e37f0269542bee4cd7e4e64e21fcd872fb667b1f75b50363af42i0",
    index: 56933,
    name: "solid gold meat cleaver",
  },
  {
    inscription:
      "a3fc7cb44ac70e1b7b462962aa192c6745af9d0df546f9d5ba0cb45188d41a43i0",
    location:
      "a3fc7cb44ac70e1b7b462962aa192c6745af9d0df546f9d5ba0cb45188d41a43:0:0",
    explorer:
      "https://ordinals.com/inscription/a3fc7cb44ac70e1b7b462962aa192c6745af9d0df546f9d5ba0cb45188d41a43i0",
    index: 56934,
    name: "pastel green",
  },
  {
    inscription:
      "2d730a9f38b303ae03d1bbf08acd16a16fe2f41f493aa9288eca244640c2a545i0",
    location:
      "2d730a9f38b303ae03d1bbf08acd16a16fe2f41f493aa9288eca244640c2a545:0:0",
    explorer:
      "https://ordinals.com/inscription/2d730a9f38b303ae03d1bbf08acd16a16fe2f41f493aa9288eca244640c2a545i0",
    index: 56935,
    name: "miner",
  },
  {
    inscription:
      "937ba4dbab365f69bc2508b9776f3b61ce5d3ac254b06481276bc50cf2963047i0",
    location:
      "937ba4dbab365f69bc2508b9776f3b61ce5d3ac254b06481276bc50cf2963047:0:0",
    explorer:
      "https://ordinals.com/inscription/937ba4dbab365f69bc2508b9776f3b61ce5d3ac254b06481276bc50cf2963047i0",
    index: 56832,
    name: "btc summer",
  },
  {
    inscription:
      "446a6fc238a5dbf26d53d28e655de56765df411de78dff66bdac251509f58848i0",
    location:
      "446a6fc238a5dbf26d53d28e655de56765df411de78dff66bdac251509f58848:0:0",
    explorer:
      "https://ordinals.com/inscription/446a6fc238a5dbf26d53d28e655de56765df411de78dff66bdac251509f58848i0",
    index: 56936,
    name: "btc genesis",
  },
  {
    inscription:
      "872a461e8af3ab703fee1c9ee8b52dda2bcb52ccd764e70bfc7e8fa62511bf48i0",
    location:
      "872a461e8af3ab703fee1c9ee8b52dda2bcb52ccd764e70bfc7e8fa62511bf48:0:0",
    explorer:
      "https://ordinals.com/inscription/872a461e8af3ab703fee1c9ee8b52dda2bcb52ccd764e70bfc7e8fa62511bf48i0",
    index: 56937,
    name: "solid gold genesis",
  },
  {
    inscription:
      "39254f92b79990db60ed40d506ef08437e466289dafb26ae21c84617ac47ad4ci0",
    location:
      "39254f92b79990db60ed40d506ef08437e466289dafb26ae21c84617ac47ad4c:0:0",
    explorer:
      "https://ordinals.com/inscription/39254f92b79990db60ed40d506ef08437e466289dafb26ae21c84617ac47ad4ci0",
    index: 56938,
    name: "silhouette",
  },
  {
    inscription:
      "ed01f7465348b3e45984fdd9718d835c952d75c5ebd57436c104c5f7f5e3fb51i0",
    location:
      "ed01f7465348b3e45984fdd9718d835c952d75c5ebd57436c104c5f7f5e3fb51:0:0",
    explorer:
      "https://ordinals.com/inscription/ed01f7465348b3e45984fdd9718d835c952d75c5ebd57436c104c5f7f5e3fb51i0",
    index: 56833,
    name: "alien",
  },
  {
    inscription:
      "ac2553a30d216985f2f8acd7786316b40bbdeb2907938cdb8faacd976d9d5b53i0",
    location:
      "ac2553a30d216985f2f8acd7786316b40bbdeb2907938cdb8faacd976d9d5b53:0:0",
    explorer:
      "https://ordinals.com/inscription/ac2553a30d216985f2f8acd7786316b40bbdeb2907938cdb8faacd976d9d5b53i0",
    index: 56939,
    name: "solid gold purple hat",
  },
  {
    inscription:
      "0205cb17d661b49a62b11ea18b337516bdbbf6fe925b857f222d4f8755f40354i0",
    location:
      "0205cb17d661b49a62b11ea18b337516bdbbf6fe925b857f222d4f8755f40354:0:0",
    explorer:
      "https://ordinals.com/inscription/0205cb17d661b49a62b11ea18b337516bdbbf6fe925b857f222d4f8755f40354i0",
    index: 56940,
    name: "flipped silhouette",
  },
  {
    inscription:
      "435931ed6e67ab42e5acf95b172d1d5ebb5348be0f6a247408d154223e576454i0",
    location:
      "435931ed6e67ab42e5acf95b172d1d5ebb5348be0f6a247408d154223e576454:0:0",
    explorer:
      "https://ordinals.com/inscription/435931ed6e67ab42e5acf95b172d1d5ebb5348be0f6a247408d154223e576454i0",
    index: 56941,
    name: "sonic",
  },
  {
    inscription:
      "737396297df649eec2d2d2ef213d4a672571999f78940371b702bcbd8cb64d5ci0",
    location:
      "737396297df649eec2d2d2ef213d4a672571999f78940371b702bcbd8cb64d5c:0:0",
    explorer:
      "https://ordinals.com/inscription/737396297df649eec2d2d2ef213d4a672571999f78940371b702bcbd8cb64d5ci0",
    index: 56834,
    name: "btc purple cap",
  },
  {
    inscription:
      "b0f49d617f2d56aee9e8bc699604c04eaa2b7388b2c915c948896dc2ac3b5764i0",
    location:
      "b0f49d617f2d56aee9e8bc699604c04eaa2b7388b2c915c948896dc2ac3b5764:0:0",
    explorer:
      "https://ordinals.com/inscription/b0f49d617f2d56aee9e8bc699604c04eaa2b7388b2c915c948896dc2ac3b5764i0",
    index: 56942,
    name: "solid gold winter",
  },
  {
    inscription:
      "608613c4ec4ba10f38b13f83a9caad8d8483ff9c5ec76dee9a3865585468dd64i0",
    location:
      "608613c4ec4ba10f38b13f83a9caad8d8483ff9c5ec76dee9a3865585468dd64:0:0",
    explorer:
      "https://ordinals.com/inscription/608613c4ec4ba10f38b13f83a9caad8d8483ff9c5ec76dee9a3865585468dd64i0",
    index: 56835,
    name: "btc knitted hat",
  },
  {
    inscription:
      "ca06a90cb96be4a4a46f156dd3619e441e30a84ab66f8bdf8c45a731b696f165i0",
    location:
      "ca06a90cb96be4a4a46f156dd3619e441e30a84ab66f8bdf8c45a731b696f165:0:0",
    explorer:
      "https://ordinals.com/inscription/ca06a90cb96be4a4a46f156dd3619e441e30a84ab66f8bdf8c45a731b696f165i0",
    index: 56836,
    name: "btc skateboard",
  },
  {
    inscription:
      "1c1a657765ae74b078ef36ea038e2940b81c67c22300aa0d3e2e3a0ff36d4e6ei0",
    location:
      "1c1a657765ae74b078ef36ea038e2940b81c67c22300aa0d3e2e3a0ff36d4e6e:0:0",
    explorer:
      "https://ordinals.com/inscription/1c1a657765ae74b078ef36ea038e2940b81c67c22300aa0d3e2e3a0ff36d4e6ei0",
    index: 56943,
    name: "pink",
  },
  {
    inscription:
      "aa1f70cb5a2abf7998461399347d9f3e00a18f23dc56995935457a4579d70f6fi0",
    location:
      "aa1f70cb5a2abf7998461399347d9f3e00a18f23dc56995935457a4579d70f6f:0:0",
    explorer:
      "https://ordinals.com/inscription/aa1f70cb5a2abf7998461399347d9f3e00a18f23dc56995935457a4579d70f6fi0",
    index: 56944,
    name: "zombie laser eyes 2.0",
  },
  {
    inscription:
      "5c4c160b113d2e686586bed1f6bccf4ffc3d0f9d580381dcee0c5ca9bc4af77ai0",
    location:
      "5c4c160b113d2e686586bed1f6bccf4ffc3d0f9d580381dcee0c5ca9bc4af77a:0:0",
    explorer:
      "https://ordinals.com/inscription/5c4c160b113d2e686586bed1f6bccf4ffc3d0f9d580381dcee0c5ca9bc4af77ai0",
    index: 56837,
    name: "apple",
  },
  {
    inscription:
      "51986a93eb633a9d0ab83e2cc34897944a7a3fde883eda492e60560aa7a38695i0",
    location:
      "51986a93eb633a9d0ab83e2cc34897944a7a3fde883eda492e60560aa7a38695:0:0",
    explorer:
      "https://ordinals.com/inscription/51986a93eb633a9d0ab83e2cc34897944a7a3fde883eda492e60560aa7a38695i0",
    index: 56945,
    name: "solid gold skateboard",
  },
  {
    inscription:
      "69394a3ae778e2c2ecc76126d2a7b0fb83d0e43e80d17e00ed50c8d80cbe259ci0",
    location:
      "69394a3ae778e2c2ecc76126d2a7b0fb83d0e43e80d17e00ed50c8d80cbe259c:0:0",
    explorer:
      "https://ordinals.com/inscription/69394a3ae778e2c2ecc76126d2a7b0fb83d0e43e80d17e00ed50c8d80cbe259ci0",
    index: 56946,
    name: "pika",
  },
  {
    inscription:
      "8b359b001cca7c5fc959d9b8f72f596811c2a9b681c2ff82af92310c2f36709fi0",
    location:
      "8b359b001cca7c5fc959d9b8f72f596811c2a9b681c2ff82af92310c2f36709f:0:0",
    explorer:
      "https://ordinals.com/inscription/8b359b001cca7c5fc959d9b8f72f596811c2a9b681c2ff82af92310c2f36709fi0",
    index: 56838,
    name: "btc meat cleaver",
  },
  {
    inscription:
      "4ec7a8c62fc217246ba2b9a3babc3193148f7d9cfabd72de313b2085975daca4i0",
    location:
      "4ec7a8c62fc217246ba2b9a3babc3193148f7d9cfabd72de313b2085975daca4:0:0",
    explorer:
      "https://ordinals.com/inscription/4ec7a8c62fc217246ba2b9a3babc3193148f7d9cfabd72de313b2085975daca4i0",
    index: 56947,
    name: "twitter",
  },
  {
    inscription:
      "abe749237ba961782f5ba8dabb2236b0a65c8afc01d859d61869de16ad929daai0",
    location:
      "abe749237ba961782f5ba8dabb2236b0a65c8afc01d859d61869de16ad929daa:0:0",
    explorer:
      "https://ordinals.com/inscription/abe749237ba961782f5ba8dabb2236b0a65c8afc01d859d61869de16ad929daai0",
    index: 56948,
    name: "solid gold halloween",
  },
  {
    inscription:
      "0dcc4313f7875d22bb1caa53a3b880a4d07f49c7920dc89a2858893b743a30b3i0",
    location:
      "0dcc4313f7875d22bb1caa53a3b880a4d07f49c7920dc89a2858893b743a30b3:0:0",
    explorer:
      "https://ordinals.com/inscription/0dcc4313f7875d22bb1caa53a3b880a4d07f49c7920dc89a2858893b743a30b3i0",
    index: 56949,
    name: "pastel yellow",
  },
  {
    inscription:
      "98596ecc58c422098e614cfc7754059f284931c508cafe9cad4eefd6a3a2deb8i0",
    location:
      "98596ecc58c422098e614cfc7754059f284931c508cafe9cad4eefd6a3a2deb8:0:0",
    explorer:
      "https://ordinals.com/inscription/98596ecc58c422098e614cfc7754059f284931c508cafe9cad4eefd6a3a2deb8i0",
    index: 56950,
    name: "sartoshi",
  },
  {
    inscription:
      "39d439a5fc28684b8e8029718c34c966b908c75bcda9f0af0e90a01ac8e8d3b9i0",
    location:
      "39d439a5fc28684b8e8029718c34c966b908c75bcda9f0af0e90a01ac8e8d3b9:0:0",
    explorer:
      "https://ordinals.com/inscription/39d439a5fc28684b8e8029718c34c966b908c75bcda9f0af0e90a01ac8e8d3b9i0",
    index: 56951,
    name: "pastel red",
  },
  {
    inscription:
      "db8387d9101a8046b49c78995c21a90280fd300a0aba21d2d638a3d6a3a730c3i0",
    location:
      "db8387d9101a8046b49c78995c21a90280fd300a0aba21d2d638a3d6a3a730c3:0:0",
    explorer:
      "https://ordinals.com/inscription/db8387d9101a8046b49c78995c21a90280fd300a0aba21d2d638a3d6a3a730c3i0",
    index: 56839,
    name: "ape",
  },
  {
    inscription:
      "84fb52795aceadb55d5263f99bcfb3cc34882b30d1274332f901b931c0826cc4i0",
    location:
      "84fb52795aceadb55d5263f99bcfb3cc34882b30d1274332f901b931c0826cc4:0:0",
    explorer:
      "https://ordinals.com/inscription/84fb52795aceadb55d5263f99bcfb3cc34882b30d1274332f901b931c0826cc4i0",
    index: 56953,
    name: "robot",
  },
  {
    inscription:
      "4a908148cda4a4f70254c67c7871b9399539995ebb01bdc5bd47a8357a1eb5c7i0",
    location:
      "4a908148cda4a4f70254c67c7871b9399539995ebb01bdc5bd47a8357a1eb5c7:0:0",
    explorer:
      "https://ordinals.com/inscription/4a908148cda4a4f70254c67c7871b9399539995ebb01bdc5bd47a8357a1eb5c7i0",
    index: 56954,
    name: "solid gold summer",
  },
  {
    inscription:
      "dea05bf66ef1eaca2fa7d13370892683950627676fbf1e3c03b9136034f900cdi0",
    location:
      "dea05bf66ef1eaca2fa7d13370892683950627676fbf1e3c03b9136034f900cd:0:0",
    explorer:
      "https://ordinals.com/inscription/dea05bf66ef1eaca2fa7d13370892683950627676fbf1e3c03b9136034f900cdi0",
    index: 56955,
    name: "solid gold knitted hat",
  },
  {
    inscription:
      "e53fb0d67b03e5fb69b03f9dadfc6f9b90a728cd31c08a424ab3f50ca7871fd8i0",
    location:
      "e53fb0d67b03e5fb69b03f9dadfc6f9b90a728cd31c08a424ab3f50ca7871fd8:0:0",
    explorer:
      "https://ordinals.com/inscription/e53fb0d67b03e5fb69b03f9dadfc6f9b90a728cd31c08a424ab3f50ca7871fd8i0",
    index: 56956,
    name: "pastel purple",
  },
  {
    inscription:
      "acc6c07461277848b4176a8da6ba532b9a24a9fe9716e2a77a435c7cb3f274ddi0",
    location:
      "acc6c07461277848b4176a8da6ba532b9a24a9fe9716e2a77a435c7cb3f274dd:0:0",
    explorer:
      "https://ordinals.com/inscription/acc6c07461277848b4176a8da6ba532b9a24a9fe9716e2a77a435c7cb3f274ddi0",
    index: 56957,
    name: "pastel blue",
  },
  {
    inscription:
      "ba63999d3ec720d5fd7c286995e69e1696343b4b10f328c4d06664fd81b144dfi0",
    location:
      "ba63999d3ec720d5fd7c286995e69e1696343b4b10f328c4d06664fd81b144df:0:0",
    explorer:
      "https://ordinals.com/inscription/ba63999d3ec720d5fd7c286995e69e1696343b4b10f328c4d06664fd81b144dfi0",
    index: 56958,
    name: "solid gold noggles",
  },
  {
    inscription:
      "4fee737a3652116410c3e68a00781306318802b2af2170b94a75175927cc00e8i0",
    location:
      "4fee737a3652116410c3e68a00781306318802b2af2170b94a75175927cc00e8:0:0",
    explorer:
      "https://ordinals.com/inscription/4fee737a3652116410c3e68a00781306318802b2af2170b94a75175927cc00e8i0",
    index: 56959,
    name: "btc halloween",
  },
  {
    inscription:
      "79ed335f89e00443640eb9332aa97b1987481d6d6fdcc5159d36ef46158cf8fdi0",
    location:
      "79ed335f89e00443640eb9332aa97b1987481d6d6fdcc5159d36ef46158cf8fd:0:0",
    explorer:
      "https://ordinals.com/inscription/79ed335f89e00443640eb9332aa97b1987481d6d6fdcc5159d36ef46158cf8fdi0",
    index: 56960,
    name: "flipped pfp",
  },
  {
    inscription:
      "86a63c06c6c413a9deeebeef3cd7c8597880a7c15131198807ed5dd1b9e8560bi0",
    location:
      "2088372e7efbbe36e41a99e54820ad15571538dbd6318c2ffb973c462064c7a5:0:0",
    explorer:
      "https://ordinals.com/inscription/86a63c06c6c413a9deeebeef3cd7c8597880a7c15131198807ed5dd1b9e8560bi0",
    index: 48699,
    name: "ordinals logo",
  },
];
