import {
  useColorMode,
  useColorModeValue,
  IconButton,
  Button,
} from "@chakra-ui/react";
import { FaMoon, FaSun } from "react-icons/fa";

export const ColorModeSwitcher = ({
  desktop = false,
}: {
  desktop?: boolean;
}) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const SwitchIcon = useColorModeValue(FaMoon, FaSun);

  if (desktop) {
    return (
      <IconButton
        display={{ base: "none", md: "inherit" }}
        onClick={toggleColorMode}
        icon={<SwitchIcon />}
        aria-label={`Switch to ${text} mode`}
      />
    );
  }

  return (
    <Button
      display={{ base: "block", md: "none" }}
      variant="unstyled"
      width="full"
      textAlign="left"
      onClick={toggleColorMode}
    >
      {`Switch to ${text} mode`}
    </Button>
  );
};
