import { Container, Heading } from "@chakra-ui/react";
import { Link } from "../components/Link";
import { Faq } from "../components/Faq";
import { TraverseForm } from "../components/TraverseForm";

export function Event() {
  return (
    <Container maxWidth="4xl" centerContent>
      <Heading
        mt={12}
        mb={3}
        bgGradient="linear(to-r, purple.500, orange.600)"
        bgClip="text"
      >
        Deflationary Winter Event
      </Heading>
      {/* <Alert status="info" my={9} borderRadius="lg">
        <AlertIcon />
        The winter event has now ended, traverse your dinos{" "}
        <Link ml={1} to="/traverse">
          {" "}
          here
        </Link>
      </Alert> */}
      <Heading as="h3" fontWeight="normal" fontSize="lg" mb={12}>
        Transform your genesis dino into a winter dino.{" "}
        <Link type="hash" to="#faq">
          Learn more...
        </Link>
      </Heading>
      {/* <Box mb={12}>
        <OneOnePreview />
      </Box> */}
      {/* <Heading mb={9}>Swap</Heading> */}
      <TraverseForm preview swapType="collections" />
      <Faq page="event" />
    </Container>
  );
}
