import { Container, Heading } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Container maxWidth="4xl" centerContent height="75vh">
      <Heading mt={12} mb={3}>
        Page not found
      </Heading>
    </Container>
  );
}
