import { useEthers, shortenIfAddress, useLookupAddress } from "@usedapp/core";
import { Button } from "@chakra-ui/react";

import { SwitchChains } from "./SwitchChains";

export function Connect({ desktop = false }) {
  const { activateBrowserWallet, account } = useEthers();
  const { ens } = useLookupAddress(account);

  if (desktop) {
    if (account) {
      return (
        <SwitchChains
          desktop
          title={account ? ens ?? shortenIfAddress(account) : "Connect"}
        />
      );
    }

    return (
      <Button
        display={{ base: "none", md: "block" }}
        aria-label="Connect wallet"
        onClick={activateBrowserWallet}
      >
        {account ? ens ?? shortenIfAddress(account) : "Connect"}
      </Button>
    );
  }

  return (
    <Button
      display={{ base: "block", md: "none" }}
      variant="unstyled"
      width="full"
      textAlign="left"
      aria-label="Connect wallet"
      onClick={activateBrowserWallet}
    >
      {account ? ens ?? shortenIfAddress(account) : "Connect wallet"}
    </Button>
  );
}
