import { useBreakpointValue, Box } from "@chakra-ui/react";

export const LocalTools = () => {
  const breakpoint = useBreakpointValue({
    base: "base",
    sm: "sm",
    md: "md",
    lg: "lg",
    xl: "xl",
    "2xl": "2xl",
  });

  return (
    <Box
      position="fixed"
      bottom="0"
      left="0"
      p={1}
      bg="orange.400"
      borderTopEndRadius="md"
    >
      {breakpoint}
    </Box>
  );
};
