import { Fragment } from "react";
import {
  Button,
  Box,
  useDisclosure,
  Collapse,
  VStack,
  Icon,
  Flex,
  Link,
} from "@chakra-ui/react";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { marketplaces } from "../constants";

export const Buy = () => {
  const { isOpen, onToggle } = useDisclosure();
  const { isOpen: isGenesisOpen, onToggle: onGenesisToggle } = useDisclosure();
  const { isOpen: isSummer2022Open, onToggle: onSummer2022Toggle } =
    useDisclosure();
  const { isOpen: isHalloween2022Open, onToggle: onHalloween2022Toggle } =
    useDisclosure();
  const { isOpen: isWinter2022Open, onToggle: onWinter2022Toggle } =
    useDisclosure();

  const toggleLookup = {
    genesis: { isOpen: isGenesisOpen, onToggle: onGenesisToggle },
    summer2022: { isOpen: isSummer2022Open, onToggle: onSummer2022Toggle },
    halloween2022: {
      isOpen: isHalloween2022Open,
      onToggle: onHalloween2022Toggle,
    },
    winter2022: {
      isOpen: isWinter2022Open,
      onToggle: onWinter2022Toggle,
    },
  };

  return (
    <>
      <Flex w="full" onClick={onToggle} alignItems="center">
        <Button
          display={{ base: "block", md: "none" }}
          variant="unstyled"
          width="full"
          textAlign="left"
        >
          Buy
        </Button>
        <Icon
          as={ChevronDownIcon}
          transition={"all .25s ease-in-out"}
          transform={isOpen ? "rotate(180deg)" : ""}
          w={6}
          h={6}
        />
      </Flex>
      <Box w="full">
        <Collapse in={isOpen} animateOpacity>
          <VStack align="flex-start" pl={3} w="full">
            {marketplaces.map((collection) => {
              return (
                <Fragment key={collection.name}>
                  <Flex
                    w="full"
                    onClick={
                      !collection.disabled
                        ? toggleLookup[
                            collection.collectionKey as keyof typeof toggleLookup
                          ].onToggle
                        : () => {}
                    }
                    alignItems="center"
                  >
                    <Button
                      disabled={collection.disabled}
                      display={{ base: "block", md: "none" }}
                      variant="unstyled"
                      width="full"
                      textAlign="left"
                      key={collection.name}
                    >
                      {collection.name}
                    </Button>
                    <Icon
                      as={ChevronDownIcon}
                      color={collection.disabled ? "gray.600" : "inherit"}
                      transition={"all .25s ease-in-out"}
                      transform={
                        toggleLookup[
                          collection.collectionKey as keyof typeof toggleLookup
                        ].isOpen
                          ? "rotate(180deg)"
                          : ""
                      }
                      w={6}
                      h={6}
                    />
                  </Flex>
                  <Box w="full">
                    <Collapse
                      in={
                        toggleLookup[
                          collection.collectionKey as keyof typeof toggleLookup
                        ].isOpen
                      }
                      animateOpacity
                    >
                      <VStack align="flex-start" pl={3} w="full">
                        {collection.chains.map((marketplace) => {
                          return (
                            <Link
                              pt={2}
                              pb={2}
                              href={marketplace.link}
                              isExternal
                              key={`${marketplace.link}-${marketplace.displayName}`}
                            >
                              {marketplace.displayName} ({marketplace.name}){" "}
                              <ExternalLinkIcon mx="2px" mb={1} />
                            </Link>
                          );
                        })}
                      </VStack>
                    </Collapse>
                  </Box>
                </Fragment>
              );
            })}
          </VStack>
        </Collapse>
      </Box>
    </>
  );
};
