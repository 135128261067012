import {
  Flex,
  Heading,
  Image,
  Text,
  Button,
  Stack,
  Container,
  useColorModeValue,
  Center,
  Box,
  HStack,
  Link,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Divider,
} from "@chakra-ui/react";
import { Link as RRLink } from "react-router-dom";
import { ExternalLinkIcon } from "@chakra-ui/icons";

import { TwitterIcon } from "../icons/TwitterIcon";
import { LayerZeroLogo } from "../icons/LayerZeroLogo";
import { CreativeCommonsLogo } from "../icons/CreativeCommonsLogo";
import { marketplaces } from "../constants";

const images = ["/xoshi.png", "/friendly-dino.png", "/7688.png", "/8182.png"];
const imgSrc = images[Math.floor(Math.random() * images.length)];

export const Home = () => {
  const iconFillColor = useColorModeValue("twitter.600", "twitter.200");
  const sectionSpacing = { base: 6, sm: 12, md: 20 };
  return (
    <Container maxWidth="4xl" centerContent>
      <Stack
        spacing={6}
        direction={{ base: "column", md: "row" }}
        w="full"
        mt={{ base: 12, md: 24 }}
        mb={sectionSpacing}
      >
        <Flex
          flexDirection="column"
          alignItems="stretch"
          w={{ base: "100%", md: "50%" }}
        >
          <Heading
            size={{ base: "3xl", sm: "4xl" }}
            as="h2"
            bgGradient="linear(to-r, pink.500, purple.500)"
            bgClip="text"
          >
            Omnichain
          </Heading>
          <Heading
            size={{ base: "3xl", sm: "4xl" }}
            as="h2"
            bgGradient="linear(to-r, green.500, teal.500)"
            bgClip="text"
          >
            CC0
          </Heading>
          <Heading
            size={{ base: "3xl", sm: "4xl" }}
            as="h2"
            mb={6}
            bgGradient="linear(to-r, blue.500, cyan.500)"
            bgClip="text"
            pb={1}
          >
            NFT
          </Heading>
          <Text pb={6} pt={3} fontSize="xl" fontWeight="bold">
            Tiny dinos bring fun, vibes and innovation to the nft community
          </Text>
          <Stack spacing={2} mb={3} direction={{ base: "column", lg: "row" }}>
            <Button
              colorScheme="blue"
              size="lg"
              as={Link}
              href="https://opensea.io/collection/tiny-dinos-eth"
              isExternal
            >
              Buy a tiny dino
            </Button>
            <Button
              variant="outline"
              colorScheme="twitter"
              size={["md", "lg"]}
              as={Link}
              href="https://twitter.com/tinydinosnft"
              isExternal
              rightIcon={<TwitterIcon color={iconFillColor} width={16} />}
            >
              Be part of the community
            </Button>
          </Stack>
          <Text fontSize="sm">
            Join over 3k people holding a unique piece of digital art with next
            generation innovation
          </Text>
        </Flex>
        <Center w={{ base: "100%", md: "50%" }}>
          <Image src={imgSrc} borderRadius="xl" boxShadow="base" />
        </Center>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        borderRadius="xl"
        w="full"
        mb={sectionSpacing}
        mt={sectionSpacing}
      >
        <Heading
          as="h2"
          textAlign="center"
          mb={12}
          w="full"
          px={{ base: 0, sm: 12, md: 24 }}
          bgGradient="linear(to-r, pink.500, purple.500)"
          bgClip="text"
        >
          Don't tie yourself to a single blockchain
        </Heading>
        <Stack spacing={6} direction={{ base: "column", md: "row" }}>
          <Box w={{ base: "100%", md: "50%" }}>
            <Center
              py={20}
              backgroundColor="black"
              borderRadius="xl"
              boxShadow="base"
            >
              <LayerZeroLogo color="white" width="200px" />
            </Center>
          </Box>
          <Box w={{ base: "100%", md: "50%" }}>
            <Text mb={6} fontSize="lg">
              Switch chains with ease thanks to layerzero omnichain
              functionality
            </Text>
            <Text mb={6} fontSize="lg">
              Future proof without compromise
            </Text>
            <Stack spacing={2} mb={3}>
              <Button
                as={RRLink}
                to="/traverse"
                variant="solid"
                size="md"
                width="min"
              >
                Traverse chains
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        borderRadius="xl"
        w="full"
        mb={sectionSpacing}
        mt={sectionSpacing}
      >
        <Heading
          as="h2"
          textAlign="center"
          mb={12}
          w="full"
          px={{ base: 0, sm: 12, md: 24 }}
          bgGradient="linear(to-r, green.500, teal.500)"
          bgClip="text"
        >
          Own the token, share the art
        </Heading>
        <Stack spacing={6} direction={{ base: "column", md: "row" }}>
          <Box w={{ base: "100%", md: "50%" }}>
            <Text mb={6} fontSize="lg">
              Creative Commons Zero (CC0) empowers anyone to use tiny dinos
              however they want
            </Text>
            <Text mb={6} fontSize="lg">
              The token is yours, the artwork is the worlds
            </Text>
            <Stack spacing={2} mb={3}>
              <Button
                variant="solid"
                size="md"
                width="min"
                as={Link}
                href="https://creativecommons.org/cc-and-nfts/"
                isExternal
              >
                Learn more
              </Button>
            </Stack>
          </Box>
          <Box w={{ base: "100%", md: "50%" }}>
            <Center
              py={20}
              backgroundColor="#ed592f"
              borderRadius="xl"
              boxShadow="base"
            >
              <CreativeCommonsLogo color="white" width="200px" />
            </Center>
          </Box>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        borderRadius="xl"
        w="full"
        mb={sectionSpacing}
        mt={sectionSpacing}
      >
        <Heading
          as="h2"
          textAlign="center"
          mb={12}
          w="full"
          px={{ base: 0, sm: 12, md: 24 }}
          bgGradient="linear(to-r, blue.500, cyan.500)"
          bgClip="text"
        >
          Omnicollection innovation
        </Heading>
        <Stack spacing={6} direction={{ base: "column", md: "row" }}>
          <Box w={{ base: "100%", md: "50%" }}>
            <Center>
              <Image
                src="/summer-logo.png"
                borderRadius="xl"
                boxShadow="base"
              />
            </Center>
          </Box>
          <Box w={{ base: "100%", md: "50%" }}>
            <Text mb={6} fontSize="lg">
              The first project to harness Omnichain functionality to allow your
              dino to swap collections too
            </Text>
            <Text mb={6} fontSize="lg">
              Limited time events where your dino can take on a whole new look
            </Text>
            <Text mb={6} fontSize="lg">
              All while reducing the supply of genesis dinos
            </Text>
            <Stack spacing={2} mb={3}>
              <Button
                variant="solid"
                size="md"
                width="min"
                as={RRLink}
                to="/traverse#faq"
              >
                Learn more
              </Button>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Divider />
      <Stack
        spacing={2}
        borderRadius="xl"
        w="full"
        mb={20}
        mt={sectionSpacing}
        id="buy"
      >
        <Heading
          as="h2"
          textAlign="center"
          mb={6}
          w="full"
          px={{ base: 0, sm: 12, md: 24 }}
          bgGradient="linear(to-r, red.500, orange.500)"
          bgClip="text"
        >
          Buy a dino
        </Heading>
        <HStack px={{ base: 0, sm: 6 }}>
          <Tabs w="full" variant="soft-rounded">
            <TabList>
              {marketplaces.map((collection) => (
                <Tab
                  fontSize={{ base: "sm", sm: "md" }}
                  isDisabled={collection.disabled}
                  key={collection.name}
                >
                  {collection.name}
                </Tab>
              ))}
            </TabList>

            <TabPanels>
              {marketplaces.map((collection) => (
                <TabPanel key={collection.name}>
                  {collection.chains.map((marketplace) => {
                    return (
                      <Box
                        key={`${marketplace.link}-${marketplace.displayName}`}
                      >
                        <Text pt={2} pb={2}>
                          <Link
                            href={marketplace.link}
                            isExternal
                            key={`${marketplace.link}-${marketplace.displayName}`}
                          >
                            {collection.name} {marketplace.displayName} (
                            {marketplace.name}){" "}
                            <ExternalLinkIcon mx="2px" mb={1} />
                          </Link>
                        </Text>
                      </Box>
                    );
                  })}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </HStack>
      </Stack>
    </Container>
  );
};
