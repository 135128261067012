import { useToast, Link } from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import {
  genesisContract,
  summer2022Contract,
  halloween2022Contract,
  winter2022Contract,
} from "../constants";
import { useEthers, useContractFunction } from "@usedapp/core";

export function useTraverse() {
  const { chainId } = useEthers();
  const toast = useToast();

  const {
    send: sendGenesisTraverse,
    state: genesisTraverseState,
    resetState: genesisResetState,
  } = useContractFunction(
    chainId && genesisContract(chainId),
    "traverseChains",
    {
      transactionName: "Genesis Traverse",
    }
  );

  const {
    send: sendSummer2022Traverse,
    state: summer2022TraverseState,
    resetState: summer2022ResetState,
  } = useContractFunction(
    chainId && summer2022Contract(chainId),
    "traverseChains",
    {
      transactionName: "Summer 2022 Traverse",
    }
  );

  const {
    send: sendHalloween2022Traverse,
    state: halloween2022TraverseState,
    resetState: halloween2022ResetState,
  } = useContractFunction(
    chainId && halloween2022Contract(chainId),
    "traverseChains",
    {
      transactionName: "Halloween 2022 Traverse",
    }
  );

  const {
    send: sendWinter2022Traverse,
    state: winter2022TraverseState,
    resetState: winter2022ResetState,
  } = useContractFunction(
    chainId && winter2022Contract(chainId),
    "traverseChains",
    {
      transactionName: "Winter 2022 Traverse",
    }
  );

  if (genesisTraverseState?.status === "Success") {
    genesisResetState();
    if (
      !toast.isActive(
        genesisTraverseState?.transaction?.hash || "genesis-traverse-success"
      )
    ) {
      toast({
        id: genesisTraverseState?.transaction?.hash,
        title: "Success",
        description: (
          <Link
            href={`https://layerzeroscan.com/tx/${genesisTraverseState?.transaction?.hash}`}
            isExternal
          >
            Track transaction on LayerZero Scan{" "}
            <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
        ),
        status: "success",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (summer2022TraverseState?.status === "Success") {
    summer2022ResetState();
    if (
      !toast.isActive(
        summer2022TraverseState?.transaction?.hash || "summer-traverse-success"
      )
    ) {
      toast({
        id: summer2022TraverseState?.transaction?.hash,
        title: "Success",
        description: (
          <Link
            href={`https://layerzeroscan.com/tx/${summer2022TraverseState?.transaction?.hash}`}
            isExternal
          >
            Track transaction on LayerZero Scan{" "}
            <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
        ),
        status: "success",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (halloween2022TraverseState?.status === "Success") {
    halloween2022ResetState();
    if (
      !toast.isActive(
        halloween2022TraverseState?.transaction?.hash ||
          "halloween-traverse-success"
      )
    ) {
      toast({
        id: halloween2022TraverseState?.transaction?.hash,
        title: "Success",
        description: (
          <Link
            href={`https://layerzeroscan.com/tx/${halloween2022TraverseState?.transaction?.hash}`}
            isExternal
          >
            Track transaction on LayerZero Scan{" "}
            <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
        ),
        status: "success",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (winter2022TraverseState?.status === "Success") {
    winter2022ResetState();
    if (
      !toast.isActive(
        winter2022TraverseState?.transaction?.hash || "winter-traverse-success"
      )
    ) {
      toast({
        id: winter2022TraverseState?.transaction?.hash,
        title: "Success",
        description: (
          <Link
            href={`https://layerzeroscan.com/tx/${winter2022TraverseState?.transaction?.hash}`}
            isExternal
          >
            Track transaction on LayerZero Scan{" "}
            <ExternalLinkIcon mx="2px" mb={1} />
          </Link>
        ),
        status: "success",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (genesisTraverseState?.errorCode) {
    genesisResetState();
    if (!toast.isActive(genesisTraverseState?.errorCode)) {
      toast({
        id: genesisTraverseState?.errorCode,
        title: "Error",
        description: genesisTraverseState.errorMessage,
        status: "error",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (summer2022TraverseState?.errorCode) {
    summer2022ResetState();
    if (!toast.isActive(summer2022TraverseState?.errorCode)) {
      toast({
        id: summer2022TraverseState?.errorCode,
        title: "Error",
        description: summer2022TraverseState.errorMessage,
        status: "error",
        duration: null,
        isClosable: true,
      });
    }
  }

  if (winter2022TraverseState?.errorCode) {
    winter2022ResetState();
    if (!toast.isActive(winter2022TraverseState?.errorCode)) {
      toast({
        id: winter2022TraverseState?.errorCode,
        title: "Error",
        description: winter2022TraverseState.errorMessage,
        status: "error",
        duration: null,
        isClosable: true,
      });
    }
  }

  return {
    sendGenesisTraverse,
    sendSummer2022Traverse,
    sendHalloween2022Traverse,
    sendWinter2022Traverse,
  };
}
