import {
  HStack,
  Heading,
  Flex,
  Img,
  Box,
  Spacer,
  useDisclosure,
  Collapse,
  IconButton,
  VStack,
  Link as ChakraLink,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";

import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Connect } from "./Connect";
import { Link } from "./Link";
import { SwitchChains } from "./SwitchChains";
import { Buy } from "./Buy";
import { TwitterIcon } from "../icons/TwitterIcon";
import { OpenSeaIcon } from "../icons/OpenSeaIcon";

export const Header = () => {
  const { isOpen, onToggle } = useDisclosure();
  const iconFillColor = useColorModeValue("black", "white");

  return (
    <Box as="header">
      <Flex mb={3}>
        <Box>
          <Link
            to="/"
            _hover={{
              textDecoration: "none",
            }}
          >
            <HStack spacing={3}>
              <Img src="/logo-no-padding.png" h="40px" w="35px" />
              <Heading
                display={{ base: "none", sm: "block" }}
                as="h1"
                fontSize="lg"
                lineHeight={0.9}
              >
                Tiny
                <br />
                Dinos
              </Heading>
            </HStack>
          </Link>
        </Box>
        <HStack
          as="nav"
          ml={6}
          spacing={3}
          direction="row"
          display={{ base: "none", md: "flex" }}
        >
          <Link pt={2} pb={2} type="navlink" to="/ordinals">
            Ordinals
          </Link>
          <Link pt={2} pb={2} type="navlink" to="/traverse">
            Traverse
          </Link>

          {/* <Link pt={2} pb={2} type="navlink" to="/event/winter-2022">
            Winter
          </Link> */}

          <Link pt={2} pb={2} type="hash" to="/#buy">
            Buy
          </Link>
        </HStack>
        <Spacer />
        <Box>
          <HStack spacing={2} justifyContent="center">
            <IconButton
              aria-label="OpenSea"
              as={ChakraLink}
              href="https://opensea.io/collection/tiny-dinos-eth"
              isExternal
              fontSize="16px"
              icon={<OpenSeaIcon color={iconFillColor} width={16} />}
            />
            <IconButton
              aria-label="Twitter"
              as={ChakraLink}
              href="https://twitter.com/tinydinosnft"
              isExternal
              fontSize="16px"
              icon={<TwitterIcon color={iconFillColor} width={16} />}
            />
            <Connect desktop />
            <ColorModeSwitcher desktop />
            <IconButton
              display={{ base: "block", md: "none" }}
              onClick={onToggle}
              aria-label="Toggle menu"
              icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            />
          </HStack>
        </Box>
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack
          mt={3}
          mb={6}
          align="flex-start"
          display={{ base: "flex", md: "none" }}
        >
          <Connect />
          <SwitchChains />
          <Link
            pt={2}
            pb={2}
            w="full"
            type="navlink"
            to="/traverse"
            onClick={onToggle}
          >
            Traverse
          </Link>
          {/* <Link
            pt={2}
            pb={2}
            w="full"
            type="navlink"
            to={`/event/winter-2022`}
            onClick={onToggle}
          >
            Winter
          </Link> */}
          <Buy />
          <Link
            pt={2}
            pb={2}
            w="full"
            type="navlink"
            to="/ordinals"
            onClick={onToggle}
          >
            Ordinals
          </Link>
          <ChakraLink
            pt={2}
            pb={2}
            w="full"
            href="https://twitter.com/tinydinosnft"
            onClick={onToggle}
            isExternal
          >
            Twitter <ExternalLinkIcon mx="2px" mb={1} />
          </ChakraLink>
          <ColorModeSwitcher />
        </VStack>
      </Collapse>
    </Box>
  );
};
