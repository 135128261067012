import { useState } from "react";
import { utils } from "ethers";
import {
  Input,
  Select,
  Button,
  FormControl,
  Box,
  Text,
  FormLabel,
  FormHelperText,
  FormErrorMessage,
  Stack,
  Image,
  Center,
  chakra,
  RadioGroup,
  Radio,
  Tooltip as ChakraTooltip,
  Spacer,
  Flex,
} from "@chakra-ui/react";
import { supportedChains } from "../constants";
import { useEthers, ChainId } from "@usedapp/core";
import { useForm, SubmitHandler } from "react-hook-form";

import { QuestionTooltip } from "./QuestionTooltip";
import { useTraverse } from "../hooks/useTraverse";
import { useEstimateFees } from "../hooks/useEstimateFees";

import { metadata } from "../metadata/winter-2022";

export interface Inputs {
  collection: string;
  id: string;
  destination: string;
}

const collectionOptions = {
  /**
   * ids for event collections are reversed as the options are which collection to swap into rather than which collection to swap from
   */
  collections: [
    {
      title: "Winter dino ⛄️",
      id: "genesis",
      disabled: false,
      defaultOption: true,
    },
    {
      title: "Genesis dino 🦖",
      id: "winter-2022",
      disabled: false,
    },
  ],
  chains: [
    {
      title: "Genesis dino 🦖",
      id: "genesis",
      disabled: false,
      defaultOption: true,
    },
    {
      title: "Summer dino 🍉",
      id: "summer-2022",
      disabled: false,
      defaultOption: false,
    },
    {
      title: "Halloween dino 🎃",
      id: "halloween-2022",
      disabled: false,
    },
    {
      title: "Winter dino ⛄️",
      id: "winter-2022",
      disabled: false,
    },
  ],
};

export function TraverseForm({
  preview = false,
  swapType,
}: {
  preview?: boolean;
  swapType: "chains" | "collections";
}) {
  const isEventTraversePage = swapType === "collections";
  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
    control,
  } = useForm<Inputs>({
    defaultValues: {
      collection: isEventTraversePage ? "genesis" : "summer-2022",
    },
  });
  const { chainId } = useEthers();
  const watchField = watch();
  const {
    sendGenesisTraverse,
    sendSummer2022Traverse,
    sendHalloween2022Traverse,
    sendWinter2022Traverse,
  } = useTraverse();
  const { gasFeeEstimate } = useEstimateFees(control);
  const [showAttributes, toggleShowAttributes] = useState(false);

  const onSubmit: SubmitHandler<Inputs> = ({ id, destination, collection }) => {
    const destinationInt = parseInt(destination, 10);
    interface Contracts {
      [key: string]: any;
    }
    const collectionContracts: Contracts = {
      genesis: sendGenesisTraverse,
      "summer-2022": sendSummer2022Traverse,
      "halloween-2022": sendHalloween2022Traverse,
      "winter-2022": sendWinter2022Traverse,
    };

    const fn = collectionContracts[collection];

    fn(supportedChains[destinationInt].LayerZero.chainId, id, {
      value: utils.parseEther(gasFeeEstimate),
    });
  };

  return (
    <chakra.form mb={12} w="full" onSubmit={handleSubmit(onSubmit)}>
      {chainId ? (
        <>
          <Stack
            direction={{ base: "column", sm: "row" }}
            spacing={{ base: 0, sm: 6 }}
            mb={6}
          >
            <Box
              w={{ base: "100%", sm: isEventTraversePage ? "50%" : "100%" }}
              mb={9}
            >
              <FormControl mb={9} isInvalid={!!errors?.id}>
                <FormLabel mb={3} htmlFor="id">
                  Dino ID
                </FormLabel>
                <Input
                  id="id"
                  {...register("id", {
                    required: "Please enter a dino id",
                    min: { value: 1, message: "Must be between 1 & 10001" },
                    max: {
                      value: 10001,
                      message: "Must be between 1 & 10001",
                    },
                  })}
                  placeholder="1234"
                  type="number"
                />
                {errors?.id ? (
                  <FormErrorMessage>{errors.id.message}</FormErrorMessage>
                ) : (
                  <FormHelperText mt={2}>
                    The id of a tiny dino you own
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={9} isInvalid={!!errors?.collection}>
                <FormLabel mb={3} htmlFor="collection">
                  {isEventTraversePage ? "Become a" : "Traverse"}
                </FormLabel>
                <RadioGroup
                  defaultValue={
                    collectionOptions[swapType].find(
                      (collection) => collection.defaultOption
                    )?.id
                  }
                >
                  <Stack>
                    {collectionOptions[swapType].map(
                      ({ title, disabled, id }) => {
                        const radio = (
                          <Radio
                            isDisabled={disabled}
                            backgroundColor="gray.50"
                            mr={3}
                            key={id}
                            value={id}
                            {...register("collection", { required: true })}
                          >
                            {title}
                          </Radio>
                        );

                        if (disabled) {
                          return (
                            <ChakraTooltip
                              shouldWrapChildren
                              label="Disabled during winter event"
                              placement="bottom-start"
                              key={id}
                            >
                              {radio}
                            </ChakraTooltip>
                          );
                        }

                        return radio;
                      }
                    )}
                    ;
                  </Stack>
                </RadioGroup>
                {errors?.collection ? (
                  <FormErrorMessage>
                    {errors.collection.message}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText mt={2}>
                    {isEventTraversePage
                      ? "The collection you want to swap your dino into"
                      : "The collection your dino lives in"}
                  </FormHelperText>
                )}
              </FormControl>
              <FormControl mb={6} isInvalid={!!errors?.destination}>
                <FormLabel mb={3} htmlFor="destination">
                  Destination chain
                </FormLabel>
                <Select
                  placeholder="Choose your destination"
                  {...register("destination", {
                    required: "Choose a destination chain",
                  })}
                >
                  {Object.keys(supportedChains).map((key) => {
                    const destinationId = parseInt(key, 10);
                    return (
                      <option
                        value={destinationId}
                        disabled={
                          !isEventTraversePage && destinationId === chainId
                        }
                        key={destinationId}
                      >
                        {supportedChains[destinationId as ChainId].displayName}
                      </option>
                    );
                  })}
                </Select>
                {errors?.destination ? (
                  <FormErrorMessage mt={2}>
                    {errors.destination.message}
                  </FormErrorMessage>
                ) : (
                  <FormHelperText mt={2}>
                    The chain you want to{" "}
                    {isEventTraversePage ? "swap" : "traverse"} to
                    {isEventTraversePage && " (can be the same chain)"}
                  </FormHelperText>
                )}
              </FormControl>
              <Text>
                Traverse fee{" "}
                <Text as="span" fontWeight="medium">
                  {gasFeeEstimate ?? "-"}{" "}
                  {supportedChains[chainId] &&
                    supportedChains[chainId].nativeCurrency?.symbol}{" "}
                  plus gas
                </Text>{" "}
                <QuestionTooltip tip="The traverse fee covers the gas required to complete the transaction on the destination chain. Any of this fee not used will be refunded to you." />
              </Text>
            </Box>
            {preview && (
              <Box w={{ base: "100%", sm: "50%" }}>
                <Text mb={3} fontWeight="medium">
                  Winter Preview{" "}
                  <QuestionTooltip
                    tip={`preview your winter dino before you traverse`}
                  />
                </Text>
                <Box position="relative">
                  <Image
                    w="full"
                    src={
                      Number(watchField?.id) > 0 &&
                      Number(watchField?.id) < 10002
                        ? `/images/winter-2022/${watchField.id}.png`
                        : "/question-mark.png"
                    }
                    borderRadius="xl"
                    boxShadow="base"
                  />
                  <Flex
                    display={showAttributes ? "block" : "none"}
                    position="absolute"
                    top={0}
                    borderRadius="xl"
                    direction="column"
                    w="full"
                    py={3}
                    px={6}
                    bg="blackAlpha.400"
                    height="full"
                  >
                    {metadata[watchField?.id]?.attributes.map(
                      (attribute: any) => {
                        return (
                          <Flex
                            color="white"
                            justify="space-between"
                            pb={{ base: 0, md: 1 }}
                            key={attribute.trait_type}
                            fontSize={{ base: "xs", md: "md" }}
                          >
                            <Text>{attribute.trait_type}</Text>
                            <Spacer />
                            <Text fontWeight="bold">{attribute.value}</Text>
                          </Flex>
                        );
                      }
                    )}
                  </Flex>
                </Box>
                <Center pt={6}>
                  <Button
                    disabled={!watchField?.id}
                    onClick={() => toggleShowAttributes(!showAttributes)}
                  >
                    {showAttributes ? "Hide attributes" : "Show attributes"}
                  </Button>
                </Center>
              </Box>
            )}
          </Stack>
          <Center>
            <Button type="submit" colorScheme="green" size="lg">
              {isEventTraversePage
                ? `Become a ${
                    watchField.collection === "genesis" ? "winter" : "genesis"
                  } dino
                  `
                : "Traverse"}
            </Button>
          </Center>
        </>
      ) : (
        <Text align="center">Please connect to a supported chain</Text>
      )}
    </chakra.form>
  );
}
