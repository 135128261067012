import { Container, Heading } from "@chakra-ui/react";

import { Faq } from "../components/Faq";
import { TraverseForm } from "../components/TraverseForm";
import { Link } from "../components/Link";

export function Traverse() {
  return (
    <Container maxWidth="4xl" centerContent>
      <Heading
        mt={12}
        mb={3}
        bgGradient="linear(to-r, pink.500, purple.500)"
        bgClip="text"
      >
        Traverse Chains
      </Heading>
      <Heading as="h3" fontWeight="normal" fontSize="lg" mb={12}>
        Move your tiny dino from one chain to another thanks to LayerZero's
        Omnichain functionality.{" "}
        <Link type="hash" to="#faq">
          Learn more...
        </Link>
      </Heading>
      <Container maxWidth="xl" centerContent>
        <TraverseForm swapType="chains" />
      </Container>
      <Faq page="traverse" />
    </Container>
  );
}
